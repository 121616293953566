import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { CommunicationTemplate } from "app/features/tenant-unified-inbox/tenant-unified-inbox.model";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { State } from "../../tenant-dialog.state";
import { tenantDialogActivityActions } from "../tenant-dialog-activities/tenant-dialog-activities.action";
import { actionTenantDialogTenantRepsRequested } from "../tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.action";
import { selectTenantDialogTenantId } from "../tenant-dialog-user-general/tenant-dialog-general.selectors";
import { actionTenantDialogSendEmailRequest, actionTenantDialogSendEmailSuccessed, actionTenantDialogSendEmailFailed, actionTenantDialogSendEmailAndCollaborateRequest, actionTenantDialogSendEmailAndCollaborateSuccessed, actionTenantDialogSendEmailAndCollaborateFailed, actionTenantDialogEmailTemplateSaveAndUpdate, actionTenantDialogEmailTemplateSaveAndUpdateSucceeded, actionTenantDialogEmailTemplateSaveAndUpdateFailed, actionTenantDialogEmailTemplatesRequest, actionTenantDialogEmailTemplatesLoaded, actionTenantDialogEmailTemplatesFalied, actionTenantDialogEmailTemplateDeleteRequest, actionTenantDialogEmailTemplateDeleteSucceeded, actionTenantDialogEmailTemplateDeleteFailed, actionTenantDialogSendEmailReplyEditorIsForwardingUpdate } from "./tenant-dialog-email-editor.action";
import { TenantDialogEmailService } from "./tenant-dialog-email.service";

@Injectable()
export class TenantDialogEmailEditorEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private tenantDialogEmailService: TenantDialogEmailService,
        private notificationService: NotificationService
    ) { }

    createLeaEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendEmailRequest),
            switchMap(action =>
                this.tenantDialogEmailService.createTenantEmail(action.tenantId, action.externalIdentifier, action.emailType, action.text, action.sendFromUserId, action.sendTo, action.sendToCc, action.sendToBcc, action.subject, action.files).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Email sent!')
                            return actionTenantDialogSendEmailSuccessed();
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogSendEmailFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogSendEmailFailed())
                        })
                    )
                )
            )
        )
    );

    createLeaEmailAndCollaborate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendEmailAndCollaborateRequest),
            switchMap(action =>
                this.tenantDialogEmailService.createTenantEmailAndCollaborate(action.tenantId, action.externalIdentifier, action.emailType, action.text, action.sendFromUserId, action.sendTo, action.sendToCc, action.sendToBcc, action.subject, action.files).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Email sent!')
                            return actionTenantDialogSendEmailAndCollaborateSuccessed();
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogSendEmailAndCollaborateFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogSendEmailAndCollaborateFailed())
                        })
                    )
                )
            )
        )
    );

    collaborateAndEmailSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendEmailAndCollaborateSuccessed),
            withLatestFrom(this.store.select(selectTenantDialogTenantId)),
            switchMap(([action, leadId]) =>
                [
                    actionTenantDialogTenantRepsRequested({ tenantId: leadId }),
                    actionTenantDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false })
                ]
            )
        )
    );

    saveAndUpdateEmailTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogEmailTemplateSaveAndUpdate),
            switchMap(action =>
                this.tenantDialogEmailService.saveAndUpdateEmailTemplate(action.friendlyName, action.displayName, action.content, action.isAccountShared, action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Saved!')
                            return actionTenantDialogEmailTemplateSaveAndUpdateSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogEmailTemplateSaveAndUpdateFailed();
                        }
                    }),
                        catchError(error => {
                            this.notificationService.error('Template Save Failed')
                            return of(actionTenantDialogEmailTemplateSaveAndUpdateFailed())
                        })
                    )
                )
            )
        )
    );

    saveAndUpdateEmailTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogEmailTemplateSaveAndUpdateSucceeded),
            switchMap(action =>
                [
                    actionTenantDialogEmailTemplatesRequest(),
                ]
            )
        )
    );

    deleteEmailTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogEmailTemplateDeleteRequest),
            switchMap(action =>
                this.tenantDialogEmailService.deleteEmailTemplate(action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Deleted!')
                            return actionTenantDialogEmailTemplateDeleteSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogEmailTemplateDeleteFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogEmailTemplateDeleteFailed())
                        })
                    )
                )
            )
        )
    );

    deleteEmailTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogEmailTemplateDeleteSucceeded),
            switchMap(action =>
                [
                    actionTenantDialogEmailTemplatesRequest(),
                ]
            )
        )
    );


    requestEmailTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogEmailTemplatesRequest),
            switchMap(action =>
                this.tenantDialogEmailService.getEmailTemplates().pipe(
                    map((response => {
                        if (response.status == 1) {
                            let templates: CommunicationTemplate[] = response.data
                            return actionTenantDialogEmailTemplatesLoaded({ templates });
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogEmailTemplatesFalied();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogEmailTemplatesFalied())
                        })
                    )
                )
            )
        )
    );

    createLeadEmailSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendEmailSuccessed),
            withLatestFrom(this.store.pipe(select(selectTenantDialogTenantId))),
            switchMap(([action, leadId]) =>
                [
                    tenantDialogActivityActions.actionTenantDialogAllActivitiesRequest({ tenantId: leadId, pageNo: 0, pageSize: 20, itemCountPage: 0 }),
                    actionTenantDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false })
                ]
            )
        )
    );

}
