import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { AssignedUser } from "../../../../shared/models/tenant-info-models";
import { actionTenantDialogTenantCallFromPhoneNumbersRequest } from "../../tenant-dialog.actions";
import { TenantDialogService } from "../../tenant-dialog.service";
import { tenantDialogRepActions } from "./tenant-dialog-tenant-rep.action";

@Injectable()
export class TenantDialogRepsEffects {
    constructor(
        private actions$: Actions,
        private clientDialogService: TenantDialogService,
        private notificationService: NotificationService
    ) { }

    requestTenantReps = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogTenantRepsRequested),
            switchMap(action =>
                this.clientDialogService.getTenantReps(action.tenantId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let reps = response.data as AssignedUser[]
                            return tenantDialogRepActions.actionTenantDialogTenantRepsLoaded({ reps })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogRepActions.actionTenantDialogTenantRepsFailed()
                        }
                    }),
                    catchError(error => of(tenantDialogRepActions.actionTenantDialogTenantRepsFailed()))
                )
            )
        )
    );

    requestClientRepOptions = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogRepOptionsRequested),
            switchMap(action =>
                this.clientDialogService.getTenantRepOptions().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let users = response.data as AssignedUser[]
                            return tenantDialogRepActions.actionTenantDialogRepOptionsLoaded({ reps: users })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogRepActions.actionTenantDialogRepOptionsFailed()
                        }
                    }),
                    catchError(error => of(tenantDialogRepActions.actionTenantDialogRepOptionsFailed()))
                )
            )
        )
    );

    initialRepCheckStatus = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogRepOptionsLoaded),
            switchMap(action =>
                [tenantDialogRepActions.actionTenantDialogRepOptionsCheckStateInitialize()]
            )
        )
    )

    deleteClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogTenantRepDeleteRequested),
            switchMap(action =>
                this.clientDialogService.deleteTenantRep(action.tenantId, action.repId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let users: AssignedUser[] = response.data
                            return tenantDialogRepActions.actionTenantDialogTenantRepDeleteSuccessfully({ tenantId: action.tenantId, reps: users })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogRepActions.actionTenantDialogTenantRepDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogRepActions.actionTenantDialogTenantRepDeleteFailed())
                    })
                )
            )
        )
    );

    updateClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogRepsUpdateRequested),
            switchMap(action =>
                this.clientDialogService.updateTenantReps(action.id, action.reps).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let users: AssignedUser[] = response.data
                            return tenantDialogRepActions.actionTenantDialogRepsUpdateSuccessfully({ tenantId: action.id, reps: users })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogRepActions.actionTenantDialogRepsUpdateFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogRepActions.actionTenantDialogRepsUpdateFailed())
                    })
                )
            )
        )
    );

    addBatchClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogBatchRepsAddRequested),
            switchMap(action =>
                this.clientDialogService.addBatchTenantReps(action.tenantIds, action.repIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return tenantDialogRepActions.actionTenantDialogBatchRepsAddSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogRepActions.actionTenantDialogBatchRepsAddFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogRepActions.actionTenantDialogBatchRepsAddFailed())
                    })
                )
            )
        )
    );

    removeBatchClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogBatchRepsRemoveRequested),
            switchMap(action =>
                this.clientDialogService.removeBatchTenantReps(action.tenantIds, action.repIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return tenantDialogRepActions.actionTenantDialogBatchRepsRemoveSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogRepActions.actionTenantDialogBatchRepsRemoveFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogRepActions.actionTenantDialogBatchRepsRemoveFailed())
                    })
                )
            )
        )
    );



    tenantAssignedUserUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogRepsUpdateSuccessfully),
            switchMap((action) => [
                actionTenantDialogTenantCallFromPhoneNumbersRequest({ tenantId: action.tenantId })
            ])
        )
    )

    tenantAssignedUserRemove$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogRepActions.actionTenantDialogTenantRepDeleteSuccessfully),
            switchMap((action) => [
                actionTenantDialogTenantCallFromPhoneNumbersRequest({ tenantId: action.tenantId })
            ])
        )
    )

}
