import { createAction, props } from '@ngrx/store';
import { IApplicationToken } from './auth.models';

export const authLogin = createAction('[Auth] Login');

export const authLogout = createAction('[Auth] Logout');

export const authLoginSuccess = createAction(
  '[Auth] Login Success',
  props<{ token: IApplicationToken }>()
);

export const authRefreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{ token: IApplicationToken }>()
);

export const authTwoFactorAuth = createAction(
  '[Auth] Two Factor Auth',
  props<{ token: IApplicationToken }>()
);

export const authTwoFactorAuthVerify = createAction(
  '[Auth] Two Factor Auth Veriffy',
  props<{ code: string }>()
);

export const authTwoFactorAuthVerifySuccess = createAction(
  '[Auth] Two Factor Auth Veriffy Success',
  props<{ token: IApplicationToken }>()
);

export const authTwoFactorAuthVerifyFailed = createAction(
  '[Auth] Two Factor Auth Veriffy Failed',
);

export const authTwoFactorAuthVerifyReSendCode= createAction(
  '[Auth] Two Factor Auth Veriffy Re Send Code',
);
export const authTwoFactorAuthVerifyReSendCodeSuccess= createAction(
  '[Auth] Two Factor Auth Veriffy Re Send Code Success',
);
export const authTwoFactorAuthVerifyReSendCodeFailed= createAction(
  '[Auth] Two Factor Auth Veriffy Re Send Code Failed',
);

export const authLoginFailed = createAction('[Auth] Login Failed');

export const authTokenCheck = createAction('[Auth] Check Token Expiration');

export const authTokenCheckSuccess = createAction('[Auth] Check Token Expiration Success');

export const authTokenExpired = createAction('[Auth] Login Expired');