import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IWebApiResponse } from 'app/core/response/response.model';
import { environment } from 'environments/environment';
import { IUpdateLeadProfileParams, LeadBuilding, AssignedUser, Tag } from '../../shared/models/lead-info-models';
import { TaskCreateParam, TaskUpdateParam } from 'app/shared/models/task.model';
import { LeadPreference } from 'app/shared/models/lead-preference.model';


@Injectable()
export class ClientDialogService {
  constructor(
    private http: HttpClient,
  ) { }

  requestUserCommunicationSettings(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getComSettings?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getUserRepresentativeCalendar(): Observable<IWebApiResponse> {
    return this.http
      .get(`${environment.apiBaseUrl}lead-detail/getUserRepresentativeCalendar`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getLeadSources(): Observable<IWebApiResponse> {
    return this.http
      .get(`${environment.apiBaseUrl}lead-detail/getLeadSources`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getUserRepresentativeAvailabilities(bookingDay: Date, calendarId: number): Observable<IWebApiResponse> {
    let formattedDate = bookingDay.toISOString();
    return this.http
      .get(`${environment.apiBaseUrl}lead-detail/getUserRepresentativeAvailabilities?bookingDay=${formattedDate}&calendarId=${calendarId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getCalendarBuildingAddress(calendarId: number): Observable<IWebApiResponse> {
    return this.http
      .get(`${environment.apiBaseUrl}lead-detail/GetCalendarBuildingAddress?calendarId=${calendarId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  createUserAppointment(form: any) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/createUserAppointment`,
      form
    )
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  requestLeadCommunicationSettings(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadCommunicationSettings?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateUserGeneralProfile(params: IUpdateLeadProfileParams) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/updateLeadBasicInfo`, params)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createLeadNote(id: number, note: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/addLeadNote`,
      {
        id: id,
        note: note
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createLeadTextMessage(id: number, text: string, sendFromId: string, sendTo: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/sendTextMessage`,
      {
        id: id,
        text: text,
        sendFromId: sendFromId,
        sendTo: sendTo
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }


  getLeadDetailInfo(id: number | string): Observable<IWebApiResponse> {
    return this.http
      .get(
        `${environment.apiBaseUrl}lead-detail/getLeadDetailInfoById?id=${id}`
      )
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getLeadTagOptions() {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadTagOptions`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadTags(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadTags?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadPreferences(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadInterests?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadReps(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadReps?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadRepOptions() {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadAssignedUserOptions?page=1&pageSize=50`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadBuildings(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadBuildings?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadBuildingOptions() {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadBuildingOptions?page=1&pageSize=50`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getActivitiesByLeadId(leadId: number, pageNo: number, pageSize: number, itemCountPage: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadActivities?id=${leadId}&pageNo=${pageNo}&pageSize=${pageSize}&itemCountPage=${itemCountPage}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteClientTag(leadId: number, tagId: number) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/removeLeadTag`, {
      leadId: leadId,
      tagId: tagId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteClientPreDefinedTag(leadId: number, tagId: number) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/removeLeadPreDefinedTag`, {
      leadId: leadId,
      tagId: tagId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteClientPreference(leadId: number, preferenceValue: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/removeLeadInterest`, {
      leadId: leadId,
      interestValue: preferenceValue
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteClientRep(leadId: number, repId: number) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/removeLeadAssignedUser`, {
      leadId: leadId,
      userId: repId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteClientBuilding(leadId: number, buildingId: number) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/removeLeadBuilding`, {
      leadId: leadId,
      buildingId: buildingId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateClientTags(id: number, tags: Tag[], preDefinedTags: Tag[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/updateLeadTags`, {
      id: id,
      tags: tags,
      preDefinedTags: preDefinedTags
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateClientPreferences(id: number, preferences: LeadPreference[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/updateLeadInterests`, {
      id: id,
      leadIntrests: preferences
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addBatchClientTags(leadIds: number[], tagIds: number[], preDefinedTagIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-query/BatchAddLeadTag`, {
      leadIds: leadIds,
      tagIds: tagIds,
      preDefinedTagIds: preDefinedTagIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  removeBatchClientTags(leadIds: number[], tagIds: number[], preDefinedTagIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-query/BatchRemoveLeadTag`, {
      leadIds: leadIds,
      tagIds: tagIds,
      preDefinedTagIds: preDefinedTagIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }
  addBatchClientReps(leadIds: number[], repIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-query/BatchAddLeadAssignation`, {
      leadIds: leadIds,
      userIds: repIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  removeBatchClientReps(leadIds: number[], repIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-query/BatchRemoveLeadAssignation`, {
      leadIds: leadIds,
      userIds: repIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateClientReps(id: number, reps: AssignedUser[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/updateLeadAssignedUsers`, {
      id: id,
      users: reps
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateClientBuildings(leadId: number, buildings: LeadBuilding[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/updateLeadBuildings`, {
      leadId: leadId,
      buildingIds: buildings.map(x => x.id)
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadsToMerge(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getDuplicateLeads?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  leadsMergeRequested(leadId: number, leadToMergeIds: string[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/mergeLeads`, {
      leadId: leadId,
      leadToMergeIds: leadToMergeIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createRentalApplication(form: any) {
    return this.http.post(`${environment.apiBaseUrl}rentalRequest/createRentalApplication`,
      form
    )
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getLeadsBySearchKeyWord(search: string, page: number, pageSize: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-query/GetLeadsByText?search=${search}&page=${page}&pageSize=${pageSize}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTasksByLeadId(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}user-task/getUserTasksByLeadId?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTaskAssignUserList() {
    return this.http.get(`${environment.apiBaseUrl}user-task/getTaskAssignUsers`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createTask(param: TaskCreateParam) {
    return this.http.post(`${environment.apiBaseUrl}user-task/createUserLeadTask`, param)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateTask(param: TaskUpdateParam) {
    return this.http.post(`${environment.apiBaseUrl}user-task/updateUserTask`, param)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteTask(taskId: number) {
    return this.http.post(`${environment.apiBaseUrl}user-task/deleteUserTask`, taskId)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  toggleUserTasKComnplateStatus(taskId: number) {
    return this.http.post(`${environment.apiBaseUrl}user-task/toggleUserTasKComnplateStatus`, taskId)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  phoneNumberBlock(leadId: number, phoneNumber: string, reason: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/phoneNumberBlock`,
      {
        leadId: leadId,
        phoneNumber: phoneNumber,
        reason: reason
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  phoneNumberUnBlock(leadId: number, phoneNumber: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/phoneNumberUnBlock`,
      {
        leadId: leadId,
        phoneNumber: phoneNumber,
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }
}
