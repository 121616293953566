import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionTenantDialogCommunicationSettingsRequested, tenantDialogActions } from '../tenant-dialog.actions';


import { State } from '../tenant-dialog.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { TenantInfo } from '../../../shared/models/tenant-info-models';
import { selectTenantDialogTopNavigationTab } from '../tenant-dialog.selector';
import {
  TenantDialogTenantNoteComponent
} from '../components/tenant-dialog-tenant-note/tenant-dialog-tenant-note/tenant-dialog-tenant-note.component';
import {
  actionTenantDialogUpdateTenantId,
  actionTenantDialogByTenantIdRequired
} from '../components/tenant-dialog-user-general/tenant-dialog-general.action';
import {
  selectTenantDialogTenantInfo,
  selectTenantDialogTenantInfoIsLoaded
} from '../components/tenant-dialog-user-general/tenant-dialog-general.selectors';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { AuthService } from 'app/core/auth/auth.service';
import { MatTabGroup } from '@angular/material/tabs';
import { TenantEmailMessage } from 'app/shared/models/tenant-communication.model';
import {
  actionTenantDialogEmailEditorIsCcAndBccActiveUpdate,
  actionTenantDialogEmailEditorStateReset,
  actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate,
  actionTenantDialogEmailTemplatesRequest,
  actionTenantDialogSendEmailReplyEditorIsForwardingUpdate,
  actionTenantDialogSendEmailReplyEditorReplyAllToUpdate,
  actionTenantDialogSendEmailReplyEditorUpdate
} from '../components/tenant-dialog-email-editor/tenant-dialog-email-editor.action';
import { EmailSendType } from 'app/shared/models/user-communication.model';

@Component({
  selector: 'dq-tenant-dialog',
  templateUrl: './tenant-dialog.component.html',
  styleUrls: ['./tenant-dialog.component.scss', 'tenant-dialog.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    public dialogRef: MatDialogRef<TenantDialogComponent>,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.tenantId) {
      this.store.dispatch(actionTenantDialogUpdateTenantId({ tenantId: data.tenantId }));
    }
  }

  addNoteDialogRef: MatDialogRef<TenantDialogTenantNoteComponent>;

  @ViewChild('topTabGroup', { static: false }) topTabGroup: MatTabGroup;
  @ViewChild('target', { static: false }) target: ElementRef;

  unsubscribe: Subject<void> = new Subject();

  tenantInfo$: Observable<TenantInfo>;
  tenantId: number;
  isTenantInfoLoaded$: Observable<boolean>;

  isMobile$: Observable<boolean>;
  isMobile: boolean;
  activeTab$: Observable<number>;
  userRoles: string[];
  selectedTabIndex: number;

  ngOnInit() {

    this.store.dispatch(tenantDialogActions.actionTenantDialogResetState());
    this.store.dispatch(tenantDialogActions.actionTenantDialogLogInUserEmailsAndPhoneNumbersRequest());

    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe)).subscribe(roles => {
      this.userRoles = roles;
    });

    if (this.data && this.data.tenantId) {
      const id: number = +this.data.tenantId;
      this.store.dispatch(actionTenantDialogByTenantIdRequired({ tenantId: id }));
    }

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile));
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });

    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo));
    this.tenantInfo$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantInfo => {
      if (tenantInfo.id > 0) {
        this.tenantId = tenantInfo.id;
        this.store.dispatch(actionTenantDialogCommunicationSettingsRequested({ tenantId: this.tenantId }));
        this.store.dispatch(actionTenantDialogEmailTemplatesRequest());
      }
    });

    this.isTenantInfoLoaded$ = this.store.pipe(select(selectTenantDialogTenantInfoIsLoaded));
    this.activeTab$ = this.store.pipe(select(selectTenantDialogTopNavigationTab));

  }

  handleSelectedTabChange(index: number): void {
    this.selectedTabIndex = index;
  }

  openAddNoteDialog(): void {
    this.addNoteDialogRef = this.dialog.open(TenantDialogTenantNoteComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false
    });

    this.addNoteDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
    });
  }

  hasAccessToEditAssignedUser(): Observable<boolean> {
    return this.authService.isUserInRole([SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager]);
  }

  refreshDialog() {
    this.store.dispatch(tenantDialogActions.actionTenantDialogResetState());
    this.store.dispatch(tenantDialogActions.actionTenantDialogLogInUserEmailsAndPhoneNumbersRequest());
    if (this.data && this.data.tenantId) {

      const id: number = +this.data.tenantId;
      this.store.dispatch(actionTenantDialogByTenantIdRequired({ tenantId: id }));
    }
  }

  close() {
    this.dialogRef.close();
  }

  onTopNavigate(tab: number) {
    if (tab <= 3) {
      this.store.dispatch(tenantDialogActions.actionTenantDialogTopNavigationActiveTabChange({ tab }));
    }
  }

  replyEmail(emailMessage: TenantEmailMessage) {
    this.topTabGroup.selectedIndex = 1;
    this.target.nativeElement.scrollTop = 0;
    const subject = emailMessage.subject.toLowerCase().startsWith('re') ? emailMessage.subject : 'RE: ' + emailMessage.subject;
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorUpdate({
      email: emailMessage.content,
      sendToCcs: [],
      sendToBccs: [],
      subject
    }));
    this.store.dispatch(actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate({
      sendType: EmailSendType.REPLY,
      externalIdentifier: emailMessage.externalIdentifier
    }));
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false }));
  }

  replyEmailAll(emailMessage: TenantEmailMessage) {
    this.topTabGroup.selectedIndex = 1;
    this.target.nativeElement.scrollTop = 0;
    const subject = emailMessage.subject.toLowerCase().startsWith('re') ? emailMessage.subject : 'RE: ' + emailMessage.subject;
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorUpdate({
      email: emailMessage.content,
      sendToCcs: emailMessage.ccs?.length > 0 ? emailMessage.ccs?.split(';') : [],
      sendToBccs: emailMessage.bccs?.length > 0 ? emailMessage.bccs?.split(';') : [],
      subject
    }));
    this.store.dispatch(actionTenantDialogEmailEditorIsCcAndBccActiveUpdate({ isActive: true }));
    this.store.dispatch(actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate({
      sendType: EmailSendType.REPLYALL,
      externalIdentifier: emailMessage.externalIdentifier
    }));
    const replyAllToEmails = emailMessage.to?.length > 0 ? emailMessage.to?.split(';') : [];
    replyAllToEmails.push(emailMessage.from);
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorReplyAllToUpdate({ replyAllTo: replyAllToEmails }));
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false }));
  }

  forwardEmail(emailMessage: TenantEmailMessage) {
    this.topTabGroup.selectedIndex = 1;
    this.target.nativeElement.scrollTop = 0;
    const subject = emailMessage.subject.toLowerCase().startsWith('fwd') ? emailMessage.subject : 'Fwd: ' + emailMessage.subject;
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorUpdate({
      email: '<p>---------- Forwarded message ---------</p>' + emailMessage.content,
      sendToCcs: [],
      sendToBccs: [],
      subject
    }));

    this.store.dispatch(actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate({
      sendType: EmailSendType.FORWARD,
      externalIdentifier: emailMessage.externalIdentifier
    }));
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: true }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(tenantDialogActions.actionTenantDialogResetState());
    this.store.dispatch(tenantDialogActions.actionTenantDialogBaseStateReset());
    this.store.dispatch(actionTenantDialogEmailEditorStateReset());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}

