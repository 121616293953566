import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import {
  actionScreenManagerUpdateMobileScreen, actionScreenManagerUpdateWindowSize
} from "./screen-manager.actions";
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { selectScreenManagerIsMobileSize } from "./screen-manager.selectors";

@Injectable()
export class ScreenManagerEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}


  requestAreaOptions$ = createEffect(() => this.actions$.pipe(
    ofType(actionScreenManagerUpdateWindowSize),
    withLatestFrom(
      this.store.select(selectScreenManagerIsMobileSize)
    ),
    filter(
      ([action, prevIsMobileSize]) =>
        prevIsMobileSize !== action.windowScreenInfo.windowWidthSize <= 768
    ),
    map(([action]) =>
      actionScreenManagerUpdateMobileScreen({
        isMobile: action.windowScreenInfo.windowWidthSize <= 768,
      })
    )
  ));
}
