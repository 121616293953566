import { createAction, props } from "@ngrx/store"
import { CommunicationTemplate } from "app/features/unified-inbox/unified-inbox.model";
import { EmailSendType } from "app/shared/models/user-communication.model";

export const actionClientDialogSendEmailRequest = createAction(
    '[Client Dialog Email] Send Email Request',
    props<{ leadId: number, externalIdentifier: string, emailType: EmailSendType, text: string, sendFromUserId: string, sendTo: string[], sendToCc: string[], sendToBcc: string[], subject: string, files: string[] }>()
);

export const actionClientDialogSendEmailSuccessed = createAction(
    '[Client Dialog Email] Send Email Successed',
);

export const actionClientDialogSendEmailFailed = createAction(
    '[Client Dialog Email] Send Email Failed',
);

export const actionClientDialogEmailEditorIsCcAndBccActiveUpdate = createAction(
    '[Client Dialog Email] Email Editor Is Cc And Bcc Active Update',
    props<{ isActive: boolean }>()
);

export const actionClientDialogSendEmailEditorReset = createAction(
    '[Client Dialog Email] Email Editor Reset',
);

export const actionClientDialogSendEmailReplyEditorIsForwardingUpdate = createAction(
    '[Client Dialog Email] Email Editor Reply Is Forwarding Update',
    props<{ isForwarding: boolean }>()
);

export const actionClientDialogSendEmailReplyEditorReplyAllToUpdate = createAction(
    '[Client Dialog Email] Email Editor Reply All To Update',
    props<{ replyAllTo: string[] }>()
);

export const actionClientDialogSendEmailReplyEditorReset = createAction(
    '[Client Dialog Email] Email Editor Reply Reset',
);

export const actionClientDialogSendEmailEditorUpdate = createAction(
    '[Client Dialog Email] Email Editor Update',
    props<{ email: string, sendFromUserId: number, sendToEmails: string[], sendToCcs: string[], sendToBccs: string[], subject: string, attachements: string[] }>()
);

export const actionClientDialogSendEmailReplyEditorUpdate = createAction(
    '[Client Dialog Email] Email Reply Editor Update',
    props<{ email: string, sendToCcs: string[], sendToBccs: string[], subject: string }>()
);

export const actionClientDialogSendEmailAndCollaborateRequest = createAction(
    '[Client Dialog Email] Send Email And Collaborate Request',
    props<{ leadId: number, externalIdentifier: string, emailType: EmailSendType, text: string, sendFromUserId: string, sendTo: string[], sendToCc: string[], sendToBcc: string[], subject: string, files: string[] }>()
);

export const actionClientDialogSendEmailAndCollaborateSuccessed = createAction(
    '[Client Dialog Email] Send Email And Collaborate Successed',
);

export const actionClientDialogSendEmailAndCollaborateFailed = createAction(
    '[Client Dialog Email] Send Email And Collaborate Failed',
);

export const actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate = createAction(
    '[Client Dialog Email] Sending Mail Type And External Identifier Update',
    props<{ sendType: EmailSendType, externalIdentifier: string }>()
)

export const actionClientDialogEmailTemplatesRequest = createAction(
    '[Client Dialog Email] Templates Request',
)

export const actionClientDialogEmailTemplatesLoaded = createAction(
    '[Client Dialog Email] Templates Loaded',
    props<{ templates: CommunicationTemplate[] }>()
)

export const actionClientDialogEmailTemplatesFalied = createAction(
    '[Client Dialog Email] Templates Failed',
)

export const actionClientDialogEmailTemplateSaveAndUpdate = createAction(
    '[Client Dialog Email] Eamil Template Save And Update',
    props<{ friendlyName: string, displayName: string, subject: string, content: string, isAccountShared: boolean, id?: number }>()
);

export const actionClientDialogEmailTemplateSaveAndUpdateSucceeded = createAction(
    '[Client Dialog Email] Eamil Template Save And Update Succeeded'
);

export const actionClientDialogEmailTemplateSaveAndUpdateFailed = createAction(
    '[Client Dialog Email] Eamil Template Save And Update Failed'
);

export const actionClientDialogEmailTemplateDeleteRequest = createAction(
    '[Client Dialog Email] Eamil Template Delete Request',
    props<{ id: number }>()
);

export const actionClientDialogEmailTemplateDeleteSucceeded = createAction(
    '[Client Dialog Email] Eamil Template Delete Succeeded'
);

export const actionClientDialogEmailTemplateDeleteFailed = createAction(
    '[Client Dialog Email] Eamil Template Delete Failed'
);

export const actionClientDialogEmailTemplateSaveAndUpdateStateReset = createAction(
    '[Client Dialog Email] Eamil Template Save And Update State Reset'
);

export const actionClientDialogEmailTemplateDeleteStateReset = createAction(
    '[Client Dialog Email] Eamil Template Delete State Reset'
);

export const actionClientDialogEmailEditorStateReset = createAction(
    '[Client Dialog Email] Email Editor State Reset',
);

export const actionClientDialogEmailEditorActions = {
    actionClientDialogSendEmailRequest,
    actionClientDialogSendEmailSuccessed,
    actionClientDialogSendEmailFailed,
    actionClientDialogSendEmailReplyEditorIsForwardingUpdate,
    actionClientDialogSendEmailReplyEditorReplyAllToUpdate,
    actionClientDialogEmailEditorIsCcAndBccActiveUpdate,
    actionClientDialogSendEmailEditorUpdate,
    actionClientDialogSendEmailReplyEditorUpdate,
    actionClientDialogSendEmailEditorReset,
    actionClientDialogSendEmailReplyEditorReset,
    actionClientDialogSendEmailAndCollaborateRequest,
    actionClientDialogSendEmailAndCollaborateSuccessed,
    actionClientDialogSendEmailAndCollaborateFailed,
    actionClientDialogEmailTemplatesRequest,
    actionClientDialogEmailTemplatesLoaded,
    actionClientDialogEmailTemplatesFalied,
    actionClientDialogEmailTemplateSaveAndUpdate,
    actionClientDialogEmailTemplateSaveAndUpdateSucceeded,
    actionClientDialogEmailTemplateSaveAndUpdateFailed,
    actionClientDialogEmailTemplateDeleteRequest,
    actionClientDialogEmailTemplateDeleteSucceeded,
    actionClientDialogEmailTemplateDeleteFailed,
    actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate,
    actionClientDialogEmailTemplateDeleteStateReset,
    actionClientDialogEmailTemplateSaveAndUpdateStateReset,
    actionClientDialogEmailEditorStateReset
}
