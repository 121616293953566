import { createAction, props } from "@ngrx/store";
import { Tag } from "../../../../shared/models/tenant-info-models";

export const actionTenantDialogTenantTagsRequested = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tenant Tags Requested',
    props<{ tenantId: number }>()
)

export const actionTenantDialogTenantTagsLoaded = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tenant Tags Loaded',
    props<{ tags: Tag[] }>()
)

export const actionTenantDialogTenantTagsFailed = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tenant Tags Failed'
)

export const actionTenantDialogTagOptionsRequested = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tag Options Requested',
)

export const actionTenantDialogTagOptionsLoaded = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tag Options Loaded',
    props<{ tags: Tag[] }>()
)

export const actionTenantDialogTagsUpdate = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Update ',
    props<{ tags: Tag[] }>()
);

export const actionTenantDialogTagsTagIsCheckedUpdate = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Tag Is Checked Update ',
    props<{ checked: boolean, tag: Tag }>()
);

export const actionTenantDialogTagOptionsCheckStateInitialize = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tag Options Check State Initalize',
)

export const actionTenantDialogTagOptionsCheckStateUnCheckAll = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tag Options Check State Un Check All',
)

export const actionTenantDialogTagOptionsFailed = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tag Options Failed',
)

export const actionTenantDialogTagsUpdateRequested = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Update Requested',
    props<{ id: number, tags: Tag[] }>()
)

export const actionTenantDialogTagsUpdateSuccessfully = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Update Successfully',
    props<{ tags: Tag[] }>()
)

export const actionTenantDialogTagsUpdateFailed = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Update Failed',
)

export const actionTenantDialogBatchTagsAddRequested = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Batch Tags Add Requested',
    props<{ tenantIds: number[], tagIds: number[] }>()
)

export const actionTenantDialogBatchTagsAddSuccessfully = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Batch Tags Add Successfully',
)

export const actionTenantDialogBatchTagsAddFailed = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Batch Tags Add Failed',
)

export const actionTenantDialogBatchTagsRemoveRequested = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Batch Remove Add Requested',
    props<{ tenantIds: number[], tagIds: number[] }>()
)

export const actionTenantDialogBatchTagsRemoveSuccessfully = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Batch Tags Remove Successfully',
)

export const actionTenantDialogBatchTagsRemoveFailed = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Batch Tags Remove Failed',
)

export const actionTenantDialogTenantTagDeleteRequested = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tenant Tag Delete Requested',
    props<{ tenantId: number, tagId: number }>()
)

export const actionTenantDialogTenantTagDeleteSuccessfully = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tenant Tag Delete Successfully',
    props<{ tags: Tag[] }>()
)

export const actionTenantDialogTenantTagDeleteFailed = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tenant Tag Delete Failed',
)

export const actionTenantDialogTagsCreateUpdateSuccessfulStateReset = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Create Update Successfully State Reset'
)

export const actionTenantDialogTagsTagOptionsDialogStateReset = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags Tag Options Dialog State Reset',
)

export const actionTenantDialogTagsReset = createAction(
    '[Tenant Dialog Tags] Tenant Dialog Tags  Reset',
)


export const tenantDialogTagActions = {
    actionTenantDialogTenantTagsRequested: actionTenantDialogTenantTagsRequested,
    actionTenantDialogTenantTagsLoaded: actionTenantDialogTenantTagsLoaded,
    actionTenantDialogTenantTagsFailed: actionTenantDialogTenantTagsFailed,
    actionTenantDialogTagOptionsRequested: actionTenantDialogTagOptionsRequested,
    actionTenantDialogTagOptionsLoaded: actionTenantDialogTagOptionsLoaded,
    actionTenantDialogTagsUpdate: actionTenantDialogTagsUpdate,
    actionTenantDialogTagsTagIsCheckedUpdate: actionTenantDialogTagsTagIsCheckedUpdate,
    actionTenantDialogTagsUpdateRequested: actionTenantDialogTagsUpdateRequested,
    actionTenantDialogTagsUpdateSuccessfully: actionTenantDialogTagsUpdateSuccessfully,
    actionTenantDialogTenantTagDeleteRequested: actionTenantDialogTenantTagDeleteRequested,
    actionTenantDialogTenantTagDeleteSuccessfully: actionTenantDialogTenantTagDeleteSuccessfully,
    actionTenantDialogTagOptionsCheckStateInitialize: actionTenantDialogTagOptionsCheckStateInitialize,
    actionTenantDialogTagOptionsCheckStateUnCheckAll: actionTenantDialogTagOptionsCheckStateUnCheckAll,
    actionTenantDialogTagOptionsFailed: actionTenantDialogTagOptionsFailed,
    actionTenantDialogTagsUpdateFailed: actionTenantDialogTagsUpdateFailed,
    actionTenantDialogTenantTagDeleteFailed: actionTenantDialogTenantTagDeleteFailed,
    actionTenantDialogBatchTagsAddRequested: actionTenantDialogBatchTagsAddRequested,
    actionTenantDialogBatchTagsAddSuccessfully: actionTenantDialogBatchTagsAddSuccessfully,
    actionTenantDialogBatchTagsAddFailed: actionTenantDialogBatchTagsAddFailed,
    actionTenantDialogBatchTagsRemoveRequested: actionTenantDialogBatchTagsRemoveRequested,
    actionTenantDialogBatchTagsRemoveSuccessfully: actionTenantDialogBatchTagsRemoveSuccessfully,
    actionTenantDialogBatchTagsRemoveFailed: actionTenantDialogBatchTagsRemoveFailed,
    actionTenantDialogTagsCreateUpdateSuccessfulStateReset: actionTenantDialogTagsCreateUpdateSuccessfulStateReset,
    actionTenantDialogTagsTagOptionsDialogStateReset: actionTenantDialogTagsTagOptionsDialogStateReset,
    actionTenantDialogTagsReset: actionTenantDialogTagsReset,
}