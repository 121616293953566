import { createAction, props } from "@ngrx/store";
import { AssignedUser } from "../../../../shared/models/lead-info-models";

export const actionClientDialogLeadRepsRequested = createAction(
    '[Client Dialog Reps] Client Dialog Lead Reps Requested',
    props<{ leadId: number }>()
)

export const actionClientDialogLeadRepsLoaded = createAction(
    '[Client Dialog Reps] Client Dialog Lead Reps Loaded',
    props<{ reps: AssignedUser[] }>()
)

export const actionClientDialogLeadRepsFailed = createAction(
    '[Client Dialog Reps] Client Dialog Lead Reps Failed'
)

export const actionClientDialogRepOptionsRequested = createAction(
    '[Client Dialog Reps] Client Dialog Rep Options Requested',
)

export const actionClientDialogRepOptionsLoaded = createAction(
    '[Client Dialog Reps] Client Dialog Rep Options Loaded',
    props<{ reps: AssignedUser[] }>()
)


export const actionClientDialogRepsUpdate = createAction(
    '[Client Dialog Reps] Client Dialog Reps Update ',
    props<{ reps: AssignedUser[] }>()
);


export const actionClientDialogRepsRepIsCheckedUpdate = createAction(
    '[Client Dialog Reps] Client Dialog Reps Rep Is Checked Update ',
    props<{ checked: boolean, rep: AssignedUser }>()
);


export const actionClientDialogRepOptionsCheckStateInitialize = createAction(
    '[Client Dialog Reps] Client Dialog Rep Options Check State Initalize',
)


export const actionClientDialogClientRepsFailed = createAction(
    '[Client Dialog Reps] Client Dialog Reps Failed',
)


export const actionClientDialogRepOptionsFailed = createAction(
    '[Client Dialog Reps] Client Dialog Rep Options Failed',
)


export const actionClientDialogRepsUpdateRequested = createAction(
    '[Client Dialog Reps] Client Dialog Reps Update Requested',
    props<{ id: number, reps: AssignedUser[] }>()
)

export const actionClientDialogRepsUpdateSuccessfully = createAction(
    '[Client Dialog Reps] Client Dialog Reps Update Successfully',
    props<{ leadId: number, reps: AssignedUser[] }>()
)

export const actionClientDialogRepsUpdateFailed = createAction(
    '[Client Dialog Reps] Client Dialog Reps Update Failed',
)

export const actionClientDialogBatchRepsAddRequested = createAction(
    '[Client Dialog Reps] Client Dialog Batch Reps Add Requested',
    props<{ leadIds: number[], repIds: number[] }>()
)

export const actionClientDialogBatchRepsAddSuccessfully = createAction(
    '[Client Dialog Reps] Client Dialog Batch Reps Add Successfully',
)

export const actionClientDialogBatchRepsAddFailed = createAction(
    '[Client Dialog Reps] Client Dialog Batch Reps Add Failed',
)

export const actionClientDialogBatchRepsRemoveRequested = createAction(
    '[Client Dialog Reps] Client Dialog Batch Reps Remove Requested',
    props<{ leadIds: number[], repIds: number[] }>()
)

export const actionClientDialogBatchRepsRemoveSuccessfully = createAction(
    '[Client Dialog Reps] Client Dialog Batch Reps Remove Successfully',
)

export const actionClientDialogBatchRepsRemoveFailed = createAction(
    '[Client Dialog Reps] Client Dialog Batch Reps Remove Failed',
)

export const actionClientDialogClientRepDeleteRequested = createAction(
    '[Client Dialog TRepsags] Client Dialog Cilent Rep Delete Requested',
    props<{ leadId: number, repId: number }>()
)

export const actionClientDialogRepsCreateUpdateSuccessfulStateReset = createAction(
    '[Client Dialog Reps] Client Dialog Reps Create Update Successfully State Reset'
)

export const actionClientDialogClientRepDeleteSuccessfully = createAction(
    '[Client Dialog Reps] Client Dialog Client Rep Delete Successfully',
    props<{ leadId: number, reps: AssignedUser[] }>()
)

export const actionClientDialogClientRepDeleteFailed = createAction(
    '[Client Dialog Reps] Client Dialog Client Rep Delete Failed',
)

export const actionClientDialogRepsReset = createAction(
    '[Client Dialog Reps] Client Dialog Reps  Reset',
)

export const clientDialogRepActions = {
    actionClientDialogLeadRepsRequested,
    actionClientDialogLeadRepsLoaded,
    actionClientDialogLeadRepsFailed,
    actionClientDialogRepOptionsFailed,
    actionClientDialogClientRepsFailed,
    actionClientDialogRepsUpdateFailed,
    actionClientDialogClientRepDeleteFailed,
    actionClientDialogBatchRepsAddRequested,
    actionClientDialogBatchRepsAddSuccessfully,
    actionClientDialogBatchRepsAddFailed,
    actionClientDialogBatchRepsRemoveRequested,
    actionClientDialogBatchRepsRemoveSuccessfully,
    actionClientDialogBatchRepsRemoveFailed,
    actionClientDialogRepOptionsRequested,
    actionClientDialogRepOptionsLoaded,
    actionClientDialogRepsUpdate,
    actionClientDialogRepsRepIsCheckedUpdate,
    actionClientDialogRepsUpdateRequested,
    actionClientDialogRepsUpdateSuccessfully,
    actionClientDialogClientRepDeleteRequested,
    actionClientDialogClientRepDeleteSuccessfully,
    actionClientDialogRepOptionsCheckStateInitialize,
    actionClientDialogRepsCreateUpdateSuccessfulStateReset,
    actionClientDialogRepsReset,
}