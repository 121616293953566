import { createAction, props } from "@ngrx/store";
import { AiConversation, AiConversationBuilding } from "../../ai-simulation.model";

export const actionAiSimulationChatSelectedConversationUpdate = createAction(
    '[AI Simulation] Chat Selected Conversation Update',
    props<{ conversationId: string }>()
);

export const actionAiSimulationChatAddNewConversationRequested = createAction(
    '[AI Simulation] Chat Add New Conversation Requested',
    props<{ conversationName: string, buildingId: number }>()
);

export const actionAiSimulationChatAddNewConversationSucceed = createAction(
    '[AI Simulation] Chat Add New Conversation Succeed',
    props<{ conversationId: string, conversationName: string }>()
);

export const actionAiSimulationChatAddNewConversationFailed = createAction(
    '[AI Simulation] Chat Add New Conversation Failed',
);

export const actionAiSimulationChatMessageSendRequested = createAction(
    '[AI Simulation] Chat Message Send Requested',
    props<{ message: string, conversationId: string }>()
);

export const actionAiSimulationChatMessageSendSucceed = createAction(
    '[AI Simulation] Chat Message Send Succeed',
);

export const actionAiSimulationChatMessageSendFailed = createAction(
    '[AI Simulation] Chat Message Send Failed',
);

export const actionAiSimulationChatMessageReceived = createAction(
    '[AI Simulation] Chat Message Received',
    props<{ message: string, conversationId: string }>()
);

export const actionAiSimulationBuildingOptionsRequested = createAction(
    '[AI Simulation] Building Options Requested',
)

export const actionAiSimulationBuildingOptionsLoaded = createAction(
    '[AI Simulation] Building Options Loaded',
    props<{ buildings: AiConversationBuilding[] }>()
)

export const actionAiSimulationBuildingOptionsFailed = createAction(
    '[AI Simulation] Building Options Failed',
)

export const actionAiSimulationChatAllConversationRequested = createAction(
    '[AI Simulation] Chat All Conversation Requested',
);

export const actionAiSimulationChatAllConversationLoaded = createAction(
    '[AI Simulation] Chat All Conversation Loaded',
    props<{ conversations: AiConversation[]}>()
);

export const actionAiSimulationChatAllConversationFailed = createAction(
    '[AI Simulation] Chat All Conversation Failed',
);

export const actionAiSimulationChatStateReset = createAction(
    '[AI Simulation] Chat Message State Reset',
);