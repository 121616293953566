import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { CoreModule } from './core/core.module';
import { CallSettingsComponent } from './features/call-settings/call-settings/call-settings.component';
import {
  OnGoingDialogPadComponent
} from './features/call-settings/on-going-call-handler/components/dialog-pad/on-going-dialog-pad.component';
import {
  OnGoingCallHandlerComponent
} from './features/call-settings/on-going-call-handler/on-going-call-handler.component';
import { ClientDialogModule } from './features/client-dialog/client-dialog.module';
import { LandingComponent } from './features/landing/landing.component';
import { NotificationModule } from './features/notification/notification.module';
import { StaticModule } from './features/static/static.module';
import { SharedModule } from './shared/shared.module';
import { provideClarity } from 'ngx-clarity';
import { environment } from 'environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TenantDialogModule } from './features/tenant-dialog/tenant-dialog.module';

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    MatExpansionModule,
    SharedModule,
    StaticModule,
    // core
    CoreModule,

    // app
    AppRoutingModule,

    // Internal Module
    ClientDialogModule,
    TenantDialogModule,
    NotificationModule
  ],
  providers: [
    provideClarity({
      enabled: environment.clarity.enabled,
      projectId: environment.clarity.projectId
    })
  ],
  declarations: [
    AppComponent,
    LandingComponent,
    CallSettingsComponent,
    OnGoingCallHandlerComponent,
    OnGoingDialogPadComponent
  ],
  exports: [
    NgbModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
