import { createSelector } from "@ngrx/store";
import { selectClientDialog, ClientDialogState, ClientDialogLeadNoteState } from "../../client-dialog.state";

export const selectClientDialogLeadNoteState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogLeadNote
);

export const selectIsNoteSubmitting = createSelector(
    selectClientDialogLeadNoteState,
    (state: ClientDialogLeadNoteState) => state.isNoteSubmitting
);

export const selectIsNoteSubmittedSuccessfully = createSelector(
    selectClientDialogLeadNoteState,
    (state: ClientDialogLeadNoteState) => state.isNoteSubmittedSuccessfully
);
