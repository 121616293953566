import { createSelector } from "@ngrx/store";
import { ClientDialogState, ClientDialogTextMessageState, selectClientDialog } from "../../client-dialog.state";
export const selectClientDialogTextMessageState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => state.clientDialogTextMessage
);

export const selectIsTextMessageSubmitting = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTextMessageSubmitting
);

export const selectIsTextMessageSubmittedSuccessfully = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTextMessageSubmittedSuccessfully
);

export const selectSendFromPhoneNumbers = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.sendFromPhoneNumbers
);

export const selectTextMessagesTextEditor = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.textMessageEditor
);

export const selectTextMessagesTemplates = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.textMessageTemplates
);

export const selectTextMessagesIsTemplatesLoaded = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTemplatesLoaded
);

export const selectTextMessagesIsTemplateSaveOrUpdating = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTemplateSaveOrUpdateing
);

export const selectTextMessagesIsTemplateSaveOrUpdatedSuccessfully = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTemplateSaveOrUpdateSuccessfully
);

export const selectTextMessagesIsTemplateDeleting = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTemplateDeleting
);

export const selectTextMessagesIsTemplateDeletedSuccessfully = createSelector(
  selectClientDialogTextMessageState,
  (state: ClientDialogTextMessageState) => state.isTemplateDeletedSuccessfully
);
