<div class="zoom-documentation-component">
    <div class="zoom-documentation-container">
        <h1>
            Zoom Documentation
        </h1>
        <div style="margin-bottom: 20px;">
            Last updated July 22, 2021
        </div>
        <h3>
            Installation
        </h3>
        <p>From the left Tab, go to (Profile > Account Integration) and and click on "Sign in With Zoom"</p>
        <h3>
            Usage
        </h3>
        <p>DashQ Inside Sales Agent will book appointments on your calendar. This will trigger the creation of a Zoom meeting on your behalf. The same way if an ISA cancel an appointment, this will automatically cancel the zoom meeting on your behalf.</p>
        <h3>
            Uninstallation
        </h3>
        <p>
            Login to your Zoom Account and navigate to the Zoom App Marketplace.<br/>
            Click Manage > Installed Apps or search for DashQ for Zoom.<br/>
            Click the DashQ for Zoom.<br/>
            Click Uninstall.
        </p>
    </div>
</div>