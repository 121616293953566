import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadInfo, AssignedUser } from 'app/shared/models/lead-info-models';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectClientLeadId, selectClientLeadInfo } from '../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogLeadRepsRequested, actionClientDialogRepsCreateUpdateSuccessfulStateReset, actionClientDialogClientRepDeleteRequested, actionClientDialogRepsReset } from '../client-dialog-lead-rep.action';
import { selectclientDialogRepsStateClientReps, selectclientDialogRepsStateIsRepsLoaded } from '../client-dialog-lead-rep.selector';
import { ClientDialogUserGeneralRepsComponent } from '../components/client-dialog-user-general-reps/client-dialog-user-general-reps.component';
import { MultiPurposeDialogComponent } from 'app/shared/components/multi-purpose-dialog/multi-purpose-dialog.component';

@Component({
  selector: 'dq-client-dialog-lead-rep',
  templateUrl: './client-dialog-lead-rep.component.html',
  styleUrls: ['./client-dialog-lead-rep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogLeadRepComponent implements OnInit {

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) { }

  unsubscribe: Subject<void> = new Subject();
  isMobile$: Observable<boolean>
  isMobile: boolean
  leadInfo$: Observable<LeadInfo>
  leadId$: Observable<number>
  leadId: number
  isRepsLoaded$: Observable<boolean>
  clientReps$: Observable<AssignedUser[]>
  leadRepsEditorDialogRef: MatDialogRef<MultiPurposeDialogComponent>;

  ngOnInit(): void {
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo))
    this.leadId$ = this.store.pipe(select(selectClientLeadId))
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId
      if (leadId > 0) {
        this.store.dispatch(actionClientDialogLeadRepsRequested({ leadId: leadId }))
      }
    })
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })
    this.clientReps$ = this.store.pipe(select(selectclientDialogRepsStateClientReps));
    this.isRepsLoaded$ = this.store.pipe(select(selectclientDialogRepsStateIsRepsLoaded));
  }

  openRepAddEditDialogEdit(rep?: AssignedUser): void {
    this.leadRepsEditorDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: {
        title: 'Assign Representative',
        component: ClientDialogUserGeneralRepsComponent,
        dialogData: {
        }
      }
    });

    this.leadRepsEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.store.dispatch(actionClientDialogRepsCreateUpdateSuccessfulStateReset())
    });
  }

  deleteRep(repId: number) {
    if (this.leadId > 0) {
      this.store.dispatch(actionClientDialogClientRepDeleteRequested({ leadId: this.leadId, repId: repId }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionClientDialogRepsReset())
  }
}
