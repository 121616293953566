import { actionSettingsChangeTheme } from '../core/settings/settings.actions';
import browser from 'browser-detect';
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';

import { environment as env } from '../../environments/environment';

import {
  authLogout,
  LocalStorageService,
  routeAnimations,
  selectEffectiveTheme,
  selectIsAuthenticated,
  selectSettingsLanguage,
  selectSettingsStickyHeader,
  SignalRService
} from '../core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '../core/settings/settings.actions';
import { MatSidenavContainer, MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
import { IProfile } from '../core/auth/auth.models';
import { selectIsNotExpired, selectProfile, selectToken } from '../core/auth/auth.selectors';
import { NAVIGATION, NavItem } from './app.model';
import { UI_SIZES } from '../core/ui/ui.model';
import { actionWindowWidthUpsert } from '../core/ui/ui.actions';
import { CustomIconService } from '../core/custom-icon/custom-icon.service';
import { selectWindowWidth } from '../core/ui/ui.selectors';
import { selectUserName, selectUserRoles } from '../core/auth/auth.selectors';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { actionScreenManagerUpdateWindowSize } from 'app/core/screen-manager/screen-manager.actions';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { selectconnectionState, selectTaskNotificationCount, selectUserLeadConverationNotificationCount, selectUserTenantConverationNotificationCount } from 'app/core/settings/settings.selectors';
import { selectCallManagerIsDeviceRegistered, selectRegisteredDeviceToken } from 'app/core/call-manager/call-manager.selectors';
import { authTokenCheck } from 'app/core/auth/auth.actions';
import { selectNotificationNotificationCount } from 'app/core/notification-manager/notification-manager.selectors';
import Intercom from '@intercom/messenger-js-sdk';

declare let window: any;

@Component({
  selector: 'dq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  constructor(
    private store: Store,
    private customIconService: CustomIconService,
    private storageService: LocalStorageService,
    private signalRService: SignalRService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.customIconService.init();
  }


  private mouseEnterTimer: NodeJS.Timeout;
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  isToggledSidenav = true;
  hasFooter$: Observable<boolean>;

  languages = ['en', 'fr'];
  themes = ['DEFAULT-THEME', 'DARK-THEME'];

  navigationSideMenu: NavItem[];

  sizes = UI_SIZES;
  width: number;

  isAuthenticated$: Observable<boolean>;
  isNotExpired$: Observable<boolean>;
  connectionState$: Observable<string>;

  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;
  url$: Observable<string>;
  username$: Observable<string>;
  profile$: Observable<IProfile>;
  taskNotificationCount$: Observable<number>
  userLeadConversationNotificationCount$: Observable<number>
  userTenantConversationNotificationCount$: Observable<number>
  width$: Observable<number>;

  tokenForCall$: Observable<string | undefined> | undefined;
  isCallDeviceRegistered$: Observable<boolean>;
  callPanelOpen = false;

  isMobile$: Observable<boolean>
  isMobile: boolean

  cdkLogo = '../../assets/logo-glasses.png';
  dashQLogo = '../../assets/dashq-symbol.png';
  dashQFullLogo = '../../assets/dashq-black.svg';

  checkRefreshTokenInterval: NodeJS.Timeout;
  isTokenGoingToExpire$: Observable<boolean>;
  isTokenGoingToExpire: boolean;

  notificationCount$: Observable<number>;

  theme: string;

  @ViewChild('innerSidenavContainer')
  innerSidenavContainer: MatSidenavContainer;
  @ViewChild('innerSidenav') innerSidenav: MatSidenav;

  unsubscribe: Subject<void> = new Subject();
  autosize = true

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.width = window.innerWidth;
    if (this.width < this.sizes.lg) this.isToggledSidenav = true;
    this.store.dispatch(actionWindowWidthUpsert({ width: window.innerWidth }));

    if (isPlatformBrowser(this.platformId)) {
      this.store.dispatch(actionScreenManagerUpdateWindowSize({
        windowScreenInfo:
        {
          windowWidthSize: window.innerWidth,
          windowHeightSize: window.innerHeight,
        }
      }));
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`); // See: https://dev.to/frehner/css-vh-dvh-lvh-svh-and-vw-units-27k4
    }
  }

  ngOnInit(): void {
    this.store.pipe(select(selectProfile), takeUntil(this.unsubscribe)).subscribe(profile => {
      if (profile != undefined && typeof window.clarity === 'function') {
        window.clarity('identify', profile.userName);
      }
    });

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })

    this.notificationCount$ = this.store.pipe(select(selectNotificationNotificationCount));

    // Then we set the value in the --vh custom property to the root of the document
    if (isPlatformBrowser(this.platformId)) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.store.dispatch(actionScreenManagerUpdateWindowSize({
        windowScreenInfo:
        {
          windowWidthSize: window.innerWidth,
          windowHeightSize: window.innerHeight,
        }
      }));
      if (AppComponent.isIEorEdgeOrSafari()) {
        this.store.dispatch(actionSettingsChangeAnimationsPageDisabled({ pageAnimationsDisabled: true }));
      }
    }
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.width = window.innerWidth;
    this.store.dispatch(actionWindowWidthUpsert({ width: window.innerWidth }));
    this.width$ = this.store.pipe(select(selectWindowWidth));

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.isNotExpired$ = this.store.pipe(select(selectIsNotExpired));
    this.connectionState$ = this.store.pipe(select(selectconnectionState));

    this.tokenForCall$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectRegisteredDeviceToken));
    this.isCallDeviceRegistered$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsDeviceRegistered));

    this.isAuthenticated$.pipe(takeUntil(this.unsubscribe)).subscribe(isAuthenticated => {
      if (isAuthenticated === true) {
        clearInterval(this.checkRefreshTokenInterval);
        this.checkRefreshTokenInterval = setInterval(() => {
          this.store.dispatch(authTokenCheck());
        }, 30000);
      }
      else {
        clearInterval(this.checkRefreshTokenInterval);
      }
    });

    combineLatest([this.isAuthenticated$, this.store.pipe(select(selectToken))]).pipe(takeUntil(this.unsubscribe)).subscribe(([isAuthenticated, token]) => {
      if (isAuthenticated && token != undefined) {
        this.signalRService.startConnection(token);
        this.signalRService.turnOnTaskNotifyListener();
        this.signalRService.turnOnConversationCountNotifyListener();
        this.signalRService.turnOnTenantConversationCountNotifyListener();
      }
    })

    combineLatest([this.isAuthenticated$, this.store.pipe(select(selectProfile))]).pipe(takeUntil(this.unsubscribe)).subscribe(([isAuthenticated, profile]) => {
      if (isAuthenticated && profile != undefined && env.production == true) {
        Intercom({
          app_id: env.intercom.appId, // Your Intercom App ID
          id: profile.userName,
          name: `${profile.firstName} ${profile.lastName}`,
          email: profile.userName,
          user_hash : profile.intercomUser,
          createdAt: Math.floor(Date.now() / 1000) // Unix Timestamp
        });
      }
    })

    // this.hasFooter$ = this.store.pipe(select(selectHasFooter));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    this.username$ = this.store.pipe(select(selectUserName));
    this.profile$ = this.store.pipe(select(selectProfile));
    this.taskNotificationCount$ = this.store.pipe(select(selectTaskNotificationCount));
    this.userLeadConversationNotificationCount$ = this.store.pipe(select(selectUserLeadConverationNotificationCount));
    this.userTenantConversationNotificationCount$ = this.store.pipe(select(selectUserTenantConverationNotificationCount));
    // this.url$ = this.store.pipe(select(selectRouterUrl));

    this.profile$.pipe(takeUntil(this.unsubscribe)).subscribe(profile => {
      console.log('profile', profile);
    });

    this.theme$.subscribe(theme => {
      this.theme = theme;
    });

    this.store.pipe(select(selectScreenManagerIsMobile), takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.autosize = !isMobile
    })

    combineLatest([
      this.isMobile$,
      this.store.pipe(select(selectUserRoles))
    ])
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(([isMobile, roles ]) => {
      if (roles && roles.length > 0) {
        this.navigationSideMenu = this.filterNavigation(NAVIGATION, roles, isMobile, this.router.url);
      }
    });
  }

  filterNavigation(navItems: NavItem[], roles: string[], isMobile: boolean, activeLink?: string): NavItem[] {
    const result: NavItem[] = [];

    for (const item of navItems) {
      // Check if the current item should be included
      if ((!item.isLeaf || roles.some(role => item.roles.includes(role))) && isMobile === item.isForMobile ) {
        const newItem = { ...item };  // Create a shallow copy

        // Recursively filter the children
        newItem.children = this.filterNavigation(newItem.children, roles, isMobile, activeLink);

        // If it's not a leaf and ends up with only one child, make that child a leaf.
        if (!newItem.isLeaf && newItem.children.length === 1) {
          const singleChild = { ...newItem.children[0] };  // Shallow copy of the single child
          singleChild.isLeaf = true;
          result.push(singleChild);
        } else if (newItem.isLeaf || newItem.children.length >= 2) {
          // Otherwise, include it as is.
          result.push(newItem);
        }
        // If it's not a leaf but ends up with zero children, we simply don't include it.
      }
    }
    return result;
  }

  checkIfActive(children: NavItem[]) {
    let route;
    children.forEach(child => {
      if (this.router.url.includes(child.link)) {
        route = child.link;
      }
    });
    return route;
  }

  toggleInnerSidenav() {
    if (this.isMobile) {
      this.isToggledSidenav = !this.isToggledSidenav
    }
  }
  onProfileClick() {
    // this.store.dispatch(authLogin());
    this.router.navigate(['user-profile']);
  }

  onLogoutClick() {
    this.store.dispatch(authLogout());
    this.callPanelOpen = false;
    if (typeof window.Intercom === 'function') {
      window.Intercom('shutdown');
    }
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }

  onLanguageClick({ target: { value: language } }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }

  onThemeClick() {
    if (this.theme.toLowerCase() === 'default-theme'.toLowerCase()) {
      this.store.dispatch(actionSettingsChangeTheme({ theme: 'DARK-THEME' }));
    } else {
      this.store.dispatch(actionSettingsChangeTheme({ theme: 'DEFAULT-THEME' }));
    }
  }

  isLightTheme(): boolean {
    return this.theme.toLowerCase() === 'default-theme'.toLowerCase();
  }

  isSideNavSmall() {
    return this.isToggledSidenav;
  }

  startDelay() {
    this.mouseEnterTimer = setTimeout(() => {
      this.isToggledSidenav = false;
      this.cdr.markForCheck();
    }, 500); // 500ms delay
  }

  cancelDelay() {
    if (this.mouseEnterTimer) {
      clearTimeout(this.mouseEnterTimer);
    }
    this.cdr.markForCheck();

    this.isToggledSidenav = true;
  }

  toggleCallPanelOpen() {
    this.callPanelOpen = !this.callPanelOpen;
  }

  ngOnDestroy() {
    clearInterval(this.checkRefreshTokenInterval);
  }
}
