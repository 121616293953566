import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }
  init() {
    // Custom
    this.matIconRegistry.addSvgIcon(
      'account',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/account.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'assessment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/assessment.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bbq',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/bbq.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'signature',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/signature.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'board-room',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/board-room.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'car-wash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/car-wash.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'elevator',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/elevator.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cherry',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/cherry.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'event-room',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/event-room.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'patio',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/patio.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rooftop-terrace',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/rooftop-terrace.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cdk-glasses',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/cdk-glasses.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'heart',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/heart.svg'
      )
    );

    // Font Awesome
    this.matIconRegistry.addSvgIcon(
      'assignment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/assignment.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bed',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/bed.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chart-line',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/chart-line.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'paper-plane',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/paper-plane.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'queue',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/queue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'crane',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/crane.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rectangle-history-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/rectangle-history-circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chalkboard-user',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/chalkboard-user.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/user-check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'clone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/clone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user-tie',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/user-tie.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/user-plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calendar-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/calendar-plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'angle-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/angle-up.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'browser',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/browser.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/cancel.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rocket-solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/rocket-solid.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'dollar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/dollar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'filter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/filter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'building',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/building.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'history',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/history.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'car',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/car.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'dumbbell',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/dumbbell.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'facebook-f',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/facebook-f.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/google.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'map-marker-alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/map-marker-alt.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'shower',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/shower.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sign',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sign.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'glass-cheers',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/glass-cheers.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bicycle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/bicycle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'charging-station',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/charging-station.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'concierge-bell',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/concierge-bell.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'campfire',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/campfire.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'fire',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/fire.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'guest-suite',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/guest-suite.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'lounge',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/lounge.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'projector',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/projector.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'dog',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/dog.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'swimming-pool',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/swimming-pool.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'racquet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/racquet.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user-shield',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/user-shield.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/user.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'hot-tub',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/hot-tub.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'hand-point-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/hand-point-right.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'boxes-alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/boxes-alt.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'parking',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/parking.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bell',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/bell.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chart-bar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/chart-bar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'move',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/move.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'badge-dollar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/badge-dollar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'angle-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/angle-down.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/arrow-left.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/arrow-right.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'circle-arrow-down-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/circle-arrow-down-left.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'circle-arrow-up-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/circle-arrow-up-right.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calendar-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/calendar-check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bars',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/bars.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'check-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/check-circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/instagram.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/linkedin.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rocket',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/rocket.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sliders-h',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sliders-h.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'times',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/times.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'undo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/undo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/edit.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'save',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/save.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'star',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/star.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'send',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/send.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'envelope',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/envelope.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'key',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/key.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sticky',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sticky-note.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/phone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/youtube.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'vimeo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/vimeo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/search.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'times-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/times-circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash-alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/trash-alt.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'minus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/minus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'caret-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/caret-down.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'image',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/image.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pencil-ruler',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/pencil-ruler.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'person-sign',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/person-sign.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sign-in',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sign-in.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sign-out',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sign-out.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'heart-solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/heart-solid.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'comment-dots',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/comment-dots.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/comment.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calculator',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/calculator.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/calendar-day.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'newspaper',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/newspaper.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/info.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'house-user',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/house-user.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'location',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/location.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'list',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/list.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sliders',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sliders.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'tags',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/tags.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calendar-app',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/custom/calendar-app.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'shop-solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/shop-solid.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'approve',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/approve.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'banking',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/banking.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'credit-card',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/credit-card.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'deposit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/deposit.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'docs',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/docs.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'id-card',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/id-card.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'move-in',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/move-in.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'price',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/price.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'security-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/security-check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'selfie',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/selfie.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sent',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/sent.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'signature',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/fontawesome/signature.svg'
      )
    );
  }
}
