<div class="dq-row header-container">
    <div class="dq-row dq-flex-aligned">
        <mat-icon class="icon-general">label</mat-icon>
        <span class="title">Tags</span>
    </div>
    <button mat-icon-button (click)="openTagAddEditDialogEdit()">
        <mat-icon>add</mat-icon>
    </button>
</div>

<div class="row items-container">
    <div class="tags-container">
        <ng-container *ngIf="(isTagsLoaded$  | async); else clientTagsLoading">
            <ng-container *ngFor="let tag of (clientTags$ | async)">
                <div class="tag-div-wrapper">
                    <div class="tag-div"> {{tag.name}}<mat-icon class="tag-icon"
                            (click)="deleteTag(tag)">clear
                        </mat-icon>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #clientTagsLoading>
            <mat-spinner class="tags-loading-spinner"></mat-spinner>
        </ng-template>
    </div>
</div>