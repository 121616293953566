import { createAction, props } from "@ngrx/store"
import { CommunicationTemplate } from "app/features/unified-inbox/unified-inbox.model"



export const actionClientDialogCreateTextMessageRequest = createAction(
    '[Client Dialog Text Message] Create Text Message Request',
    props<{ id: number, text: string, sendFromId: string, sendTo: string }>()
)

export const actionClientDialogCreateTextMessageSuccessed = createAction(
    '[Client Dialog Text Message] Create Text Message Successed',
)

export const actionClientDialogCreateTextMessageFailed = createAction(
    '[Client Dialog Text Message] Create Text Message Failed',
)

export const actionClientDialogSendTextRequested = createAction(
    '[Client Dialog Text Message] Send Text Message Requested',
    props<{ leadId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionClientDialogSendTextSuccess = createAction(
    '[Client Dialog Text Message] Send Text Message Success',
)

export const actionClientDialogSendTextFailed = createAction(
    '[Client Dialog Text Message] Send Text Message Failed',
)

export const actionClientDialogSendTextAndCollaborateRequested = createAction(
    '[Client Dialog Text Message] Send Text Message And Collaborate Requested',
    props<{ leadId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionClientDialogSendTextAndCollaborateSuccess = createAction(
    '[Client Dialog Text Message] Send Text Message And Collaborate Success',
)

export const actionClientDialogSendTextAndCollaborateFailed = createAction(
    '[Client Dialog Text Message] Send Text Message And Collaborate Failed',
)

export const actionClientDialogTextMessageTextContentUpdate = createAction(
    '[Client Dialog Text Message] Text Content Update',
    props<{ message: string, sendFrom: string, sendTo: string }>()
)
export const actionClientDialogTextMessageTemplatesRequest = createAction(
    '[Client Dialog Text Message] Templates Request',
)

export const actionClientDialogTextMessageTemplatesLoaded = createAction(
    '[Client Dialog Text Message] Templates Loaded',
    props<{ templates: CommunicationTemplate[] }>()
)

export const actionClientDialogTextMessageTemplatesFalied = createAction(
    '[Client Dialog Text Message] Templates Failed',
)

export const actionClientDialogTextMessageTemplateSaveAndUpdate = createAction(
    '[Client Dialog Text Message] Eamil Template Save And Update',
    props<{ friendlyName: string, displayName: string, content: string, isAccountShared: boolean, id?: number }>()
);

export const actionClientDialogTextMessageTemplateSaveAndUpdateSucceeded = createAction(
    '[Client Dialog Text Message] Eamil Template Save And Update Succeeded'
);

export const actionClientDialogTextMessageTemplateSaveAndUpdateFailed = createAction(
    '[Client Dialog Text Message] Eamil Template Save And Update Failed'
);

export const actionClientDialogTextMessageTemplateDeleteRequest = createAction(
    '[Client Dialog Text Message] Eamil Template Delete Request',
    props<{ id: number }>()
);

export const actionClientDialogTextMessageTemplateDeleteSucceeded = createAction(
    '[Client Dialog Text Message] Eamil Template Delete Succeeded'
);

export const actionClientDialogTextMessageTemplateDeleteFailed = createAction(
    '[Client Dialog Text Message] Eamil Template Delete Failed'
);

export const actionClientDialogTextMessageTemplateDeleteStateReset = createAction(
    '[Client Dialog Text Message] Eamil Template Delete State Reset'
);
export const actionClientDialogTextMessageTemplateSaveAndUpdateStateReset = createAction(
    '[Client Dialog Text Message] Eamil Template Save And Failed State Reset'
);

export const actionClientDialogTextMessagestateReset = createAction(
    '[Client Dialog Text Message] Text Message State Reset',
)

export const clientDialogTextMessageActions = {
    actionClientDialogCreateTextMessageRequest,
    actionClientDialogCreateTextMessageSuccessed,
    actionClientDialogCreateTextMessageFailed,
    actionClientDialogSendTextRequested,
    actionClientDialogSendTextSuccess,
    actionClientDialogSendTextFailed,
    actionClientDialogSendTextAndCollaborateRequested,
    actionClientDialogSendTextAndCollaborateSuccess,
    actionClientDialogSendTextAndCollaborateFailed,
    actionClientDialogTextMessageTextContentUpdate,
    actionClientDialogTextMessageTemplatesRequest,
    actionClientDialogTextMessageTemplatesLoaded,
    actionClientDialogTextMessageTemplatesFalied,
    actionClientDialogTextMessageTemplateSaveAndUpdate,
    actionClientDialogTextMessageTemplateSaveAndUpdateSucceeded,
    actionClientDialogTextMessageTemplateSaveAndUpdateFailed,
    actionClientDialogTextMessageTemplateDeleteRequest,
    actionClientDialogTextMessageTemplateDeleteSucceeded,
    actionClientDialogTextMessageTemplateDeleteFailed,
    actionClientDialogTextMessageTemplateDeleteStateReset,
    actionClientDialogTextMessageTemplateSaveAndUpdateStateReset,
    actionClientDialogTextMessagestateReset
}
