import { Action, createReducer, on } from "@ngrx/store";
import { BookingBuildingAvailabilityState } from "../../booking.state";
import { bookingBuildingAvailabilityActions } from "./booking-building-avaliabilty.action";

export const initialState: BookingBuildingAvailabilityState = {
  dateFilter: new Date().toISOString(),
  isBuildingAvailabilityLoaded: false,
  isBuildingsLoaded: false,
  availabilitySelected: '',
  buildingSelected: {
    id: -999,
    name: '',
    address: '',
    style: '',
    photoUrl: '',
    activationDate: ''
  },
  buildingSelectedId: -999,
  buildings: [],
  buildingWeeklyAvailability: {
    weekStartDate: '',
    weekEndDate: '',
    weeklyScheduleDays: []
  },
  isBookingCalendarsLoaded: false,
  bookingCalendars: [], 
  isScheduleOverwrite: false
}

const reducer = createReducer(
  initialState,
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityRequested,
    (state, { buildingId }) => ({
      ...state,
      isBuildingAvailabilityLoaded: false
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityScheduleOverwriteRequested,
    (state, { buildingId }) => ({
      ...state,
      isBuildingAvailabilityLoaded: false
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityScheduleOverwriteLoaded,
    (state, { buildingAvailability }) => ({
      ...state,
      isBuildingAvailabilityLoaded: true,
      buildingWeeklyAvailability: buildingAvailability
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityLoaded,
    (state, { buildingAvailability }) => ({
      ...state,
      isBuildingAvailabilityLoaded: true,
      buildingWeeklyAvailability: buildingAvailability
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityBuildingsRequested,
    (state) => ({
      ...state,
      isBuildingsLoaded: false
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityBuildingsLoaded,
    (state, { buildings }) => ({
      ...state,
      isBuildingsLoaded: true,
      buildings: buildings
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityCalendarsRequested,
    (state) => ({
      ...state,
      isBookingCalendarsLoaded: false
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityCalendarsLoaded,
    (state, { calendars }) => ({
      ...state,
      isBookingCalendarsLoaded: true,
      bookingCalendars: calendars
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityDateFilterChange,
    (state, { date }) => ({
      ...state,
      dateFilter: date
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityTimeSlotToConfirmChange,
    (state, { timeSlot }) => ({
      ...state,
      availabilitySelected: timeSlot
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilitySelectedBuildingChange,
    (state, { building }) => ({
      ...state,
      buildingSelected: building
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilitySelectedBuildingIdChange,
    (state, { builidngId }) => ({
      ...state,
      buildingSelectedId: builidngId
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityCalendarsReset,
    (state) => ({
      ...state,
      isBookingCalendarsLoaded: false,
      bookingCalendars: []
    })
  ),
  on(
    bookingBuildingAvailabilityActions.actionBookingBuildingAvailabilityReset,
    (state) => (
      initialState
    )
  ),
  on(bookingBuildingAvailabilityActions.actionBookingBuildingCalendarToggleScheduleOverwrite, 
    (state, { isScheduleOverwrite }) => ({
        ...state,             
        isScheduleOverwrite: isScheduleOverwrite
  })),
)

export const bookingBuildingAvailabilityReducer = (
  state: BookingBuildingAvailabilityState | undefined,
  action: Action
) => {
  return reducer(state, action);
};
