<div class="on-going-dialog-pad-component">
    <div class="container">
        <div class="row">
            <div class="digit col-4" id="one"><div (click)="pressKeyValue('1')" class="simple-digit">1</div></div>
            <div class="digit col-4" id="two"><div (click)="pressKeyValue('2')" class="alpha-digit">2<div class="sub">ABC</div></div>
                
            </div>
            <div class="digit col-4" id="three"><div (click)="pressKeyValue('3')" class="alpha-digit">3
                <div class="sub">DEF</div></div>
            </div>
        </div>
        <div class="row">
            <div class="digit col-4" id="four"><div (click)="pressKeyValue('4')" class="alpha-digit">4
                <div class="sub">GHI</div></div>
            </div>
            <div class="digit col-4" id="five"><div (click)="pressKeyValue('5')" class="alpha-digit">5
                <div class="sub">JKL</div></div>
            </div>
            <div class="digit col-4"><div (click)="pressKeyValue('6')" class="alpha-digit">6
                <div class="sub">MNO</div></div>
            </div>
        </div>
        <div class="row">
            <div class="digit col-4"><div (click)="pressKeyValue('7')" class="alpha-digit">7
                <div class="sub">PQRS</div></div>
            </div>
            <div class="digit col-4"><div (click)="pressKeyValue('8')" class="alpha-digit">8
                <div class="sub">TUV</div></div>
            </div>
            <div class="digit col-4"><div (click)="pressKeyValue('9')" class="alpha-digit">9
                <div class="sub">WXYZ</div></div>
            </div>
        </div>
        <div class="row">
            <div class="digit col-4"><div (click)="pressKeyValue('*')" class="special-digit">*</div>
            </div>
            <div class="digit col-4"><div (click)="pressKeyValue('0')" class="simple-digit">0</div>
            </div>
            <div class="digit col-4"><div (click)="pressKeyValue('#')" class="special-digit">#</div>
            </div>
        </div>
    </div>
</div>