export const NATIONALITY = [
    { value: 'AF', label: 'Afghani' },
    { value: 'AL', label: 'Albanian' },
    { value: 'DZ', label: 'Algerian' },
    { value: 'US', label: 'American' },
    { value: 'AS', label: 'American Samoan' },
    { value: 'AD', label: 'Andorran' },
    { value: 'AO', label: 'Angolan' },
    { value: 'AI', label: 'Anguillan' },
    { value: 'AQ', label: 'Antarctic' },
    { value: 'AG', label: 'Antiguan' },
    { value: 'AR', label: 'Argentine' },
    { value: 'AM', label: 'Armenian' },
    { value: 'AW', label: 'Arubian' },
    { value: 'AU', label: 'Australian' },
    { value: 'AT', label: 'Austrian' },
    { value: 'AZ', label: 'Azerbaijani' },
    { value: 'BS', label: 'Bahameese' },
    { value: 'BH', label: 'Bahrainian' },
    { value: 'BD', label: 'Bangladeshi' },
    { value: 'BB', label: 'Barbadian' },
    { value: 'BL', label: 'Barthélemois' },
    { value: 'BY', label: 'Belarusian' },
    { value: 'BE', label: 'Belgian' },
    { value: 'BZ', label: 'Belizean' },
    { value: 'BJ', label: 'Beninese' },
    { value: 'BM', label: 'Bermudan' },
    { value: 'BT', label: 'Bhutanese' },
    { value: 'BO', label: 'Bolivian' },
    { value: 'BA', label: 'Bosnian' },
    { value: 'BR', label: 'Brazilian' },
    { value: 'GB', label: 'British' },
    { value: 'BN', label: 'Bruneian' },
    { value: 'BG', label: 'Bulgarian' },
    { value: 'BF', label: 'Burkinabe' },
    { value: 'BI', label: 'Burundian' },
    { value: 'KH', label: 'Cambodian' },
    { value: 'CM', label: 'Cameroonian' },
    { value: 'CA', label: 'Canadian' },
    { value: 'CV', label: 'Cape Verdean' },
    { value: 'KY', label: 'Caymanian' },
    { value: 'CF', label: 'Central African' },
    { value: 'TD', label: 'Chadian' },
    { value: 'CL', label: 'Chilean' },
    { value: 'CN', label: 'Chinese' },
    { value: 'CX', label: 'Christmas Islander' },
    { value: 'CC', label: 'Cocossian' },
    { value: 'CO', label: 'Colombian' },
    { value: 'KM', label: 'Comoran' },
    { value: 'CG', label: 'Congolese' },
    { value: 'CD', label: 'Congolese (Democratic Republic of the Congo)' },
    { value: 'CK', label: 'Cook Islander' },
    { value: 'CR', label: 'Costa Rican' },
    { value: 'HR', label: 'Croatian' },
    { value: 'CU', label: 'Cuban' },
    { value: 'CW', label: 'Curaçaoan' },
    { value: 'CY', label: 'Cypriot' },
    { value: 'CZ', label: 'Czech' },
    { value: 'DK', label: 'Danish' },
    { value: 'DJ', label: 'Djiboutian' },
    { value: 'DM', label: 'Dominican (Commonwealth)' },
    { value: 'DO', label: 'Dominican (Republic)' },
    { value: 'NL', label: 'Dutch' },
    { value: 'EC', label: 'Ecuadorean' },
    { value: 'EG', label: 'Egyptian' },
    { value: 'AE', label: 'Emirian' },
    { value: 'GQ', label: 'Equatorial Guinean' },
    { value: 'ER', label: 'Eritrean' },
    { value: 'EE', label: 'Estonian' },
    { value: 'ET', label: 'Ethiopian' },
    { value: 'FK', label: 'Falkland Islander' },
    { value: 'FO', label: 'Faroese' },
    { value: 'FJ', label: 'Fijian' },
    { value: 'PH', label: 'Filipino' },
    { value: 'FI', label: 'Finnish' },
    { value: 'FR', label: 'French' },
    { value: 'GF', label: 'French Guianese' },
    { value: 'PF', label: 'French Polynesian' },
    { value: 'GA', label: 'Gabonese' },
    { value: 'GM', label: 'Gambian' },
    { value: 'GE', label: 'Georgian' },
    { value: 'DE', label: 'German' },
    { value: 'GH', label: 'Ghanaian' },
    { value: 'GI', label: 'Gibralterian' },
    { value: 'GR', label: 'Greek' },
    { value: 'GL', label: 'Greenlander' },
    { value: 'GD', label: 'Grenadian' },
    { value: 'GP', label: 'Guadeloupean' },
    { value: 'GU', label: 'Guamanian' },
    { value: 'GT', label: 'Guatemalan' },
    { value: 'GW', label: 'Guinea-Bissau nationals' },
    { value: 'GN', label: 'Guinean' },
    { value: 'GY', label: 'Guyanese' },
    { value: 'HT', label: 'Haitian' },
    { value: 'HN', label: 'Honduran' },
    { value: 'HK', label: 'Hong Konger' },
    { value: 'HU', label: 'Hungarian' },
    { value: 'KI', label: 'I-Kiribati' },
    { value: 'IS', label: 'Icelander' },
    { value: 'IN', label: 'Indian' },
    { value: 'ID', label: 'Indonesian' },
    { value: 'IR', label: 'Iranian' },
    { value: 'IQ', label: 'Iraqi' },
    { value: 'IE', label: 'Irish' },
    { value: 'IL', label: 'Israeli' },
    { value: 'IT', label: 'Italian' },
    { value: 'CI', label: 'Ivorian' },
    { value: 'JM', label: 'Jamaican' },
    { value: 'JP', label: 'Japanese' },
    { value: 'JO', label: 'Jordanian' },
    { value: 'KZ', label: 'Kazakhstani' },
    { value: 'KE', label: 'Kenyan' },
    { value: 'KN', label: 'Kittian' },
    { value: 'KW', label: 'Kuwaiti' },
    { value: 'KG', label: 'Kyrgyzstani' },
    { value: 'LA', label: 'Laotian' },
    { value: 'LV', label: 'Latvian' },
    { value: 'LB', label: 'Lebanese' },
    { value: 'LR', label: 'Liberian' },
    { value: 'LY', label: 'Libyan' },
    { value: 'LI', label: 'Liechtensteiner' },
    { value: 'LT', label: 'Lithunian' },
    { value: 'LU', label: 'Luxembourger' },
    { value: 'MO', label: 'Macanese' },
    { value: 'MK', label: 'Macedonian' },
    { value: 'YT', label: 'Mahoran' },
    { value: 'MG', label: 'Malagasy' },
    { value: 'MW', label: 'Malawian' },
    { value: 'MY', label: 'Malaysian' },
    { value: 'MV', label: 'Maldivan' },
    { value: 'ML', label: 'Malian' },
    { value: 'MT', label: 'Maltese' },
    { value: 'IM', label: 'Manx' },
    { value: 'MH', label: 'Marshallese' },
    { value: 'MQ', label: 'Martinican' },
    { value: 'MR', label: 'Mauritanian' },
    { value: 'MU', label: 'Mauritian' },
    { value: 'MX', label: 'Mexican' },
    { value: 'FM', label: 'Micronesian' },
    { value: 'MD', label: 'Moldovan' },
    { value: 'MC', label: 'Monacan' },
    { value: 'MN', label: 'Mongolian' },
    { value: 'ME', label: 'Montenegrin' },
    { value: 'MS', label: 'Montserratian' },
    { value: 'MA', label: 'Moroccan' },
    { value: 'LS', label: 'Mosotho' },
    { value: 'BW', label: 'Motswana' },
    { value: 'MZ', label: 'Mozambican' },
    { value: 'MM', label: 'Myanmarese' },
    { value: 'NA', label: 'Namibian' },
    { value: 'NR', label: 'Nauruan' },
    { value: 'NP', label: 'Nepalese' },
    { value: 'NC', label: 'New Caledonian' },
    { value: 'NZ', label: 'New Zealander' },
    { value: 'VU', label: 'Ni-Vanuatu' },
    { value: 'NI', label: 'Nicaraguan' },
    { value: 'NG', label: 'Nigerian' },
    { value: 'NE', label: 'Nigerien' },
    { value: 'NU', label: 'Niuean' },
    { value: 'NF', label: 'Norfolk Islander' },
    { value: 'KP', label: 'North Korean' },
    { value: 'MP', label: 'Northern Mariana Islander' },
    { value: 'NO', label: 'Norwegian' },
    { value: 'OM', label: 'Omani' },
    { value: 'PK', label: 'Pakistani' },
    { value: 'PW', label: 'Palauan' },
    { value: 'PS', label: 'Palestinian' },
    { value: 'PA', label: 'Panamanian' },
    { value: 'PG', label: 'Papua New Guinean' },
    { value: 'PY', label: 'Paraguayan' },
    { value: 'PE', label: 'Peruvian' },
    { value: 'PN', label: 'Pitcairn Islander' },
    { value: 'PL', label: 'Polish' },
    { value: 'PT', label: 'Portuguese' },
    { value: 'PR', label: 'Puerto Rican' },
    { value: 'QA', label: 'Qatari' },
    { value: 'RO', label: 'Romanian' },
    { value: 'RU', label: 'Russian' },
    { value: 'RW', label: 'Rwandan' },
    { value: 'SH', label: 'Saint Helenian' },
    { value: 'LC', label: 'Saint Lucian' },
    { value: 'VC', label: 'Saint Vincentian' },
    { value: 'PM', label: 'Saint-Pierrais' },
    { value: 'SV', label: 'Salvadorean' },
    { value: 'WS', label: 'Samoan' },
    { value: 'SM', label: 'Sanmarinese' },
    { value: 'SA', label: 'Saudi Arabian' },
    { value: 'SN', label: 'Senegalese' },
    { value: 'RS', label: 'Serbian' },
    { value: 'SC', label: 'Seychellois' },
    { value: 'SL', label: 'Sierra Leonean' },
    { value: 'SG', label: 'Singaporean' },
    { value: 'SK', label: 'Slovakian' },
    { value: 'SI', label: 'Slovenian' },
    { value: 'SB', label: 'Solomon Islander' },
    { value: 'SO', label: 'Somali' },
    { value: 'ZA', label: 'South African' },
    { value: 'KR', label: 'South Korean' },
    { value: 'ES', label: 'Spanish' },
    { value: 'LK', label: 'Sri Lankan' },
    { value: 'SD', label: 'Sudanese' },
    { value: 'SS', label: 'Sudanese (South Sudan)' },
    { value: 'SR', label: 'Surinamer' },
    { value: 'SZ', label: 'Swazi' },
    { value: 'SE', label: 'Swedish' },
    { value: 'CH', label: 'Swiss' },
    { value: 'SY', label: 'Syrian' },
    { value: 'ST', label: 'São Tomean' },
    { value: 'TW', label: 'Taiwanese' },
    { value: 'TJ', label: 'Tajikistani' },
    { value: 'TZ', label: 'Tanzanian' },
    { value: 'TH', label: 'Thai' },
    { value: 'TL', label: 'Timorese' },
    { value: 'TG', label: 'Togolese' },
    { value: 'TK', label: 'Tokelauan' },
    { value: 'TO', label: 'Tongan' },
    { value: 'TT', label: 'Trinidadian' },
    { value: 'TN', label: 'Tunisian' },
    { value: 'TR', label: 'Turkish' },
    { value: 'TM', label: 'Turkmen' },
    { value: 'TC', label: 'Turks and Caicos Islander' },
    { value: 'TV', label: 'Tuvaluan' },
    { value: 'UG', label: 'Ugandan' },
    { value: 'UA', label: 'Ukrainian' },
    { value: 'UY', label: 'Uruguayan' },
    { value: 'UZ', label: 'Uzbekistani' },
    { value: 'VE', label: 'Venezuelan' },
    { value: 'VN', label: 'Vietnamese' },
    { value: 'VG', label: 'Virgin Islander (British Virgin Islands)' },
    { value: 'WF', label: 'Wallisian' },
    { value: 'EH', label: 'Western Saharan' },
    { value: 'YE', label: 'Yemeni' },
    { value: 'ZM', label: 'Zambian' },
    { value: 'ZW', label: 'Zimbabwean' },
    { value: 'AX', label: 'Ålandic' },
]

export const LOCAL_COUNTRY = [
  { value: 'CA', label: 'Canada' },
  { value: 'US', label: 'United States' }
]

export const COUNTRY = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: 'Åland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo, Democratic Republic of' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: 'Côte D’Ivoire' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard and McDonald Islands' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran (Islamic Republic of)' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: 'Korea, Democratic People’s Republic' },
    { value: 'KR', label: 'Korea, Republic of' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Lao People’s Democratic Republic' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libyan Arab Jamahiriya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macau' },
    { value: 'MK', label: 'Macedonia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia, Federated States of' },
    { value: 'MD', label: 'Moldova, Republic of' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestinian Territory, Occupied' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Reunion' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'SH', label: 'Saint Helena' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SX', label: 'Sint Maarten' },
    { value: 'SK', label: 'Slovakia (Slovak Republic)' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'PM', label: 'St. Pierre and Miquelon' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen Islands' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania, United Republic of' },
    { value: 'TH', label: 'Thailand' },
    { value: 'NL', label: 'The Netherlands' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VA', label: 'Vatican City State (Holy See)' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'VG', label: 'Virgin Islands (British)' },
    { value: 'VI', label: 'Virgin Islands (U.S.)' },
    { value: 'WF', label: 'Wallis and Futuna Islands' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' }
]

export const CANADIAN_PROVINCE = [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'YT', label: 'Yukon' },
  ];

export const US_STATE = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AA', label: 'Armed Forces Americas' },
    { value: 'AE', label: 'Armed Forces Europe' },
    { value: 'AP', label: 'Armed Forces Pacific' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IT', label: 'International' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Is.' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PW', label: 'Palau' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'VI', label: 'U.S. Virgin Islands' },
    { value: 'US', label: 'United States Federal' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ];