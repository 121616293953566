import { CallTransferQueue, UserPhoneNumber } from 'app/shared/models/user-communication.model';

import { createAction, props } from '@ngrx/store';

import { CallRegistrySettings } from './call-manager.model';

export const actionCallManagerLogInDeviceRequested = createAction(
  '[Call Manager] Login and Register Device Requested'
);

export const actionCallManagerLogInDeviceSuccess = createAction(
  '[Call Manager] Log In Device Success',
  props<{ token: string; identity: string, expires: string }>()
);

export const actionCallManagerLogInDeviceFailed = createAction(
  '[Call Manager] Log In Deivce Failed',
  props<{ error: string }>()
);

export const actionCallManagerLogOutDeviceRequested = createAction(
  '[Call Manager] Logout Device Requested'
);

export const actionCallManagerLogOutDeviceSuccess = createAction(
  '[Call Manager] Logout Device Success'
);

export const actionCallManagerLogoutDeviceFailed = createAction(
  '[Call Manager] Log out Device Failed',
  props<{ error: string }>()
);

export const actionCallManagerRegisterSettingsReset = createAction(
  '[Call Manager] Register Setting Reset'
);

export const actionCallManagerRegisterSettingsFromLocalStorageRequst = createAction(
  '[Call Manager] Register Setting From Local Storage Request'
);

export const actionCallManagerRegisterSettingsFromLocalStorageLoad = createAction(
  '[Call Manager] Register Setting From Local Storage Load',
  props<{ settings: CallRegistrySettings }>()
);

export const actionCallManagerRegisterSettingsFromLocalStorageExpired = createAction(
  '[Call Manager] Register Setting From Local Storage Expired'
);

export const actionCallManagerLogEvent = createAction(
  '[Call Manager] Log',
  props<{ event: string }>()
);

export const actionCallManagerSetCurrentIncomingCall = createAction(
  '[Call Manager] Set Current Incoming Call',
  props<{ sid: string }>()
);

export const actionCallManagerSetCurrentOutgoingCall = createAction(
  '[Call Manager] Set Current Outgoing Call',
  props<{ sid: string }>()
);

export const actionCallManagerUpdateOutgoingCallPhoneNumber = createAction(
  '[Call Manager] Update Outgoing Call Phone Number',
  props<{ phoneNumber: string }>()
);

export const actionCallManagerUpdateHangupIncomingCallId = createAction(
  '[Call Manager] Update Hangup Incoming Call Id',
  props<{ sid: string }>()
);

export const actionCallManagerUpdateHangupOutgoingCallId = createAction(
  '[Call Manager] Update Hangup Outgoing Call Id',
  props<{ sid: string }>()
);

export const actionCallManagerUserPhoneNumberSettingsRequested = createAction(
  '[Call Manager] User Phone Number Settings Requested',
);

export const actionCallManagerUserPhoneNumberSettingsLoaded = createAction(
  '[Call Manager] User Phone Number Settings Loaded',
  props<{ phoneNumbers: UserPhoneNumber[] }>()
);

export const actionCallManagerUserPhoneNumberSettingsFailed = createAction(
  '[Call Manager] User Phone Number Settings Failed',
);

export const actionCallManagerTransferPhoneQueueOptionsRequested = createAction(
  '[Call Manager] Transfer Phone Queue Options Requested',
);

export const actionCallManagerTransferCallRequested = createAction(
  '[Call Manager] Transfer Call Requested',
  props<{ transferToQueueId: number }>()
);

export const actionCallManagerTransferCallSuccess = createAction(
  '[Call Manager] Transfer Call Success',
);

export const actionCallManagerTransferCallFailed = createAction(
  '[Call Manager] Transfer Call Failed',
);

export const actionCallManagerTransferPhoneQueueOptionsLoaded = createAction(
  '[Call Manager] Transfer Phone Queue Options Loaded',
  props<{ queues: CallTransferQueue[] }>()
);

export const actionCallManagerTransferPhoneQueueOptionsFailed = createAction(
  '[Call Manager] Transfer Phone Queue Options Failed',
);

export const actionCallManagerDeviceRegisteredUpdate = createAction(
  '[Call Manager] Device Registered Update',
  props<{ isRegistered: boolean }>()
);

export const actionCallManagerUpdateCallPanel = createAction(
  '[Call Manager] Call Panel Update',
  props<{ isCallPanelActive: boolean }>()
);

export const actionCallManagerUpdateInComingCallConnected = createAction(
  '[Call Manager] Incoming Call Active Update',
  props<{ isIncomingCallConnected: boolean }>()
);

export const actionCallManagerUpdateOutgoingCallConnected = createAction(
  '[Call Manager] Outgoing Call Active Update',
  props<{ isOutgoingCallConnected: boolean }>()
);

export const actionCallManagerUpdateInComingCallPanel = createAction(
  '[Call Manager] Incoming Call Panel Active Update',
  props<{ isIncomingCallPanelActive: boolean }>()
);

export const actionCallManagerUpdateOutgoingCallPanel = createAction(
  '[Call Manager] Outgoing Call Panel Active Update',
  props<{ isOutgoingCallPanelActive: boolean }>()
);

export const actionCallManagerUpdateCallFromPhoneNumber = createAction(
  '[Call Manager] Update Call From Phone Number',
  props<{ fromPhoneNumber: string }>()
);

export const actionCallManagerUpdateCallToPhoneNumber = createAction(
  '[Call Manager] Update Call To Phone Number',
  props<{ toPhoneNumber: string }>()
);

export const actionCallManagerBlockPhoneNumberRequested = createAction(
  '[Call Manager] Block phone number requested',
  props<{ phoneNumber: string, reason: string }>()
);

export const actionCallManagerBlockPhoneNumberSuccess = createAction(
  '[Call Manager] Block phone number success',
);

export const actionCallManagerBlockPhoneNumberFailed = createAction(
  '[Call Manager] Block phone number failed',
);