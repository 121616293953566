import {
  actionCallManagerBlockPhoneNumberRequested,
  actionCallManagerLogEvent,
  actionCallManagerTransferCallRequested
} from 'app/core/call-manager/call-manager.actions';
import { DeviceAuth, VolumeBar } from 'app/core/call-manager/call-manager.model';
import {
  selectCallManagerCallFromPhoneNumber,
  selectCallManagerCallToPhoneNumber,
  selectCallManagerIsIncomingCallConnected,
  selectCallManagerIsIncomingCallPanelActive,
  selectCallManagerIsOutgoingCallConnected,
  selectCallManagerIsOutgoingCallPanelActive,
  selectCallManagerTransferQueueOptions
} from 'app/core/call-manager/call-manager.selectors';
import { AppState } from 'app/core/core.state';
import { CallTransferQueue } from 'app/shared/models/user-communication.model';
import { fromEvent, Observable, Subject } from 'rxjs';
import { delay, map, startWith, takeUntil, tap } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CallManagerService } from 'app/core/call-manager/call-manager.service';

@Component({
  selector: 'dq-on-going-call-handler',
  templateUrl: './on-going-call-handler.component.html',
  styleUrls: ['./on-going-call-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OnGoingCallHandlerComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private callManagerService: CallManagerService
  ) {
  }

  @ViewChild('block', { read: ElementRef }) set content(content: ElementRef) {
    if (content) {
      this.block = content;
      this.mouseDown$ = fromEvent(this.block.nativeElement, 'mousedown').pipe(tap(console.log));
      this.mouseUp$ = fromEvent(this.block.nativeElement, 'mouseup').pipe(tap(console.log));
    }
  }

  block: ElementRef;

  mouseDown$: Observable<any>;
  mouseUp$: Observable<any>;

  isDialActive: boolean = false;
  isTransferActive: boolean = false;
  isMuteActive: boolean = false;

  unsubscribe: Subject<void> = new Subject();

  isIncomingCallPanelActive$: Observable<boolean>;
  isOutgoingCallPanelActive$: Observable<boolean>;
  isIncomingCallConnected$: Observable<boolean>;
  isOutgoingCallConnected$: Observable<boolean>;

  deviceAuth$: Observable<DeviceAuth>;

  callDuration: number = 0;
  callDurationDisplay: string = '00:00:00';
  callDurationTimerInterval: any;

  currentOutgoingFromPhoneNumber$: Observable<string>;
  currentOutgoingToPhoneNumber$: Observable<string>;

  transferPhoneQueueSearchOptions$: Observable<CallTransferQueue[]>;
  transferPhoneQueueOptions$: Observable<CallTransferQueue[]>;
  transferPhoneQueueOptions: CallTransferQueue[];
  transferPhoneQueueFormControl: UntypedFormControl = new UntypedFormControl();

  currentActiveOutgoingPhoneNumber: string;

  inputVolumeBar: VolumeBar = {
    intensity: 0,
    color: 'green'
  };

  outputVolumeBar: VolumeBar = {
    intensity: 0,
    color: 'green'
  };

  ngOnInit(): void {

    this.isIncomingCallPanelActive$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsIncomingCallPanelActive));
    this.isOutgoingCallPanelActive$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsOutgoingCallPanelActive));
    this.isIncomingCallConnected$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsIncomingCallConnected));
    this.isOutgoingCallConnected$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsOutgoingCallConnected));

    this.transferPhoneQueueOptions$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerTransferQueueOptions));
    this.currentOutgoingFromPhoneNumber$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerCallFromPhoneNumber));
    this.currentOutgoingToPhoneNumber$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerCallToPhoneNumber));

    /*
    this.deviceAuth$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectRegisteredDeviceAuth));
    this.deviceAuth$.pipe(takeUntil(this.unsubscribe)).subscribe((deviceAuth) => {
      if (deviceAuth.token && new Date(deviceAuth.expires) > new Date()) {
        this.callManagerService.registerDevice(deviceAuth);
      }
    })
    */

    this.isIncomingCallConnected$.pipe(takeUntil(this.unsubscribe)).subscribe((isIncomingCallConnected) => {
      if (isIncomingCallConnected) {
        this.incomingCallAccepted();
      }
    });

    this.isOutgoingCallConnected$.pipe(takeUntil(this.unsubscribe)).subscribe((isOutgoingCallConnected) => {
      if (isOutgoingCallConnected) {
        this.outgoingCallAccepted();
      }
    });

    this.transferPhoneQueueOptions$.pipe(takeUntil(this.unsubscribe)).subscribe((transferPhoneNumberOptions) => {
      this.transferPhoneQueueOptions = transferPhoneNumberOptions;
      this.transferPhoneQueueSearchOptions$ = this.transferPhoneQueueFormControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const phone = typeof value === 'string' ? value : value?.phoneNumber;
          return this._filter(phone);
        })
      );
    });
  }

  displayTransferPhoneQueue(queue: CallTransferQueue): string {
    return queue && queue.name ? `${queue.name}` : '';
  }

  getMicOnOff() {
    if (this.isMuteActive) {
      return 'mic_off';
    } else {
      return 'mic';
    }
  }

  private _filter(value): CallTransferQueue[] {
    const filterValue = value?.toLowerCase();
    return this.transferPhoneQueueOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  transferPhoneQueueClean() {
    this.transferPhoneQueueFormControl.setValue('');
  }

  transfertCall() {
    const transferTo: string = this.transferPhoneQueueFormControl.value?.name;
    if (!transferTo)
      return;
    this.log(`Transfering call to ${transferTo}`);
    this.store.dispatch(actionCallManagerTransferCallRequested({ transferToQueueId: this.transferPhoneQueueFormControl.value?.id }));
  }

  getCallParameterFrom() {
    return this.callManagerService.CallParameterFrom;
  }

  getCallParameterTo() {
    return this.callManagerService.getCallParameterTo();
  }

  acceptIncomingCall() {
    this.callManagerService.acceptCall();
  }

  hangupIncomingCall() {
    this.callManagerService.endCall();
    this.transferPhoneQueueFormControl.reset();
    clearInterval(this.callDurationTimerInterval);
  }

  rejectIncomingCall() {
    this.callManagerService.rejectIncomingCall();
    this.transferPhoneQueueFormControl.reset();
    clearInterval(this.callDurationTimerInterval);
  }

  hangupOutgoingCall() {
    this.callManagerService.endCall();
    this.transferPhoneQueueFormControl.reset();
    clearInterval(this.callDurationTimerInterval);
  }

  outgoingCallAccepted() {
    this.log('Outgoing call accepted');
    this.isDialActive = false;
    this.isTransferActive = false;
    this.isMuteActive = false;
    this.cdr.markForCheck();
    this.callDurationTimerOn();
  }

  incomingCallAccepted() {
    this.log('Incoming call accepted');
    this.isDialActive = false;
    this.isTransferActive = false;
    this.isMuteActive = false;
    this.callDurationTimerOn();
    this.cdr.markForCheck();
  }

  toggleInComingCallMute() {
    this.isMuteActive = !this.isMuteActive;
    this.callManagerService.mute(this.isMuteActive);
  }

  toggleOutgoingCallMute() {
    this.isMuteActive = !this.isMuteActive;
    this.callManagerService.mute(this.isMuteActive);
  }

  callDurationTimerOn() {
    this.callDurationTimerInterval = setInterval(() => {
      this.callDurationDisplay = new Date(this.callDuration++ * 1000).toISOString().substr(11, 8);
      this.cdr.markForCheck();
    }, 1000);
  }

  blockPhoneNumber() {
    this.mouseDown$.pipe(
      delay(2000),
      takeUntil(this.mouseUp$)
    )
      .subscribe(res => {
        const phoneNumber = this.getCallParameterFrom();
        const reason = `Quick block from the call ${phoneNumber}`
        this.store.dispatch(actionCallManagerBlockPhoneNumberRequested({
          phoneNumber: phoneNumber,
          reason: reason
        }));
        this.callManagerService.endCall();
        this.transferPhoneQueueFormControl.reset();
        clearInterval(this.callDurationTimerInterval);
        this.log(reason);
      });
  }

  pressIncomingCallKey($event) {
    this.callManagerService.sendDigit($event);
  }

  pressOutgoingCallKey($event) {
    this.callManagerService.sendDigit($event);
  }

  log(log: string) {
    this.store.dispatch(actionCallManagerLogEvent({ event: log }));
    console.log(log);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    clearInterval(this.callDurationTimerInterval);
  }
}
