import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { CommunicationTemplate, State } from "../../tenant-dialog.state";
import { tenantDialogActivityActions } from "../tenant-dialog-activities/tenant-dialog-activities.action";
import { actionTenantDialogTenantRepsRequested } from "../tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.action";
import { selectTenantDialogTenantId } from "../tenant-dialog-user-general/tenant-dialog-general.selectors";
import { actionTenantDialogSendTextAndCollaborateFailed, actionTenantDialogSendTextAndCollaborateRequested, actionTenantDialogSendTextAndCollaborateSuccess, actionTenantDialogSendTextFailed, actionTenantDialogSendTextRequested, actionTenantDialogSendTextSuccess, actionTenantDialogTextMessageTemplateDeleteFailed, actionTenantDialogTextMessageTemplateDeleteRequest, actionTenantDialogTextMessageTemplateDeleteSucceeded, actionTenantDialogTextMessageTemplateSaveAndUpdate, actionTenantDialogTextMessageTemplateSaveAndUpdateFailed, actionTenantDialogTextMessageTemplateSaveAndUpdateSucceeded, actionTenantDialogTextMessageTemplatesFalied, actionTenantDialogTextMessageTemplatesLoaded, actionTenantDialogTextMessageTemplatesRequest } from "./tenant-dialog-tenant-quick-text-message.action";
import { TenantDialogTenantTextMessageService } from "./tenant-dialog-tenant-text-message.service"


@Injectable()
export class TenantDialogTenantTextMessageEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private tenantDialogService: TenantDialogTenantTextMessageService,
        private notificationService: NotificationService
    ) { }

    requestSendTextMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendTextRequested),
            switchMap(action =>
                this.tenantDialogService.sendTextMessage(action.tenantId, action.text, action.sendFromId, action.sendTo).pipe(
                    map((response => {
                        if (response.status == 1) {
                            return actionTenantDialogSendTextSuccess()
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogSendTextFailed()
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogSendTextFailed())
                        })
                    )
                )
            )
        )
    );

    requestSendTextMessageAndCollaborate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendTextAndCollaborateRequested),
            switchMap(action =>
                this.tenantDialogService.sendTextMessageAndCollaborate(action.tenantId, action.text, action.sendFromId, action.sendTo).pipe(
                    map((response => {
                        if (response.status == 1) {
                            return actionTenantDialogSendTextAndCollaborateSuccess()
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogSendTextAndCollaborateFailed()
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogSendTextAndCollaborateFailed())
                        })
                    )
                )
            )
        )
    );

    collaborateAndMessageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendTextAndCollaborateSuccess),
            withLatestFrom(this.store.select(selectTenantDialogTenantId)),
            switchMap(([action, tenantId]) =>
                [
                    actionTenantDialogTenantRepsRequested({ tenantId: tenantId }),
                ]
            )
        )
    );

    sentTextSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogSendTextSuccess),
            withLatestFrom(this.store.pipe(select(selectTenantDialogTenantId))),
            switchMap(([action, tenantId]) =>
                [tenantDialogActivityActions.actionTenantDialogAllActivitiesRequest({ tenantId: tenantId, pageNo: 0, pageSize: 20, itemCountPage: 0 })]
            )
        )
    );


    saveAndUpdateTextTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogTextMessageTemplateSaveAndUpdate),
            switchMap(action =>
                this.tenantDialogService.saveAndUpdateTextTemplate(action.friendlyName, action.displayName, action.content, action.isAccountShared, action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Saved!')
                            return actionTenantDialogTextMessageTemplateSaveAndUpdateSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogTextMessageTemplateSaveAndUpdateFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogTextMessageTemplateSaveAndUpdateFailed())
                        })
                    )
                )
            )
        )
    );

    saveAndUpdateTextTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogTextMessageTemplateSaveAndUpdateSucceeded),
            switchMap(action =>
                [
                    actionTenantDialogTextMessageTemplatesRequest(),
                ]
            )
        )
    );

    requestTextTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogTextMessageTemplatesRequest),
            switchMap(action =>
                this.tenantDialogService.getTextTemplates().pipe(
                    map((response => {
                        if (response.status == 1) {
                            let templates: CommunicationTemplate[] = response.data
                            return actionTenantDialogTextMessageTemplatesLoaded({ templates });
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogTextMessageTemplatesFalied();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogTextMessageTemplatesFalied())
                        })
                    )
                )
            )
        )
    );

    deleteTextTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogTextMessageTemplateDeleteRequest),
            switchMap(action =>
                this.tenantDialogService.deleteTextTemplate(action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Deleted!')
                            return actionTenantDialogTextMessageTemplateDeleteSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogTextMessageTemplateDeleteFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantDialogTextMessageTemplateDeleteFailed())
                        })
                    )
                )
            )
        )
    );

    deleteTextTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogTextMessageTemplateDeleteSucceeded),
            switchMap(action =>
                [
                    actionTenantDialogTextMessageTemplatesRequest(),
                ]
            )
        )
    );
}
