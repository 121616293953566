import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { TenantInfo, TenantBuilding } from 'app/shared/models/tenant-info-models';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectTenantDialogTenantId, selectTenantDialogTenantInfo } from '../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { actionTenantDialogTenantBuildingRequested, actionTenantDialogBuildingReset } from '../tenant-dialog-tenant-building.action';
import { selectTenantDialogBuildingsStateTenantBuilding, selectTenantDialogBuildingsStateIsBuildingLoaded } from '../tenant-dialog-tenant-building.selector';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'dq-tenant-dialog-tenant-building',
  templateUrl: './tenant-dialog-tenant-building.component.html',
  styleUrls: ['./tenant-dialog-tenant-building.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTenantBuildingComponent implements OnInit {

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) { }

  unsubscribe: Subject<void> = new Subject();
  isMobile$: Observable<boolean>
  isMobile: boolean
  tenantInfo$: Observable<TenantInfo>
  tenantId$: Observable<number>
  tenantId: number
  isBuildingsLoaded$: Observable<boolean>
  tenantBuilding$: Observable<TenantBuilding>

  ngOnInit(): void {
    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo))
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId))
    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId
      if (tenantId > 0) {
        this.store.dispatch(actionTenantDialogTenantBuildingRequested({ tenantId }))
      }
    })
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })
    this.tenantBuilding$ = this.store.pipe(select(selectTenantDialogBuildingsStateTenantBuilding));
    this.isBuildingsLoaded$ = this.store.pipe(select(selectTenantDialogBuildingsStateIsBuildingLoaded));
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionTenantDialogBuildingReset())
  }
}
