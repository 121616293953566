import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  actionCallManagerBlockPhoneNumberFailed,
  actionCallManagerBlockPhoneNumberRequested,
  actionCallManagerBlockPhoneNumberSuccess,
  actionCallManagerLogEvent,
  actionCallManagerLogInDeviceFailed,
  actionCallManagerLogInDeviceRequested,
  actionCallManagerLogInDeviceSuccess,
  actionCallManagerLogOutDeviceRequested,
  actionCallManagerLogOutDeviceSuccess,
  actionCallManagerRegisterSettingsFromLocalStorageExpired,
  actionCallManagerRegisterSettingsFromLocalStorageLoad,
  actionCallManagerRegisterSettingsFromLocalStorageRequst,
  actionCallManagerTransferCallFailed,
  actionCallManagerTransferCallRequested,
  actionCallManagerTransferCallSuccess,
  actionCallManagerTransferPhoneQueueOptionsFailed,
  actionCallManagerTransferPhoneQueueOptionsLoaded,
  actionCallManagerTransferPhoneQueueOptionsRequested,
  actionCallManagerUpdateHangupOutgoingCallId,
  actionCallManagerUpdateOutgoingCallPhoneNumber,
  actionCallManagerUserPhoneNumberSettingsFailed,
  actionCallManagerUserPhoneNumberSettingsLoaded,
  actionCallManagerUserPhoneNumberSettingsRequested
} from './call-manager.actions';
import { LocalStorageService, NotificationService } from '../core.module';
import { CallRegistrySettings } from './call-manager.model';
import { CallTransferQueue, UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { CallManagerService } from './call-manager.service';

export const CALL_AUTH_KEY = 'CALL_AUTH';
@Injectable()
export class CallManagerEffects {

  constructor(
    private actions$: Actions,
    private callManagerService: CallManagerService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService
  ) { }


  register = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerLogInDeviceRequested),
      switchMap(action =>
        this.callManagerService.getDeviceAuthenticationToken()
          .pipe(
            map(response => {
              return actionCallManagerLogInDeviceSuccess({ token: response.token, identity: response.identity, expires: response.expires })
            }),
            catchError(error => {
              return of(actionCallManagerLogInDeviceFailed({ error }))
            })
          )
        )
      )
  );

  unregister = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerLogOutDeviceRequested),
      switchMap(action => {
        return of(actionCallManagerLogOutDeviceSuccess());
      })
    )
  );

  registerSucceeded = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionCallManagerLogInDeviceSuccess),
        tap(action => {
          this.localStorageService.setItem(CALL_AUTH_KEY, {
            isAuthenticated: true,
            token: action.token,
            expires: action.expires,
            identity: action.identity
          });
        })
      ),
    { dispatch: false }
  );

  unregisterSucceeded = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionCallManagerLogOutDeviceSuccess),
        tap(action => {
          this.localStorageService.setItem(CALL_AUTH_KEY, {
            isAuthenticated: false
          });
        })
      ),
    { dispatch: false }
  );

  registerSettingsFromLocalStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerRegisterSettingsFromLocalStorageRequst),
      switchMap(action => {
        let settings: CallRegistrySettings = this.localStorageService.getItem(CALL_AUTH_KEY);
        if (settings?.isAuthenticated && settings?.expires &&
          new Date(settings?.expires) > new Date(new Date().toUTCString())) {
          return of(actionCallManagerRegisterSettingsFromLocalStorageLoad({ settings }))
        } else {
          return of(actionCallManagerRegisterSettingsFromLocalStorageExpired())
        }
      }
      )
    )
  );

  callManagerTransferCallRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerTransferCallRequested),
      switchMap(action =>
        this.callManagerService.transferCall(action.transferToQueueId).pipe(
          map((response => {
            if (response.status == 1) {
              return actionCallManagerTransferCallSuccess();
            } else {
              this.notificationService.error(response.message);
              return actionCallManagerTransferCallFailed();
            }
          }),
            catchError(error => {
              return of(actionCallManagerTransferCallFailed())
            })
          )
        )
      )
    )
  );

  callManagerLogEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerLogEvent),
      switchMap(action =>
        this.callManagerService.logEvent(action.event).pipe(
          map((response => {
            if (response.status == 1) {
              return actionCallManagerTransferCallSuccess();
            } else {
              this.notificationService.error(response.message);
              return actionCallManagerTransferCallFailed();
            }
          }),
            catchError(error => {
              return of(actionCallManagerTransferCallFailed())
            })
          )
        )
      )
    )
  );

  hangupOutgoingCall = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerUpdateHangupOutgoingCallId),
      switchMap(action => [
        actionCallManagerUpdateOutgoingCallPhoneNumber({ phoneNumber: '' })
      ]
      )
    )
  );

  callCenterUserPhoneNumberSetiingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerUserPhoneNumberSettingsRequested),
      switchMap(action =>
        this.callManagerService.getUserPhoneNumberSettings().pipe(
          map((response => {
            if (response.status == 1) {
              let phoneNumbers: UserPhoneNumber[] = response.data.phoneNumbers
              return actionCallManagerUserPhoneNumberSettingsLoaded({ phoneNumbers });
            }
            else {
              this.notificationService.error(response.message);
              return actionCallManagerUserPhoneNumberSettingsFailed();
            }
          }),
            catchError(error => {
              return of(actionCallManagerUserPhoneNumberSettingsFailed())
            })
          )
        )
      )
    )
  );

  callCenterTransferPhoneNumberSetiingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerTransferPhoneQueueOptionsRequested),
      switchMap(action =>
        this.callManagerService.getTransferQueueOptions().pipe(
          map((response => {
            if (response.status == 1) {
              let queues: CallTransferQueue[] = response.data
              return actionCallManagerTransferPhoneQueueOptionsLoaded({ queues });
            }
            else {
              this.notificationService.error(response.message);
              return actionCallManagerTransferPhoneQueueOptionsFailed();
            }
          }),
            catchError(error => {
              return of(actionCallManagerTransferPhoneQueueOptionsFailed())
            })
          )
        )
      )
    )
  );

  blockPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionCallManagerBlockPhoneNumberRequested),
      switchMap(action =>
        this.callManagerService.blockPhoneNumber(action.phoneNumber, action.reason).pipe(
          map((response => {
            if (response.status == 1) {
              let queues: CallTransferQueue[] = response.data
              return actionCallManagerBlockPhoneNumberSuccess();
            } else {
              this.notificationService.error(response.message);
              return actionCallManagerBlockPhoneNumberFailed();
            }
          }),
            catchError(error => {
              return of(actionCallManagerBlockPhoneNumberFailed())
            })
          )
        )
      )
    )
  );
}
