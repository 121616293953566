import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subject } from 'rxjs';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { AssignedUser, IUserPhoneNumber, TenantInfo } from 'app/shared/models/tenant-info-models';
import { UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { selectUserId } from 'app/core/auth/auth.selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  TenantDialogCommunicationTemplateEditorComponent
} from '../../tenant-dialog-communication-template-editor/tenant-dialog-communication-template-editor.component';
import {
  selectTenantDialogTenantId,
  selectTenantDialogTenantInfo
} from '../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { CommunicationTemplate, State, TextMessageEditor } from 'app/features/tenant-dialog/tenant-dialog.state';
import {
  selectTenantDialogIsTextMessageSubmittedSuccessfully,
  selectTenantDialogIsTextMessageSubmitting,
  selectTenantDialogSendFromPhoneNumbers,
  selectTenantDialogTextMessagesIsTemplatesLoaded,
  selectTenantDialogTextMessagesTemplates,
  selectTenantDialogTextMessagesTextEditor
} from '../tenant-dialog-tenant-quick-text-message.selector';
import {
  actionTenantDialogSendTextRequested,
  actionTenantDialogTextMessageTemplatesRequest,
  actionTenantDialogTextMessageTextContentUpdate
} from '../tenant-dialog-tenant-quick-text-message.action';
import {
  selectTenantDialogRepsStateTenantReps
} from '../../tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.selector';
import {
  selectTenantIsActivitiesLastCommunicationPhoneNumber
} from '../../tenant-dialog-activities/tenant-dialog-activities.selector';
import { CommunicationType, TextChatMessage } from 'app/features/tenant-unified-inbox/tenant-unified-inbox.model';
import { environment } from 'environments/environment';
import { LEAD_PHONE_MERGE_TAGS } from 'app/shared/models/tinymce.model';
import { Editor } from 'tinymce';
import { MatProgressButtonOptions } from '../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-tenant-dialog-tenant-quick-text-message',
  templateUrl: './tenant-dialog-tenant-quick-text-message.component.html',
  styleUrls: ['./tenant-dialog-tenant-quick-text-message.component.scss', 'tenant-dialog-tenant-quick-text-message.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTenantQuickTextMessageComponent implements OnInit, OnDestroy {

  constructor(private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }


  textMessageTemplateEditorDialogRef: MatDialogRef<TenantDialogCommunicationTemplateEditorComponent>;
  @ViewChild('messageTextarea') messageTextarea: ElementRef;
  tenantId$: Observable<number>;
  tenantId: number;

  isMobile$: Observable<boolean>;
  isMobile: boolean;

  unsubscribe: Subject<void> = new Subject();

  isSubmitting$: Observable<boolean>;
  isSubmittedSuccessfully$: Observable<boolean>;

  textMessages$: Observable<TextChatMessage[]>;
  itemCountPage: number;

  tenantReps$: Observable<AssignedUser[]>;
  textEditor$: Observable<TextMessageEditor>;
  lastCommunicationPhoneNumber$: Observable<string>;

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Send',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: true,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'send'
    }
  };

  formText = this.formBuilder.group({
    message: ['', [Validators.required]],
    sendTo: ['', [Validators.required]],
    sendFrom: ['', [Validators.required]]
  });

  tenantInfo$: Observable<TenantInfo>;
  sendFromPhoneNumbers$: Observable<UserPhoneNumber[]>;
  sendFromPhoneNumbers: UserPhoneNumber[];
  toPhoneNumberOptions: string[];

  totalRecords: number;

  isLoading$: Observable<boolean>;

  loginUserId$: Observable<number>;
  loginUserId: number;
  collaborated = false;
  messageTemplates$: Observable<CommunicationTemplate[]>;
  messageTemplateSearchOptions$: Observable<CommunicationTemplate[]>;
  messageTemplates: CommunicationTemplate[];
  templateSerachControl = new FormControl();
  isTemplatesLoaded$: Observable<boolean>;

  picture = '../../../../../../../../assets/static/images/calendar_drag.png';

  tinyMceApiKey = environment.tinyMce.tinyMceApiKey;
  editor: Editor;
  editorSettings = {
    branding: false,
    menubar: '',
    height: '200',
    toolbar: 'mergetags',
    plugins: 'mergetags',
    browser_spellcheck: true,
    external_plugins: {},
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: LEAD_PHONE_MERGE_TAGS,
    setup: (editor: Editor) => {
      this.editor = editor;
    }
  };

  ngOnInit(): void {

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile));

    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    });

    this.loginUserId$ = this.store.pipe(select(selectUserId))
    this.loginUserId$.pipe(takeUntil(this.unsubscribe)).subscribe(loginUserId => {
      this.loginUserId = loginUserId
    });

    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId));
    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo));

    this.textEditor$ = this.store.pipe(select(selectTenantDialogTextMessagesTextEditor));
    this.lastCommunicationPhoneNumber$ = this.store.pipe(select(selectTenantIsActivitiesLastCommunicationPhoneNumber));

    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId;
      this.formText.get('message').reset();
      this.store.dispatch(actionTenantDialogTextMessageTemplatesRequest());
    });

    this.tenantInfo$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantInfo => {
      this.toPhoneNumberOptions = tenantInfo.phoneNumbers
      this.updateToNumber(tenantInfo.phoneNumbers[0])
      this.cdr.markForCheck()
    });

    this.isTemplatesLoaded$ = this.store.pipe(select(selectTenantDialogTextMessagesIsTemplatesLoaded))
    this.isSubmitting$ = this.store.pipe(select(selectTenantDialogIsTextMessageSubmitting))
    this.isSubmittedSuccessfully$ = this.store.pipe(select(selectTenantDialogIsTextMessageSubmittedSuccessfully))
    this.isSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmitting => {
      this.spinnerSaveButtonOptions.active = isSubmitting;
      this.cdr.markForCheck()
    });

    this.isSubmittedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmittedSuccessfully => {
      if (isSubmittedSuccessfully) {
        this.formText.get('message').reset()
        this.cdr.markForCheck()
      }
    });

    this.sendFromPhoneNumbers$ = this.store.pipe(select(selectTenantDialogSendFromPhoneNumbers));

    this.sendFromPhoneNumbers$.pipe(takeUntil(this.unsubscribe)).subscribe(sendFromPhoneNumbers => {
      if (sendFromPhoneNumbers.length > 0) {
        this.sendFromPhoneNumbers = sendFromPhoneNumbers;
        const loginUserPhoneNumber = this.getLoginUserPhoneNumber(sendFromPhoneNumbers)
        this.updateFromNumber(loginUserPhoneNumber ? loginUserPhoneNumber.id : sendFromPhoneNumbers[0]?.id);
      }
    });

    this.formText.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.spinnerSaveButtonOptions.disabled = !this.formText.valid
      this.cdr.markForCheck()
    });

    this.tenantReps$ = this.store.pipe(select(selectTenantDialogRepsStateTenantReps));
    combineLatest([this.tenantReps$, this.loginUserId$]).pipe(takeUntil(this.unsubscribe)).subscribe(([tenantReps, loginUserId]) => {
      this.collaborated = tenantReps.findIndex(x => x.id == loginUserId) >= 0;
      this.cdr.markForCheck();
    });

    this.textEditor$.pipe(take(1)).subscribe(textEditor => {
      this.formText.get('message').setValue(textEditor.message);
      this.formText.get('sendFrom').setValue(textEditor.sendFrom);
      // this.formText.get('sendTo').setValue(textEditor.sendTo);
    });

    this.formText.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.store.dispatch(actionTenantDialogTextMessageTextContentUpdate({ message: this.formText.get('message').value, sendFrom: this.formText.get('sendFrom').value, sendTo: this.formText.get('sendTo').value }))
    });

    this.messageTemplates$ = this.store.pipe(select(selectTenantDialogTextMessagesTemplates));

    this.messageTemplates$.pipe(takeUntil(this.unsubscribe)).subscribe(messageTemplates => {
      this.messageTemplates = messageTemplates
      this.messageTemplateSearchOptions$ = this.templateSerachControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTemplateSearchOption(value))
      );
    })
  }

  private _filterTemplateSearchOption(value: string): CommunicationTemplate[] {
    const filterValue = value.toLowerCase();
    return this.messageTemplates.filter(x => x.displayName.toLowerCase().includes(filterValue));
  }


  sendMessage() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    if (this.formText.valid) {
      const message = this.editor.getContent({ format: 'text' });
      this.store.dispatch(actionTenantDialogSendTextRequested(
        {
          tenantId: this.tenantId,
          text: message,
          sendFromId: this.formText.get('sendFrom').value,
          sendTo: this.formText.get('sendTo').value,
        }));
    }
  }

  updateToNumber(phoneNumber: string) {
    this.formText.get('sendTo').setValue(phoneNumber)
  }

  updateFromNumber(phoneNumberId: string) {
    this.formText.get('sendFrom').setValue(phoneNumberId);
  }

  getCurrentFromNumberDisplayLabel() {
    const currentId = this.formText.get('sendFrom').value;
    if (currentId != '') {
      const phoneNumber = this.sendFromPhoneNumbers?.find(x => x.id == currentId);
      return `${phoneNumber?.friendlyName} (${phoneNumber?.phoneNumber})`
    } else {
      return '';
    }
  }

  getFromNumberDisplayLabel(phoneNumber: IUserPhoneNumber) {
    return `${phoneNumber?.friendlyName} (${phoneNumber?.phoneNumber})`
  }

  close() {
  }

  getLoginUserPhoneNumber(phoneNumbers: UserPhoneNumber[]) {
    return phoneNumbers?.find(x => x.userId == this.loginUserId)
  }

  applyTemplate(template: CommunicationTemplate) {
    this.editor.execCommand('mceInsertContent', true, template);
    this.formText.get('message').setValue(this.editor.getContent());
    this.templateSerachControl.setValue('');
  }

  openTextTemplateEditDialogEdit(): void {
    this.textMessageTemplateEditorDialogRef = this.dialog.open(TenantDialogCommunicationTemplateEditorComponent, {
      width: this.isMobile ? '100vw' : '675px',
      height: this.isMobile ? '100dvh' : '',
      maxWidth: '100vw',
      autoFocus: true,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: {
        templateType: CommunicationType.TEXT
      }
    });

    this.textMessageTemplateEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        this.store.dispatch(actionTenantDialogTextMessageTemplatesRequest());
      }
    });
  }

  resetMessage() {
    this.formText.get('message').reset();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
