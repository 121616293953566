import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { AuthService } from 'app/core/auth/auth.service';
import {
  selectCallManagerIsCallPanelActive,
  selectCallManagerIsDeviceRegistered,
  selectCallManagerIsIncomingCallConnected,
  selectCallManagerIsOutgoingCallConnected
} from 'app/core/call-manager/call-manager.selectors';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { atLeastOneValidator } from 'app/shared/components/form-validators/at-least-one-validaor';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';

import {
  AssignedUser, IUpdateLeadProfileParams, LeadInfo, LeadPhoneNumber, LeadSource, Tag
} from '../../../../../shared/models/lead-info-models';
import { ClientDialogComponent } from '../../../client-dialog/client-dialog.component';
import {
  actionClientDialogAppointmentAppointmentsRequested
} from '../../client-dialog-appointment/client-dialog-appointment.action';
import {
  ClientDialogInventoryBookingComponent
} from '../../client-dialog-inventory/components/client-dialog-inventory-booking/client-dialog-inventory-booking/client-dialog-inventory-booking.component';
import {
  actionClientDialogClientRepDeleteRequested, actionClientDialogRepsCreateUpdateSuccessfulStateReset
} from '../../client-dialog-lead-rep/client-dialog-lead-rep.action';
import {
  ClientDialogUserGeneralRepsComponent
} from '../../client-dialog-lead-rep/components/client-dialog-user-general-reps/client-dialog-user-general-reps.component';
import {
  ClientDialogUserGeneralTagsComponent
} from '../../client-dialog-lead-tag/components/client-dialog-user-general-tags/client-dialog-user-general-tags.component';
import {
  actionClientDialogGeneralStateReset, actionClientUserGeneralProfileChangeIsBaseInfoEditMode,
  actionClientUserGeneralProfileUpdateRequested
} from '../client-dialog-general.action';
import {
  selectclientDialogLeadCaLLFromPhoneNumbers,
  selectClientLeadId, selectClientLeadInfo, selectClientLeadInfoIsBaseInfoEditMode,
  selectClientLeadInfoisBaseInfoSubmitting,
  selectClientLeadInfoPhoneNumbers
} from '../client-dialog-general.selectors';
import {
  ClientDialogLeadMergeComponent
} from '../components/client-dialog-lead-merge/client-dialog-lead-merge.component';
import { UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { CallManagerService } from 'app/core/call-manager/call-manager.service';
import { PhoneNavItem } from 'app/shared/components/phone-number-menu/phone-number-menu.model';
import { actionClientDialogLeadCallFromPhoneNumbersRequest } from 'app/features/client-dialog/client-dialog.actions';
import {  MatMenuTrigger } from '@angular/material/menu';
import { ClientDialogPhoneNumberBlockConfirmComponent } from '../components/phone-number-block-confirm/phone-number-block-confirm.component';
import { PhoneNumberBlockInfo } from 'app/shared/models/phone-number-block-info.model';

@Component({
  selector: 'dq-client-dialog-user-general',
  templateUrl: './client-dialog-user-general.component.html',
  styleUrls: ['./client-dialog-user-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogUserGeneralComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: UntypedFormBuilder,
    private store: Store<State>,
    public dialog: MatDialog,
    public clientDialogRef: MatDialogRef<ClientDialogComponent>,
    private callManagerService: CallManagerService,
    private autheService: AuthService) {
  }
  userGeneralTagsAddEditorDialogRef: MatDialogRef<ClientDialogUserGeneralTagsComponent>
  userGeneralRepsAddEditorDialogRef: MatDialogRef<ClientDialogUserGeneralRepsComponent>

  @ViewChildren('emailInput') emails: QueryList<ElementRef>
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  // we create an object that contains coordinates
  menuTopLeftPosition = { x: '0', y: '0' }

  isCallPanelActive$: Observable<boolean>;
  isDeviceRegistered$: Observable<boolean>;
  isIncomingCallOnGoing$: Observable<boolean>;
  isOutgoingCallOnGoing$: Observable<boolean>;

  callFromPhoneNumbers$: Observable<UserPhoneNumber[]>;
  callFromPhoneNumbers: UserPhoneNumber[];
  phoneNavItems: PhoneNavItem[] = [];

  unsubscribe: Subject<void> = new Subject();
  leadInfo$: Observable<LeadInfo>;
  leadInfoPhoneNumbers$: Observable<string[]>;
  leadPhoneNumberBlockInfo: LeadPhoneNumber[];
  leadSource$: Observable<LeadSource>;
  isSessionProfile$: Observable<boolean>;
  isTabLoading$: Observable<boolean>;
  dialogProfile: MatDialogRef<ClientDialogUserGeneralTagsComponent>;
  dialogRep: MatDialogRef<ClientDialogUserGeneralRepsComponent>;
  dialogLeadMerge: MatDialogRef<ClientDialogLeadMergeComponent>;
  dialogPhoneNumberBlock: MatDialogRef<ClientDialogPhoneNumberBlockConfirmComponent>;
  leadId$: Observable<number>;
  leadId: number;

  copiedElement: string;

  isMobile$: Observable<boolean>;
  isMobile: boolean;

  stage: string;

  isNolyDeveloperRole: boolean;

  isBaseInfoEditMode$: Observable<boolean>;
  isBaseInfoSubmitting$: Observable<boolean>;
  isBaseInfoEditMode: boolean;
  isBaseInfoSubmitting: boolean;
  isRepsLoaded$: Observable<boolean>;
  clientReps$: Observable<AssignedUser[]>;
  tags: Tag[];
  reps: AssignedUser[];
  userRoles: string[];

  tagNameControl = new UntypedFormControl();
  repNameControl = new UntypedFormControl();

  countryCode: string;
  initialName: string = '';
  selectedItemsList = [];
  checkedID = [];

  leadInfoForm = this.formBuilder.group(
    {
      id: ['', []],
      firstName: ['', []],
      lastName: ['', []],
      emails: new UntypedFormArray([
        new UntypedFormControl('', [Validators.email])
      ]),
      phoneNumbers: new UntypedFormArray([
        new UntypedFormControl('', [])
      ]),
      sourceName: ['', []]
    },
    { validators: atLeastOneValidator(['emails', 'phoneNumbers']) }
  )

  bookAppointmentDialogRef: MatDialogRef<ClientDialogInventoryBookingComponent>

  ngOnInit(): void {

    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe)).subscribe(roles => {
      this.userRoles = roles
    })

    this.leadId$ = this.store.pipe(select(selectClientLeadId))
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      if (leadId > 0) {
        this.leadId = leadId
        this.store.dispatch(actionClientDialogLeadCallFromPhoneNumbersRequest({ leadId }))
      }
    })

    this.isBaseInfoEditMode$ = this.store.pipe(select(selectClientLeadInfoIsBaseInfoEditMode))
    this.isBaseInfoSubmitting$ = this.store.pipe(select(selectClientLeadInfoisBaseInfoSubmitting))
    this.isBaseInfoEditMode$.pipe(takeUntil(this.unsubscribe)).subscribe(isBaseInfoEditMode => {
      this.isBaseInfoEditMode = isBaseInfoEditMode
    })
    this.isBaseInfoSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isBaseInfoSubmitting => {
      this.isBaseInfoSubmitting = isBaseInfoSubmitting
    })
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo))
    this.leadInfo$.pipe(takeUntil(this.unsubscribe)).subscribe(leadInfo => {
      this.leadPhoneNumberBlockInfo = leadInfo.phoneNumberBlockInfo
    })
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })

    this.isIncomingCallOnGoing$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsIncomingCallConnected));
    this.isOutgoingCallOnGoing$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsOutgoingCallConnected));
    this.isCallPanelActive$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsCallPanelActive));
    this.isDeviceRegistered$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsDeviceRegistered));

    this.leadInfoPhoneNumbers$ = this.store.pipe(select(selectClientLeadInfoPhoneNumbers));
    this.callFromPhoneNumbers$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectclientDialogLeadCaLLFromPhoneNumbers));
    this.callFromPhoneNumbers$.pipe(takeUntil(this.unsubscribe)).subscribe(callFromPhoneNumbers => {
      this.callFromPhoneNumbers = callFromPhoneNumbers;
    })

    combineLatest([this.callFromPhoneNumbers$, this.leadInfoPhoneNumbers$]).pipe(takeUntil(this.unsubscribe)).subscribe(([callFromPhoneNumbers, leadInfoPhoneNumbers]) => {
      callFromPhoneNumbers.forEach(fromPhoneNumber => {
        let parentItem = <PhoneNavItem>{
          displayName: fromPhoneNumber.friendlyName,
          value: fromPhoneNumber.phoneNumber,
          iconName: 'call',
          children: []
        }
        leadInfoPhoneNumbers.forEach(toPhoneNumber => {
          let childItem = <PhoneNavItem>{
            displayName: toPhoneNumber,
            parentValue: fromPhoneNumber.phoneNumber,
            iconName: 'call',
            value: toPhoneNumber
          }
          parentItem.children.push(childItem);
        })
        this.phoneNavItems.push(parentItem);
      });
    });

    combineLatest([this.isBaseInfoEditMode$, this.leadInfo$]).pipe(takeUntil(this.unsubscribe)).subscribe(([isBaseInfoEditMode, leadInfo]) => {
      if (leadInfo) {
        this.leadInfoForm.enable()
        this.leadInfoForm.get('id').setValue(leadInfo.id)
        this.leadInfoForm.get('firstName').setValue(leadInfo.firstName)
        this.leadInfoForm.get('lastName').setValue(leadInfo.lastName)

        if (isBaseInfoEditMode) {
          if (leadInfo.emails.length > this.emailArray.length) {
            leadInfo.emails.forEach((email, index) => {
              if (index > 0 && (this.emailArray.length <= leadInfo.emails.length)) {
                this.addEmail()
              }
            })
          }
          let emailFormArray = this.leadInfoForm.get('emails') as UntypedFormArray
          leadInfo.emails.forEach((email, index) => {
            emailFormArray.at(index).patchValue(email)
          })
          if (leadInfo.phoneNumbers.length > this.phoneArray.length) {
            leadInfo.phoneNumbers.forEach((phoneNumber, index) => {
              if (index > 0 && (this.phoneArray.length <= leadInfo.phoneNumbers.length)) {
                this.addPhone()
              }
            })
          }
          let phoneNumberFormArray = this.leadInfoForm.get('phoneNumbers') as UntypedFormArray
          leadInfo.phoneNumbers.forEach((phoneNumber, index) => {
            phoneNumberFormArray.at(index).patchValue(phoneNumber)
          })
        }


        // this.leadInfoForm.get('sourceName').setValue(leadInfo.leadSource.name)
        let initial = leadInfo.firstName?.charAt(0)?.toUpperCase() + leadInfo.lastName?.charAt(0)?.toUpperCase()
        this.initialName = initial
      }

    })
  }

  onPhoneRightClick(event: MouseEvent, phoneNumber: string) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: phoneNumber }

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  toggleClientInfoEditMode(isEditMode) {
    this.store.dispatch(actionClientUserGeneralProfileChangeIsBaseInfoEditMode({ isEditMode }))
  }

  saveChanges() {
    if (this.isBaseInfoSubmitting) {
      return
    }
    this.leadInfoForm.markAllAsTouched()
    if (this.leadInfoForm.invalid) {
      return
    }
    let params: IUpdateLeadProfileParams =
    {
      id: this.leadInfoForm.get('id').value,
      firstName: this.leadInfoForm.get('firstName').value,
      lastName: this.leadInfoForm.get('lastName').value,
      emails: this.leadInfoForm.get('emails').value,
      phoneNumbers: this.leadInfoForm.get('phoneNumbers').value,
    }
    this.store.dispatch(actionClientUserGeneralProfileUpdateRequested({ params }))
  }

  ifPhoneNumberBlocked(phoneNumber: string) {
    return this.leadPhoneNumberBlockInfo.find(x => x.phoneNumber == phoneNumber)?.isPhoneNumberBlocked;
  }

  getPhoneNumberBlockInfo(phoneNumber: string) {
    return this.leadPhoneNumberBlockInfo.find(x => x.phoneNumber == phoneNumber);
  }

  countryChanged(countrySelected: string | any) {
    this.countryCode = countrySelected.iso2
  }


  deleteRep(repId: number) {
    this.store.dispatch(actionClientDialogClientRepDeleteRequested({ leadId: this.leadId, repId: repId }))
  }

  openLeadMergeDialog(): void {
    this.dialogLeadMerge = this.dialog.open(ClientDialogLeadMergeComponent, {
      width: this.isMobile ? '100vw' : '75vw',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
    })

    this.dialogLeadMerge.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
    })
  }

  openPhoneNumberDialog(phoneNumber: string): void {
    let data: PhoneNumberBlockInfo = {
      updateType: this.ifPhoneNumberBlocked(phoneNumber) ? 'Unblock' : 'Block',
      phoneNumber: phoneNumber,
      phoneNumberId: this.getPhoneNumberBlockInfo(phoneNumber)?.id
    }
    this.dialogPhoneNumberBlock = this.dialog.open(ClientDialogPhoneNumberBlockConfirmComponent, {
      width: this.isMobile ? '100vw' : '575px',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: data
    })

    this.dialogPhoneNumberBlock.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
    })
  }

  openRepAddEditDialogEdit(rep?: AssignedUser): void {
    this.dialogRep = this.dialog.open(ClientDialogUserGeneralRepsComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
    })

    this.dialogRep.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.store.dispatch(actionClientDialogRepsCreateUpdateSuccessfulStateReset())
    })
  }

  get emailArray() {
    return this.leadInfoForm.get('emails') as UntypedFormArray
  }

  public removeEmail(index) {
    this.emailArray.removeAt(index)
  }

  public addEmail() {
    let newFormControl: UntypedFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ])
    this.emailArray.push(newFormControl)
    setTimeout(() => {
      this.emails.last.nativeElement.focus()
    })
  }


  get phoneArray() {
    return this.leadInfoForm.get('phoneNumbers') as UntypedFormArray
  }

  public removePhone(index) {
    this.phoneArray.removeAt(index)
  }


  public addPhone() {
    let newFormControl: UntypedFormControl = new UntypedFormControl('', [
      Validators.required
    ])
    this.phoneArray.push(newFormControl)
  }

  closeDialog() {
    this.clientDialogRef.close()
  }

  hasAccessToBooking(): Observable<boolean> {
    return this.autheService.isUserInRole([SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesBookingManager])
  }

  openBookAppointmentDialog() {
    this.bookAppointmentDialogRef = this.dialog.open(
      ClientDialogInventoryBookingComponent,
      {
        width: this.isMobile ? '100vw' : '75vw',
        height: '100dvh',
        maxWidth: '100vw',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: false,
      }
    )

    this.bookAppointmentDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        this.store.dispatch(actionClientDialogAppointmentAppointmentsRequested({ leadId: this.leadId }))
      }
    })
  }

  makeOutgoingCall(fromPhoneNumber: string, toPhoneNumber: string) {
    this.callManagerService.startOutgoingCall(fromPhoneNumber, toPhoneNumber);
  }

  makeOutgoingCallMultiSelect(event$: any) {
    this.callManagerService.startOutgoingCall(event$.from, event$.to);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.store.dispatch(actionClientDialogGeneralStateReset())
  }

}
