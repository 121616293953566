<div class="index-search-component search-div">
  <mat-icon class="index-search-icon">search</mat-icon>
  <input class="index-search-input" type="text" placeholder="Search" matInput [formControl]="searchControl"
         [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="lead-queue-search-custom-autocomplete">
    <mat-option *ngFor="let lead of leadSearchOptions$ | async" [value]="lead.firstName + ' ' + lead.lastName"
                (onSelectionChange)="openClientDialog(lead.id)" class="lead-info">
      <div class="lead-name">
        <strong>{{ lead.firstName }} {{ lead.lastName }}</strong>
        <span class="d-flex">
          <span
            class="text-truncate stage-span"
            [ngClass]="{
              'ready-review-color': lead.stage === 'Ready For Review',
              'lead-color': lead.stage === 'Lead',
              'won-color': lead.stage === 'Won',
              'lost-color': lead.stage === 'Lost',
              'in-application-color' : lead.stage === 'In Application',
              'appointment-color': lead.stage === 'Appointment'}"
          >
              {{ lead.stage }}
          </span>
          <ng-container *ngIf="lead.stage === 'Appointment' && lead.isAttended">
              <mat-icon class="attended-icon">check_circle</mat-icon>
          </ng-container>
        </span>
      </div>
      <div class="lead-contact">
        <span class="lead-phone-number">{{ lead.phoneNumbers[0] }}</span> |
        <span class="lead-email">{{ lead.emails[0] }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
