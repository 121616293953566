<mat-card *ngIf="card"
  class="d-flex dq-nav-card-component justify-content-between flex-column section mat-elevation-z">
  <a mat-stroked-button [id]="card.title" *ngIf="isLink" class="card-link" [routerLink]="card.links[0].link"
    #rla="routerLinkActive" routerLinkActive="is-active">
  </a>
  <mat-card-content [routerLink]="card.links[0].link">
    <div class="d-flex flex-wrap section_content">
      <div class="col-8 section-d">
        <h2>{{card.title | translate}}</h2>
        <p *ngIf="card.description as description" [innerHTML]="description| translate | safeHtml"></p>
      </div>
      <mat-icon *ngIf="card.icon as icon" class="col-4 d-flex justify-content-center section_icon">
        {{icon}}
      </mat-icon>
    </div>
    <div class="p-0 d-flex justify-content-between align-items-left flex-wrap links">
      <a mat-button [id]="link.id" *ngFor=" let link of card.links" [routerLink]="link.link"
        class="col-6 col-md-12 col-lg-6 d-flex">
        {{link.label|translate}} <mat-icon>
          keyboard_arrow_right</mat-icon></a>
    </div>
  </mat-card-content>

</mat-card>
