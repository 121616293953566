import { createAction, props } from "@ngrx/store";
import { Task, TaskAssignUser, TaskCreateParam, TaskUpdateParam } from "app/shared/models/task.model";

export const actionClientDialogTaskTasksRequested = createAction(
    '[Client Dialog Task] Tasks Requested',
    props<{ leadId: number }>()
)

export const actionClientDialogTaskTasksLoaded = createAction(
    '[Client Dialog Task] Tasks Loaded',
    props<{ tasks: Task[] }>()
)

export const actionClientDialogTaskTasksLoadFailed = createAction(
    '[Client Dialog Task] Tasks Load Failed'
)

export const actionClientDialogTasktaskAssignUsersRequested = createAction(
    '[Client Dialog Task] Task Assign Users Requested',
)

export const actionClientDialogTasktaskAssignUsersLoaded = createAction(
    '[Client Dialog Task] Task Assign Users Loaded',
    props<{ taskAssignUsers: TaskAssignUser[] }>()
)

export const actionClientDialogTasktaskAssignUsersFailed = createAction(
    '[Client Dialog Task] Task Assign Users Failed',
)

export const actionClientDialogTasktaskCreateRequested = createAction(
    '[Client Dialog Task] Task Create Requested',
    props<{ param: TaskCreateParam }>()
)

export const actionClientDialogTasktaskCreateSucceed = createAction(
    '[Client Dialog Task] Task Create Succeed',
)

export const actionClientDialogTasktaskCreateFailed = createAction(
    '[Client Dialog Task] Task Create Failed',
)

export const actionClientDialogTasktaskUpdateRequested = createAction(
    '[Client Dialog Task] Task Update Requested',
    props<{ param: TaskUpdateParam }>()
)

export const actionClientDialogTasktaskUpdateSucceed = createAction(
    '[Client Dialog Task] Task Update Succeed',
)

export const actionClientDialogTasktaskUpdateFailed = createAction(
    '[Client Dialog Task] Task Update Failed',
)

export const actionClientDialogTasktaskDeleteRequested = createAction(
    '[Client Dialog Task] Task Delete Requested',
    props<{ taskId: number }>()
)

export const actionClientDialogTasktaskDeleteSucceed = createAction(
    '[Client Dialog Task] Task Delete Succeed',
)

export const actionClientDialogTasktaskDeleteFailed = createAction(
    '[Client Dialog Task] Task Delete Failed',
)

export const actionClientDialogTaskTaskStatusToggle = createAction(
    '[Client Dialog Task] Task Status Toggle',
    props<{ taskId: number }>()
)

export const actionClientDialogTaskTaskStatusToggleSucceed = createAction(
    '[Client Dialog Task] Task Status Toggle Succeed',
)

export const actionClientDialogTaskTaskStatusToggleFailed = createAction(
    '[Client Dialog Task] Task Status Toggle Failed',
    props<{ taskId: number }>()
)

export const actionClientDialogTaskTaskCreateStateReset = createAction(
    '[Client Dialog Task] Tasks Task Create State Reset'
)

export const actionClientDialogTaskTaskEditStateReset = createAction(
    '[Client Dialog Task] Tasks Task Edit State Reset'
)

export const actionClientDialogTaskTaskDeleteStateReset = createAction(
    '[Client Dialog Task] Tasks Task Delete State Reset'
)

export const actionClientDialogTaskStateReset = createAction(
    '[Client Dialog Task] Tasks State Reset'
)

export const clientDialogTaskActions = {
    actionClientDialogTaskTasksRequested,
    actionClientDialogTaskTasksLoaded,
    actionClientDialogTasktaskAssignUsersRequested,
    actionClientDialogTasktaskAssignUsersLoaded,
    actionClientDialogTasktaskCreateRequested,
    actionClientDialogTasktaskCreateSucceed,
    actionClientDialogTasktaskUpdateRequested,
    actionClientDialogTasktaskUpdateSucceed,
    actionClientDialogTasktaskDeleteRequested,
    actionClientDialogTasktaskDeleteSucceed,
    actionClientDialogTaskTaskStatusToggle,
    actionClientDialogTaskTaskStatusToggleSucceed,
    actionClientDialogTaskTaskCreateStateReset,
    actionClientDialogTaskTaskEditStateReset,
    actionClientDialogTaskTaskDeleteStateReset,
    actionClientDialogTaskStateReset
}

export const clientDialogTaskFailActions = {
    actionClientDialogTaskTasksLoadFailed,
    actionClientDialogTasktaskCreateFailed,
    actionClientDialogTasktaskUpdateFailed,
    actionClientDialogTasktaskDeleteFailed,
    actionClientDialogTasktaskAssignUsersFailed,
    actionClientDialogTaskTaskStatusToggleFailed,
}