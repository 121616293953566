
<div class="form-div">
    <form [formGroup]="addCreditCheckFormGroup">

        <div class="dq-row">
            <div class="dq-flex-centered col-12">
                <mat-form-field appearance="outline" class="col-6" required>
                    <mat-label>Report Type</mat-label>
                    <mat-select formControlName="reportType"
                        (selectionChange)="onCountryChange($event)" required>
                        <mat-option *ngFor="let reportTypeOption of reportTypeOptions"
                            [value]="reportTypeOption.value">
                            {{reportTypeOption.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="dq-row">
            <div class="dq-flex-centered col-12">
                <button class="lead-info-btn" (click)="onUseLeadInfo()" mat-stroked-button>
                    <span>Use current lead information</span>
                </button>
            </div>
        </div>
        <div class="dq-row">
            <mat-form-field appearance="outline" class="col-4">
                <mat-label>Applicant First Name</mat-label>
                <input type="text" matInput formControlName="applicantFirstName" placeholder="Applicant First Name" name="applicantFirstName">
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantFirstName'].hasError('required')">
                    A first name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-4">
                <mat-label>Applicant Last Name</mat-label>
                <input type="text" matInput formControlName="applicantLastName" placeholder="Applicant Last Name" name="applicantLastName">
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantLastName'].hasError('required')">
                A last name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-4">
                <mat-label>Applicant Date of Birth</mat-label>
                <input matInput [max]="maxDate" [matDatepicker]="birthDateDatePicker" (click)="birthDateDatePicker.open()" formControlName="applicantBirthDate" required>
                <mat-datepicker-toggle matSuffix [for]="birthDateDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #birthDateDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="dq-row">
            <mat-form-field appearance="outline" class="col-6">
                <mat-label>Email</mat-label>
                <input type="text" matInput formControlName="applicantEmail"
                placeholder="Email" name="applicantEmail">
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantEmail'].hasError('required')">
                    An email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-6">
                <mat-label>Phone Number</mat-label>
                <ngx-mat-intl-tel-input (countryChanged)="countryChanged($event)" [cssClass]="'custom'"
                    [preferredCountries]="['ca', 'us']" placeholder="Enter Phone Number"
                    [enablePlaceholder]="false" [enableSearch]="true" formControlName="applicantPhoneNumber"
                    name="applicantPhoneNumber" #phoneNumber></ngx-mat-intl-tel-input>
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantPhoneNumber'].hasError('required')">
                    A phone number is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
            <div class="dq-row">
                <mat-form-field appearance="outline" class="col-6" required>
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="applicantCountry"
                        (selectionChange)="onCountryChange($event)" required>
                        <mat-option *ngFor="let countryOption of countryOptions"
                            [value]="countryOption.value">
                            {{countryOption.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-6" required>
                    <mat-label>State/Province</mat-label>
                    <mat-select formControlName="applicantState" required>
                        <mat-option *ngFor="let provinceStateOption of provinceStateOptions"
                            [value]="provinceStateOption.value">{{provinceStateOption.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="dq-row">
            <mat-form-field appearance="outline" class="col-4">
                <mat-label>Applicant Street Address</mat-label>
                <input type="text" matInput formControlName="applicantStreetAddress"
                placeholder="Applicant Last Name" name="applicantStreetAddress">
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantStreetAddress'].hasError('required')">
                    Street Address is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-4">
                <mat-label>Applicant City</mat-label>
                <input type="text" matInput formControlName="applicantCity"
                placeholder="Applicant City" name="applicantCity">
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantCity'].hasError('required')">
                    City is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-4">
                <mat-label>Applicant Postal Code / Zip</mat-label>
                <input type="text" matInput formControlName="applicantPostalCode"
                placeholder="Applicant Postal Code" name="applicantPostalCode">
                <mat-error *ngIf="addCreditCheckFormGroup.controls['applicantPostalCode'].hasError('required')">
                    Postal Code / ZIP is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            </div>
    </form>
</div>
<div class="action-buttons dq-flex-centered">
    <dq-spinner-button [disabled]="!addCreditCheckFormGroup.valid" class="generic-button confirm-button" type="button" (click)="addNewApplication()"
    [options]="spinnerCreateButtonOptions"></dq-spinner-button>
</div>
