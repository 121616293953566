import { createSelector } from "@ngrx/store";
import { selectClientDialog, ClientDialogState, ClientDialogTaskState } from "../../client-dialog.state";

export const selectClientDialogTaskState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogTask
);

export const selectClientDialogTasks = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.tasks
)

export const selectClientDialogIsTasksLoaded = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.isTasksLoaded
)

export const selectClientDialogTaskAssignUsers = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskCreateState.taskAssignUsers
)

export const selectClientDialogTaskIsTaskCreating = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskCreateState.isCreating
)

export const selectClientDialogTaskIsTaskCreatedSuccessfully = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskCreateState.isCreatedSuccessfully
)

export const selectClientDialogTaskIsTaskEditing = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskEditState.isEditing
)

export const selectClientDialogTaskIsTaskEditSuccessfully = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskEditState.isEditSuccessfully
)

export const selectClientDialogTaskIsTaskDeleting = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskDeleteState.isDeleting
)

export const selectClientDialogTaskIsTaskDeletedSuccessfully = createSelector(
    selectClientDialogTaskState,
    (state: ClientDialogTaskState) => state.taskDeleteState.isDeletSuccessfully
)