import { CountryCode, getCountries, NumberFormat, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digiPhoneNumberPipe'
})
export class DigiPhoneNumberPipe implements PipeTransform {


  transform(value: string, format: NumberFormat = 'INTERNATIONAL', defaultCountryCode: CountryCode | CountryCode[] = 'CA'): string {
    try {
      let phoneNumber: PhoneNumber;
      for (const countryCode of [defaultCountryCode, ...getCountries()].flat(Infinity) as CountryCode[]) {
        if ((phoneNumber = parsePhoneNumberFromString(value, countryCode)).isValid()) {
          return phoneNumber.format(format);
        }
      }
    } catch (e) {
      console.error(e);
    }
    return value;
  }

}
