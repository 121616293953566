export interface DepositPaymentType {
    id: string
    label: string
}

export const DEPOSIT_PAYMENT_TYPES: DepositPaymentType[] = [
    {
        id: 'NoDeposit',
        label: 'No Deposit'
    },
    {
        id: 'ApplicationDeposit',
        label: 'Application Deposit'
    },
    {
        id: 'PreLeasingDeposit',
        label: 'Pre Leasing Deposit'
    },
    {
        id: 'PostLeasingDeposit',
        label: 'Post Leasing Deposit'
    }
]

export enum DepositPaymentTypes {
    NoDeposit = 'NoDeposit',
    ApplicationDeposit = 'ApplicationDeposit',
    PreLeasingDeposit = 'PreLeasingDeposit',
    PostLeasingDeposit = 'PostLeasingDeposit'
}