export enum AlgoliaOperatorTypes {
  OPERATOR_OR = ' OR ',
  OPERATOR_AND = ' AND ',
  OPERATOR_NOT = ' NOT ',
  OPERATOR_TO = ' TO ',
  OPERATOR_OPEN_ANGLED_BRACKET = ' < ',
  OPERATOR_CLOSE_ANGLED_BRACKET = ' > ',
  OPERATOR_OPEN_ANGLED_BRACKET_EQUAL = ' >= ',
  OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL = ' <= ',
  OPERATOR_NOT_EQUAL = ' != ',
  OPERATOR_EQUAL = ' = '
}

export enum AlgoliaBuildingQueryParameterTypes {
  geoPolygons = 'geoPolygons',
  buildingTypes = 'buildingTypes',
  buildingStyles = 'buildingStyles',
  listingsForSaleCount = 'listingsForSaleCount',
  listingsForRentCount = 'listingsForRentCount',
  beds = 'buildingProperties.beds',
  baths = 'buildingProperties.baths',
  price = 'buildingProperties.price',
  transactionType = 'buildingProperties.transactionType',
  size = 'buildingProperties.size',
  amenities = 'buildingAmenities',
  marketDate = 'buildingProperties.marketDate',
  marketDateTimestamp = 'buildingProperties.marketDate_timestamp',
  buildingAge = 'builtYear'
}

export enum AlgoliaPropertyQueryParameterTypes {
  geoPolygons = 'propertyBuilding.geoPolygons',
  buildingTypes = 'propertyBuilding.buildingTypes',
  buildingStyles = 'propertyBuilding.buildingStyles',
  beds = 'beds',
  baths = 'baths',
  price = 'price',
  transactionType = 'transactionType',
  size = 'size',
  amenities = 'propertyBuilding.buildingAmenities',
  marketDate = 'marketDate',
  marketDateTimestamp = 'marketDate_timestamp',
  buildingAge = 'propertyBuilding.builtYear'
}
