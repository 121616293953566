import { LeadEmailMessage, LeadTextMessage, LeadVoiceMessage, LeadWebFormMessage } from "../../models/lead-communication.model";
import { TenantTextMessage, TenantVoiceMessage, TenantEmailMessage, TenantWebFormMessage } from "../../models/tenant-communication.model";

export interface ITimelineElement {
  id?: number
  leadId?: number;
  tenantId?: number
  direction?: string
  type?: keyof ITimelineTypes;
  date?: Date;
  title?: string;
  textMessage?: LeadTextMessage | TenantTextMessage
  voiceMessage?: LeadVoiceMessage | TenantVoiceMessage
  emailMessage?: LeadEmailMessage | TenantEmailMessage
  webFormMessage?: LeadWebFormMessage | TenantWebFormMessage
  timelineAppointment?: TimelineAppointment
  timelineNote?: TimelineNote
  phoneNumber?: string
}

export interface TimelineAppointment {
  id: string
  buildingAddress: string
  repName: string
  startTime: string
  timeZone: string
  appointmentStatusType: string
  winPrice: number
  lostReason: string
  lostComments: string
  closingDate: string
  budget: string
  bedPreference: string[]
  bathPreference: string[]
  parkingPreference: string
  petPreference: string[]
  moveInDate: string
  note: string
  buildingId: number
  unitIdentifier: string
}

export interface TimelineNote {
  noteOwner: string
  note: string
}

export interface ITimelineMeta {
  icon: string
}

export interface ITimelineTypes {
  Note: ITimelineMeta,
  Voice: ITimelineMeta,
  Text: ITimelineMeta,
  Email: ITimelineMeta,
  Appointment: ITimelineMeta,
  WebForm: ITimelineMeta
}

export const TIMELINE_TYPES: ITimelineTypes = {
  Note: {
    icon: 'note_alt'
  },
  Voice: {
    icon: 'call'
  },
  Text: {
    icon: 'sms'
  },
  Email: {
    icon: 'mail'
  },
  Appointment: {
    icon: 'date_range'
  },
  WebForm: {
    icon: 'dvr'
  }
}
