<div class="form-div">
  <form [formGroup]="cancelRequestFormGroup">
      <div class="row">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Reason Type</mat-label>
            <mat-select formControlName="reasonType" name="reasonType">
                <mat-option *ngFor="let resonType of REQUEST_CANCEL_REASON_TYPES" [value]="resonType.id">
                  {{resonType.label}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="cancelRequestFormGroup.controls['reasonType'].hasError('required')">
              A reason type is <strong>required</strong>
            </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Reason</mat-label>
            <textarea maxlength="4000" type="text" matInput formControlName="reason" placeholder="Reason" name="reason"></textarea>
            <mat-error *ngIf="cancelRequestFormGroup.controls['reason'].hasError('required')">
              A first name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="cancelRequestFormGroup.controls['reason'].hasError('maxlength')">
              Max length is 4000.
          </mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="(errorMessage$ | async) != ''">
        <div class="row">
          <div class="error-message-container">
            <span class="error-message">
              {{errorMessage$ | async}}
            </span>
          </div>
        </div>
      </ng-container>
  </form>
</div>
<div class="row">
    <div class="col-12 button-div">
        <dq-spinner-button [disabled]="!cancelRequestFormGroup.valid" class="generic-button confirm-button" type="button" (click)="cancelRentalRequest()"
            [options]="spinnerCancelButtonOptions"></dq-spinner-button>
    </div>
</div>