import { Action, createReducer, on } from '@ngrx/store';

import {
  actionCallManagerDeviceRegisteredUpdate, actionCallManagerLogEvent,
  actionCallManagerLogInDeviceSuccess, actionCallManagerLogOutDeviceSuccess, actionCallManagerRegisterSettingsFromLocalStorageLoad,
  actionCallManagerRegisterSettingsReset,
  actionCallManagerSetCurrentIncomingCall, actionCallManagerSetCurrentOutgoingCall,
  actionCallManagerTransferPhoneQueueOptionsLoaded,
  actionCallManagerUpdateCallFromPhoneNumber, actionCallManagerUpdateCallPanel, actionCallManagerUpdateCallToPhoneNumber, actionCallManagerUpdateHangupIncomingCallId,
  actionCallManagerUpdateHangupOutgoingCallId, actionCallManagerUpdateInComingCallConnected, actionCallManagerUpdateInComingCallPanel, actionCallManagerUpdateOutgoingCallConnected, actionCallManagerUpdateOutgoingCallPanel, actionCallManagerUpdateOutgoingCallPhoneNumber,
  actionCallManagerUserPhoneNumberSettingsLoaded
} from './call-manager.actions';
import { CallManagerState } from './call-manager.model';

export const initialState: CallManagerState = {
  logs: [],
  callFromPhoneNumber: '',
  callToPhoneNumber: '',
  callFromPhoneNumbers: [],
  callTransferQueues: [],
  isDeviceRegistered: false,
  isIncomingCallPanelActive: false,
  isOutgoingCallPanelActive: false,
  isIncomingCallConnected: false,
  isOutgoingCallConnected: false,
  isCallPanelActive: false,
  deviceAuth: {
    token: undefined,
    expires: undefined,
    identity: undefined,
    isAuthenticated: false
  }
};

const reducer = createReducer(
  initialState,
  on(actionCallManagerLogInDeviceSuccess, (state, props) => ({
    ...state,
    deviceAuth: {
      identity: props.identity,
      token: props.token,
      expires: props.expires,
      isAuthenticated: true
    }
  })),
  on(actionCallManagerRegisterSettingsFromLocalStorageLoad, (state, { settings }) => ({
    ...state,
    deviceAuth: {
      identity: settings.identity,
      token: settings.token,
      expires: settings.expires,
      isAuthenticated: settings.isAuthenticated
    }
  })),
  on(actionCallManagerLogOutDeviceSuccess, (state) => ({
    ...state,
    deviceAuth: {
      isAuthenticated: false
    }
  })),
  on(actionCallManagerLogEvent, (state, { event }) => ({
    ...state,
    logs: [...state.logs, event]
  })),
  on(actionCallManagerSetCurrentIncomingCall, (state, { sid }) => ({
    ...state,
    currentIncomingCallSid: sid
  })),
  on(actionCallManagerUpdateOutgoingCallPhoneNumber, (state, { phoneNumber }) => ({
    ...state,
    currentOutgoingPhoneNumber: phoneNumber
  })),
  on(actionCallManagerSetCurrentOutgoingCall, (state, { sid }) => ({
    ...state,
    currentOutgoingCallSid: sid
  })),
  on(actionCallManagerUpdateHangupIncomingCallId, (state, { sid }) => ({
    ...state,
    hangupIncomingCallSid: sid
  })),
  on(actionCallManagerUpdateHangupOutgoingCallId, (state, { sid }) => ({
    ...state,
    hangupOutgoingCallSid: sid
  })),
  on(actionCallManagerUserPhoneNumberSettingsLoaded, (state, { phoneNumbers }) => ({
    ...state,
    callFromPhoneNumbers: phoneNumbers
  })),
  on(actionCallManagerTransferPhoneQueueOptionsLoaded, (state, { queues }) => ({
    ...state,
    callTransferQueues: queues
  })),
  on(actionCallManagerDeviceRegisteredUpdate, (state, { isRegistered }) => ({
    ...state,
    isDeviceRegistered: isRegistered
  })),
  on(actionCallManagerUpdateCallPanel, (state, { isCallPanelActive }) => ({
    ...state,
    isCallPanelActive: isCallPanelActive
  })),
  on(actionCallManagerUpdateInComingCallConnected, (state, { isIncomingCallConnected }) => ({
    ...state,
    isIncomingCallConnected: isIncomingCallConnected
  })),
  on(actionCallManagerUpdateOutgoingCallConnected, (state, { isOutgoingCallConnected }) => ({
    ...state,
    isOutgoingCallConnected: isOutgoingCallConnected
  })),
  on(actionCallManagerUpdateInComingCallPanel, (state, { isIncomingCallPanelActive }) => ({
    ...state,
    isIncomingCallPanelActive: isIncomingCallPanelActive
  })),
  on(actionCallManagerUpdateOutgoingCallPanel, (state, { isOutgoingCallPanelActive }) => ({
    ...state,
    isOutgoingCallPanelActive: isOutgoingCallPanelActive
  })),
  on(actionCallManagerUpdateCallFromPhoneNumber, (state, { fromPhoneNumber }) => ({
    ...state,
    callFromPhoneNumber: fromPhoneNumber
  })),
  on(actionCallManagerUpdateCallToPhoneNumber, (state, { toPhoneNumber }) => ({
    ...state,
    callToPhoneNumber: toPhoneNumber
  })),

  on(actionCallManagerRegisterSettingsReset, (state) => (
    initialState
  )),
);

export function callManagerReducer(
  state: CallManagerState | undefined,
  action: Action
): CallManagerState {
  return reducer(state, action);
}