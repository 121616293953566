import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewEncapsulation, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subject } from 'rxjs';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { takeUntil, take, startWith, map } from 'rxjs/operators';
import { IUserPhoneNumber, LeadInfo, AssignedUser, LeadBuilding } from 'app/shared/models/lead-info-models';
import { UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { selectUserId } from 'app/core/auth/auth.selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientDialogCommunicationTemplateEditorComponent } from '../../client-dialog-communication-template-editor/client-dialog-communication-template-editor.component';
import { selectClientLeadId, selectClientLeadInfo } from '../../client-dialog-user-general/client-dialog-general.selectors';
import { CommunicationTemplate, State, TextMessageEditor } from 'app/features/client-dialog/client-dialog.state';
import { selectIsTextMessageSubmittedSuccessfully, selectIsTextMessageSubmitting, selectSendFromPhoneNumbers, selectTextMessagesIsTemplatesLoaded, selectTextMessagesTemplates, selectTextMessagesTextEditor } from '../client-dialog-lead-quick-text-message.selector';
import { actionClientDialogSendTextRequested, actionClientDialogTextMessageTemplatesRequest, actionClientDialogTextMessageTextContentUpdate } from '../client-dialog-lead-quick-text-message.action';
import { selectclientDialogRepsStateClientReps } from '../../client-dialog-lead-rep/client-dialog-lead-rep.selector';
import { selectClienIsActivitiesLastCommunicationPhoneNumber } from '../../client-dialog-activities/client-dialog-activities.selector';
import { CommunicationType, TextChatMessage } from 'app/features/unified-inbox/unified-inbox.model';
import { environment } from 'environments/environment';
import { LEAD_PHONE_MERGE_TAGS } from 'app/shared/models/tinymce.model';
import { Editor } from 'tinymce';
import { TinyMceInventoryBuildingOptions } from 'app/features/client-dialog/client-dialog-model';
import { CilentDialogLeadTextMessageService } from '../client-dialog-lead-text-message.service';
import { selectclientDialogBuildingsStateBuildingOptions } from '../../client-dialog-lead-building/client-dialog-lead-building.selector';
import { MatProgressButtonOptions } from '../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-lead-quick-text-message',
  templateUrl: './client-dialog-lead-quick-text-message.component.html',
  styleUrls: ['./client-dialog-lead-quick-text-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogLeadQuickTextMessageComponent implements OnInit, OnDestroy {

  constructor(private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private textService: CilentDialogLeadTextMessageService,
    public dialog: MatDialog,
  ) { }


  textMessageTemplateEditorDialogRef: MatDialogRef<ClientDialogCommunicationTemplateEditorComponent>;
  @ViewChild('messageTextarea') messageTextarea: ElementRef;
  leadId$: Observable<number>;
  leadId: number;

  isMobile$: Observable<boolean>;
  isMobile: boolean;

  unsubscribe: Subject<void> = new Subject();

  isSubmitting$: Observable<boolean>;
  isSubmittedSuccessfully$: Observable<boolean>;

  textMessages$: Observable<TextChatMessage[]>;
  itemCountPage: number;

  leadReps$: Observable<AssignedUser[]>;
  textEditor$: Observable<TextMessageEditor>;
  lastCommunicationPhoneNumber$: Observable<string>;

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Send',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: true,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'send',
    }
  };

  formText = this.formBuilder.group({
    message: ['', [Validators.required]],
    sendTo: ['', [Validators.required]],
    sendFrom: ['', [Validators.required]]
  });

  leadInfo$: Observable<LeadInfo>;
  sendFromPhoneNumbers$: Observable<UserPhoneNumber[]>;
  sendFromPhoneNumbers: UserPhoneNumber[];
  toPhoneNumberOptions: string[];

  totalRecords: number;

  isLoading$: Observable<boolean>;

  loginUserId$: Observable<number>;
  loginUserId: number;
  collaborated: boolean = false;
  messageTemplates$: Observable<CommunicationTemplate[]>;
  messageTemplateSearchOptions$: Observable<CommunicationTemplate[]>;
  messageTemplates: CommunicationTemplate[];
  templateSerachControl = new UntypedFormControl();
  isTemplatesLoaded$: Observable<boolean>;

  picture: string = '../../../../../../../../assets/static/images/calendar_drag.png';

  tinyMceApiKey = environment.tinyMce.tinyMceApiKey;
  editorBuildingOptions$: Observable<LeadBuilding[]>
  editorBuildingOptions: TinyMceInventoryBuildingOptions[]
  editor: Editor;
  editorSettings = {
    branding: false,
    skin_url: '/assets/skins/ui/CUSTOM',
    menubar: '',
    height: "200",
    toolbar: 'mergetags | templatesbtn',
    plugins: 'mergetags',
    browser_spellcheck: true,
    external_plugins: {},
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: LEAD_PHONE_MERGE_TAGS,
    setup: (editor: Editor) => {
      editor.ui.registry.addButton('templatesbtn', {
        icon: 'comment-add',
        onAction: () => {
          this.editorBuildings();
          this.openInventoryTemplatesDialog(editor);
        }
      });
      this.editor = editor;
    }
  };

  ngOnInit(): void {

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));

    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    });

    this.loginUserId$ = this.store.pipe(select(selectUserId))
    this.loginUserId$.pipe(takeUntil(this.unsubscribe)).subscribe(loginUserId => {
      this.loginUserId = loginUserId
    });

    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo));

    this.textEditor$ = this.store.pipe(select(selectTextMessagesTextEditor));
    this.lastCommunicationPhoneNumber$ = this.store.pipe(select(selectClienIsActivitiesLastCommunicationPhoneNumber));

    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId;
      this.formText.get('message').reset();
      this.store.dispatch(actionClientDialogTextMessageTemplatesRequest());
    });

    this.leadInfo$.pipe(takeUntil(this.unsubscribe)).subscribe(leadInfo => {
      this.toPhoneNumberOptions = leadInfo.phoneNumbers
      this.updateToNumber(leadInfo.phoneNumbers[0])
      this.cdr.markForCheck()
    });

    this.isTemplatesLoaded$ = this.store.pipe(select(selectTextMessagesIsTemplatesLoaded))
    this.isSubmitting$ = this.store.pipe(select(selectIsTextMessageSubmitting))
    this.isSubmittedSuccessfully$ = this.store.pipe(select(selectIsTextMessageSubmittedSuccessfully))
    this.isSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmitting => {
      this.spinnerSaveButtonOptions.active = isSubmitting;
      this.cdr.markForCheck()
    });

    this.isSubmittedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmittedSuccessfully => {
      if (isSubmittedSuccessfully) {
        this.formText.get('message').reset()
        this.cdr.markForCheck()
      }
    });

    this.sendFromPhoneNumbers$ = this.store.pipe(select(selectSendFromPhoneNumbers));

    this.sendFromPhoneNumbers$.pipe(takeUntil(this.unsubscribe)).subscribe(sendFromPhoneNumbers => {
      if (sendFromPhoneNumbers.length > 0) {
        this.sendFromPhoneNumbers = sendFromPhoneNumbers;
        let loginUserPhoneNumber = this.getLoginUserPhoneNumber(sendFromPhoneNumbers)
        this.updateFromNumber(loginUserPhoneNumber ? loginUserPhoneNumber.id : sendFromPhoneNumbers[0]?.id);
      }
    });

    this.formText.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.spinnerSaveButtonOptions.disabled = !this.formText.valid
      this.cdr.markForCheck()
    });

    this.leadReps$ = this.store.pipe(select(selectclientDialogRepsStateClientReps));
    combineLatest([this.leadReps$, this.loginUserId$]).pipe(takeUntil(this.unsubscribe)).subscribe(([leadReps, loginUserId]) => {
      this.collaborated = leadReps.findIndex(x => x.id == loginUserId) >= 0;
      this.cdr.markForCheck();
    });

    this.textEditor$.pipe(take(1)).subscribe(textEditor => {
      this.formText.get('message').setValue(textEditor.message);
      this.formText.get('sendFrom').setValue(textEditor.sendFrom);
      // this.formText.get('sendTo').setValue(textEditor.sendTo);
    });

    this.formText.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(values => {
      this.store.dispatch(actionClientDialogTextMessageTextContentUpdate({ message: this.formText.get('message').value, sendFrom: this.formText.get('sendFrom').value, sendTo: this.formText.get('sendTo').value }))
    });

    this.messageTemplates$ = this.store.pipe(select(selectTextMessagesTemplates));

    this.messageTemplates$.pipe(takeUntil(this.unsubscribe)).subscribe(messageTemplates => {
      this.messageTemplates = messageTemplates
      this.messageTemplateSearchOptions$ = this.templateSerachControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTemplateSearchOption(value))
      );
    })
  }

  openInventoryTemplatesDialog(editor: Editor) {
    const dialog = editor.windowManager.open({
      title: 'Inventory Templates',
      body: {
        type: 'panel',
        items: [
          {
            type: 'selectbox',
            name: 'building',
            items: this.editorBuildingOptions
          }
        ]
      },
      buttons: [
        {
          type: 'submit',
          text: 'Insert',
          primary: true,
        }
      ],
      onSubmit: (api) => {
        const buildingId = api.getData();
        this.textService.getInventoryPricesTemplate(buildingId.building).subscribe(response => {
          editor.selection.setContent(response.data);
        });
        dialog.close();
      }
    });
  }

  editorBuildings() {
    this.editorBuildingOptions$ = this.store.pipe(select(selectclientDialogBuildingsStateBuildingOptions));
    this.editorBuildingOptions$.pipe(
      takeUntil(this.unsubscribe),
      map(buildings => {
        return buildings.map(building => {
          return {
            text: building.name,
            value: building.id.toString()
          }
        })
      })
    ).subscribe(tinyBuildings => {
      this.editorBuildingOptions = tinyBuildings
    })
  }

  private _filterTemplateSearchOption(value: string): CommunicationTemplate[] {
    const filterValue = value.toLowerCase();
    return this.messageTemplates.filter(x => x.displayName.toLowerCase().includes(filterValue));
  }


  sendMessage() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    if (this.formText.valid) {
      var message = this.editor.getContent({ format: 'text' });
      this.store.dispatch(actionClientDialogSendTextRequested(
        {
          leadId: this.leadId,
          text: message,
          sendFromId: this.formText.get('sendFrom').value,
          sendTo: this.formText.get('sendTo').value,
        }));
    }
  }

  updateToNumber(phoneNumber: string) {
    this.formText.get('sendTo').setValue(phoneNumber)
  }

  updateFromNumber(phoneNumberId: string) {
    this.formText.get('sendFrom').setValue(phoneNumberId);
  }

  getCurrentFromNumberDisplayLabel() {
    let currentId = this.formText.get('sendFrom').value;
    if (currentId != '') {
      let phoneNumber = this.sendFromPhoneNumbers?.find(x => x.id == currentId);
      return `${phoneNumber?.friendlyName} (${phoneNumber?.phoneNumber})`
    } else {
      return '';
    }
  }

  getFromNumberDisplayLabel(phoneNumber: IUserPhoneNumber) {
    return `${phoneNumber?.friendlyName} (${phoneNumber?.phoneNumber})`
  }

  close() {
  }

  getLoginUserPhoneNumber(phoneNumbers: UserPhoneNumber[]) {
    return phoneNumbers?.find(x => x.userId == this.loginUserId)
  }

  applyTemplate(template: CommunicationTemplate) {
    this.editor.execCommand('mceInsertContent', true, template);
    this.formText.get('message').setValue(this.editor.getContent());
    this.templateSerachControl.setValue('');
  }

  openTextTemplateEditDialogEdit(): void {
    this.textMessageTemplateEditorDialogRef = this.dialog.open(ClientDialogCommunicationTemplateEditorComponent, {
      width: this.isMobile ? '100vw' : '675px',
      height: this.isMobile ? '100dvh' : '',
      maxWidth: '100vw',
      autoFocus: true,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: {
        templateType: CommunicationType.TEXT
      }
    });

    this.textMessageTemplateEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        this.store.dispatch(actionClientDialogTextMessageTemplatesRequest());
      }
    });
  }

  resetMessage() {
    this.formText.get('message').reset();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
