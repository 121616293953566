import { createAction, props } from '@ngrx/store';

export const actionHasFooterRequested = createAction(
  '[UI] Has Footer Requested'
);

export const actionHasFooterLoaded = createAction(
  '[UI] Has Footer Loaded',
  props<{ hasFooter: boolean }>()
);

export const actionHasFooterFailed = createAction('[UI] Has Footer Failed');

export const actionHasFooterUpsert = createAction(
  '[UI] Has Footer Upsert',
  props<{ hasFooter: boolean }>()
);

export const actionHasFooterUpsertSuccess = createAction(
  '[UI] Has Footer Upsert Success',
  props<{ hasFooter: boolean }>()
);

export const actionHasFooterUpsertFail = createAction(
  '[UI] Has Footer Upsert Fail'
);

export const actionWindowWidthUpsert = createAction(
  '[UI] Window Width Upsert',
  props<{ width: number }>()
);
