import { createAction, props } from '@ngrx/store';
import { Notification } from './notification-manager.model'

export const actionNotificationManagerAddNewNotification = createAction(
  '[Notification Manager] Add New Notification',
  props<{ notification: Notification }>()
);

export const actionNotificationManagerNotificationRead = createAction(
  '[Notification Manager] Notification Read',
  props<{ notification: Notification }>()
);
