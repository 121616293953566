<div class="dq-row header-container">
    <div class="dq-row dq-flex-aligned">
        <mat-icon class="building-icon" svgIcon="building"></mat-icon>
        <span class="title">Buildings</span>
    </div>
    <button mat-icon-button (click)="openBuildingAddEditDialogEdit()">
        <mat-icon>add</mat-icon>
    </button>
</div>

<div class="dq-row items-container">
    <div class="buildings-container">
        <ng-container *ngIf="(isBuildingsLoaded$  | async); else buildingsLoading">
            <ng-container *ngFor="let building of (clientBuildings$ | async)">
                <div class="building-div-wrapper">
                    <div class="building-div"> {{building.name}}<mat-icon class="clear-icon"
                            (click)="deleteBuilding(building.id)">clear
                        </mat-icon>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #buildingsLoading>
            <mat-spinner class="buildings-loading-spinner"></mat-spinner>
        </ng-template>
    </div>
</div>