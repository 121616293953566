import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { actionNotificationManagerNotificationRead } from 'app/core/notification-manager/notification-manager.actions';
import { Notification } from 'app/core/notification-manager/notification-manager.model';


@Component({
  selector: 'dq-notification-system',
  templateUrl: './notification-system.component.html',
  styleUrls: ['./notification-system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationSystemComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  @Input()
  notification: Notification;

  ngOnInit(): void {
  }

  deleteNotification() {
    this.store.dispatch(actionNotificationManagerNotificationRead({ notification: this.notification }));
  }
}
