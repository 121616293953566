<ng-container [ngSwitch]="true">
  <button *ngSwitchCase="!options.raised && !options.stroked && !options.flat"
          mat-button
          [type]="options.type"
          [class.active]="options.active"
          [class.fullWidth]="options.fullWidth"
          [class.mat-fab]="options.fab"
          [ngClass]="options.customClass"
          [disabled]="options.active || options.disabled">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button *ngSwitchCase="options.raised"
          mat-raised-button
          [type]="options.type"
          [class.active]="options.active"
          [class.fullWidth]="options.fullWidth"
          [class.mat-fab]="options.fab"
          [ngClass]="options.customClass"
          [disabled]="options.active || options.disabled">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button *ngSwitchCase="options.stroked"
          mat-stroked-button
          [type]="options.type"
          [class.active]="options.active"
          [class.fullWidth]="options.fullWidth"
          [class.mat-fab]="options.fab"
          [ngClass]="options.customClass"
          [disabled]="options.active || options.disabled">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button *ngSwitchCase="options.flat"
          mat-flat-button
          [type]="options.type"
          [class.active]="options.active"
          [class.fullWidth]="options.fullWidth"
          [class.mat-fab]="options.fab"
          [ngClass]="options.customClass"
          [disabled]="options.active || options.disabled">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-container>

<ng-template #buttonContent>
  <ng-container *ngIf="options.buttonIcon">
    <mat-icon class="mat-button-icon"
              [class.is-mat-icon]="!options.buttonIcon.fontSet"
              [class.active]="options.active && !options.disabled"
              [ngClass]="options.buttonIcon.customClass"
              [fontSet]="options.buttonIcon.fontSet"
              [fontIcon]="options.buttonIcon.fontIcon"
              [svgIcon]="options.buttonIcon.svgIcon"
              [inline]="options.buttonIcon.inline"
              [ngClass]="options.iconCustomClass">
      {{ options.buttonIcon.fontSet ? '' : options.buttonIcon.fontIcon }}
    </mat-icon>
  </ng-container>

  <span class="button-text"
        *ngIf="!options.fab"
        [class.active]="options.active && !options.disabled">
    {{ options.text }}
  </span>

  <ng-container *ngIf="options.fab && options.icon">
    <mat-icon [fontSet]="options.icon.fontSet"
              [fontIcon]="options.icon.fontIcon"
              [svgIcon]="options.icon.svgIcon"
              [inline]="options.icon.inline"
              [ngClass]="options.iconCustomClass">
      {{ options.icon.fontSet ? '' : options.icon.fontIcon }}
    </mat-icon>
  </ng-container>

  <mat-spinner class="spinner"
               *ngIf="options.active && !options.disabled"
               [diameter]="options.fab ? 58 : options.spinnerSize"
               [mode]="options.mode"
               [value]="options.value"
               [class.active]="options.active && !options.disabled"
               [ngClass]="options.spinnerCustomClass">
  </mat-spinner>
</ng-template>
