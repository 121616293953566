import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { actionTenantDialogResetState, actionTenantDialogTenantCallFromPhoneNumbersRequest, actionTenantDialogTenantCallFromPhoneNumbersSuccessed, actionTenantDialogTenantCallFromPhoneNumbersFailed, tenantDialogActions } from "./tenant-dialog.actions";
import { TenantDialogService } from "./tenant-dialog.service";
import { actionTenantDialogActivityStateReset } from "./components/tenant-dialog-activities/tenant-dialog-activities.action";
import { actionTenantDialogTenantNoteStateReset } from "./components/tenant-dialog-tenant-note/tenant-dialog-tenant-note.action";
import { actionTenantDialogGeneralStateReset } from "./components/tenant-dialog-user-general/tenant-dialog-general.action";
import { UserEmail, UserPhoneNumber } from "app/shared/models/user-communication.model";
import { select, Store } from "@ngrx/store";
import { State } from "./tenant-dialog.state";
import { selectUserId } from "app/core/auth/auth.selectors";
import { actionTenantDialogTextMessagestateReset } from "./components/tenant-dialog-tenant-quick-text-message/tenant-dialog-tenant-quick-text-message.action";
import { NotificationService } from "app/core/core.module";


@Injectable()
export class TenantDialogEffects {
  constructor(
    private actions$: Actions,
    private tenantDialogService: TenantDialogService,
    private notificationService: NotificationService,
    private store: Store<State>,
  ) { }

  tenantDialogRequestCommunicationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tenantDialogActions.actionTenantDialogCommunicationSettingsRequested),
      withLatestFrom(this.store.pipe(select(selectUserId))),
      switchMap(([action, userId]) =>
        this.tenantDialogService.requestUserCommunicationSettings(action.tenantId).pipe(
          map((response => {
            if (response.status == 1) {
              let emails: UserEmail[] = response.data.emails
              let phoneNumbers: UserPhoneNumber[] = response.data.phoneNumbers
              return tenantDialogActions.actionTenantDialogCommunicationSettingsSucceded({ emails, phoneNumbers, userId })
            } else {
              this.notificationService.error(response.message);
              return tenantDialogActions.actionTenantDialogCommunicationSettingsFailed()
            }
          }),
            catchError(error => {
              return of(tenantDialogActions.actionTenantDialogCommunicationSettingsFailed())
            })
          )
        )
      )
    )
  );

  requestTenantCallFromPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionTenantDialogTenantCallFromPhoneNumbersRequest),
      switchMap(action =>
        this.tenantDialogService.requestUserCommunicationSettings(action.tenantId).pipe(
          map((response => {
            if (response.status == 1) {
              let phoneNumbers: UserPhoneNumber[] = response.data.phoneNumbers
              return actionTenantDialogTenantCallFromPhoneNumbersSuccessed({ phoneNumbers })
            } else {
              this.notificationService.error(response.message);
              return actionTenantDialogTenantCallFromPhoneNumbersFailed()
            }
          }),
            catchError(error => {
              return of(actionTenantDialogTenantCallFromPhoneNumbersFailed())
            })
          )
        )
      )
    )
  );

  tenantDialogStateReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionTenantDialogResetState),
      switchMap(action => [
        actionTenantDialogActivityStateReset(),
        // actionTenantDialogEmailEditorStateReset(),
        actionTenantDialogTenantNoteStateReset(),
        actionTenantDialogTextMessagestateReset(),
        actionTenantDialogGeneralStateReset()
      ])
    )
  )

}
