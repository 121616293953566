import { createSelector } from "@ngrx/store";
import { ClientDialogClientRentalRequestState, ClientDialogState, selectClientDialog } from "app/features/client-dialog/client-dialog.state";

export const selectClientDialogClientRentalRequestState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => {
    return state.clientDialogClientRentalRequest
  }
);

export const selectClientDialogIsClientRentalRequestLoaded = createSelector(
  selectClientDialogClientRentalRequestState,
  (state: ClientDialogClientRentalRequestState) => {
    return state.isRentalRequestLoaded
  }
);

export const selectClientDialogClientLeasingReps = createSelector(
  selectClientDialogClientRentalRequestState,
  (state: ClientDialogClientRentalRequestState) => {
    return state.leasingReps
  }
);

export const selectClientDialogClientRentalParkingItems = createSelector(
  selectClientDialogClientRentalRequestState,
  (state: ClientDialogClientRentalRequestState) => {
    return state.rentalParkingItems
  }
);

export const selectClientDialogClientRentalStorageItems = createSelector(
  selectClientDialogClientRentalRequestState,
  (state: ClientDialogClientRentalRequestState) => {
    return state.rentalStorageItems
  }
);

export const selectClientDialogIsClientRentalRequestSending = createSelector(
  selectClientDialogClientRentalRequestState,
  (state: ClientDialogClientRentalRequestState) => {
    return state.isRentalRequestSending
  }
);

export const selectClientDialogIsClientRentalRequestDeleting = createSelector(
  selectClientDialogClientRentalRequestState,
  (state: ClientDialogClientRentalRequestState) => {
    return state.isRentalRequestdeleting
  }
);