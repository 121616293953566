<div class="dq-row header-container">
    <div class="dq-row dq-flex-aligned">
        <mat-icon class="icon-general">label</mat-icon>
        <span class="title">Preferences</span>
    </div>
    <button mat-icon-button (click)="openPreferenceAddEditDialogEdit()"
        class="add-preference-button">
        <mat-icon>add</mat-icon>
    </button>
</div>

<div class="dq-row items-container">
    <ng-container *ngIf="(isPreferencesLoaded$  | async); else clientPreferencesLoading">
        <ng-container *ngIf="leadBedPreferences?.length > 0">
            <div class="preference-container">
                <mat-icon class="preference-type-icon" svgIcon="bed"></mat-icon>
                <ng-container *ngFor="let preference of leadBedPreferences">
                    <div class="preference-div-wrapper">
                        <div class="preference-div"> {{preference.label}}<mat-icon class="preference-icon"
                                (click)="deletePreference(preference.value)">clear
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="leadBathPreferences?.length > 0">
            <div class="preference-container">
                <mat-icon class="preference-type-icon" svgIcon="shower"></mat-icon>
                <ng-container *ngFor="let preference of leadBathPreferences">
                    <div class="preference-div-wrapper">
                        <div class="preference-div"> {{preference.label}}
                            <mat-icon class="preference-icon"
                                (click)="deletePreference(preference.value)">clear
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="leadParkingPreference">
            <div class="preference-container">
                <mat-icon class="preference-type-icon" svgIcon="parking"></mat-icon>
                <div class="preference-div-wrapper">
                    <div class="preference-div"> {{leadParkingPreference.label}}
                        <mat-icon class="preference-icon"
                            (click)="deletePreference(leadParkingPreference.value)">clear
                        </mat-icon>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="leadPetsPreferences?.length > 0">
            <div class="preference-container">
                <mat-icon class="preference-type-icon">pets</mat-icon>
                <ng-container *ngFor="let preference of leadPetsPreferences">
                    <div class="preference-div-wrapper">
                        <div class="preference-div"> {{preference.label}}
                            <mat-icon class="preference-icon"
                                (click)="deletePreference(preference.value)">clear
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="leadLanguagePreferences?.length > 0">
            <div class="preference-container">
                <mat-icon class="preference-type-icon">language</mat-icon>
                <ng-container *ngFor="let preference of leadLanguagePreferences">
                    <div class="preference-div-wrapper">
                        <div class="preference-div"> {{preference.label}}<mat-icon class="preference-icon"
                                (click)="deletePreference(preference.value)">clear
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="leadAccessbilityPreferences?.length > 0">
            <div class="preference-container">
                <mat-icon class="preference-type-icon">accessible</mat-icon>
                <ng-container *ngFor="let preference of leadAccessbilityPreferences">
                    <div class="preference-div-wrapper">
                        <div class="preference-div"> {{preference.label}}<mat-icon class="preference-icon"
                                (click)="deletePreference(preference.value)">clear
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="leadBudgetPreference">
            <div class="preference-container">
                <mat-icon class="preference-type-icon"> attach_money</mat-icon>
                <div class="preference-div-wrapper">
                    <div class="preference-div"> {{leadBudgetPreference.value | number:'2.'}}<mat-icon
                            class="preference-icon"
                            (click)="deletePreference(leadBudgetPreference.value)">clear
                        </mat-icon>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="leadMoveInDatePreference">
            <div class="preference-container">
                <mat-icon class="preference-type-icon">date_range</mat-icon>
                <div class="preference-div-wrapper">
                    <div class="preference-div"> {{leadMoveInDatePreference.value | date:'EEEE, MMM d,
                        y'}}<mat-icon class="preference-icon"
                            (click)="deletePreference(leadMoveInDatePreference.value)">clear
                        </mat-icon>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #clientPreferencesLoading>
        <mat-spinner class="preference-loading-spinner"></mat-spinner>
    </ng-template>
</div>
