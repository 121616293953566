import { createAction, props } from '@ngrx/store';
import { UserEmail, UserPhoneNumber } from 'app/shared/models/user-communication.model';
import {
  IUserPhoneNumber
} from '../../shared/models/lead-info-models';

export const actionClientDialogLogInUserEmailsAndPhoneNumbersRequest = createAction(
  '[Client Dialog User Communication Settings] Request LogIn User Emails And Phone Numbers Request',
)

export const actionClientDialogLogInUserEmailsAndPhoneNumbersSuccessed = createAction(
  '[Client Dialog User Communication Settings] Request LogIn User Emails And Phone Numbers Successed',
  props<{ emails: UserEmail[], phoneNumbers: IUserPhoneNumber[] }>()
)

export const actionClientDialogLogInUserEmailsAndPhoneNumbersFailed = createAction(
  '[Client Dialog User Communication Settings] Request LogIn User Emails And Phone Numbers Failed',
)

export const actionClientDialogCommunicationSettingsRequested = createAction(
  '[Client Dialog User Communication Settings] Communication Settings Requested',
  props<{ leadId: number }>()
)

export const actionClientDialogCommunicationSettingsSucceded = createAction(
  '[Client Dialog User Communication Settings] Communication Settings Succeded',
  props<{ emails: UserEmail[], phoneNumbers: UserPhoneNumber[], userId?: number }>()
)

export const actionClientDialogCommunicationSettingsFailed = createAction(
  '[Client Dialog User Communication Settings] Communication Settings Failed',
)

export const actionClientDialogLeadCallFromPhoneNumbersRequest = createAction(
  '[Client Dialog User Communication Settings] Lead Call From Phone Numbers Request',
  props<{ leadId: number }>()
)

export const actionClientDialogLeadCallFromPhoneNumbersSuccessed = createAction(
  '[Client Dialog User Communication Settings] Lead Call From Phone Numbers Successed',
  props<{ phoneNumbers: UserPhoneNumber[] }>()
)

export const actionClientDialogLeadCallFromPhoneNumbersFailed = createAction(
  '[Client Dialog User Communication Settings] Lead Call From Phone Numbers Failed',
)

export const actionClientDialogTopNavigationActiveTabChange = createAction(
  '[Client Dialog Top Navigation] Client Dialog Top Navigation Active Tab Change',
  props<{ tab: number }>()
)

export const actionClientDialogBaseStateReset = createAction(
  '[Client Dialog] Base State Reset'
);

export const actionClientDialogResetState = createAction(
  '[Client Dialog] Reset State'
);

export const clientDialogActions = {
  actionClientDialogBaseStateReset,
  actionClientDialogResetState,
  actionClientDialogLogInUserEmailsAndPhoneNumbersRequest,
  actionClientDialogLogInUserEmailsAndPhoneNumbersSuccessed,
  actionClientDialogLogInUserEmailsAndPhoneNumbersFailed,
  actionClientDialogCommunicationSettingsRequested,
  actionClientDialogCommunicationSettingsSucceded,
  actionClientDialogCommunicationSettingsFailed,
  actionClientDialogTopNavigationActiveTabChange,
};
