export interface NotificationManagerState {
  notifications: Notification[]
  notificationCount: number
}

export interface Notification {
  id: number | string
  type: string
  title: string
  content: string
  date: Date
}

export enum NotificationTypes {
  SYSTEM = 'system',
}
