<div class="timeline-unknown-component">
    <div class="desktop-container">
        <section>
            <article class="timeline-content">
                <header class="header">
                    <mat-icon class="timeline-icon unknown_alt">note_alt</mat-icon>
                    <div class="header-meta">
                        <strong>
                            Communication from an external system
                        </strong>
                        <em>
                            {{event.date|date: 'medium'}}
                        </em>
                    </div>
                </header>
                <div>
                </div>
            </article>
        </section>
    </div>
</div>