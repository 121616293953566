import { Action, createReducer, on } from "@ngrx/store";
import { TenantDialogTenantNoteState } from "../../tenant-dialog.state";
import { tenantDialogTenantNoteActions } from "./tenant-dialog-tenant-note.action";

const initialState: TenantDialogTenantNoteState = {
    isNoteSubmitting: false,
    isNoteSubmittedSuccessfully: false
}
const reducer = createReducer(
    initialState,
    on(
        tenantDialogTenantNoteActions.actionTenantDialogCreateNoteRequest,
        (state) => ({
            ...state,
            isNoteSubmitting: true,
            isNoteSubmittedSuccessfully: false
        })
    ),
    on(
        tenantDialogTenantNoteActions.actionTenantDialogCreateNoteSuccessed,
        (state) => ({
            ...state,
            isNoteSubmitting: false,
            isNoteSubmittedSuccessfully: true
        })
    ),
    on(
        tenantDialogTenantNoteActions.actionTenantDialogCreateNoteFailed,
        (state) => ({
            ...state,
            isNoteSubmitting: false,
            isNoteSubmittedSuccessfully: false
        })
    ),
    on(
        tenantDialogTenantNoteActions.actionTenantDialogTenantNoteStateReset,
        (state) => initialState
    )
)

export const tenantDialogTenantNoteReducer = (
    state: TenantDialogTenantNoteState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
