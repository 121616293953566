import { createSelector } from "@ngrx/store";
import { ClientDialogClientAppointmentState, ClientDialogState, selectClientDialog } from "app/features/client-dialog/client-dialog.state";

export const selectClientDialogClientAppointmentState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => {
    return state.clientDialogClientAppointment
  }
);

export const selectClientDialogIsClientAppointmentsLoaded = createSelector(
  selectClientDialogClientAppointmentState,
  (state: ClientDialogClientAppointmentState) => {
    return state.isAppointmentsLoaded
  }
)

export const selectClientDialogClientAppointments = createSelector(
  selectClientDialogClientAppointmentState,
  (state: ClientDialogClientAppointmentState) => {
    return state.clientAppointments
  }
)

export const selectClientDialogClientAppointmentAppointmentInEditting = createSelector(
  selectClientDialogClientAppointmentState,
  (state: ClientDialogClientAppointmentState) => {
    return state.appointmentInEditting
  }
)

export const selectClientDialogClientAppointmentLostReasonOptions = createSelector(
  selectClientDialogClientAppointmentState,
  (state: ClientDialogClientAppointmentState) => {
    return state.lostReasonOptions
  }
)

export const selectClientDialogClientAppointmentIsCancelling = createSelector(
  selectClientDialogClientAppointmentState,
  (state: ClientDialogClientAppointmentState) => {
    return state.isCancelling
  }
)

export const selectClientDialogClientAppointmentIsCancelledSuccessfully = createSelector(
  selectClientDialogClientAppointmentState,
  (state: ClientDialogClientAppointmentState) => {
    return state.isCancelledSuccessfully
  }
)