import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { Notification, NotificationTypes } from 'app/core/notification-manager/notification-manager.model';
import { selectNotificationNotifications } from 'app/core/notification-manager/notification-manager.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'dq-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  notifications$: Observable<Notification[]>;

  notification_type_system: string = NotificationTypes.SYSTEM;

  ngOnInit(): void {
    this.notifications$ = this.store.pipe(select(selectNotificationNotifications));
  }

}
