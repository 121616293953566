import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { DigiDecimalPipe } from '../pipes/digi-decimal-pipe.pipe';

@Directive({
  selector: '[cdkNumberDotComma]'
})
export class NumberDotCommaFormatDirective {

  @Input() maxValue: number;
  @Input() minValue: number;

  constructor(private el: ElementRef, private decimalPipe: DecimalPipe, private digiDecimalPipe: DigiDecimalPipe) { }

  @HostListener('input', ['$event']) onInput() {

    let val = this.digiDecimalPipe.transform(this.el.nativeElement.value);
    if (this.el.nativeElement.value == "" || isNaN(this.digiDecimalPipe.transform(this.el.nativeElement.value))) {
      this.el.nativeElement.value = 0;
    } else if (val > this.maxValue) {
      this.el.nativeElement.value = this.decimalPipe.transform(this.maxValue, '2.');
    } else if (val < 10) {
      this.el.nativeElement.value = val;
    } else {
      this.el.nativeElement.value = this.decimalPipe.transform(val, '2.');
    }
  }

  @HostListener('change', ['$event']) onChange() {
    let val = this.digiDecimalPipe.transform(this.el.nativeElement.value);
    if (val < this.minValue) {
      this.el.nativeElement.value = this.decimalPipe.transform(this.minValue, '2.');
    }
  }
}
