import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';

@Component({
  selector: 'dq-timeline-webform',
  templateUrl: './timeline-webform.component.html',
  styleUrls: ['./timeline-webform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimelineWebformComponent implements OnInit {

  @Input() event: ITimelineElement

  isMobile$: Observable<boolean>;

  constructor(public store: Store<State>) { }

  message: string;
  sourceName: string;
  unitDescription: string;
  bedrooms: string;
  bathrooms: string;
  moveInDate: string;
  buildingName: string;
  url: string;

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile));
    this.message = this.event.webFormMessage?.message;
    this.sourceName = this.event.webFormMessage?.sourceName;
    // this.unitDescription = this.event.leadWebFormMessage?.unitDescription;
    // this.bedrooms = JSON.parse(this.event.metaData)?.bedrooms;
    // this.bathrooms = JSON.parse(this.event.metaData)?.bathrooms;
    // this.moveInDate = JSON.parse(this.event.metaData)?.moveInDate;
    // this.buildingName = JSON.parse(this.event.metaData)?.buildingName;
    // this.url = JSON.parse(this.event.metaData)?.url;
  }
}
