import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private store: Store
  ) { }

  defaultNoSweet(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay',
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  infoNoSweet(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'info-notification-overlay',
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  successNoSweet(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay',
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  warnNoSweet(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay',
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  errorNoSweet(message: string) {
    this.show(message, {
      duration: 3000,
      panelClass: 'error-notification-overlay',
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  default(message: string) {
    this.sweetShow(message, 'info', false, 3000);
  }

  info(message: string) {
    this.sweetShow(message, 'info', false, 3000);
  }

  success(message: string) {
    this.sweetShow(message, 'success', false, 3000);
  }

  warn(message: string) {
    this.sweetShow(message, 'warning', false, 3000);
  }

  error(message: string) {
    this.sweetShow(message, 'error', false, 6000);
  }

  systemUpdateNotify(message: string) {
    this.sweetNotify(message, 'info', true, 'Refresh');
  }
  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }

  private sweetShow(
    message: string,
    icon: SweetAlertIcon,
    showConfirmButton: boolean,
    timer: number
  ) {
    Swal.fire({
      position: 'top-end',
      icon: icon,
      title: message,
      showConfirmButton: showConfirmButton,
      timer: timer
    });
  }

  private sweetNotify(
    message: string,
    icon: SweetAlertIcon,
    showConfirmButton: boolean,
    confirmButtonText: string) {
    Swal.fire({
      title: message,
      showConfirmButton: showConfirmButton,
      icon: icon,
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }
}
