import { createAction, props } from "@ngrx/store";
import { LeadBuilding } from "../../../../shared/models/lead-info-models";

export const actionClientDialogLeadBuildingsRequested = createAction(
    '[Client Dialog Buildings] Client Dialog Lead Buildings Requested',
    props<{ leadId: number }>()
)

export const actionClientDialogLeadBuildingsLoaded = createAction(
    '[Client Dialog Buildings] Client Dialog Lead Buildings Loaded',
    props<{ buildings: LeadBuilding[] }>()
)

export const actionClientDialogLeadBuildingsFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Lead Buildings Failed'
)

export const actionClientDialogBuildingOptionsRequested = createAction(
    '[Client Dialog Buildings] Client Dialog Building Options Requested',
)

export const actionClientDialogBuildingOptionsLoaded = createAction(
    '[Client Dialog Buildings] Client Dialog Building Options Loaded',
    props<{ buildings: LeadBuilding[] }>()
)


export const actionClientDialogBuildingsUpdate = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Update ',
    props<{ buildings: LeadBuilding[] }>()
);


export const actionClientDialogBuildingsBuildingIsCheckedUpdate = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Building Is Checked Update ',
    props<{ checked: boolean, building: LeadBuilding }>()
);


export const actionClientDialogBuildingOptionsCheckStateInitialize = createAction(
    '[Client Dialog Buildings] Client Dialog Building Options Check State Initalize',
)


export const actionClientDialogClientBuildingsFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Failed',
)


export const actionClientDialogBuildingOptionsFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Building Options Failed',
)


export const actionClientDialogBuildingsUpdateRequested = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Update Requested',
    props<{ id: number, buildings: LeadBuilding[] }>()
)

export const actionClientDialogBuildingsUpdateSuccessfully = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Update Successfully',
    props<{ buildings: LeadBuilding[] }>()
)

export const actionClientDialogBuildingsUpdateFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Update Failed',
)

export const actionClientDialogBatchBuildingsAddRequested = createAction(
    '[Client Dialog Buildings] Client Dialog Batch Buildings Add Requested',
    props<{ leadIds: number[], buildingIds: number[] }>()
)

export const actionClientDialogBatchBuildingsAddSuccessfully = createAction(
    '[Client Dialog Buildings] Client Dialog Batch Buildings Add Successfully',
)

export const actionClientDialogBatchBuildingsAddFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Batch Buildings Add Failed',
)

export const actionClientDialogBatchBuildingsRemoveRequested = createAction(
    '[Client Dialog Buildings] Client Dialog Batch Buildings Remove Requested',
    props<{ leadIds: number[], buildingIds: number[] }>()
)

export const actionClientDialogBatchBuildingsRemoveSuccessfully = createAction(
    '[Client Dialog Buildings] Client Dialog Batch Buildings Remove Successfully',
)

export const actionClientDialogBatchBuildingsRemoveFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Batch Buildings Remove Failed',
)

export const actionClientDialogClientBuildingDeleteRequested = createAction(
    '[Client Dialog TBuildingsags] Client Dialog Cilent Building Delete Requested',
    props<{ leadId: number, buildingId: number }>()
)

export const actionClientDialogBuildingsCreateUpdateSuccessfulStateReset = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings Create Update Successfully State Reset'
)

export const actionClientDialogClientBuildingDeleteSuccessfully = createAction(
    '[Client Dialog Buildings] Client Dialog Client Building Delete Successfully',
    props<{ buildings: LeadBuilding[] }>()
)

export const actionClientDialogClientBuildingDeleteFailed = createAction(
    '[Client Dialog Buildings] Client Dialog Client Building Delete Failed',
)

export const actionClientDialogBuildingsReset = createAction(
    '[Client Dialog Buildings] Client Dialog Buildings  Reset',
)

export const clientDialogBuildingActions = {
    actionClientDialogLeadBuildingsRequested,
    actionClientDialogLeadBuildingsLoaded,
    actionClientDialogLeadBuildingsFailed,
    actionClientDialogBuildingOptionsFailed,
    actionClientDialogClientBuildingsFailed,
    actionClientDialogBuildingsUpdateFailed,
    actionClientDialogClientBuildingDeleteFailed,
    actionClientDialogBatchBuildingsAddRequested,
    actionClientDialogBatchBuildingsAddSuccessfully,
    actionClientDialogBatchBuildingsAddFailed,
    actionClientDialogBatchBuildingsRemoveRequested,
    actionClientDialogBatchBuildingsRemoveSuccessfully,
    actionClientDialogBatchBuildingsRemoveFailed,
    actionClientDialogBuildingOptionsRequested,
    actionClientDialogBuildingOptionsLoaded,
    actionClientDialogBuildingsUpdate,
    actionClientDialogBuildingsBuildingIsCheckedUpdate,
    actionClientDialogBuildingsUpdateRequested,
    actionClientDialogBuildingsUpdateSuccessfully,
    actionClientDialogClientBuildingDeleteRequested,
    actionClientDialogClientBuildingDeleteSuccessfully,
    actionClientDialogBuildingOptionsCheckStateInitialize,
    actionClientDialogBuildingsCreateUpdateSuccessfulStateReset,
    actionClientDialogBuildingsReset,
}