import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { actionClientDialogResetState, actionClientDialogLeadCallFromPhoneNumbersRequest, actionClientDialogLeadCallFromPhoneNumbersSuccessed, actionClientDialogLeadCallFromPhoneNumbersFailed, clientDialogActions } from "./client-dialog.actions";
import { ClientDialogService } from "./client-dialog.service";
import { actionClientDialogActivityStateReset } from "./components/client-dialog-activities/client-dialog-activities.action";
// import { actionClientDialogEmailEditorStateReset } from "./pages/client-dialog-email-editor/client-dialog-email-editor.action";
import { actionClientDialogLeadNoteStateReset } from "./components/client-dialog-lead-note/client-dialog-lead-note.action";
// import { actionClientDialogTextMessageStateReset } from "./pages/client-dialog-lead-text-message/client-dialog-lead-text-message.action";
import { actionClientDialogGeneralStateReset } from "./components/client-dialog-user-general/client-dialog-general.action";
import { UserEmail, UserPhoneNumber } from "app/shared/models/user-communication.model";
import { select, Store } from "@ngrx/store";
import { State } from "./client-dialog.state";
import { selectUserId } from "app/core/auth/auth.selectors";
import { actionClientDialogTextMessagestateReset } from "./components/client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message.action";
import { NotificationService } from "app/core/core.module";


@Injectable()
export class ClientDialogEffects {
  constructor(
    private actions$: Actions,
    private clientDialogService: ClientDialogService,
    private notificationService: NotificationService,
    private store: Store<State>,
  ) { }

  // requestLoginUserEmailsAndPhoneNumbers$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actionClientDialogLogInUserEmailsAndPhoneNumbersRequest),
  //     switchMap(action =>
  //       this.clientDialogService.requestUserCommunicationSettings().pipe(
  //         map((response => {
  //           if (response.status == 1) {
  //             let emails: UserEmail[] = response.data.emails
  //             let phoneNumbers: IUserPhoneNumber[] = response.data.phoneNumbers
  //             return actionClientDialogLogInUserEmailsAndPhoneNumbersSuccessed({ emails, phoneNumbers })
  //           } else {
  //             return actionClientDialogLogInUserEmailsAndPhoneNumbersFailed()
  //           }
  //         }),
  //           catchError(error => {
  //             return of(actionClientDialogLogInUserEmailsAndPhoneNumbersFailed())
  //           })
  //         )
  //       )
  //     )
  //   )
  // );

  clientDialogRequestCommunicationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDialogActions.actionClientDialogCommunicationSettingsRequested),
      withLatestFrom(this.store.pipe(select(selectUserId))),
      switchMap(([action, userId]) =>
        this.clientDialogService.requestUserCommunicationSettings(action.leadId).pipe(
          map((response => {
            if (response.status == 1) {
              let emails: UserEmail[] = response.data.emails
              let phoneNumbers: UserPhoneNumber[] = response.data.phoneNumbers
              return clientDialogActions.actionClientDialogCommunicationSettingsSucceded({ emails, phoneNumbers, userId })
            } else {
              this.notificationService.error(response.message);
              return clientDialogActions.actionClientDialogCommunicationSettingsFailed()
            }
          }),
            catchError(error => {
              return of(clientDialogActions.actionClientDialogCommunicationSettingsFailed())
            })
          )
        )
      )
    )
  );

  requestLeadCallFromPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionClientDialogLeadCallFromPhoneNumbersRequest),
      switchMap(action =>
        this.clientDialogService.requestLeadCommunicationSettings(action.leadId).pipe(
          map((response => {
            if (response.status == 1) {
              let phoneNumbers: UserPhoneNumber[] = response.data.phoneNumbers
              return actionClientDialogLeadCallFromPhoneNumbersSuccessed({ phoneNumbers })
            } else {
              this.notificationService.error(response.message);
              return actionClientDialogLeadCallFromPhoneNumbersFailed()
            }
          }),
            catchError(error => {
              return of(actionClientDialogLeadCallFromPhoneNumbersFailed())
            })
          )
        )
      )
    )
  );

  clientDialogStateReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionClientDialogResetState),
      switchMap(action => [
        actionClientDialogActivityStateReset(),
        // actionClientDialogEmailEditorStateReset(),
        actionClientDialogLeadNoteStateReset(),
        actionClientDialogTextMessagestateReset(),
        actionClientDialogGeneralStateReset()
      ])
    )
  )

}
