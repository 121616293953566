import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpinnerButtonComponent } from './component/spinner-button.component';

import {  MatButtonModule } from '@angular/material/button';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

// Export module's public API
export { SpinnerButtonComponent } from './component/spinner-button.component';

export { MatProgressButtonOptions } from './spinner-button.interface';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatIconModule
  ],
  exports: [SpinnerButtonComponent],
  declarations: [SpinnerButtonComponent]
})
export class SpinnerButtonModule {
  /*
  see : https://www.npmjs.com/package/mat-progress-buttons/v/9.3.1
   */
}
