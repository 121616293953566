export namespace PropertyBuildingFilters {
  export interface FilterCriteria {
    id: string;
    type: string;
    tag: string;
  }

  export interface Operator {
    type: string;
  }

  export enum FilterOperatorTypes {
    OPERATOR_OR = 'OPERATOR_OR',
    OPERATOR_AND = 'OPERATOR_AND',
    OPERATOR_TO = 'OPERATOR_TO',
    OPERATOR_OPEN_ANGLED_BRACKET = 'OPERATOR_OPEN_ANGLED_BRACKET',
    OPERATOR_CLOSE_ANGLED_BRACKET = 'OPERATOR_CLOSE_ANGLED_BRACKET',
    OPERATOR_OPEN_ANGLED_BRACKET_EQUAL = 'OPERATOR_OPEN_ANGLED_BRACKET_EQUAL',
    OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL = 'OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL',
    OPERATOR_NOT_EQUAL = 'OPERATOR_NOT_EQUAL',
    OPERATOR_EQUAL = 'OPERATOR_EQUAL'
  }

  export enum FilterCriteriaTypes {
    QUERY_PARAMETER_TYPES = 'QUERY_PARAMETER_TYPES',
    QUERY_PARAMETER_STYLES = 'QUERY_PARAMETER_STYLES',
    QUERY_PARAMETER_LISTINGS_FOR_SALE_COUNT = 'QUERY_PARAMETER_LISTINGS_FOR_SALE_COUNT',
    QUERY_PARAMETER_LISTINGS_FOR_RENT_COUNT = 'QUERY_PARAMETER_LISTINGS_FOR_RENT_COUNT',
    QUERY_PARAMETER_BEDS = 'QUERY_PARAMETER_BEDS',
    QUERY_PARAMETER_BATHS = 'QUERY_PARAMETER_BATHS',
    QUERY_PARAMETER_PRICE = 'QUERY_PARAMETER_PRICE',
    QUERY_PARAMETER_TRANSACTION_TYPE = 'QUERY_PARAMETER_TRANSACTION_TYPE',
    QUERY_PARAMETER_SIZE = 'QUERY_PARAMETER_SIZE',
    QUERY_PARAMETER_AMENITIES = 'QUERY_PARAMETER_AMENITIES',
    QUERY_PARAMETER_MARKET_DATE_DAYS = 'QUERY_PARAMETER_MARKET_DATE',
    QUERY_PARAMETER_AGE = 'QUERY_PARAMETER_AGE',
    QUERY_PARAMETER_GEO_POLYGONS = 'QUERY_PARAMETER_GEO_POLYGONS'
  }

  export class OperatorOr implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_OR;

    constructor() {}
  }
  export class OperatorAnd implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_AND;
    constructor() {}
  }
  export class OperatorTo implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_TO;
    constructor() {}
  }
  export class OperatorOpenAngledBracket implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET;
    constructor() {}
  }
  export class OperatorCloseAngledBracket implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET;
    constructor() {}
  }
  export class OperatorOpenAngledBracketEqual implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL;
    constructor() {}
  }
  export class OperatorCloseAngledBracketEqual implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL;
    constructor() {}
  }
  export class OperatorNotEqual implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_NOT_EQUAL;
    constructor() {}
  }
  export class OperatorEqual implements Operator {
    readonly type = FilterOperatorTypes.OPERATOR_EQUAL;
    constructor() {}
  }

  export class BuildingTypes implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_TYPES;
    tag = '';
    criteria: {
      id: string;
      value: string;
    };
    // constructor(public criteria: { id: string, value: string; }) {}
  }
  export class BuildingStyles implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_STYLES;
    tag = '';
    criteria: {
      id: string;
      value: string;
    };
    // constructor(public criteria: { id: string, value: string; }) {}
  }
  export class ListingsForSaleCount implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_LISTINGS_FOR_SALE_COUNT;
    tag = '';
    criteria: {
      value: number;
      operator: Operator;
    };
    // constructor(public criteria: { value: number; operator: Operator }) {}
  }
  export class ListingsForRentCount implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_LISTINGS_FOR_RENT_COUNT;
    tag = '';
    criteria: {
      value: number;
      operator: Operator;
    };
    // constructor(public criteria: { value: number; operator: Operator }) {}
  }
  export class Beds implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_BEDS;
    tag = '';
    criteria: {
      id: string;
      value: number;
      operator: Operator;
    };
    // constructor(public criteria: { id: string, value: number; operator: Operator }) {}
  }
  export class Baths implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_BATHS;
    tag = '';
    criteria: {
      id: string;
      value: number;
      operator: Operator;
    };
    // constructor(public criteria: { id: string, value: number; operator: Operator }) {}
  }
  export class Price implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_PRICE;
    tag = '';
    criteria: {
      minValue?: number;
      maxValue?: number;
      operator: Operator;
    };
    // constructor(public criteria: { minValue?: number; maxValue?: number; operator: Operator }) {}
  }
  export class TransactionType implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_TRANSACTION_TYPE;
    tag = '';
    criteria: {
      id: string;
      value: string;
    };
    // constructor(public criteria: { id: string, value: string; }) {}
  }
  export class Size implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_SIZE;
    tag = '';
    criteria: {
      minValue?: number;
      maxValue?: number;
      operator: Operator;
    };
    // constructor(public criteria: { minValue?: number; maxValue?: number; operator: Operator }) {}
  }
  export class Amenities implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_AMENITIES;
    tag = '';
    criteria: {
      id: string;
      value: string;
    };
    // constructor(public criteria: { id: string, value: string; }) {}
  }
  export class MarketDate implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_MARKET_DATE_DAYS;
    tag = '';
    criteria: {
      id: string;
      value: number;
      operator: Operator;
    };
    // constructor(public criteria: { id: string, value: number; operator: Operator }) {}
  }
  export class BuildingAge implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_AGE;
    tag = '';
    criteria: {
      id: string;
      value: number;
      operator: Operator;
    };
    // constructor(public criteria: { id: string, value: number; operator: Operator }) {}
  }
  export class GeoPolygons implements FilterCriteria {
    id: string;
    readonly type = FilterCriteriaTypes.QUERY_PARAMETER_GEO_POLYGONS;
    tag = '';
    criteria: {
      id: string;
      value: string[];
    };
    // constructor(public criteria: { id: string, value: string[]; }) {}
  }

  export type FilterCriteriaOperators =
    | OperatorOr
    | OperatorAnd
    | OperatorTo
    | OperatorOpenAngledBracket
    | OperatorCloseAngledBracket
    | OperatorOpenAngledBracketEqual
    | OperatorCloseAngledBracketEqual
    | OperatorNotEqual
    | OperatorEqual;

  export type FilterCriterias =
    | BuildingTypes
    | BuildingStyles
    | ListingsForSaleCount
    | ListingsForRentCount
    | Beds
    | Baths
    | Price
    | TransactionType
    | Size
    | Amenities
    | MarketDate
    | BuildingAge
    | GeoPolygons;
}
