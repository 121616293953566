<div class="tenant-dialog-user-general-component">
  <!-- an hidden div is created to set the position of appearance of the menu-->
  <div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu"></div>

  <!-- standard material menu -->
  <mat-menu #rightMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <ng-container *ngIf="!ifPhoneNumberBlocked(item)">
        <button style="background-color: #ffd1d1;" mat-menu-item (click)="openPhoneNumberDialog(item)">
          <mat-icon>notifications_off</mat-icon>
          <span style="font-weight: 600;"> Block </span> ( <span [dqPhoneNumber]="item"></span> )
        </button>
      </ng-container>
      <ng-container *ngIf="ifPhoneNumberBlocked(item)">
        <button style="background-color: #f5cab1;" mat-menu-item (click)="openPhoneNumberDialog(item)">
          <mat-icon>notifications</mat-icon>
          <span style="font-weight: 600;"> Unblock </span> ( <span [dqPhoneNumber]="item"></span> )
        </button>
      </ng-container>
    </ng-template>
  </mat-menu>
    <div class="desktop-container">
      <ng-container *ngIf="tenantInfo$ | async as tenantInfo">
        <div class="base-info-container dq-box-shadow">
          <mat-card>
            <div class="edit-icon-div">
              <ng-container *ngIf="!(isBaseInfoEditMode$ | async); else baseInfoEdit">
                <mat-icon class="edit-icon" (click)="toggleClientInfoEditMode(true)">create</mat-icon>
              </ng-container>
              <ng-template #baseInfoEdit>
                <ng-container *ngIf="(isBaseInfoSubmitting$ | async); else canSave">
                  <mat-spinner></mat-spinner>
                </ng-container>
                <ng-template #canSave>
                  <mat-icon class="edit-icon" (click)="saveChanges()">save</mat-icon>
                </ng-template>
                <mat-icon class="edit-icon" (click)="toggleClientInfoEditMode(false)">cancel</mat-icon>
              </ng-template>
            </div>
            <!--
              <div class="col-2 profile-image-div">
                <div id="profileImage">
                  {{initialName ? initialName : ' '}}
                </div>
              </div>
              -->
            <div class="basic-info">

              <div class="tenant-name" [cdkCopyToClipboard]="tenantInfo.firstName + ' ' + tenantInfo.lastName"
                (cdkCopyToClipboardCopied)="copiedElement = tenantInfo.firstName + ' ' + tenantInfo.lastName"
                [matTooltip]="copiedElement === tenantInfo.firstName + ' ' + tenantInfo.lastName? 'Copied!' : 'Copy'"
                matTooltipPosition="right">{{ tenantInfo.firstName ?? ' '}} {{ tenantInfo.lastName ??
                ''}}<mat-icon class="icon-copy" svgIcon="clone"></mat-icon></div>

              <div class="subtitle">Created <span class="subtitle-span">{{(tenantInfo.createdOn | amFromUtc | amLocal |
                  amTimeAgo)}}</span></div>
              <div class="subtitle" *ngIf="tenantInfo.lastCommunicationDate">Last Communication <span
                  class="subtitle-span">{{(tenantInfo.lastCommunicationDate |
                  amFromUtc | amLocal | amTimeAgo)}}</span></div>
              <div *ngIf="!(tenantInfo.lastCommunicationDate)" class="subtitle">No communication yet</div>
              <div style="padding-bottom: 10px;"></div>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngIf="!(isBaseInfoEditMode$ | async)">
              <div class="contact-info-container">
                <div class="contact-info-detail">
                  <div class="contact-info-detail-label" *ngFor="let phone of tenantInfo.phoneNumbers"
                    [ngClass]="{'blocked-phoneNumber' : ifPhoneNumberBlocked(phone), 'unblocked-phoneNumber':  !ifPhoneNumberBlocked(phone)}"
                    (cdkCopyToClipboardCopied)="copiedElement = phone" (contextmenu)="onPhoneRightClick($event, phone)"
                    [matTooltip]="copiedElement === phone ? 'Copied!' : 'Copy'" [cdkCopyToClipboard]="phone"
                    matTooltipPosition="right">
                    <mat-icon class="icon-general icon-phone" svgIcon="phone"></mat-icon>
                    <span [dqPhoneNumber]="phone"></span>
                    <mat-icon class="icon-copy" svgIcon="clone"></mat-icon>
                  </div>
                  <div class="contact-info-detail-label" [cdkCopyToClipboard]="email"
                    (cdkCopyToClipboardCopied)="copiedElement = email"
                    [matTooltip]="copiedElement === email ? 'Copied!' : 'Copy'" *ngFor="let email of tenantInfo.emails"
                    matTooltipPosition="right"><mat-icon class="icon-general icon-envelope"
                      svgIcon="envelope"></mat-icon> {{email}}
                    <mat-icon class="icon-copy" svgIcon="clone"></mat-icon>
                  </div>
                </div>
              </div>

              <div class="action-container">
                <!-- <button [disabled]="!(hasAccessToBooking() | async)" class="action-btn booking-btn"
                  (click)="openBookAppointmentDialog()">
                  <mat-icon style="margin-left: 5px;">calendar_today</mat-icon> Book
                </button> -->
                <ng-container *ngIf="(callFromPhoneNumbers$ | async).length >= 1 && tenantInfo.phoneNumbers.length >= 1">
                  <!-- Option one from one to -->
                  <ng-container
                    *ngIf="(callFromPhoneNumbers$ | async).length <= 1 && tenantInfo.phoneNumbers.length <= 1">
                    <button
                      [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async) || tenantInfo.phoneNumbers.length === 0"
                      (click)="makeOutgoingCall(callFromPhoneNumbers[0].phoneNumber, tenantInfo.phoneNumbers[0])"
                      class="action-btn call-btn">
                      <mat-icon>call</mat-icon> Call
                    </button>
                  </ng-container>
                  <!-- Option one from many to -->
                  <ng-container *ngIf="(callFromPhoneNumbers$ | async).length <= 1 && tenantInfo.phoneNumbers.length > 1">
                    <button class="action-btn call-btn"
                      [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async)"
                      [matMenuTriggerFor]="menu">
                      <mat-icon>call</mat-icon> Call
                    </button>
                    <mat-menu #menu="matMenu">
                      <ng-container *ngFor="let phone of tenantInfo.phoneNumbers;let i = index">
                        <button (click)="makeOutgoingCall(callFromPhoneNumbers[0].phoneNumber, phone)" mat-menu-item>
                          <mat-icon>call</mat-icon>
                          <span>{{phone}}</span>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </ng-container>
                  <!-- Option many from one to -->
                  <ng-container *ngIf="(callFromPhoneNumbers$ | async).length > 1 && tenantInfo.phoneNumbers.length <= 1">
                    <button class="action-btn call-btn"
                      [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async)"
                      [matMenuTriggerFor]="menu">
                      <mat-icon>call</mat-icon> Call
                    </button>
                    <mat-menu #menu="matMenu">
                      <ng-container *ngFor="let phone of (callFromPhoneNumbers$ | async);let i = index">
                        <button (click)="makeOutgoingCall(phone.phoneNumber, tenantInfo.phoneNumbers[0])" mat-menu-item>
                          <mat-icon>call</mat-icon>
                          <span>{{phone.friendlyName}} ({{phone.phoneNumber}})</span>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </ng-container>
                  <!-- Option many from many to -->
                  <ng-container *ngIf="(callFromPhoneNumbers$ | async).length > 1 && tenantInfo.phoneNumbers.length > 1">
                    <button class="action-btn call-btn"
                      [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async)"
                      [matMenuTriggerFor]="menuRoot">
                      <mat-icon>call</mat-icon> Call
                    </button>

                    <mat-menu #menuRoot="matMenu">
                      <ng-container *ngFor="let item of phoneNavItems">
                        <!-- Handle branch node buttons here -->
                        <ng-container *ngIf="item.children && item.children.length > 0">
                          <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
                            <mat-icon>{{item.iconName}}</mat-icon>
                            <span>{{item.displayName}}</span>
                          </button>
                          <dq-phone-number-menu (phoneNumberSelected)="makeOutgoingCallMultiSelect($event)" #menu
                            [items]="item.children"></dq-phone-number-menu>
                        </ng-container>
                      </ng-container>
                    </mat-menu>

                  </ng-container>
                </ng-container>
              </div>
              <div></div>
            </ng-container>
            <ng-container *ngIf="(isBaseInfoEditMode$ | async)">
              <form class="base-info-form" [formGroup]="tenantInfoForm" *ngIf="tenantInfo as tenant">
                <mat-form-field appearance="outline" class="col-6">
                  <mat-label>{{'cdk.tenant-dialog-user-profile.firstName' | translate}}</mat-label>
                  <input matInput formControlName="firstName">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-6">
                  <mat-label>{{'cdk.tenant-dialog-user-profile.lastName' | translate}}</mat-label>
                  <input matInput formControlName="lastName">
                </mat-form-field>
                <ng-container formArrayName="emails" *ngFor="let email of emailArray.controls; let emailIndex = index">
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'cdk.tenant-dialog-user-profile.email' | translate}}</mat-label>
                    <input #emailInput matInput placeholder="Email" [formControlName]="emailIndex" requiredid="email">
                    <span matSuffix class="remove-text" *ngIf="emailIndex > 0"
                      (click)="removeEmail(emailIndex)">remove</span>
                  </mat-form-field>
                </ng-container>
                <div class="more-recipient-container" *ngIf="emailArray.length < 4">
                  <div class="more-recipient" (click)="addEmail()">
                    add email +
                  </div>
                </div>
                <ng-container formArrayName="phoneNumbers"
                  *ngFor="let phoneNumber of phoneArray.controls; let phoneIndex = index">
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'cdk.tenant-dialog-user-profile.phone' | translate}}</mat-label>
                    <ngx-mat-intl-tel-input #phoneInput (countryChanged)="countryChanged($event)" [cssClass]="'custom'"
                      [preferredCountries]="['ca', 'us']" placeholder="Enter Number" [enablePlaceholder]="false"
                      [enableSearch]="true" [formControlName]="phoneIndex">
                    </ngx-mat-intl-tel-input>
                    <span matSuffix class="remove-text" *ngIf="phoneIndex > 0"
                      (click)="removePhone(phoneIndex)">remove</span>
                  </mat-form-field>
                </ng-container>
                <div class="more-recipient-container" *ngIf="phoneArray.length < 4">
                  <div class="more-recipient" (click)="addPhone()">
                    add phoneNumber +
                  </div>
                </div>
                <ng-container *ngIf="tenantInfoForm.hasError('atLeastOne')">
                  <div class="alert alert-danger col-12">
                    Email and Phone Number at least one is required.
                  </div>
                </ng-container>
              </form>
            </ng-container>
          </mat-card>
        </div>

        <div class="tags-info-container">
          <dq-tenant-dialog-tenant-tag></dq-tenant-dialog-tenant-tag>
        </div>

        <div class="reps-info-container">
          <dq-tenant-dialog-tenant-rep></dq-tenant-dialog-tenant-rep>
        </div>

        <div class="tasks-info-container">
          <dq-tenant-dialog-task></dq-tenant-dialog-task>
        </div>

        <div class="buildings-info-container">
          <dq-tenant-dialog-tenant-building></dq-tenant-dialog-tenant-building>
        </div>

      </ng-container>
    </div>
</div>