import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import {
  actionCallManagerLogEvent,
  actionCallManagerRegisterSettingsFromLocalStorageRequst,
  actionCallManagerTransferPhoneQueueOptionsRequested,
  actionCallManagerUpdateCallPanel,
  actionCallManagerUserPhoneNumberSettingsRequested
} from 'app/core/call-manager/call-manager.actions';
import { DeviceAuth } from 'app/core/call-manager/call-manager.model';
import {
  selectCallManagerCallFromPhoneNumbers,
  selectCallManagerIsCallPanelActive,
  selectCallManagerIsDeviceRegistered,
  selectCallManagerIsIncomingCallConnected,
  selectCallManagerIsOutgoingCallConnected,
  selectRegisteredDeviceAuth,
  selectRegisteredDeviceIdentity,
  selectRegisteredDeviceLogs,
  selectRegisteredDeviceToken
} from 'app/core/call-manager/call-manager.selectors';
import { CallManagerService } from 'app/core/call-manager/call-manager.service';
import { selectIsAuthenticated } from 'app/core/core.module';
import { AppState } from 'app/core/core.state';
import { UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OnGoingCallHandlerComponent } from '../on-going-call-handler/on-going-call-handler.component';

@Component({
  selector: 'dq-call-settings',
  templateUrl: './call-settings.component.html',
  styleUrls: ['./call-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CallSettingsComponent implements OnInit, OnDestroy {
  isIncomingCallOnGoing: boolean;
  isOutgoingCallOnGoing: boolean;
  isCallPanelActive: boolean;

  constructor(
    private store: Store<AppState>,
    private callManagerService: CallManagerService
  ) {
  }

  isAuthenticated$: Observable<boolean>;
  unsubscribe: Subject<void> = new Subject();
  identity$: Observable<string | undefined> | undefined;
  logs$: Observable<string[]> | undefined;
  isCallPanelActive$: Observable<boolean>;
  isDeviceSettingVisible = false;
  isDeviceLogVisible = false;

  isIncomingCallOnGoing$: Observable<boolean>;
  isOutgoingCallOnGoing$: Observable<boolean>;

  get speakerMediaDevices(): MediaDeviceInfo[] {
    return this.callManagerService.speakerMediaDevices;
  }

  get ringtoneMediaDevices(): MediaDeviceInfo[] {
    return this.callManagerService.ringtoneMediaDevices;
  }

  speakerDeviceFormControl = new UntypedFormControl(this.callManagerService.device?.audio?.speakerDevices.get().values()?.[0]);
  ringtoneDeviceFormControl = new UntypedFormControl(this.callManagerService.device?.audio?.ringtoneDevices.get());
  identity = '';

  snackBarRef: MatSnackBarRef<OnGoingCallHandlerComponent>;

  callFromPhoneNumbers$: Observable<UserPhoneNumber[]>;
  callFromPhoneNumberFormControl: UntypedFormControl = new UntypedFormControl();

  deviceAuth$: Observable<DeviceAuth>;
  token$: Observable<string | undefined> | undefined;
  isDeviceRegistered$: Observable<boolean>;
  isDeviceRegistered: boolean;

  ngOnInit() {
    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.isAuthenticated$.pipe(takeUntil(this.unsubscribe)).subscribe(isAuthenticated => {
      if (isAuthenticated === true) {
        this.store.dispatch(actionCallManagerRegisterSettingsFromLocalStorageRequst());
        this.store.dispatch(actionCallManagerUserPhoneNumberSettingsRequested());
        this.store.dispatch(actionCallManagerTransferPhoneQueueOptionsRequested());
      }
    });

    this.callManagerService.onSpeakerChange().pipe(take(1)).subscribe(() => {
      this.speakerDeviceFormControl.setValue(this.callManagerService.speakerDevices.deviceId, { emitEvent: false });
    });
    this.callManagerService.onRingtoneChange().pipe(take(1)).subscribe(() => {
      this.ringtoneDeviceFormControl.setValue(this.callManagerService.ringtoneDevices.deviceId, { emitEvent: false });
    });

    this.deviceAuth$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectRegisteredDeviceAuth));
    this.token$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectRegisteredDeviceToken));
    this.identity$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectRegisteredDeviceIdentity));
    this.logs$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectRegisteredDeviceLogs));
    this.callFromPhoneNumbers$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerCallFromPhoneNumbers));
    this.isDeviceRegistered$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsDeviceRegistered));
    this.isCallPanelActive$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsCallPanelActive));
    this.isIncomingCallOnGoing$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsIncomingCallConnected));
    this.isOutgoingCallOnGoing$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsOutgoingCallConnected));

    this.callFromPhoneNumbers$.pipe(takeUntil(this.unsubscribe)).subscribe((callFromPhoneNumber) => {
      this.callFromPhoneNumberFormControl.setValue(callFromPhoneNumber[0]?.phoneNumber);
    });

    this.identity$.pipe(takeUntil(this.unsubscribe)).subscribe((identity) => {
      if (identity != null) {
        this.identity = identity;
      }
    });

    this.deviceAuth$.pipe(takeUntil(this.unsubscribe)).subscribe((deviceAuth) => {
      this.callManagerService.registerDevice(deviceAuth);
    });

    this.speakerDeviceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value) {
        this.callManagerService.setSpeakerDeviceVolume(value);
      }
    });
    this.ringtoneDeviceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value) {
        this.callManagerService.setRingtoneDeviceVolume(value);
      }
    });

    this.isDeviceRegistered$.pipe(takeUntil(this.unsubscribe)).subscribe(isDeviceRegistered => {
      this.isDeviceRegistered = isDeviceRegistered;
    });

    this.isIncomingCallOnGoing$.pipe(takeUntil(this.unsubscribe)).subscribe(isIncomingCallOnGoing => {
      this.isIncomingCallOnGoing = isIncomingCallOnGoing;
    });

    this.isOutgoingCallOnGoing$.pipe(takeUntil(this.unsubscribe)).subscribe(isOutgoingCallOnGoing => {
      this.isOutgoingCallOnGoing = isOutgoingCallOnGoing;
    });
    this.isCallPanelActive$.pipe(takeUntil(this.unsubscribe)).subscribe(isCallPanelActive => {
      this.isCallPanelActive = isCallPanelActive;
    });
  }

  isActionDisabled() {
    return !this.isDeviceRegistered || !!this.isIncomingCallOnGoing || !!this.isOutgoingCallOnGoing || !!this.isCallPanelActive
  }

  startOutgoingCall(to: string) {
    if (this.callFromPhoneNumberFormControl.value && to) {
      this.callManagerService.startOutgoingCall(this.callFromPhoneNumberFormControl.value, to);
    }
  }

  log(log: string) {
    this.store.dispatch(actionCallManagerLogEvent({ event: log }));
  }

  doLoginAndRegister() {
    this.log('Login and Registering the device...');
    this.callManagerService.doLoginAndRegister();
  }

  doLogoutAndUnregister() {
    this.log('Unregistering the device...');
    this.callManagerService.doLogoutAndUnregister();
  }

  closePanel() {
    this.store.dispatch(actionCallManagerUpdateCallPanel({ isCallPanelActive: false }));
    this.snackBarRef?.dismiss();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
