import { createReducer, on } from "@ngrx/store";
import { tenantDialogActions } from "../../tenant-dialog.actions";
import { TenantDialogEmailEditorState } from "../../tenant-dialog.state";
import { actionTenantDialogEmailEditorActions } from "./tenant-dialog-email-editor.action";
import { EmailSendType } from "app/shared/models/user-communication.model";

const initialSate: TenantDialogEmailEditorState = {
    isEmailSubmitting: false,
    isEmailSubmittedSuccessfully: false,
    emailSendType: EmailSendType.SEND,
    emailSendingExternalIdentifier: '',
    tenantUserEmails: [],
    emailEditor: {
        email: '',
        subject: '',
        sendToBccs: [],
        sendToCcs: [],
        attachements: [],
        sendFromUserId: 0,
        sendToEmails: []
    },
    emailReplyEditor: {
        email: '',
        subject: '',
        sendToBccs: [],
        sendToCcs: [],
    },
    isForwarding: false,
    replayAllTo: [],
    isCcAndBccActive: false,
    emailTemplates: [],
    isTemplatesLoaded: false,
    isTemplateSaveOrUpdateing: false,
    isTemplateSaveOrUpdateSuccessfully: false,
    isTemplateDeletedSuccessfully: false,
    isTemplateDeleting: false
}

const reducer = createReducer(
    initialSate,
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailEditorStateReset,
        (state) => initialSate
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailRequest,
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailAndCollaborateRequest,
        (state) => ({
            ...state,
            isEmailSubmitting: true,
            isEmailSubmittedSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailSuccessed,
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailAndCollaborateSuccessed,
        (state) => ({
            ...state,
            isEmailSubmitting: false,
            isEmailSubmittedSuccessfully: true
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailFailed,
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailAndCollaborateFailed,
        (state) => ({
            ...state,
            isEmailSubmitting: false,
            isEmailSubmittedSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailReplyEditorIsForwardingUpdate,
        (state, { isForwarding }) => ({
            ...state,
            isForwarding: isForwarding
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailReplyEditorReplyAllToUpdate,
        (state, { replyAllTo }) => ({
            ...state,
            replayAllTo: replyAllTo
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailEditorIsCcAndBccActiveUpdate,
        (state, { isActive }) => ({
            ...state,
            isCcAndBccActive: isActive
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate,
        (state, { sendType, externalIdentifier }) => ({
            ...state,
            emailSendType: sendType,
            emailSendingExternalIdentifier: externalIdentifier
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailEditorReset,
        (state) => ({
            ...state,
            emailEditor: initialSate.emailEditor
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailReplyEditorReset,
        (state) => ({
            ...state,
            emailReplyEditor: initialSate.emailReplyEditor
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailEditorUpdate,
        (state, { email, sendFromUserId, sendToEmails, sendToCcs, sendToBccs, subject, attachements }) => ({
            ...state,
            emailEditor: {
                ...state.emailEditor,
                email: email,
                sendFromUserId: sendFromUserId,
                sendToEmails: sendToEmails,
                sendToCcs: sendToCcs,
                sendToBccs: sendToBccs,
                subject: subject,
                attachements: attachements
            }
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogSendEmailReplyEditorUpdate,
        (state, { email, sendToCcs, sendToBccs, subject }) => ({
            ...state,
            emailReplyEditor: {
                ...state.emailReplyEditor,
                email: email,
                sendToCcs: sendToCcs,
                sendToBccs: sendToBccs,
                subject: subject,
            }
        })
    ),
    on(
        tenantDialogActions.actionTenantDialogCommunicationSettingsSucceded,
        (state, { emails, phoneNumbers, userId }) => ({
            ...state,
            tenantUserEmails: emails,
            emailEditor: {
                ...state.emailEditor,
                sendFromUserId: emails.find(x => x.userId == userId) != null ? userId : emails[0]?.userId
            }
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateSaveAndUpdate,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: true,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateSaveAndUpdateSucceeded,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: true
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateSaveAndUpdateFailed,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateSaveAndUpdateStateReset,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateDeleteRequest,
        (state) => ({
            ...state,
            isTemplateDeleting: true,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateDeleteSucceeded,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: true
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateDeleteFailed,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplateDeleteStateReset,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        actionTenantDialogEmailEditorActions.actionTenantDialogEmailTemplatesLoaded,
        (state, { templates }) => ({
            ...state,
            emailTemplates: templates,
            isTemplatesLoaded: true
        })
    ),
)

export const tenantDialogEmailEditorReducer = (state: TenantDialogEmailEditorState | undefined, action) => reducer(state, action)