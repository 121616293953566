import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'app/core/core.module';
import { CommunicationTemplate, CommunicationType } from 'app/features/unified-inbox/unified-inbox.model';
import { DeleteOrRemoveConfirmService } from 'app/shared/components/delete-remove-confirm/delete-or-remove-confirm.service';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { State } from '../../client-dialog.state';
import { actionClientDialogEmailTemplateDeleteRequest, actionClientDialogEmailTemplateDeleteStateReset, actionClientDialogEmailTemplateSaveAndUpdate, actionClientDialogEmailTemplateSaveAndUpdateStateReset } from '../client-dialog-email-editor/client-dialog-email-editor.action';
import { selectClientDialogEmailisTemplateDeletedSuccessfully, selectClientDialogEmailIsTemplateDeleting, selectClientDialogEmailisTemplateSaveOrUpdatedSuccessfully, selectClientDialogEmailIsTemplateSaveOrUpdating, selectClientDialogEmailTemplates } from '../client-dialog-email-editor/client-dialog-email-editor.selector';
import { actionClientDialogTextMessageTemplateDeleteRequest, actionClientDialogTextMessageTemplateDeleteStateReset, actionClientDialogTextMessageTemplateSaveAndUpdate, actionClientDialogTextMessageTemplateSaveAndUpdateStateReset } from '../client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message.action';
import { selectTextMessagesIsTemplateDeletedSuccessfully, selectTextMessagesIsTemplateDeleting, selectTextMessagesIsTemplateSaveOrUpdatedSuccessfully, selectTextMessagesIsTemplateSaveOrUpdating, selectTextMessagesTemplates } from '../client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message.selector';
import { Editor } from 'tinymce';
import { environment } from 'environments/environment';
import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { filePickerCallback, LEAD_EMAIL_MERGE_TAGS, LEAD_PHONE_MERGE_TAGS } from 'app/shared/models/tinymce.model';
import { MatProgressButtonOptions } from '../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-communication-template-editor',
  templateUrl: './client-dialog-communication-template-editor.component.html',
  styleUrls: ['./client-dialog-communication-template-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogCommunicationTemplateEditorComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private dialogRef: MatDialogRef<ClientDialogCommunicationTemplateEditorComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private deleteConfirmService: DeleteOrRemoveConfirmService
  ) {
    if (data && data.templateType) {
      this.templateType = data.templateType
    }
  }

  editorEmailToolbar = 'mergetags | image | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help';
  editorEmailPlugin = 'anchor autolink charmap codesample emoticons image link lists media table visualblocks wordcount checklist mediaembed casechange export formatpainter powerpaste editimage mergetags inlinecss help';
  editorEmailMenuBar = 'file edit insert view format table tools help';

  tinyMceApiKey = environment.tinyMce.tinyMceApiKey;
  editor: Editor;
  editorSettings = {
    branding: false,
    toolbar: '',
    plugins: '',
    menubar: '',
    file_picker_types: 'file image media',
    file_picker_callback: filePickerCallback,
    browser_spellcheck: true,
    external_plugins: {},
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: [],
    setup: (editor: Editor) => {
      this.editor = editor;
    }
  };

  unsubscribe: Subject<void> = new Subject();

  userRoles$: Observable<string[]>;
  userRoles: string[];

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  templates$: Observable<CommunicationTemplate[]>;
  templates: CommunicationTemplate[];
  templateType: CommunicationType;
  TEMPLATE_TYPE_EMAIL: CommunicationType = CommunicationType.EMAIL;
  TEMPLATE_TYPE_TEXT: CommunicationType = CommunicationType.TEXT;

  isEmailTemplateSaveUpdating$: Observable<boolean>;
  isEmailTemplateSaveUpdatedSuccessfully$: Observable<boolean>;
  isEmailTemplateDeleting$: Observable<boolean>;
  isEmailTemplateDeletedSuccessfully$: Observable<boolean>;

  isTextTemplateSaveUpdating$: Observable<boolean>;
  isTextTemplateSaveUpdatedSuccessfully$: Observable<boolean>;
  isTextTemplateDeleting$: Observable<boolean>;
  isTextTemplateDeletedSuccessfully$: Observable<boolean>;

  formTemplate = this.formBuilder.group({
    id: ['', []],
    friendlyName: ['', [Validators.required]],
    displayName: ['', [Validators.required]],
    content: ['', [Validators.required]],
    subject: ['', []],
    isAccountShared: ['', [Validators.required]]
  });

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: true,
    mode: 'indeterminate',
  };

  ngOnInit(): void {

    this.userRoles$ = this.store.pipe(select(selectUserRoles));
    this.userRoles$.pipe(takeUntil(this.unsubscribe)).subscribe(userRoles => {
      this.userRoles = userRoles;
    });

    this.formTemplate.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.spinnerSaveButtonOptions.disabled = !this.formTemplate.valid
      this.cdr.markForCheck()
    });

    if (this.templateType == CommunicationType.EMAIL) {
      this.editorSettings.mergetags_list = LEAD_EMAIL_MERGE_TAGS;
      this.editorSettings.toolbar = this.editorEmailToolbar;
      this.editorSettings.plugins = this.editorEmailPlugin;
      this.editorSettings.menubar = this.editorEmailMenuBar;
      this.templates$ = this.store.pipe(select(selectClientDialogEmailTemplates));
      this.isEmailTemplateSaveUpdating$ = this.store.pipe(select(selectClientDialogEmailIsTemplateSaveOrUpdating));
      this.isEmailTemplateSaveUpdatedSuccessfully$ = this.store.pipe(select(selectClientDialogEmailisTemplateSaveOrUpdatedSuccessfully));
      this.isEmailTemplateDeleting$ = this.store.pipe(select(selectClientDialogEmailIsTemplateDeleting));
      this.isEmailTemplateDeletedSuccessfully$ = this.store.pipe(select(selectClientDialogEmailisTemplateDeletedSuccessfully));
      this.templates$.pipe(takeUntil(this.unsubscribe)).subscribe(templates => {
        this.templates = templates;
        this.updateTemplateForm(templates[0]);
      });
      this.isEmailTemplateSaveUpdating$.pipe(takeUntil(this.unsubscribe)).subscribe(saving => {
        this.spinnerSaveButtonOptions.active = saving;
        this.cdr.markForCheck();
      });
      this.isEmailTemplateSaveUpdatedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(successfully => {
        if (successfully) {
          // this.dialogRef.close(true);
        }
      });
    }
    if (this.templateType == CommunicationType.TEXT) {
      this.editorSettings.mergetags_list = LEAD_PHONE_MERGE_TAGS;
      this.editorSettings.toolbar = 'mergetags';
      this.editorSettings.plugins = 'mergetags';
      this.editorSettings.menubar = '';
      this.templates$ = this.store.pipe(select(selectTextMessagesTemplates));
      this.isTextTemplateSaveUpdating$ = this.store.pipe(select(selectTextMessagesIsTemplateSaveOrUpdating));
      this.isTextTemplateSaveUpdatedSuccessfully$ = this.store.pipe(select(selectTextMessagesIsTemplateSaveOrUpdatedSuccessfully));
      this.isTextTemplateDeleting$ = this.store.pipe(select(selectTextMessagesIsTemplateDeleting));
      this.isTextTemplateDeletedSuccessfully$ = this.store.pipe(select(selectTextMessagesIsTemplateDeletedSuccessfully));
      this.isTextTemplateSaveUpdating$.pipe(takeUntil(this.unsubscribe)).subscribe(saving => {
        this.spinnerSaveButtonOptions.active = saving;
        this.cdr.markForCheck();
      });
      this.isTextTemplateSaveUpdatedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(successfully => {
        if (successfully) {
          // this.dialogRef.close(true);
        }
      });
      this.templates$.pipe(takeUntil(this.unsubscribe)).subscribe(templates => {
        this.templates = templates;
        this.updateTemplateForm(templates[0]);
      });
    }
  }

  updateTemplateForm(template?: CommunicationTemplate) {
    if (template) {
      this.formTemplate.get('id').setValue(template.id);
      this.formTemplate.get('friendlyName').setValue(template.friendlyName);
      this.formTemplate.get('displayName').setValue(template.displayName);
      this.formTemplate.get('content').setValue(template.content);
      this.formTemplate.get('subject').setValue(template.subject);
      this.formTemplate.get('isAccountShared').setValue(template.isAccountShared);
    } else {
      this.formTemplate.get('friendlyName').reset();
      this.formTemplate.get('displayName').reset();
      this.formTemplate.get('content').reset();
      this.formTemplate.get('subject').reset();
      this.formTemplate.get('id').reset();
      this.formTemplate.get('isAccountShared').setValue(false);
    }
  }

  get friendlyName(): string {
    return this.formTemplate.get('friendlyName').value;
  }

  get isAccountShared(): boolean {
    return this.formTemplate.get('isAccountShared').value;
  }

  hasRightToEdit() {
    return ((this.userRoles?.indexOf(SESSION_USER_ROLES.CommunicationTemplateManager) != -1) || !this.formTemplate.get('isAccountShared').value)
  }

  hasRightToShare() {
    return (this.userRoles?.indexOf(SESSION_USER_ROLES.CommunicationTemplateManager) != -1);
  }

  close() {
    this.dialogRef.close();
  }

  isEditTemplate() {
    return this.formTemplate.get('id').value > 0;
  }

  cancelCreateNew() {
    this.updateTemplateForm(this.templates[0])
  }

  save() {
    if (this.formTemplate.invalid) {
      return;
    }
    if (this.spinnerSaveButtonOptions.active) {
      return;
    }
    const id: number = this.formTemplate.get('id').value;
    const friendlyName: string = this.formTemplate.get('friendlyName').value;
    const displayName: string = this.formTemplate.get('displayName').value;
    const content: string = this.formTemplate.get('content').value;
    const subject: string = this.formTemplate.get('subject').value;
    const isAccountShared: boolean = this.formTemplate.get('isAccountShared').value;

    if (this.templateType == CommunicationType.EMAIL) {
      this.store.dispatch(actionClientDialogEmailTemplateSaveAndUpdate({ friendlyName, displayName, subject, content, isAccountShared, id }));
    }
    if (this.templateType == CommunicationType.TEXT) {
      this.store.dispatch(actionClientDialogTextMessageTemplateSaveAndUpdate({ friendlyName, displayName, content, isAccountShared, id }));
    }
  }

  deleteTemplate() {
    const id = this.formTemplate.get('id').value
    if (id > 0) {
      if (this.templateType == CommunicationType.EMAIL) {
        this.deleteConfirmService.openDeleteConfrimDialog(
          `Delete Template`,
          `${this.formTemplate.get('friendlyName').value}`,
          'Delete',
          'warn',
          this.isEmailTemplateDeleting$,
          this.isEmailTemplateDeletedSuccessfully$,
          actionClientDialogEmailTemplateDeleteRequest({ id }),
          [actionClientDialogEmailTemplateDeleteStateReset()]
        )
      }
      if (this.templateType == CommunicationType.TEXT) {
        {
          this.deleteConfirmService.openDeleteConfrimDialog(
            `Delete Template`,
            `${this.formTemplate.get('friendlyName').value}`,
            'Delete',
            'warn',
            this.isTextTemplateDeleting$,
            this.isTextTemplateDeletedSuccessfully$,
            actionClientDialogTextMessageTemplateDeleteRequest({ id }),
            [actionClientDialogTextMessageTemplateDeleteStateReset()]
          )
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionClientDialogEmailTemplateSaveAndUpdateStateReset());
    this.store.dispatch(actionClientDialogTextMessageTemplateSaveAndUpdateStateReset());
  }
}
