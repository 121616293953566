import { createAction, props } from "@ngrx/store";
import { Tag } from "../../../../shared/models/lead-info-models";

export const actionClientDialogLeadTagsRequested = createAction(
    '[Client Dialog Tags] Client Dialog Lead Tags Requested',
    props<{ leadId: number }>()
)

export const actionClientDialogLeadTagsLoaded = createAction(
    '[Client Dialog Tags] Client Dialog Lead Tags Loaded',
    props<{ tags: Tag[] }>()
)

export const actionClientDialogLeadTagsFailed = createAction(
    '[Client Dialog Tags] Client Dialog Lead Tags Failed'
)

export const actionClientDialogTagOptionsRequested = createAction(
    '[Client Dialog Tags] Client Dialog Tag Options Requested',
)

export const actionClientDialogTagOptionsLoaded = createAction(
    '[Client Dialog Tags] Client Dialog Tag Options Loaded',
    props<{ tags: Tag[] }>()
)

export const actionClientDialogTagsUpdate = createAction(
    '[Client Dialog Tags] Client Dialog Tags Update ',
    props<{ tags: Tag[] }>()
);

export const actionClientDialogTagsTagIsCheckedUpdate = createAction(
    '[Client Dialog Tags] Client Dialog Tags Tag Is Checked Update ',
    props<{ checked: boolean, tag: Tag }>()
);

export const actionClientDialogTagOptionsCheckStateInitialize = createAction(
    '[Client Dialog Tags] Client Dialog Tag Options Check State Initalize',
)

export const actionClientDialogTagOptionsCheckStateUnCheckAll = createAction(
    '[Client Dialog Tags] Client Dialog Tag Options Check State Un Check All',
)

export const actionClientDialogClientTagsFailed = createAction(
    '[Client Dialog Tags] Client Dialog Tags Failed',
)

export const actionClientDialogTagOptionsFailed = createAction(
    '[Client Dialog Tags] Client Dialog Tag Options Failed',
)

export const actionClientDialogTagsUpdateRequested = createAction(
    '[Client Dialog Tags] Client Dialog Tags Update Requested',
    props<{ id: number, tags: Tag[], preDefinedTags: Tag[] }>()
)

export const actionClientDialogTagsUpdateSuccessfully = createAction(
    '[Client Dialog Tags] Client Dialog Tags Update Successfully',
    props<{ tags: Tag[] }>()
)

export const actionClientDialogTagsUpdateFailed = createAction(
    '[Client Dialog Tags] Client Dialog Tags Update Failed',
)

export const actionClientDialogBatchTagsAddRequested = createAction(
    '[Client Dialog Tags] Client Dialog Batch Tags Add Requested',
    props<{ leadIds: number[], tagIds: number[], preDefinedTagIds: number[] }>()
)

export const actionClientDialogBatchTagsAddSuccessfully = createAction(
    '[Client Dialog Tags] Client Dialog Batch Tags Add Successfully',
)

export const actionClientDialogBatchTagsAddFailed = createAction(
    '[Client Dialog Tags] Client Dialog Batch Tags Add Failed',
)

export const actionClientDialogBatchTagsRemoveRequested = createAction(
    '[Client Dialog Tags] Client Dialog Batch Remove Add Requested',
    props<{ leadIds: number[], tagIds: number[], preDefinedTagIds: number[] }>()
)

export const actionClientDialogBatchTagsRemoveSuccessfully = createAction(
    '[Client Dialog Tags] Client Dialog Batch Tags Remove Successfully',
)

export const actionClientDialogBatchTagsRemoveFailed = createAction(
    '[Client Dialog Tags] Client Dialog Batch Tags Remove Failed',
)

export const actionClientDialogClientTagDeleteRequested = createAction(
    '[Client Dialog Tags] Client Dialog Cilent Tag Delete Requested',
    props<{ leadId: number, tagId: number }>()
)

export const actionClientDialogClientTagDeleteSuccessfully = createAction(
    '[Client Dialog Tags] Client Dialog Client Tag Delete Successfully',
    props<{ tags: Tag[] }>()
)

export const actionClientDialogClientTagDeleteFailed = createAction(
    '[Client Dialog Tags] Client Dialog Client Tag Delete Failed',
)

export const actionClientDialogClientLeadPreDefinedTagDeleteRequested = createAction(
    '[Client Dialog Tags] Client Dialog Cilent Lead Pre Defined Tag Delete Requested',
    props<{ leadId: number, tagId: number }>()
)

export const actionClientDialogClientLeadPreDefinedTagDeleteSuccessfully = createAction(
    '[Client Dialog Tags] Client Dialog Client Lead Pre Defined Tag Delete Successfully',
    props<{ tags: Tag[] }>()
)

export const actionClientDialogClientLeadPreDefinedTagDeleteFailed = createAction(
    '[Client Dialog Tags] Client Dialog Client Lead Pre Defined Tag Delete Failed',
)

export const actionClientDialogTagsCreateUpdateSuccessfulStateReset = createAction(
    '[Client Dialog Tags] Client Dialog Tags Create Update Successfully State Reset'
)

export const actionClientDialogTagsTagOptionsDialogStateReset = createAction(
    '[Client Dialog Tags] Client Dialog Tags Tag Options Dialog State Reset',
)


export const actionClientDialogTagsReset = createAction(
    '[Client Dialog Tags] Client Dialog Tags  Reset',
)


export const clientDialogTagActions = {
    actionClientDialogLeadTagsRequested,
    actionClientDialogLeadTagsLoaded,
    actionClientDialogLeadTagsFailed,
    actionClientDialogTagOptionsRequested,
    actionClientDialogTagOptionsLoaded,
    actionClientDialogTagsUpdate,
    actionClientDialogTagsTagIsCheckedUpdate,
    actionClientDialogTagsUpdateRequested,
    actionClientDialogTagsUpdateSuccessfully,
    actionClientDialogClientTagDeleteRequested,
    actionClientDialogClientTagDeleteSuccessfully,
    actionClientDialogClientLeadPreDefinedTagDeleteRequested,
    actionClientDialogClientLeadPreDefinedTagDeleteSuccessfully,
    actionClientDialogTagOptionsCheckStateInitialize,
    actionClientDialogTagOptionsCheckStateUnCheckAll,
    actionClientDialogTagOptionsFailed,
    actionClientDialogClientTagsFailed,
    actionClientDialogTagsUpdateFailed,
    actionClientDialogClientTagDeleteFailed,
    actionClientDialogClientLeadPreDefinedTagDeleteFailed,
    actionClientDialogBatchTagsAddRequested,
    actionClientDialogBatchTagsAddSuccessfully,
    actionClientDialogBatchTagsAddFailed,
    actionClientDialogBatchTagsRemoveRequested,
    actionClientDialogBatchTagsRemoveSuccessfully,
    actionClientDialogBatchTagsRemoveFailed,
    actionClientDialogTagsCreateUpdateSuccessfulStateReset,
    actionClientDialogTagsTagOptionsDialogStateReset,
    actionClientDialogTagsReset,
}