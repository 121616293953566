import { createSelector, createFeatureSelector } from '@ngrx/store';

import { AuthState } from './auth.models';

export const selectAuthState = createFeatureSelector< AuthState>(
  'auth'
);

export const selectAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => {
    return state.isAuthenticated === true && state.expires !== undefined && new Date(state?.expires) > new Date(new Date().toUTCString())
  }
);

export const selectIsNotExpired = createSelector(
  selectAuthState,
  authState =>
    authState.expires &&
    new Date(authState?.expires) > new Date(new Date().toUTCString())
);

export const selectTokenExpireDate = createSelector(
  selectAuthState,
  authState => authState?.expires
);

export const selectUserRoles = createSelector(
  selectAuthState,
  authState => authState?.roles
);

export const selectToken = createSelector(
  selectAuthState,
  authState => authState?.token
);

export const selectProfile = createSelector(
  selectAuth,
  authState => authState?.profile
);

export const selectUserName = createSelector(
  selectProfile,
  profile => profile?.userName
);

export const selectUserId = createSelector(
  selectProfile,
  profile => profile?.userId
);

export const selectUserTimeZone = createSelector(
  selectProfile,
  profile => profile?.userTimeZone
);

export const selectIsTwoFactorVerifying = createSelector(
  selectAuthState,
  authState => authState?.twoFactorAuthVetifying
);

export const selectIsRequireTwoFactorVerify = createSelector(
  selectAuthState,
  authState => authState?.isRequiredTwoFactor
);

export const selectIsResendingCode = createSelector(
  selectAuthState,
  authState => authState?.isResendingCode
);
