import { createAction, props } from "@ngrx/store";
import { ClientAppointment } from "../../../../shared/models/lead-info-models";
import { IUpdateLostStatusParams } from "app/shared/models/appointment.model";

export const actionClientDialogAppointmentAppointmentsRequested = createAction(
  '[Client Dialog Appointment] Client Appointments Requested',
  props<{ leadId: number }>()
);

export const actionClientDialogAppointmentAppointmentsLoaded = createAction(
  '[Client Dialog Appointment] Client Appointments Loaded',
  props<{ clientAppointments: ClientAppointment[] }>()
);

export const actionClientDialogAppointmentAppointmentsFailed = createAction(
  '[Client Dialog Appointment] Client Appointments Failed',
);

export const actionClientDialogAppointmentAppointmentInEdittingRequested = createAction(
  '[Client Dialog Appointment] Client Appointment In Editting Requested',
  props<{ id: number }>()
);

export const actionClientDialogAppointmentAppointmentInEdittingLoaded = createAction(
  '[Client Dialog Appointment] Client Appointment In Editting Loaded',
  props<{ clientAppointment: ClientAppointment }>()
);

export const actionClientDialogAppointmentAppointmentInEdittingFailed = createAction(
  '[Client Dialog Appointment] Client Appointment In Editting Failed',
);


export const actionClientDialogAppointmentAppointmentInEdittingReset = createAction(
  '[Client Dialog Appointment]  Client Appointment Appointment In Editting State Reset'
);

export const actionClientDialogAppointmentCancelRequseted = createAction(
  '[Client Dialog Appointment]  Appointment Cancel Requested',
  props<{ params: IUpdateLostStatusParams }>()
);

export const actionClientDialogAppointmentCancelSuccessfully = createAction(
  '[Client Dialog Appointment] Appointment Cancelled Successfully'
);

export const actionClientDialogAppointmentCancelFailed = createAction(
  '[Client Dialog Appointment] Appointment Cancelled Failed'
);

export const actionClientDialogAppointmentCancelStateReset = createAction(
  '[Client Dialog Appointment] Appointment Cancel State Reset'
);

export const actionClientDialogAppointmentStateReset = createAction(
  '[Client Dialog Appointment]  Client Appointment State Reset'
);

export const clientDialogAppointmentsActions = {
  actionClientDialogAppointmentAppointmentsRequested,
  actionClientDialogAppointmentAppointmentsLoaded,
  actionClientDialogAppointmentAppointmentInEdittingRequested,
  actionClientDialogAppointmentAppointmentInEdittingLoaded,
  actionClientDialogAppointmentAppointmentInEdittingReset,
  actionClientDialogAppointmentCancelRequseted,
  actionClientDialogAppointmentCancelSuccessfully,
  actionClientDialogAppointmentCancelStateReset,
  actionClientDialogAppointmentStateReset
}

export const clientDialogAppointmentsFailedActions = {
  actionClientDialogAppointmentAppointmentsFailed,
  actionClientDialogAppointmentAppointmentInEdittingFailed,
  actionClientDialogAppointmentCancelFailed
}

