import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';

@Component({
  selector: 'dq-timeline-note',
  templateUrl: './timeline-note.component.html',
  styleUrls: ['./timeline-note.component.scss', './timeline-note.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimelineNoteComponent implements OnInit {

  @Input() event: ITimelineElement

  isMobile$: Observable<boolean>;

  noteOwner: string

  constructor(public store: Store<State>) { }

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));
    this.noteOwner = this.event.timelineNote?.noteOwner;
  }

}
