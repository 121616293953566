

export interface TimeZone {
    id: string
    label: string
}
export const TIME_ZONES: TimeZone[] = [
    {
        id: 'ET',
        label: 'Eastern Time'
    },
    {
        id: 'PT',
        label: 'Pacific Time'
    },
    {
        id: 'MT',
        label: 'Mountain Time'
    },
    {
        id: 'CT',
        label: 'Central Time'
    },
    {
        id: 'AT',
        label: 'Atlantic Time'
    }
]