import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService, TitleService } from 'app/core/core.module';
import { FormService } from 'app/core/form/form.service';
import { IWebApiResponse } from 'app/core/response/response.model';

import { Subject, BehaviorSubject, of } from 'rxjs';
import { tap, map, catchError, takeUntil } from 'rxjs/operators';
import { SupportService } from './support.service';
import { MatProgressButtonOptions } from '../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportComponent implements OnInit, OnDestroy {

  constructor(
    private titleService: TitleService,
    private formBuilder: UntypedFormBuilder,
    private notification: NotificationService,
    private supportService: SupportService,
    private formService: FormService,
    private cdr: ChangeDetectorRef) { }

    supportForm: UntypedFormGroup;
    isLoading$: Subject<boolean> = new BehaviorSubject<boolean>(false);

    unsubscribe: Subject<void> = new Subject();

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Send',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  ngOnInit(): void {
    this.titleService.setTitleByText('Support | DashQ')
    this.supportForm = this.formBuilder.group(
      {
        firstName: new UntypedFormControl('',[Validators.required]),
        lastName: new UntypedFormControl('',[Validators.required]),
        email: new UntypedFormControl('',[Validators.required, Validators.email]),
        message: new UntypedFormControl('',[Validators.required]),
      }
    )

    this.isLoading$.pipe(takeUntil(this.unsubscribe)).subscribe(isActive => {
      this.spinnerButtonOptions.active = isActive;
      this.cdr.markForCheck();
    });
  }

  send(){

    if(this.spinnerButtonOptions.active){
      return
    }
    this.formService.markFormGroupTouched(this.supportForm);

    if(this.supportForm.valid){
      this.spinnerButtonOptions.active = true
      this.cdr.markForCheck()
      let request = {
        firstName: this.supportForm.get('firstName').value,
        lastName: this.supportForm.get('lastName').value,
        email: this.supportForm.get('email').value,
        message: this.supportForm.get('message').value,
      }

      this.getSendSupportResponse(request).pipe(takeUntil(this.unsubscribe)).subscribe((response: boolean) => {
        this.supportForm.reset()
        this.spinnerButtonOptions.active = false;
        this.cdr.markForCheck()
      });
    }
  }


  private getSendSupportResponse(form) {
    return this.supportService.sendSupportForm(form).pipe(
      tap(response => {
        if (!response) {
          this.isLoading$.next(false);
          throw new Error('Contact us reponse is incorrect');
        }
      }),
      map((response: IWebApiResponse) => {
        if (response.status == 1) {
          this.isLoading$.next(false);
          this.notification.success('Your message has been sent');
          return of(true);
        } else if (response.status == 0) {
          this.isLoading$.next(false);
          return of(false);
        } else {
          this.isLoading$.next(false);
          this.notification.error(
            "An error occured! Can't send your message."
          );
          return of(false);
        }
      }),
      catchError(responseError => {
        this.isLoading$.next(false);
        if (responseError instanceof HttpErrorResponse) {
          this.notification.error(
            "An error occured! Can't send your message."
          );
        }
        return of(false);
      })
    );
  }


  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
