import { Injectable, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DeleteRemoveConfirmDialogComponent } from './delete-remove-confirm-dialog/delete-remove-confirm-dialog.component';
import { DeleteConfrimParam } from './delete-remove-confirm.model';

@Injectable({
  providedIn: 'root'
})
export class DeleteOrRemoveConfirmService implements OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) { }

  confrimDeleteDialogRef: MatDialogRef<DeleteRemoveConfirmDialogComponent>;
  unsubscribe: Subject<void> = new Subject();

  openDeleteConfrimDialog(title: string, content: string, deleteButtonText: string, deleteButtonColor: ThemePalette, isDeleting$: Observable<boolean>, isDeleteSuccessfully$: Observable<boolean>, deleteAction: any, afterDeleteActions?: any[]) {
    this.store.pipe(select(selectScreenManagerIsMobileSize)).pipe(take(1)).subscribe(isMobile => {
      const param: DeleteConfrimParam = {
        title,
        content,
        deleteButtonText,
        deleteButtonColor,
        isDeleting$,
        isDeleteSuccessfully$,
        deleteAction
      }
      this.confrimDeleteDialogRef = this.dialog.open(
        DeleteRemoveConfirmDialogComponent,
        {
          width: isMobile ? '100vw' : '575px',
          height: isMobile ? '100dvh' : '',
          maxWidth: '100vw',
          autoFocus: true,
          panelClass: 'no-padding-dialog-container',
          disableClose: false,
          data: param
        }
      );

      this.confrimDeleteDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(confirm => {
        if (confirm && afterDeleteActions) {
          afterDeleteActions.forEach(action => this.store.dispatch(action))
        }
      });
    })

  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}
