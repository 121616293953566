import { createAction, props } from "@ngrx/store"
import { ITimelineElement } from "app/shared/components/timeline/timeline.model"

export const actionClientDialogAllActivitiesRequest = createAction(
    '[Client Dialog Activity] All Activities Request',
    props<{ leadId: number, pageNo: number, pageSize: number, itemCountPage: number, isLoadMore?: boolean }>()
)

export const actionClientDialogAllActivitiesSuccessed = createAction(
    '[Client Dialog Activity] All Activities Successed',
    props<{ activities: ITimelineElement[], pageNo: number, pageSize: number, totalRecords: number }>()
)

export const actionClientDialogAllActivitiesFailed = createAction(
    '[Client Dialog Activity] All Activities Failed',
)

export const actionClientDialogActivityStateReset = createAction(
    '[Client Dialog Activity] State Reset'
)

export const clientDialogActivityActions = {
    actionClientDialogAllActivitiesRequest,
    actionClientDialogAllActivitiesSuccessed,
    actionClientDialogAllActivitiesFailed,
    actionClientDialogActivityStateReset
}