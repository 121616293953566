import { createSelector } from "@ngrx/store";
import { ClientDialogEmailEditorState, ClientDialogState, selectClientDialog } from "../../client-dialog.state";

export const selectClientDialogEmailEditorState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogEmailEditor
);

export const selectLeadRepEmails = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.leadUserEmails
);

export const selectIsEmailSubmittedSuccessfully = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isEmailSubmittedSuccessfully
);

export const selectIsEmailSubmitting = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isEmailSubmitting
);

export const selectClientDialogEmailEditor = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.emailEditor
);

export const selectClientDialogEmailEditorIsCcAndBccActive = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isCcAndBccActive
);

export const selectClientDialogEmailReplyEditor = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.emailReplyEditor
);

export const selectClientDialogEmailReplyEditorIsForwarding = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isForwarding
);

export const selectClientDialogEmailReplyEditorReplyAllTo = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.replayAllTo
);

export const selectClientDialogEmailTemplates = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.emailTemplates
);

export const selectClientDialogEmailSendingType = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.emailSendType
);

export const selectClientDialogEmailSendingExternalIdentifier = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.emailSendingExternalIdentifier
);

export const selectClientDialogisEmailTemplatesLoaded = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isTemplatesLoaded
);

export const selectClientDialogEmailIsTemplateSaveOrUpdating = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isTemplateSaveOrUpdateing
);

export const selectClientDialogEmailisTemplateSaveOrUpdatedSuccessfully = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isTemplateSaveOrUpdateSuccessfully
);

export const selectClientDialogEmailIsTemplateDeleting = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isTemplateDeleting
);

export const selectClientDialogEmailisTemplateDeletedSuccessfully = createSelector(
    selectClientDialogEmailEditorState,
    (state: ClientDialogEmailEditorState) => state.isTemplateDeletedSuccessfully
);
