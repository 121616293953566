import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { phoneNumberValidator } from '../../directives/phone-number-validator.directive';

@Component({
  selector: 'dq-dialog-pad',
  templateUrl: './dialog-pad.component.html',
  styleUrls: ['./dialog-pad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DialogPadComponent{

  numberFormControl: UntypedFormControl = new UntypedFormControl('+1', [
    Validators.required,
    phoneNumberValidator()
  ]);

  @Output()
  callByNumber = new EventEmitter<string>();

  keypress(event: KeyboardEvent): boolean {
    return /[0-9#+*]/.test(event.key) || event.key.toLowerCase().includes('enter')
  }

  addNumberValue(key: string | number) {
    const currentValue: string = this.numberFormControl.value
    if (currentValue.length < 12) {
      this.numberFormControl.setValue(currentValue + key)
    }
  }

  backNumberValue() {
    const currentValue: string = this.numberFormControl.value
    if (currentValue.length > 1) {
      this.numberFormControl.setValue(currentValue.substring(0, currentValue.length - 1))
    }
  }

  call() {
    this.numberFormControl.markAsTouched()
    if (this.numberFormControl.valid) {
      this.callByNumber.emit(this.numberFormControl.value)
    }
  }
}
