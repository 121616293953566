<div class="timeline-text-component">
    <div class="desktop-container">
        <section>
            <article class="timeline-content">
                <header class="header">
                    <mat-icon class="timeline-icon sms">sms</mat-icon>
                    <div class="header-meta">
                        <span class="header-strong">Message</span> from <span class="header-strong">
                            {{msgFrom}}
                        </span> to 
                        <span class="header-strong">
                            {{msgTo}}
                        </span>
                        <div class="header-date">
                            <em>
                                {{event.date|date: 'medium'}}
                            </em>
                        </div>
                    </div>
                </header>
                <div [innerHtml]="msg | safeHtml">
                </div>
            </article>
        </section>
    </div>
</div>