import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Location} from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';

import {environment} from '../../../environments/environment';
import {AppState} from '../core.state';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {IWebApiResponse} from '../response/response.model';
import {IApplicationToken, SESSION_USER_ROLES} from './auth.models';
import {selectUserRoles} from './auth.selectors';

export const AUTH_KEY = 'AUTH';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    public location: Location,
  ) {
  }

  getToken(): string {
    const auth = this.localStorageService.getItem(AUTH_KEY);
    return auth && auth.token;
  }

  getTestToken(): string {
    const auth = this.localStorageService.getItem('test');
    return auth && auth.token;
  }

  getExpDate(): string {
    const auth = this.localStorageService.getItem(AUTH_KEY);
    return auth && auth.expires;
  }

  getRefreshToken(): string {
    const auth = this.localStorageService.getItem(AUTH_KEY);
    return auth && auth.refreshToken;
  }

  logIn(username: string, password: string, impersonateUsername: string): Observable<IApplicationToken> {

    const data = {
      username: username,
      password: password,
      impersonateUsername: impersonateUsername
    };
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http
      .post(environment.apiBaseUrl + 'auth/login', data, {headers})
      .pipe(
        map((response: Response) => {
          if (response)
            return <IApplicationToken>{
              token: response['token'],
              refreshToken: response['refreshToken'],
              expires: response['expiration'],
              roles: response['roles'],
              userName: response['userName'],
              userId: response['userId'],
              userTimeZone: response['userTimeZone'],
              firstName: response['firstName'],
              lastName: response['lastName'],
              frontVerificationHash: response['frontVerificationHash'],
              isRequiredTwoFactor: response['isRequiredTwoFactor'],
              intercomUser: response['intercomUser']
            };
          return <IApplicationToken>undefined;
        })
      );
  }

  forgotPassword(username: string): Observable<IWebApiResponse> {
    const data = {
      email: username
    };
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post(environment.apiBaseUrl + 'auth/forgotPassword', data, {
        headers: headers
      })
      .pipe(
        map((response: Response) => {
          return <IWebApiResponse>{
            status: response['status'],
            data: response['data'],
            message: response['message']
          };
        })
      );
  }

  resetPassword(
    username: string,
    password: string,
    confirmedPassword: string,
    code: string
  ): Observable<IWebApiResponse> {
    const data = {
      Email: username,
      Password: password,
      ConfirmPassword: confirmedPassword,
      Code: code
    };
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post(environment.apiBaseUrl + 'auth/resetPassword', data, {
        headers: headers
      })
      .pipe(
        map((response: Response) => {
          return <IWebApiResponse>{
            status: response['status'],
            data: response['data'],
            message: response['message']
          };
        })
      );
  }

  isUserInRole(expectedRoles: string[]): Observable<boolean> {
    return this.store.pipe(select(selectUserRoles),
      map((roles: string[]) => (roles || []).map(role => role.toLowerCase())),
      map((roles: string[]) => {

        if (!(expectedRoles?.length))
          return false;

        //If user is admin, return true
        if (roles.some(role => role === SESSION_USER_ROLES.Admin.toLowerCase())) {
          return true;
        }

        return expectedRoles.some(e => roles.includes(e.toLowerCase()));
      })
    );
  }

  //refresh example
  refreshToken(): Observable<IApplicationToken> {

    const data = {
      accessToken: this.getToken(),
      refreshToken: this.getRefreshToken(),
    };

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http
      .post(environment.apiBaseUrl + 'auth/refresh-token', data, {headers})
      .pipe(
        map((response: Response) => {
          if (response)
            return <IApplicationToken>{
              token: response['token'],
              refreshToken: response['refreshToken'],
              expires: response['expiration'],
              roles: response['roles'],
              userName: response['userName'],
              userId: response['userId'],
              userTimeZone: response['userTimeZone'],
              firstName: response['firstName'],
              lastName: response['lastName'],
              frontVerificationHash: response['frontVerificationHash'],
              intercomUser: response['intercomUser']
            };
          return <IApplicationToken>undefined;
        })
      );
  }

  twoFactorAuthVerify(code: string, userName: string) {
    const headers = new HttpHeaders();
    const data = {
      twoFactorCode: code,
      userEmail: userName
    };
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(environment.apiBaseUrl + 'auth/loginTwoStepAuth', data, {headers}).pipe(
      map((response: IWebApiResponse) => {
        if (response)
          return <IApplicationToken>{
            token: response['token'],
            refreshToken: response['refreshToken'],
            expires: response['expiration'],
            roles: response['roles'],
            userName: response['userName'],
            userId: response['userId'],
            userTimeZone: response['userTimeZone'],
            firstName: response['firstName'],
            lastName: response['lastName'],
            frontVerificationHash: response['frontVerificationHash'],
            intercomUser: response['intercomUser']
          };
        return <IApplicationToken>undefined;
      })
    );
  }

  reSendTwoFactorAuthVerificationCode() {
    return this.http.get(environment.apiBaseUrl + 'auth/reSendCodeVerification').pipe(
      map((response: IWebApiResponse) => {
        return response;
      })
    );
  }
}
