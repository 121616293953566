<div class="terms-of-use-component">
    <div class="terms-of-use-container">
        <h1>Terms of Use</h1>
        <div style="margin-bottom: 20px">Last updated July 22, 2021</div>
        <p>Please read these Terms of Use (the
            Agreement) carefully before using the Site. These Terms of Use also apply to data and services provided to
            you by email and via DashQ mobile applications. All use is for personal, non-commercial use only. By
            using the Site and its mobile versions, and by subscribing to email services you agree to comply with the
            Agreement and confirm that you have a bona fide interest in buying, selling or leasing a residential
            property. If you do not agree to comply with the Agreement, you may not use the Site or subscribe to email
            services from DashQ, and you should leave the site immediately.
            By using this web site, you are agreeing to comply and be bound by the following terms of service and use.
            Please review the following terms in their entirety and ensure their comprehension before using and viewing
            this web site.
            The Site is owned and operated by Digi Brokerage. The real estate representatives are a team under Digi
            Brokerage, and hereinafter referred to as, Digi Brokerage. Digi Brokerage is a member of the Canadian Real
            Estate Association (CREA) and is licensed to trade in real estate in the province of Ontario. Digi
            Brokerage, and its affiliates (collectively, we, us) reserve the right, at our sole discretion, to modify
            the Agreement at any time, and such modifications shall be effective immediately upon posting of the
            modified version on the Site. You agree to review the Agreement periodically to be aware of such
            modifications, and your continued use of the Site shall be deemed your conclusive acceptance of the modified
            Agreement.
            dashq.io is an Affiliated VOW Partner on behalf of Digi Brokerage in Ottawa, ON. All MLS® Listing data
            is intended only for your personal, non-commercial use. Users must have a bona fide interest in the
            purchase, sale, or lease of real estate of the type being offered through this VOW. All registrants agree
            that you will not copy, redistribute, retransmit, or otherwise use any of the data or Listing Information
            provided, except in connection with your consideration of the purchase, sale, or lease of an individual
            property.</p>
        <h3>Nature of Website</h3>
        <p>The Site provides real estate related information to consumers and promotes and delivers services offered by
            DashQ. The information provided on the Site is from sources we consider reliable, but because it is
            supplied by third parties, we cannot guarantee that it is accurate or complete and it should not be relied
            upon as such. All listing and other data is subject to changes without notices, and to errors and omissions.
            The data has not been, and cannot be verified by us.
            You should use your own judgment regarding the merits of any data, individual, or entity that you find on or
            through the Site. In spite of its good faith effort, DashQ cannot and does not represent or warrant that
            any individual or entity who’s services are promoted or offered on or through the Site is registered,
            licensed, qualified, or otherwise authorized or capable of performing any described services. DashQ, and
            its affiliates, do not make any representations, warranties or assurances as to the timeliness, completeness
            or accuracy of the information provided by any real estate professional, real estate agent, broker,
            brokerage, affiliate, appraiser or other real estate- or real-property-related service provider on or
            through the Site, including without limitation any real property evaluation or other related services.</p>
        <h3>Proprietary Nature of Site and Content</h3>
        <p>
            The Site, and all text, information, images, audio, video and other material (the Content) posted on or
            through the Site, are protected by copyright, trade-mark, and other intellectual property and proprietary
            rights laws. All Content posted on or through this Site, remains the valuable intellectual property of its
            owner(s), and is made available on or through the Site solely for your own personal, non-commercial, lawful
            use. You agree to abide by all copyright notices, information and restrictions contained in or displayed
            herein and with any Content.
        </p>
        <h3>Legal Capacity</h3>
        <p>
            You affirm that you have attained the age of majority in the jurisdiction in which you are a resident, or
            possess legal parental or guardian consent, and are fully able and competent to enter into the terms,
            conditions, obligations, affirmations, representations, and warranties set forth in this Agreement, and to
            abide by and comply with this Agreement.
        </p>

        You may not use the Site if you live in a jurisdiction where access to or use of the Site or any part of it
        may be illegal. It is solely your responsibility to determine whether your use of the Site is lawful, and
        you must comply with all applicable laws. In cases where you have authorized a minor to use the Site, you
        recognize that you are fully responsible for:
        <ul>
            <li>the online conduct of such minor;</li>
            <li>controlling the minor's access to and the use of the Site; and</li>
            <li>the consequences of any misuse by the minor. You acknowledge that some of the areas of the Site may
                contain material that is inappropriate for minors.</li>
        </ul>

        <h3>No Rights Granted in Intellectual Property or Content</h3>
        <p>Nothing contained on the Site should be considered as granting you, by implication, estoppel, or otherwise,
            any license or right to use any copyrighted material, trade-marks, logos, or other names, or other
            intellectual property used on or in connection with the Site, including, but not limited to, those
            identifying DashQ and/or its affiliates, licensors and related companies, or their respective products
            and services displayed on the Site, without the expressed written consent of the applicable rights holder.
            You do not acquire any ownership rights in any Content, nor are you granted any commercial, sale, resale,
            reproduction, licensing or sub-licensing, distribution, publishing, broadcast, transmission, performance or
            promotional use rights with respect to the Content.
            Your Limited Rights with Respect to Content
            You may only copy and store Content on your own computer for your personal, non-commercial home use, and you
            may not copy, share, modify, translate, license or sublicense, distribute, publish, broadcast, transmit,
            perform, display, sell or disseminate any Content to another recipient or otherwise use the Content for any
            other purpose. You are hereby advised that DashQ, its affiliates and licensors will aggressively enforce
            their intellectual property rights to the fullest extent permitted by law.</p>
        <h3>Prohibited Use of the Site and Content</h3>

        <p>
            You must be 16 years of age to use this website.
        </p>
        <h3>Uploaded Content</h3>
        Grant of Rights. With respect to Content that you upload, post, transmit, distribute or otherwise disseminate
        or
        make available on or through the Site (your Contributions), you irrevocably grant us the world-wide,
        perpetual,
        royalty-free, non-exclusive, sub-licensable and transferable right and license (without obligation) to use,
        distribute, reproduce, encode, modify, adapt, edit, display, exhibit, publish, translate, publicly perform,
        communicate, publicly display your Contributions, and to incorporate them into other works, in any format
        and
        through any media channel, whether now known or hereafter invented, including, without limitation, by way of
        the
        Site. Subject to the rights expressly granted to us, you retain ownership of the copyright in your
        Contributions. Moral Rights Waiver. You irrevocably waive your moral rights with respect to your
        Contributions,
        in connection with our exploitation of the rights granted to us in this Agreement. Prohibited Content. You
        will
        not upload, post, transmit, distribute, or otherwise disseminate or make available on or through the Site
        any
        Content that:
        <ul>
            <li>is copyrighted, protected by trade secret or otherwise subject to third party proprietary or
                contractual
                rights, including privacy and personality/publicity rights, unless you are the owner of such rights
                or
                have written permission from their rightful owner to post the Content and to grant us all of the
                rights
                granted herein;</li>
            <li>is obscene, profane, defamatory, libelous, slanderous, fraudulent, deceptive, threatening,
                intimidating,
                pornographic, harassing, abusive, hateful, racially or ethnically offensive, encourages or assists
                conduct that would constitute a criminal offence, could result in civil liability, or is otherwise
                objectionable, offensive, inappropriate or unlawful;</li>
            <li>implies or suggests an endorsement by us;</li>
            <li>in the case of audio or video Content, is greater than ten (10) minutes in duration; or</li>
            <li>contains advertising, a solicitation of funds, or a solicitation for goods or services unless
                written
                authorization is granted by us; or</li>
            <li>otherwise violates this Agreement or any applicable municipal, provincial, federal or international
                law,
                order or regulation.</li>
        </ul>
        <p>Third Party Clearances. You represent and warrant that you own or have secured all necessary licenses,
            rights,
            consents and permissions to use, and to authorize us to use, any trade-marks, copyrighted material or other
            intellectual property and/or proprietary material contained in your Contributions, and that you have the
            written
            consent, release and/or permission of every identifiable individual person appearing or referenced in your
            Contributions to use his/her name and/or likeness. Removal of Content. DashQ has no obligation to, and
            does
            not and cannot, monitor or review every item of Content that you and other users make available through the
            Site, and DashQ is not responsible for any of this Content. However, DashQ reserves the right to
            investigate, monitor, delete and/or refuse to transmit, move, remove, edit or refuse to post any material or
            information, in whole or in part, without notice to you, that we deem in our absolute discretion,
            unacceptable,
            undesirable or in violation of any law, regulation, contractual obligation or this Agreement. Responsibility
            for
            Uploaded Content. You will be solely responsible for any Content that you make available through the Site,
            and
            any liability resulting from its use as contemplated in this Agreement. You will also be liable for any
            costs,
            losses or damage resulting, directly or indirectly, from your making any Content available on or through the
            Site. Postings Non-Confidential. Any communications, comments, ideas or concepts that you post to the Site
            will
            be treated as non-confidential and non-proprietary and will not be protected under our privacy policy. You
            agree
            that DashQ and its affiliates are free to use any ideas, concepts, know-how or techniques that you send
            us
            for any purpose.</p>
        <h3>Third Party Content</h3>
        <p>
            Third Party Content. You acknowledge that, despite the prohibitions above, Content posted on the Site by
            other end users, may contain inaccurate, inappropriate, offensive or sexually explicit material, products or
            services and links to other web sites. We do not have control over Content provided by third parties and
            assume no responsibility or liability for it. Content you access or transmit using the Site is accessed and
            transmitted at your own risk. Third Party Web sites. Web sites accessed via links or other means from the
            Site have, unless otherwise indicated, been independently developed by third parties. The inclusion of any
            Content or links on the Site should not be construed as an express nor an implied endorsement of any
            third-party products or services by us. We are not responsible for the Content or opinions expressed on any
            linked web sites, and such web sites are in no way investigated, monitored or checked by us.
        </p>
        <h3>Linking and Framing</h3>
        <p>
            Unless otherwise agreed in writing by us, you may only provide a hypertext link to the Site on another web
            site, provided that (a) the appearance, position and other aspects of the link may not be such as to damage
            or dilute the goodwill associated with our names and trade-marks, as determined by us in our sole and
            absolute discretion; (b) the appearance, position and other aspects of the link may not create the false
            appearance that an entity is associated with or sponsored by us, as determined by us in our sole and
            absolute discretion; (c) the link, when activated by a user, must display the Site full-screen and not
            within a "frame" on the linked web site; and (d) we reserve the right to revoke our consent to the link at
            any time in our sole discretion.
        </p>
        <h3>
            Security
        </h3>
        <p>
            You may be issued a unique username and password to access portions of the Site. You are solely responsible
            for protecting your username and password. We are not liable for any claims, losses, actions, damages, suits
            or proceedings relating to the sharing of your username and password with others. You agree to notify
            DashQ immediately after you become aware of any unauthorized use of your user name and password, and to
            take such reasonable steps as are necessary to prevent any reoccurrence of such event.
        </p>
        <h3>
            Termination
        </h3>
        <p>
            This Agreement shall continue indefinitely until terminated by you or us pursuant to the provisions hereof
            or in accordance with applicable law. We may discontinue, or terminate your access to, the Site or any
            portion of the Site, at any time without notice to you. Our discontinuance of the Site or any portion of the
            Site may result in the removal or deletion of Content made available by you. You are responsible for
            maintaining any required back up copies of such Content. DashQ will not be liable to you with respect to
            its deletion of any Content.
        </p>
        <h3>
            Disclaimer of Warranties
        </h3>
        <p>
            THE SITE, AND ALL CONTENT, MATERIAL, INFORMATION OR POSTINGS FOUND ON OR ACCESSED THROUGH THE SITE, ARE
            PROVIDED ON AN "AS IS" BASIS. DashQ, ITS AFFILIATES, LICENSORS AND RELATED COMPANIES, AND EACH OF THEIR
            RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS AND AGENTS EXPRESSLY DISCLAIM ANY AND ALL
            REPRESENTATIONS, WARRANTIES AND CONDITIONS, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY AND ALL
            REPRESENTATIONS AND WARRANTIES OF TITLE AND NON-INFRINGEMENT, AND ALL IMPLIED WARRANTIES AND CONDITIONS OF
            MERCHANTABLE QUALITY, FITNESS FOR ANY PARTICULAR PURPOSE, SUITABILITY FOR ANY PARTICULAR PURPOSE, AND ANY
            REPRESENTATIONS, WARRANTIES OR CONDITIONS ARISING FROM ANY COURSE OF DEALING OR USAGE OF TRADE, WITH RESPECT
            TO THE SITE OR THE CONTENT CONTAINED IN OR ACCESSED THROUGH THE SITE. NEITHER DASHQ NOR ITS AFFILIATES,
            LICENSORS AND RELATED COMPANIES, NOR EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS
            AND AGENTS MAKES ANY REPRESENTATIONS OR WARRANTIES AS TO THE ACCURACY, TIMELINESS, RELIABILITY, TRUTHFULNESS
            OR COMPLETENESS OF THE SITE OR THE CONTENT CONTAINED IN OR ACCESSED THROUGH THE SITE, INCLUDING, BUT NOT
            LIMITED TO, THE CONTENTS OF ANY CONTENT, INFORMATION, MATERIAL, OR POSTING FOUND ON THE SITE (INCLUDING, BUT
            NOT LIMITED TO, ANY PRESS RELEASES OR FINANCIAL INFORMATION), ANY SERVICES PROVIDED THROUGH THE SITE, OR ANY
            LINKS TO OTHER SITES MADE AVAILABLE ON THE SITE OR THE CONTENT CONTAINED ON SUCH SITE(S), OR AS TO THE
            CONTINUOUS OR ERROR FREE USE AND OPERATION OF THE SITE. NEITHER DASHQ NOR ITS AFFILIATES, LICENSORS AND
            RELATED COMPANIES, NOR EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS OR AGENTS MAKES
            ANY REPRESENTATIONS OR WARRANTIES THAT THE SITE OR ACCESS TO AND USE OF THE SITE WILL BE UNINTERRUPTED,
            ERROR FREE, OR FREE FROM DEFECTS, VIRUSES OR OTHER HARMFUL COMPONENTS. YOU EXPRESSLY AGREE THAT USE OF THE
            SITE IS AT YOUR SOLE RISK.
        </p>
        <h3>
            Limitation of Liability
        </h3>
        <p>
            UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT
            MISREPRESENTATION AND FUNDAMENTAL BREACH SHALL DASHQ OR ITS AFFILIATES, LICENSORS AND RELATED COMPANIES,
            AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS AND AGENTS BE LIABLE FOR ANY
            DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR ANY LOSS THAT RESULT FROM YOUR USE OF, OR
            YOUR INABILITY TO USE, ANY CONTENT, INFORMATION, MATERIAL, OR POSTINGS ON THE SITE, DIRECTLY OR INDIRECTLY.
            THESE LIMITATIONS APPLY REGARDLESS OF WHETHER THE PARTY LIABLE OR ALLEGEDLY LIABLE WAS ADVISED, HAD OTHER
            REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY OF SUCH DAMAGES. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE
            THAT NEITHER DASHQ NOR ITS AFFILIATES, LICENSORS AND RELATED COMPANIES, NOR EACH OF THEIR RESPECTIVE
            DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS AND AGENTS SHALL BE LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR
            ILLEGAL CONDUCT OF ANY USER, INCLUDING YOU.
        </p>
        <h3>
            Indemnification
        </h3>
        <p>
            To the maximum extent permitted by applicable law, you will defend, indemnify and hold harmless DashQ,
            its affiliates, licensors and related companies, and each of their respective directors, officers,
            employees, consultants and agents from and against all claims, liability, and expenses, including all legal
            fees and costs, arising out of: (a) your breach of any provision of this Agreement; or (b) your use of the
            Site, the Content , your contributions and the placement or transmission of any Content or any of your
            contributions on the Site made by you or others using your user name and password. DashQ reserves the
            right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you and you shall not in any event settle any matter without
            the prior written consent of DashQ or its affiliates, licensors or related companies. You will
            co-operate as fully as reasonably required in the defense of any claim.
        </p>
        <h3>
            Privacy
        </h3>
        <p>
            Our collection, use and disclosure of personal information in connection with the Site are governed by our
            Privacy Policy, which is posted at http://www.dashq.io/en/privacy-policy.
        </p>
        <h3>
            Remedies & Recourse
        </h3>
        <p>
            If you are dissatisfied with the Site or with this Agreement, your sole and exclusive remedy is to
            discontinue using the Site.
        </p>
        <p>
            DashQ reserves the right to seek all remedies available at law and in equity for violations of this
            Agreement, including the right to block access from a particular IP address to the Site or any part thereof.
        </p>
        <h3>
            Internet Data Exchange and other Virtual Office Websites
        </h3>
        <p>
            These terms apply to the Internet Data Exchange and Virtual Office Website features of the Site, which
            provides listing data from various real estate boards/associations. You agree and acknowledge that:
        </p>
        <ul>
            <li>you have received, read and understood the agency disclosure document for your respective province as
                linked below, that you have had the opportunity to discuss such document with DashQ, and you accept
                declare that the only relationship sought with DashQ is as a customer/consumer, not a client.
        </ul>

        <h3>ON - Working with a REALTOR® by the Ontario Real Estate Association</h3>
        <img [src]="workingRealtor" alt="Working with a realtor!" title="Working with a realtor!">
        <ul>
            <li>these Terms of Use do not create an agency relationship or representation agreement between you and
                DashQ;
            <li>these Terms of Use do not impose any financial obligations on you for services;
            <li>all data obtained from dashq.io is intended only for your personal, non-commercial use;
            <li>you have a bona fide interest in the purchase, sale or lease of real estate of the type being offered
                through dashq.io;
            <li>you will not use, copy, print, or save any of the data on dashq.io other than for conducting your
                due diligence for the prospective sale, purchase or lease of a real estate property; this shall be your
                only permitted use of the data on DashQ .com;
            <li>you recognize the validity of, and will respect the ownership of, the proprietary rights and copyrights
                of the listing data provided by, and the MLS® System of, each respective real estate board/association;
            <li>you consent that some personal information (name, phone number, email address, and other) may be
                collected by this website prior to you being granted access to certain information on the Internet Data
                Exchange or Virtual Office Website features of dashq.io; this requirement is to satisfy DashQ’s
                compliance with the rules, regulations, policies, and by-laws of the various boards/associations
                providing the listing data;
            <li>your personal information may be shared with the real estate boards/associations, or their authorized
                representatives, providing data to dashq.io for the purpose of auditing and monitoring DashQ’s
                compliance with each respective board’s/association’s rules, regulations, policies and by-laws, the
                security of the data and other legal purposes, related to the use of the boards’/associations’ data;
            <li>by accepting these Terms of Use, you are NOT acquiring a right or licence to, and are prohibited from,
                either directly or indirectly, collecting, copying, displaying, posting, transferring, “scrapping”,
                “data mining”, saving, storing, selling, manipulating, disseminating, distributing/redistributing,
                retransmitting, reproducing, publishing, broadcasting, sharing, licensing or sublicensing any of the
                data on the Site or in emails from us to another individual, entity via any means or medium;
            <li>though all information presented on the Site is deemed to be from reliable sources, neither DashQ,
                nor the real estate boards and/ or associations providing listing data to DashQ, nor third party
                information providers, assume any responsibility for, and make no guarantee as to, the accuracy of the
                information provided on dashq.io. No information should be relied upon without being independently
                verified by you with the assistance of professionals as required;
            <li>some of the information contained on this site is based in whole or in part on information that is
                provided by members of The Canadian Real Estate Association (CREA), who are responsible for its
                accuracy. CREA reproduces and distributes this information as a service for its members, such as
                DashQ, and assumes no responsibility for its accuracy;
            <li>neither DashQ nor any board/associations shall be responsible or liable, directly or indirectly, in
                any way, for any loss or damage of any kind incurred as a result of, or in connection with, your use or
                reliance on the listing information/data, any related data, and/or posted or hyperlinked comments or
                reviews.
        </ul>
        <h3>
            Other Important Terms
        </h3>
        <p>Any disputes arising hereunder shall be governed exclusively by the laws of the province of Ontario and the
            laws
            of Canada applicable therein, without giving effect to their conflict of laws principles. You expressly
            consent
            to the exclusive forum, jurisdiction, and venue of the courts of Ontario and/or the Federal Court of Canada
            in
            Ontario, or any other judicial district or jurisdiction as DashQ may determine in any and all actions.
            This
            Agreement and any rules, policies, guidelines or other agreements posted on the Site by DashQ constitute
            the
            entire agreement between DashQ and you with respect to your use of the Site. No waiver by either
            DashQ
            or you of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent
            breach
            or default. If any provisions contained herein shall be determined to be void, invalid, or otherwise
            unenforceable by a court of competent jurisdiction, such termination shall not affect the remaining
            provisions
            contained herein. This Agreement has been drawn up in the English language at the express request of the
            parties. La présente convention a été rédigée en anglais à la demande expresse des parties.</p>
        <h3>
            Disclaimers
        </h3>
        <p>REALTOR®, REALTORS®, and the REALTOR® logo are certification marks that are owned by REALTOR® Canada Inc. and
            licensed exclusively to The Canadian Real Estate Association (CREA). These certification marks identify real
            estate professionals who are members of CREA and who must abide by CREA’s By‐Laws, Rules, and the REALTOR®
            Code.
            The MLS® trademark, Multiple Listing Service® and the MLS® logo are owned by CREA and identify the quality
            of
            services provided by real estate professionals who are members of CREA. Used under license. The Site is not
            intended to solicit consumers already under exclusive contact with other brokerages.</p>
        <h3>
            Contact
        </h3>
        To contact DashQ in connection with this Agreement or the Site, write to:<br />
        Compliance Officer<br />
        c/o DashQ<br />
        401-1306 Wellington St W<br />
        Ottawa, ON K1Y 3B2
    </div>
</div>