import { createAction, props } from '@ngrx/store';
import { UserEmail, UserPhoneNumber } from 'app/shared/models/user-communication.model';
import {
  IUserPhoneNumber
} from '../../shared/models/tenant-info-models';

export const actionTenantDialogLogInUserEmailsAndPhoneNumbersRequest = createAction(
  '[Tenant Dialog User Communication Settings] Request LogIn User Emails And Phone Numbers Request',
)

export const actionTenantDialogLogInUserEmailsAndPhoneNumbersSuccessed = createAction(
  '[Tenant Dialog User Communication Settings] Request LogIn User Emails And Phone Numbers Successed',
  props<{ emails: UserEmail[], phoneNumbers: IUserPhoneNumber[] }>()
)

export const actionTenantDialogLogInUserEmailsAndPhoneNumbersFailed = createAction(
  '[Tenant Dialog User Communication Settings] Request LogIn User Emails And Phone Numbers Failed',
)

export const actionTenantDialogCommunicationSettingsRequested = createAction(
  '[Tenant Dialog User Communication Settings] Communication Settings Requested',
  props<{ tenantId: number }>()
)

export const actionTenantDialogCommunicationSettingsSucceded = createAction(
  '[Tenant Dialog User Communication Settings] Communication Settings Succeded',
  props<{ emails: UserEmail[], phoneNumbers: UserPhoneNumber[], userId?: number }>()
)

export const actionTenantDialogCommunicationSettingsFailed = createAction(
  '[Tenant Dialog User Communication Settings] Communication Settings Failed',
)

export const actionTenantDialogTenantCallFromPhoneNumbersRequest = createAction(
  '[Tenant Dialog User Communication Settings] Tenant Call From Phone Numbers Request',
  props<{ tenantId: number }>()
)

export const actionTenantDialogTenantCallFromPhoneNumbersSuccessed = createAction(
  '[Tenant Dialog User Communication Settings] Tenant Call From Phone Numbers Successed',
  props<{ phoneNumbers: UserPhoneNumber[] }>()
)

export const actionTenantDialogTenantCallFromPhoneNumbersFailed = createAction(
  '[Tenant Dialog User Communication Settings] Tenant Call From Phone Numbers Failed',
)

export const actionTenantDialogTopNavigationActiveTabChange = createAction(
  '[Tenant Dialog Top Navigation] Tenant Dialog Top Navigation Active Tab Change',
  props<{ tab: number }>()
)

export const actionTenantDialogBaseStateReset = createAction(
  '[Tenant Dialog] Base State Reset'
);

export const actionTenantDialogResetState = createAction(
  '[Tenant Dialog] Reset State'
);

export const tenantDialogActions = {
  actionTenantDialogBaseStateReset,
  actionTenantDialogResetState,
  actionTenantDialogLogInUserEmailsAndPhoneNumbersRequest,
  actionTenantDialogLogInUserEmailsAndPhoneNumbersSuccessed,
  actionTenantDialogLogInUserEmailsAndPhoneNumbersFailed,
  actionTenantDialogCommunicationSettingsRequested,
  actionTenantDialogCommunicationSettingsSucceded,
  actionTenantDialogCommunicationSettingsFailed,
  actionTenantDialogTopNavigationActiveTabChange,
};
