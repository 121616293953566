import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { AssignedUser, Tag } from '../../shared/models/tenant-info-models';
import { ITimelineElement } from 'app/shared/components/timeline/timeline.model';
import { tenantDialogGeneralInfoReducer } from './components/tenant-dialog-user-general/tenant-dialog-general.reducer';
import { tenantDialogActivityReducer } from './components/tenant-dialog-activities/tenant-dialog-activities.reducer';
import { tenantDialogTenantNoteReducer } from './components/tenant-dialog-tenant-note/tenant-dialog-tenant-note.reducer';
import { tenantDialogBaseReducer } from './tenant-dialog.reducer';
import { tenantDialogTaskReducer } from './components/tenant-dialog-task/tenant-dialog-task.reducer';
import { Task, TaskAssignUser } from 'app/shared/models/task.model';
import { tenantDialogTagsReducer } from './components/tenant-dialog-tenant-tag/tenant-dialog-tenant-tag.reducer';
import { tenantDialogRepsReducer } from './components/tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.reducer';
import { tenantDialogBuildingReducer } from './components/tenant-dialog-tenant-building/tenant-dialog-tenant-building.reducer';

import { EmailSendType, UserEmail, UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { tenantDialogEmailEditorReducer } from './components/tenant-dialog-email-editor/tenant-dialog-email-editor.reducer';
import { tenantDialogTextMessageReducer } from './components/tenant-dialog-tenant-quick-text-message/tenant-dialog-tenant-quick-text-message.reducer';
import { TenantBuilding, TenantInfo } from 'app/shared/models/tenant-info-models';
import { BookingCalendar } from '../../shared/models/booking-calendar.model';


export const FEATURE_NAME = 'tenantDialog';


export const selectTenantDialog = createFeatureSelector<
  TenantDialogState
>(FEATURE_NAME);


export const tenantDialogReducers: ActionReducerMap<TenantDialogState> = {
  tenantDialogBase: tenantDialogBaseReducer,
  tenantDialogGeneralInfo: tenantDialogGeneralInfoReducer,
  tenantDialogTask: tenantDialogTaskReducer,
  tenantDialogTags: tenantDialogTagsReducer,
  tenantDialogReps: tenantDialogRepsReducer,
  tenantDialogBuilding: tenantDialogBuildingReducer,
  tenantDialogActivities: tenantDialogActivityReducer,
  tenantDialogTenantNote: tenantDialogTenantNoteReducer,
  tenantDialogEmailEditor: tenantDialogEmailEditorReducer,
  tenantDialogTextMessage: tenantDialogTextMessageReducer
};

export interface TenantDialogState {
  tenantDialogBase: TenantDialogBaseState
  tenantDialogGeneralInfo: TenantDialogGeneralInfoState
  tenantDialogTags: TenantDialogTagsState
  tenantDialogReps: TenantDialogRepsState
  tenantDialogBuilding: TenantDialogBuildingState
  tenantDialogTask: TenantDialogTaskState
  tenantDialogActivities: TenantDialogActivitiesState
  tenantDialogTenantNote: TenantDialogTenantNoteState
  tenantDialogEmailEditor: TenantDialogEmailEditorState,
  tenantDialogTextMessage: TenantDialogTextMessageState,
}

export interface TenantDialogBaseState {
  activeTab: number;
}

export interface TenantDialogGeneralInfoState {
  tenantId: number;
  tenantInfo: TenantInfo;
  isTenantInfoLoaded: boolean;
  isBaseInfoEditMode: boolean;
  isBaseInfoSubmitting: boolean;
  callFromPhoneNumbers: UserPhoneNumber[]
  tenantDialogPhoneNumberBlock: TenantDialogTenantPhoneNumberBlockState
}

export interface TenantDialogBookingState {
  isBookingFormSubmitting: boolean;
  isBookedSuccessfully: boolean;
  timeSlots: Date[],
  representativeCalendars: BookingCalendar[]
  buildingAddress: string;
}

export interface TenantDialogTagsState {
  isTagsLoaded: boolean
  isTagOptionsLoaded: boolean
  tenantTags: Tag[]
  tagOptions: Tag[]
  isTagSubmitting: boolean
  isUpdateSuccessfully: boolean
}


export interface TenantDialogRepsState {
  isRepsLoaded: boolean
  isRepOptionsLoaded: boolean
  tenantReps: AssignedUser[]
  repOptions: AssignedUser[]
  isRepSubmitting: boolean
  isUpdateSuccessfully: boolean
}

export interface TenantDialogBuildingState {
  isBuildingLoaded: boolean
  tenantBuilding: TenantBuilding
}


export interface TenantDialogTenantPhoneNumberBlockState {
  isUpdating: boolean
  isUpdatedSuccessfully: boolean
}

export interface TenantDialogActivitiesState {
  activities: ITimelineElement[]
  isActivitiesLoaded: boolean
  communicationTotalecords: number
  communicationPageNo: number
  communicationPageSize: number
  isLoadingMoreCommunications: boolean
  lastCommunicatePhoneNumber: string
}

export interface TenantDialogTenantNoteState {
  isNoteSubmitting: boolean;
  isNoteSubmittedSuccessfully: boolean;
}

export interface TenantDialogRentalApplicationState {
  isRentalApplicationSubmitting: boolean;
  isentalApplicationSuccessfullySubmitting: boolean;
}

export interface TenantDialogTaskState {
  isTasksLoaded: boolean
  tasks: Task[]
  taskCreateState: TaskCreateState
  taskEditState: TaskEditState
  taskDeleteState: TaskDeleteState
}

export interface TaskCreateState {
  taskAssignUsers: TaskAssignUser[]
  isCreating: boolean
  isCreatedSuccessfully: boolean
}

export interface TaskEditState {
  isEditing: boolean
  isEditSuccessfully: boolean
}

export interface TaskDeleteState {
  isDeleting: boolean
  isDeletSuccessfully: boolean
}

export interface TenantDialogEmailEditorState {
  isEmailSubmitting: boolean;
  isEmailSubmittedSuccessfully: boolean;
  emailSendType: EmailSendType;
  emailSendingExternalIdentifier: string;
  tenantUserEmails: UserEmail[];
  emailEditor: EmailEditor;
  emailReplyEditor: EmailReplyEditor;
  isCcAndBccActive: boolean;
  replayAllTo: string[];
  isForwarding: boolean;
  emailTemplates: CommunicationTemplate[];
  isTemplatesLoaded: boolean;
  isTemplateSaveOrUpdateing: boolean;
  isTemplateSaveOrUpdateSuccessfully: boolean;
  isTemplateDeleting: boolean;
  isTemplateDeletedSuccessfully: boolean;
}


export interface TenantDialogTextMessageState {
  isTextMessageSubmitting: boolean;
  isTextMessageSubmittedSuccessfully: boolean;
  sendFromPhoneNumbers: UserPhoneNumber[];
  textMessageEditor: TextMessageEditor;
  textMessageTemplates: CommunicationTemplate[];
  isTemplatesLoaded: boolean;
  isTemplateSaveOrUpdateing: boolean;
  isTemplateSaveOrUpdateSuccessfully: boolean;
  isTemplateDeleting: boolean;
  isTemplateDeletedSuccessfully: boolean;
}


export interface EmailEditor {
  email: string;
  sendFromUserId: number;
  sendToEmails: string[];
  sendToCcs: string[];
  sendToBccs: string[];
  subject: string;
  attachements: string[];
}

export interface EmailReplyEditor {
  email: string;
  sendToCcs: string[];
  sendToBccs: string[];
  subject: string;
}

export interface CommunicationTemplate {
  id: number;
  type: string;
  friendlyName: string;
  displayName: string;
  content: string;
  isAccountShared: boolean;
}

export interface TextMessageEditor {
  message: string;
  sendFrom: string;
  sendTo: string;
}


export interface State extends AppState {
  [FEATURE_NAME]: TenantDialogState;
}
