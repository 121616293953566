import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { selectUserId, selectUserName } from 'app/core/auth/auth.selectors';
import { NotificationService, ROUTE_ANIMATIONS_ELEMENTS } from 'app/core/core.module';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { AccountIntegrationService } from 'app/core/account-integration/account-integration.service';
import { environment } from 'environments/environment';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { AssignedUser } from 'app/shared/models/lead-info-models';
import { actionTenantDialogSendEmailRequest, actionTenantDialogSendEmailAndCollaborateRequest, actionTenantDialogSendEmailEditorUpdate, actionTenantDialogEmailTemplatesRequest, actionTenantDialogSendEmailEditorReset, actionTenantDialogEmailEditorIsCcAndBccActiveUpdate, actionTenantDialogSendEmailReplyEditorReset, actionTenantDialogSendEmailReplyEditorIsForwardingUpdate, actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate } from '../tenant-dialog-email-editor.action';
import { selectTenantDialogIsEmailSubmitting, selectTenantDialogIsEmailSubmittedSuccessfully, selectTenantDialogTenantRepEmails, selectTenantDialogEmailEditorIsCcAndBccActive, selectTenantDialogEmailEditor, selectTenantDialogEmailReplyEditor, selectTenantDialogEmailTemplates, selectTenantDialogEmailReplyEditorIsForwarding, selectTenantDialogisEmailTemplatesLoaded, selectTenantDialogEmailSendingExternalIdentifier, selectTenantDialogEmailSendingType, selectTenantDialogEmailReplyEditorReplyAllTo } from '../tenant-dialog-email-editor.selector';


import 'quill-emoji/dist/quill-emoji.js';
import {  MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { emailValidatorRegexp } from 'app/shared/components/helpers/email.validator';
import { EmailSendType, UserEmail } from 'app/shared/models/user-communication.model';
import { CommunicationTemplate, CommunicationType, EmailEditor, EmailReplyEditor } from 'app/features/tenant-unified-inbox/tenant-unified-inbox.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TenantDialogCommunicationTemplateEditorComponent } from '../../tenant-dialog-communication-template-editor/tenant-dialog-communication-template-editor.component';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { selectTenantDialogTenantId, selectTenantDialogTenantInfo, selectTenantDialogTenantInfoEmails } from '../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { selectTenantDialogRepsStateTenantReps } from '../../tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.selector';
import { Editor } from 'tinymce';
import { filePickerCallback, TENANT_EMAIL_MERGE_TAGS } from 'app/shared/models/tinymce.model';
import { TenantInfo } from 'app/shared/models/tenant-info-models';
import { TenantEmailMessage } from 'app/shared/models/tenant-communication.model';
import { MatProgressButtonOptions } from '../../../../../shared/components/spinner-button/spinner-button.interface';


@Component({
  selector: 'dq-tenant-dialog-email-editor',
  templateUrl: './tenant-dialog-email-editor.component.html',
  styleUrls: ['./tenant-dialog-email-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogEmailEditorComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private accountIntegrationService: AccountIntegrationService
  ) {

  }

  //Quills configuration

  tinyMceApiKey = environment.tinyMce.tinyMceApiKey;
  editor: Editor;
  editorSettings = {
    branding: false,
    toolbar: 'mergetags | image | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
    plugins: 'anchor autolink charmap codesample emoticons image link lists media table visualblocks wordcount checklist mediaembed casechange export formatpainter powerpaste editimage mergetags inlinecss help',
    file_picker_types: 'file image media',
    file_picker_callback: filePickerCallback,
    browser_spellcheck: true,
    external_plugins: {},
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: TENANT_EMAIL_MERGE_TAGS,
    setup: (editor: Editor) => {
      this.editor = editor;
    }
  };

  @Input() emailMessage: TenantEmailMessage;

  //Mat Chips configuration
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  microsoftSignImage: string = '../../../../../assets/static/logos/microsoft_signin_dark.png'

  microsoftResponseType: string = 'code';
  microsoftRedirectUri: string = encodeURIComponent(`${environment.baseUrl.webapp}account-integration/microsoft`);
  microsoftResponseMode: string = 'query';
  microsoftScope: string = encodeURIComponent('offline_access user.read mail.send mail.readwrite calendars.readwrite');
  microsoftSignInUrl: string = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${environment.microsoftGraph.microsoftClientId}&response_type=${this.microsoftResponseType}&redirect_uri=${this.microsoftRedirectUri}&response_mode=${this.microsoftResponseMode}&scope=${this.microsoftScope}&state=`

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  tenantId$: Observable<number>;
  tenantId: number;

  tenantInfo$: Observable<TenantInfo>;

  isMobile$: Observable<boolean>;
  isMobile: boolean;

  unsubscribe: Subject<void> = new Subject();

  collaborated: boolean = false;

  isCcBccVisible$: Observable<boolean>;
  isCcBccVisible: boolean;
  isEmailEditorExpended: boolean = false;

  emialEditorContentIndex: number = 0;

  isSubmitting$: Observable<boolean>;
  isSubmittedSuccessfully$: Observable<boolean>;
  isMicrosoftIntegrated: boolean = true;
  selectedFile: any = null;
  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Send',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: true,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: "send"
    }
  };

  spinnerScheduleButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Schedule',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: true,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: "access_time"
    }
  };

  emailEdiror$: Observable<EmailEditor>;
  emailReplyEdiror$: Observable<EmailReplyEditor>;
  emailTemplates$: Observable<CommunicationTemplate[]>;
  emailTemplates: CommunicationTemplate[];
  emailTemplateSearchOptions$: Observable<CommunicationTemplate[]>;
  templateSerachControl = new FormControl();
  isTemplatesLoaded$: Observable<boolean>;
  isForwarding$: Observable<boolean>;
  emailSendingType$: Observable<EmailSendType>;
  emailSendingType: EmailSendType;
  emailSendingExternalIdentifier$: Observable<string>;
  emailSendingExternalIdentifier: string;

  emailTemplateEditorDialogRef: MatDialogRef<TenantDialogCommunicationTemplateEditorComponent>;

  formEmail = this.formBuilder.group({
    email: ['', [Validators.required]],
    sendFromUserId: ['', [Validators.required]],
    sendToEmails: ['', [Validators.required]],
    sendToCcs: [[], []],
    sendToBccs: [[], []],
    attachements: [[], []],
    subject: ['', [Validators.required]]
  });

  tenantRepEmails$: Observable<UserEmail[]>;
  tenantEmails$: Observable<string[]>;

  tenantReps$: Observable<AssignedUser[]>;
  loginUserId$: Observable<number>;
  loginUserId: number;

  replyAllTo$: Observable<string[]>

  ngOnInit(): void {
    this.checkMicrosoftConnection()
    this.store.pipe(takeUntil(this.unsubscribe), select(selectUserName)).pipe(takeUntil(this.unsubscribe)).subscribe(userName => {
      if (userName) {
        var hash = Md5.hashStr(userName)
      }
      this.microsoftSignInUrl = this.microsoftSignInUrl + hash;
    });
    this.loginUserId$ = this.store.pipe(select(selectUserId))
    this.loginUserId$.pipe(takeUntil(this.unsubscribe)).subscribe(loginUserId => {
      this.loginUserId = loginUserId;
    });
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });

    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId));

    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId;
    });

    this.isTemplatesLoaded$ = this.store.pipe(select(selectTenantDialogisEmailTemplatesLoaded));
    this.isCcBccVisible$ = this.store.pipe(select(selectTenantDialogEmailEditorIsCcAndBccActive));
    this.isCcBccVisible$.pipe(takeUntil(this.unsubscribe)).subscribe(isCcBccVisible => {
      this.isCcBccVisible = isCcBccVisible;
    });
    this.isSubmitting$ = this.store.pipe(select(selectTenantDialogIsEmailSubmitting));
    this.isSubmittedSuccessfully$ = this.store.pipe(select(selectTenantDialogIsEmailSubmittedSuccessfully));
    this.isSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmitting => {
      this.spinnerSaveButtonOptions.active = isSubmitting
      this.cdr.markForCheck()
    });

    this.isSubmittedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmittedSuccessfully => {
      if (isSubmittedSuccessfully) {
        this.emailEditorReset();
      }
    });


    this.emailSendingExternalIdentifier$ = this.store.pipe(select(selectTenantDialogEmailSendingExternalIdentifier));
    this.emailSendingExternalIdentifier$.pipe(takeUntil(this.unsubscribe)).subscribe(emailSendingExternalIdentifier => {
      this.emailSendingExternalIdentifier = emailSendingExternalIdentifier;
    });
    this.emailSendingType$ = this.store.pipe(select(selectTenantDialogEmailSendingType));
    this.emailSendingType$.pipe(takeUntil(this.unsubscribe)).subscribe(emailSendingType => {
      this.emailSendingType = emailSendingType;
      //when forwarding, sent to filed need to be input manually
      if (emailSendingType == EmailSendType.FORWARD) {
        this.formEmail.get('sendToEmails').addValidators([Validators.email]);
      } else {
        this.formEmail.get('sendToEmails').removeValidators([Validators.email])
      }
      this.formEmail.get('sendToEmails').setValue('');
    });

    this.tenantRepEmails$ = this.store.pipe(select(selectTenantDialogTenantRepEmails))
    this.tenantRepEmails$.pipe(takeUntil(this.unsubscribe)).subscribe(emails => {
      let loginUserEmail = this.getLoginUserEmail(emails);
      this.formEmail.get('sendFromUserId').setValue(loginUserEmail ? loginUserEmail.userId : emails[0]?.userId)
    })

    this.tenantEmails$ = this.store.pipe(select(selectTenantDialogTenantInfoEmails));

    combineLatest([this.emailSendingType$, this.tenantEmails$]).pipe(takeUntil(this.unsubscribe)).subscribe(([emailSendingType, tenantEmails]) => {
      if (emailSendingType == EmailSendType.SEND || emailSendingType == EmailSendType.REPLY) {
        if (tenantEmails.length > 0) {
          this.formEmail.get('sendToEmails').patchValue([tenantEmails[0]]);
        }
      }
    });

    this.emailTemplates$ = this.store.pipe(select(selectTenantDialogEmailTemplates));

    this.emailTemplates$.pipe(takeUntil(this.unsubscribe)).subscribe(emailTemplates => {
      this.emailTemplates = emailTemplates
      this.emailTemplateSearchOptions$ = this.templateSerachControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTemplateSearchOption(value))
      );
    })

    this.replyAllTo$ = this.store.pipe(select(selectTenantDialogEmailReplyEditorReplyAllTo))
    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo))

    this.formEmail.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.spinnerSaveButtonOptions.disabled = !this.formEmail.valid
      this.cdr.markForCheck()
    });

    this.tenantReps$ = this.store.pipe(select(selectTenantDialogRepsStateTenantReps));

    combineLatest([this.tenantReps$, this.loginUserId$]).pipe(takeUntil(this.unsubscribe)).subscribe(([tenantReps, loginUserId]) => {
      this.collaborated = tenantReps.findIndex(x => x.id == loginUserId) >= 0;
      this.cdr.markForCheck();
    });

    this.emailEdiror$ = this.store.pipe(select(selectTenantDialogEmailEditor));

    this.emailEdiror$.pipe(take(1)).subscribe(emailEditor => {
      this.formEmail.get('subject').setValue(emailEditor.subject);
      this.formEmail.get('email').setValue(emailEditor.email);
      this.formEmail.get('sendFromUserId').setValue(emailEditor.sendFromUserId);
      this.formEmail.get('sendFromUserId').setValue(emailEditor.sendFromUserId);
      this.formEmail.get('sendToCcs').setValue(emailEditor.sendToCcs);
      this.formEmail.get('sendToBccs').setValue(emailEditor.sendToBccs);
      this.formEmail.get('attachements').setValue(emailEditor.attachements);
    });

    this.emailReplyEdiror$ = this.store.pipe(select(selectTenantDialogEmailReplyEditor));
    this.emailReplyEdiror$.pipe(takeUntil(this.unsubscribe)).subscribe(emailReplyEdiror => {
      if (emailReplyEdiror.subject || emailReplyEdiror.email || emailReplyEdiror.sendToCcs?.length > 0 || emailReplyEdiror.sendToBccs?.length > 0) {
        this.formEmail.get('subject').setValue(emailReplyEdiror.subject);
        this.formEmail.get('email').setValue(`<br/><br/><blockquote style="margin: 0px 0px 0px 0.8ex; border-left-width: 1px; border-left-style: solid; padding-left: 1ex; border-left-color: rgb(204,204,204);">${emailReplyEdiror.email}</blockquote>`);
        if (emailReplyEdiror.sendToCcs?.length > 0) {
          this.formEmail.get('sendToCcs').setValue(emailReplyEdiror.sendToCcs);
        }
        if (emailReplyEdiror.sendToBccs?.length > 0) {
          this.formEmail.get('sendToBccs').setValue(emailReplyEdiror.sendToBccs);
        }
        setTimeout(() => { this.editor?.focus(); }, 2000);
      }
    });

    this.formEmail.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(values => {
      this.store.dispatch(actionTenantDialogSendEmailEditorUpdate({
        email: this.formEmail.get('email').value,
        sendFromUserId: this.formEmail.get('sendFromUserId').value,
        subject: this.formEmail.get('subject').value,
        sendToBccs: this.formEmail.get('sendToBccs').value,
        sendToCcs: this.formEmail.get('sendToCcs').value,
        sendToEmails: this.formEmail.get('sendToEmails').value,
        attachements: this.formEmail.get('attachements').value,
      }
      ));
    });

    this.isForwarding$ = this.store.pipe(select(selectTenantDialogEmailReplyEditorIsForwarding));
    this.isForwarding$.pipe(takeUntil(this.unsubscribe)).subscribe(isForwarding => {
      if (isForwarding) {
        this.formEmail.get('sendToEmails').setValue('');
      }
    });

    this.replyAllTo$.pipe(takeUntil(this.unsubscribe)).subscribe(replyAllTo => {
      if (replyAllTo.length > 0) {
        this.formEmail.get('sendToEmails').setValue(replyAllTo);
      }
    })
  }

  private _filterTemplateSearchOption(value: string): CommunicationTemplate[] {
    const filterValue = value.toLowerCase();
    return this.emailTemplates.filter(x => x.displayName.toLowerCase().includes(filterValue));
  }


  updateIsCcAndBccActive(isActive: boolean) {
    this.store.dispatch(actionTenantDialogEmailEditorIsCcAndBccActiveUpdate({ isActive }))
  }

  get attachements(): FormArray {
    return this.formEmail.controls.attachements as FormArray;
  }

  get sendToEmails(): FormArray {
    return this.formEmail.controls.sendToEmails as FormArray;
  }

  get sendToCcs(): FormArray {
    return this.formEmail.controls.sendToCcs as FormArray;
  }

  get sendToBccs(): FormArray {
    return this.formEmail.controls.sendToBccs as FormArray;
  }

  addEmail(event: MatChipInputEvent, formControlName: string, list: string[]): void {
    const value = (event.value || '').trim();
    if (value) {
      var isValidEmail = emailValidatorRegexp.test(value);
      if (isValidEmail) {
        this.formEmail.get(formControlName).setValue([...list, value]);
      }
      else {
        this.notificationService.warn('Invalid email entry!')
      }
    }
    event.chipInput!.clear();
  }

  removeEmail(value: string, formControlName: string, list: string[]): void {
    this.formEmail.get(formControlName).setValue(list.filter(x => x != value));
  }

  addAttachement(files: any[], list: string[]) {
    this.selectedFile = files[0] ?? null;
    if (files.length > 0) {
      const file = files[0];
      this.formEmail.get('attachements').setValue([...list, this.selectedFile]);
    }

  }

  removeAttachement(value: string, formControlName: string, list: string[]): void {
    this.formEmail.get(formControlName).setValue(list.filter(x => x != value));
  }

  sendEmail() {
    if (this.spinnerSaveButtonOptions.active || this.spinnerSaveButtonOptions.disabled) {
      return;
    }

    if (this.formEmail.valid) {
      this.store.dispatch(actionTenantDialogSendEmailRequest(
        {
          tenantId: this.tenantId,
          emailType: this.emailSendingType,
          externalIdentifier: this.emailSendingExternalIdentifier,
          text: this.formEmail.get('email').value,
          sendFromUserId: this.formEmail.get('sendFromUserId').value,
          sendTo: this.emailSendingType == EmailSendType.FORWARD ? [this.formEmail.get('sendToEmails').value] : this.formEmail.get('sendToEmails').value,
          sendToCc: this.formEmail.get('sendToCcs').value,
          sendToBcc: this.formEmail.get('sendToBccs').value,
          subject: this.formEmail.get('subject').value,
          files: this.formEmail.get('attachements').value
        }));
    }
  }

  emailEditorReset() {
    this.formEmail.get('subject').reset();
    this.formEmail.get('email').reset();
    this.formEmail.get('sendFromUserId').reset();
    this.formEmail.get('sendToCcs').setValue([]);
    this.formEmail.get('sendToBccs').setValue([]);
    this.formEmail.get('attachements').setValue([]);
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false }));
    this.store.dispatch(actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate({ sendType: EmailSendType.SEND, externalIdentifier: '' }));
    this.store.dispatch(actionTenantDialogSendEmailEditorReset());
    this.cdr.markForCheck();
  }


  sendEmailAndCollaborate() {
    if (this.spinnerSaveButtonOptions.active || this.spinnerSaveButtonOptions.disabled) {
      return;
    }
    if (this.formEmail.valid) {
      this.store.dispatch(actionTenantDialogSendEmailAndCollaborateRequest(
        {
          tenantId: this.tenantId,
          emailType: this.emailSendingType,
          externalIdentifier: this.emailSendingExternalIdentifier,
          text: this.formEmail.get('email').value,
          sendFromUserId: this.formEmail.get('sendFromUserId').value,
          sendTo: this.emailSendingType == EmailSendType.FORWARD ? [this.formEmail.get('sendToEmails').value] : this.formEmail.get('sendToEmails').value,
          sendToCc: this.formEmail.get('sendToCcs').value,
          sendToBcc: this.formEmail.get('sendToBccs').value,
          subject: this.formEmail.get('subject').value,
          files: this.formEmail.get('attachements').value
        }));
    }
  }

  checkMicrosoftConnection() {
    this.accountIntegrationService.microsoftCheckAccount().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      if (res.status == 1) {
        this.isMicrosoftIntegrated = res.data
        this.cdr.markForCheck();
      }
    });
  }

  getLoginUserEmail(emails: UserEmail[]) {
    return emails.find(x => x.userId == this.loginUserId)
  }

  openMicrosoftIntegration() {
    this.popupwindow(this.microsoftSignInUrl, 'microsoft connection', 600, 600);
  }

  popupwindow(url, title, w, h) {
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  ifFormEmailValid() {
    return this.formEmail.valid
  }

  openEmailTemplateEditDialogEdit(): void {
    this.emailTemplateEditorDialogRef = this.dialog.open(TenantDialogCommunicationTemplateEditorComponent, {
      width: this.isMobile ? '100vw' : '675px',
      height: this.isMobile ? '100dvh' : '',
      maxWidth: '100vw',
      autoFocus: true,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: {
        templateType: CommunicationType.EMAIL
      }
    });

    this.emailTemplateEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        this.store.dispatch(actionTenantDialogEmailTemplatesRequest());
      }
    });
  }

  applyTemplate(template: CommunicationTemplate) {
    this.editor.execCommand('mceInsertContent', true, template);
    this.formEmail.get('email').setValue(this.editor.getContent());
    this.templateSerachControl.setValue('');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionTenantDialogSendEmailReplyEditorReset());
  }

}
