export const EMPLOYEMENT_STATUS_OPTIONS = [
    {
        value: 'Employed',
        label: 'Employed'
    },
    {
        value: 'SelfEmployed',
        label: 'Self-employed'
    },
    {
        value: 'Retired',
        label: 'Retired'
    },
    {
        value: 'Unemployed',
        label: 'Unemployed'
    },
    {
        value: 'Student',
        label: 'Student'
    },
    {
        value: 'InternationalStudent',
        label: 'International Student'
    },
    {
        value: 'Unknown',
        label: 'Unknown'
    }
]
