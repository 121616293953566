import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { Tag } from "../../../../shared/models/tenant-info-models";
import { TenantDialogService } from "../../tenant-dialog.service";
import { tenantDialogTagActions } from "./tenant-dialog-tenant-tag.action";

@Injectable()
export class TenantDialogTagsEffects {
    constructor(
        private actions$: Actions,
        private tenantDialogService: TenantDialogService,
        private notificationService: NotificationService
    ) { }

    requestTenantTags = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogTenantTagsRequested),
            switchMap(action =>
                this.tenantDialogService.getTenantTags(action.tenantId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags = response.data as Tag[]
                            return tenantDialogTagActions.actionTenantDialogTenantTagsLoaded({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogTagActions.actionTenantDialogTenantTagsFailed()
                        }
                    }),
                    catchError(error => of(tenantDialogTagActions.actionTenantDialogTenantTagsFailed()))
                )
            )
        )
    );

    requestClientTagOptions = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogTagOptionsRequested),
            switchMap(action =>
                this.tenantDialogService.getTenantTagOptions().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags = response.data as Tag[]
                            return tenantDialogTagActions.actionTenantDialogTagOptionsLoaded({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogTagActions.actionTenantDialogTagOptionsFailed()
                        }
                    }),
                    catchError(error => of(tenantDialogTagActions.actionTenantDialogTagOptionsFailed()))
                )
            )
        )
    );

    initialTagCheckStatus = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogTagOptionsLoaded),
            switchMap(action =>
                [tenantDialogTagActions.actionTenantDialogTagOptionsCheckStateInitialize()]
            )
        )
    )

    deleteClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogTenantTagDeleteRequested),
            switchMap(action =>
                this.tenantDialogService.deleteTenantTag(action.tenantId, action.tagId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags: Tag[] = response.data
                            return tenantDialogTagActions.actionTenantDialogTenantTagDeleteSuccessfully({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogTagActions.actionTenantDialogTenantTagDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogTagActions.actionTenantDialogTenantTagDeleteFailed())
                    })
                )
            )
        )
    );



    updateClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogTagsUpdateRequested),
            switchMap(action =>
                this.tenantDialogService.updateTenantTags(action.id, action.tags).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags: Tag[] = response.data
                            return tenantDialogTagActions.actionTenantDialogTagsUpdateSuccessfully({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogTagActions.actionTenantDialogTagsUpdateFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogTagActions.actionTenantDialogTagsUpdateFailed())
                    })
                )
            )
        )
    );

    addBatchClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogBatchTagsAddRequested),
            switchMap(action =>
                this.tenantDialogService.addBatchTenantTags(action.tenantIds, action.tagIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return tenantDialogTagActions.actionTenantDialogBatchTagsAddSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogTagActions.actionTenantDialogBatchTagsAddFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogTagActions.actionTenantDialogBatchTagsAddFailed())
                    })
                )
            )
        )
    );

    removeBatchClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogTagActions.actionTenantDialogBatchTagsRemoveRequested),
            switchMap(action =>
                this.tenantDialogService.removeBatchTenantTags(action.tenantIds, action.tagIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return tenantDialogTagActions.actionTenantDialogBatchTagsRemoveSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogTagActions.actionTenantDialogBatchTagsRemoveFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogTagActions.actionTenantDialogBatchTagsRemoveFailed())
                    })
                )
            )
        )
    );

}