import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { State } from  'app/features/booking/booking.state';
import { takeUntil } from 'rxjs/operators';
import { actionBookingEventCalendarChangeMinDate } from 'app/features/booking/components/booking-calendar/booking-calendar.action';
import { selectBookingEventCalendarMinDate, selectBookingEventIsScheduleOverwrite } from 'app/features/booking/components/booking-calendar/booking-calendar.selector';
@Component({
  selector: 'dq-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy {

  constructor(public store: Store<State>) { }

  @Output()
  dateSelected = new EventEmitter<string>();

  minDate: Date;
  selectedDate: Date = new Date();
  isScheduleOverwrite$: Observable<boolean>;
  minCalendarDate$: Observable<Date>;
  unsubscribe: Subject<void> = new Subject();

  calendarHeader = CalendarHeaderComponent;

  ngOnInit(): void {

    this.minCalendarDate$ = this.store.pipe(select(selectBookingEventCalendarMinDate));
    this.isScheduleOverwrite$ = this.store.pipe(select(selectBookingEventIsScheduleOverwrite));

    this.isScheduleOverwrite$.pipe(takeUntil(this.unsubscribe)).subscribe(isScheduleOverWrite => {
      if(isScheduleOverWrite){
        var date = new Date();
        date.setFullYear(date.getFullYear()-2);
        this.store.dispatch(actionBookingEventCalendarChangeMinDate({minDate: date}));
        this.minDate = date;
      }
      else{
        this.store.dispatch(actionBookingEventCalendarChangeMinDate({minDate: new Date()}));
        this.minDate = new Date();
      }
    });
  }

  dateChanged(event) {
    this.dateSelected.emit(event)
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
        const highlightDate = this.minDate < date
        return highlightDate ? 'special-date' : '';
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}

