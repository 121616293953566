export interface LeadInfo {
  id: number;
  firstName: string;
  lastName: string;
  emails: string[];
  username: string;
  phoneNumbers: string[];
  phoneNumberCountryCode: string;
  interests: string;
  leadSourceId: number;
  leadSources: LeadSource[];
  leadBuildings: LeadBuilding[]
  lastCommunicationDate: string
  leadListTags: Tag[]
  leadAssignedUsers: AssignedUser[]
  phoneNumberBlockInfo?: LeadPhoneNumber[]
}

export interface LeadPhoneNumber {
  id: number
  phoneNumber: string
  isPhoneNumberBlocked: boolean
  leadId: number
}

export interface IUpdateLeadProfileParams {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumbers: string[];
  emails: string[];
}

export interface LeadSource {
  id: number;
  name: string;
  shortName: string;
  description: string;
  logoUri: string;
  isDefault?: boolean
}

export interface Tag {
  id: number;
  name: string;
  isChecked: boolean;
  isPreDefined?: boolean
}

export interface Preference {
  id: number;
  name: string;
  isChecked: boolean;
}

export interface AssignedUser {
  id: number;
  name: string;
  phoneNumbers?: IUserPhoneNumber[]
  email: string
  isChecked: boolean;
}

export interface LeadBuilding {
  id: number
  name: string
  isChecked: boolean
}


export interface BookingRepresentativeCalendarType {
  name: string;
  type: string;
}

export interface BookingTimeSlot {
  id: string;
  label: string;
}

export interface ILeadToMerge {
  id: string;
  name: string;
  emails: string;
  phoneNumbers: string;
  appointmentCount: string;
  communicationCount: string;
}

export interface IUserPhoneNumber {
  id: number
  friendlyName: string
  phoneNumber: string
}

export const ACTIVITY_TYPES = {
  TEXT: 'Text',
  VOICE: 'Voice',
  EMAIL: 'Email',
  NOTE: 'Note',
  WEBFORM: 'WebForm',
  APPOINTMENT: 'Appointment'
}

export interface EmploymentStatus {
  id: string
  label: string
}
export const employmentStatus: EmploymentStatus[] = [
  {
    id: '1',
    label: 'ExistingEmployed'
  },
  {
    id: '2',
    label: 'NewEmployed'
  },
  {
    id: '3',
    label: 'Self-Employed'
  },
  {
    id: '4',
    label: 'Student'
  },
  {
    id: '5',
    label: 'International Student'
  },
  {
    id: '6',
    label: 'Corporation'
  },
  {
    id: '7',
    label: 'UnEmployed'
  }
]


export interface ClientAppointment {
  id: number
  buildingId: number
  representativeName: string;
  clientName: string;
  startTime: string;
  timeZone: string;
  appointmentStatusType: string;
  winPrice: number;
  lostReason: string;
  lostComments: string;
  closingDate: string;
  bedPreference: string;
  bathPreference: string;
  parkingPreference: string;
  petPreference: string;
  buildingName: string;
  buildingAddress: string;
  budget: string;
  moveInDate: string;
  note: string;
  unitIdentifier: string;
  language?: string
  lastUpdateBy?: string
}






