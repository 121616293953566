import { createSelector } from "@ngrx/store";
import { TenantDialogEmailEditorState, TenantDialogState, selectTenantDialog } from "../../tenant-dialog.state";

export const selectTenantDialogEmailEditorState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogEmailEditor
);

export const selectTenantDialogTenantRepEmails = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.tenantUserEmails
);

export const selectTenantDialogIsEmailSubmittedSuccessfully = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isEmailSubmittedSuccessfully
);

export const selectTenantDialogIsEmailSubmitting = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isEmailSubmitting
);

export const selectTenantDialogEmailEditor = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.emailEditor
);

export const selectTenantDialogEmailEditorIsCcAndBccActive = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isCcAndBccActive
);

export const selectTenantDialogEmailReplyEditor = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.emailReplyEditor
);

export const selectTenantDialogEmailReplyEditorIsForwarding = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isForwarding
);

export const selectTenantDialogEmailReplyEditorReplyAllTo = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.replayAllTo
);

export const selectTenantDialogEmailTemplates = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.emailTemplates
);

export const selectTenantDialogEmailSendingType = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.emailSendType
);

export const selectTenantDialogEmailSendingExternalIdentifier = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.emailSendingExternalIdentifier
);

export const selectTenantDialogisEmailTemplatesLoaded = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isTemplatesLoaded
);

export const selectTenantDialogEmailIsTemplateSaveOrUpdating = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isTemplateSaveOrUpdateing
);

export const selectTenantDialogEmailisTemplateSaveOrUpdatedSuccessfully = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isTemplateSaveOrUpdateSuccessfully
);

export const selectTenantDialogEmailIsTemplateDeleting = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isTemplateDeleting
);

export const selectTenantDialogEmailisTemplateDeletedSuccessfully = createSelector(
    selectTenantDialogEmailEditorState,
    (state: TenantDialogEmailEditorState) => state.isTemplateDeletedSuccessfully
);
