import { createAction, props } from "@ngrx/store";
import { WeeklySchedule } from "app/shared/models/weekschedule.model";
import { RepresentativeThumbnail, BookingEvent, IAppointment } from "../../booking.state";

export const actionBookingAccountRepresentativesSelectedUserIdUpdate = createAction(
    '[Booking] Booking Account Representatives Selected User Id Update',
    props<{ repUserId: number }>()
);
export const actionBookingRepresentativeCalendarRequested = createAction(
    '[Booking] Booking Representative Calendar Requested',
    props<{ repUserId: number }>()
);

export const actionBookingRepresentativeCalendarLoaded = createAction(
    '[Booking] Booking Representative Calendar Loaded',
    props<{ representative: RepresentativeThumbnail, events: BookingEvent[] }>()
);

export const actionBookingRepresentativeCalendarFailed = createAction(
    '[Booking] Booking Representative Calendar Failed',
);

export const actionBookingRepresentativeCalendarAppointmentsRequested = createAction(
    '[Booking] Booking Representative Calendar Appointments Requested',
    props<{ userId: number }>()
);

export const actionBookingRepresentativeCalendarAppointmentsLoaded = createAction(
    '[Booking] Booking Representative Calendar Appointments Loaded',
    props<{ appointments: IAppointment[] }>()
);

export const actionBookingRepresentativeCalendarAppointmentsFailed = createAction(
    '[Booking] Booking Representative Calendar Appointments Failed',
);

export const actionBookingRepresentativeCalendarAppointmentCanceled = createAction(
    '[Booking] Booking Representative Calendar Appointment Canceled',
    props<{ id: number }>()
)

export const actionBookingRepresentativeCalendarAppointmentCanceledSuccess = createAction(
    '[Booking] Booking Representative Calendar Appointment Canceled Success',
)

export const actionBookingRepresentativeCalendarAppointmentCanceledFailed = createAction(
    '[Booking] Booking Representative Calendar Appointment Canceled Failed',
)

export const actionBookingRepresentativeCalendarCheckMicrosoftConnected = createAction(
    '[Booking] Booking Representative Calendar Check Microsoft Connected',
    props<{ repUserId: number }>()
);

export const actionBookingRepresentativeCalendarChangeMicrosoftConnected = createAction(
    '[Booking] Booking Representative Calendar Change Microsoft Connected',
    props<{ ifConnected: boolean }>()
);

export const actionBookingRepresentativeCalendarChangeMicrosoftConnectedFailed = createAction(
    '[Booking] Booking Representative Calendar Change Microsoft Connected Failed',
);

export const actionBookingRepresentativeCalendarCheckZoomConnected = createAction(
    '[Booking] Booking Representative Calendar Check Zoom Connected',
    props<{ repUserId: number }>()
);
export const actionBookingRepresentativeCalendarCheckZoomConnectedFailed = createAction(
    '[Booking] Booking Representative Calendar Check Zoom Connected Failed',
);

export const actionBookingRepresentativeCalendarChangeZoomConnected = createAction(
    '[Booking] Booking Representative Calendar Change Zoom Connected',
    props<{ ifConnected: boolean }>()
);

export const actionBookingRepresentativeCalendarRepresentativeWeekScheduleRequested = createAction(
    '[Booking] Booking Representative Calendar Representative Week Schedule Requested',
    props<{ representativeId: number, date: string }>()
);

export const actionBookingRepresentativeCalendarRepresentativeWeekScheduleLoaded = createAction(
    '[Booking] Booking Representative Calendar Representative Week Schedule Loaded',
    props<{ weekSchedule: WeeklySchedule }>()
);
export const actionBookingRepresentativeCalendarRepresentativeWeekScheduleFailed = createAction(
    '[Booking] Booking Representative Calendar Representative Week Schedule Failed',
);

export const actionBookingRepresentativeCalendarReset = createAction(
    '[Booking] Booking Representative Calendar Reset',
);


export const bookingRepresentativeCalendarActions = {
    actionBookingRepresentativeCalendarRequested,
    actionBookingRepresentativeCalendarLoaded,
    actionBookingRepresentativeCalendarAppointmentsRequested,
    actionBookingRepresentativeCalendarAppointmentsLoaded,
    actionBookingRepresentativeCalendarAppointmentCanceled,
    actionBookingRepresentativeCalendarAppointmentCanceledSuccess,
    actionBookingRepresentativeCalendarCheckMicrosoftConnected,
    actionBookingRepresentativeCalendarChangeMicrosoftConnected,
    actionBookingRepresentativeCalendarCheckZoomConnected,
    actionBookingRepresentativeCalendarChangeZoomConnected,
    actionBookingRepresentativeCalendarRepresentativeWeekScheduleRequested,
    actionBookingRepresentativeCalendarRepresentativeWeekScheduleLoaded,
    actionBookingRepresentativeCalendarReset
}

export const bookingRepresentativeCalendarFailedActions = {
    actionBookingRepresentativeCalendarFailed,
    actionBookingRepresentativeCalendarAppointmentsFailed,
    actionBookingRepresentativeCalendarAppointmentCanceledFailed,
    actionBookingRepresentativeCalendarChangeMicrosoftConnectedFailed,
    actionBookingRepresentativeCalendarCheckZoomConnectedFailed,
    actionBookingRepresentativeCalendarRepresentativeWeekScheduleFailed
}

