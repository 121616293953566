import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IWebApiResponse } from 'app/core/response/response.model';
import { environment } from 'environments/environment';


@Injectable()
export class AccountIntegrationService {

  constructor(private http: HttpClient) {
  }

  microsoftAuthorize(params: any) {
    return this.http
      .post(`${environment.apiBaseUrl}microsoftIdentity/authorize`, params)
      .pipe(map((response: IWebApiResponse) => response));
  }

  zoomAuthorize(params: any) {
    return this.http
      .post(`${environment.apiBaseUrl}zoomIdentity/authorize`, params)
      .pipe(map((response: IWebApiResponse) => response));
  }

  microsoftCheckAccount() {
    return this.http.get(`${environment.apiBaseUrl}microsoftIdentity/checkAccount`)
      .pipe(map((response: IWebApiResponse) => response));
  }

  zoomCheckAccount() {
    return this.http.get(`${environment.apiBaseUrl}zoomIdentity/checkAccount`)
      .pipe(map((response: IWebApiResponse) => response));
  }

}
