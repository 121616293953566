import { Action, createReducer, on } from '@ngrx/store';
import { actionNotificationManagerAddNewNotification, actionNotificationManagerNotificationRead } from './notification-manager.actions';

import { NotificationManagerState } from './notification-manager.model';

export const initialState: NotificationManagerState = {
  notifications: [

  ],
  notificationCount: 0
};

const reducer = createReducer(
  initialState,
  on(actionNotificationManagerAddNewNotification, (state, { notification }) => ({
    ...state,
    notifications: [...state.notifications, notification],
    notificationCount: state.notificationCount + 1
  })),
  on(actionNotificationManagerNotificationRead, (state, { notification }) => ({
    ...state,
    notifications: state.notifications.filter(x => x.id != notification.id),
    notificationCount: state.notificationCount - 1
  })),
)

export function notificationManagerReducer(
  state: NotificationManagerState | undefined,
  action: Action
): NotificationManagerState {
  return reducer(state, action);
}
