<div class="tenant-dialog-communication-template-editor-component">
    <div class="communication-template-dialog-title">
        <div>
            <mat-icon class="template-icon">list</mat-icon>
            <h4>Templates</h4>
        </div>
              <button mat-icon-button (click)="$event.preventDefault();close()">
            <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
        </button>
    </div>
    <div class="main-container">
        <div class="template-content-container">
            <form [formGroup]="formTemplate">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Template</mat-label>
                            <mat-select formControlName="id" [disabled]="!isEditTemplate()">
                                <mat-select-trigger>
                                    <ng-container *ngIf="isAccountShared"><mat-icon matListIcon>supervised_user_circle</mat-icon></ng-container>&nbsp;{{friendlyName}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let template of (templates$ | async)" [value]="template.id" (click)="updateTemplateForm(template)">
                                    <ng-container *ngIf="template.isAccountShared">
                                        <mat-icon matListIcon>supervised_user_circle</mat-icon>
                                    </ng-container>{{template.friendlyName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Template Friendly Name</mat-label>
                            <input matInput placeholder="Template Friendly Name" formControlName="friendlyName" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Display Name</mat-label>
                            <input matInput placeholder="Display Name" formControlName="displayName" required>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <editor #tinyMce [apiKey]="tinyMceApiKey" [init]="editorSettings" formControlName="content"></editor>
                    </div>
                </div>
                <div class="footer-action-group">
                    <div class="footer-left-action-group">
                        <div class="footer-left-action">
                            <button class="operate-button" mat-stroked-button (click)="updateTemplateForm()">Create New</button>
                        </div>
                        <ng-container *ngIf="hasRightToEdit()">
                            <div class="footer-left-action">
                                <ng-container *ngIf="isEditTemplate()">
                                    <button class="operate-button" mat-stroked-button
                                        (click)="deleteTemplate()">Delete</button>
                                </ng-container>
                                <ng-container *ngIf="!isEditTemplate() && templates.length > 0">
                                    <button class="operate-button" mat-stroked-button (click)="cancelCreateNew()">Cancel</button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="footer-right-action-group">
                        <ng-container *ngIf="hasRightToEdit()">
                            <ng-container *ngIf="hasRightToShare()">
                                <div class="footer-right-action">
                                    Shared Template? <mat-slide-toggle formControlName="isAccountShared"></mat-slide-toggle>
                                </div>
                            </ng-container>
                            <div class="footer-right-action">
                                <dq-spinner-button class="save-button" type="button" (click)="save()" [options]="spinnerSaveButtonOptions"></dq-spinner-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
