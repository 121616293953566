import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IWebApiResponse } from 'app/core/response/response.model';
import { environment } from 'environments/environment';
import { IUpdateTenantProfileParams, AssignedUser, Tag } from '../../shared/models/tenant-info-models';
import { TaskCreateParam, TaskUpdateParam } from 'app/shared/models/task.model';


@Injectable()
export class TenantDialogService {
  constructor(
    private http: HttpClient,
  ) { }

  requestUserCommunicationSettings(tenantId: number) {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getComSettings?tenantId=${tenantId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }


  updateUserGeneralProfile(params: IUpdateTenantProfileParams) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/updateTenantBasicInfo`, params)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createTenantNote(id: number, note: string) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/addTenantNote`,
      {
        id: id,
        note: note
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createTenantTextMessage(id: number, text: string, sendFromId: string, sendTo: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/sendTextMessage`,
      {
        id: id,
        text: text,
        sendFromId: sendFromId,
        sendTo: sendTo
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }


  getTenantDetailInfo(id: number | string): Observable<IWebApiResponse> {
    return this.http
      .get(
        `${environment.apiBaseUrl}tenant-detail/getTenantDetailInfoById?id=${id}`
      )
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getTenantTagOptions() {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTenantTagOptions`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTenantTags(tenantId: number) {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTenantTags?tenantId=${tenantId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }


  getTenantReps(tenantId: number) {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTenantReps?tenantId=${tenantId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTenantRepOptions() {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTenantAssignedUserOptions`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTenantBuilding(tenantId: number) {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTenantBuilding?tenantId=${tenantId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getActivitiesByTenantId(leadId: number, pageNo: number, pageSize: number, itemCountPage: number) {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTenantActivities?id=${leadId}&pageNo=${pageNo}&pageSize=${pageSize}&itemCountPage=${itemCountPage}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteTenantTag(tenantId: number, tagId: number) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/removeTenantTag`, {
      tenantId: tenantId,
      tagId: tagId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }


  deleteTenantRep(tenantId: number, repId: number) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/removeTenantAssignedUser`, {
      tenantId: tenantId,
      userId: repId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }


  updateTenantTags(id: number, tags: Tag[]) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/updateTenantTags`, {
      id: id,
      tags: tags,
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addBatchTenantTags(tenantIds: number[], tagIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}tenant-query/BatchAddTenantTag`, {
      tenantIds: tenantIds,
      tagIds: tagIds,
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  removeBatchTenantTags(tenantIds: number[], tagIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}tenant-query/BatchRemoveTenantTag`, {
      tenantIds: tenantIds,
      tagIds: tagIds,
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addBatchTenantReps(tenantIds: number[], repIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}tenant-query/BatchAddTenantAssignation`, {
      tenantIds: tenantIds,
      userIds: repIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  removeBatchTenantReps(tenantIds: number[], repIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}tenant-query/BatchRemoveTenantAssignation`, {
      tenantIds: tenantIds,
      userIds: repIds
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateTenantReps(id: number, reps: AssignedUser[]) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/updateTenantAssignedUsers`, {
      id: id,
      users: reps
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTenantsBySearchKeyWord(search: string, page: number, pageSize: number) {
    return this.http.get(`${environment.apiBaseUrl}tenant-query/GetTenantsByText?search=${search}&page=${page}&pageSize=${pageSize}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTasksByTenantId(tenantId: number) {
    return this.http.get(`${environment.apiBaseUrl}user-task/getUserTasksByTenantId?tenantId=${tenantId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getTaskAssignUserList() {
    return this.http.get(`${environment.apiBaseUrl}user-task/getTaskAssignUsers`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  createTask(param: TaskCreateParam) {
    return this.http.post(`${environment.apiBaseUrl}user-task/createUserTenantTask`, param)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateTask(param: TaskUpdateParam) {
    return this.http.post(`${environment.apiBaseUrl}user-task/updateUserTask`, param)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  deleteTask(taskId: number) {
    return this.http.post(`${environment.apiBaseUrl}user-task/deleteUserTask`, taskId)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  toggleUserTasKComnplateStatus(taskId: number) {
    return this.http.post(`${environment.apiBaseUrl}user-task/toggleUserTasKComnplateStatus`, taskId)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  phoneNumberBlock(tenantId: number, phoneNumber: string, reason: string) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/phoneNumberBlock`,
      {
        tenantId: tenantId,
        phoneNumber: phoneNumber,
        reason: reason
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  phoneNumberUnBlock(tenantId: number, phoneNumber: string) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/phoneNumberUnBlock`,
      {
        tenantId: tenantId,
        phoneNumber: phoneNumber,
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }
}
