import { createAction, props } from "@ngrx/store"
import { ITimelineElement } from "app/shared/components/timeline/timeline.model"

export const actionTenantDialogAllActivitiesRequest = createAction(
    '[Tenant Dialog Activity] All Activities Request',
    props<{ tenantId: number, pageNo: number, pageSize: number, itemCountPage: number, isLoadMore?: boolean }>()
)

export const actionTenantDialogAllActivitiesSuccessed = createAction(
    '[Tenant Dialog Activity] All Activities Successed',
    props<{ activities: ITimelineElement[], pageNo: number, pageSize: number, totalRecords: number }>()
)

export const actionTenantDialogAllActivitiesFailed = createAction(
    '[Tenant Dialog Activity] All Activities Failed',
)

export const actionTenantDialogActivityStateReset = createAction(
    '[Tenant Dialog Activity] State Reset'
)

export const tenantDialogActivityActions = {
    actionTenantDialogAllActivitiesRequest,
    actionTenantDialogAllActivitiesSuccessed,
    actionTenantDialogAllActivitiesFailed,
    actionTenantDialogActivityStateReset
}