import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadUpdateAction } from 'app/features/lead-manager/lead-manager.model';
import { LeadPreference, LeadPreferenceTypes } from 'app/shared/models/lead-preference.model';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectClientLeadId } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogPreferenceOptionsCheckStateInitialize, actionClientDialogPreferencePreferenceIsCheckedUpdate, actionClientDialogPreferenceUpdateRequested } from '../../client-dialog-lead-preference.action';
import { selectclientDialogPreferencesStateIsPreferenceOptionssLoaded, selectclientDialogPreferencesIsPreferenceSubmitting, selectclientDialogPreferencesIsCreateUpdateSuccessfully, selectclientDialogPreferencesStatePreferenceOptions, selectclientDialogPreferencesStateIsPreferencesLoaded } from '../../client-dialog-lead-preference.selector';
import { MatAccordion } from '@angular/material/expansion';
import { DigiDecimalPipe } from 'app/shared/pipes/digi-decimal-pipe.pipe';
import { DatePipe } from '@angular/common';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-user-general-preferences',
  templateUrl: './client-dialog-user-general-preference.component.html',
  styleUrls: ['./client-dialog-user-general-preference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogUserGeneralPreferencesComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ClientDialogUserGeneralPreferencesComponent>,
    @Inject('dialogData') private dialogData: any,
    private digiDecimalPipe: DigiDecimalPipe,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    // this.leadIds.length > 0 means now this components is used for bacth leads update (leadIds will come from leads manage page when tigger this dialog)
    if (dialogData && dialogData.action && dialogData.leadIds) {
      this.action = dialogData.action
      this.leadIds = dialogData.leadIds
    }
  }
  preferencePannelsOpen = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  action: LeadUpdateAction
  leadIds: number[] = []
  unsubscribe: Subject<void> = new Subject();
  budgetFormControl: UntypedFormControl = new UntypedFormControl();
  parkingFormControl: UntypedFormControl = new UntypedFormControl();
  accessibilityFormControl: UntypedFormControl = new UntypedFormControl();
  moveInDateFormControl: UntypedFormControl = new UntypedFormControl();
  preferenceOptions$: Observable<LeadPreference[]>;
  preferenceOptions: LeadPreference[];
  preferenceBedOptions: LeadPreference[];
  preferenceBathOptions: LeadPreference[];
  preferencePetOptions: LeadPreference[];
  preferenceParkingOptions: LeadPreference[];
  preferenceLanguageOptions: LeadPreference[];
  preferenceAccessbilityOptions: LeadPreference[];
  isPreferenceLoaded$: Observable<boolean>
  preferenceNameControl = new UntypedFormControl();
  type = '';
  panelOpenState = false;
  checkedPreferenceList: LeadPreference[];
  isPreferenceChecked: boolean;
  name: string;
  leadId$: Observable<number>;
  leadId: number;
  isMobile$: Observable<boolean>;
  isMobile: boolean;
  isPreferenceOptionsLoaded$: Observable<boolean>;
  isPreferencesSubmitting$: Observable<boolean>;
  isUpdateSuccessfully$: Observable<boolean>;

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.isPreferenceOptionsLoaded$ = this.store.pipe(select(selectclientDialogPreferencesStateIsPreferenceOptionssLoaded));
    this.isPreferencesSubmitting$ = this.store.pipe(select(selectclientDialogPreferencesIsPreferenceSubmitting));
    this.isUpdateSuccessfully$ = this.store.pipe(select(selectclientDialogPreferencesIsCreateUpdateSuccessfully));
    this.preferenceOptions$ = this.store.pipe(select(selectclientDialogPreferencesStatePreferenceOptions));
    this.isPreferenceLoaded$ = this.store.pipe(select(selectclientDialogPreferencesStateIsPreferencesLoaded));
    this.store.dispatch(actionClientDialogPreferenceOptionsCheckStateInitialize())
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.preferenceOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(preferences => {
      this.preferenceOptions = preferences;
      this.preferenceBedOptions = preferences.filter(x => x.type == LeadPreferenceTypes.BED);
      this.preferenceBathOptions = preferences.filter(x => x.type == LeadPreferenceTypes.BATH);
      this.preferencePetOptions = preferences.filter(x => x.type == LeadPreferenceTypes.PET);
      this.preferenceParkingOptions = preferences.filter(x => x.type == LeadPreferenceTypes.PARKING);
      this.preferenceLanguageOptions = preferences.filter(x => x.type == LeadPreferenceTypes.LANGUAGE);
      this.preferenceAccessbilityOptions = preferences.filter(x => x.type == LeadPreferenceTypes.ACCESSIBILITY);
      this.spinnerSaveButtonOptions.disabled = preferences.length == 0
      // this.leadIds.length > 0 means now this components is used for bacth leads update (leadIds will come from leads manage page when tigger this dialog)
      if (this.leadIds && this.leadIds.length > 0) {
        this.spinnerSaveButtonOptions.disabled = preferences.filter(preference => preference.isChecked).length == 0;

      }
      this.cdr.markForCheck()
    })

    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId;
    })
    this.isPreferencesSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isPreferencesSubmitting => {
      this.spinnerSaveButtonOptions.active = isPreferencesSubmitting;
      this.cdr.markForCheck()
    })
    this.isUpdateSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isUpdateSuccessfully => {
      if (isUpdateSuccessfully) {
        this.dialogRef.close()
      }
    })

    this.preferenceNameControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value) {
        this.accordion.openAll();
      } else {
        this.accordion.closeAll();
      }
      this.preferenceBedOptions = this._filter(value, LeadPreferenceTypes.BED);
      this.preferenceBathOptions = this._filter(value, LeadPreferenceTypes.BATH);
      this.preferencePetOptions = this._filter(value, LeadPreferenceTypes.PET);
      this.preferenceParkingOptions = this._filter(value, LeadPreferenceTypes.PARKING);
      this.preferenceLanguageOptions = this._filter(value, LeadPreferenceTypes.LANGUAGE);
      this.preferenceAccessbilityOptions = this._filter(value, LeadPreferenceTypes.ACCESSIBILITY);
      this.cdr.markForCheck();
    })
  }

  private _filter(value: string, type: LeadPreferenceTypes): LeadPreference[] {
    const filterValue = value.toLowerCase();
    return this.preferenceOptions.filter(option => option.label.toLowerCase().includes(filterValue) && option.type == type);
  }

  changeSelection(checked, preference) {
    this.store.dispatch(actionClientDialogPreferencePreferenceIsCheckedUpdate({ checked, preference }));
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    let preferences = this.preferenceOptions.filter(preference => preference.isChecked);
    if (this.budgetFormControl.value && this.digiDecimalPipe.transform((this.budgetFormControl.value)) != 0) {
      const budgetPreference: LeadPreference = {
        label: this.digiDecimalPipe.transform(this.budgetFormControl.value),
        type: LeadPreferenceTypes.BUDGET,
        value: this.digiDecimalPipe.transform(this.budgetFormControl.value)
      }
      preferences = [...preferences, budgetPreference]
    }
    if (this.moveInDateFormControl.value) {
      const moveInDatePreference: LeadPreference = {
        label: this.datePipe.transform(this.moveInDateFormControl.value, 'MMM d, y'),
        type: LeadPreferenceTypes.MOVE_IN_DATE,
        value: this.moveInDateFormControl.value
      }
      preferences = [...preferences, moveInDatePreference]
    }
    if (this.parkingFormControl.value) {
      const parkingPreference: LeadPreference = {
        label: this.parkingFormControl.value,
        type: LeadPreferenceTypes.PARKING,
        value: this.parkingFormControl.value
      }
      preferences = [...preferences, parkingPreference]
    }
    if (this.accessibilityFormControl.value) {
      const accessibilityPreference: LeadPreference = {
        label: this.accessibilityFormControl.value,
        type: LeadPreferenceTypes.ACCESSIBILITY,
        value: this.accessibilityFormControl.value
      }
      preferences = [...preferences, accessibilityPreference]
    }
    const id = this.leadId
    this.store.dispatch(actionClientDialogPreferenceUpdateRequested({ id, preferences }))

  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
