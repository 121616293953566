<div class="timeline-email-component">
    <div class="desktop-container">
        <section>
            <article class="timeline-content">
                <header class="header">
                    <mat-icon class="timeline-icon email">mail</mat-icon>
                    <div class="header-meta">
                        <strong>
                            {{event.title}}
                        </strong>
                        <h4>{{subject}}</h4>
                        <h5>
                            <ng-container *ngIf="fromEmail">
                                <strong>From:</strong>
                                <span>
                                    {{fromEmail}}
                                </span>
                            </ng-container>
                            <!-- <ng-container *ngIf="toEmails && toEmails.length > 0">
                                <strong>To:</strong>
                                <span *ngFor="let email of toEmails; let i = index">
                                    {{email}}
                                    <ng-container *ngIf="i < toEmails.length -1">
                                        ,
                                    </ng-container>
                                </span>
                            </ng-container> -->
                            <strong>To:</strong>
                            <span>
                                {{toEmail}}
                            </span>
                        </h5>
                        <h5>
                            <ng-container *ngIf="ccs">
                                <strong>Cc:</strong>
                                <span>
                                    {{ccs}}
                                </span>
                            </ng-container>
                        </h5>
                        <em>
                            {{event.date|date: 'medium'}}
                            <ng-container *ngIf="displayEmailReply">
                                <span class="reply-button-span">
                                    <mat-icon class="reply-icon" matTooltip="Reply"
                                        (click)="replyEmail()">reply</mat-icon>
                                    <mat-icon class="reply-icon" matTooltip="Reply All"
                                        (click)="replyAllEamil()">reply_all</mat-icon>
                                    <mat-icon class="reply-icon forward-icon" matTooltip="Forward"
                                        (click)="forwardEmail()">reply</mat-icon>
                                </span>
                            </ng-container>
                        </em>
                    </div>
                </header>
                <iframe #iframe [ngClass]="showFullMails?'mail-details-expanded':'mail-details-expandable'"></iframe>
                <div class="more-less-toggle-div">
                    <ng-container *ngIf="showFullMails">
                        <span class="more-less-toggle" (click)="toggleShowMoreLess()">Hide Email</span>
                    </ng-container>
                    <ng-container *ngIf="!showFullMails">
                        <span class="more-less-toggle" (click)="toggleShowMoreLess()">Show Email</span>
                    </ng-container>
                </div>
            </article>
        </section>
    </div>
</div>