import { createSelector } from "@ngrx/store";
import { ClientDialogBaseState, ClientDialogState, selectClientDialog } from "./client-dialog.state";

export const selectClientDialogBase = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogBase
)

export const selectTopNavigationTab = createSelector(
    selectClientDialogBase,
    (state: ClientDialogBaseState) => state.activeTab
);

