import { createAction, props } from "@ngrx/store";
import { Account } from "../../booking.state";

export const actionBookingAccountAccountsRequested = createAction(
    '[Booking] Booking Accounts Requested'
);

export const actionBookingAccountAccountsLoaded = createAction(
    '[Booking] Booking Accounts Loaded',
    props<{accounts: Account[]}>()
);

export const actionBookingAccountAccountsFailed = createAction(
    '[Booking] Booking Accounts Failed',
);

export const actionBookingAccountAccountsReset = createAction(
    '[Booking] Booking Accounts Reset',
);


export const bookingAccountActions = {
    actionBookingAccountAccountsRequested,
    actionBookingAccountAccountsLoaded,
    actionBookingAccountAccountsReset
}

export const bookingAccountFailedActions = {
    actionBookingAccountAccountsFailed
}

  