import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { LeadPreference } from "app/shared/models/lead-preference.model";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ClientDialogService } from "../../client-dialog.service";
import { clientDialogPreferenceActions } from "./client-dialog-lead-preference.action";

@Injectable()
export class ClientDialogPreferencesEffects {
    constructor(
        private actions$: Actions,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    requestLeadPreferences = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogPreferenceActions.actionClientDialogLeadPreferenceRequested),
            switchMap(action =>
                this.clientDialogService.getLeadPreferences(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let preferences = response.data as LeadPreference[]
                            return clientDialogPreferenceActions.actionClientDialogLeadPreferenceLoaded({ preferences })
                        } else {
                            return clientDialogPreferenceActions.actionClientDialogLeadPreferenceFailed()
                        }
                    }),
                    catchError(error => of(clientDialogPreferenceActions.actionClientDialogLeadPreferenceFailed()))
                )
            )
        )
    );

    deleteClientPreference = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogPreferenceActions.actionClientDialogClientPreferenceDeleteRequested),
            switchMap(action =>
                this.clientDialogService.deleteClientPreference(action.leadId, action.preferenceValue).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let preferences: LeadPreference[] = response.data
                            return clientDialogPreferenceActions.actionClientDialogClientPreferenceDeleteSuccessfully({ preferences })
                        } else {
                            this.notificationService.error('Remove Failed')
                            return clientDialogPreferenceActions.actionClientDialogClientPreferenceDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error('Remove Failed')
                        return of(clientDialogPreferenceActions.actionClientDialogClientPreferenceDeleteFailed())
                    })
                )
            )
        )
    );

    updateClientPreference = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogPreferenceActions.actionClientDialogPreferenceUpdateRequested),
            switchMap(action =>
                this.clientDialogService.updateClientPreferences(action.id, action.preferences).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let preferences: LeadPreference[] = response.data
                            return clientDialogPreferenceActions.actionClientDialogPreferenceUpdateSuccessfully({ preferences })
                        } else {
                            this.notificationService.error('Update Failed')
                            return clientDialogPreferenceActions.actionClientDialogPreferenceUpdateFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error('Update Failed')
                        return of(clientDialogPreferenceActions.actionClientDialogPreferenceUpdateFailed())
                    })
                )
            )
        )
    );
}
