import { createReducer, on } from "@ngrx/store";
import { clientDialogActions } from "../../client-dialog.actions";
import { ClientDialogTextMessageState } from "../../client-dialog.state";
import { clientDialogTextMessageActions } from "./client-dialog-lead-quick-text-message.action";

const initialSate: ClientDialogTextMessageState = {
    isTextMessageSubmitting: false,
    isTextMessageSubmittedSuccessfully: false,
    sendFromPhoneNumbers: [],
    textMessageEditor: {
        message: '',
        sendFrom: '',
        sendTo: ''
    },
    textMessageTemplates: [],
    isTemplatesLoaded: false,
    isTemplateSaveOrUpdateing: false,
    isTemplateSaveOrUpdateSuccessfully: false,
    isTemplateDeletedSuccessfully: false,
    isTemplateDeleting: false
}

const reducer = createReducer(
    initialSate,
    on(
        clientDialogTextMessageActions.actionClientDialogSendTextRequested,
        clientDialogTextMessageActions.actionClientDialogSendTextAndCollaborateRequested,
        (state) => ({
            ...state,
            isTextMessageSubmitting: true,
            isTextMessageSubmittedSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogSendTextSuccess,
        clientDialogTextMessageActions.actionClientDialogSendTextAndCollaborateSuccess,
        (state) => ({
            ...state,
            isTextMessageSubmitting: false,
            isTextMessageSubmittedSuccessfully: true
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogSendTextFailed,
        clientDialogTextMessageActions.actionClientDialogSendTextAndCollaborateFailed,
        (state) => ({
            ...state,
            isTextMessageSubmitting: false,
            isTextMessageSubmittedSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTextContentUpdate,
        (state, { message, sendFrom, sendTo }) => ({
            ...state,
            textMessageEditor: {
                message: message,
                sendFrom: sendFrom,
                sendTo: sendTo
            }
        })
    ),
    on(clientDialogActions.actionClientDialogCommunicationSettingsSucceded, (state, { emails, phoneNumbers, userId }) => ({
        ...state,
        sendFromPhoneNumbers: phoneNumbers,
        textMessageEditor: {
            ...state.textMessageEditor,
            sendFrom: phoneNumbers.find(x => x.userId == userId) != null ? phoneNumbers.find(x => x.userId == userId).id : phoneNumbers[0]?.id
        }
    })),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplatesLoaded,
        (state, { templates }) => ({
            ...state,
            textMessageTemplates: templates,
            isTemplatesLoaded: true
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateSaveAndUpdate,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: true,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateSaveAndUpdateSucceeded,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: true
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateSaveAndUpdateFailed,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateSaveAndUpdateStateReset,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateDeleteRequest,
        (state) => ({
            ...state,
            isTemplateDeleting: true,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateDeleteSucceeded,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: true
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateSaveAndUpdateFailed,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        clientDialogTextMessageActions.actionClientDialogTextMessageTemplateDeleteStateReset,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(clientDialogTextMessageActions.actionClientDialogTextMessagestateReset, (state) => initialSate),
)

export const clientDialogTextMessageReducer = (state: ClientDialogTextMessageState | undefined, action) => reducer(state, action)