<div class="tenant-dialog-note-component">
  <div class="desktop-container">
    <mat-form-field appearance="outline">
      <mat-label>Note</mat-label>
      <textarea [formControl]="note" matInput cdkTextareaAutosize cdkAutosizeMinRows="8" cdkAutosizeMaxRows="200"
        placeholder="Add Note" maxlength="4000"></textarea>
    </mat-form-field>
    <dq-spinner-button class="add-button" type="button" (click)="save()" [options]="spinnerSaveButtonOptions">
    </dq-spinner-button>
  </div>
</div>