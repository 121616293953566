import { Action, createReducer, on } from "@ngrx/store";
import { TenantDialogTaskState } from "../../tenant-dialog.state";
import { tenantDialogTaskActions, tenantDialogTaskFailActions } from "./tenant-dialog-task.action";

const initialState: TenantDialogTaskState = {
    isTasksLoaded: false,
    tasks: [],
    taskCreateState: {
        isCreating: false,
        isCreatedSuccessfully: false,
        taskAssignUsers: []
    },
    taskEditState: {
        isEditing: false,
        isEditSuccessfully: false
    },
    taskDeleteState: {
        isDeleting: false,
        isDeletSuccessfully: false
    }
}

const reducer = createReducer(
    initialState,
    on(
        tenantDialogTaskActions.actionTenantDialogTaskTasksRequested,
        (state) => ({
            ...state,
            isTasksLoaded: false
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTaskTasksLoaded,
        (state, { tasks }) => ({
            ...state,
            isTasksLoaded: true,
            tasks: tasks,
        })
    ),
    on(
        tenantDialogTaskFailActions.actionTenantDialogTaskTasksLoadFailed,
        (state) => ({
            ...state,
            isTasksLoaded: true
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskCreateRequested,
        (state) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                isCreating: true
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskCreateSucceed,
        (state) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                isCreating: false,
                isCreatedSuccessfully: true
            }
        })
    ),
    on(
        tenantDialogTaskFailActions.actionTenantDialogTasktaskCreateFailed,
        (state) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                isCreating: false,
                isCreatedSuccessfully: false
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskUpdateRequested,
        (state) => ({
            ...state,
            taskEditState: {
                ...state.taskEditState,
                isEditing: true
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskUpdateSucceed,
        (state) => ({
            ...state,
            taskEditState: {
                ...state.taskEditState,
                isEditing: false,
                isEditSuccessfully: true
            }
        })
    ),
    on(
        tenantDialogTaskFailActions.actionTenantDialogTasktaskUpdateFailed,
        (state) => ({
            ...state,
            taskEditState: {
                ...state.taskEditState,
                isEditing: false,
                isEditSuccessfully: false
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskDeleteRequested,
        (state) => ({
            ...state,
            taskDeleteState: {
                ...state.taskDeleteState,
                isDeleting: true
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskDeleteSucceed,
        (state) => ({
            ...state,
            taskDeleteState: {
                ...state.taskDeleteState,
                isDeleting: false,
                isDeletSuccessfully: true
            }
        })
    ),
    on(
        tenantDialogTaskFailActions.actionTenantDialogTasktaskDeleteFailed,
        (state) => ({
            ...state,
            taskDeleteState: {
                ...state.taskDeleteState,
                isDeleting: false,
                isDeletSuccessfully: false
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTasktaskAssignUsersLoaded,
        (state, { taskAssignUsers }) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                taskAssignUsers: taskAssignUsers
            }
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTaskTaskStatusToggle,
        tenantDialogTaskFailActions.actionTenantDialogTaskTaskStatusToggleFailed,
        (state, { taskId }) => ({
            ...state,
            tasks: state.tasks.map(x => x.id == taskId ? { ...x, isCompleted: !x.isCompleted } : x)
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTaskTaskCreateStateReset,
        (state) => ({
            ...state,
            taskCreateState: initialState.taskCreateState
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTaskTaskEditStateReset,
        (state) => ({
            ...state,
            taskEditState: initialState.taskEditState
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTaskTaskDeleteStateReset,
        (state) => ({
            ...state,
            taskDeleteState: initialState.taskDeleteState
        })
    ),
    on(
        tenantDialogTaskActions.actionTenantDialogTaskStateReset,
        (state) => initialState
    )
)

export const tenantDialogTaskReducer = (
    state: TenantDialogTaskState | undefined,
    action: Action
) => {
    return reducer(state, action);
};