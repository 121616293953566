import { createSelector } from '@ngrx/store';
import { selectNotificationState } from '../core.state';
import { NotificationManagerState } from './notification-manager.model';

export const selectNotificationNotifications = createSelector(
    selectNotificationState,
    (state: NotificationManagerState) => state.notifications
);

export const selectNotificationNotificationCount = createSelector(
    selectNotificationState,
    (state: NotificationManagerState) => state.notificationCount
);