import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TitleService } from 'app/core/core.module';

@Component({
  selector: 'cdk-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfUseComponent implements OnInit,OnDestroy {

  constructor(
              private titleService: TitleService,
              private router: Router) { }

  workingRealtor = `${environment.baseUrl.staticBaseUrl}/assets/misk/working-realtor.png`;

  unsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    this.titleService.setTitleByText('Terms of Use | DashQ')
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
