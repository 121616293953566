import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogClientAppointmentState } from "app/features/client-dialog/client-dialog.state";
import { clientDialogAppointmentsActions, clientDialogAppointmentsFailedActions } from "./client-dialog-appointment.action";
import { LOST_REASONS } from "app/shared/models/appointment.model";

export const initialState: ClientDialogClientAppointmentState = {
    isAppointmentsLoaded: true,
    clientAppointments: [],
    appointmentInEditting: {
        id: -999,
        buildingId: 61,
        representativeName: '',
        startTime: '',
        timeZone: '',
        appointmentStatusType: '',
        winPrice: 9999,
        lostReason: '',
        lostComments: '',
        closingDate: '',
        bedPreference: '',
        bathPreference: '',
        parkingPreference: '',
        petPreference: '',
        buildingAddress: '',
        budget: '',
        moveInDate: '',
        note: '',
        unitIdentifier: '',
        buildingName: '',
        clientName: ''
    },
    isCancelling: false,
    isCancelledSuccessfully: false,
    lostReasonOptions: LOST_REASONS
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentsRequested,
        (state) => ({
            ...state,
            isAppointmentsLoaded: false
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentsLoaded,
        (state, { clientAppointments }) => ({
            ...state,
            isAppointmentsLoaded: true,
            clientAppointments: clientAppointments
        })
    ),
    on(
        clientDialogAppointmentsFailedActions.actionClientDialogAppointmentAppointmentsFailed,
        (state) => ({
            ...state,
            isAppointmentsLoaded: true
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentInEdittingLoaded,
        (state, { clientAppointment }) => ({
            ...state,
            appointmentInEditting: clientAppointment
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentInEdittingReset,
        (state) => ({
            ...state,
            appointmentInEditting: initialState.appointmentInEditting
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentCancelStateReset,
        (state) => ({
            ...state,
            isCancelledSuccessfully: false,
            isCancelling: false
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentCancelRequseted,
        (state) => ({
            ...state,
            isCancelledSuccessfully: false,
            isCancelling: true
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentCancelSuccessfully,
        (state) => ({
            ...state,
            isCancelledSuccessfully: true,
            isCancelling: false
        })
    ),
    on(
        clientDialogAppointmentsFailedActions.actionClientDialogAppointmentAppointmentInEdittingFailed,
        (state) => ({
            ...state,
            isCancelledSuccessfully: false,
            isCancelling: false
        })
    ),
    on(
        clientDialogAppointmentsActions.actionClientDialogAppointmentStateReset,
        (state) => (initialState)
    )
)

export const clientDialogClientAppointmentReducer = (
    state: ClientDialogClientAppointmentState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
