import { createSelector } from '@ngrx/store';
import { UIState } from './ui.model';
import { selectUiState } from '../core.state';

export const selectUI = createSelector(
  selectUiState,
  (state: UIState) => state
);

export const selectHasFooter = createSelector(
  selectUI,
  (state: UIState) => state.hasFooter
);

export const selectWindowWidth = createSelector(
  selectUI,
  (state: UIState) => state.width
);
