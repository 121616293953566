
<div class="applicant-name">{{rentalApplicantFirstName}} {{rentalApplicantLastName}}</div>
<div class="form-div dq-flex-centered">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
    <div class="file-upload">
        <button mat-mini-fab class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>cloud_upload</mat-icon>
        </button>
        <div class="file-info">
            <div class="file-label">File Name: <span class="file-label-value">{{fileName || "No file uploaded yet."}}</span></div>
            <div class="file-label">File Type: <span class="file-label-value">{{fileType || "Not available yet"}}</span></div>
            <div class="file-label">File Size: <span class="file-label-value">{{(fileSize | dqFileSize) || "Not available yet"}}</span></div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 button-div">
        <dq-spinner-button [disabled]="!fileValid" class="generic-button confirm-button" type="button" (click)="submitForm()"
            [options]="spinnerAddNewFormButtonOptions"></dq-spinner-button>
    </div>
</div>

