import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectClientDialogClientAppointmentIsCancelledSuccessfully, selectClientDialogClientAppointmentIsCancelling, selectClientDialogClientAppointmentLostReasonOptions } from '../../client-dialog-appointment.selector';
import { IUpdateLostStatusParams } from 'app/shared/models/appointment.model';
import { actionClientDialogAppointmentCancelRequseted, actionClientDialogAppointmentCancelStateReset } from '../../client-dialog-appointment.action';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';


@Component({
  selector: 'dq-client-appointment-cancel-dialog',
  templateUrl: './client-appointment-cancel-dialog.component.html',
  styleUrls: ['./client-appointment-cancel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogAppointmentCancelDialopgComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private dialogRef: MatDialogRef<ClientDialogAppointmentCancelDialopgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  id: number;
  lostForm: UntypedFormGroup

  isMobile$: Observable<boolean>
  unsubscribe$: Subject<void> = new Subject();
  lostReasonOptions$: Observable<string[]>

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm',
    flat: true,
    spinnerSize: 18,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  isCancelling$: Observable<boolean>
  isCancelledSuccessfully$: Observable<boolean>

  ngOnInit(): void {

    if (this.data) {
      if (this.data.id !== undefined) {
        this.id = this.data.id
      }
    }

    this.lostReasonOptions$ = this.store.pipe(select(selectClientDialogClientAppointmentLostReasonOptions))
    this.isCancelling$ = this.store.pipe(select(selectClientDialogClientAppointmentIsCancelling))
    this.isCancelledSuccessfully$ = this.store.pipe(select(selectClientDialogClientAppointmentIsCancelledSuccessfully))

    this.lostForm = this.formBuilder.group({
      id: new UntypedFormControl(this.id, [Validators.required]),
      lostReason: new UntypedFormControl('Cancelled', [Validators.required]),
      comments: new UntypedFormControl('', [Validators.required]),
    })

    this.isCancelledSuccessfully$.pipe(takeUntil(this.unsubscribe$)).subscribe(isCancelledSuccessfully => {
      if (isCancelledSuccessfully) {
        this.close()
      }
    })
    this.isCancelling$.pipe(takeUntil(this.unsubscribe$)).subscribe(isCancelling => {
      this.spinnerButtonOptions.active = isCancelling
      this.cdr.markForCheck()
    })
  }

  confirm() {
    this.lostForm.markAllAsTouched();
    if (!this.lostForm.valid) {
      return
    }
    if (this.spinnerButtonOptions.active) {
      return
    }
    let params: IUpdateLostStatusParams = {
      id: this.id,
      lostReason: this.lostForm.get('lostReason').value,
      comments: this.lostForm.get('comments').value,
    }
    this.store.dispatch(actionClientDialogAppointmentCancelRequseted({ params }))
  }

  cancel() {
    this.dialogRef.close(0);
  }

  public close() {
    this.dialogRef.close(0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.store.dispatch(actionClientDialogAppointmentCancelStateReset())
  }

}
