import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { clientDialogApplicationRentalApplicationActions } from '../../client-dialog-rental-application.action';
import { USER_LANGUAGES } from 'app/shared/models/user-language.model';
import { takeUntil } from 'rxjs/operators';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { selectClientDialogIsRentalApplicationSending, selectClientDialogRentalApplicationAddNewErrorMsg } from '../../client-dialog-rental-application.selector';
import { RentalApplicationSetting } from 'app/features/client-dialog/client-dialog-model';
import { EMPLOYEMENT_STATUS_OPTIONS } from 'app/shared/models/employment.model';
import { selectClientLeadInfo } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { LeadInfo } from 'app/shared/models/lead-info-models';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-add-new',
  templateUrl: './client-dialog-rental-application-add-new.component.html',
  styleUrls: ['./client-dialog-rental-application-add-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationAddNewComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    private cdr: ChangeDetectorRef,
    @Inject('dialogData') private dialogData: any,
    public fb: UntypedFormBuilder) {
    this.rentalRequestId = dialogData.rentalRequestId;
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  leadInfo: LeadInfo;

  addApplicationFormGroup: UntypedFormGroup;
  rentalRequestId: number;

  countryCode: string;

  spinnerCreateButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm',
    flat: true, spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
  };

  USER_LANGUAGES = USER_LANGUAGES;
  employmentStatusOptions = EMPLOYEMENT_STATUS_OPTIONS;
  errorMsg$: Observable<string>;

  ngOnInit(): void {

    this.store.select(selectClientLeadInfo).pipe(takeUntil(this.unsubscribe$)).subscribe(leadInfo => {
      this.leadInfo = leadInfo;
    });

    this.addApplicationFormGroup = this.fb.group({
      applicantFirstName: ['', Validators.required],
      applicantLastName: ['', Validators.required],
      applicantEmail: ['', [Validators.required, Validators.email]],
      applicantPhoneNumber: ['', Validators.required],
      isDepositPayer: [false, Validators.required],
      isManualDocument: [false, Validators.required],
      applicantLanguage: ['en', Validators.required],
      applicantType: ['Tenant', Validators.required],
      applicantEmploymentStatus: ['Employed', Validators.required],
    });

    this.store.select(selectClientDialogIsRentalApplicationSending).pipe(takeUntil(this.unsubscribe$)).subscribe(isSending => {
      this.spinnerCreateButtonOptions.active = isSending;
      this.cdr.markForCheck();
    });
    this.errorMsg$ = this.store.select(selectClientDialogRentalApplicationAddNewErrorMsg);
  }

  onUseLeadInfo() {
    this.addApplicationFormGroup.get('applicantFirstName').setValue(this.leadInfo?.firstName);
    this.addApplicationFormGroup.get('applicantLastName').setValue(this.leadInfo?.lastName);
    this.addApplicationFormGroup.get('applicantEmail').setValue(this.leadInfo?.emails[0]);
    this.addApplicationFormGroup.get('applicantPhoneNumber').setValue(this.leadInfo?.phoneNumbers[0]);
  }

  addNewApplication() {
    if (this.addApplicationFormGroup.valid && !this.spinnerCreateButtonOptions.active) {
      var rentalApplicationSetting: RentalApplicationSetting = {
        rentalRequestId: this.rentalRequestId,
        applicantFirstName: this.addApplicationFormGroup.get('applicantFirstName').value,
        applicantLastName: this.addApplicationFormGroup.get('applicantLastName').value,
        applicantLanguage: this.addApplicationFormGroup.get('applicantLanguage').value,
        applicantType: this.addApplicationFormGroup.get('applicantType').value,
        applicantEmploymentStatus: this.addApplicationFormGroup.get('applicantEmploymentStatus').value,
        applicantEmail: this.addApplicationFormGroup.get('applicantEmail').value,
        applicantPhoneNumber: this.addApplicationFormGroup.get('applicantPhoneNumber').value,
        isDepositPayer: this.addApplicationFormGroup.get('isDepositPayer').value,
        isManualDocument: this.addApplicationFormGroup.get('isManualDocument').value,
      }

      this.store.dispatch(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewRequested({
        rentalApplicationSetting
      }));
    }
  }

  onManualSliderToggleChange() {
    if (this.addApplicationFormGroup.get('isManualDocument').value == true) {
      this.addApplicationFormGroup.get('isDepositPayer').setValue(false);
    }
  }

  onCheckSliderToggleChange() {
    this.addApplicationFormGroup.get('isManualDocument').setValue(false);
  }

  countryChanged(countrySelected) {
    this.countryCode = countrySelected.iso2;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalAddNewApplicantErrorClean());
  }
}

