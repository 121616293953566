import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { AppState } from "app/core/core.state";
import { TIME_ZONES } from "app/shared/models/timezone.model";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { BookingEvent, BuildingProperty, BookingLeadSource, RepresentativeThumbnail } from "../../client-dialog-inventory.model";
import { ClientDialogInventoryBookingService } from "../../client-dialog-booking-building.service";
import { clientDialogBookingEventCalendarActions, clientDialogBookingEventCalendarFailedActions } from "./client-dialog-booking-calendar.action";

@Injectable()
export class ClientDialogBookingEventCalendarEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private clientDialogInventoryBookingService: ClientDialogInventoryBookingService,
        private notificationService: NotificationService
    ) { }

    requestBookingEventCalendar = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarRequested),
            switchMap(action =>
                this.clientDialogInventoryBookingService.getEventCalendar(action.repUserId, action.calendarId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let representative = response.data.representativeThumbnail as RepresentativeThumbnail
                            let event = response.data.representativeCalendar as BookingEvent
                            let buildingId = response.data.buildingId as number
                            let buildingProperties = response.data.buildingProperties as BuildingProperty[]
                            return clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarLoaded({ representative, event, buildingId, buildingProperties })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarFailed()
                        }
                    }),
                    catchError(error => of(clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarFailed()))
                )
            )
        )
    );

    loadedBookingEventCalendar = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarLoaded),
            map(action => {
                let timeZone: string = action.event.timeZone ?? 'ET';
                return clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarChangeTimeZone({ timeZone: TIME_ZONES.filter(x => x.id == timeZone)[0] })
            })
        )
    )

    requestBookingEventCalendarAvaliablities = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarAvaliablitiesRequested),
            switchMap(action =>
                this.clientDialogInventoryBookingService.getEventCalendarAvailability(action.repUserId, action.calendarid, action.bookingDate, action.timeZone).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let timeSlots = response.data as string[]
                            return clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarAvaliablitiesLoaded({ timeSlots })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarAvaliablitiesFailed()
                        }
                    }),
                    catchError(error => of(clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarAvaliablitiesFailed()))
                ))
        )
    );

    requestBookingEventCalendarLeadSources = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarLeadSourcesRequested),
            switchMap(action =>
                this.clientDialogInventoryBookingService.getEventCalendarLeadSource().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let leadSources = response.data as BookingLeadSource[]
                            return clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarLeadSourcesLoaded({ leadSources })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarLeadSourcesFailed()
                        }
                    }),
                    catchError(error => of(clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarLeadSourcesFailed()))
                ))
        )
    );

    createBookingEventCalendarAppointment = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarCreateAppointment),
            switchMap((action) =>
                this.clientDialogInventoryBookingService.createAppointment(action.formType, action.form).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Appointment booked successfully!')

                            return clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarCreateAppointmentSuccess()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarCreateAppointmentFailed()
                        }
                    }
                    ),
                    catchError(error => {
                        this.notificationService.error('Appointment booked failed!')
                        return of(clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarCreateAppointmentFailed())
                    })
                )
            )
        )
    );

    updateBookingEventCalendarAppointment = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarUpdateAppointment),
            switchMap((action) =>
                this.clientDialogInventoryBookingService.updateAppointment(action.formType, action.form).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Appointment update successfully!')

                            return clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarUpdateAppointmentSuccess()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarUpdateAppointmentFailed()
                        }
                    }
                    ),
                    catchError(error => {
                        this.notificationService.error('Appointment update failed!')
                        return of(clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarUpdateAppointmentFailed())
                    })
                )
            )
        )
    );
}
