import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebApiResponse } from 'app/core/response/response.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';


@Injectable()
export class SupportService {

  constructor(
    private http: HttpClient,
  ) {

  }

  sendSupportForm(form: any){
    return this.http.post(`${environment.apiBaseUrl}support/createticket`,
    form
  )
  .pipe(map((response: IWebApiResponse) => {
      return response;
    })
  );
  }

}
