import { createAction, props } from "@ngrx/store";
import { TimeZone } from "app/shared/models/timezone.model";
import { BookingEvent, BookingTimeSlot, BuildingProperty, BookingLeadSource, RepresentativeThumbnail } from "../../client-dialog-inventory.model";

export const actionClientDialogBookingEventCalendarRequested = createAction(
  '[Client Dialog Booking] Booking Event Calendar Requested',
  props<{ repUserId: number, calendarId: number }>()
);

export const actionClientDialogBookingEventCalendarLoaded = createAction(
  '[Client Dialog Booking] Booking Event Calendar Loaded',
  props<{ representative: RepresentativeThumbnail, event: BookingEvent, buildingId: number, buildingProperties: BuildingProperty[] }>()
);

export const actionClientDialogBookingEventCalendarAvaliablitiesRequested = createAction(
  '[Client Dialog Booking] Booking Event Calendar Avaliabilities Requested',
  props<{ repUserId: number, calendarid: number, bookingDate: string, timeZone: string }>()
);

export const actionClientDialogBookingEventCalendarAvaliablitiesLoaded = createAction(
  '[Client Dialog Booking] Booking Event Calendar Avaliablities Loaded',
  props<{ timeSlots: string[] }>()
);

export const actionClientDialogBookingEventCalendarLeadSourcesRequested = createAction(
  '[Client Dialog Booking] Booking Event Calendar Lead Sources Requested',
);

export const actionClientDialogBookingEventCalendarLeadSourcesLoaded = createAction(
  '[Client Dialog Booking] Booking Event Calendar Lead Sources Loaded',
  props<{ leadSources: BookingLeadSource[] }>()
);

export const actionClientDialogBookingEventCalendarLeadSourcesFailed = createAction(
  '[Client Dialog Booking] Booking Event Calendar Lead Sources Failed',
);

export const actionClientDialogBookingEventOverwriteCalendarAvaliablitiesRequested = createAction(
  '[Client Dialog Booking] Booking Event Overwrite Calendar Avaliabilities Requested'
);

export const actionClientDialogBookingEventOverwriteCalendarAvaliablitiesLoaded = createAction(
  '[Client Dialog Booking] Booking Event Overwrite Calendar Avaliablities Loaded',
  props<{ timeSlots: string[] }>()
);

export const actionClientDialogBookingEventCalendarFailed = createAction(
  '[Client Dialog Booking] Booking Event Calendar Failed',
);

export const actionClientDialogBookingEventCalendarAvaliablitiesFailed = createAction(
  '[Client Dialog Booking] Booking Event Calendar Avaliablities Failed',
);

export const actionClientDialogBookingEventCalendarReset = createAction(
  '[Client Dialog Booking] Booking Event Calendar Reset',
);

export const actionClientDialogBookingEventCalendarDateChange = createAction(
  '[Client Dialog Booking] Booking Event Calendar Date Change',
  props<{ date: string }>()
);

export const actionClientDialogBookingEventCalendarTimeChange = createAction(
  '[Client Dialog Booking] Booking Event Calendar Time Change',
  props<{ time: BookingTimeSlot }>()
);

export const actionClientDialogBookingEventCalendarToggleScheduleOverwrite = createAction(
  '[Client Dialog Booking] Booking Event Calendar Toggle Schedule Overwrite',
  props<{ isScheduleOverwrite: boolean }>()
)

export const actionClientDialogBookingEventCalendarToggleIsFormActive = createAction(
  '[Client Dialog Booking] Booking Event Calendar Toggle Is Form Active',
  props<{ isFormActive: boolean }>()
)

export const actionClientDialogBookingEventCalendarTimeIdToConfirmChange = createAction(
  '[Client Dialog Booking] Booking Event Calendar Time Slot Id To Confirm Change',
  props<{ id: string }>()
);

export const actionClientDialogBookingEventCalendarCreateAppointment = createAction(
  '[Client Dialog Booking] Booking Event Calendar Create Appointement',
  props<{ formType: string, form: any }>()
);

export const actionClientDialogBookingEventCalendarCreateAppointmentSuccess = createAction(
  '[Client Dialog Booking] Booking Event Calendar Create Appointement Success',
);

export const actionClientDialogBookingEventCalendarCreateAppointmentFailed = createAction(
  '[Client Dialog Booking] Booking Event Calendar Create Appointement Failed',
);

export const actionClientDialogBookingEventCalendarUpdateAppointment = createAction(
  '[Client Dialog Booking] Booking Event Calendar Update Appointement',
  props<{ formType: string, form: any }>()
);

export const actionClientDialogBookingEventCalendarUpdateAppointmentSuccess = createAction(
  '[Client Dialog Booking] Booking Event Calendar Update Appointement Success',
);

export const actionClientDialogBookingEventCalendarUpdateAppointmentFailed = createAction(
  '[Client Dialog Booking] Booking Event Calendar Update Appointement Failed',
);


export const actionClientDialogBookingEventCalendarBookingFormBuildingAddressChange = createAction(
  '[Client Dialog Booking] Booking Event Calendar Booking Form Building Address Change',
  props<{ buildingAddress: string }>()
);

export const actionClientDialogBookingEventCalendarChangeTimeZone = createAction(
  '[Client Dialog Booking] Booking Event Calendar Time Zone Changed',
  props<{ timeZone: TimeZone }>()
);

export const actionClientDialogBookingEventCalendarChangeMinDate = createAction(
  '[Client Dialog Booking] Booking Event Calendar Min Date Changed',
  props<{ minDate: Date }>()
);

export const clientDialogBookingEventCalendarActions = {
  actionClientDialogBookingEventCalendarRequested,
  actionClientDialogBookingEventCalendarLoaded,
  actionClientDialogBookingEventCalendarAvaliablitiesRequested,
  actionClientDialogBookingEventCalendarAvaliablitiesLoaded,
  actionClientDialogBookingEventOverwriteCalendarAvaliablitiesRequested,
  actionClientDialogBookingEventOverwriteCalendarAvaliablitiesLoaded,
  actionClientDialogBookingEventCalendarToggleScheduleOverwrite,
  actionClientDialogBookingEventCalendarDateChange,
  actionClientDialogBookingEventCalendarTimeChange,
  actionClientDialogBookingEventCalendarTimeIdToConfirmChange,
  actionClientDialogBookingEventCalendarCreateAppointment,
  actionClientDialogBookingEventCalendarCreateAppointmentSuccess,
  actionClientDialogBookingEventCalendarUpdateAppointment,
  actionClientDialogBookingEventCalendarUpdateAppointmentSuccess,
  actionClientDialogBookingEventCalendarChangeTimeZone,
  actionClientDialogBookingEventCalendarReset,
  actionClientDialogBookingEventCalendarChangeMinDate,
  actionClientDialogBookingEventCalendarLeadSourcesRequested,
  actionClientDialogBookingEventCalendarLeadSourcesLoaded,
  actionClientDialogBookingEventCalendarBookingFormBuildingAddressChange,
  actionClientDialogBookingEventCalendarToggleIsFormActive
}

export const clientDialogBookingEventCalendarFailedActions = {
  actionClientDialogBookingEventCalendarFailed,
  actionClientDialogBookingEventCalendarAvaliablitiesFailed,
  actionClientDialogBookingEventCalendarCreateAppointmentFailed,
  actionClientDialogBookingEventCalendarUpdateAppointmentFailed,
  actionClientDialogBookingEventCalendarLeadSourcesFailed
}

