import { createReducer, on } from "@ngrx/store";
import { clientDialogActions } from "./client-dialog.actions";
import { ClientDialogBaseState } from "./client-dialog.state";

const initialState: ClientDialogBaseState = {
    activeTab: 1
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogActions.actionClientDialogTopNavigationActiveTabChange,
        (state, { tab }) => ({
            ...state,
            activeTab: tab
        })
    ),
    on(
        clientDialogActions.actionClientDialogBaseStateReset,
        () => initialState
    ),
)

export const clientDialogBaseReducer = (state: ClientDialogBaseState | undefined, action) => reducer(state, action)