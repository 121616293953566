import { createSelector } from '@ngrx/store';
import { TenantDialogState, selectTenantDialog, TenantDialogGeneralInfoState } from '../../tenant-dialog.state';

export const selectTenantDialogGeneralInfoState = createSelector(
  selectTenantDialog,
  (state: TenantDialogState) => state.tenantDialogGeneralInfo
);

export const selectTenantDialogTenantId = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.tenantId
);

export const selectTenantDialogTenantInfoIsLoaded = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.isTenantInfoLoaded
);

export const selectTenantDialogTenantInfoIsBaseInfoEditMode = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.isBaseInfoEditMode
);

export const selectTenantDialogTenantInfoIsBaseInfoSubmitting = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.isBaseInfoSubmitting
);

export const selectTenantDialogTenantInfo = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.tenantInfo
);

export const selectTenantDialogTenantInfoPhoneNumbers = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.tenantInfo.phoneNumbers
);

export const selectTenantDialogTenantInfoEmails = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => state.tenantInfo.emails
);

export const selectTenantDialogTenantCaLLFromPhoneNumbers = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => {
    return state.callFromPhoneNumbers
  }
);

export const selectTenantDialogTenantPhoneNumberBlockIsUpdating = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => {
    return state.tenantDialogPhoneNumberBlock.isUpdating
  }
);

export const selectTenantDialogTenantPhoneNumberBlockIsUpdatedSuccessfully = createSelector(
  selectTenantDialogGeneralInfoState,
  (state: TenantDialogGeneralInfoState) => {
    return state.tenantDialogPhoneNumberBlock.isUpdatedSuccessfully
  }
);