import { createAction, props } from "@ngrx/store";
import { LeasingRep, RentalItem, RentalRequest, InitRentalRequest } from "../../client-dialog-model";

export const actionClientDialogApplicationRentalRequestRequested = createAction(
  '[Client Dialog Appointment] Client Application Rental Request Requested',
  props<{ leadId: number }>()
);

export const actionClientDialogApplicationRentalRequestSuccess = createAction(
  '[Client Dialog Appointment] Client Application Rental Request Success',
  props<{ rentalRequest: RentalRequest }>()
);

export const actionClientDialogApplicationRentalRequestFailed = createAction(
  '[Client Dialog Appointment] Client Application Rental Request Failed',
);

export const actionClientDialogApplicationInitRentalRequestRequested = createAction(
  '[Client Dialog Appointment] Client Application Init Rental Request Requested',
  props<{ initRentalRequest: InitRentalRequest }>()
);

export const actionClientDialogApplicationInitRentalRequestSuccess = createAction(
  '[Client Dialog Appointment] Client Application Init Rental Request Success'
);

export const actionClientDialogApplicationInitRentalRequestFailed = createAction(
  '[Client Dialog Appointment] Client Application Init Rental Request Failed',
);

export const actionClientDialogApplicationRentalItemsRequested = createAction(
  '[Client Dialog Appointment] Client Application Rental Items Requested',
  props<{ unitId: number }>()
);

export const actionClientDialogApplicationRentalItemsSuccess = createAction(
  '[Client Dialog Appointment] Client Application Rental Items Success',
  props<{ rentalItems: RentalItem[] }>()
);

export const actionClientDialogApplicationRentalItemsFailed = createAction(
  '[Client Dialog Appointment] Client Application Rental Items Failed',
);

export const actionClientDialogApplicationLeasingRepRequested = createAction(
  '[Client Dialog Appointment] Client Application Leasing Rep Requested',
  props<{ buildingId: number }>()
);

export const actionClientDialogApplicationLeasingRepSuccess = createAction(
  '[Client Dialog Appointment] Client Application Leasing Rep Success',
  props<{ leasingReps: LeasingRep[] }>()
);

export const actionClientDialogApplicationLeasingRepFailed = createAction(
  '[Client Dialog Appointment] Client Application Leasing Rep Failed',
);

export const actionClientDialogApplicationCancelRequested = createAction(
  '[Client Dialog Appointment] Client Application Rental Request Cancel Requested',
  props<{ rentalRequestId: number, reasonType: string, reason: string }>()
);

export const actionClientDialogApplicationCancelSuccess = createAction(
  '[Client Dialog Appointment] Client Application Rental Request Cancel Success',
);

export const actionClientDialogApplicationCancelFailed = createAction(
  '[Client Dialog Appointment] Client Application Rental Request Cancel Failed',
);

export const actionClientDialogApplicationRentalRequestReset = createAction(
  '[Client Dialog Appointment]  Client Application Rental Request Reset'
);

export const clientDialogRentalRequestActions = {
  actionClientDialogApplicationRentalRequestRequested,
  actionClientDialogApplicationRentalRequestSuccess,
  actionClientDialogApplicationInitRentalRequestRequested,
  actionClientDialogApplicationInitRentalRequestSuccess,
  actionClientDialogApplicationRentalItemsRequested,
  actionClientDialogApplicationRentalItemsSuccess,
  actionClientDialogApplicationLeasingRepRequested,
  actionClientDialogApplicationLeasingRepSuccess,
  actionClientDialogApplicationCancelRequested,
  actionClientDialogApplicationCancelSuccess,
  actionClientDialogApplicationRentalRequestReset
}

export const clientDialogRentalRequestFailedActions = {
  actionClientDialogApplicationRentalRequestFailed,
  actionClientDialogApplicationInitRentalRequestFailed,
  actionClientDialogApplicationRentalItemsFailed,
  actionClientDialogApplicationLeasingRepFailed,
  actionClientDialogApplicationCancelFailed,
}

