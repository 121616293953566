import * as uuid from 'uuid';

export interface IRouterLink {
  id: string | number;
  link: string;
  label?: string;
  icon?: string;
  auth?: string;
  roles?: string[]
}

export class RouterLink implements IRouterLink {
  id: string | number;
  link: string;
  label?: string;
  icon?: string;
  auth?: string;

  constructor(link: string, label?: string, icon?: string, auth?: string) {
    this.id = uuid.v4();
    this.link = link;
    this.label = label;
    this.icon = icon;
    this.auth = auth;
  }
}
