import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { LEAD_PREFERENCE_BATH_OPTIONS, LEAD_PREFERENCE_BED_OPTIONS, LEAD_PREFERENCE_PARKING_OPTIONS, LEAD_PREFERENCE_PET_OPTIONS, LeadPreference } from 'app/shared/models/lead-preference.model';

@Component({
  selector: 'dq-timeline-appointment',
  templateUrl: './timeline-appointment.component.html',
  styleUrls: ['./timeline-appointment.component.scss', './timeline-appointment.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimelineAppointmentComponent implements OnInit {

  @Input() event: ITimelineElement

  isMobile$: Observable<boolean>;

  repName: string;
  startTime: string;
  timeZone: string;
  appointmentStatusType: string;
  winPrice: number;
  lostReason: string;
  lostComments: string;
  closingDate: string;
  bedPreference: string;
  bathPreference: string;
  parkingPreference: string;
  petPreference: string;
  buildingAddress: string;
  budget: string;
  moveInDate: string;
  note: string;
  unitIdentifier: string;

  constructor(public store: Store<State>) { }

  ngOnInit(): void {

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));

    this.repName = this.event.timelineAppointment?.repName;
    this.startTime = this.event.timelineAppointment?.startTime;
    this.timeZone = this.event.timelineAppointment?.timeZone;
    this.appointmentStatusType = this.event.timelineAppointment?.appointmentStatusType;
    this.winPrice = this.event.timelineAppointment?.winPrice;
    this.lostReason = this.event.timelineAppointment?.lostReason;
    this.lostComments = this.event.timelineAppointment?.lostComments;
    this.closingDate = this.event.timelineAppointment?.closingDate;
    this.buildingAddress = this.event.timelineAppointment?.buildingAddress;
    this.budget = this.event.timelineAppointment?.budget;
    this.moveInDate = this.event.timelineAppointment?.moveInDate;
    this.note = this.event.timelineAppointment?.note;
    this.unitIdentifier = this.event.timelineAppointment?.unitIdentifier;
    this.bedPreference = this.formatOptionsPreference(LEAD_PREFERENCE_BED_OPTIONS, this.event.timelineAppointment?.bedPreference);
    this.bathPreference = this.formatOptionsPreference(LEAD_PREFERENCE_BATH_OPTIONS, this.event.timelineAppointment?.bathPreference);
    this.parkingPreference = LEAD_PREFERENCE_PARKING_OPTIONS.find(x => x.value == this.event.timelineAppointment?.parkingPreference)?.label;
    this.petPreference = this.formatOptionsPreference(LEAD_PREFERENCE_PET_OPTIONS, this.event.timelineAppointment?.petPreference);
  }

  formatOptionsPreference(leadPreferenceOptions: LeadPreference[], preferences: string[]) {
    let preference: string = ''
    preferences?.forEach((p, i) => {
      preference += leadPreferenceOptions.find(x => x.value == p)?.label
      if (i < preferences.length - 1) {
        preference += ', '
      }
    })
    return preference
  }
}
