import { createSelector } from '@ngrx/store';

import { selectCallManagerState } from '../core.state';
import { CallManagerState } from './call-manager.model';

export const selectRegisteredDeviceAuth = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.deviceAuth
);

export const selectRegisteredDeviceIdentity = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.deviceAuth.identity
);

export const selectRegisteredDeviceToken = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.deviceAuth.token
);

export const selectRegisteredDeviceTokenExpires = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.deviceAuth.expires
);

export const selectRegisteredDeviceLogs = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.logs
);

export const selectCallManagerCallFromPhoneNumbers = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.callFromPhoneNumbers
);

export const selectCallManagerTransferQueueOptions = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.callTransferQueues
);

export const selectCallManagerIsDeviceRegistered = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.isDeviceRegistered
)

export const selectCallManagerIsCallPanelActive = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.isCallPanelActive
)

export const selectCallManagerIsIncomingCallConnected = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.isIncomingCallConnected
)

export const selectCallManagerIsOutgoingCallConnected = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.isOutgoingCallConnected
)

export const selectCallManagerIsIncomingCallPanelActive = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.isIncomingCallPanelActive
)

export const selectCallManagerIsOutgoingCallPanelActive = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.isOutgoingCallPanelActive
)

export const selectCallManagerCallFromPhoneNumber = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.callFromPhoneNumber
)

export const selectCallManagerCallToPhoneNumber = createSelector(
  selectCallManagerState,
  (state: CallManagerState) => state.callToPhoneNumber
)
