import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { selectIsAuthenticated, selectIsNotExpired } from './auth.selectors';
import { AppState } from '../core.state';

@Injectable()
export class SignInGuardService  {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return combineLatest(
      [
        this.store.select(selectIsAuthenticated),
        this.store.select(selectIsNotExpired)
      ],
      (isAuth, isNotExpired) => {
        return !isAuth || !isNotExpired;
      }
    );
  }
}
