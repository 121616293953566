<div class="notification-system-component">
    <div class="container">
        <div class="row">
            <div class="col-2">
                <mat-icon class="notification-icon">error</mat-icon>
            </div>
            <div class="col-10">
                <div class="title">
                    {{notification.title}}
                    <mat-icon class="delete-icon" (click)="$event.stopPropagation();deleteNotification()">delete</mat-icon>
                </div>
                <div class="notification-date">
                    {{notification.date | date:'short'}}
                </div>
                <div class="content">
                    {{notification.content}}
                </div>
            </div>
        </div>
    </div>
</div>