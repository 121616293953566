import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebApiResponse } from 'app/core/response/response.model';
import { IUpdateLostStatusParams } from 'app/shared/models/appointment.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientDialogAppointmentService {

  constructor(
    private http: HttpClient
  ) {

  }

  getClientAppointmentsByLeadId(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-booking/getClientAppointments?leadId=${leadId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getAppointmentById(id: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-booking/GetAppointmentInfoById?appointmentId=${id}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  cancelAppointmentById(params: IUpdateLostStatusParams) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-booking/cancelAppointmentById`, params)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }
}
