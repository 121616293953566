declare let tinymce: any;

export const LEAD_PHONE_MERGE_TAGS = [
    {
      title: 'Lead Info',
      menu: [
        {
          value: 'Lead.FirstName',
          title: 'Lead First Name'
        },
        {
          value: 'Lead.LastName',
          title: 'Lead Last Name'
        },
        {
          value: 'Lead.PhoneNumber',
          title: 'Lead Phone Number'
        }
      ]
    },
    {
      title: 'User Info',
      menu: [
        {
          value: 'User.FirstName',
          title: 'User First Name'
        },
        {
          value: 'User.LastName',
          title: 'User Last Name'
        },
        {
          value: 'User.PhoneNumber',
          title: 'User Phone Number'
        }
      ]
    }
  ]

  export const LEAD_EMAIL_MERGE_TAGS = [
    {
      title: 'Lead Info',
      menu: [
        {
          value: 'Lead.FirstName',
          title: 'Lead First Name'
        },
        {
          value: 'Lead.LastName',
          title: 'Lead Last Name'
        },
        {
          value: 'Lead.Email',
          title: 'Lead Email'
        }
      ]
    },
    {
      title: 'User Info',
      menu: [
        {
          value: 'User.FirstName',
          title: 'User First Name'
        },
        {
          value: 'User.LastName',
          title: 'User Last Name'
        },
        {
          value: 'User.Email',
          title: 'User Email'
        }
      ]
    }
  ]

  export const TENANT_EMAIL_MERGE_TAGS = [
    {
      title: 'Tenant Info',
      menu: [
        {
          value: 'Tenant.FirstName',
          title: 'Tenant First Name'
        },
        {
          value: 'Tenant.LastName',
          title: 'Tenant Last Name'
        },
        {
          value: 'Tenant.Email',
          title: 'Tenant Email'
        }
      ]
    },
    {
      title: 'User Info',
      menu: [
        {
          value: 'User.FirstName',
          title: 'User First Name'
        },
        {
          value: 'User.LastName',
          title: 'User Last Name'
        },
        {
          value: 'User.Email',
          title: 'User Email'
        }
      ]
    }
  ]

  export const LEAD_BUILDING_APP_MERGE_TAGS = [
    {
      title: 'Building Info',
      menu: [
        {
          value: 'Building.Name',
          title: 'Building Name'
        }
      ]
    },
    {
      title: 'Lead Info',
      menu: [
        {
          value: 'Lead.FirstName',
          title: 'Lead First Name'
        },
        {
          value: 'Lead.LastName',
          title: 'Lead Last Name'
        },
        {
          value: 'Lead.Email',
          title: 'Lead Email'
        },
        {
          value: 'Lead.PhoneNumber',
          title: 'Lead Phone Number'
        }
      ]
    },
    {
      title: 'Appointment Info',
      menu: [
        {
          value: 'Appointment.Date',
          title: 'Appointment Date'
        },
        {
          value: 'Appointment.Time',
          title: 'Appointment Time'
        },
        {
          value: 'Appointment.Location',
          title: 'Appointment Location'
        }
      ]
    },
    {
      title: 'Booking Agent Info',
      menu: [
        {
          value: 'Booking.Agent.FirstName',
          title: 'Booking Agent FirstName'
        },
        {
          value: 'Booking.Agent.LastName',
          title: 'Booking Agent LastName'
        },
        {
          value: 'Booking.Agent.Email',
          title: 'Booking Agent Email'
        },
        {
          value: 'Booking.Agent.PhoneNumber',
          title: 'Booking Agent Phone Number'
        },
      ]
    },
    {
      title: 'Representative Info',
      menu: [
        {
          value: 'Rep.FirstName',
          title: 'Representative FirstName'
        },
        {
          value: 'Rep.LastName',
          title: 'Representative Agent LastName'
        },
        {
          value: 'Rep.Email',
          title: 'Representative Agent Email'
        },
        {
          value: 'Rep.PhoneNumber',
          title: 'Representative Agent Phone Number'
        },
      ]
    }
  ]

  export const LEAD_BUILDING_APP_REMINDER_MERGE_TAGS = [
    {
      title: 'Building Info',
      menu: [
        {
          value: 'Building.Name',
          title: 'Building Name'
        },
        {
          value: 'Building.PhoneNumber',
          title: 'Building Phone Number'
        }
      ]
    },
    {
      title: 'Lead Info',
      menu: [
        {
          value: 'Lead.FirstName',
          title: 'Lead First Name'
        },
        {
          value: 'Lead.LastName',
          title: 'Lead Last Name'
        },
        {
          value: 'Lead.Email',
          title: 'Lead Email'
        },
        {
          value: 'Lead.PhoneNumber',
          title: 'Lead Phone Number'
        }
      ]
    },
    {
      title: 'Appointment Info',
      menu: [
        {
          value: 'Appointment.Date',
          title: 'Appointment Date'
        },
        {
          value: 'Appointment.Time',
          title: 'Appointment Time'
        },
        {
          value: 'Appointment.Location',
          title: 'Appointment Location'
        }
      ]
    },
    {
      title: 'Booking Agent Info',
      menu: [
        {
          value: 'Booking.Agent.FirstName',
          title: 'Booking Agent FirstName'
        },
        {
          value: 'Booking.Agent.LastName',
          title: 'Booking Agent LastName'
        },
        {
          value: 'Booking.Agent.Email',
          title: 'Booking Agent Email'
        },
        {
          value: 'Booking.Agent.PhoneNumber',
          title: 'Booking Agent Phone Number'
        },
      ]
    },
    {
      title: 'Representative Info',
      menu: [
        {
          value: 'Rep.FirstName',
          title: 'Representative FirstName'
        },
        {
          value: 'Rep.LastName',
          title: 'Representative LastName'
        },
        {
          value: 'Rep.Email',
          title: 'Representative Email'
        },
        {
          value: 'Rep.PhoneNumber',
          title: 'Representative Phone Number'
        },
      ]
    }
  ]

  export const LEAD_INTEAGTRATION_AUTO_MERGE_TAGS = [
    {
      title: 'Building Info',
      menu: [
        {
          value: 'Building.Name',
          title: 'Building Name'
        }
      ]
    },
    {
      title: 'Lead Info',
      menu: [
        {
          value: 'Lead.FirstName',
          title: 'Lead First Name'
        },
        {
          value: 'Lead.LastName',
          title: 'Lead Last Name'
        },
        {
          value: 'Lead.Email',
          title: 'Lead Email'
        },
        {
          value: 'Lead.PhoneNumber',
          title: 'Lead Phone Number'
        }
      ]
    },
    {
      title: 'Inside Sales Agent Info',
      menu: [
        {
          value: 'Agent.FirstName',
          title: 'Agent FirstName'
        },
        {
          value: 'Agent.LastName',
          title: 'Agent LastName'
        },
        {
          value: 'Agent.Email',
          title: 'Agent Email'
        }
      ],
    },
    {
      title: 'Inquiry Source Info',
      menu: [
        {
          value: 'Inquiry.Source.Name',
          title: 'Inquiry Source Name'
        }
      ],
    },
    {
      title: 'Inventory',
      menu: [
        {
          value: 'Inventory.List',
          title: 'Inventory List'
        }
      ],
    }
  ]

  export const LEAD_INTEAGTRATION_DEFAULT_AUTO_MERGE_TAGS = [
    {
      title: 'Lead Info',
      menu: [
        {
          value: 'Lead.FirstName',
          title: 'Lead First Name'
        },
        {
          value: 'Lead.LastName',
          title: 'Lead Last Name'
        },
        {
          value: 'Lead.Email',
          title: 'Lead Email'
        },
        {
          value: 'Lead.PhoneNumber',
          title: 'Lead Phone Number'
        }
      ]
    },
    {
      title: 'Inside Sales Agent Info',
      menu: [
        {
          value: 'Agent.FirstName',
          title: 'Agent FirstName'
        },
        {
          value: 'Agent.LastName',
          title: 'Agent LastName'
        },
        {
          value: 'Agent.Email',
          title: 'Agent Email'
        }
      ],
    },
    {
      title: 'Inquiry Source Info',
      menu: [
        {
          value: 'Inquiry.Source.Name',
          title: 'Inquiry Source Name'
        }
      ],
    }
  ]

  export function filePickerCallback(cb, value, meta) {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');  
    input.onchange = function() {
      var file = input.files[0];
      var reader = new FileReader();
      reader.onload = function () {
        // Note: Now we need to register the blob in TinyMCEs image blob
        // registry. In the next release this part hopefully won't be
        // necessary, as we are looking to handle it internally.
        var id = 'blobid' + (new Date()).getTime();
        var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
        var base64 = (<string>reader.result).split(',')[1];
        var blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        // call the callback and populate the Title field with the file name
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  }