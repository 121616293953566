
import parsePhoneNumberFromString, {
    CountryCode, getCountries, isValidNumber, isValidPhoneNumber, NumberFormat
} from 'libphonenumber-js';

import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import {  MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[dqPhoneNumber]',
  providers: [MatTooltip]
})
export default class PhoneNumberDirective implements OnInit, AfterViewInit {
  defaultCountryCode: CountryCode | CountryCode[] = ['CA', 'US']
  validNumber: string
  numberValidity: 'unknown' | 'valid' | 'invalid' = 'invalid'

  @Input("dqPhoneNumber") phoneNumber: string
  @Input('format') format: NumberFormat = 'INTERNATIONAL'

  tooltipText: string

  constructor(private el: ElementRef, private tooltip: MatTooltip) {
  }

  ngAfterViewInit(): void {
    if (!this.phoneNumber)
      this.applyDirective()
  }

  ngOnInit(): void {
    if (this.phoneNumber)
      this.applyDirective()
  }

  applyDirective() {

    this.phoneNumber = this.phoneNumber || this.el.nativeElement.innerText
    if (!this.phoneNumber)
      return
    this.evaluateNumber(this.phoneNumber)
    this.el.nativeElement.textContent = this.validNumber || this.phoneNumber
    switch (this.numberValidity) {
      case 'invalid':
        this.tooltipText = 'Number could not be validated'
        this.el.nativeElement.style.borderBottom = 'dotted 2px #ff444488'
        break
      case 'unknown':
        this.tooltipText = 'Number could not be evaluated toroughtly'
        this.el.nativeElement.style.borderBottom = 'dotted 2px #ff884488'
        break
      default: this.tooltipText = null
    }
  }

  evaluateNumber(number: string) {
    this.numberValidity = 'invalid'
    if (!number)
      return

    if (!isValidPhoneNumber(number)) {
      const withoutCanadianCountryCode = `+${number.substring(2)}`
      if (/\+\d+/gm.test(number) && isValidNumber(withoutCanadianCountryCode)) {
        this.numberValidity = 'unknown'
        this.validNumber = withoutCanadianCountryCode
      }
      return
    }
    try {
      for (const countryCode of [this.defaultCountryCode, ...getCountries()].flat(Infinity) as CountryCode[]) {
        const phoneNumber = parsePhoneNumberFromString(number, countryCode)
        if (phoneNumber.isValid()) {
          this.numberValidity = 'valid'
          this.validNumber = phoneNumber.format(this.format)
          return
        }
      }
    } finally {
      if (this.numberValidity != 'valid')
        this.numberValidity = 'unknown'
    }
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.message = this.tooltipText
    this.tooltip.show()
  }
  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide()
  }
}
