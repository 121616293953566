import { createAction, props } from "@ngrx/store"
import { CommunicationTemplate } from "app/features/tenant-unified-inbox/tenant-unified-inbox.model";
import { EmailSendType } from "app/shared/models/user-communication.model";

export const actionTenantDialogSendEmailRequest = createAction(
    '[Tenant Dialog Email] Send Email Request',
    props<{ tenantId: number, externalIdentifier: string, emailType: EmailSendType, text: string, sendFromUserId: string, sendTo: string[], sendToCc: string[], sendToBcc: string[], subject: string, files: string[] }>()
);

export const actionTenantDialogSendEmailSuccessed = createAction(
    '[Tenant Dialog Email] Send Email Successed',
);

export const actionTenantDialogSendEmailFailed = createAction(
    '[Tenant Dialog Email] Send Email Failed',
);

export const actionTenantDialogEmailEditorIsCcAndBccActiveUpdate = createAction(
    '[Tenant Dialog Email] Email Editor Is Cc And Bcc Active Update',
    props<{ isActive: boolean }>()
);

export const actionTenantDialogSendEmailEditorReset = createAction(
    '[Tenant Dialog Email] Email Editor Reset',
);

export const actionTenantDialogSendEmailReplyEditorIsForwardingUpdate = createAction(
    '[Tenant Dialog Email] Email Editor Reply Is Forwarding Update',
    props<{ isForwarding: boolean }>()
);

export const actionTenantDialogSendEmailReplyEditorReplyAllToUpdate = createAction(
    '[Tenant Dialog Email] Email Editor Reply All To Update',
    props<{ replyAllTo: string[] }>()
);

export const actionTenantDialogSendEmailReplyEditorReset = createAction(
    '[Tenant Dialog Email] Email Editor Reply Reset',
);

export const actionTenantDialogSendEmailEditorUpdate = createAction(
    '[Tenant Dialog Email] Email Editor Update',
    props<{ email: string, sendFromUserId: number, sendToEmails: string[], sendToCcs: string[], sendToBccs: string[], subject: string, attachements: string[] }>()
);

export const actionTenantDialogSendEmailReplyEditorUpdate = createAction(
    '[Tenant Dialog Email] Email Reply Editor Update',
    props<{ email: string, sendToCcs: string[], sendToBccs: string[], subject: string }>()
);

export const actionTenantDialogSendEmailAndCollaborateRequest = createAction(
    '[Tenant Dialog Email] Send Email And Collaborate Request',
    props<{ tenantId: number, externalIdentifier: string, emailType: EmailSendType, text: string, sendFromUserId: string, sendTo: string[], sendToCc: string[], sendToBcc: string[], subject: string, files: string[] }>()
);

export const actionTenantDialogSendEmailAndCollaborateSuccessed = createAction(
    '[Tenant Dialog Email] Send Email And Collaborate Successed',
);

export const actionTenantDialogSendEmailAndCollaborateFailed = createAction(
    '[Tenant Dialog Email] Send Email And Collaborate Failed',
);

export const actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate = createAction(
    '[Tenant Dialog Email] Sending Mail Type And External Identifier Update',
    props<{ sendType: EmailSendType, externalIdentifier: string }>()
)

export const actionTenantDialogEmailTemplatesRequest = createAction(
    '[Tenant Dialog Email] Templates Request',
)

export const actionTenantDialogEmailTemplatesLoaded = createAction(
    '[Tenant Dialog Email] Templates Loaded',
    props<{ templates: CommunicationTemplate[] }>()
)

export const actionTenantDialogEmailTemplatesFalied = createAction(
    '[Tenant Dialog Email] Templates Failed',
)

export const actionTenantDialogEmailTemplateSaveAndUpdate = createAction(
    '[Tenant Dialog Email] Eamil Template Save And Update',
    props<{ friendlyName: string, displayName: string, content: string, isAccountShared: boolean, id?: number }>()
);

export const actionTenantDialogEmailTemplateSaveAndUpdateSucceeded = createAction(
    '[Tenant Dialog Email] Eamil Template Save And Update Succeeded'
);

export const actionTenantDialogEmailTemplateSaveAndUpdateFailed = createAction(
    '[Tenant Dialog Email] Eamil Template Save And Update Failed'
);

export const actionTenantDialogEmailTemplateDeleteRequest = createAction(
    '[Tenant Dialog Email] Eamil Template Delete Request',
    props<{ id: number }>()
);

export const actionTenantDialogEmailTemplateDeleteSucceeded = createAction(
    '[Tenant Dialog Email] Eamil Template Delete Succeeded'
);

export const actionTenantDialogEmailTemplateDeleteFailed = createAction(
    '[Tenant Dialog Email] Eamil Template Delete Failed'
);

export const actionTenantDialogEmailTemplateSaveAndUpdateStateReset = createAction(
    '[Tenant Dialog Email] Eamil Template Save And Update State Reset'
);

export const actionTenantDialogEmailTemplateDeleteStateReset = createAction(
    '[Tenant Dialog Email] Eamil Template Delete State Reset'
);

export const actionTenantDialogEmailEditorStateReset = createAction(
    '[Tenant Dialog Email] Email Editor State Reset',
);

export const actionTenantDialogEmailEditorActions = {
    actionTenantDialogSendEmailRequest: actionTenantDialogSendEmailRequest,
    actionTenantDialogSendEmailSuccessed: actionTenantDialogSendEmailSuccessed,
    actionTenantDialogSendEmailFailed: actionTenantDialogSendEmailFailed,
    actionTenantDialogSendEmailReplyEditorIsForwardingUpdate: actionTenantDialogSendEmailReplyEditorIsForwardingUpdate,
    actionTenantDialogSendEmailReplyEditorReplyAllToUpdate: actionTenantDialogSendEmailReplyEditorReplyAllToUpdate,
    actionTenantDialogEmailEditorIsCcAndBccActiveUpdate: actionTenantDialogEmailEditorIsCcAndBccActiveUpdate,
    actionTenantDialogSendEmailEditorUpdate: actionTenantDialogSendEmailEditorUpdate,
    actionTenantDialogSendEmailReplyEditorUpdate: actionTenantDialogSendEmailReplyEditorUpdate,
    actionTenantDialogSendEmailEditorReset: actionTenantDialogSendEmailEditorReset,
    actionTenantDialogSendEmailReplyEditorReset: actionTenantDialogSendEmailReplyEditorReset,
    actionTenantDialogSendEmailAndCollaborateRequest: actionTenantDialogSendEmailAndCollaborateRequest,
    actionTenantDialogSendEmailAndCollaborateSuccessed: actionTenantDialogSendEmailAndCollaborateSuccessed,
    actionTenantDialogSendEmailAndCollaborateFailed: actionTenantDialogSendEmailAndCollaborateFailed,
    actionTenantDialogEmailTemplatesRequest: actionTenantDialogEmailTemplatesRequest,
    actionTenantDialogEmailTemplatesLoaded: actionTenantDialogEmailTemplatesLoaded,
    actionTenantDialogEmailTemplatesFalied: actionTenantDialogEmailTemplatesFalied,
    actionTenantDialogEmailTemplateSaveAndUpdate: actionTenantDialogEmailTemplateSaveAndUpdate,
    actionTenantDialogEmailTemplateSaveAndUpdateSucceeded: actionTenantDialogEmailTemplateSaveAndUpdateSucceeded,
    actionTenantDialogEmailTemplateSaveAndUpdateFailed: actionTenantDialogEmailTemplateSaveAndUpdateFailed,
    actionTenantDialogEmailTemplateDeleteRequest: actionTenantDialogEmailTemplateDeleteRequest,
    actionTenantDialogEmailTemplateDeleteSucceeded: actionTenantDialogEmailTemplateDeleteSucceeded,
    actionTenantDialogEmailTemplateDeleteFailed: actionTenantDialogEmailTemplateDeleteFailed,
    actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate: actionTenantDialogEmailSendingMailTypeAndExternalIdentifierUpdate,
    actionTenantDialogEmailTemplateDeleteStateReset: actionTenantDialogEmailTemplateDeleteStateReset,
    actionTenantDialogEmailTemplateSaveAndUpdateStateReset: actionTenantDialogEmailTemplateSaveAndUpdateStateReset,
    actionTenantDialogEmailEditorStateReset: actionTenantDialogEmailEditorStateReset
}
