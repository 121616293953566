import {
  UntypedFormGroup
} from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormService {
  constructor() {}

  public markFormGroupTouched(formGroup: UntypedFormGroup) {
    if (formGroup.controls !== undefined) {
      Object.values(formGroup.controls).forEach(control => {
        control.markAsTouched();
        if (control['controls']) {
          control['controls'].forEach(c => this.markFormGroupTouched(c));
        }
      });
    }
  }

  public validationMessages() {
    const messages = {
      required: 'This field is required',
      email: 'This email address is invalid'
    };

    return messages;
  }

  // Validate form instance
  // check_dirty true will only emit errors if the field is touched
  // check_dirty false will check all fields independent of
  // being touched or not. Use this as the last check before submitting
  public validateForm(
    formToValidate: UntypedFormGroup,
    formErrors: any,
    checkDirty?: boolean
  ) {
    const form = formToValidate;

    for (const field in formErrors) {
      if (field) {
        formErrors[field] = '';
        const control = form.get(field);

        const messages = this.validationMessages();
        if (control && !control.valid) {
          if (!checkDirty || control.dirty || control.touched) {
            for (const key in control.errors) {
              if (key && key !== 'invalid_characters') {
                formErrors[field] = formErrors[field] || messages[key];
              } else {
                formErrors[field] =
                  formErrors[field] || messages[key](control.errors[key]);
              }
            }
          }
        }
      }
    }

    return formErrors;
  }
}
