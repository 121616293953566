import { Action, createReducer, on } from "@ngrx/store";
import { BookingRepresentativeCalendarEventsState } from "../../booking.state";
import { actionBookingAccountRepresentativesSelectedUserIdUpdate, bookingRepresentativeCalendarActions, bookingRepresentativeCalendarFailedActions } from "./booking-representative-calendar.action";

export const initialState: BookingRepresentativeCalendarEventsState = {
    isLoaded: false,
    repSelectedUserId: -1,
    representative: {
        id: -1,
        uri: '',
        firstName: '',
        lastName: '',
        photoUri: ''
    },
    ifMicrosoftConnected: false,
    ifZoomConnected: false,
    events: [],
    isAppointmentsLoaded: false,
    appointments: [],
    isWeekScheduleLoading: true,
    weekSchedule: {
        weekStartDate: '',
        weekEndDate: '',
        weeklyScheduleDays: []
    }
}

const reducer = createReducer(
    initialState,
    on(
        actionBookingAccountRepresentativesSelectedUserIdUpdate,
        (state, { repUserId: repId }) => ({
            ...state,
            repSelectedUserId: repId
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarRequested,
        (state) => ({
            ...state,
            isLoaded: false
        })
    ),
    on(
        bookingRepresentativeCalendarFailedActions.actionBookingRepresentativeCalendarFailed,
        (state) => ({
            ...state,
            isLoaded: true
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarAppointmentsRequested,
        (state) => ({
            ...state,
            isAppointmentsLoaded: false
        })
    ),
    on(
        bookingRepresentativeCalendarFailedActions.actionBookingRepresentativeCalendarAppointmentsFailed,
        (state) => ({
            ...state,
            isAppointmentsLoaded: true
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarLoaded,
        (state, { representative, events }) => ({
            ...state,
            isLoaded: true,
            representative: representative,
            events: events
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarAppointmentsLoaded,
        (state, { appointments }) => ({
            ...state,
            isAppointmentsLoaded: true,
            appointments: appointments
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarChangeMicrosoftConnected,
        (state, { ifConnected }) => ({
            ...state,
            ifMicrosoftConnected: ifConnected
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarChangeZoomConnected,
        (state, { ifConnected }) => ({
            ...state,
            ifZoomConnected: ifConnected
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarRepresentativeWeekScheduleRequested,
        (state) => ({
            ...state,
            isWeekScheduleLoading: true
        })
    ),
    on(
        bookingRepresentativeCalendarFailedActions.actionBookingRepresentativeCalendarRepresentativeWeekScheduleFailed,
        (state) => ({
            ...state,
            isWeekScheduleLoading: false
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarRepresentativeWeekScheduleLoaded,
        (state, { weekSchedule }) => ({
            ...state,
            isWeekScheduleLoading: false,
            weekSchedule: weekSchedule
        })
    ),
    on(
        bookingRepresentativeCalendarActions.actionBookingRepresentativeCalendarReset,
        (state) => ({
            ...initialState,
            repSelectedUserId: state.repSelectedUserId
        })
    ),
)

export const bookingRepresentativeCalenadarEventsReducer = (
    state: BookingRepresentativeCalendarEventsState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
