import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';

@Component({
  selector: 'dq-timeline-unknown',
  templateUrl: './timeline-unknown.component.html',
  styleUrls: ['./timeline-unknown.component.scss', './timeline-unknown.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimelineUnknownComponent implements OnInit {

  @Input() event: ITimelineElement

  isMobile$: Observable<boolean>;

  text: string

  constructor(public store: Store<State>) { }

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));
  }
}
