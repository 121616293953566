import { createAction, props } from "@ngrx/store";
import { RepresentativeThumbnail, Account, IBuilding, IUnit } from "../../booking.state";


export const actionBookingAccountRepresentativesRequested = createAction(
    '[Booking] Booking Account Representatives Requested',
)

export const actionBookingAccountRepresentativesLoaded = createAction(
    '[Booking] Booking Account Representatives Loaded',
    props<{ account: Account, representativeThumbnails: RepresentativeThumbnail[] }>()
)

export const actionBookingAccountRepresentativesFailed = createAction(
    '[Booking] Booking Account Representatives Failed',
)

export const actionBookingAccountBuildingInventoryRequested = createAction(
    '[Booking] Booking Account Building Inventory Requested',
    props<{ buildingId: string }>()
)

export const actionBookingAccountBuildingInventoryLoaded = createAction(
    '[Booking] Booking Account Building Inventory Loaded',
    props<{ units: IUnit[] }>()
)

export const actionBookingAccountBuildingInventoryFailed = createAction(
    '[Booking] Booking Account Building Inventory Failed',
)

export const actionBookingAccountBuildingRepresentativesRequested = createAction(
    '[Booking] Booking Account Building Representatives Requested',
    props<{ buildingId: string }>()
)

export const actionBookingAccountBuildingRepresentativesLoaded = createAction(
    '[Booking] Booking Account Building Representatives Loaded',
    props<{ representativeThumbnails: RepresentativeThumbnail[] }>()
)

export const actionBookingAccountBuildingRepresentativesFailed = createAction(
    '[Booking] Booking Account Building Representatives Failed',
)

export const actionBookingAccountBuildingssRequested = createAction(
    '[Booking] Booking Account Buildings Requested',
    props<{ accountId: string }>()
)

export const actionBookingAccountBuildingsLoaded = createAction(
    '[Booking] Booking Account Buildings Loaded',
    props<{ buildings: IBuilding[] }>()
)

export const actionBookingAccountBuildingsFailed = createAction(
    '[Booking] Booking Account Buildings Failed',
)

export const actionBookingAccountRepresentativesReset = createAction(
    '[Booking] Booking Account Representatives State Reset',
)

