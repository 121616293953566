import { Action, createReducer, on } from "@ngrx/store";
import { BookingAccountState } from "../../booking.state";
import { bookingAccountActions } from "./booking-account.action";

export const initialState: BookingAccountState = {
    isLoaded: false,
    accounts: []
}

const reducer =  createReducer(
    initialState,
    on(
        bookingAccountActions.actionBookingAccountAccountsLoaded,
        (state,{accounts}) =>({
          ...state,
          isLoaded: true,
          accounts: accounts
        })
    ),
    on(
        bookingAccountActions.actionBookingAccountAccountsRequested,
        (state) =>({
          ...state,
          isLoaded: false
        })
    ),
    on(
        bookingAccountActions.actionBookingAccountAccountsReset,
        (state) =>(
          initialState
        )
    ),
)

export const bookingAccountReducer = (
    state: BookingAccountState | undefined,
    action: Action
  ) => {
    return reducer(state, action);
  };
  