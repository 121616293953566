<div class="mat-dialog-content">
    <div class="dq-row">
        <div class="col-sm-12">
            <div class="search-div search-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input class="search-input contrast-background-color" type="text" placeholder="Search rep"
                    [formControl]="repNameControl">
            </div>
        </div>
    </div>
    <ng-container *ngIf="(isRepOptionsLoaded$ | async); else loading">
        <ng-container *ngIf="(repSearchOptions$ | async)">
            <div class="dq-row">
                <div class="col-sm-12">
                    <div class="rep-options-container">
                        <ng-container *ngFor="let rep of (repSearchOptions$ | async)">
                            <div class="rep-option-div">
                                <mat-checkbox class="col-12 py-2 px-3" [value]="rep.name"
                                    (change)="changeSelection($event.checked, rep)" [checked]="rep.isChecked">
                                    {{rep.name}}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #loading>
        <mat-spinner></mat-spinner>
    </ng-template>
</div>
<div class="dq-row">
    <div class="col-12" style="text-align: center;">
        <dq-spinner-button type="button" (click)="save()"
            [options]="spinnerSaveButtonOptions"></dq-spinner-button>
    </div>
</div>