import { UntypedFormGroup } from "@angular/forms";

export const atLeastOneValidator = (keys: string[]) => {
    return (group: UntypedFormGroup) => {
        const { controls } = group;
        return keys.some(key => controls[key] && checkIfValueValid(controls[key].value))
            ? null
            : { atLeastOne: 'error' };
    };
};

//not '', not null, not undefined, if value is an array , it has to be at least one element is valid (not '', not null, not undefined)
const checkIfValueValid = (value) => {
    if (Array.isArray(value)) {
        return value.some(x => !!x)
    }
    return !!value
}