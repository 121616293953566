import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadInfo } from 'app/shared/models/lead-info-models';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS, LEAD_PREFERENCE_BATH_OPTIONS, LEAD_PREFERENCE_BED_OPTIONS, LEAD_PREFERENCE_LANGUAGE_OPTIONS, LEAD_PREFERENCE_PARKING_OPTIONS, LEAD_PREFERENCE_PET_OPTIONS, LeadPreference, LeadPreferenceTypes } from 'app/shared/models/lead-preference.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectClientLeadId, selectClientLeadInfo } from '../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogClientPreferenceDeleteRequested, actionClientDialogLeadPreferenceRequested, actionClientDialogPreferenceCreateUpdateSuccessfulStateReset, actionClientDialogPreferenceReset } from '../client-dialog-lead-preference.action';
import { selectclientDialogPreferencesStateClientPreferences, selectclientDialogPreferencesStateIsPreferencesLoaded } from '../client-dialog-lead-preference.selector';
import { ClientDialogUserGeneralPreferencesComponent } from '../components/client-dialog-user-general-preference/client-dialog-user-general-preference.component';
import { MultiPurposeDialogComponent } from 'app/shared/components/multi-purpose-dialog/multi-purpose-dialog.component';

@Component({
  selector: 'dq-client-dialog-lead-preference',
  templateUrl: './client-dialog-lead-preference.component.html',
  styleUrls: ['./client-dialog-lead-preference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogLeadPreferenceComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  unsubscribe: Subject<void> = new Subject();
  isMobile$: Observable<boolean>;
  isMobile: boolean;
  leadInfo$: Observable<LeadInfo>;
  leadId$: Observable<number>
  leadId: number;
  isPreferencesLoaded$: Observable<boolean>;
  leadPreferences$: Observable<LeadPreference[]>;
  leadBedPreferences: LeadPreference[];
  leadBathPreferences: LeadPreference[];
  leadBudgetPreference: LeadPreference;
  leadParkingPreference: LeadPreference;
  leadPetsPreferences: LeadPreference[];
  leadMoveInDatePreference: LeadPreference;
  leadLanguagePreferences: LeadPreference[];
  leadAccessbilityPreferences: LeadPreference[];
  leadPreferencesEditorDialogRef: MatDialogRef<MultiPurposeDialogComponent>;

  ngOnInit(): void {
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo))
    this.leadId$ = this.store.pipe(select(selectClientLeadId))
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId
      if (leadId > 0) {
        this.store.dispatch(actionClientDialogLeadPreferenceRequested({ leadId: leadId }))
      }
    })
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })
    this.leadPreferences$ = this.store.pipe(select(selectclientDialogPreferencesStateClientPreferences));
    this.leadPreferences$.pipe(takeUntil(this.unsubscribe)).subscribe(preferences => {

      this.leadBedPreferences = LEAD_PREFERENCE_BED_OPTIONS.filter(x => preferences.map(y => y.type).includes(x.type) && preferences.map(y => y.value).includes(x.value));
      this.leadBathPreferences = LEAD_PREFERENCE_BATH_OPTIONS.filter(x => preferences.map(y => y.type).includes(x.type) && preferences.map(y => y.value).includes(x.value));
      this.leadParkingPreference = LEAD_PREFERENCE_PARKING_OPTIONS.find(x => preferences.map(y => y.type).includes(x.type) && preferences.map(y => y.value).includes(x.value));
      this.leadPetsPreferences = LEAD_PREFERENCE_PET_OPTIONS.filter(x => preferences.map(y => y.type).includes(x.type) && preferences.map(y => y.value).includes(x.value));
      this.leadLanguagePreferences = LEAD_PREFERENCE_LANGUAGE_OPTIONS.filter(x => preferences.map(y => y.type).includes(x.type) && preferences.map(y => y.value).includes(x.value));
      this.leadAccessbilityPreferences = LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS.filter(x => preferences.map(y => y.type).includes(x.type) && preferences.map(y => y.value).includes(x.value));
      this.leadBudgetPreference = preferences.find(x => x.type == LeadPreferenceTypes.BUDGET);
      this.leadMoveInDatePreference = preferences.find(x => x.type == LeadPreferenceTypes.MOVE_IN_DATE);

      this.cdr.markForCheck();
    });
    this.isPreferencesLoaded$ = this.store.pipe(select(selectclientDialogPreferencesStateIsPreferencesLoaded));
  }

  openPreferenceAddEditDialogEdit(preference?: LeadPreference): void {
    this.leadPreferencesEditorDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: {
        title: 'Add Preference',
        component: ClientDialogUserGeneralPreferencesComponent,
        dialogData: {
        }
      }
    });

    this.leadPreferencesEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.store.dispatch(actionClientDialogPreferenceCreateUpdateSuccessfulStateReset())
    });
  }

  deletePreference(preferenceValue: string) {
    if (this.leadId > 0) {
      this.store.dispatch(actionClientDialogClientPreferenceDeleteRequested({ leadId: this.leadId, preferenceValue: preferenceValue }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionClientDialogPreferenceReset())
  }
}
