import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { ACTIVITY_TYPES } from 'app/shared/models/lead-info-models';
import { selectTenantDialogTenantId, selectTenantDialogTenantInfo } from 'app/features/tenant-dialog/components/tenant-dialog-user-general/tenant-dialog-general.selectors';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { ITimelineElement } from 'app/shared/components/timeline/timeline.model';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionTenantDialogActivityStateReset, tenantDialogActivityActions } from '../tenant-dialog-activities.action';
import { selectTenantIsActivitiesLoaded, selectTenantActivities, selectTenantIsActivitiesTotalCommunicationRecords, selectTenantIsActivitiesCommunicationPageNo, selectTenantIsActivitiesCommunicationPageSize, selectTenantIsActivitiesCommunicationIsLoadingMore } from '../tenant-dialog-activities.selector';
import { LeadEmailMessage } from 'app/shared/models/lead-communication.model';
import { TenantInfo } from 'app/shared/models/tenant-info-models';
import { MatProgressButtonOptions } from '../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-tenant-dialog-activities',
  templateUrl: './tenant-dialog-activities.component.html',
  styleUrls: ['./tenant-dialog-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogActivitiesComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) { }

  tenantInfo$: Observable<TenantInfo>
  tenantId$: Observable<number>
  tenantId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  unsubscribe: Subject<void> = new Subject();

  @Output()
  reply = new EventEmitter<LeadEmailMessage>();

  @Output()
  replyAll = new EventEmitter<LeadEmailMessage>();

  @Output()
  forward = new EventEmitter<LeadEmailMessage>();


  activities$: Observable<ITimelineElement[]>
  allActivities: ITimelineElement[]
  callActivities: ITimelineElement[]
  noteActivities: ITimelineElement[]
  smsActivities: ITimelineElement[]
  appointmentActivities: ITimelineElement[]
  emailActivities: ITimelineElement[]
  webActivities: ITimelineElement[]
  isActivitiesLoaded$: Observable<boolean>

  communicationTotalecords$: Observable<number>
  communicationPageNo$: Observable<number>
  communicationPageNo: number
  communicationPageSize$: Observable<number>
  communicationPageSize: number
  isLoadingMoreCommunications$: Observable<boolean>
  communicationCount: number

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Load More',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    });

    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo));
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId))
    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      if (tenantId > 0) {
        this.tenantId = tenantId
        this.store.dispatch(tenantDialogActivityActions.actionTenantDialogAllActivitiesRequest({ tenantId: tenantId, pageNo: 0, pageSize: 30, itemCountPage: 0 }));
      }
    });

    this.isActivitiesLoaded$ = this.store.pipe(select(selectTenantIsActivitiesLoaded))
    this.activities$ = this.store.pipe(select(selectTenantActivities))
    this.activities$.pipe(takeUntil(this.unsubscribe)).subscribe(activities => {
      this.allActivities = activities
      this.smsActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.TEXT)
      this.callActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.VOICE)
      this.emailActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.EMAIL)
      this.noteActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.NOTE)
      this.webActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.WEBFORM)
      this.appointmentActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.APPOINTMENT)
      this.communicationCount = activities.filter(activity => activity.type != ACTIVITY_TYPES.APPOINTMENT && activity.type != ACTIVITY_TYPES.NOTE).length
    });

    this.communicationTotalecords$ = this.store.pipe(select(selectTenantIsActivitiesTotalCommunicationRecords))
    this.communicationPageNo$ = this.store.pipe(select(selectTenantIsActivitiesCommunicationPageNo))
    this.communicationPageNo$.pipe(takeUntil(this.unsubscribe)).subscribe(communicationPageNo => {
      this.communicationPageNo = communicationPageNo
    });
    this.communicationPageSize$ = this.store.pipe(select(selectTenantIsActivitiesCommunicationPageSize))
    this.communicationPageSize$.pipe(takeUntil(this.unsubscribe)).subscribe(communicationPageSize => {
      this.communicationPageSize = communicationPageSize
    });
    this.isLoadingMoreCommunications$ = this.store.pipe(select(selectTenantIsActivitiesCommunicationIsLoadingMore))
    this.isLoadingMoreCommunications$.pipe(takeUntil(this.unsubscribe)).subscribe(isLoadingMoreCommunications => {
      this.spinnerButtonOptions.active = isLoadingMoreCommunications
      this.cdr.markForCheck()
    });
  }

  loadMoreCommunications() {
    if (this.spinnerButtonOptions.active) {
      return
    }
    this.store.dispatch(tenantDialogActivityActions.actionTenantDialogAllActivitiesRequest({ tenantId: this.tenantId, pageNo: this.communicationPageNo + 1, pageSize: 20, itemCountPage: this.communicationCount, isLoadMore: true }));
  }

  replyEmail(emailMessage: LeadEmailMessage) {
    this.reply.emit(emailMessage);
  }

  replyEmailAll(emailMessage: LeadEmailMessage) {
    this.replyAll.emit(emailMessage);
  }

  forwardEmail(emailMessage: LeadEmailMessage) {
    this.forward.emit(emailMessage);
  }


  ngOnDestroy(): void {
    // reset communication state
    this.store.dispatch(actionTenantDialogActivityStateReset());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
