<div class="tenant-dialog-tags-component">
    <mat-card>
        <div class="container">
            <div class="row">
                <div>
                    <mat-icon class="icon-general">label</mat-icon>
                </div>
                <div>
                    <h4 class="title">Tags</h4>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div class="row items-container">
                <ng-container>
                    <div class="tags-container">
                        <ng-container *ngIf="(isTagsLoaded$  | async); else tenantTagsLoading">
                            <ng-container *ngFor="let tag of (tenantTags$ | async)">
                                <div class="tag-div-wrapper">
                                    <div class="tag-div"> {{tag.name}}<mat-icon class="tag-icon"
                                            (click)="deleteTag(tag)">clear
                                        </mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                            <button (click)="openTagAddEditDialogEdit()" mat-mini-fab class="add-tag-button">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #tenantTagsLoading>
                            <mat-spinner class="tags-loading-spinner"></mat-spinner>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>
</div>