import { IQueryResult } from "app/shared/models/queries.model";

export interface Conversation {
    id: number;
    userId: number;
    tenantId: number;
    closedByUserId: number;
    markedReadUserId: number;
    lastCommunicationType: string;
    lastCommunicationDirection: string;
    lastCommunicationSnippet: string;
    lastCommunicationDate: Date;
    creationDate: Date;
    closedDate: Date;
    markedReadDate: Date;
    clientInfo: ClientInfo;
    userInfo: UserInfo;
}

export interface Communication {
    id: number;
    userId: number;
    tenantId: number;
    communicationType: string;
    direction: string;
    communicationDate: Date;
    clientInfo: ClientInfo;
}

export interface ITextMessageQueryResult extends IQueryResult {
    objectsList: TextChatMessage[]
}

export interface TextChatMessage {
    id: number;
    tenantId: number;
    from: string;
    to: string
    direction: string;
    communicationDate: string;
    message: string;
    fromLabel: string;
    toLabel: string;
}

export interface ActivityNote {
    tenantId: number;
    type: string;
    date: Date;
    content: string;
    metaData: string;
}

export interface ClientInfo {
    firstName: string,
    lastName: string
}

export interface UserInfo {
    firstName: string,
    lastName: string
}

export interface User {
    id: number
    name: string
}

export interface Building {
    id: number
    name: string
}

export enum ConversationType {
    MY_INBOX = 'My Inbox',
    COMPANY_INBOX = 'Company Inbox',
    OUTBOX = 'Outbox',
    VOICE_MAIL_BOX = 'Voicemail Inbox'
}

export const CONVERSASTION_TYPE_OPTIONS: ConversationType[] = [
    ConversationType.MY_INBOX,
    ConversationType.COMPANY_INBOX,
    ConversationType.OUTBOX,
    ConversationType.VOICE_MAIL_BOX
]

export interface TextMessageEditor {
    message: string;
    sendFrom: string;
    sendTo: string;
}

export interface EmailEditor {
    email: string;
    sendFromUserId: number;
    sendToEmails: string[];
    sendToCcs: string[];
    sendToBccs: string[];
    subject: string;
    attachements: string[];
}

export interface EmailReplyEditor {
    email: string;
    sendToCcs: string[];
    sendToBccs: string[];
    subject: string;
}

export interface CommunicationTemplate {
    id: number;
    type: string;
    friendlyName: string;
    displayName: string;
    content: string;
    subject?: string
    isAccountShared: boolean
}

export enum CommunicationType {
    EMAIL = 'Email',
    TEXT = 'Text',
    VOICE = 'Voice',
}

export const COMMUNICATION_TYPES = [
    {
        name: 'Email',
        value: CommunicationType.EMAIL,
    },
    {
        name: 'Text',
        value: CommunicationType.TEXT,
    },
    {
        name: 'Call',
        value: CommunicationType.VOICE,
    }
]


export enum CommunicationUserRole {
    INSIDESALESREPRESENTATIVE = 'InsideSalesRepresentative',
    REPRESENTATIVE = 'Representative',
    TEAMLEAD = 'TeamLead',
}

export const COMMUNICATION_USER_ROLES = [
    {
        name: 'ISA',
        value: CommunicationUserRole.INSIDESALESREPRESENTATIVE
    },
    {
        name: 'Agent',
        value: CommunicationUserRole.REPRESENTATIVE
    },
    {
        name: 'Manager',
        value: CommunicationUserRole.TEAMLEAD
    },
]
