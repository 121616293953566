<div class="common-delete-confirm-component">
    <div class="mat-dialog-title">
        <h2>{{title}}</h2>
        <div (click)="$event.preventDefault();close()">
            <mat-icon svgIcon="times" class="cancel"></mat-icon>
        </div>
    </div>
    <div class="delete-content" [innerHTML]="content">

    </div>
    <div class="row">
        <div class="col-12 button-div">
            <dq-spinner-button class="" type="button" (click)="confirm()"
                [options]="spinnerSaveButtonOptions"></dq-spinner-button>
        </div>
    </div>
</div>
