import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { ACTIVITY_TYPES, LeadInfo } from 'app/shared/models/lead-info-models';
import { selectClientLeadId, selectClientLeadInfo } from 'app/features/client-dialog/components/client-dialog-user-general/client-dialog-general.selectors';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { ITimelineElement } from 'app/shared/components/timeline/timeline.model';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionClientDialogActivityStateReset, clientDialogActivityActions } from '../client-dialog-activities.action';
import { selectClienIsActivitiesLoaded, selectClienActivities, selectClienIsActivitiesTotalCommunicationRecords, selectClienIsActivitiesCommunicationPageNo, selectClienIsActivitiesCommunicationPageSize, selectClienIsActivitiesCommunicationIsLoadingMore } from '../client-dialog-activities.selector';
import { LeadEmailMessage } from 'app/shared/models/lead-communication.model';
import { MatProgressButtonOptions } from '../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-activities',
  templateUrl: './client-dialog-activities.component.html',
  styleUrls: ['./client-dialog-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogActivitiesComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) { }

  leadInfo$: Observable<LeadInfo>
  leadId$: Observable<number>
  leadId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  unsubscribe: Subject<void> = new Subject();

  @Output()
  reply = new EventEmitter<LeadEmailMessage>();

  @Output()
  replyAll = new EventEmitter<LeadEmailMessage>();

  @Output()
  forward = new EventEmitter<LeadEmailMessage>();


  activities$: Observable<ITimelineElement[]>
  allActivities: ITimelineElement[]
  callActivities: ITimelineElement[]
  noteActivities: ITimelineElement[]
  smsActivities: ITimelineElement[]
  appointmentActivities: ITimelineElement[]
  emailActivities: ITimelineElement[]
  webActivities: ITimelineElement[]
  isActivitiesLoaded$: Observable<boolean>

  communicationTotalecords$: Observable<number>
  communicationPageNo$: Observable<number>
  communicationPageNo: number
  communicationPageSize$: Observable<number>
  communicationPageSize: number
  isLoadingMoreCommunications$: Observable<boolean>
  communicationCount: number

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Load More',
    flat: true,
    spinnerSize: 18,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    });

    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo));
    this.leadId$ = this.store.pipe(select(selectClientLeadId))
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      if (leadId > 0) {
        this.leadId = leadId
        this.store.dispatch(clientDialogActivityActions.actionClientDialogAllActivitiesRequest({ leadId: leadId, pageNo: 0, pageSize: 30, itemCountPage: 0 }));
      }
    });

    this.isActivitiesLoaded$ = this.store.pipe(select(selectClienIsActivitiesLoaded))
    this.activities$ = this.store.pipe(select(selectClienActivities))
    this.activities$.pipe(takeUntil(this.unsubscribe)).subscribe(activities => {
      this.allActivities = activities
      this.smsActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.TEXT)
      this.callActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.VOICE)
      this.emailActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.EMAIL)
      this.noteActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.NOTE)
      this.webActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.WEBFORM)
      this.appointmentActivities = activities.filter(activity => activity.type == ACTIVITY_TYPES.APPOINTMENT)
      this.communicationCount = activities.filter(activity => activity.type != ACTIVITY_TYPES.APPOINTMENT && activity.type != ACTIVITY_TYPES.NOTE).length
    });

    this.communicationTotalecords$ = this.store.pipe(select(selectClienIsActivitiesTotalCommunicationRecords))
    this.communicationPageNo$ = this.store.pipe(select(selectClienIsActivitiesCommunicationPageNo))
    this.communicationPageNo$.pipe(takeUntil(this.unsubscribe)).subscribe(communicationPageNo => {
      this.communicationPageNo = communicationPageNo
    });
    this.communicationPageSize$ = this.store.pipe(select(selectClienIsActivitiesCommunicationPageSize))
    this.communicationPageSize$.pipe(takeUntil(this.unsubscribe)).subscribe(communicationPageSize => {
      this.communicationPageSize = communicationPageSize
    });
    this.isLoadingMoreCommunications$ = this.store.pipe(select(selectClienIsActivitiesCommunicationIsLoadingMore))
    this.isLoadingMoreCommunications$.pipe(takeUntil(this.unsubscribe)).subscribe(isLoadingMoreCommunications => {
      this.spinnerButtonOptions.active = isLoadingMoreCommunications
      this.cdr.markForCheck()
    });
  }

  loadMoreCommunications() {
    if (this.spinnerButtonOptions.active) {
      return
    }
    this.store.dispatch(clientDialogActivityActions.actionClientDialogAllActivitiesRequest({ leadId: this.leadId, pageNo: this.communicationPageNo + 1, pageSize: 20, itemCountPage: this.communicationCount, isLoadMore: true }));
  }

  replyEmail(emailMessage: LeadEmailMessage) {
    this.reply.emit(emailMessage);
  }

  replyEmailAll(emailMessage: LeadEmailMessage) {
    this.replyAll.emit(emailMessage);
  }

  forwardEmail(emailMessage: LeadEmailMessage) {
    this.forward.emit(emailMessage);
  }


  ngOnDestroy(): void {
    // reset communication state
    this.store.dispatch(actionClientDialogActivityStateReset());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
