import { createSelector } from "@ngrx/store";
import { TenantDialogState, TenantDialogBuildingState, selectTenantDialog } from "../../tenant-dialog.state";

export const selectTenantDialogBuildingsState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogBuilding
);

export const selectTenantDialogBuildingsStateIsBuildingLoaded = createSelector(
    selectTenantDialogBuildingsState,
    (state: TenantDialogBuildingState) => {
        return state.isBuildingLoaded
    }
);

export const selectTenantDialogBuildingsStateTenantBuilding = createSelector(
    selectTenantDialogBuildingsState,
    (state: TenantDialogBuildingState) => {
        return state.tenantBuilding
    }
);

