import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Observable, Subject } from 'rxjs';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LeasingRep, RentalItem, InitRentalRequest } from 'app/features/client-dialog/client-dialog-model';
import { actionClientDialogApplicationLeasingRepRequested, actionClientDialogApplicationInitRentalRequestRequested } from '../client-dialog-rental-request.action';
import { selectClientDialogClientLeasingReps, selectClientDialogClientRentalParkingItems, selectClientDialogClientRentalStorageItems, selectClientDialogIsClientRentalRequestSending } from '../client-dialog-rental-request.selector';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { USER_LANGUAGES } from 'app/shared/models/user-language.model';
import { RENTAL_REQUEST_STATUSES } from 'app/shared/models/rental-application.model';

@Component({
  selector: 'dq-client-dialog-rental-request',
  templateUrl: './client-dialog-rental-request.component.html',
  styleUrls: ['./client-dialog-rental-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalRequestComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    @Inject('dialogData') private dialogData: any,
    public fb: UntypedFormBuilder)
  {
      this.madeReadyDate = dialogData.madeReadyDate as Date;
      this.unitId = dialogData.unitId as number;
      this.buildingId = dialogData.buildingId as number;
      this.leadId = dialogData.leadId as number;
      this.price = dialogData.price as number;
      this.inventoryFriendlyName = dialogData.inventoryFriendlyName as string;
  }

  unitId: number;
  inventoryFriendlyName: string;

  unsubscribe$: Subject<void> = new Subject();
  leadId: number
  userRoles: string[]
  price: number;
  buildingId: number;

  rentalParkingItems$: Observable<RentalItem[]>;
  rentalStorageItems$: Observable<RentalItem[]>;

  leasingReps$: Observable<LeasingRep[]>;

  isRentalRequestSending$: Observable<boolean>;
  isRentalRequestSending: boolean;

  rentalRequestForm: UntypedFormGroup;
  madeReadyDate: Date;
  minDate: Date = new Date();
  RENTAL_REQUEST_STATUES = RENTAL_REQUEST_STATUSES;
  USER_LANGUAGES = USER_LANGUAGES;

  ngOnInit(): void {

    var minMoveInDate = this.madeReadyDate < new Date() ? new Date() : this.madeReadyDate

    var year = minMoveInDate.getFullYear();
    var month = minMoveInDate.getMonth();
    var day = minMoveInDate.getDate();
    var leaseEndDate = new Date(year + 1, month, day);

    this.rentalRequestForm = this.fb.group({
      moveInDate: [minMoveInDate, {validators: [Validators.required]}],
      leaseStartDate: [minMoveInDate, {validators: [Validators.required]}],
      leaseEndDate: [leaseEndDate, {validators: [Validators.required]}],
      leasingRep: ['' , {validators: [Validators.required]}],
      leasingLanguage: ['en' , {validators: [Validators.required]}],
      rentalStorageItems: [],
      rentalParkingItems: [],
    });

    this.leasingReps$ = this.store.pipe(select(selectClientDialogClientLeasingReps));
    this.isRentalRequestSending$ = this.store.pipe(select(selectClientDialogIsClientRentalRequestSending));
    this.isRentalRequestSending$.pipe(takeUntil(this.unsubscribe$)).subscribe(isRentalRequestSending => {
      this.isRentalRequestSending = isRentalRequestSending;
    });

    this.store.dispatch(actionClientDialogApplicationLeasingRepRequested({ buildingId: this.buildingId }));

    this.rentalParkingItems$ = this.store.pipe(select(selectClientDialogClientRentalParkingItems));
    this.rentalStorageItems$ = this.store.pipe(select(selectClientDialogClientRentalStorageItems));

    this.store.pipe(select(selectUserRoles)).pipe(takeUntil(this.unsubscribe$)).subscribe(roles => {
      this.userRoles = roles;
    });
  }

  initNewRentalRequest() {
    if (this.rentalRequestForm.valid && !this.isRentalRequestSending) {
      let rentalStorageItems: RentalItem[] = this.rentalRequestForm.get('rentalStorageItems').value ?? [];
      let rentalParkingItems: RentalItem[] = this.rentalRequestForm.get('rentalParkingItems').value ?? [];
      let rentalItemIds: number[] = rentalStorageItems?.map(x => x.id).concat(rentalParkingItems.map(x => x.id));

      let initRentalRequest: InitRentalRequest = {
        unitId: this.unitId,
        leadId: this.leadId,
        moveInDate: this.rentalRequestForm.get('moveInDate').value,
        leaseStartDate: this.rentalRequestForm.get('leaseStartDate').value,
        leaseEndDate: this.rentalRequestForm.get('leaseEndDate').value,
        leasingRepId: this.rentalRequestForm.get('leasingRep').value,
        leasingLanguage: this.rentalRequestForm.get('leasingLanguage').value,
        rentalItems: rentalItemIds,
      }
      this.store.dispatch(actionClientDialogApplicationInitRentalRequestRequested({initRentalRequest: initRentalRequest}))
    }
  }

  isRentalRequestSubmitActive() {
    return this.rentalRequestForm.valid && !this.isRentalRequestSending;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
