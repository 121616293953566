import { Action, createReducer, on } from '@ngrx/store';
import {
  actionHasFooterFailed,
  actionHasFooterLoaded,
  actionHasFooterUpsertFail,
  actionHasFooterUpsertSuccess,
  actionWindowWidthUpsert
} from './ui.actions';

import { UIState } from './ui.model';

export const initialState: UIState = {
  hasFooter: false,
  width: 0
};

const reducer = createReducer(
  initialState,
  on(
    actionHasFooterLoaded,
    actionHasFooterUpsertSuccess,
    (state, { hasFooter }) => ({
      ...state,
      hasFooter
    })
  ),
  on(actionWindowWidthUpsert, (state, { width }) => ({
    ...state,
    width
  })),
  on(actionHasFooterFailed, actionHasFooterUpsertFail, () => initialState)
);

export function uiReducer(state: UIState | undefined, action: Action) {
  return reducer(state, action);
}
