export const RENTAL_REQUEST_STATUSES = {
    'CREATED': 'Created',
    'IN_PROGRESS': 'InProgress',
    'IN_REVIEW': 'InReview',
    'APPROVED': 'Approved',
    'CANCELLED': 'Cancelled',
    'COMPLETED': 'Completed'
}

export const RENTAL_APPLICATION_STATUSES = {
    'CREATED': 'Created',
    'IN_PROGRESS': 'InProgress',
    'SUBMITTED_FOR_REVIEW' : 'SubmittedForReview',
    'CANCELLED': 'Cancelled',
    'COMPLETED': 'Completed'
}

export const RENTAL_FORM_STATUSES = {
    'CREATED': 'Created',
    'IN_PROGRESS' : 'InProgress',
    'WAITING_FOR_APPLICANT' : 'WaitingForApplicant',
    'SUBMITTED_FOR_REVIEW' : 'SubmittedForReview',
    'CANCELLED': 'Cancelled',
    'COMPLETED': 'Completed',
    'FAILED': 'Failed',
}

export const RENTAL_VERIFICATION_REPORT_TYPE = {
    'IDENTITYCHECK': 'IdentityCheck',
    'INCOMECHECK' : 'IncomeCheck',
    'ASSETCHECK' : 'AssetCheck'
}

export const RENTAL_APPLICANT_TYPE = {
    'TENANT': 'Tenant',
    'OCCUPANT' : 'Occupant',
    'GUARANTOR' : 'Guarantor'
}

export const RENTAL_APPLICANT_EMPLOYEMENT_STATUS = {
    'EMPLOYED': 'Employed',
    'SELFEMPLOYED': 'SelfEmployed',
    'RETIRED' : 'Retired',
    'UNEMPLOYED' : 'Unemployed',
    'STUDENT' : 'Student',
    'INTERNATIONALSTUDENT' : 'InternationalStudent'
}

export const RENTAL_FORM_TYPE = {
    'RENTAL_APPLICATION_FORM': 'RentalApplicationForm',
    'RENTAL_APPLICATION_ADDENDUM': 'RentalApplicationAddendum',
    'LEASE_AGREEMENT' : 'LeaseAgreement',
    'LEASE_AGREEMENT_ADDENDUM' : 'LeaseAgreementAddendum',
    'LEASE_AGREEMENT_ADDENDUM_FORM' : 'LeaseAgreementAddendumForm',
    'LEASE_SIMPLE_DOCUMENT': 'LeaseSimpleDocument'
}

export interface RentalRequestDeleteReasonType {
    id: string
    label: string
}

export const APP_CANCEL_REASON_TYPES: RentalRequestDeleteReasonType[] = [
    {
        id: 'NoLongerInterested',
        label: 'No Longer Interested'
    },
    {
        id: 'References',
        label: 'Didn\'t Qualify - References'
    },
    {
        id: 'Background',
        label: 'Didn\'t Qualify - Background'
    },
    {
        id: 'Credit',
        label: 'Didn\'t Qualify - Credit'
    },
    {
        id: 'Income',
        label: 'Didn\'t Qualify - Income'
    },
    {
        id: 'Incomplete',
        label: 'Application Incomplete'
    }
]

export const REQUEST_CANCEL_REASON_TYPES: RentalRequestDeleteReasonType[] = [
    {
        id: 'NoLongerInterested',
        label: 'No Longer Interested'
    },
    {
        id: 'References',
        label: 'Didn\'t Qualify - References'
    },
    {
        id: 'Background',
        label: 'Didn\'t Qualify - Background'
    },
    {
        id: 'Credit',
        label: 'Didn\'t Qualify - Credit'
    },
    {
        id: 'Income',
        label: 'Didn\'t Qualify - Income'
    },
    {
        id: 'Incomplete',
        label: 'Application Incomplete'
    }
]