import { createAction, props } from "@ngrx/store";
import { TenantBuilding } from "../../../../shared/models/tenant-info-models";

export const actionTenantDialogTenantBuildingRequested = createAction(
    '[Tenant Dialog Buildings] Tenant Dialog Tenant Buildings Requested',
    props<{ tenantId: number }>()
)

export const actionTenantDialogTenantBuildingLoaded = createAction(
    '[Tenant Dialog Buildings] Tenant Dialog Tenant Buildings Loaded',
    props<{ building: TenantBuilding }>()
)

export const actionTenantDialogTenantBuildingFailed = createAction(
    '[Tenant Dialog Buildings] Tenant Dialog Tenant Buildings Failed'
)


export const actionTenantDialogBuildingReset = createAction(
    '[Tenant Dialog Buildings] Tenant Dialog Buildings  Reset',
)

export const tenantDialogBuildingActions = {
    actionTenantDialogTenantBuildingRequested,
    actionTenantDialogTenantBuildingLoaded,
    actionTenantDialogTenantBuildingFailed,
    actionTenantDialogBuildingReset,
}