<ng-container *ngIf="(rentalItemTypeSelected$ | async); else rentalItemTypes">
    <ng-container
        *ngIf="(isRentalItemOptionsLoaded$ | async) || (rentalItemOptions$ | async).length > 0; else loading">
        <ng-container *ngIf="(rentalItemOptions$ | async).length > 0; else noRentalItemAvailable">
            <div class="rental-items-div">
                <mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <mat-radio-button [value]="element.id" (change)="selectItem(element.id)"
                                [checked]="selectedItemId === element.id">
                                {{element.friendlyName}}
                            </mat-radio-button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ getRentalItemTypeDisplayName(element.type)
                            }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                        <mat-cell *matCellDef="let element"><mat-icon>attach_money</mat-icon>{{ element.price |
                            number: '2.' }}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row class="accent-hover-50" *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div>
                    <ng-container
                        *ngIf="(rentalItemOptions$ | async).length < (rentalItemOptionsTotalRecords$ | async)">
                        <dq-spinner-button class="load-more-button" type="button"
                            (click)="loadMoreRentalItemOptions()"
                            [options]="spinnerLoadMoreButtonOptions"></dq-spinner-button>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-6 button-div">
                    <button mat-stroked-button (click)="cancelType()">Cancel</button>
                </div>
                <div class="col-6 button-div">
                    <dq-spinner-button class="generic-button confirm-button" type="button"
                        [disabled]="!selectedItemId" (click)="submitForm()"
                        [options]="spinnerAddRentalItemButtonOptions"></dq-spinner-button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #rentalItemTypes>
    <ng-container *ngIf="(isRentalItemTypesLoaded$ | async); else loading">
        <ng-container *ngIf="(rentalItemTypes$ | async).length > 0; else noRentalItemAvailable">
            <div class="container">
                <div class="form-div">
                    <div class="row">
                        <ng-container *ngFor="let rentalType of (rentalItemTypes$ | async)">
                            <div class="col-6">
                                <div class="rental-type-div" (click)="rentalItemTypeSelected(rentalType)">
                                    {{getRentalItemTypeDisplayName(rentalType)}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #noRentalItemAvailable>
    <div class="dq-flex-centered rental-item-not-available">
        Not Available
    </div>
</ng-template>
<ng-template #loading>
    <div class="dq-flex-centered loading-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

