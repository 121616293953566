import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogLeadNoteState } from "../../client-dialog.state";
import { clientDialogLeadNoteActions } from "./client-dialog-lead-note.action";

const initialState: ClientDialogLeadNoteState = {
    isNoteSubmitting: false,
    isNoteSubmittedSuccessfully: false
}
const reducer = createReducer(
    initialState,
    on(
        clientDialogLeadNoteActions.actionClientDialogCreateNoteRequest,
        (state) => ({
            ...state,
            isNoteSubmitting: true,
            isNoteSubmittedSuccessfully: false
        })
    ),
    on(
        clientDialogLeadNoteActions.actionClientDialogCreateNoteSuccessed,
        (state) => ({
            ...state,
            isNoteSubmitting: false,
            isNoteSubmittedSuccessfully: true
        })
    ),
    on(
        clientDialogLeadNoteActions.actionClientDialogCreateNoteFailed,
        (state) => ({
            ...state,
            isNoteSubmitting: false,
            isNoteSubmittedSuccessfully: false
        })
    ),
    on(
        clientDialogLeadNoteActions.actionClientDialogLeadNoteStateReset,
        (state) => initialState
    )
)

export const clientDialogLeadNoteReducer = (
    state: ClientDialogLeadNoteState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
