import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadEmailMessage } from 'app/shared/models/lead-communication.model';
import { TenantEmailMessage } from 'app/shared/models/tenant-communication.model';

@Component({
  selector: 'dq-timeline-email',
  templateUrl: './timeline-email.component.html',
  styleUrls: ['./timeline-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    // animation triggers go here
    trigger('fadeInOut', [
      transition('void => *', [
        // :enter is alias to 'void => *'
        style({ opacity: 0, maxHeight: 0 }),
        animate(2000, style({ opacity: 1, maxHeight: 9999 }))
      ]),
      transition('* => void', [
        // :leave is alias to '* => void'
        style({ opacity: 1, maxHeight: 9999, height: '*' }),
        animate(2000, style({ opacity: 0, maxHeight: 0, height: 0 }))
      ])
    ])
  ]
})
export class TimelineEmailComponent implements OnInit, AfterViewInit {


  constructor(public store: Store<State>) { }

  @Input() event: ITimelineElement;


  @Input() displayEmailReply: boolean = false;

  @Output()
  reply = new EventEmitter<LeadEmailMessage | TenantEmailMessage>();

  @Output()
  replyAll = new EventEmitter<LeadEmailMessage | TenantEmailMessage>();

  @Output()
  forward = new EventEmitter<LeadEmailMessage | TenantEmailMessage>();


  isMobile$: Observable<boolean>;

  subject: string = '';

  fromEmail: string;

  ccs: string;

  bccs: string;

  toEmails: string[] = [];

  toEmail: string

  showFullMails: boolean = false;

  emailMessage: LeadEmailMessage | TenantEmailMessage

  @ViewChild('iframe') iframe: ElementRef;

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile));
    this.subject = this.event.emailMessage?.subject;
    this.fromEmail = this.event.emailMessage?.from;
    this.toEmail = this.event.emailMessage?.to;
    this.ccs = this.event.emailMessage?.ccs;
    this.bccs = this.event.emailMessage?.bccs;
    this.emailMessage = this.event.emailMessage;
  }

  /*
  ngAfterViewInit(): void {
    this.iframe?.nativeElement?.contentWindow?.frames?.document?.body?.insertAdjacentHTML('afterbegin', this.event?.emailMessage?.content);
  }
    */

  ngAfterViewInit(): void {
    const blobUrl = this.event?.emailMessage?.content; // Assuming this is the URL to the blob
    if (blobUrl) {
      fetch(blobUrl)
        .then(response => response.text())
        .then(htmlContent => {
          this.iframe?.nativeElement?.contentWindow?.document?.body?.insertAdjacentHTML('afterbegin', htmlContent);
        })
        .catch(error => {
          console.error('Error fetching blob content:', error);
        });
    }
  }

  resizeIFrameToFitContent() {
    setTimeout(() => {
      this.iframe.nativeElement.height = this.iframe?.nativeElement?.contentWindow?.frames?.document?.body?.scrollHeight
    }, 0)

  }

  replyEmail() {
    this.reply.emit(this.emailMessage);
  }

  replyAllEamil() {
    this.replyAll.emit(this.emailMessage);
  }

  forwardEmail() {
    this.forward.emit(this.emailMessage);
  }

  toggleShowMoreLess() {
    this.showFullMails = !this.showFullMails;
    if (this.showFullMails) {
      this.resizeIFrameToFitContent()
    }
  }
}
