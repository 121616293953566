import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { Task, TaskType, TASK_TYPES } from 'app/shared/models/task.model';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { selectTenantDialogTenantId } from '../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { actionTenantDialogTaskStateReset, actionTenantDialogTaskTasksRequested, tenantDialogTaskActions } from '../tenant-dialog-task.action';
import { selectTenantDialogIsTasksLoaded, selectTenantDialogTasks } from '../tenant-dialog-task.selector';
import { TenantDialogTaskCreateDialogComponent } from '../components/tenant-dialog-task-create-dialog/tenant-dialog-task-create-dialog.component';
import { TenantDialogTaskEditDialogComponent } from '../components/tenant-dialog-task-edit-dialog/tenant-dialog-task-edit-dialog.component';

@Component({
  selector: 'dq-tenant-dialog-task',
  templateUrl: './tenant-dialog-task.component.html',
  styleUrls: ['./tenant-dialog-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTaskComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  unsubscribe: Subject<void> = new Subject();

  tenantId$: Observable<number>
  tenantId: number
  tasks$: Observable<Task[]>
  isTasksLoaded$: Observable<boolean>

  taskTypes: TaskType[] = TASK_TYPES

  taskCreateDialogRef: MatDialogRef<TenantDialogTaskCreateDialogComponent>;
  taskEditDialogRef: MatDialogRef<TenantDialogTaskEditDialogComponent>;

  ngOnInit(): void {
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId));
    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      if (tenantId > 0) {
        this.tenantId = tenantId
        this.store.dispatch(actionTenantDialogTaskTasksRequested({ tenantId: tenantId }))
      }
    })

    this.tasks$ = this.store.pipe(select(selectTenantDialogTasks))
    this.isTasksLoaded$ = this.store.pipe(select(selectTenantDialogIsTasksLoaded))
  }

  getTaskTypeInfo(typeValue: string): TaskType {
    let taskType = this.taskTypes.find(type => type.value == typeValue)
    return taskType ? taskType : {
      id: 0,
      name: 'unknown',
      value: 'unknown',
      icon: 'help',
      color: 'black'
    }
  }

  openCreateTaskDialog() {
    this.store.pipe(select(selectScreenManagerIsMobile)).pipe(take(1)).subscribe(isMobile => {
      this.taskCreateDialogRef = this.dialog.open(
        TenantDialogTaskCreateDialogComponent,
        {
          width: isMobile ? '100vw' : '675px',
          height: isMobile ? '100dvh' : '',
          maxWidth: '100vw',
          autoFocus: true,
          panelClass: 'no-padding-dialog-container',
          disableClose: false,
          data: this.tenantId
        }
      )
    })
  }

  openEditTaskDialog(task: Task) {
    this.store.pipe(select(selectScreenManagerIsMobile)).pipe(take(1)).subscribe(isMobile => {
      this.taskEditDialogRef = this.dialog.open(
        TenantDialogTaskEditDialogComponent,
        {
          width: isMobile ? '100vw' : '675px',
          height: isMobile ? '100dvh' : '',
          maxWidth: '100vw',
          autoFocus: true,
          panelClass: 'no-padding-dialog-container',
          disableClose: false,
          data: task
        }
      )
    })
  }

  taskStatusToggle(taskId: number) {
    this.store.dispatch(tenantDialogTaskActions.actionTenantDialogTaskTaskStatusToggle({ taskId }))
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionTenantDialogTaskStateReset())
  }

}
