import { Action, createReducer, on } from '@ngrx/store';
import { actionClientDialogLeadCallFromPhoneNumbersSuccessed } from '../../client-dialog.actions';
import { ClientDialogGeneralInfoState } from '../../client-dialog.state';
import { clientDialogGeneralInfoActions } from './client-dialog-general.action';


export const initialState: ClientDialogGeneralInfoState = {
  leadId: -1,
  leadInfo: {
    id: -1,
    firstName: '',
    lastName: '',
    emails: [],
    username: '',
    phoneNumbers: [],
    phoneNumberCountryCode: '',
    lastCommunicationDate: '',
    interests: '',
    leadSourceId: -1,
    leadSources: [],
    leadBuildings: [],
    leadListTags: [],
    leadAssignedUsers: []
  },
  callFromPhoneNumbers: [],
  isLeadInfoLoaded: false,
  isBaseInfoEditMode: false,
  isBaseInfoSubmitting: false,
  clientDialogLeadsToMerge: {
    isLeadsToMergeLoaded: false,
    leadsToMerge: [],
    leadToMergeIds: [],
    isMergeSubmitting: false,
    isMegerSuccessfully: false
  },
  clientDialogPhoneNumberBlock: {
    isUpdating: false,
    isUpdatedSuccessfully: false
  }
};

const reducer = createReducer(
  initialState,
  on(
    clientDialogGeneralInfoActions.actionClientDialogByLeadIdSuccess,
    (state, { leadInfo }) => ({
      ...state,
      leadId: leadInfo.id,
      leadInfo: leadInfo,
      isLeadInfoLoaded: true,
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogFailed,
    (state) => ({
      ...state,
      isLeadInfoLoaded: true,
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientUserGeneralProfileUpdateSuccessfully,
    (state, { leadInfo }) => ({
      ...state,
      leadInfo: leadInfo,
      isBaseInfoEditMode: false,
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogFailed,
    state => ({
      ...state,
      isLeadInfoLoaded: true
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientUserGeneralProfileChangeIsBaseInfoEditMode,
    (state, { isEditMode }) => ({
      ...state,
      isBaseInfoEditMode: isEditMode
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientUserGeneralProfileUpdateRequested,
    (state) => ({
      ...state,
      isBaseInfoSubmitting: true
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientUserGeneralProfileUpdateSuccessfully,
    clientDialogGeneralInfoActions.actionClientUserGeneralProfileUpdateFailed,
    (state) => ({
      ...state,
      isBaseInfoSubmitting: false
    })
  ),
  on(clientDialogGeneralInfoActions.actionUpdateLeadId, (state, { leadId }) => ({
    ...state,
    leadId: leadId
  })),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeRequested,
    (state) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        isLeadsToMergeLoaded: false
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeLoaded,
    (state, { leadsToMerge }) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        isLeadsToMergeLoaded: true,
        leadsToMerge: leadsToMerge
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeFailed,
    (state) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        isLeadsToMergeLoaded: true,
        isMegerSuccessfully: false,
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeIdsUpdate,
    (state, { leadToMergeIds }) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        leadToMergeIds: leadToMergeIds
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeRequested,
    (state) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        isMergeSubmitting: true
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeFailed,
    (state) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        isMergeSubmitting: false
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeSuccessfully,
    (state) => ({
      ...state,
      clientDialogLeadsToMerge: {
        ...state.clientDialogLeadsToMerge,
        isMergeSubmitting: false,
        isMegerSuccessfully: true
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadsMergeReset,
    (state) => ({
      ...state,
      clientDialogLeadsToMerge: initialState.clientDialogLeadsToMerge
    })
  ),
  on(
    actionClientDialogLeadCallFromPhoneNumbersSuccessed,
    (state, { phoneNumbers }) => ({
      ...state,
      callFromPhoneNumbers: phoneNumbers
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockRequested,
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockRequested,
    (state,) => ({
      ...state,
      clientDialogPhoneNumberBlock: {
        ...state.clientDialogPhoneNumberBlock,
        isUpdating: true,
        isUpdatedSuccessfully: false
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockSuccessfully,
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockSuccessfully,
    (state, { phoneNumberBlockInfo }) => ({
      ...state,
      leadInfo: {
        ...state.leadInfo,
        phoneNumberBlockInfo: phoneNumberBlockInfo
      },
      clientDialogPhoneNumberBlock: {
        ...state.clientDialogPhoneNumberBlock,
        isUpdating: false,
        isUpdatedSuccessfully: true
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockFailed,
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockFailed,
    (state,) => ({
      ...state,
      clientDialogPhoneNumberBlock: {
        ...state.clientDialogPhoneNumberBlock,
        isUpdating: false,
        isUpdatedSuccessfully: false
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockStateReset,
    (state,) => ({
      ...state,
      clientDialogPhoneNumberBlock: {
        ...state.clientDialogPhoneNumberBlock,
        isUpdating: false,
        isUpdatedSuccessfully: false
      }
    })
  ),
  on(
    clientDialogGeneralInfoActions.actionClientDialogGeneralStateReset,
    (state) => initialState
  ),
);

export const clientDialogGeneralInfoReducer = (
  state: ClientDialogGeneralInfoState | undefined,
  action: Action
) => {
  return reducer(state, action);
};
