import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { Task, TaskAssignUser } from "app/shared/models/task.model";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { TenantDialogService } from "../../tenant-dialog.service";
import { State } from "../../tenant-dialog.state";
import { selectTenantDialogTenantId } from "../tenant-dialog-user-general/tenant-dialog-general.selectors";
import { tenantDialogTaskActions, tenantDialogTaskFailActions } from "./tenant-dialog-task.action";

@Injectable()
export class TenantDialogTaskEffects {
    constructor(
        private store: Store<State>,
        private actions$: Actions,
        private tenantDialogService: TenantDialogService,
        private notificationService: NotificationService,
    ) { }

    requestTasks$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTaskTasksRequested
                ),
                switchMap(action =>
                    this.tenantDialogService.getTasksByTenantId(action.tenantId).pipe(
                        map(response => {
                            if (response.status == 1) {
                                let tasks: Task[] = response.data
                                return tenantDialogTaskActions.actionTenantDialogTaskTasksLoaded({ tasks })
                            } else {
                                return tenantDialogTaskFailActions.actionTenantDialogTaskTasksLoadFailed()
                            }
                        }), catchError(error => of(tenantDialogTaskFailActions.actionTenantDialogTaskTasksLoadFailed()))
                    )
                )
            )
    )


    requesttaskAssignUsers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskAssignUsersRequested
                ),
                switchMap(action =>
                    this.tenantDialogService.getTaskAssignUserList().pipe(
                        map(response => {
                            if (response.status == 1) {
                                let taskAssignUsers: TaskAssignUser[] = response.data
                                return tenantDialogTaskActions.actionTenantDialogTasktaskAssignUsersLoaded({ taskAssignUsers })
                            } else {
                                this.notificationService.error(response.message);
                                return tenantDialogTaskFailActions.actionTenantDialogTasktaskAssignUsersFailed()
                            }
                        }), catchError(err => of(tenantDialogTaskFailActions.actionTenantDialogTasktaskAssignUsersFailed()))
                    )
                )
            )
    )


    createTask$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskCreateRequested
                ),
                switchMap(action =>
                    this.tenantDialogService.createTask(action.param).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return tenantDialogTaskActions.actionTenantDialogTasktaskCreateSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return tenantDialogTaskFailActions.actionTenantDialogTasktaskCreateFailed()
                            }
                        }), catchError(err => {
                            return of(tenantDialogTaskFailActions.actionTenantDialogTasktaskCreateFailed())
                        })
                    )
                )
            )
    )

    createTaskSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskCreateSucceed
                ),
                withLatestFrom(
                    this.store.pipe(select(selectTenantDialogTenantId)),
                ),
                switchMap(([action, tenantId]) =>
                    [
                        tenantDialogTaskActions.actionTenantDialogTaskTasksRequested({ tenantId: tenantId })
                    ]
                )
            )
    )

    updateTask$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskUpdateRequested
                ),
                switchMap(action =>
                    this.tenantDialogService.updateTask(action.param).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return tenantDialogTaskActions.actionTenantDialogTasktaskUpdateSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return tenantDialogTaskFailActions.actionTenantDialogTasktaskUpdateFailed()
                            }
                        }), catchError(err => {
                            return of(tenantDialogTaskFailActions.actionTenantDialogTasktaskUpdateFailed())
                        }
                        )
                    )
                )
            )
    )

    updateTaskSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskUpdateSucceed
                ),
                withLatestFrom(
                    this.store.pipe(select(selectTenantDialogTenantId)),
                ),
                switchMap(([action, tenantId]) =>
                    [
                        tenantDialogTaskActions.actionTenantDialogTaskTasksRequested({ tenantId: tenantId })
                    ]
                )
            )
    )

    deleteTask$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskDeleteRequested
                ),
                switchMap(action =>
                    this.tenantDialogService.deleteTask(action.taskId).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return tenantDialogTaskActions.actionTenantDialogTasktaskDeleteSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return tenantDialogTaskFailActions.actionTenantDialogTasktaskDeleteFailed()
                            }
                        }), catchError(err => {
                            return of(tenantDialogTaskFailActions.actionTenantDialogTasktaskDeleteFailed())
                        }
                        )
                    )
                )
            )
    )

    deleteTaskSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTasktaskDeleteSucceed
                ),
                withLatestFrom(
                    this.store.pipe(select(selectTenantDialogTenantId)),
                ),
                switchMap(([action, tenantId]) =>
                    [
                        tenantDialogTaskActions.actionTenantDialogTaskTasksRequested({ tenantId: tenantId })
                    ]
                )
            )
    )

    toggleTaskStatus$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    tenantDialogTaskActions.actionTenantDialogTaskTaskStatusToggle
                ),
                switchMap(action =>
                    this.tenantDialogService.toggleUserTasKComnplateStatus(action.taskId).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return tenantDialogTaskActions.actionTenantDialogTaskTaskStatusToggleSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return tenantDialogTaskFailActions.actionTenantDialogTaskTaskStatusToggleFailed({ taskId: action.taskId })
                            }
                        }), catchError(err => {
                            return of(tenantDialogTaskFailActions.actionTenantDialogTaskTaskStatusToggleFailed({ taskId: action.taskId }))
                        }
                        )
                    )
                )
            )
    )

}