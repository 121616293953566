import { createSelector } from "@ngrx/store";
import { ClientDialogState, ClientDialogRepsState, selectClientDialog } from "../../client-dialog.state";

export const selectClientDialogRepsState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogReps
);

export const selectclientDialogRepsStateIsRepsLoaded = createSelector(
    selectClientDialogRepsState,
    (state: ClientDialogRepsState) => {
        return state.isRepsLoaded
    }
);

export const selectclientDialogRepsStateIsRepOptionsLoaded = createSelector(
    selectClientDialogRepsState,
    (state: ClientDialogRepsState) => {
        return state.isRepOptionsLoaded
    }
);

export const selectclientDialogRepsStateClientReps = createSelector(
    selectClientDialogRepsState,
    (state: ClientDialogRepsState) => {
        return state.clientReps
    }
);

export const selectclientDialogRepsStateRepOptions = createSelector(
    selectClientDialogRepsState,
    (state: ClientDialogRepsState) => {
        return state.repOptions
    }
);

export const selectclientDialogRepsIsRepSubmitting = createSelector(
    selectClientDialogRepsState,
    (state: ClientDialogRepsState) => {
        return state.isRepSubmitting
    }
);

export const selectclientDialogRepsIsCreateUpdateSuccessfully = createSelector(
    selectClientDialogRepsState,
    (state: ClientDialogRepsState) => {
        return state.isUpdateSuccessfully
    }
);  