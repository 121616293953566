import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogBookingEventCalendarState } from "app/features/client-dialog/client-dialog.state";
import { TIME_ZONES } from "app/shared/models/timezone.model";
import * as uuid from "uuid";
import { clientDialogBookingEventCalendarActions, clientDialogBookingEventCalendarFailedActions } from "./client-dialog-booking-calendar.action";

export const initialState: ClientDialogBookingEventCalendarState = {
    isLoaded: false,
    ifBookSucceed: false,
    representative: {
        id: -1,
        firstName: '',
        uri: '',
        lastName: '',
        photoUri: ''
    },
    buildingId: -999,
    buildingAddress: '',
    buildingProperties: [],
    date: new Date().toISOString(),
    timeZoneOptions: TIME_ZONES,
    timeZone: {
        id: 'ET',
        label: 'Eastern Time'
    },
    timeSlot: {
        id: '',
        label: ''
    },
    timeToConfirmId: '',
    event: {
        id: -1,
        representativeId: '',
        name: '',
        duration: 15,
        description: '',
        uri: '',
        externalCalendarRef: '',
        representativeCalendarTypeId: '',
        representativeCalendarType: {
            name: '',
            type: ''
        },
        colorCode: '',
        bookingStartTimeLocal: '',
        bookingEndTimeLocal: ''
    },
    timeSlots: [],
    timeSlotsLoaded: false,
    isSubmitting: false,
    leadSources: [],
    isFormActive: false,
    minCalendarDate: new Date()
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarRequested,
        (state) => ({
            ...state,
            isLoaded: false
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarBookingFormBuildingAddressChange,
        (state, { buildingAddress }) => ({
            ...state,
            isLoaded: true,
            buildingAddress: buildingAddress
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarLoaded,
        (state, { representative, event, buildingId, buildingProperties }) => ({
            ...state,
            isLoaded: true,
            representative: representative,
            event: event,
            buildingId: buildingId,
            buildingProperties: buildingProperties,
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarAvaliablitiesRequested,
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventOverwriteCalendarAvaliablitiesRequested,
        (state) => ({
            ...state,
            timeSlotsLoaded: false
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarAvaliablitiesLoaded,
        (state, { timeSlots }) => ({
            ...state,
            timeSlots: timeSlots.map(x => { return { id: uuid.v4(), label: x } }),
            timeSlotsLoaded: true
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventOverwriteCalendarAvaliablitiesLoaded,
        (state, { timeSlots }) => ({
            ...state,
            timeSlots: timeSlots.map(x => { return { id: uuid.v4(), label: x } }),
            timeSlotsLoaded: true
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarLeadSourcesLoaded,
        (state, { leadSources }) => ({
            ...state,
            leadSources: leadSources,
        })
    ),
    on(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarToggleScheduleOverwrite,
        (state, { isScheduleOverwrite }) => ({
            ...state,
            isScheduleOverwrite: isScheduleOverwrite
        })),
    on(clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarToggleIsFormActive,
        (state, { isFormActive }) => ({
            ...state,
            isFormActive: isFormActive
        })),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarDateChange,
        (state, { date }) => ({
            ...state,
            date: date
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarTimeChange,
        (state, { time }) => ({
            ...state,
            timeSlot: time
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarTimeIdToConfirmChange,
        (state, { id }) => ({
            ...state,
            timeToConfirmId: id
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarChangeMinDate,
        (state, { minDate }) => ({
            ...state,
            minCalendarDate: minDate
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarChangeTimeZone,
        (state, { timeZone }) => ({
            ...state,
            timeZone: timeZone
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarCreateAppointment,
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarUpdateAppointment,
        (state) => ({
            ...state,
            isSubmitting: true
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarCreateAppointmentSuccess,
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarUpdateAppointmentSuccess,
        (state) => ({
            ...state,
            isSubmitting: false,
            ifBookSucceed: true
        })
    ),
    on(
        clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarCreateAppointmentFailed,
        clientDialogBookingEventCalendarFailedActions.actionClientDialogBookingEventCalendarUpdateAppointmentFailed,
        (state) => ({
            ...state,
            isSubmitting: false,
            ifBookSucceed: false
        })
    ),
    on(
        clientDialogBookingEventCalendarActions.actionClientDialogBookingEventCalendarReset,
        (state) => (initialState)
    )
)

export const clientDialogBookingRepresentativeEventCalendarReducer = (
    state: ClientDialogBookingEventCalendarState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
