import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadInfo, Tag } from 'app/shared/models/lead-info-models';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectClientLeadId, selectClientLeadInfo } from '../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogClientLeadPreDefinedTagDeleteRequested, actionClientDialogClientTagDeleteRequested, actionClientDialogLeadTagsRequested, actionClientDialogTagsCreateUpdateSuccessfulStateReset, actionClientDialogTagsReset } from '../client-dialog-lead-tag.action';
import { selectclientDialogTagsStateClientTags, selectclientDialogTagsStateIsTagsLoaded } from '../client-dialog-lead-tag.selector';
import { ClientDialogUserGeneralTagsComponent } from '../components/client-dialog-user-general-tags/client-dialog-user-general-tags.component';
import { MultiPurposeDialogComponent } from 'app/shared/components/multi-purpose-dialog/multi-purpose-dialog.component';

@Component({
  selector: 'dq-client-dialog-lead-tag',
  templateUrl: './client-dialog-lead-tag.component.html',
  styleUrls: ['./client-dialog-lead-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogLeadTagComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) { }

  unsubscribe: Subject<void> = new Subject();
  isMobile$: Observable<boolean>
  isMobile: boolean
  leadInfo$: Observable<LeadInfo>
  leadId$: Observable<number>
  leadId: number
  isTagsLoaded$: Observable<boolean>
  clientTags$: Observable<Tag[]>
  leadTagsEditorDialogRef: MatDialogRef<MultiPurposeDialogComponent>;

  ngOnInit(): void {
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo))
    this.leadId$ = this.store.pipe(select(selectClientLeadId))
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId
      if (leadId > 0) {
        this.store.dispatch(actionClientDialogLeadTagsRequested({ leadId: leadId }))
      }
    })
    this.clientTags$ = this.store.pipe(select(selectclientDialogTagsStateClientTags));
    this.isTagsLoaded$ = this.store.pipe(select(selectclientDialogTagsStateIsTagsLoaded));
  }

  openTagAddEditDialogEdit(tag?: Tag): void {
    this.leadTagsEditorDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: {
        title: 'Add Tags',
        component: ClientDialogUserGeneralTagsComponent,
        dialogData: {
        }
      }
    });

    this.leadTagsEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.store.dispatch(actionClientDialogTagsCreateUpdateSuccessfulStateReset())
    });
  }

  deleteTag(tag: Tag) {
    if (this.leadId > 0) {
      this.store.dispatch(tag.isPreDefined ? actionClientDialogClientLeadPreDefinedTagDeleteRequested({ leadId: this.leadId, tagId: tag.id }) : actionClientDialogClientTagDeleteRequested({ leadId: this.leadId, tagId: tag.id }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionClientDialogTagsReset())
  }
}
