import { createAction, props } from '@ngrx/store';

export const actionTenantDialogCreateNoteRequest = createAction(
    '[Tenant Dialog Note] Create Note Request',
    props<{ id: number, note: string }>()
)

export const actionTenantDialogCreateNoteSuccessed = createAction(
    '[Tenant Dialog Note] Create Note Successed',
)

export const actionTenantDialogCreateNoteFailed = createAction(
    '[Tenant Dialog Note] Create Note Failed',
)

export const actionTenantDialogTenantNoteStateReset = createAction(
    '[Tenant Dialog Note] Tenant Note State Reset',
)

export const tenantDialogTenantNoteActions = {
    actionTenantDialogCreateNoteRequest,
    actionTenantDialogCreateNoteSuccessed,
    actionTenantDialogCreateNoteFailed,
    actionTenantDialogTenantNoteStateReset
};
