import { CommonModule } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';
import {
  faFacebook,
  faGithub,
  faInstagram,
  faMediumM,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faCog,
  faFastBackward,
  faPlayCircle,
  faPowerOff,
  faRocket,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../../environments/environment';
import { AccountIntegrationService } from './account-integration/account-integration.service';
import { AlgoliaQueryService } from './algolia/algolia-query.service';
import { AnimationsService } from './animations/animations.service';
import {
  ROUTE_ANIMATIONS_ELEMENTS,
  routeAnimations
} from './animations/route.animations';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthTwoFactorGuardService } from './auth/auth-two-factor-guard.service';
import { authLogin, authLogout } from './auth/auth.actions';
import { AuthEffects } from './auth/auth.effects';
import { selectAuth, selectIsAuthenticated } from './auth/auth.selectors';
import { RoleGuardService } from './auth/role-guard.service';
import { SignInGuardService } from './auth/sign-in-guard.service';
import { InsightsMonitoringService } from './azure-insights/insights.service';
import { CallManagerEffects } from './call-manager/call-manager.effects';
import { AppState, metaReducers, reducers } from './core.state';
import { CustomIconService } from './custom-icon/custom-icon.service';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { FormService } from './form/form.service';
import { httpInterceptorProviders } from './http-interceptors';
import { LocalStorageService } from './local-storage/local-storage.service';
import { NotificationService } from './notification-manager/notification.service';
import { CustomSerializer } from './router/custom-serializer';
import { ScreenManagerEffects } from './screen-manager/screen-manager.effects';
import { SettingsEffects } from './settings/settings.effects';
import {
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
} from './settings/settings.selectors';
import { SignalRService } from './signalr/signalr.service';
import { TitleService } from './title/title.service';
import { UIEffects } from './ui/ui.effects';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

export {
  TitleService,
  selectAuth,
  authLogin,
  authLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated,
  ROUTE_ANIMATIONS_ELEMENTS,
  AnimationsService,
  AuthGuardService,
  AuthTwoFactorGuardService,
  SignInGuardService,
  NotificationService,
  SignalRService,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}

@NgModule({
  declarations: [],
  exports: [
    // angular
    FormsModule,
    // material
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatButtonModule,
    MatBadgeModule,
    // 3rd party
    FontAwesomeModule,
    TranslateModule
  ],
  imports: [
    // angular
    CommonModule,
    FormsModule,
    // material
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatButtonModule,
    MatBadgeModule,
    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects,
      SettingsEffects,
      CallManagerEffects,
      UIEffects,
      ScreenManagerEffects
    ]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'DashQ',
          connectInZone: true
        }),
    // 3rd party
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    AlgoliaQueryService,
    AuthGuardService,
    AuthTwoFactorGuardService,
    SignalRService,
    CustomIconService,
    FormService,
    InsightsMonitoringService,
    RoleGuardService,
    SignInGuardService,
    AccountIntegrationService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    faIconLibrary: FaIconLibrary
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
    faIconLibrary.addIcons(
      faFastBackward,
      faCog,
      faBars,
      faRocket,
      faPowerOff,
      faUserCircle,
      faPlayCircle,
      faGithub,
      faMediumM,
      faTwitter,
      faInstagram,
      faFacebook,
      faYoutube
    );
  }
}
