<div class="tenant-dialog-tag-component">
    <div class="desktop-container">
        <div class="tenant-dialog-tag-container">
            <div class="mat-dialog-title">
                <h4>
                    {{title}}
                </h4>
                <div (click)="$event.preventDefault();close()">
                    <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
                </div>
            </div>
            <div class="mat-dialog-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="search-div search-input">
                            <mat-icon class="search-icon">search</mat-icon>
                            <input class="search-input contrast-background-color" type="text" placeholder="Search tag"
                                [formControl]="tagNameControl">
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="(isTagOptionsLoaded$ | async); else loading">
                    <ng-container *ngIf="(tagSearchOptions$ | async)">
                        <div class="tag-options-container">
                            <ng-container *ngFor="let tag of (tagSearchOptions$ | async)">
                                <div class="tag-option-div">
                                    <mat-checkbox class="col-12 py-2 px-3" [value]="tag.name"
                                        (change)="changeSelection($event.checked, tag)" [checked]="tag.isChecked">
                                        {{tag.name}}
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #loading>
                    <mat-spinner></mat-spinner>
                </ng-template>
            </div>

            <div class="row">
                <div class="col-6" style="text-align: center;">
                    <button (click)="cancel()" mat-button class="generic-button cancel-button">Cancel</button>
                </div>
                <div class="col-6" style="text-align: center;">
                    <dq-spinner-button class="generic-button" type="button" (click)="save()"
                        [options]="spinnerSaveButtonOptions"></dq-spinner-button>
                </div>
            </div>
        </div>
    </div>
</div>