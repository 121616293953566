
<div class="dq-row">
    <div class="col-sm-12">
        <div class="search-div search-input">
            <mat-icon class="search-icon">search</mat-icon>
            <input class="search-input contrast-background-color" type="text" placeholder="Search Preference"
                [formControl]="preferenceNameControl">
        </div>
    </div>
</div>
<div class="dq-row">
    <div class="col-sm-12">
        <div class="preference-options-container">
            <ng-container *ngIf="(isPreferenceOptionsLoaded$ | async); else loading">
                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Bedrooms
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let preference of preferenceBedOptions">
                            <div class="preference-option-div">
                                <mat-checkbox [value]="preference.value"
                                    (change)="changeSelection($event.checked, preference)"
                                    [checked]="preference.isChecked">
                                    {{preference.label}}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Bathrooms
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let preference of preferenceBathOptions">
                            <div class="preference-option-div">
                                <mat-checkbox [value]="preference.value"
                                    (change)="changeSelection($event.checked, preference)"
                                    [checked]="preference.isChecked">
                                    {{preference.label}}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Parking
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="form-filed-div">
                            <mat-radio-group aria-label="Parking" class="parking-radio-group"
                                [formControl]="parkingFormControl">
                                <ng-container *ngFor="let option of preferenceParkingOptions">
                                    <mat-radio-button class="parking-radio-button"
                                        [value]="option.value">{{option.label}}</mat-radio-button>
                                </ng-container>
                            </mat-radio-group>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Pets
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let preference of preferencePetOptions">
                            <div class="preference-option-div">
                                <mat-checkbox [value]="preference.value"
                                    (change)="changeSelection($event.checked, preference)"
                                    [checked]="preference.isChecked">
                                    {{preference.label}}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Language
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let preference of preferenceLanguageOptions">
                            <div class="preference-option-div">
                                <mat-checkbox [value]="preference.value"
                                    (change)="changeSelection($event.checked, preference)"
                                    [checked]="preference.isChecked">
                                    {{preference.label}}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Accessbility
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="form-filed-div">
                            <mat-radio-group aria-label="Parking" class="parking-radio-group"
                                [formControl]="accessibilityFormControl">
                                <ng-container *ngFor="let option of preferenceAccessbilityOptions">
                                    <mat-radio-button class="parking-radio-button"
                                        [value]="option.value">{{option.label}}</mat-radio-button>
                                </ng-container>
                            </mat-radio-group>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Budget
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="form-filed-div">
                            <mat-form-field appearance="outline">
                                <mat-label>Budget</mat-label>
                                <input matInput cdkNumberDotComma placeholder="Enter Budget"
                                    [formControl]="budgetFormControl" required id="budget">
                                <mat-icon class="dollar-icon" matSuffix svgIcon="dollar"></mat-icon>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="preference-panel"
                        [expanded]="preferencePannelsOpen">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                                Move In Date
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="form-filed-div">
                            <mat-form-field appearance="outline" (click)="moveInDatepicker.open()">
                                <mat-label>Move In Date</mat-label>
                                <input readonly matInput required
                                    [formControl]="moveInDateFormControl"
                                    [matDatepicker]="moveInDatepicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="moveInDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #moveInDatepicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>
<div class="dq-row">
    <div class="col-12" style="text-align: center;">
        <dq-spinner-button class="generic-button" type="button" (click)="save()"
            [options]="spinnerSaveButtonOptions"></dq-spinner-button>
    </div>
</div>