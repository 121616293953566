
export interface UserLanguage {
    id: string
    label: string
}

export const USER_LANGUAGES: UserLanguage[] = [
    {
        id: 'en',
        label: 'English'
    },
    {
        id: 'fr',
        label: 'French'
    }
]