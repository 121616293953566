<div class="client-dialog-lead-merge-component">
    <div class="desktop-container">
        <div class="client-dialog-lead-merge-container">
            <div class="mat-dialog-title">
                <h4>
                    Lead Merge
                </h4>
                <div (click)="$event.preventDefault();close()">
                    <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
                </div>
            </div>
            <div class="mat-dialog-content">
                <div class="lead-merge-options-container">
                    <ng-container *ngIf="(isLeadsToMergeLoaded$ | async); else loading">
                        <ng-container *ngIf="(leadsToMergeOptions$ | async).length > 0; else noLead">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef> Emails </th>
                                    <td mat-cell *matCellDef="let element"> {{element.emails}} </td>
                                </ng-container>

                                <ng-container matColumnDef="phoneNumber">
                                    <th mat-header-cell *matHeaderCellDef> PhoneNumbers </th>
                                    <td mat-cell *matCellDef="let element"> {{element.phoneNumbers}} </td>
                                </ng-container>

                                <ng-container matColumnDef="appointmentCount">
                                    <th mat-header-cell *matHeaderCellDef> Appointments </th>
                                    <td mat-cell *matCellDef="let element"> {{element.appointmentCount}} </td>
                                </ng-container>

                                <ng-container matColumnDef="communicationCount">
                                    <th mat-header-cell *matHeaderCellDef> Communications </th>
                                    <td mat-cell *matCellDef="let element"> {{element.communicationCount}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                    (click)="selection.toggle(row)">
                                </tr>
                            </table>
                        </ng-container>
                        <ng-template #noLead>
                            <h3 class="no-lead-hint">No Lead To Merge</h3>
                        </ng-template>
                    </ng-container>
                    <ng-template #loading>
                        <mat-spinner class="loading-spinner"></mat-spinner>
                    </ng-template>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="text-align: right;">
                    <button (click)="cancel()" mat-button class="generic-button cancel-button">Cancel</button>
                    <dq-spinner-button class="generic-button" type="button" (click)="save()"
                        [options]="spinnerSaveButtonOptions"></dq-spinner-button>
                </div>
            </div>
        </div>
    </div>
</div>
