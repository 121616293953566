import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IndexSearchService } from 'app/core/index-search/index-search.service';
import { debounceTime, map, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { LeadIndex } from '../../models/lead.model';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientDialogComponent } from 'app/features/client-dialog/client-dialog/client-dialog.component';
import { CLIENT_DIALOG_ID } from 'app/features/client-dialog/client-dialog-model';

@Component({
  templateUrl: './index-search.component.html',
  selector: 'dq-index-search',
  styleUrls: ['./index-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class IndexSearchComponent implements OnInit {

  constructor(
    private indexSearchService: IndexSearchService,
    public dialog: MatDialog
  ) {
  }


  leadSearchOptions$: Observable<LeadIndex[]>;
  searchControl = new UntypedFormControl();
  unsubscribe$: Subject<void> = new Subject();
  clientDialogRef: MatDialogRef<ClientDialogComponent>;

  ngOnInit(): void {
    this.leadSearchOptions$ = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        // delay emits
        debounceTime(300),
        // use switch map so as to cancel previous subscribed events, before creating new once
        switchMap(value => {
          if (value !== '' && (typeof value === 'string')) {
            return this.indexSearchService.getLeadsBySearchKeyWord(value, 1, 20).pipe(
              map(result => result.data.objectsList));
          } else {
            // if no value is present, return null
            return of(null);
          }
        })
      );
  }

  openClientDialog(leadId: number): void {
    const width = '75vw';
    this.clientDialogRef = this.dialog.open(ClientDialogComponent, {
      id: CLIENT_DIALOG_ID,
      width,
      maxWidth: '100vw',
      height: '100dvh',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: true,
      data: {
        leadId
      }
    });

  }
}
