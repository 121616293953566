<div class="notification-component">
    <ng-container *ngFor="let notification of (notifications$ | async); let i=index">
        <ng-container *ngIf="notification.type == notification_type_system">
            <div class="notification-item-div">
                <dq-notification-system [notification]="notification" (click)="$event.stopPropagation();"></dq-notification-system>
            </div>
        </ng-container>

        <ng-container *ngIf="i < (notifications$ | async).length -1">
            <mat-divider></mat-divider></ng-container>
    </ng-container>
    <ng-container *ngIf="(notifications$ | async).length == 0">
        <div class="no-notification-hint">
            No Notification
        </div>
    </ng-container>
</div>