import { createSelector } from "@ngrx/store";
import { ClientDialogState, ClientDialogActivitiesState, selectClientDialog } from "../../client-dialog.state";

export const selectClientDialogActivitiesState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogActivities
)

export const selectClienActivities = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.activities
);

export const selectClienIsActivitiesLoaded = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.isActivitiesLoaded
);

export const selectClienIsActivitiesTotalCommunicationRecords = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.communicationTotalecords
);

export const selectClienIsActivitiesCommunicationPageNo = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.communicationPageNo
);

export const selectClienIsActivitiesCommunicationPageSize = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.communicationPageSize
);

export const selectClienIsActivitiesCommunicationIsLoadingMore = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.isLoadingMoreCommunications
);

export const selectClienIsActivitiesLastCommunicationPhoneNumber = createSelector(
    selectClientDialogActivitiesState,
    (state: ClientDialogActivitiesState) => state.lastCommunicatePhoneNumber
);
