import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { TenantDialogService } from "../../tenant-dialog.service";
import { State } from "../../tenant-dialog.state";
import { tenantDialogActivityActions } from "../tenant-dialog-activities/tenant-dialog-activities.action";
import { selectTenantDialogTenantId } from "../tenant-dialog-user-general/tenant-dialog-general.selectors";
import { actionTenantDialogCreateNoteRequest, actionTenantDialogCreateNoteSuccessed, actionTenantDialogCreateNoteFailed } from "./tenant-dialog-tenant-note.action";


@Injectable()
export class TenantDialogTenantNoteEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private tenantDialogService: TenantDialogService,
        private notificationService: NotificationService
    ) { }

    createTenantNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogCreateNoteRequest),
            switchMap(action =>
                this.tenantDialogService.createTenantNote(action.id, action.note).pipe(
                    map((response => {
                        if (response.status == 1) {
                            return actionTenantDialogCreateNoteSuccessed()
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantDialogCreateNoteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(actionTenantDialogCreateNoteFailed())
                    })
                    )
                )
            )
        )
    );

    createTenantNoteSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantDialogCreateNoteSuccessed),
            withLatestFrom(this.store.pipe(select(selectTenantDialogTenantId))),
            switchMap(([action, tenantId]) =>
                [tenantDialogActivityActions.actionTenantDialogAllActivitiesRequest({ tenantId: tenantId, pageNo: 0, pageSize: 20, itemCountPage: 0 })]
            )
        )
    );

}
