import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

@Component({
  selector: 'dq-unit-description-dialog',
  standalone: true,
  imports: [],
  templateUrl: './unit-description-dialog.component.html',
  styleUrl: './unit-description-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitDescriptionDialogComponent {
  constructor(@Inject('dialogData') private dialogData: any,
  ) {
    if (dialogData) {
      this.description = dialogData.desc;
    }
  }

  description: string;
}
