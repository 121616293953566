export const LOST_REASONS: string[] = [
    'Canceled',
    'Inventory/Availability',
    'Unit Issues',
    'Building Issues',
    'Pricing',
    'Rented Elsewhere',
    'Unresponsive',
    'Waitlist',
    "Not Approved",
    'No Show',
]

export interface IUpdateWinStatusParams {
    id: number;
    winPrice: number;
    moveInDate: string
}

export interface IUpdateLostStatusParams {
    id: number;
    lostReason: string;
    comments: string;
}