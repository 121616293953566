import { createSelector, createFeatureSelector } from '@ngrx/store';
import {ScreenManagerState, WindowScreenInfo} from './screen-manager.state';

export const selectScreenManagerState = createFeatureSelector<
  ScreenManagerState
>('screenManager');

export const selectScreenManager = createSelector(
  selectScreenManagerState,
  (state: ScreenManagerState) => state
);

export const selectScreenManagerWindowScreenInfo = createSelector(
  selectScreenManagerState,
  (state: ScreenManagerState) => state.windowScreenInfo
);

export const selectScreenManagerWindowScreenWidth = createSelector(
  selectScreenManagerWindowScreenInfo,
  (state: WindowScreenInfo) => state.windowWidthSize
);

export const selectScreenManagerWindowScreenHeight = createSelector(
  selectScreenManagerWindowScreenInfo,
  (state: WindowScreenInfo) => state.windowHeightSize
);

export const selectScreenManagerIsMobileSize = createSelector(
  selectScreenManagerState,
  (state: ScreenManagerState) => state.isMobileSize
);
export const selectScreenManagerIsMobile = createSelector(
  selectScreenManagerState,
  (state: ScreenManagerState) => state.isMobile
);
