import { Action, createReducer, on } from "@ngrx/store";
import { LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS, LEAD_PREFERENCE_BATH_OPTIONS, LEAD_PREFERENCE_BED_OPTIONS, LEAD_PREFERENCE_LANGUAGE_OPTIONS, LEAD_PREFERENCE_PARKING_OPTIONS, LEAD_PREFERENCE_PET_OPTIONS } from "app/shared/models/lead-preference.model";
import { ClientDialogPreferenceState } from "../../client-dialog.state";
import { clientDialogPreferenceActions } from "./client-dialog-lead-preference.action";

const initialState: ClientDialogPreferenceState = {
    isPreferencesLoaded: false,
    isPreferenceOptionsLoaded: true,
    clientPreferences: [],
    preferenceOptions: [...LEAD_PREFERENCE_BED_OPTIONS, ...LEAD_PREFERENCE_BATH_OPTIONS, ...LEAD_PREFERENCE_PET_OPTIONS, ...LEAD_PREFERENCE_PARKING_OPTIONS, ...LEAD_PREFERENCE_LANGUAGE_OPTIONS, ...LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS],
    isPreferenceSubmitting: false,
    isUpdateSuccessfully: false
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogPreferenceActions.actionClientDialogLeadPreferenceRequested,
        (state) => ({
            ...state,
            isPreferencesLoaded: false
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogLeadPreferenceLoaded,
        (state, { preferences }) => ({
            ...state,
            isPreferencesLoaded: true,
            clientPreferences: preferences
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogLeadPreferenceFailed,
        (state) => ({
            ...state,
            isPreferencesLoaded: true
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceOptionsLoaded,
        (state, { preferences }) => ({
            ...state,
            preferenceOptions: preferences,
            isPreferenceOptionsLoaded: true
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceOptionsFailed,
        (state) => ({
            ...state,
            isPreferenceOptionsLoaded: true
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceUpdate,
        (state, { preferences }) => ({
            ...state,
            isPreferencesLoaded: true,
            clientPreferences: preferences
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceOptionsCheckStateInitialize,
        (state) => ({
            ...state,
            preferenceOptions: state.preferenceOptions.map(x => state.clientPreferences.map(y => y.value).includes(x.value) ? { ...x, isChecked: true } : { ...x, isChecked: false })
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferencePreferenceIsCheckedUpdate,
        (state, { checked, preference }) => ({
            ...state,
            preferenceOptions: state.preferenceOptions.map(x => x.value == preference.value ? { ...x, isChecked: checked } : x)
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceUpdateRequested,
        (state) => ({
            ...state,
            isPreferenceSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogBatchPreferenceAddRequested,
        clientDialogPreferenceActions.actionClientDialogBatchPreferenceRemoveRequested,
        (state) => ({
            ...state,
            isPreferenceSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceUpdateSuccessfully,
        (state, { preferences }) => ({
            ...state,
            isPreferenceSubmitting: false,
            isUpdateSuccessfully: true,
            clientPreferences: preferences
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogBatchPreferenceAddSuccessfully,
        clientDialogPreferenceActions.actionClientDialogBatchPreferenceRemoveSuccessfully,
        (state) => ({
            ...state,
            isPreferenceSubmitting: false,
            isUpdateSuccessfully: true,
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogClientPreferenceDeleteSuccessfully,
        (state, { preferences }) => ({
            ...state,
            clientPreferences: preferences
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceUpdateFailed,
        (state) => ({
            ...state,
            isPreferenceSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogBatchPreferenceAddFailed,
        clientDialogPreferenceActions.actionClientDialogBatchPreferenceRemoveFailed,
        (state) => ({
            ...state,
            isPreferenceSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceCreateUpdateSuccessfulStateReset,
        (state) => ({
            ...state,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogClientPreferenceFailed,
        (state) => ({
            ...state,
            isPreferencesLoaded: true
        })
    ),
    on(
        clientDialogPreferenceActions.actionClientDialogPreferenceReset,
        (state,) => (initialState)
    ),
)

export const clientDialogPreferencesReducer = (
    state: ClientDialogPreferenceState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
