import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { PhoneNavItem } from '../phone-number-menu.model';

@Component({
  selector: 'dq-phone-number-menu',
  templateUrl: './phone-number-menu.component.html',
  styleUrls: ['./phone-number-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PhoneNumberMenuComponent implements OnInit {

  @Input() items: PhoneNavItem[];
  @Output() phoneNumberSelected = new EventEmitter();

  @ViewChild('childMenu', {static: true}) public childMenu: any;



  ngOnInit(): void {
  }

  onPhoneNumberSelected(from: string, to: string) {
    this.phoneNumberSelected.emit({ from: from, to: to });
  }
}
