import { createSelector } from "@ngrx/store";
import { ClientDialogBookingEventCalendarState, ClientDialogState, selectClientDialog } from "app/features/client-dialog/client-dialog.state";

export const selectClientDialogBookingEventCalendarState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => {
    return state.clientDialogBookingeventCalendar
  }
);

export const selectClientDialogBookingEventCalendarStateIsloaded = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.isLoaded;
  }
);

export const selectClientDialogBookingEventCalendarStateRepresentative = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.representative;
  }
);

export const selectClientDialogBookingEventCalendarStateBuildingAddress = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.buildingAddress;
  }
);

export const selectClientDialogBookingEventCalendarStateBuildingId = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.buildingId;
  }
);
export const selectClientDialogBookingEventCalendarStateBuildingProperties = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.buildingProperties;
  }
);

export const selectClientDialogBookingEventCalendarStateEvent = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.event;
  }
);

export const selectClientDialogBookingEventCalendarLeadSources = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.leadSources;
  }
);

export const selectClientDialogBookingEventCalendarIsFormActive = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.isFormActive;
  }
);

export const selectClientDialogBookingEventCalendarMinDate = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.minCalendarDate;
  }
);

export const selectClientDialogBookingEventCalendarStateTimeSlots = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.timeSlots;
  }
);

export const selectClientDialogBookingEventCalendarStateDate = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.date;
  }
);

export const selectClientDialogBookingEventCalendarStateTimeSlot = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.timeSlot;
  }
);

export const selectClientDialogBookingEventCalendarStateTimeIdToConfirm = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.timeToConfirmId;
  }
);

export const selectClientDialogBookingEventCalendarStateCalendarType = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.event.representativeCalendarType.type;
  }
);

export const selectClientDialogBookingEventCalendarStateTimeSlotsLoaded = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.timeSlotsLoaded;
  }
);

export const selectClientDialogBookingEventCalendarStateIsSubmiting = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.isSubmitting;
  }
);

export const selectClientDialogBookingEventCalendarStateTimeZoneOptions = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.timeZoneOptions;
  }
);

export const selectClientDialogBookingEventCalendarStateTimeZone = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.timeZone;
  }
);

export const selectClientDialogBookingEventCalendaIfBookSucceed = createSelector(
  selectClientDialogBookingEventCalendarState,
  (state: ClientDialogBookingEventCalendarState) => {
    return state.ifBookSucceed;
  }
);
