import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { LeadInfo, ILeadToMerge, LeadPhoneNumber } from "../../../../shared/models/lead-info-models";
import { ClientDialogService } from "../../client-dialog.service";
import { State } from "../../client-dialog.state";
import { clientDialogActivityActions } from "../client-dialog-activities/client-dialog-activities.action";
import { actionClientUserGeneralProfileUpdateRequested, actionClientUserGeneralProfileUpdateSuccessfully, actionClientUserGeneralProfileUpdateFailed, clientDialogGeneralInfoActions } from "./client-dialog-general.action";
import { selectClientLeadId } from "./client-dialog-general.selectors";
import { IWebApiResponse } from "app/core/response/response.model";

@Injectable()
export class ClientDialogGeneralEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    updateClientUserGeneralInfos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientUserGeneralProfileUpdateRequested),
            switchMap(action =>
                this.clientDialogService.updateUserGeneralProfile(action.params).pipe(
                    map((response => {
                        if (response.status == 1) {
                            let leadInfo: LeadInfo = response.data
                            return actionClientUserGeneralProfileUpdateSuccessfully({ leadInfo })
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientUserGeneralProfileUpdateFailed()
                        }
                    }),
                        catchError(error => {
                            return of(actionClientUserGeneralProfileUpdateFailed())
                        })
                    )
                )
            )
        )
    );

    actionClientDialogByLeadIdRequired = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogGeneralInfoActions.actionClientDialogByLeadIdRequired),
            switchMap(action =>
                this.clientDialogService
                    .getLeadDetailInfo(action.leadId)
                    .pipe(
                        map((response: IWebApiResponse) => {
                            if (response.status == 1) {
                                return clientDialogGeneralInfoActions.actionClientDialogByLeadIdSuccess({
                                    leadInfo: response.data
                                });
                            } else {
                                this.notificationService.error(response.message);
                                return clientDialogGeneralInfoActions.actionClientDialogFailed();
                            }
                        }),
                        catchError(error =>
                            of(clientDialogGeneralInfoActions.actionClientDialogFailed())
                        )
                    )
            )
        )
    );


    // updateClientTagSuccessfully = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(clientDialogActions.actionClientDialogTagsUpdateSuccessfully),
    //     withLatestFrom(this.store.pipe(select(selectClientLeadId))),
    //     switchMap(([action, leadId]) =>
    //       [clientDialogActions.actionClientDialogClientTagsRequested({ leadId })]
    //     )
    //   )
    // );
    requestClientLeadsToMerge = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeRequested),
            switchMap(action =>
                this.clientDialogService.getLeadsToMerge(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let leadsToMerge = response.data as ILeadToMerge[]
                            return clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeLoaded({ leadsToMerge })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeFailed()
                        }
                    }),
                    catchError(error => of(clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeFailed()))
                )
            )
        )
    );

    mergeClientLeadsToMerge = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeRequested),
            switchMap(action =>
                this.clientDialogService.leadsMergeRequested(action.leadId, action.leadToMergeIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeFailed())
                    })
                )
            )
        )
    );

    mergeLeadsSuccessfully = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogGeneralInfoActions.actionClientDialogLeadsToMergeMergeSuccessfully),
            withLatestFrom(this.store.pipe(select(selectClientLeadId))),
            switchMap(([action, leadId]) =>
                [
                    clientDialogGeneralInfoActions.actionClientDialogByLeadIdRequired({ leadId }),
                    clientDialogActivityActions.actionClientDialogAllActivitiesRequest({ leadId: leadId, pageNo: 0, pageSize: 20, itemCountPage: 0 })
                ]
            )
        )
    );

    leadPhoneNumberBlock = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockRequested),
            switchMap(action =>
                this.clientDialogService.phoneNumberBlock(action.leadId, action.phoneNumber, action.reason).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let phoneNumberBlockInfo: LeadPhoneNumber[] = response.data
                            return clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockSuccessfully({ phoneNumberBlockInfo: phoneNumberBlockInfo ? phoneNumberBlockInfo : [] })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberBlockFailed())
                    })
                )
            )
        )
    );

    leadPhoneNumberUnBlock = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockRequested),
            switchMap(action =>
                this.clientDialogService.phoneNumberUnBlock(action.leadId, action.phoneNumber).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let phoneNumberBlockInfo: LeadPhoneNumber[] = response.data
                            return clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockSuccessfully({ phoneNumberBlockInfo: phoneNumberBlockInfo ? phoneNumberBlockInfo : [] })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogGeneralInfoActions.actionClientDialogLeadPhoneNumberUnBlockFailed())
                    })
                )
            )
        )
    );

}
