

export const REPRESENTATIVE_CALENDAR_TYPES = {
  callWithRepresentative: 'CallWithRepresentative',
  tour: 'Tour',
  virtualBuyer: 'VirtualBuyer',
  developerTour: 'DeveloperTour',
  developerVirtualTour: 'DeveloperVirtualTour',
  mortgageConsultation: 'MortgageConsultation',
  mortgageVirtualConsultation: 'MortgageVirtualConsultation'
}

export const ACCOUNT_TYPES = {
  broker: 'broker',
  developer: 'developer'
}

export const LANGUAGE_OPTIONS = [
  {
    id: 'en',
    label: 'English'
  },
  {
    id: 'fr',
    label: 'French'
  }
]

export const BED_OPTIONS = [
  {
    id: 'bed0',
    label: "Studio",
    count: 0,
    isActive: false
  },
  {
    id: 'bed1',
    label: "1 Bed",
    count: 1,
    isActive: false
  },
  {
    id: 'bed1-den',
    label: "1 Bed + Den",
    count: 1.1,
    isActive: false
  },
  {
    id: 'bed2',
    label: "2 Beds",
    count: 2,
    isActive: false
  },
  {
    id: 'bed2-den',
    label: "2 Beds + Den",
    count: 2.1,
    isActive: false
  },
  {
    id: 'bed3',
    label: "3 Beds",
    count: 3,
    isActive: false
  },
  {
    id: 'bed4',
    label: "4+ Beds",
    count: 4,
    isActive: false
  }
]

export const APPOINTMENT_LEAD_TYPE_OPTIONS = [
  {
    id: 1,
    value: 'buyer',
    label: 'Buyer'
  },
  {
    id: 2,
    value: 'seller',
    label: 'Seller'
  },
  {
    id: 3,
    value: 'renter',
    label: 'Renter'
  },
]

export const BATH_OPTIONS = [
  {
    id: 2,
    value: 1,
    label: '1 bath'
  },
  {
    id: 3,
    value: 2,
    label: '2 baths'
  },
  {
    id: 4,
    value: 3,
    label: '3 baths'
  },
  {
    id: 5,
    value: 4,
    label: '4+ baths'
  },
]


export const TIME_LINE_OPTIONS = [
  {
    id: 1,
    value: '1-3',
    label: '1-3 months'
  },
  {
    id: 2,
    value: '4-6',
    label: '4-6 months'
  },
  {
    id: 3,
    value: '6',
    label: '6 months+'
  }
]

export const APPOINTMENT_SOURCE_OPTIONS = [
  {
    id: 1,
    value: 'book-a-showing',
    label: 'Book a Tour'
  },
  {
    id: 2,
    value: 'make-an-offer',
    label: 'Make an Offer'
  },
  {
    id: 3,
    value: 'featured-building-inquiry',
    label: 'Featured Building Inquiry'
  },
  {
    id: 4,
    value: 'general-inquiry',
    label: 'General Inquiry'
  },
  {
    id: 5,
    value: 'registration',
    label: 'Registration'
  },
]

export const LEAD_SCORE_OPTIONS = [
  {
    id: 1,
    value: 'hot',
    label: 'Hot'
  },
  {
    id: 2,
    value: 'warm',
    label: 'Warm'
  },
]

export const PERSONA_OPTIONS = [
  {
    id: 1,
    value: 'debbie-downsizer',
    label: 'Debbie Downsizer'
  },
  {
    id: 2,
    value: 'sally-for-school',
    label: 'Sally For Shcool'
  },
  {
    id: 3,
    value: 'bryan-the-banker',
    label: 'Bryan the Banker'
  },
  {
    id: 4,
    value: 'robert-the-relocator',
    label: 'Robert the Relocator'
  },
  {
    id: 5,
    value: 'isaac-the-investor',
    label: 'Isaac the Investor'
  },
  {
    id: 6,
    value: 'frank-the-first-timer',
    label: 'Frank the First Timer'
  },
  {
    id: 7,
    value: 'the-jones',
    label: 'The Jones'
  },
  {
    id: 8,
    value: 'dean-the-divorcee',
    label: 'Dean the Divorcee'
  },
]


export const APPOINTMENT_METHOD_OPTIONS = [
  {
    id: 1,
    value: 'call',
    label: 'Call'
  },
  {
    id: 2,
    value: 'text',
    label: 'Text'
  },
  {
    id: 3,
    value: 'email',
    label: 'Email'
  },
]










