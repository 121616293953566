import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { TenantInfo, AssignedUser } from 'app/shared/models/tenant-info-models';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectTenantDialogTenantId, selectTenantDialogTenantInfo } from '../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { actionTenantDialogTenantRepsRequested, actionTenantDialogRepsCreateUpdateSuccessfulStateReset, actionTenantDialogTenantRepDeleteRequested, actionTenantDialogRepsReset } from '../tenant-dialog-tenant-rep.action';
import { selectTenantDialogRepsStateTenantReps, selectTenantDialogRepsStateIsRepsLoaded } from '../tenant-dialog-tenant-rep.selector';
import { TenantDialogUserGeneralRepsComponent } from '../components/tenant-dialog-user-general-reps/tenant-dialog-user-general-reps.component';

@Component({
  selector: 'dq-tenant-dialog-tenant-rep',
  templateUrl: './tenant-dialog-tenant-rep.component.html',
  styleUrls: ['./tenant-dialog-tenant-rep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTenantRepComponent implements OnInit {

  constructor(

    private store: Store<State>,
    public dialog: MatDialog,
  ) { }

  unsubscribe: Subject<void> = new Subject();
  isMobile$: Observable<boolean>
  isMobile: boolean
  tenantInfo$: Observable<TenantInfo>
  tenantId$: Observable<number>
  tenantId: number
  isRepsLoaded$: Observable<boolean>
  tenantReps$: Observable<AssignedUser[]>
  tenantRepsEditorDialogRef: MatDialogRef<TenantDialogUserGeneralRepsComponent>;

  ngOnInit(): void {
    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo))
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId))
    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId
      if (tenantId > 0) {
        this.store.dispatch(actionTenantDialogTenantRepsRequested({ tenantId: tenantId }))
      }
    })
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })
    this.tenantReps$ = this.store.pipe(select(selectTenantDialogRepsStateTenantReps));
    this.isRepsLoaded$ = this.store.pipe(select(selectTenantDialogRepsStateIsRepsLoaded));
  }

  openRepAddEditDialogEdit(rep?: AssignedUser): void {
    this.tenantRepsEditorDialogRef = this.dialog.open(TenantDialogUserGeneralRepsComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
    });

    this.tenantRepsEditorDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.store.dispatch(actionTenantDialogRepsCreateUpdateSuccessfulStateReset())
    });
  }

  deleteRep(repId: number) {
    if (this.tenantId > 0) {
      this.store.dispatch(actionTenantDialogTenantRepDeleteRequested({ tenantId: this.tenantId, repId: repId }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionTenantDialogRepsReset())
  }
}
