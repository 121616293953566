import { createSelector } from "@ngrx/store";
import { TenantDialogState, TenantDialogRepsState, selectTenantDialog } from "../../tenant-dialog.state";

export const selectTenantDialogRepsState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogReps
);

export const selectTenantDialogRepsStateIsRepsLoaded = createSelector(
    selectTenantDialogRepsState,
    (state: TenantDialogRepsState) => {
        return state.isRepsLoaded
    }
);

export const selectTenantDialogRepsStateIsRepOptionsLoaded = createSelector(
    selectTenantDialogRepsState,
    (state: TenantDialogRepsState) => {
        return state.isRepOptionsLoaded
    }
);

export const selectTenantDialogRepsStateTenantReps = createSelector(
    selectTenantDialogRepsState,
    (state: TenantDialogRepsState) => {
        return state.tenantReps
    }
);

export const selectTenantDialogRepsStateRepOptions = createSelector(
    selectTenantDialogRepsState,
    (state: TenantDialogRepsState) => {
        return state.repOptions
    }
);

export const selectTenantDialogRepsIsRepSubmitting = createSelector(
    selectTenantDialogRepsState,
    (state: TenantDialogRepsState) => {
        return state.isRepSubmitting
    }
);

export const selectTenantDialogRepsIsCreateUpdateSuccessfully = createSelector(
    selectTenantDialogRepsState,
    (state: TenantDialogRepsState) => {
        return state.isUpdateSuccessfully
    }
);  