import { storeFreeze } from 'ngrx-store-freeze';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { CallManagerState } from './call-manager/call-manager.model';
import { callManagerReducer } from './call-manager/call-manager.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { NotificationManagerState } from './notification-manager/notification-manager.model';
import { notificationManagerReducer } from './notification-manager/notification-manager.reducer';
import { RouterStateUrl } from './router/router.state';
import { screenManagerReducer } from './screen-manager/screen-manager.reducer';
import { ScreenManagerState } from './screen-manager/screen-manager.state';
import { SettingsState } from './settings/settings.model';
import { settingsReducer } from './settings/settings.reducer';
import { UIState } from './ui/ui.model';
import { uiReducer } from './ui/ui.reducer';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  settings: settingsReducer,
  router: routerReducer,
  ui: uiReducer,
  screenManager: screenManagerReducer,
  callManager: callManagerReducer,
  notification: notificationManagerReducer
}

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage,
  storeFreeze
]

if (!environment.production) {
  if (!environment.test) {
    metaReducers.unshift(debug)
  }
}

export const selectCallManagerState = createFeatureSelector<
  CallManagerState
>(
  'callManager'
)

export const selectSettingsState = createFeatureSelector<
  SettingsState
>('settings')


export const selectNotificationState = createFeatureSelector<
  NotificationManagerState
>('notification')

export const selectUiState = createFeatureSelector< UIState>('ui')

export interface AppState {
  auth: AuthState
  settings: SettingsState
  router: RouterReducerState<RouterStateUrl>
  ui: UIState
  screenManager: ScreenManagerState
  callManager: CallManagerState
  notification: NotificationManagerState
}
