<div class="phone-number-confirm-component">
    <div class="mat-dialog-title">
        <h2>{{title}}</h2>
        <button (click)="$event.preventDefault();close()">
            <mat-icon svgIcon="times" class="cancel"></mat-icon>
        </button>
    </div>
    <div class="delete-content" [innerHTML]="content">
    </div>
    <ng-container *ngIf="updateType === 'Block'">
        <div class="row">
            <div class="col-lg-12 reason-field-div">
                <mat-form-field appearance="outline" class="reason-field">
                    <mat-label>Reason</mat-label>
                    <textarea matInput [formControl]="blockReason" cdkTextareaAutosize required cdkAutosizeMinRows="10"
                        cdkAutosizeMaxRows="15" placeholder="Enter reason" maxlength="2000"></textarea>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-12 button-div">
            <dq-spinner-button class="" type="button" (click)="confirm()"
                [options]="spinnerSaveButtonOptions"></dq-spinner-button>
        </div>
    </div>
</div>
