<div class="tenant-dialog-email-component">
    <div class="desktop-container-email">
        <ng-container *ngIf="!isMicrosoftIntegrated; else connected">
            <div class="connection-div">
                <div class="connection-description">
                    <div>
                        <img class="sign-in-image" [src]="microsoftSignImage" (click)="openMicrosoftIntegration()">
                        <div class="text-hint ">Please connect your Microsoft Office 365 account with
                            Dashq.</div>
                    </div>
                </div>
                <button mat-mini-fab class="refresh-button" (click)="checkMicrosoftConnection()" aria-label="refresh">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </ng-container>

        <ng-template #connected>
            <div class="editor-container">
                <form [formGroup]="formEmail">
                    <div class="row">
                        <div class="col-3">
                            <mat-form-field appearance="outline" style="width:100%">
                                <mat-label>From</mat-label>
                                <mat-select formControlName="sendFromUserId">
                                    <mat-option *ngFor="let option of tenantRepEmails$ | async" [value]="option.userId">
                                        {{option.email}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="!(isForwarding$ | async)">
                            <div class="col-3">
                                <ng-container *ngIf="((emailSendingType$ | async) == 'ReplyAll'); else notReplyAll">
                                    <div class="send-to-container">
                                        <mat-form-field appearance="outline" style="width:100%">
                                            <mat-label>To</mat-label>
                                            <mat-select formControlName="sendToEmails" multiple>
                                                <mat-option *ngFor="let email of replyAllTo$ | async" [value]="email">
                                                    {{email}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <ng-template #notReplyAll>
                                    <div class="send-to-container">
                                        <mat-form-field appearance="outline" style="width:100%">
                                            <mat-label>To</mat-label>
                                            <mat-select formControlName="sendToEmails" multiple>
                                                <mat-option *ngFor="let email of tenantEmails$ | async" [value]="email">
                                                    {{email}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </ng-template>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="(isForwarding$ | async)">
                            <div class="col-3">
                                <div class="send-to-container">
                                    <mat-form-field appearance="outline" style="width:100%">
                                        <mat-label>To</mat-label>
                                        <input matInput formControlName="sendToEmails">
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-lg-6">
                            <div class="subject-container">
                                <mat-form-field appearance="outline" class="email-form-field">
                                    <mat-label>Subject</mat-label>
                                    <input matInput formControlName="subject">
                                </mat-form-field>
                            </div>
                            <div class="toggle-cc-bcc">
                                <span (click)="updateIsCcAndBccActive(!isCcBccVisible)">CC/BCC/Templates</span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="(isCcBccVisible$ | async)">
                        <div class="row">
                            <div class="col-lg-6">
                                <mat-form-field appearance="outline" class="email-form-field">
                                    <mat-label>Cc</mat-label>
                                    <mat-chip-set #sendToCcList aria-label="Ccs" formArrayName="sendToCcs">
                                        <mat-chip selected *ngFor="let sendToCc of sendToCcs.value"
                                            (removed)="removeEmail(sendToCc, 'sendToCcs',sendToCcs.value)">
                                            {{sendToCc}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="New Cc..." [matChipInputFor]="sendToCcList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="addEmail($event, 'sendToCcs', sendToCcs.value)">
                                    </mat-chip-set>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field appearance="outline" class="email-form-field">
                                    <mat-label>Bcc</mat-label>
                                    <mat-chip-set #sendToBccList aria-label="Bccs" formArrayName="sendToBccs">
                                        <mat-chip selected *ngFor="let sendToBcc of sendToBccs.value"
                                            (removed)="removeEmail(sendToBcc, 'sendToBccs',sendToBccs.value)">
                                            {{sendToBcc}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="New Bcc..." [matChipInputFor]="sendToBccList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="addEmail($event, 'sendToBccs', sendToBccs.value)">
                                    </mat-chip-set>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="attachement-container">
                                    <button mat-mini-fab class="edit-template-button"
                                        (click)="openEmailTemplateEditDialogEdit()">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <mat-chip-set #sendToBccList aria-label="Attachments" formArrayName="attachements">
                                        <mat-chip selected *ngFor="let attachement of attachements.value"
                                            (removed)="removeAttachement(attachement,'attachements',attachements.value)">
                                            {{attachement.name}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                    </mat-chip-set>
                                    <button class="attachements-btn" mat-mini-fab (click)="fileInput.click()">
                                        <mat-icon>attach_file</mat-icon>
                                    </button> Attachments
                                    <input hidden
                                        (change)="addAttachement($any($event).target.files, attachements.value)"
                                        multiple #fileInput type="file">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="tenant-profile-templates-container">
                        <button matTooltipPosition="left" matTooltipClass="link-tip" class="m-2 mat-menu-toggle"
                            mat-stroked-button [matMenuTriggerFor]="templates">
                            <span class="d-xl-inline">
                                Templates
                            </span>

                        </button>
                        <mat-menu class="tenant-dialog-email-templates-menu" #templates="matMenu">
                            <div class="d-flex flex-wrap flex-column">
                                <div class="search-div search-input" (click)="$event.stopPropagation()">
                                    <mat-icon class="search-icon">search</mat-icon>
                                    <input class="search-input contrast-background-color" type="text"
                                        placeholder="Search" [formControl]="templateSerachControl">
                                </div>
                                <ng-container *ngIf="(isTemplatesLoaded$ | async); else templatesLoading">
                                    <ng-container *ngFor="let template of (emailTemplateSearchOptions$ | async)">
                                        <div class="template-item" mat-menu-item (click)="applyTemplate(template)">
                                            <span>{{template.displayName}}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #templatesLoading>
                                    <mat-spinner class="tenant-dialog-email-templates-loading-spinner"></mat-spinner>
                                </ng-template>
                            </div>
                        </mat-menu>
                    </div>
                    <editor #tinyMce [apiKey]="tinyMceApiKey" [init]="editorSettings" formControlName="email">
                    </editor>
                </form>
            </div>
            <div class="footer-email">
                <button class="clear-button" mat-stroked-button (click)="emailEditorReset()">
                    <mat-icon>clear_all</mat-icon> Clear
                </button>
                <div class="footer-eamil-right">
                    <ng-container *ngIf="(emailSendingType$ | async) == 'Reply'">
                        <span class="send-type">Replying</span>
                    </ng-container>
                    <ng-container *ngIf="(emailSendingType$ | async) == 'ReplyAll'">
                        <span class="send-type">Replying All</span>
                    </ng-container>
                    <ng-container *ngIf="(emailSendingType$ | async) == 'Forward'">
                        <span class="send-type">Forwarding</span>
                    </ng-container>

                    <ng-container *ngIf="collaborated">
                        <dq-spinner-button class="operate-button" type="button" (click)="sendEmail()"
                            [options]="spinnerSaveButtonOptions"></dq-spinner-button>
                    </ng-container>
                    <ng-container *ngIf="!collaborated">
                        <dq-spinner-button class="operate-button" type="button" [options]="spinnerSaveButtonOptions"
                            [ngClass]="{ 'disabled' : !ifFormEmailValid() }" [matMenuTriggerFor]="sendMailMenu">
                        </dq-spinner-button>
                        <mat-menu class="send-mail-menu-panel" #sendMailMenu="matMenu" yPosition="above">
                            <button mat-menu-item (click)="sendEmail()">
                                <mat-icon>send</mat-icon> Send
                            </button>
                            <button mat-menu-item (click)="sendEmailAndCollaborate()">
                                <mat-icon>send</mat-icon> Send And Collaborate
                            </button>
                        </mat-menu>
                    </ng-container>
                </div>

            </div>
        </ng-template>
    </div>
</div>