import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { DeleteOrRemoveConfirmService } from 'app/shared/components/delete-remove-confirm/delete-or-remove-confirm.service';
import { Task, TaskAssignUser, TaskReminderTimeBeforeOption, TaskReminderType, TaskType, TaskUpdateParam, TASK_REMINDER_TIME_BEFORE_OPTION, TASK_REMINDER_TYPES, TASK_TYPES } from 'app/shared/models/task.model';
import { TimeZone, TIME_ZONES } from 'app/shared/models/timezone.model';

import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionClientDialogTasktaskUpdateRequested, clientDialogTaskActions } from '../../client-dialog-task.action';
import { selectClientDialogTaskAssignUsers, selectClientDialogTaskIsTaskDeletedSuccessfully, selectClientDialogTaskIsTaskDeleting, selectClientDialogTaskIsTaskEditing, selectClientDialogTaskIsTaskEditSuccessfully } from '../../client-dialog-task.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-task-edit-dialog',
  templateUrl: './client-dialog-task-edit-dialog.component.html',
  styleUrls: ['./client-dialog-task-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogTaskEditDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ClientDialogTaskEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    @Inject('dialogData') private dialogData: any,
    private deleteConfirmService: DeleteOrRemoveConfirmService
  ) {
    if (dialogData) {
      this.task = dialogData.task
    }
  }

  dashQTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#70d080'
    },
    dial: {
      dialBackgroundColor: '#70d080',
    },
    clockFace: {
      clockFaceBackgroundColor: '#f0f0f0',
      clockHandColor: '#70d080',
      clockFaceTimeInactiveColor: '#555'
    }
  };

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  spinnerDeleteButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Remove',
    spinnerSize: 18,
    flat: true,    
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'error-contrast-40',
    spinnerCustomClass: 'spinner-error-contrast-80'
  };

  unsubscribe: Subject<void> = new Subject();

  task: Task

  taskTypes: TaskType[] = TASK_TYPES
  taskReminderTypes: TaskReminderType[] = TASK_REMINDER_TYPES
  taskReminderTimeBeforeOptions: TaskReminderTimeBeforeOption[] = TASK_REMINDER_TIME_BEFORE_OPTION

  isEditing$: Observable<boolean>
  isDeleting$: Observable<boolean>
  isEditdSuccessfully$: Observable<boolean>
  isDeleteSuccessfully$: Observable<boolean>

  taskAssignUsers$: Observable<TaskAssignUser[]>

  taskEditForm: UntypedFormGroup

  today: Date = new Date()

  timeZoneOptions: TimeZone[] = TIME_ZONES

  ngOnInit(): void {
    this.taskEditForm = this.formBuilder.group({
      taskId: new UntypedFormControl(this.task.id, [Validators.required]),
      taskName: new UntypedFormControl(this.task.name, [Validators.required]),
      taskType: new UntypedFormControl(this.task.type, [Validators.required]),
      date: new UntypedFormControl(this.task.date, [Validators.required]),
      timeZone: new UntypedFormControl(this.task.timeZone, [Validators.required]),
      time: new UntypedFormControl(this.datePipe.transform(this.task.date, 'h:mm a'), [Validators.required]),
      taskReminderType: new UntypedFormControl(this.task.reminderType, [Validators.required]),
      taskReminderTimeBefore: new UntypedFormControl(this.task.reminderTimeBefore, [Validators.required]),
    })


    this.isEditing$ = this.store.pipe(select(selectClientDialogTaskIsTaskEditing))
    this.isDeleting$ = this.store.pipe(select(selectClientDialogTaskIsTaskDeleting))

    this.isEditdSuccessfully$ = this.store.pipe(select(selectClientDialogTaskIsTaskEditSuccessfully))
    this.isDeleteSuccessfully$ = this.store.pipe(select(selectClientDialogTaskIsTaskDeletedSuccessfully))
    this.taskAssignUsers$ = this.store.pipe(select(selectClientDialogTaskAssignUsers))

    this.isEditing$.pipe(takeUntil(this.unsubscribe)).subscribe(isEditing => {
      this.spinnerSaveButtonOptions.active = isEditing
      this.cdr.markForCheck()
    })

    this.isDeleting$.pipe(takeUntil(this.unsubscribe)).subscribe(isDeleting => {
      this.spinnerDeleteButtonOptions.active = isDeleting
      this.cdr.markForCheck()
    })

    this.isEditdSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isEditdSuccessfully => {
      if (isEditdSuccessfully) {
        this.dialogRef.close(true);
      }
    })
    this.isDeleteSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isDeleteSuccessfully => {
      if (isDeleteSuccessfully) {
        this.dialogRef.close(true);
      }
    })
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    this.taskEditForm.markAllAsTouched()
    if (this.taskEditForm.invalid) {
      return
    }
    let param: TaskUpdateParam = {
      id: this.taskEditForm.get('taskId').value,
      taskName: this.taskEditForm.get('taskName').value,
      taskType: this.taskEditForm.get('taskType').value,
      date: this.taskEditForm.get('date').value,
      time: this.taskEditForm.get('time').value,
      timeZone: this.taskEditForm.get('timeZone').value,
      taskReminderType: this.taskEditForm.get('taskReminderType').value,
      taskReminderTimeBefore: this.taskEditForm.get('taskReminderTimeBefore').value,
    }
    this.store.dispatch(actionClientDialogTasktaskUpdateRequested({ param }))
  }

  delete() {
    if (this.spinnerDeleteButtonOptions.active) {
      return
    }
    this.deleteConfirmService.openDeleteConfrimDialog(
      `Delete task ${this.task.name}`,
      '',
      'Delete',
      'warn',
      this.store.pipe(select(selectClientDialogTaskIsTaskDeleting)),
      this.store.pipe(select(selectClientDialogTaskIsTaskDeletedSuccessfully)),
      clientDialogTaskActions.actionClientDialogTasktaskDeleteRequested({ taskId: this.task.id }),
      [clientDialogTaskActions.actionClientDialogTaskTaskDeleteStateReset()]
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(clientDialogTaskActions.actionClientDialogTaskTaskEditStateReset())
  }


}
