<div class="privacy-policy-component">
    <div class="privacy-policy-container">
        <h1>
            Privacy Policy
        </h1>
        <div style="margin-bottom: 20px;">
            Last updated July 22, 2021
        </div>
        <h3>
            Our Commitment to Privacy
        </h3>
        <p>DashQ is committed to respecting and maintaining the security, confidentiality and privacy of your
            personal information. This Privacy Policy documents our on-going commitment to you and has been developed in
            compliance with the Personal Information Protection and Electronic Documents Act. This Privacy Policy is
            subject to change from time to time.
            This page informs you of our policies regarding the collection, use and disclosure of personal data when you
            use our Service and the choices you have associated with that data.
            We use your data to provide and improve the Service. By using the Service, you agree to the collection and
            use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the
            terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from
            www.dashq.io</p>
        <h3>
            Scope of Privacy Policy
        </h3>
        <p>This Policy applies to DashQ and to its website, dashq.com. This Privacy Policy addresses personal
            information about identifiable individuals and does not apply to the information collected, used or
            disclosed with respect to corporate or commercial entities.
            This Policy does not impose any limits on the collection, use or disclosure of your business contact
            information, and certain publicly available information.
            DashQ operates the website dashq.com, which may hosts public forums, message boards, and other news
            groups available to its users. Information that you disclosed in these areas of the website become public
            information and is not governed or protected by this Privacy Policy.
            DashQ may refer you to third party goods and services providers. These parties will have their own
            respective privacy policies independent of DashQ 's. The information you provide to those third partied
            will be subject to those parties' own privacy policies.</p>
        <h3>
            Collection and Use of Personal Information
        </h3>
        A representative from DashQ may communicate with you by email, phone or text message to provide information about real estate products, services, and/or news which may interest and benefit you. 
        When collecting your personal information, DashQ will always state the purpose of collection and seek your
        consent.
        Most personal information is collected directly from you through:
        <ul>
            <li>the various contracts and other documents you complete (e.g. buyer and/or seller representation
                contracts, agreements of purchase and sale, property disclosure statements, etc.), either with
                DigiBrokerage, a DashQ Team agent, or a DashQ third party goods or services provider,
                discussions with a DashQ representative, or via dashq.com.
            <li>Some information may be collected from other sources such as government departments and agencies (e.g.
                Land Titles/Registry offices, municipal assessment departments, provincial regulators of real estate
                acts).
            <li>Your information may be disclosed to (or accessible by) various real estate boards/associations in
                Canada, their staff and members, other REALTORS® and their clients, government departments and agencies,
                financial institutions, legal advisors, thirds party goods and services providers, provincial real
                estate regulators, and members of the public for the purposes described below.
            <li>Your personal information may be shared with the real estate boards/associations, or their authorized
                representatives, providing data to DashQ.com for the purpose of auditing and monitoring DashQ 's
                compliance with each respective board's/association's rules, regulations, policies and by-laws, the
                security of the data and other legal purposes, related to the use of the boards'/associations' data;
            <li>Not all of your information will be accessible by each of these entities. For example, once a listing
                agreement is terminated, the general public may not have access to your information unless it is
                otherwise accessible through public registries such as a Land Titles Office.
            <li>DashQ and its partners collect, use and disclose your personal information for one or more of the
                following purposes depending you the services your requested from and agreed to with DashQ:
            <li>Helping you connect with a DashQ Team agent or a third party goods and services provider; in which
                case your personal information would be disclosed to such third parties;
            <li>Providing you access to restricted listing data – a requirement of various real estate
                boards/associations;
            <li>Marketing of your property as a seller or property search criteria as a buyer;
            <li>Managing our relationship with you;
            <li>Monitoring and managing the customer service you receive from DashQ and our third party partners;
            <li>To understand customer requirements and honour requests for services or information;
            <li>To manage and develop DashQ 's business and operations;
            <li>To comply with legal, regulatory and contractual obligations;
            <li>Facilitating your purchase and/or sale of a property by cooperating with third party service providers
                such as financial institutions, legal advisors, moving companies, and others;
        </ul>
        <p>
            Complying with legal requirements and acting pursuant to legal authorizations;
        </p>
        <ul>
            <li>Complying with codes of professional conduct and ethics for members of Real Estate Boards/Associations.
            <li>The above collections, uses and disclosures are a necessary part of your relationship with DashQ.
        </ul>
        <p>
            Other uses:
        </p>
        <ul>
            <li>DashQ may communicate with you to determine whether you require additional real estate related
                services.
            <li>DashQ may communicate with you to provide information about other products or services which may
                interest and benefit to you.
            <li>You may instruct DashQ to refrain from using or sharing information in the two ways described above
                under “other uses” at any time by providing written notification to the DashQ Privacy Officer.
                DashQ acknowledges that the sharing of information in the two ways described above is at your option
                and you will not be refused services merely because you advised DashQ to stop using or sharing
                information in these ways.
            <li>When personal information that has been collected is to be used for a purpose not previously identified,
                the new purpose shall be identified prior to use and consent for same shall be obtained from you unless
                the use is authorized or required by law.
        </ul>
        <p>
            In regard to dashq.com:
        </p>
        <p>We may use your IP address to help diagnose problems with our server, and to administer our web site. Your IP
            address may also be used to gather broad, aggregated demographic information.
            Our site may use cookies to make sure you do not see the same ad repeatedly, to deliver content specific to
            your interests, to save your password so you don't have to re-enter it each time you visit our site, and for
            the other purposes set out below:
            to remind us who you are and to find your account information in our database when you access a service so
            you do not need to log in at every visit; this helps us to provide you with service tailored to your
            specific needs and interests; a cookie is created when you register for a service.
            to estimate our audience size; your browser is given a unique cookie that helps us determine whether yours
            is a repeat visit or a first visit.
            to determine the browser the visitor uses so the site can be designed to work properly with the most common
            versions of different browsers.
            Advertisers that place ads with DashQ.com may use cookies.
            Some of our website's features require users to give us their first name, last name, email address, phone
            number, and password. DashQ.com does not use or disclose this personal information for purposes other
            than those for which it was collected, except with the consent of the individual or as required by law.
            DashQ.com retains personal information only as long as necessary for the fulfillment of those purposes
            and as may be limited by contractual obligations.
            This site contains links to third party sites. DashQ.com is not responsible for the privacy practices or
            the content of such web sites. DashQ.com co-branded sites and content partner sites may have privacy
            policies independent of DashQ.com. This Privacy Policy extends only to data collected on web pages
            hosted on DashQ.com servers. DashQ.com may share aggregate information with trading partners,
            co-branded sites, and advertisers. However, this aggregated information does not contain personally
            identifiable information.</p>
        <p>
            Demographic and profile data is used to tailor our visitor's experience at our site and display the content
            according to their preferences. This information is shared with advertisers on an aggregate basis only.
        </p>
        <h3>
            Consent
        </h3>
        <p>
            DashQ will obtain your consent to collect, use or disclose personal information except where DashQ
            is authorized or required by law to do so without consent. For example, DashQ may collect, use or
            disclose personal information without your knowledge or consent where: the information is publicly
            available, as defined by statute or regulation; DashQ is obtaining legal advice; or, DashQ
            reasonably expects that obtaining consent would compromise an investigation or proceeding. Other exceptions
            may apply.
        </p>
        <p>
            Your consent can be express, implied or given through an authorized representative such as a lawyer, agent,
            broker or other 3rd party. Consent may be provided orally, in writing, electronically, through inaction
            (such as when you fail to notify DashQ that you do not wish your personal information
            collected/used/disclosed for various purposes after you have received notice of those purposes) or
            otherwise.
        </p>
        <p>
            You may withdraw consent at any time, subject to legal, contractual and other restrictions, provided that
            you give reasonable notice of withdrawal of consent to DashQ. On receipt of notice of withdrawal of
            consent, DashQ will inform you of the likely consequences of the withdrawal of consent, which may
            include the inability of DashQ to provide certain services for which that information is necessary.
        </p>
        <h3>
            Limits on Collection of Personal Information
        </h3>
        <p>
            DashQ will not collect information indiscriminately and will limit collection of information to that
            which is reasonable and necessary to provide services and which is reasonable and necessary for the purposes
            consented to by you. DashQ will also collect information as authorized or required by law.
        </p>
        <h3>
            Limits for Using, Disclosing and Retaining Personal Information
        </h3>
        <p>
            Your personal information will only be used or disclosed for the purposes set out above or authorized by
            you, and as authorized or required by law.
        </p>
        <p>
            DashQ will keep personal information used to make a decision affecting you for at least one year after
            using it to make the decision.
        </p>
        <p>
            DashQ will destroy, erase or make anonymous documents or other records containing personal information
            as soon as it is reasonable to assume that the original purpose is no longer being served by retention of
            the information and retention is no longer necessary for legal, regulatory, compliance, or business
            purposes.
        </p>
        <p>
            DashQ will take due care when destroying personal information so as to prevent unauthorized access to
            the information.
        </p>
        <h3>
            Accuracy
        </h3>
        <p>
            DashQ will make a reasonable effort to ensure that personal information it is using or disclosing is
            accurate and complete.
        </p>
        <p>
            If you demonstrate the inaccuracy or incompleteness of personal information, DashQ will amend the
            information as required. If appropriate, DashQ will send the amended information to third parties to
            whom the information has been disclosed.
        </p>
        <p>
            When a challenge regarding the accuracy of personal information is not resolved to your satisfaction,
            DashQ will annotate the personal information under its control with a note that the correction was
            requested but not made.
        </p>
        <h3>
            Safeguarding Personal Information
        </h3>
        <p>
            DashQ protects the personal information in its custody or control by making reasonable security
            arrangements based on the sensitivity and format of the information to prevent unauthorized access,
            collection, use, disclosure, copying, modification, disposal, loss, theft, or similar risks.
        </p>
        <p>
            DashQ will take reasonable steps, through contractual or other reasonable means, to ensure that a
            comparable level of personal information protection is implemented by the suppliers and agents who assist in
            providing services.
        </p>
        <p>
            Some specific safeguards include:
        </p>
        <ul>
            <li>physical measures such as locked filing cabinets;
            <li>organizational measures such as restricting employee access to files and databases as appropriate;
            <li>electronic measures such as passwords and firewalls; and
            <li>investigative measures where DashQ has reasonable grounds to believe that personal information is
                being inappropriately collected, used or disclosed.
        </ul>
        <p>
            In order to ensure the safety and privacy of your information, DashQ maintains internal Privacy Policy
            Guidelines and employees are regularly trained on these guidelines.
        </p>
        <p>
            Note that confidentiality and security are not assured when information is transmitted through e-mail or
            other wireless communication.
        </p>
        <p>
            DashQ.com is not marketed to or directed towards children. Nevertheless, it is possible for children to
            publicly post or distribute personally identifiable contact information without prior parental consent
            within chat rooms, forums, message boards, and/or news groups. No communication, however, will be sent to
            anyone or information collected from anyone who indicates that they are younger than 16 years old without
            their parents' permission.
        </p>
        <h3>
            Providing Access
        </h3>
        <p>
            You have a right to access your personal information held by DashQ.
        </p>
        <p>
            Upon written request and authentication of your identity, DashQ will provide you with your personal
            information under its control, information about the ways in which your information is being used and a
            description of the individuals and organizations to whom that information has been disclosed. DashQ may
            charge a reasonable fee for doing so.
        </p>
        <p>
            DashQ will make the information available within 30 days or provide written notice where additional time
            is required to fulfil the request.
        </p>
        <p>
            In some situations, DashQ may not be able to provide access to certain personal information (e.g., if
            disclosure would reveal personal information about another individual, the personal information is protected
            by solicitor/client privilege, the information was collected for the purposes of an investigation or where
            disclosure of the information would reveal confidential commercial information that could harm the
            competitive position of DashQ). DashQ may also be prevented by law from providing access to certain
            personal information.
        </p>
        <p>
            Where an access request is refused, DashQ will notify you in writing, document the reasons for refusal
            and outline further steps which are available to you.
        </p>
        <h3>
            Accountability & Complaints
        </h3>
        <p>
            DashQ has a designated a Privacy Officer who is responsible for DashQ 's compliance with this
            Privacy Policy. Any inquiries, complaints or questions regarding this Privacy Policy should be directed in
            writing to:
        </p>

        <p>Email<br />
            <a href="hello@dashq.com">hello&#64;dashq.com</a><br />
            Subject: PRIVACY OFFICER<br /></p>

        <p>Mail
            Digi Companies<br />
            Attn: PRIVACY OFFICER<br />
            401-1306 Wellington St W<br />
            Ottawa, ON K1Y 3B2<br /></p>
    </div>
</div>