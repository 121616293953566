<div class="rental-request-container">
    <div class="rental-request-title">Start New Rental Application Process</div>
    <div class="rental-request-sub-title">{{inventoryFriendlyName}} - ${{price}}</div>
    <div class="rental-request-fields-container">
        <form [formGroup]="rentalRequestForm">
            <div class="section-title">
                Leasing Dates
            </div>
            <div class="leasing-date-container">
                <mat-form-field appearance="outline">
                    <mat-label>Move-in date</mat-label>
                    <input matInput [min]="minDate" [matDatepicker]="moveInDatePicker" (click)="moveInDatePicker.open()" formControlName="moveInDate" required>
                    <mat-datepicker-toggle matSuffix [for]="moveInDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #moveInDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Lease Start Date</mat-label>
                    <input matInput [min]="minDate" [matDatepicker]="leaseStartDatePicker" (click)="leaseStartDatePicker.open()" formControlName="leaseStartDate" required>
                    <mat-datepicker-toggle matSuffix [for]="leaseStartDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #leaseStartDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Lease End Date</mat-label>
                    <input matInput [min]="minDate" [matDatepicker]="leaseEndDatePicker" (click)="leaseEndDatePicker.open()" formControlName="leaseEndDate" required>
                    <mat-datepicker-toggle matSuffix [for]="leaseEndDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #leaseEndDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="section-title">
                Leasing Rental Items
            </div>
            <div class="leasing-rental-items">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="rentalStorageItems" placeholder="Storage" multiple>
                        <mat-option *ngFor="let rentalStorageItem of rentalStorageItems$ | async" [value]="rentalStorageItem">
                            {{rentalStorageItem.friendlyName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="rentalParkingItems" placeholder="Parking" multiple>
                        <mat-option *ngFor="let rentalParkingItem of rentalParkingItems$ | async" [value]="rentalParkingItem">
                            {{rentalParkingItem.friendlyName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="section-title">
                Representative and Leasing Agreement Language
            </div>
            <div class="leasing-config">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Leasing Representative</mat-label>
                    <mat-select formControlName="leasingRep" name="leasingRep">
                        <mat-option *ngFor="let leasingRep of leasingReps$ | async" [value]="leasingRep.id">
                            {{leasingRep.firstName}} {{leasingRep.lastName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="rentalRequestForm.controls['leasingRep'].hasError('required')">
                        Leasing rep is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Leasing Agreement Language Version</mat-label>
                    <mat-select formControlName="leasingLanguage" name="leasingLanguage">
                        <mat-option *ngFor="let language of USER_LANGUAGES" [value]="language.id">
                            {{language.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="rentalRequestForm.controls['leasingLanguage'].hasError('required')">
                        Leasing Agreement Language Version is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-stroked-button class="submit-btn" [disabled]="!isRentalRequestSubmitActive()" (click)="initNewRentalRequest()">
                        <ng-container *ngIf="(isRentalRequestSending$ | async) == false; else isSending">
                            Start
                        </ng-container>
                        <ng-template #isSending>
                            <mat-spinner diameter="24"></mat-spinner>
                        </ng-template>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
