import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { AppState } from '../core.state';
import { actionCallManagerRegisterSettingsReset } from '../call-manager/call-manager.actions';
import { CALL_AUTH_KEY } from '../call-manager/call-manager.effects';
import { NotificationService, SignalRService } from '../core.module';
import { LocalStorageService } from '../local-storage/local-storage.service';
import {
    authLoginSuccess, authLogout, authRefreshTokenSuccess, authTokenCheck, authTokenCheckSuccess, authTwoFactorAuth,
    authTwoFactorAuthVerify, authTwoFactorAuthVerifyFailed, authTwoFactorAuthVerifyReSendCode,
    authTwoFactorAuthVerifyReSendCodeFailed, authTwoFactorAuthVerifyReSendCodeSuccess, authTwoFactorAuthVerifySuccess
} from './auth.actions';
import { ApplicationToken, IApplicationToken } from './auth.models';
import { selectTokenExpireDate, selectUserName } from './auth.selectors';
import { AuthService } from './auth.service';
import { CallManagerService } from '../call-manager/call-manager.service';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private signalRService: SignalRService,
    private callManagerService: CallManagerService,
    private store: Store<AppState>,
    private ngZone: NgZone,
    private authService: AuthService,
    private notificationService: NotificationService
  ) { }

  mediaStream: MediaStream;

  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginSuccess),
        tap(action => {
          const payload = action.token as IApplicationToken;
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: true,
            token: payload.token,
            refreshToken: payload.refreshToken,
            expires: payload.expires,
            roles: payload.roles,
            profile: {
              userName: payload.userName,
              userId: payload.userId,
              userTimeZone: payload.userTimeZone,
              intercomUser: payload.intercomUser
            }
          });
          this.router.navigate(['index']);
        })
      ),
    { dispatch: false }
  );

  authTwoFactorAuthVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authTwoFactorAuthVerify),
      withLatestFrom(this.store.pipe(select(selectUserName))),
      switchMap(([action, userName]) =>
        this.authService.twoFactorAuthVerify(action.code, userName).pipe(
          map(response => {
            if (response.token) {
              let token: IApplicationToken = response
              return authTwoFactorAuthVerifySuccess({ token })
            } else {
              return authTwoFactorAuthVerifyFailed()
            }
          }),
          catchError(error => of(authTwoFactorAuthVerifyFailed()))
        )
      )
    )
  );

  twoFactorVerify = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authTwoFactorAuth),
        tap(action => {
          const payload = action.token as IApplicationToken;
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false,
            token: payload.token,
            refreshToken: payload.refreshToken,
            expires: payload.expires,
            profile: {
              userName: payload.userName,
              userId: payload.userId,
              userTimeZone: payload.userTimeZone,
              intercomUser: payload.intercomUser
            }
          });
          this.router.navigate(['/sign-in/sms-verification']);
        })
      ),
    { dispatch: false }
  );

  twoFactorVerifySuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authTwoFactorAuthVerifySuccess),
        tap(action => {
          const payload = action.token as IApplicationToken;
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: true,
            token: payload.token,
            refreshToken: payload.refreshToken,
            expires: payload.expires,
            roles: payload.roles,
            profile: {
              userName: payload.userName,
              userId: payload.userId,
              userTimeZone: payload.userTimeZone,
              intercomUser: payload.intercomUser
            }
          });
          this.router.navigate(['index']);
        })
      ),
    { dispatch: false }
  );

  twoFactorVerifyFailed = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authTwoFactorAuthVerifyFailed),
        tap(action => {
          this.notificationService.error('Verify failed')
        })
      ),
    { dispatch: false }
  );

  authTwoFactorAuthVerifyReSendCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authTwoFactorAuthVerifyReSendCode),
      switchMap((action =>
        this.authService.reSendTwoFactorAuthVerificationCode().pipe(
          map(response => {
            if (response.status == 1) {
              return authTwoFactorAuthVerifyReSendCodeSuccess()
            } else {
              this.notificationService.error('Code Send Failed')
              return authTwoFactorAuthVerifyReSendCodeFailed()
            }
          }),
          catchError(error => {
            this.notificationService.error('Code Send Failed')
            return of(authTwoFactorAuthVerifyReSendCodeFailed())
          })
        )
      )
      ))
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.callManagerService.doLogoutAndUnregister();
          this.signalRService.stopConnection();
          this.router.navigate(['sign-in']);
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false
          });
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false
          });
          this.localStorageService.setItem(CALL_AUTH_KEY, {
            isAuthenticated: false
          });
          this.mediaStream?.getTracks().forEach(function (track) {
            track.stop();
          });
        })
      ),
    { dispatch: false }
  );

  logoutResetCallSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authLogout),
      switchMap(action => [
        actionCallManagerRegisterSettingsReset(),
      ])
    )
  )

  isGoingToExpire = createEffect(() =>
    this.actions$.pipe(
      ofType(authTokenCheck),
      withLatestFrom(this.store.pipe(select(selectTokenExpireDate))),
      switchMap(([action, tokenExpireDate]) => {
        const date = new Date();
        const timestampUtcNow: number = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes() + 1,
          date.getUTCSeconds()
        );
        const isGoingToExpire = tokenExpireDate ? new Date(tokenExpireDate) <= new Date(timestampUtcNow) : false;
        if (isGoingToExpire) {
          return this.authService.refreshToken().pipe(
            map((res: any) => {
              this.localStorageService.setItem(AUTH_KEY, {
                isAuthenticated: true,
                token: res.token,
                refreshToken: res.refreshToken,
                expires: res.expires,
                roles: res.roles,
                profile: {
                  userName: res.userName,
                  userId: res.userId,
                  userTimeZone: res.userTimeZone
                }
              })
              return authRefreshTokenSuccess({ token: new ApplicationToken(res) });
            }))
        }
        else {
          return of(authTokenCheckSuccess())
        }
      })
    )
  );
}
