import { createSelector } from "@ngrx/store";
import { TenantDialogState, TenantDialogActivitiesState, selectTenantDialog } from "../../tenant-dialog.state";

export const selectTenantDialogActivitiesState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogActivities
)

export const selectTenantActivities = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.activities
);

export const selectTenantIsActivitiesLoaded = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.isActivitiesLoaded
);

export const selectTenantIsActivitiesTotalCommunicationRecords = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.communicationTotalecords
);

export const selectTenantIsActivitiesCommunicationPageNo = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.communicationPageNo
);

export const selectTenantIsActivitiesCommunicationPageSize = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.communicationPageSize
);

export const selectTenantIsActivitiesCommunicationIsLoadingMore = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.isLoadingMoreCommunications
);

export const selectTenantIsActivitiesLastCommunicationPhoneNumber = createSelector(
    selectTenantDialogActivitiesState,
    (state: TenantDialogActivitiesState) => state.lastCommunicatePhoneNumber
);
