import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { CommunicationTemplate } from "app/features/unified-inbox/unified-inbox.model";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { State } from "../../client-dialog.state";
import { clientDialogActivityActions } from "../client-dialog-activities/client-dialog-activities.action";
import { actionClientDialogLeadRepsRequested } from "../client-dialog-lead-rep/client-dialog-lead-rep.action";
import { selectClientLeadId } from "../client-dialog-user-general/client-dialog-general.selectors";
import { actionClientDialogSendEmailRequest, actionClientDialogSendEmailSuccessed, actionClientDialogSendEmailFailed, actionClientDialogSendEmailAndCollaborateRequest, actionClientDialogSendEmailAndCollaborateSuccessed, actionClientDialogSendEmailAndCollaborateFailed, actionClientDialogEmailTemplateSaveAndUpdate, actionClientDialogEmailTemplateSaveAndUpdateSucceeded, actionClientDialogEmailTemplateSaveAndUpdateFailed, actionClientDialogEmailTemplatesRequest, actionClientDialogEmailTemplatesLoaded, actionClientDialogEmailTemplatesFalied, actionClientDialogEmailTemplateDeleteRequest, actionClientDialogEmailTemplateDeleteSucceeded, actionClientDialogEmailTemplateDeleteFailed, actionClientDialogSendEmailReplyEditorIsForwardingUpdate } from "./client-dialog-email-editor.action";
import { ClientDialogEmailService } from "./client-dialog-email.service";

@Injectable()
export class ClientDialogEmailEditorEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private clientDialogEmailService: ClientDialogEmailService,
        private notificationService: NotificationService
    ) { }

    createLeaEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendEmailRequest),
            switchMap(action =>
                this.clientDialogEmailService.createLeadEmail(action.leadId, action.externalIdentifier, action.emailType, action.text, action.sendFromUserId, action.sendTo, action.sendToCc, action.sendToBcc, action.subject, action.files).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Email sent!')
                            return actionClientDialogSendEmailSuccessed();
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogSendEmailFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogSendEmailFailed())
                        })
                    )
                )
            )
        )
    );

    createLeaEmailAndCollaborate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendEmailAndCollaborateRequest),
            switchMap(action =>
                this.clientDialogEmailService.createLeadEmailAndCollaborate(action.leadId, action.externalIdentifier, action.emailType, action.text, action.sendFromUserId, action.sendTo, action.sendToCc, action.sendToBcc, action.subject, action.files).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Email sent!')
                            return actionClientDialogSendEmailAndCollaborateSuccessed();
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogSendEmailAndCollaborateFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogSendEmailAndCollaborateFailed())
                        })
                    )
                )
            )
        )
    );

    collaborateAndEmailSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendEmailAndCollaborateSuccessed),
            withLatestFrom(this.store.select(selectClientLeadId)),
            switchMap(([action, leadId]) =>
                [
                    actionClientDialogLeadRepsRequested({ leadId: leadId }),
                    actionClientDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false })
                ]
            )
        )
    );

    saveAndUpdateEmailTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogEmailTemplateSaveAndUpdate),
            switchMap(action =>
                this.clientDialogEmailService.saveAndUpdateEmailTemplate(action.friendlyName, action.displayName, action.subject, action.content, action.isAccountShared, action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Saved!')
                            return actionClientDialogEmailTemplateSaveAndUpdateSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogEmailTemplateSaveAndUpdateFailed();
                        }
                    }),
                        catchError(error => {
                            this.notificationService.error('Template Save Failed')
                            return of(actionClientDialogEmailTemplateSaveAndUpdateFailed())
                        })
                    )
                )
            )
        )
    );

    saveAndUpdateEmailTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogEmailTemplateSaveAndUpdateSucceeded),
            switchMap(action =>
                [
                    actionClientDialogEmailTemplatesRequest(),
                ]
            )
        )
    );

    deleteEmailTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogEmailTemplateDeleteRequest),
            switchMap(action =>
                this.clientDialogEmailService.deleteEmailTemplate(action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Deleted!')
                            return actionClientDialogEmailTemplateDeleteSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogEmailTemplateDeleteFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogEmailTemplateDeleteFailed())
                        })
                    )
                )
            )
        )
    );

    deleteEmailTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogEmailTemplateDeleteSucceeded),
            switchMap(action =>
                [
                    actionClientDialogEmailTemplatesRequest(),
                ]
            )
        )
    );


    requestEmailTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogEmailTemplatesRequest),
            switchMap(action =>
                this.clientDialogEmailService.getEmailTemplates().pipe(
                    map((response => {
                        if (response.status == 1) {
                            let templates: CommunicationTemplate[] = response.data
                            return actionClientDialogEmailTemplatesLoaded({ templates });
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogEmailTemplatesFalied();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogEmailTemplatesFalied())
                        })
                    )
                )
            )
        )
    );

    createLeadEmailSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendEmailSuccessed),
            withLatestFrom(this.store.pipe(select(selectClientLeadId))),
            switchMap(([action, leadId]) =>
                [
                    clientDialogActivityActions.actionClientDialogAllActivitiesRequest({ leadId: leadId, pageNo: 0, pageSize: 20, itemCountPage: 0 }),
                    actionClientDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false })
                ]
            )
        )
    );

}
