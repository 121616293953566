import { ChangeDetectionStrategy, Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dq-multi-purpose-dialog',
  templateUrl: './multi-purpose-dialog.component.html',
  styleUrls: ['./multi-purpose-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiPurposeDialogComponent {

  componentInjector: Injector;

  constructor(
    public dialogRef: MatDialogRef<MultiPurposeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector
  ) {
    this.componentInjector = Injector.create({
      providers: [
        { provide: 'dialogData', useValue: data.dialogData },
        { provide: MatDialogRef, useValue: dialogRef }
      ],
      parent: this.injector
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}