import { createSelector } from '@ngrx/store';
import { ClientDialogState, selectClientDialog, ClientDialogLeadMergeState, ClientDialogGeneralInfoState } from '../../client-dialog.state';

export const selectClientDialogGeneralInfoState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => state.clientDialogGeneralInfo
);

export const selectClientLeadId = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.leadId
);

export const selectClientLeadInfoIsLoaded = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.isLeadInfoLoaded
);

export const selectClientLeadInfoIsBaseInfoEditMode = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.isBaseInfoEditMode
);

export const selectClientLeadInfoisBaseInfoSubmitting = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.isBaseInfoSubmitting
);

export const selectClientLeadInfo = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.leadInfo
);

export const selectClientLeadInfoPhoneNumbers = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.leadInfo.phoneNumbers
);

export const selectClientLeadInfoEmails = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => state.leadInfo.emails
);

export const selectClientDialogLeadToMergesState = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => {
    return state.clientDialogLeadsToMerge
  }
);

export const selectclientDialogLeadsToMerge = createSelector(
  selectClientDialogLeadToMergesState,
  (state: ClientDialogLeadMergeState) => {
    return state.leadsToMerge
  }
);

export const selectclientDialogLeadsToMergeIds = createSelector(
  selectClientDialogLeadToMergesState,
  (state: ClientDialogLeadMergeState) => {
    return state.leadToMergeIds
  }
);

export const selectclientDialogLeadsToMergeLoaded = createSelector(
  selectClientDialogLeadToMergesState,
  (state: ClientDialogLeadMergeState) => {
    return state.isLeadsToMergeLoaded
  }
);

export const selectclientDialogLeadsToMergeIsSubmitting = createSelector(
  selectClientDialogLeadToMergesState,
  (state: ClientDialogLeadMergeState) => {
    return state.isMergeSubmitting
  }
);

export const selectclientDialogLeadsToMergeIsMergedSuccessfully = createSelector(
  selectClientDialogLeadToMergesState,
  (state: ClientDialogLeadMergeState) => {
    return state.isMegerSuccessfully
  }
);

export const selectclientDialogLeadCaLLFromPhoneNumbers = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => {
    return state.callFromPhoneNumbers
  }
);

export const selectclientDialogLeadPhoneNumberBlockIsUpdating = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => {
    return state.clientDialogPhoneNumberBlock.isUpdating
  }
);

export const selectclientDialogLeadPhoneNumberBlockIsUpdatedSuccessfully = createSelector(
  selectClientDialogGeneralInfoState,
  (state: ClientDialogGeneralInfoState) => {
    return state.clientDialogPhoneNumberBlock.isUpdatedSuccessfully
  }
);
