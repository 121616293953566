import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogTagsState } from "../../client-dialog.state";
import { clientDialogTagActions } from "./client-dialog-lead-tag.action";

const initialState: ClientDialogTagsState = {
    isTagsLoaded: false,
    isTagOptionsLoaded: false,
    clientTags: [],
    tagOptions: [],
    isTagSubmitting: false,
    isUpdateSuccessfully: false
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogTagActions.actionClientDialogLeadTagsRequested,
        (state) => ({
            ...state,
            isTagsLoaded: false
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogLeadTagsLoaded,
        (state, { tags }) => ({
            ...state,
            isTagsLoaded: true,
            clientTags: tags
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogLeadTagsFailed,
        (state) => ({
            ...state,
            isTagsLoaded: true
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagOptionsLoaded,
        (state, { tags }) => ({
            ...state,
            tagOptions: tags,
            isTagOptionsLoaded: true
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagOptionsFailed,
        (state) => ({
            ...state,
            isTagOptionsLoaded: true
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsUpdate,
        (state, { tags }) => ({
            ...state,
            isTagsLoaded: true,
            clientTags: tags
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagOptionsCheckStateInitialize,
        (state) => ({
            ...state,
            tagOptions: state.tagOptions.map(x => state.clientTags.map(y => y.id).includes(x.id) ? { ...x, isChecked: true } : { ...x, isChecked: false })
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagOptionsCheckStateUnCheckAll,
        (state) => ({
            ...state,
            tagOptions: state.tagOptions.map(x => { return {...x, isChecked: false} })
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsTagIsCheckedUpdate,
        (state, { checked, tag }) => ({
            ...state,
            tagOptions: state.tagOptions.map(x => x.id == tag.id ? { ...x, isChecked: checked } : x)
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsUpdateRequested,
        (state) => ({
            ...state,
            isTagSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogBatchTagsAddRequested,
        clientDialogTagActions.actionClientDialogBatchTagsRemoveRequested,
        (state) => ({
            ...state,
            isTagSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsUpdateSuccessfully,
        (state, { tags }) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: true,
            clientTags: tags
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogBatchTagsAddSuccessfully,
        clientDialogTagActions.actionClientDialogBatchTagsRemoveSuccessfully,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: true,
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogClientTagDeleteSuccessfully,
        clientDialogTagActions.actionClientDialogClientLeadPreDefinedTagDeleteSuccessfully,
        (state, { tags }) => ({
            ...state,
            clientTags: tags
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsUpdateFailed,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogBatchTagsAddFailed,
        clientDialogTagActions.actionClientDialogBatchTagsRemoveFailed,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsCreateUpdateSuccessfulStateReset,
        (state) => ({
            ...state,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogClientTagsFailed,
        (state) => ({
            ...state,
            isTagsLoaded: true
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsTagOptionsDialogStateReset,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: false,
            tagOptions: state.tagOptions
        })
    ),
    on(
        clientDialogTagActions.actionClientDialogTagsReset,
        (state,) => (initialState)
    ),
)

export const clientDialogTagsReducer = (
    state: ClientDialogTagsState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
