import { SettingsState, NIGHT_MODE_THEME } from './settings.model';
import {
  actionSettingsChangeAnimationsElements,
  actionSettingsChangeAnimationsPage,
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeAutoNightMode,
  actionSettingsChangeHour,
  actionSettingsChangeLanguage,
  actionSettingsChangeStickyHeader,
  actionSettingsChangeTheme,
  actionTaskNotificationCountFailed,
  actionTaskNotificationCountSuccess,
  actionTaskNotificationCountUpdateFailed,
  actionTaskNotificationCountUpdateSuccess,
  actionUserLeadConversationCountFailed,
  actionUserLeadConversationCountSuccess,
  actionUserLeadConversationCountUpdateFailed,
  actionUserLeadConversationCountUpdateSuccess,
  actionUserTenantConversationCountFailed,
  actionUserTenantConversationCountSuccess,
  actionUserTenantConversationCountUpdateFailed,
  actionUserTenantConversationCountUpdateSuccess,
  actionUserUpdateConnectionState,
} from './settings.actions';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: SettingsState = {
  language: 'en',
  theme: 'DEFAULT-THEME',
  autoNightMode: false,
  nightTheme: NIGHT_MODE_THEME,
  stickyHeader: true,
  pageAnimations: true,
  pageAnimationsDisabled: false,
  elementsAnimations: true,
  hour: 0,
  connectionState: '',
  notifications: {
    taskNotificationCount: 0,
    userLeadConversationNotificationCount: 0,
    userTenantConversationNotificationCount: 0
  }
};

const reducer = createReducer(
  initialState,
  on(
    actionSettingsChangeLanguage,
    actionSettingsChangeTheme,
    actionSettingsChangeAutoNightMode,
    actionSettingsChangeStickyHeader,
    actionSettingsChangeAnimationsPage,
    actionSettingsChangeAnimationsElements,
    actionSettingsChangeHour,
    (state, action) => ({ ...state, ...action })
  ),
  on(
    actionSettingsChangeAnimationsPageDisabled,
    (state, { pageAnimationsDisabled }) => ({
      ...state,
      pageAnimationsDisabled,
      pageAnimations: false
    })
  ),
  on(
    actionTaskNotificationCountSuccess,
    actionTaskNotificationCountUpdateSuccess,
    (state, { taskCount }) => ({
      ...state,
      notifications: {
        ...state.notifications,
        taskNotificationCount: taskCount
      }
    })
  ),
  on(
    actionTaskNotificationCountFailed,
    actionTaskNotificationCountUpdateFailed,
    (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        taskNotificationCount: -1
      }
    })
  ),
  on(
    actionUserLeadConversationCountSuccess,
    actionUserLeadConversationCountUpdateSuccess,
    (state, { conversationCount }) => ({
      ...state,
      notifications: {
        ...state.notifications,
        userLeadConversationNotificationCount: conversationCount
      }
    })
  ),
  on(
    actionUserLeadConversationCountFailed,
    actionUserLeadConversationCountUpdateFailed,
    (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        userLeadConversationNotificationCount: -1
      }
    })
  ),
  on(
    actionUserTenantConversationCountSuccess,
    actionUserTenantConversationCountUpdateSuccess,
    (state, { conversationCount }) => ({
      ...state,
      notifications: {
        ...state.notifications,
        userTenantConversationNotificationCount: conversationCount
      }
    })
  ),
  on(
    actionUserTenantConversationCountFailed,
    actionUserTenantConversationCountUpdateFailed,
    (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        userTenantConversationNotificationCount: -1
      }
    })
  ),
  on(
    actionUserUpdateConnectionState,
    (state, { connectionState }) => ({
      ...state,
      connectionState: connectionState
    })
  ),
);

export function settingsReducer(
  state: SettingsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
