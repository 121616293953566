<div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngIf="!ifPhoneNumberBlocked(item)">
      <button style="background-color: #ffd1d1;" mat-menu-item (click)="openPhoneNumberDialog(item)">
        <mat-icon>notifications_off</mat-icon>
        <span style="font-weight: 600;"> Block </span> ( <span [dqPhoneNumber]="item"></span> )
      </button>
    </ng-container>
    <ng-container *ngIf="ifPhoneNumberBlocked(item)">
      <button style="background-color: #f5cab1;" mat-menu-item (click)="openPhoneNumberDialog(item)">
        <mat-icon>notifications</mat-icon>
        <span style="font-weight: 600;"> Unblock </span> ( <span [dqPhoneNumber]="item"></span> )
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<ng-container *ngIf="leadInfo$ | async as leadInfo">
  <div class="base-info-container dq-box-shadow contrast-background-color">
    <div class="edit-icon-div">
      <ng-container *ngIf="!(isBaseInfoEditMode$ | async); else baseInfoEdit">
        <button mat-icon-button class="edit-icon" (click)="openLeadMergeDialog()">
          <mat-icon>merge_type</mat-icon>
        </button>
        <button mat-icon-button class="edit-icon" (click)="toggleClientInfoEditMode(true)">
          <mat-icon >create</mat-icon>
        </button>
      </ng-container>
      <ng-template #baseInfoEdit>
        <ng-container *ngIf="(isBaseInfoSubmitting$ | async); else canSave">
          <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #canSave>
          <button mat-icon-button class="edit-icon" (click)="saveChanges()">
            <mat-icon >save</mat-icon>
          </button>
        </ng-template>
        <button mat-icon-button class="edit-icon" (click)="toggleClientInfoEditMode(false)">
          <mat-icon >cancel</mat-icon>
        </button>
      </ng-template>
    </div>
    <!--
        <div class="col-2 profile-image-div">
          <div id="profileImage">
            {{initialName ? initialName : ' '}}
          </div>
        </div>
        -->
    <div class="basic-info">

      <div class="lead-name" [cdkCopyToClipboard]="leadInfo.firstName + ' ' + leadInfo.lastName"
        (cdkCopyToClipboardCopied)="copiedElement = leadInfo.firstName + ' ' + leadInfo.lastName"
        [matTooltip]="copiedElement === leadInfo.firstName + ' ' + leadInfo.lastName? 'Copied!' : 'Copy'"
        matTooltipPosition="right">{{ leadInfo.firstName ?? ' '}} {{ leadInfo.lastName ??
        ''}}</div>

      <div class="subtitle">Created <span class="subtitle-span">{{(leadInfo.createdOn | amFromUtc | amLocal |
          amTimeAgo)}}</span></div>
      <div class="subtitle" *ngIf="leadInfo.lastCommunicationDate">Last Communication <span
          class="subtitle-span">{{(leadInfo.lastCommunicationDate |
          amFromUtc | amLocal | amTimeAgo)}}</span></div>
      <div *ngIf="!(leadInfo.lastCommunicationDate)" class="subtitle">No communication yet</div>
      <div style="padding-bottom: 10px;"></div>
    </div>

    <ng-container *ngIf="!(isBaseInfoEditMode$ | async)">
      <div class="contact-info-container">
        <div class="contact-info-detail">
          <!-- <div class="contact-info-detail-label" *ngFor="let phone of leadInfo.phoneNumbers"
              [ngClass]="{'blocked-phoneNumber' : ifPhoneNumberBlocked(phone), 'unblocked-phoneNumber':  !ifPhoneNumberBlocked(phone)}"
              (cdkCopyToClipboardCopied)="copiedElement = phone" (contextmenu)="onPhoneRightClick($event, phone)"
              [matTooltip]="copiedElement === phone ? 'Copied!' : 'Copy'" [cdkCopyToClipboard]="phone"
              matTooltipPosition="right">
              <mat-icon class="icon-general icon-phone" svgIcon="phone"></mat-icon>
              <span [dqPhoneNumber]="phone"></span>
              <mat-icon class="icon-copy" svgIcon="clone"></mat-icon>
            </div> -->
          <button class="contact-info-detail-button" mat-button *ngFor="let phone of leadInfo.phoneNumbers"
            [ngClass]="{'blocked-phoneNumber' : ifPhoneNumberBlocked(phone), 'unblocked-phoneNumber':  !ifPhoneNumberBlocked(phone)}"
            (cdkCopyToClipboardCopied)="copiedElement = phone" (contextmenu)="onPhoneRightClick($event, phone)"
            [matTooltip]="copiedElement === phone ? 'Copied!' : 'Copy'" [cdkCopyToClipboard]="phone"
            matTooltipPosition="right">
            <mat-icon class="icon-general icon-phone" svgIcon="phone"></mat-icon>
            <span [dqPhoneNumber]="phone" style="margin-right: 15px;"></span>
          </button>
          <!-- <div class="contact-info-detail-label" [cdkCopyToClipboard]="email"
              (cdkCopyToClipboardCopied)="copiedElement = email"
              [matTooltip]="copiedElement === email ? 'Copied!' : 'Copy'" *ngFor="let email of leadInfo.emails"
              matTooltipPosition="right"><mat-icon class="icon-general icon-envelope"
                svgIcon="envelope"></mat-icon> {{email}}
              <mat-icon class="icon-copy" svgIcon="clone"></mat-icon>
            </div> -->
          <button class="contact-info-detail-button" mat-button [cdkCopyToClipboard]="email"
            (cdkCopyToClipboardCopied)="copiedElement = email"
            [matTooltip]="copiedElement === email ? 'Copied!' : 'Copy'" *ngFor="let email of leadInfo.emails"
            matTooltipPosition="right">
            <mat-icon class="icon-general icon-envelope" style="margin-right: 15px;" svgIcon="envelope"></mat-icon>
            {{email}}
          </button>
        </div>
      </div>

      <div class="action-container">
        <button mat-stroked-button [disabled]="!(hasAccessToBooking() | async)" class="action-btn booking-btn"
          (click)="openBookAppointmentDialog()">
          <mat-icon style="margin-left: 5px;">calendar_today</mat-icon> Book
        </button>
        <ng-container
          *ngIf="(callFromPhoneNumbers$ | async).length >= 1 && leadInfo.phoneNumbers.length >= 1; else noPhone">
          <!-- Option one from one to -->
          <ng-container *ngIf="(callFromPhoneNumbers$ | async).length <= 1 && leadInfo.phoneNumbers.length <= 1">
            <button mat-stroked-button
              [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async) || leadInfo.phoneNumbers.length === 0"
              (click)="makeOutgoingCall(callFromPhoneNumbers[0].phoneNumber, leadInfo.phoneNumbers[0])"
              class="action-btn call-btn">
              <mat-icon>call</mat-icon> Call
            </button>
          </ng-container>
          <!-- Option one from many to -->
          <ng-container *ngIf="(callFromPhoneNumbers$ | async).length <= 1 && leadInfo.phoneNumbers.length > 1">
            <button class="action-btn call-btn" mat-stroked-button
              [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async)" [matMenuTriggerFor]="menu">
              <mat-icon>call</mat-icon> Call
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let phone of leadInfo.phoneNumbers;let i = index">
                <button (click)="makeOutgoingCall(callFromPhoneNumbers[0].phoneNumber, phone)" mat-menu-item>
                  <mat-icon>call</mat-icon>
                  <span>{{phone}}</span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
          <!-- Option many from one to -->
          <ng-container *ngIf="(callFromPhoneNumbers$ | async).length > 1 && leadInfo.phoneNumbers.length <= 1">
            <button mat-stroked-button class="action-btn call-btn"
              [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async)" [matMenuTriggerFor]="menu">
              <mat-icon>call</mat-icon> Call
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let phone of (callFromPhoneNumbers$ | async);let i = index">
                <button mat-menu-item (click)="makeOutgoingCall(phone.phoneNumber, leadInfo.phoneNumbers[0])">
                  <mat-icon>call</mat-icon>
                  <span>{{phone.friendlyName}} ({{phone.phoneNumber}})</span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
          <!-- Option many from many to -->
          <ng-container *ngIf="(callFromPhoneNumbers$ | async).length > 1 && leadInfo.phoneNumbers.length > 1">
            <button mat-stroked-button class="action-btn call-btn"
              [disabled]="!(isDeviceRegistered$ | async) || (isCallPanelActive$ | async)"
              [matMenuTriggerFor]="menuRoot">
              <mat-icon>call</mat-icon> Call
            </button>

            <mat-menu #menuRoot="matMenu">
              <ng-container *ngFor="let item of phoneNavItems">
                <!-- Handle branch node buttons here -->
                <ng-container *ngIf="item.children && item.children.length > 0">
                  <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
                    <mat-icon>{{item.iconName}}</mat-icon>
                    <span>{{item.displayName}}</span>
                  </button>
                  <dq-phone-number-menu (phoneNumberSelected)="makeOutgoingCallMultiSelect($event)" #menu
                    [items]="item.children"></dq-phone-number-menu>
                </ng-container>
              </ng-container>
            </mat-menu>

          </ng-container>
        </ng-container>
        <ng-template #noPhone>
          <button mat-stroked-button [disabled]="true" class="action-btn call-btn">
            <mat-icon>call</mat-icon> Call
          </button>
        </ng-template>
      </div>
      <div></div>
    </ng-container>
    <ng-container *ngIf="(isBaseInfoEditMode$ | async)">
      <form class="base-info-form" [formGroup]="leadInfoForm" *ngIf="leadInfo as client">
        <mat-form-field appearance="outline" class="col-6">
          <mat-label>{{'cdk.client-dialog-user-profile.firstName' | translate}}</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-6">
          <mat-label>{{'cdk.client-dialog-user-profile.lastName' | translate}}</mat-label>
          <input matInput formControlName="lastName">
        </mat-form-field>
        <ng-container formArrayName="emails" *ngFor="let email of emailArray.controls; let emailIndex = index">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>{{'cdk.client-dialog-user-profile.email' | translate}}</mat-label>
            <input #emailInput matInput placeholder="Email" [formControlName]="emailIndex" requiredid="email">
            <span matSuffix class="remove-text" *ngIf="emailIndex > 0" (click)="removeEmail(emailIndex)">remove</span>
          </mat-form-field>
        </ng-container>
        <div class="more-recipient-container" *ngIf="emailArray.length < 4">
          <button mat-stroked-button class="more-recipient" (click)="addEmail()">
            add email +
          </button>
        </div>
        <ng-container formArrayName="phoneNumbers"
          *ngFor="let phoneNumber of phoneArray.controls; let phoneIndex = index">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>{{'cdk.client-dialog-user-profile.phone' | translate}}</mat-label>
            <ngx-mat-intl-tel-input #phoneInput (countryChanged)="countryChanged($event)" [cssClass]="'custom'"
              [preferredCountries]="['ca', 'us']" placeholder="Enter Number" [enablePlaceholder]="false"
              [enableSearch]="true" [formControlName]="phoneIndex">
            </ngx-mat-intl-tel-input>
            <span matSuffix class="remove-text" *ngIf="phoneIndex > 0" (click)="removePhone(phoneIndex)">remove</span>
          </mat-form-field>
        </ng-container>
        <div class="more-recipient-container" *ngIf="phoneArray.length < 4">
          <button mat-stroked-button class="more-recipient" (click)="addPhone()">
            add phoneNumber +
          </button>
        </div>
        <ng-container *ngIf="leadInfoForm.hasError('atLeastOne')">
          <div class="alert alert-danger col-12">
            Email and Phone Number at least one is required.
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>

  <div class="tasks-info-container dq-box-shadow contrast-background-color">
    <dq-client-dialog-task></dq-client-dialog-task>
  </div>

  <div class="reps-info-container dq-box-shadow contrast-background-color">
    <dq-client-dialog-lead-rep></dq-client-dialog-lead-rep>
  </div>

  <div class="tags-info-container dq-box-shadow contrast-background-color">
    <dq-client-dialog-lead-tag></dq-client-dialog-lead-tag>
  </div>

  <div class="buildings-info-container dq-box-shadow contrast-background-color">
    <dq-client-dialog-lead-building></dq-client-dialog-lead-building>
  </div>

  <div class="preferences-info-container dq-box-shadow contrast-background-color">
    <dq-client-dialog-lead-preference></dq-client-dialog-lead-preference>
  </div>

  <div class="sources-info-container dq-box-shadow contrast-background-color">
    <div class="dq-row">
      <div>
        <mat-icon class="icon-general" svgIcon="browser"></mat-icon>
      </div>
      <div>
        <h4 class="title">Sources</h4>
      </div>
    </div>
    <div class="dq-row items-container">
      <div class="sources-container">
        <ng-container *ngFor="let source of (leadInfo$ | async).leadSources">
          <div class="source-div-wrapper">
            <div class="source-div"> {{source.name}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>