import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { Store } from '@ngrx/store';
import { Notification, NotificationTypes } from '../notification-manager/notification-manager.model';
import * as uuid from 'uuid';
import { actionNotificationManagerAddNewNotification } from '../notification-manager/notification-manager.actions';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private injector: Injector
  ) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    let displayMessage = 'An error occurred.';

    if (!environment.production) {
      displayMessage += ' See console for details.';
    }

    // this.notificationsService.error(displayMessage);
    let notification: Notification = {
      id: uuid.v4(),
      title: 'System Error',
      content: error.message,
      date: new Date(),
      type: NotificationTypes.SYSTEM
    }
    const store = this.injector.get(Store);
    store.dispatch(actionNotificationManagerAddNewNotification({ notification }));
    super.handleError(error);
  }
}
