import { createSelector } from '@ngrx/store';
import { ClientDialogState, selectClientDialog } from '../../client-dialog.state';

export const selectClientDialogInventoryeState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => {
        return state.clientDialogInventory
    }
);

export const selectClientDialogInventoryBuildings = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.buildings
    }
)

export const selectClientDialogInventoryBuildingOptions = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.buildingOptions
    }
)

export const selectClientDialogInventoryBuildingPropertyOptions = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.buildingPropertyOptions
    }
)

export const selectClientDialogInventoryBuildingUnits = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.buildingUnits
    }
)

export const selectClientDialogInventoryUnits = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.units
    }
)

export const selectClientDialogInventoryIsUnitsLoaded = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.isUnitsLoaded
    }
)

export const selectClientDialogInventoryIsBuildingUnitsLoaded = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.isBuildingUnitsLoaded
    }
)

export const selectClientDialogInventoryisPropertiesLoaded = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.isPropertiesLoaded
    }
)

export const selectClientDialogInventoryUnitsQueryFilterIsMadeReady = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.baseFilters.isMadeReady
    }
)
export const selectClientDialogInventoryUnitsQueryFilterIsNotReady = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.baseFilters.isNotReady
    }
)

export const selectClientDialogInventoryUnitsQueryFilterIsReserved = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.baseFilters.isReserved
    }
)

export const selectClientDialogInventoryQueryFilterBedOptions = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.queryFilterOptions.bedOptions
    }
)

export const selectClientDialogInventoryQueryFilterBathOptions = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.queryFilterOptions.bathOptions
    }
)

export const selectClientDialogInventoryUnitsQueryFilter = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter
    }
)


export const selectClientDialogInventoryUnitsQueryFilterMoreFilters = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.moreFilters
    }
)

export const selectClientDialogInventoryUnitsQueryFilterBasicFilters = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.baseFilters

    }
)

export const selectClientDialogInventoryUnitsQueryFilterBuildingIds = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.baseFilters.buildingIds
    }
)

export const selectClientDialogInventoryUnitsQueryFilterBuildingPropertyId = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsQueryFilter.baseFilters.buildingPropertyIds
    }
)


export const selectClientDialogInventoryBuildingUnitsQueryFilterBuildingId = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.buildingsUnitsQueryFilterBuildingId
    }
)

export const selectClientDialogInventoryAreaUnitsQueryPaginator = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsPaginator
    }
)

export const selectClientDialogInventoryAreaUnitsTotalRecords = createSelector(
    selectClientDialogInventoryeState,
    state => {
        return state.inventoryUnits.unitsTotalRecords
    }
)

