import { Action, createReducer, on } from "@ngrx/store";
import { TenantDialogActivitiesState } from "../../tenant-dialog.state";
import { tenantDialogActivityActions } from "./tenant-dialog-activities.action";

export const initialState: TenantDialogActivitiesState = {
  activities: [],
  isActivitiesLoaded: false,
  communicationTotalecords: 0,
  communicationPageNo: 1,
  communicationPageSize: 30,
  isLoadingMoreCommunications: false,
  lastCommunicatePhoneNumber: ''
}

export const reducer = createReducer(
  initialState,
  on(
    tenantDialogActivityActions.actionTenantDialogAllActivitiesRequest,
    (state, { tenantId: tenantId, pageNo, pageSize, isLoadMore }) => ({
      ...state,
      isActivitiesLoaded: isLoadMore ? true : false,
      activities: isLoadMore ? state.activities : initialState.activities,
      isLoadingMoreCommunications: true
    })
  ),
  on(
    tenantDialogActivityActions.actionTenantDialogAllActivitiesSuccessed,
    (state, { activities, pageNo, pageSize, totalRecords }) => ({
      ...state,
      isActivitiesLoaded: true,
      activities: state.activities.concat(activities).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
      lastCommunicatePhoneNumber: state.activities.concat(activities).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).find(x => x.type == 'Text' || x.type == 'Voice')?.phoneNumber,
      isLoadingMoreCommunications: false,
      communicationPageNo: pageNo,
      communicationPageSize: pageSize,
      communicationTotalecords: totalRecords
    })
  ),
  on(
    tenantDialogActivityActions.actionTenantDialogAllActivitiesFailed,
    (state) => ({
      ...state,
      isActivitiesLoaded: true,
      isLoadingMoreCommunications: false
    })
  ),
  on(
    tenantDialogActivityActions.actionTenantDialogActivityStateReset,
    (state) => initialState
  )
)

export const tenantDialogActivityReducer = (
  state: TenantDialogActivitiesState | undefined,
  action: Action
) => {
  return reducer(state, action);
}

