import {ActionReducer} from '@ngrx/store';

import {AppState} from '../core.state';
import {environment} from '../../../environments/environment';

export function debug(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function (state, action) {
    const newState = reducer(state, action);
    if (environment.printActions)
      console.log(`[DEBUG] action: ${action.type}`, {
        payload: (<any>action).payload,
        oldState: state,
        newState
      });
    return newState;
  };
}
