<div class="timeline-appointment-component">
    <div class="desktop-container">
        <section>
            <article class="timeline-content">
                <header class="header">
                    <mat-icon class="timeline-icon date_range">date_range</mat-icon>
                    <div class="header-meta">
                        <strong>
                            Appointment with {{repName}} on {{startTime}} - {{timeZone}}
                        </strong>
                        <em>
                            {{event.date|date: 'medium'}}
                        </em>
                    </div>
                </header>
                <div class="app-detail"
                    [ngClass]="{'app-win': appointmentStatusType === 'Won', 'app-lost' : appointmentStatusType === 'Lost', 'app-in-application' : appointmentStatusType === 'InApplication' }">
                    <ng-container *ngIf="appointmentStatusType == 'InApplication'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Hot</span></div>
                    </ng-container>
                    <ng-container *ngIf="appointmentStatusType == 'InProgress'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Active</span></div>
                    </ng-container>
                    <ng-container *ngIf="appointmentStatusType == 'Terminated'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Terminated / Canceled</span></div>
                    </ng-container>
                    <ng-container *ngIf="appointmentStatusType == 'Won'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Won</span></div>
                        <div class="app-detail-item">Winning price: <span class="app-detail-item-value">${{winPrice
                                | number:'2.'}}</span></div>
                        <div class="app-detail-item">Closing date: <span class="app-detail-item-value">{{closingDate | date: 'MMM d, y'}}</span></div>
                    </ng-container>
                    <ng-container *ngIf="appointmentStatusType == 'Lost'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Lost</span></div>
                        <div class="app-detail-item">Lost reason: <span
                                class="app-detail-item-value">{{lostReason}}</span></div>
                        <div class="app-detail-item">Lost comment: <span
                                class="app-detail-item-value">{{lostComments}}</span></div>
                        <div class="app-detail-item">Lost date: <span
                                class="app-detail-item-value">{{closingDate}}</span></div>
                    </ng-container>
                    <div class="app-detail-item">Building Address : <span
                            class="app-detail-item-value">{{buildingAddress}}</span></div>
                    <div class="app-detail-item">Move In Date: <span class="app-detail-item-value">{{moveInDate | date: 'MMM d, y'}}</span></div>
                    <div class="app-detail-item">Bed Preference: <span
                            class="app-detail-item-value">{{bedPreference}}</span></div>
                    <div class="app-detail-item">Bath Preference: <span
                            class="app-detail-item-value">{{bathPreference}}</span></div>
                    <div class="app-detail-item">Parking Preference: <span
                            class="app-detail-item-value">{{parkingPreference}}</span></div>
                    <div class="app-detail-item">Pet Preference: <span
                            class="app-detail-item-value">{{petPreference}}</span></div>                                                        
                    <div class="app-detail-item">Budget: <span class="app-detail-item-value">{{budget | number: '2.'}}</span></div>
                    <div class="app-detail-item">Note: <span class="app-detail-item-value">{{note}}</span></div>
                </div>
            </article>
        </section>
    </div>
</div>