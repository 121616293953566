import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { TenantBuilding } from "../../../../shared/models/tenant-info-models";
import { TenantDialogService } from "../../tenant-dialog.service";
import { tenantDialogBuildingActions } from "./tenant-dialog-tenant-building.action";

@Injectable()
export class TenantDialogBuildingsEffects {
    constructor(
        private actions$: Actions,
        private tenantDialogService: TenantDialogService,
        private notificationService: NotificationService
    ) { }

    requestTenantBuilding = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogBuildingActions.actionTenantDialogTenantBuildingRequested),
            switchMap(action =>
                this.tenantDialogService.getTenantBuilding(action.tenantId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let building = response.data as TenantBuilding
                            return tenantDialogBuildingActions.actionTenantDialogTenantBuildingLoaded({ building })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogBuildingActions.actionTenantDialogTenantBuildingFailed()
                        }
                    }),
                    catchError(error => of(tenantDialogBuildingActions.actionTenantDialogTenantBuildingFailed()))
                )
            )
        )
    );
}