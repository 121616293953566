import { createSelector } from "@ngrx/store";
import { selectTenantDialog, TenantDialogState, TenantDialogTenantNoteState } from "../../tenant-dialog.state";

export const selectTenantDialogTenantNoteState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogTenantNote
);

export const selectTenantDialogIsNoteSubmitting = createSelector(
    selectTenantDialogTenantNoteState,
    (state: TenantDialogTenantNoteState) => state.isNoteSubmitting
);

export const selectTenantDialogIsNoteSubmittedSuccessfully = createSelector(
    selectTenantDialogTenantNoteState,
    (state: TenantDialogTenantNoteState) => state.isNoteSubmittedSuccessfully
);
