<div class="dq-row">
    <div class="col-sm-12">
        <div class="search-div search-input">
            <mat-icon class="search-icon">search</mat-icon>
            <input class="search-input contrast-background-color" type="text" placeholder="Search building"
                [formControl]="buildingNameControl">
        </div>
    </div>
</div>
<ng-container *ngIf="(isBuildingOptionsLoaded$ | async); else loading">
    <ng-container *ngIf="(buildingSearchOptions$ | async)">
        <div class="dq-row">
            <div class="col-12">
                <div class="building-options-container">
                    <ng-container *ngFor="let building of (buildingSearchOptions$ | async)">
                        <div class="building-option-div">
                            <mat-checkbox class="col-12" [value]="building.name"
                                (change)="changeSelection($event.checked, building)" [checked]="building.isChecked">
                                {{building.name}}
                            </mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>

<div class="dq-row">
    <div class="col-12" style="text-align: center;">
        <dq-spinner-button class="generic-button" type="button" (click)="save()"
            [options]="spinnerSaveButtonOptions"></dq-spinner-button>
    </div>
</div>