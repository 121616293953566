<div class="timeline-voice-component">
    <div class="desktop-container">
        <section>
            <article class="timeline-content">
                <header class="header">
                    <mat-icon class="timeline-icon call">call</mat-icon>
                    <div class="header-meta">
                        <span class="header-strong">Call</span> from <span class="header-strong">
                            {{msgFrom}}
                        </span> to 
                        <span class="header-strong">
                            {{msgTo}}
                        </span>
                        <div class="header-date">
                            <em>
                                {{event.date|date: 'medium'}}
                            </em>
                        </div>
                    </div>
                </header>
                <div>
                    <div>
                        <span class="duration-text">Duration: {{duration}}</span>
                        <ng-container *ngIf="event.voiceMessage?.transcription">
                            <div class="voice-transcription-title">Voice mail transcription:</div>
                            <div class="voice-transcription">{{event.voiceMessage?.transcription}}</div>
                        </ng-container>
                    </div>
                    
                    <ng-container *ngIf="(ifVoiceAvailable); else noVoiceRecord">
                        <div>
                            <ng-container *ngIf="this.event.voiceMessage?.recordUrl">
                                <audio controls>
                                    <source [src]="voiceRecord" type="audio/mpeg">
                                    Your browser does not support the audio tag.
                                </audio>
                            </ng-container>
                            <ng-container *ngIf="event.voiceMessage?.voiceMailUrl">
                                <audio controls>
                                    <source [src]="voiceMail" type="audio/mpeg">
                                    Your browser does not support the audio tag.
                                </audio>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #noVoiceRecord>
                        <p>No recording.</p>
                    </ng-template>
                </div>
            </article>
        </section>
    </div>
</div>