import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BuildingWeeklyAvailability, IBuilding } from '../../client-dialog-inventory.model';
import {
  clientDialogBookingBuildingAvailabilityActions,
  clientDialogBookingBuildingAvailabilityFailedActions
} from './client-dialog-booking-building-avaliabilty.action';
import {
  selectClientDialogBookingStateBuildingAvailabilityBuildingSelectedId
} from './client-dialog-booking-building-avaliabilty.selector';
import { ClientDialogInventoryBookingService } from '../../client-dialog-booking-building.service';
import { NotificationService } from 'app/core/core.module';
import { BookingCalendar } from '../../../../../../shared/models/booking-calendar.model';

@Injectable()
export class ClientDialogBookingBuildingAvailabilityEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private clientDialogInventoryBookingService: ClientDialogInventoryBookingService
  ) { }

  requestBookingBuildingWeeklyAvailability = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityRequested),
      switchMap(action =>
        this.clientDialogInventoryBookingService.getBuildingWeeklyAvailability(action.date, action.buildingId).pipe(
          map(response => {
            if (response.status == 1) {
              const buildingAvailability = response.data as BuildingWeeklyAvailability
              return clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityLoaded({ buildingAvailability })
            } else {
              this.notificationService.error(response.message);
              return clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityFailed()
            }
          }),
          catchError(() => of(clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityFailed()))
        )
      )
    )
  );

  requestBookingBuildingWeeklyAvailabilityScheduleOverwrite = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityScheduleOverwriteRequested),
      switchMap(action =>
        this.clientDialogInventoryBookingService.getBuildingWeeklyAvailabilityScheduleOverwrite(action.date, action.buildingId).pipe(
          map(response => {
            if (response.status == 1) {
              const buildingAvailability = response.data as BuildingWeeklyAvailability
              return clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityScheduleOverwriteLoaded({ buildingAvailability });
            } else {
              this.notificationService.error(response.message);
              return clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityScheduleOverwriteFailed();
            }
          }),
          catchError(() => of(clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityFailed()))
        )
      )
    )
  );

  requestBookingBuildingWeeklyAvailabilityBuildings = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityBuildingsRequested),
      switchMap(action =>
        this.clientDialogInventoryBookingService.getBookingBuildings().pipe(
          map(response => {
            if (response.status == 1) {
              const buildings = response.data as IBuilding[]
              const buildingId = action.buildingId
              return clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityBuildingsLoaded({ buildings, buildingId })
            } else {
              this.notificationService.error(response.message);
              return clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityBuildingsFailed()
            }
          }),
          catchError(() => of(clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityBuildingsFailed()))
        )
      )
    )
  );

  requestBookingBuildingWeeklyAvailabilityBookingCalendars = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityCalendarsRequested),
      switchMap(action =>
        this.clientDialogInventoryBookingService.getBookingCalendars(action.calendarIds).pipe(
          map(response => {
            if (response.status == 1) {
              const calendars = response.data as BookingCalendar[]
              return clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityCalendarsLoaded({ calendars })
            } else {
              this.notificationService.error(response.message);
              return clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityCalendarsFailed()
            }
          }),
          catchError(() => of(clientDialogBookingBuildingAvailabilityFailedActions.actionClientDialogBookingBuildingAvailabilityCalendarsFailed()))
        )
      )
    )
  );

  updateBookingBuildingSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityBuildingsLoaded
      ),
      withLatestFrom(
        this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityBuildingSelectedId)),
      ),
      switchMap(([action, buildingSelectedId]) =>
        [
          clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange({
            building: action.buildings.filter(x => x.id == buildingSelectedId)[0] ? action.buildings.filter(x => x.id == buildingSelectedId)[0] : {
              id: -999,
              name: '',
              address: '',
              style: '',
              photoUrl: '',
              activationDate: ''
            }
          })
        ]
      )
    )
  );

  updateBookingBuildingAvailabilityToConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange
      ),
      switchMap(() =>
        [
          clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityTimeSlotToConfirmChange({ timeSlot: '' })
        ]
      )
    )
  );
}
