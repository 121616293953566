import { createAction, props } from "@ngrx/store";
import { ILeadToMerge, IUpdateLeadProfileParams, LeadInfo, LeadPhoneNumber } from "../../../../shared/models/lead-info-models";

export const actionClientDialogByLeadIdRequired = createAction(
    '[Client Dialog] Client Dialog By Lead Id Required',
    props<{ leadId: number }>()
);

export const actionClientDialogByLeadIdSuccess = createAction(
    '[Client Dialog] Client Dialog By Lead Id Loaded',
    props<{ leadInfo: LeadInfo }>()
);

export const actionUpdateLeadId = createAction(
    '[Client Dialog] Update LeadId',
    props<{ leadId: number }>()
);

export const actionClientDialogFailed = createAction(
    '[Client Dialog] Client Dialog Failed'
);

export const actionClientUserGeneralProfileChangeIsBaseInfoEditMode = createAction(
    '[Client Dialog] Change Base Info Is Edit Mode',
    props<{ isEditMode: boolean }>()
)

export const actionClientUserGeneralProfileUpdateRequested = createAction(
    '[Client Dialog] Client Dialog Dialog Status Requested',
    props<{ params: IUpdateLeadProfileParams }>()
);

export const actionClientUserGeneralProfileUpdateSuccessfully = createAction(
    '[Client Dialog] Client Dialog Dialog Status Successfully',
    props<{ leadInfo: LeadInfo }>()
);

export const actionClientUserGeneralProfileUpdateFailed = createAction(
    '[Client Dialog] Client Dialog Dialog Status Failed'
);

export const actionClientDialogLeadsToMergeRequested = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Requested',
    props<{ leadId: number }>()
)

export const actionClientDialogLeadsToMergeLoaded = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Loaded',
    props<{ leadsToMerge: ILeadToMerge[] }>()
)

export const actionClientDialogLeadsToMergeFailed = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Failed',
)

export const actionClientDialogLeadsToMergeIdsUpdate = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Ids Update',
    props<{ leadToMergeIds: string[] }>()
)

export const actionClientDialogLeadsToMergeMergeRequested = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Merge Requested',
    props<{ leadId: number, leadToMergeIds: string[] }>()
)

export const actionClientDialogLeadsToMergeMergeSuccessfully = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Merge Successfully',
)

export const actionClientDialogLeadsToMergeMergeFailed = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads To Merge Merge Failed',
)

export const actionClientDialogLeadPhoneNumberBlockRequested = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number Block Requested',
    props<{ leadId: number, phoneNumber: string, reason: string }>()
)

export const actionClientDialogLeadPhoneNumberBlockSuccessfully = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number Block Successfully',
    props<{ phoneNumberBlockInfo: LeadPhoneNumber[] }>()
)

export const actionClientDialogLeadPhoneNumberBlockFailed = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number Block Failed',
)

export const actionClientDialogLeadPhoneNumberUnBlockRequested = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number UnBlock Requested',
    props<{ leadId: number, phoneNumber: string }>()
)

export const actionClientDialogLeadPhoneNumberUnBlockSuccessfully = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number UnBlock Successfully',
    props<{ phoneNumberBlockInfo: LeadPhoneNumber[] }>()
)

export const actionClientDialogLeadPhoneNumberUnBlockFailed = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number UnBlock Failed',
)

export const actionClientDialogLeadPhoneNumberBlockStateReset = createAction(
    '[Client Dialog Lead] Client Dialog Lead Phone Number Block State Reset',
)

export const actionClientDialogLeadsMergeReset = createAction(
    '[Client Dialog Lead Merge] Client Dialog Leads Merge Reset',
)
export const actionClientDialogGeneralStateReset = createAction(
    '[Client Dialog] Client Dialog General State Reset',
)

export const clientDialogGeneralInfoActions = {
    actionClientDialogByLeadIdRequired,
    actionClientDialogByLeadIdSuccess,
    actionClientUserGeneralProfileChangeIsBaseInfoEditMode,
    actionClientUserGeneralProfileUpdateRequested,
    actionClientUserGeneralProfileUpdateSuccessfully,
    actionClientUserGeneralProfileUpdateFailed,
    actionUpdateLeadId,
    actionClientDialogFailed,
    actionClientDialogLeadsToMergeRequested,
    actionClientDialogLeadsToMergeLoaded,
    actionClientDialogLeadsToMergeFailed,
    actionClientDialogLeadsMergeReset,
    actionClientDialogLeadsToMergeIdsUpdate,
    actionClientDialogLeadsToMergeMergeRequested,
    actionClientDialogLeadsToMergeMergeSuccessfully,
    actionClientDialogLeadsToMergeMergeFailed,
    actionClientDialogLeadPhoneNumberBlockRequested,
    actionClientDialogLeadPhoneNumberBlockSuccessfully,
    actionClientDialogLeadPhoneNumberBlockFailed,
    actionClientDialogLeadPhoneNumberUnBlockRequested,
    actionClientDialogLeadPhoneNumberUnBlockSuccessfully,
    actionClientDialogLeadPhoneNumberUnBlockFailed,
    actionClientDialogLeadPhoneNumberBlockStateReset,
    actionClientDialogGeneralStateReset
}
