import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { ClientAppointment } from 'app/shared/models/lead-info-models';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as uuid from 'uuid';
import {
  BuildingWeeklyAvailability,
  IBuilding,
  WeeklyAvailabilityItem
} from '../../../client-dialog-inventory.model';
import {
  actionClientDialogBookingEventCalendarDateChange,
  actionClientDialogBookingEventCalendarRequested,
  actionClientDialogBookingEventCalendarTimeChange
} from '../../client-dialog-booking-calendar/client-dialog-booking-calendar.action';
import {
  actionClientDialogBookingBuildingAvailabilityBuildingsRequested,
  actionClientDialogBookingBuildingAvailabilityCalendarsRequested,
  actionClientDialogBookingBuildingAvailabilityDateFilterChange,
  actionClientDialogBookingBuildingAvailabilityRequested,
  actionClientDialogBookingBuildingAvailabilityReset,
  actionClientDialogBookingBuildingAvailabilityScheduleOverwriteRequested,
  actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange,
  actionClientDialogBookingBuildingAvailabilityTimeSlotToConfirmChange,
  actionClientDialogBookingEventCalendarToggleScheduleOverwrite,
  actionClientDialogBookingStepChange
} from '../client-dialog-booking-building-avaliabilty.action';
import {
  selectClientDialogBookingStateBookingStep,
  selectClientDialogBookingStateBuildingAvailability,
  selectClientDialogBookingStateBuildingAvailabilityBookingCalendars,
  selectClientDialogBookingStateBuildingAvailabilityBuildings,
  selectClientDialogBookingStateBuildingAvailabilityBuildingSelected,
  selectClientDialogBookingStateBuildingAvailabilityDateFilter,
  selectClientDialogBookingStateBuildingAvailabilityIsBookingCalendarsLoaded,
  selectClientDialogBookingStateBuildingAvailabilityIsBuildingsLoaded,
  selectClientDialogBookingStateBuildingAvailabilityIsLoaded,
  selectClientDialogBookingStateBuildingAvailabilitySelected,
  selectClientDialogIsScheduleOverwrite
} from '../client-dialog-booking-building-avaliabilty.selector';
import {
  selectClientDialogClientAppointmentAppointmentInEditting
} from '../../../../client-dialog-appointment/client-dialog-appointment.selector';
import { BookingCalendar } from '../../../../../../../shared/models/booking-calendar.model';

@Component({
  selector: 'dq-client-dialog-inventory-booking',
  templateUrl: './client-dialog-inventory-booking.component.html',
  styleUrls: ['./client-dialog-inventory-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogInventoryBookingComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    public dialogRef: MatDialogRef<ClientDialogInventoryBookingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.buildingId = data?.buildingId
  }

  unsubscribe: Subject<void> = new Subject();

  buildingId: number

  bookingStep$: Observable<number>
  bookingStep: number

  isMobile$: Observable<boolean>
  isMobile: boolean

  isBuildingWeeklyAvailabilityloaded$: Observable<boolean>
  isBuildingsLoaded$: Observable<boolean>

  availabilitySelected$: Observable<string>
  availabilitySelected: string

  buildings$: Observable<IBuilding[]>
  buildingSelected$: Observable<IBuilding>
  buildingSelected: IBuilding
  buildingWeeklyAvailability$: Observable<BuildingWeeklyAvailability>
  buildingWeeklyAvailability: BuildingWeeklyAvailability
  dateFilter$: Observable<string>
  buildingDateControl = new UntypedFormControl(new Date().toDateString());
  isScheduleOverwrite$: Observable<boolean>
  today = new Date()

  isBookingCalendarsLoaded$: Observable<boolean>
  calendars$: Observable<BookingCalendar[]>

  appointmentEditting$: Observable<ClientAppointment>
  appointmentEdittingId: number


  ngOnInit(): void {
    this.store.dispatch(actionClientDialogBookingBuildingAvailabilityBuildingsRequested({ buildingId: this.buildingId }))
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    });

    this.bookingStep$ = this.store.pipe(select(selectClientDialogBookingStateBookingStep))
    this.isBuildingWeeklyAvailabilityloaded$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityIsLoaded))
    this.isScheduleOverwrite$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectClientDialogIsScheduleOverwrite));
    this.isBuildingsLoaded$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityIsBuildingsLoaded))
    this.buildings$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityBuildings))
    this.buildingSelected$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityBuildingSelected))
    this.buildingWeeklyAvailability$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailability))
    this.dateFilter$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityDateFilter))
    this.availabilitySelected$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilitySelected))

    this.isBookingCalendarsLoaded$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityIsBookingCalendarsLoaded))
    this.calendars$ = this.store.pipe(select(selectClientDialogBookingStateBuildingAvailabilityBookingCalendars))

    this.availabilitySelected$.pipe(takeUntil(this.unsubscribe)).subscribe(availabilitySelected => {
      this.availabilitySelected = availabilitySelected
    })
    this.buildingSelected$.pipe(takeUntil(this.unsubscribe)).subscribe(buildingSelected => {
      this.buildingSelected = buildingSelected
    })
    this.buildingWeeklyAvailability$.pipe(takeUntil(this.unsubscribe)).subscribe(buildingWeeklyAvailability => {
      this.buildingWeeklyAvailability = buildingWeeklyAvailability
    })

    this.bookingStep$.pipe(takeUntil(this.unsubscribe)).subscribe(bookingStep => {
      this.bookingStep = bookingStep
    })

    this.appointmentEditting$ = this.store.pipe(select(selectClientDialogClientAppointmentAppointmentInEditting))
    this.appointmentEditting$.pipe(takeUntil(this.unsubscribe)).subscribe(appointment => {
      this.appointmentEdittingId = appointment.id
    })

    combineLatest([this.buildingSelected$, this.dateFilter$, this.isScheduleOverwrite$]).pipe(takeUntil(this.unsubscribe)).subscribe(([building, date, isScheduleOverwrite]) => {
      if (building?.id > 0) {
        if (isScheduleOverwrite) {
          this.store.dispatch(actionClientDialogBookingBuildingAvailabilityScheduleOverwriteRequested({ date, buildingId: building.id }));
        }
        else {
          this.store.dispatch(actionClientDialogBookingBuildingAvailabilityRequested({ date, buildingId: building.id }));
        }
      }
    })

    this.buildingDateControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value) {
        this.dateBuildingChange(value)
      }
    })
  }

  overWriteScheduleChange(event: MatCheckboxChange) {
    this.store.dispatch(actionClientDialogBookingEventCalendarToggleScheduleOverwrite({ isScheduleOverwrite: event.checked }));
  }

  buildingSelectedChange(building: IBuilding) {
    this.store.dispatch(actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange({ building }))
  }

  dateBuildingChangeToPreviousWeek() {
    if (this.buildingSelected.id < 0) {
      return
    }
    const weekStartDate = new Date(this.buildingWeeklyAvailability.weekStartDate)
    const previousDate = new Date()
    previousDate.setTime(weekStartDate.getTime() - (24 * 60 * 60 * 1000));
    this.dateBuildingChange(previousDate.toISOString())
  }

  dateBuildingChangeToNextWeek() {
    if (this.buildingSelected.id < 0) {
      return
    }
    const weekEndDate = new Date(this.buildingWeeklyAvailability.weekEndDate)
    const nextDate = new Date()
    nextDate.setTime(weekEndDate.getTime() + (24 * 60 * 60 * 1000));
    this.dateBuildingChange(nextDate.toISOString())
  }

  dateBuildingChangeToToday() {
    if (this.buildingSelected.id < 0) {
      return
    }
    this.dateBuildingChange(new Date().toISOString())
  }

  dateBuildingChange(date: string) {
    this.store.dispatch(actionClientDialogBookingBuildingAvailabilityDateFilterChange({ date: new Date(date).toISOString() }))
  }

  changeTimeIdToConfirm(timeSlot: string) {
    this.store.dispatch(actionClientDialogBookingBuildingAvailabilityTimeSlotToConfirmChange({ timeSlot }))
  }

  confirmTime(availability: WeeklyAvailabilityItem) {
    // this.openBookingCalendarTypeDialog(availability)
    this.store.dispatch(actionClientDialogBookingStepChange({ bookingStep: 2 }))
    this.store.dispatch(actionClientDialogBookingBuildingAvailabilityCalendarsRequested({ calendarIds: availability.calendarIds }))
  }

  close() {
    this.dialogRef.close(false);
  }

  back() {
    this.store.dispatch(actionClientDialogBookingStepChange({ bookingStep: this.bookingStep - 1 }))
  }

  confirm(calendar: BookingCalendar) {
    this.store.dispatch(actionClientDialogBookingStepChange({ bookingStep: 3 }))
    this.store.dispatch(actionClientDialogBookingEventCalendarDateChange({ date: this.availabilitySelected }));
    this.store.dispatch(actionClientDialogBookingEventCalendarTimeChange({ time: { id: uuid.v4(), label: this.availabilitySelected } }));
    this.store.dispatch(actionClientDialogBookingEventCalendarRequested({ repUserId: calendar.repUserId, calendarId: calendar.id }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(actionClientDialogBookingBuildingAvailabilityReset());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
