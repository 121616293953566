<div class="lead-div">
  <div class="dq-row">
    <div class="col-4 lead-info-item name-info" (click)="toggleSearchDiv()">
      <mat-icon>person</mat-icon>
      <span>{{(rentalApplicationLeadInfo$ | async)?.firstName + '  ' + (rentalApplicationLeadInfo$ |
        async)?.lastName}}</span>
    </div>
    <div class="col-4 lead-info-item"><span>{{(rentalApplicationLeadInfo$ | async)?.phoneNumbers[0]}}</span></div>
    <div class="col-4 lead-info-item"><span>{{(rentalApplicationLeadInfo$ | async)?.emails[0]}}</span></div>
  </div>
  <mat-form-field *ngIf="searchDivVisible" class="add-rep-form-field" appearance="outline" class="col-12 search-div">
    <mat-icon matPrefix>search</mat-icon>
    <input type="text" placeholder="Search by email, phone or lead name" matInput [formControl]="searchControl"
      [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="search-custom-autocomplete">
      <mat-option *ngFor="let lead of leadSearchOptions$ | async" [value]="lead.name"
        (click)="selectSearchOption(lead)">
        {{lead.firstName}} {{lead.lastName}} | <span class="lead-phone-number">{{lead.phoneNumbers[0]}}</span> | <span
          class="lead-email">{{lead.emails[0]}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div class="form-div">
  <form [formGroup]="applicationUpdateForm">
    <div class="dq-row">
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>Applicant First Name</mat-label>
        <input type="text" matInput formControlName="applicantFirstName" placeholder="Applicant First Name"
          name="applicantFirstName">
        <mat-error *ngIf="applicationUpdateForm.controls['applicantFirstName'].hasError('required')">
          A first name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>Applicant Last Name</mat-label>
        <input type="text" matInput formControlName="applicantLastName" placeholder="Applicant Last Name"
          name="applicantLastName">
        <mat-error *ngIf="applicationUpdateForm.controls['applicantLastName'].hasError('required')">
          A last name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>Applicant Date of Birth</mat-label>
        <input matInput [max]="maxDate" [matDatepicker]="birthDateDatePicker" (click)="birthDateDatePicker.open()"
          formControlName="applicantBirthDate" required>
        <mat-datepicker-toggle matSuffix [for]="birthDateDatePicker">
        </mat-datepicker-toggle>
        <mat-datepicker #birthDateDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="dq-row">
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="applicantEmail" placeholder="Email" name="applicantEmail">
        <mat-error *ngIf="applicationUpdateForm.controls['applicantEmail'].hasError('required')">
          An email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Phone Number</mat-label>
        <ngx-mat-intl-tel-input (countryChanged)="countryChanged($event)" [cssClass]="'custom'"
          [preferredCountries]="['ca', 'us']" placeholder="Enter Phone Number" [enablePlaceholder]="false"
          [enableSearch]="true" formControlName="applicantPhoneNumber" name="applicantPhoneNumber"
          #phoneNumber></ngx-mat-intl-tel-input>
        <mat-error *ngIf="applicationUpdateForm.controls['applicantPhoneNumber'].hasError('required')">
          A phone number is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="dq-row">
      <mat-form-field appearance="outline" class="col-6" required>
        <mat-label>Country</mat-label>
        <mat-select formControlName="applicantCountry" (selectionChange)="onCountryChange($event)" required>
          <mat-option *ngFor="let countryOption of countryOptions" [value]="countryOption.value">
            {{countryOption.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6" required>
        <mat-label>State/Province</mat-label>
        <mat-select formControlName="applicantState" required>
          <mat-option *ngFor="let provinceStateOption of provinceStateOptions"
            [value]="provinceStateOption.value">{{provinceStateOption.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="dq-row">
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>Applicant Street Address</mat-label>
        <input type="text" matInput formControlName="applicantStreetAddress" placeholder="Applicant Last Name"
          name="applicantStreetAddress">
        <mat-error *ngIf="applicationUpdateForm.controls['applicantStreetAddress'].hasError('required')">
          Street Address is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>Applicant City</mat-label>
        <input type="text" matInput formControlName="applicantCity" placeholder="Applicant City" name="applicantCity">
        <mat-error *ngIf="applicationUpdateForm.controls['applicantCity'].hasError('required')">
          City is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>Applicant Postal Code / Zip</mat-label>
        <input type="text" matInput formControlName="applicantPostalCode" placeholder="Applicant Postal Code"
          name="applicantPostalCode">
        <mat-error *ngIf="applicationUpdateForm.controls['applicantPostalCode'].hasError('required')">
          Postal Code / ZIP is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div class="action-buttons dq-flex-centered">
  <dq-spinner-button [disabled]="!applicationUpdateForm.valid" type="button" (click)="updateApplication()"
    [options]="spinnerCreateButtonOptions"></dq-spinner-button>
</div>