import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ClientDialogMatDialogService } from "app/features/client-dialog/client-dialog-mat-dialog.serivce";
import { RentalApplication, RentalApplicationForm, RentalFormFile, RentalFormTemplate, RentalItem, RentalRequestAcitivity, RentalRequestForm, RentalRequestQueryResult } from "app/features/client-dialog/client-dialog-model";
import { DynamicForm } from "app/shared/models/dynamic-form.mode";
import { ClientDialogApplicationService } from "./client-dialog-rental-application.service";
import { clientDialogApplicationRentalApplicationActions, clientDialogApplicationRentalApplicationFailedActions } from "./client-dialog-rental-application.action";
import { Store } from "@ngrx/store";
import { State } from "../../client-dialog.state";
import { RENTAL_FORM_TYPE } from "app/shared/models/rental-application.model";
import { LeadInfo } from "app/shared/models/lead-info-models";
import { IUnit } from "../client-dialog-inventory/client-dialog-inventory.model";

@Injectable()
export class ClientDialogClientRentalApplicationEffects {
    constructor(
        private actions$: Actions,
        public store: Store<State>,
        private clientDialogApplicationService: ClientDialogApplicationService,
        private clientDialogMatDialogService: ClientDialogMatDialogService,
        private notificationService: NotificationService
    ) { }

    requestClientApplicationRentalApplications = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getClientRentalRequests(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalRequestQeuryResult = response.data as RentalRequestQueryResult;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsSuccess({ rentalRequestQueryResult: rentalRequestQeuryResult });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationsFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationsFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getClientRentalRequest(action.rentalRequestId, action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalRequestQeuryResult = response.data as RentalRequestQueryResult;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestSuccess({ rentalRequestQueryResult: rentalRequestQeuryResult });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFailed())
                    })
                )
            )
        )
    );



    requestClientApplicationRentalAplicationForm = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getClientRentalApplicationForm(action.formId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormSuccess({ rentalApplicationForm: response.data as DynamicForm });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationAddNew = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.addNewApplication(action.rentalApplicationSetting).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalApplicationAddNew();
                            let rentalApplication = res.data as RentalApplication;
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewSuccess({ rentalApplication: rentalApplication }),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListRequested({ rentalRequestId: action.rentalApplicationSetting.rentalRequestId }),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListRequested({ rentalRequestId: action.rentalApplicationSetting.rentalRequestId })
                            ];
                        }
                        else {
                            let errorMsg = res.message as string
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAddNewFailed({ errorMsg })];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAddNewFailed({ errorMsg: error.message }));
                    })
                );
            })
        )
    );

    requestClientApplicationRentalAplicationCancel = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationCancelRequested),
            switchMap(action =>
                this.clientDialogApplicationService.cancelApplication(action.rentalApplicationId, action.reasonType, action.reason).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeRentalApplicationCancel();
                            let rentalApplication = response.data as RentalApplication;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationCancelSuccess({ rentalApplication: rentalApplication });
                        } else {
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationCancelFailed({ errorMessage: response.message })
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationCancelFailed({ errorMessage: error.message }))
                    })
                )
            )
        )
    );


    requestClientApplicationRentalRequestCancel = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestCancelRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.cancelRentalRequest(
                    action.rentalRequestId,
                    action.reasonType,
                    action.reason,
                    action.leadId
                ).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalRequestCancel();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestCancelSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested({
                                    leadId: action.leadId, rentalRequestId: action.rentalRequestId
                                })
                            ];
                        } else {
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestCancelFailed({ errorMessage: res.message })];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestCancelFailed({ errorMessage: error.message }));
                    })
                );
            })
        )
    );

    requestClientApplicationRentalAplicationStart = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationStartRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.startRentalApplication(action.rentalApplicationId).pipe(
                    switchMap(response => {
                        if (response.status == 1) {
                            let rentalApplication = response.data as RentalApplication;
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationStartSuccess({ rentalApplication: rentalApplication }),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested({ leadId: rentalApplication.leadId, rentalRequestId: rentalApplication.rentalRequestId })
                            ];
                        } else {
                            this.notificationService.error(response.message)
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationStartFailed({ rentalApplicationId: action.rentalApplicationId })];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalReadyForReviewFailed({ errorMessage: '', validationMessages: [] }));
                    })
                );
            })
        )
    );

    requestClientApplicationRentalAplicationTemplateForms = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormTemplatesRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationTemplateForms(action.rentalApplicationId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalFormTemplates = response.data as RentalFormTemplate[];
                            rentalFormTemplates = rentalFormTemplates?.filter(x => x.type == RENTAL_FORM_TYPE.RENTAL_APPLICATION_FORM || x.type == RENTAL_FORM_TYPE.RENTAL_APPLICATION_ADDENDUM);
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormTemplatesSuccess({ rentalFormTemplates: rentalFormTemplates });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormTemplatesFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormTemplatesFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationAddtionalTemplateForms = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationTemplateForms(action.rentalApplicationId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalFormTemplates = response.data as RentalFormTemplate[];
                            rentalFormTemplates = rentalFormTemplates?.filter(x => x.type == RENTAL_FORM_TYPE.LEASE_AGREEMENT_ADDENDUM_FORM);
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesSuccess({ rentalFormTemplates: rentalFormTemplates });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestTemplateForms = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormTemplatesRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalRequestTemplateForms(action.rentalRequestId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalFormTemplates = response.data as RentalFormTemplate[];
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormTemplatesSuccess({ rentalFormTemplates: rentalFormTemplates });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormTemplatesFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormTemplatesFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationTemplateFormAddNew = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewFormRequested),
            switchMap(action =>
                this.clientDialogApplicationService.addNewRentalApplicationForms(action.rentalApplicationId, action.formIds)
            ),
            switchMap(res => {
                if (res.status == 1) {
                    this.clientDialogMatDialogService.closeRentalNewForm();
                    return [clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewFormSuccess(),
                    clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListRequested({ rentalApplicationId: res.data as number })]
                } else {
                    this.notificationService.error(res.message)
                    return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAddNewFormFailed()]
                }
            }),
            catchError(error => {
                this.notificationService.error(error.message)
                return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormTemplatesFailed());
            })
        )
    );

    requestClientApplicationRentalRequestTemplateFormAddNew = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestAddNewFormRequested),
            switchMap(action =>
                this.clientDialogApplicationService.addNewRentalRequestForms(action.rentalRequestId, action.formIds)
            ),
            switchMap(res => {
                if (res.status == 1) {
                    this.clientDialogMatDialogService.closeRentalNewForm();
                    return [clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestAddNewFormSuccess(),
                    clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListRequested({ rentalRequestId: res.data as number })]
                } else {
                    this.notificationService.error(res.message)
                    return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestAddNewFormFailed()]
                }
            }),
            catchError(error => {
                this.notificationService.error(error.message)
                return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormTemplatesFailed());
            })
        )
    );

    requestClientApplicationRentalAplicationCreditCheckAddNew = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalAddNewCreditCheckRequested),
            switchMap(action =>
                this.clientDialogApplicationService.addNewRentalApplicationCreditCheck(action.rentalApplicationId, action.creditCheckForm)
            ),
            switchMap(res => {
                if (res.status == 1) {
                    this.clientDialogMatDialogService.closeRentalApplicationAddNewCreditCheck();
                    return [clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalAddNewCreditCheckSuccess(),
                    clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListRequested({ rentalApplicationId: res.data as number })]
                } else {
                    this.notificationService.error(res.message)
                    return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalAddNewCreditCheckFailed()]
                }
            }),
            catchError(error => {
                this.notificationService.error(error.message)
                return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalAddNewCreditCheckFailed());
            })
        )
    );

    requestClientApplicationRentalAplicationFormList = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationFormList(action.rentalApplicationId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalApplicationForms = response.data as RentalApplicationForm[];
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListSuccess({ rentalApplicationForms: rentalApplicationForms });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormListFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormListFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestFormList = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalRequestFormList(action.rentalRequestId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalRequestForm = response.data as RentalRequestForm[];
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListSuccess({ rentalRequestForms: rentalRequestForm });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormListFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormListFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationFormFileList = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormFileListRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationFormFileList(action.rentalApplicationId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalApplicationFormFiles = response.data as RentalFormFile[];
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormFileListSuccess({ rentalApplicationFormFiles: rentalApplicationFormFiles });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormFileListFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormFileListFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestFormFileList = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalRequestFormFileList(action.rentalRequestId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalRequestFormFiles = response.data as RentalFormFile[];
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListSuccess({ rentalRequestFormFiles: rentalRequestFormFiles });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormFileListFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormFileListFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationRemoveForm = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormRequested),
            switchMap(action =>
                this.clientDialogApplicationService.removeRentalApplicationForm(action.rentalApplicationId, action.formId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeRentalApplicationFormRemove();
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormSuccess({ rentalApplicationId: action.rentalApplicationId, formId: action.formId });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationRemoveFormFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationRemoveFormFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestRemoveForm = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.removeRentalRequestForm(action.rentalRequestId, action.formId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalApplicationFormRemove();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormSuccess({ rentalRequestId: action.rentalRequestId, formId: action.formId }),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListRequested({ rentalRequestId: action.rentalRequestId }),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListRequested({ rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestRemoveFormFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestRemoveFormFailed());
                    })
                );
            })
        )
    );

    requestClientApplicationRentalAplicationRemoveCertnVerificationReport = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportRequested),
            switchMap(action =>
                this.clientDialogApplicationService.removeRentalApplicationCertnVerificationReport(action.reportId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeRemoveVerificationReport();
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportSuccess({ reportId: action.reportId });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationRemovePlaidVerificationReport = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportRequested),
            switchMap(action =>
                this.clientDialogApplicationService.removeRentalApplicationPlaidVerificationReport(action.reportId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeRemoveVerificationReport();
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportSuccess({ reportId: action.reportId });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplicationRemoveFormFile = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormFileRequested),
            switchMap(action =>
                this.clientDialogApplicationService.removeRentalApplicationFormFile(action.rentalApplicationId, action.fileId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeRentalFormFileRemove();
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormFileSuccess({ fileId: action.fileId });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationRemoveFormFileFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationRemoveFormFileFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestRemoveFormFile = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormFileRequested),
            switchMap(action =>
                this.clientDialogApplicationService.removeRentalRequestFormFile(action.rentalRequestId, action.fileId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeRentalFormFileRemove();
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormFileSuccess({ fileId: action.fileId });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestRemoveFormFileFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestRemoveFormFileFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalAplication = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplication(action.rentalApplicationId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let rentalApplication = response.data as RentalApplication;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationSuccess({ rentalApplication: rentalApplication });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationFormViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationFormViewerDocument(action.rentalApplicationFormId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationUploadedFileViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationUploadedFileViewerDocument(action.fileId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestUploadedFileViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUploadedFileViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalRequestUploadedFileViewerDocument(action.fileId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUploadedFileViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestUploadedFileViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestUploadedFileViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalRequestFormViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalRequestFormViewerDocument(action.rentalRequestFormId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationCreditBackgroundReportViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnReportViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationCertnReportViewerDocument(action.reportId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnReportViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalCertnReportViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalCertnReportViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationCertnIdentityReportViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnIdentityReportViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationCertnIdentityReportViewerDocument(action.reportId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnIdentityReportViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalCertnIdentityReportViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalCertnIdentityReportViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationIncomeAssetReportViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidReportViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationPlaidReportViewerDocument(action.reportId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidReportViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalPlaidReportViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalPlaidReportViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationPlaidIdentityReportViewer = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationPlaidIdentityReportViewerDocument(action.reportId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalUpdateLeasingDates = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUpdateLeasingDatesRequested),
            switchMap(action =>
                this.clientDialogApplicationService.updateRentalLeasingDates(action.rentalRequestId, action.moveInDate, action.leaseStartDate, action.leaseEndDate).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUpdateLeasingDatesSuccess();
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationUpdateLeasingDatesFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationUpdateLeasingDatesFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationFormEditor = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalApplicationFormEditorDocument(action.rentalApplicationFormId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormEditorFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormEditorFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationRequestFormEditor = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalRequestFormEditorDocument(action.rentalRequestFormId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let document = response.data;
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorSuccess({ document });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormEditorFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormEditorFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationFormEditorSave = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorSaveRequested),
            switchMap(action =>
                this.clientDialogApplicationService.saveRentalApplicationFormData(action.formId, action.data).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorSaveSuccess();
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormEditorSaveFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormEditorSaveFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationRequestFormEditorSave = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorSaveRequested),
            switchMap(action =>
                this.clientDialogApplicationService.saveRentalRequestFormData(action.formId, action.data).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorSaveSuccess();
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormEditorSaveFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormEditorSaveFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationFormAddNewUpload = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.uploadRentalApplicationManualFile(action.rentalApplicationId, action.fileName, action.file).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalNewDocumentManual();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormFileListRequested({ rentalApplicationId: action.rentalApplicationId })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentFailed());
                    })
                );
            })
        )
    );

    requestClientApplicationRentalRequestFormAddNewUpload = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.uploadRentalRequestManualFile(action.rentalRequestId, action.fileName, action.file).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalNewDocumentManual();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListRequested({ rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentFailed());
                    })
                );
            })
        )
    );

    requestClientApplicationRentalApplicationReadyForReview = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalReadyForReviewRequested),
            switchMap(action => {
                const rentalRequestId = action.rentalRequestId; // Capture the rentalRequestId
                return this.clientDialogApplicationService.readyForReview(rentalRequestId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalRequestUpdateStatus();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalReadyForReviewSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested({ leadId: action.leadId, rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalReadyForReviewFailed({ errorMessage: res.message, validationMessages: res.data })];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalReadyForReviewFailed({ errorMessage: '', validationMessages: [] }));
                    })
                );
            })
        )
    );

    requestClientApplicationRentalApplicationRollback = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRollbackRequested),
            switchMap(action => {
                const rentalRequestId = action.rentalRequestId; // Capture the rentalRequestId
                return this.clientDialogApplicationService.rollbackStatus(rentalRequestId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalRequestUpdateStatus();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRollbackSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRequested({ leadId: action.leadId, rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRollbackFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRollbackFailed());
                    })
                );
            })
        )
    );

    requestClientApplicationRentalApplicationStartRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationStartRentalRequestRequested),
            switchMap(action => {
                const rentalRequestId = action.rentalRequestId; // Capture the rentalRequestId
                return this.clientDialogApplicationService.startRentalRequest(rentalRequestId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalRequestUpdateStatus();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationStartRentalRequestSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested({ leadId: action.leadId, rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationStartRentalRequestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationStartRentalRequestFailed());
                    })
                );
            })
        )
    );

    requestClientDialogApplicationResendApplicantEmail = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationResendApplicantEmailRequested),
            switchMap(action =>
                this.clientDialogApplicationService.resendApplicantEmail(action.rentalApplicationId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeResendApplicantEmail();
                            return clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationResendApplicantEmailSuccess();
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationResendApplicantEmailFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationResendApplicantEmailFailed())
                    })
                )
            )
        )
    );

    requestClientApplicationRentalApplicationApprove = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApproveRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.approveRentalRequest(action.rentalRequestId).pipe(
                    switchMap(res => {
                        this.clientDialogMatDialogService.closeRentalRequestUpdateStatus();
                        if (res.status === 1) {
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApproveSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested({ leadId: action.leadId, rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApproveFailed({ errorMessage: res.message, validationMessages: res.data })];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApproveFailed({ errorMessage: '', validationMessages: [] }));
                    })
                );
            })
        )
    );

    requestClientApplicationRentalApplicationSign = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalSignRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.signRentalRequest(action.rentalRequestId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalRequestUpdateStatus();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalSignSuccess(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested({ leadId: action.leadId, rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalSignFailed({ errorMessage: res.message, validationMessages: res.data })];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalSignFailed({ errorMessage: '', validationMessages: [] }));
                    })
                );
            })
        )
    );

    clientApplicationRentalApplicationFileUploadLinkSend = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.sendRentalApplicationFileUploadLink(action.rentalApplicationId, action.note).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeResendUploadLink();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendSuccess(),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendFailed());
                    })
                );
            })
        )
    );

    clientApplicationRentalRequestActivitiesRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalRequestActivities(action.rentalRequestId, action.pageNo, action.pageSize).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            let rentalRequestActivities: RentalRequestAcitivity[] = res.data.objectsList
                            let totalRecords: number = res.data.totalRecords
                            let page: number = res.data.page
                            let pageSize: number = res.data.pageSize
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesLoaded({
                                    rentalRequestActivities: rentalRequestActivities,
                                    pageNo: page,
                                    pageSize: pageSize,
                                    totalRecords: totalRecords
                                }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestActivitiesFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestActivitiesFailed());
                    })
                );
            })
        )
    );

    clientApplicationRentalRequestActivitiesRequestMore = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesMoreRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalRequestActivities(action.rentalRequestId, action.pageNo, action.pageSize).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            let rentalRequestActivities: RentalRequestAcitivity[] = res.data.objectsList
                            let totalRecords: number = res.data.totalRecords
                            let page: number = res.data.page
                            let pageSize: number = res.data.pageSize
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesMoreLoaded({
                                    rentalRequestActivities: rentalRequestActivities,
                                    pageNo: page,
                                    pageSize: pageSize,
                                    totalRecords: totalRecords
                                }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestActivitiesMoreFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestActivitiesMoreFailed());
                    })
                );
            })
        )
    );


    rentalApplicationInfoUpdate = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalInfoUpdateRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.rentalApplicationInfoUpdate(action.rentalApplicationId, action.updateInfo).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentalApplicationUpdateInfo();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalInfoUpdateSuccess({ rentalApplicationId: action.rentalApplicationId }),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListRequested({ rentalApplicationId: res.data as number })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalInfoUpdateFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalInfoUpdateFailed());
                    })
                );
            })
        )
    );


    rentalApplicationInfoUpdateSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalInfoUpdateSuccess),
            switchMap(action =>
                [clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRequested({ rentalApplicationId: action.rentalApplicationId })]
            )
        )
    );

    clientApplicationRentalItemsForRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemsForRentalReqest),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalItemsForRentalRequest(action.rentalRequestId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            let rentalItems: RentalItem[] = res.data
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemsForRentalReqestLoaded({
                                    rentalItems
                                }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemsForRentalReqestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemsForRentalReqestFailed());
                    })
                );
            })
        )
    );

    clientApplicationRentalItemOptionsForRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqest),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalItemsOptionsForRentalRequest(action.rentalRequestId, action.rentalItemType, action.page, action.pageSize).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            let rentalItemOptions: RentalItem[] = res.data.objectsList
                            let totalRecords: number = res.data.totalRecords
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqestLoaded({
                                    rentalItemOptions, totalRecords
                                }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqestFailed());
                    })
                );
            })
        )
    );

    clientApplicationRentalItemTypesForRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqest),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalItemTypeOptionsByRentalRequest(action.rentalRequestId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            let rentalItemTypes: string[] = res.data
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqestLoaded({
                                    rentalItemTypes
                                }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqestFailed());
                    })
                );
            })
        )
    );

    clientApplicationRemoveRentalItemForRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqest),
            switchMap(action => {
                return this.clientDialogApplicationService.removeRentalItemFromRentalRequest(action.rentalRequestId, action.rentalItemId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentaItemRmove();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqestSucceed({ rentalRequestId: action.rentalRequestId }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqestFailed());
                    })
                );
            })
        )
    );

    clientApplicationAddRentalItemsForRentalRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddRentalItemsToRentalReqest),
            switchMap(action => {
                return this.clientDialogApplicationService.addRentalItemsToRentalRequest(action.rentalRequestId, action.rentalItemIds).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeRentaItemAdd()
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddRentalItemsToRentalReqestSucceed({ rentalRequestId: action.rentalRequestId }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddRentalItemsToRentalReqestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddRentalItemsToRentalReqestFailed());
                    })
                );
            })
        )
    );

    rentalRequestRentalItemAddSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddRentalItemsToRentalReqestSucceed),
            switchMap(action =>
                [clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemsForRentalReqest({ rentalRequestId: action.rentalRequestId })]
            )
        )
    );

    rentalRequestRentalItemRemoveSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqestSucceed),
            switchMap(action =>
                [clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemsForRentalReqest({ rentalRequestId: action.rentalRequestId })]
            )
        )
    );

    leasingDocSignatureReset = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationLeasingDocumentSignatureResetRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.leasingDocSignatureReset(action.formId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.clientDialogMatDialogService.closeLeasingDocSignatureReset();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationLeasingDocumentSignatureResetSucceed(),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationLeasingDocumentSignatureResetFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationLeasingDocumentSignatureResetFailed());
                    })
                );
            })
        )
    );


    rentalApplicationLeadInfoRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetLeadInfoByLeadIdRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalApplicationLeadInfoByLeadId(action.leadId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            const leadInfo = res.data as LeadInfo;
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetLeadInfoByLeadIdLoaded({ leadInfo }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetLeadInfoByLeadIdFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetLeadInfoByLeadIdFailed());
                    })
                );
            })
        )
    );

    rentalApplicationUnits = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUnitsForRentalRequestRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.getRentalApplicationUnits(action.inventoryId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            const units = res.data.objectsList as IUnit[];
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUnitsForRentalRequestLoaded({ units }),
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationUnitsForRentalRequestFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationUnitsForRentalRequestFailed());
                    })
                );
            })
        )
    );

    updateRentalRequestUnit = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUnitUpdateRequested),
            switchMap(action => {
                return this.clientDialogApplicationService.updateRentalRequestUnit(action.rentalRequestId, action.unitId).pipe(
                    switchMap(res => {
                        if (res.status === 1) {
                            this.notificationService.success("Update Successfully");
                            this.clientDialogMatDialogService.closeRentalApplicatioUnitSelect();
                            return [
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUnitUpdateSucceed(),
                                clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRequested({ leadId: action.leadId, rentalRequestId: action.rentalRequestId })
                            ];
                        }
                        else {
                            this.notificationService.error(res.message);
                            return [clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestUnitUpdateFailed()];
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message);
                        return of(clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestUnitUpdateFailed());
                    })
                );
            })
        )
    );

}


