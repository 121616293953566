import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '../../shared/shared.module';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';

import { TenantDialogService } from './tenant-dialog.service';
import { FEATURE_NAME, tenantDialogReducers } from './tenant-dialog.state';
import { TenantDialogEffects } from './tenant-dialog.effects';
import { TenantDialogComponent } from './tenant-dialog/tenant-dialog.component';
import {
  TenantDialogUserGeneralTagsComponent
} from './components/tenant-dialog-tenant-tag/components/tenant-dialog-user-general-tags/tenant-dialog-user-general-tags.component';
import { MomentModule } from 'ngx-moment';
import {
  TenantDialogUserGeneralRepsComponent
} from './components/tenant-dialog-tenant-rep/components/tenant-dialog-user-general-reps/tenant-dialog-user-general-reps.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import {
  TenantDialogActivitiesComponent
} from './components/tenant-dialog-activities/tenant-dialog-activities/tenant-dialog-activities.component';
import {
  TenantDialogTenantNoteComponent
} from './components/tenant-dialog-tenant-note/tenant-dialog-tenant-note/tenant-dialog-tenant-note.component';
import {
  TenantDialogUserGeneralComponent
} from './components/tenant-dialog-user-general/tenant-dialog-user-general/tenant-dialog-user-general.component';
import { TenantDialogActivityEffects } from './components/tenant-dialog-activities/tenant-dialog-activities.effect';
import { TenantDialogTenantNoteEffects } from './components/tenant-dialog-tenant-note/tenant-dialog-tenant-note.effect';
import { TenantDialogGeneralEffects } from './components/tenant-dialog-user-general/tenant-dialog-general.effect';
import {
  TenantDialogTaskComponent
} from './components/tenant-dialog-task/tenant-dialog-task/tenant-dialog-task.component';
import { TenantDialogTaskEffects } from './components/tenant-dialog-task/tenant-dialog-task.effect';
import {
  TenantDialogTaskCreateDialogComponent
} from './components/tenant-dialog-task/components/tenant-dialog-task-create-dialog/tenant-dialog-task-create-dialog.component';
import {
  TenantDialogTaskEditDialogComponent
} from './components/tenant-dialog-task/components/tenant-dialog-task-edit-dialog/tenant-dialog-task-edit-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  TenantDialogTenantRepComponent
} from './components/tenant-dialog-tenant-rep/tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.component';
import { TenantDialogTagsEffects } from './components/tenant-dialog-tenant-tag/tenant-dialog-tenant-tag.effect';
import {
  TenantDialogTenantTagComponent
} from './components/tenant-dialog-tenant-tag/tenant-dialog-tenant-tag/tenant-dialog-tenant-tag.component';
import { TenantDialogRepsEffects } from './components/tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.effect';
import {
  TenantDialogBuildingsEffects
} from './components/tenant-dialog-tenant-building/tenant-dialog-tenant-building.effect';
import {
  TenantDialogTenantBuildingComponent
} from './components/tenant-dialog-tenant-building/tenant-dialog-tenant-building/tenant-dialog-tenant-building.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {
  TenantDialogCommunicationTemplateEditorComponent
} from './components/tenant-dialog-communication-template-editor/tenant-dialog-communication-template-editor.component';
import {
  TenantDialogEmailEditorComponent
} from './components/tenant-dialog-email-editor/tenant-dialog-email-editor/tenant-dialog-email-editor.component';
import { TenantDialogEmailService } from './components/tenant-dialog-email-editor/tenant-dialog-email.service';
import {
  TenantDialogTenantTextMessageService
} from './components/tenant-dialog-tenant-quick-text-message/tenant-dialog-tenant-text-message.service';
import {
  TenantDialogTenantQuickTextMessageComponent
} from './components/tenant-dialog-tenant-quick-text-message/tenant-dialog-tenant-quick-text-message/tenant-dialog-tenant-quick-text-message.component';
import {
  TenantDialogTenantTextMessageEffects
} from './components/tenant-dialog-tenant-quick-text-message/tenant-dialog-tenant-quick-text-message.effect';
import {
  TenantDialogEmailEditorEffects
} from './components/tenant-dialog-email-editor/tenant-dialog-email-editor.effect';
import {
  TenantDialogPhoneNumberBlockConfirmComponent
} from './components/tenant-dialog-user-general/components/phone-number-block-confirm/phone-number-block-confirm.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

@NgModule({
  imports: [
    CommonModule,
    NgxSliderModule,
    SharedModule,
    RouterModule,
    StoreModule.forFeature(FEATURE_NAME, tenantDialogReducers),
    EffectsModule.forFeature([
      TenantDialogEffects,
      TenantDialogGeneralEffects,
      TenantDialogActivityEffects,
      TenantDialogTenantNoteEffects,
      TenantDialogTaskEffects,
      TenantDialogTagsEffects,
      TenantDialogRepsEffects,
      TenantDialogBuildingsEffects,
      TenantDialogTenantTextMessageEffects,
      TenantDialogEmailEditorEffects
    ]),
    DragDropModule,
    FormsModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatExpansionModule,
    NgxMatIntlTelInputComponent,
    NgxMaterialTimepickerModule,
    NgxPaginationModule,
    ScrollingModule,
    MomentModule,

    ClipboardModule
  ],
  exports: [],
  declarations: [
    TenantDialogComponent,
    TenantDialogTenantNoteComponent,
    TenantDialogUserGeneralComponent,
    TenantDialogUserGeneralTagsComponent,
    TenantDialogUserGeneralRepsComponent,
    TenantDialogActivitiesComponent,
    TenantDialogTaskComponent,
    TenantDialogTenantTagComponent,
    TenantDialogTaskCreateDialogComponent,
    TenantDialogTaskEditDialogComponent,
    TenantDialogTenantRepComponent,
    TenantDialogTenantBuildingComponent,
    TenantDialogCommunicationTemplateEditorComponent,
    TenantDialogEmailEditorComponent,
    TenantDialogTenantQuickTextMessageComponent,
    TenantDialogPhoneNumberBlockConfirmComponent
  ],
  providers: [
    TenantDialogService,
    TenantDialogEmailService,
    TenantDialogTenantTextMessageService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TenantDialogModule {

}
