import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { selectIsAuthenticated, selectIsNotExpired } from './auth.selectors';
import { AppState } from '../core.state';
import { tap } from 'rxjs/operators';
import { authLogout } from './auth.actions';

@Injectable()
export class AuthGuardService  {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return combineLatest(
      this.store.select(selectIsAuthenticated),
      this.store.select(selectIsNotExpired),
      (isAuth, isNotExpired) => isAuth && isNotExpired
    ).pipe(
      tap(isAuth => {
        if (!isAuth) {
          this.store.dispatch(authLogout());
          this.router.navigate['sign-in'];
        }
      })
    );
  }

  canActivateService(): Observable<boolean> {
    return combineLatest(
      this.store.select(selectIsAuthenticated),
      this.store.select(selectIsNotExpired),
      (isAuth, isNotExpired) => isAuth && isNotExpired
    );
  }
}
