import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { ClientAppointment } from "../../../../shared/models/lead-info-models";
import { actionClientDialogAppointmentAppointmentsRequested, clientDialogAppointmentsActions, clientDialogAppointmentsFailedActions } from "./client-dialog-appointment.action";
import { ClientDialogAppointmentService } from "./client-dialog-appointment.service";
import { selectClientLeadId } from "../client-dialog-user-general/client-dialog-general.selectors";
import { State } from "../../client-dialog.state";
import { Store, select } from "@ngrx/store";
@Injectable()
export class ClientDialogClientAppointmentEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private clientDialogAppointmentService: ClientDialogAppointmentService,
        private notificationService: NotificationService
    ) { }

    requestClientAppointments = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentsRequested),
            switchMap(action =>
                this.clientDialogAppointmentService.getClientAppointmentsByLeadId(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let clientAppointments: ClientAppointment[] = response.data
                            return clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentsLoaded({ clientAppointments })
                        } else {
                            this.notificationService.error("Get Appointment Failed!")
                            return clientDialogAppointmentsFailedActions.actionClientDialogAppointmentAppointmentsFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error("Get Appointment Failed!")
                        return of(clientDialogAppointmentsFailedActions.actionClientDialogAppointmentAppointmentsFailed())
                    })
                )
            )
        )
    );

    requestAppointmentToEdit = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentInEdittingRequested),
            switchMap(action =>
                this.clientDialogAppointmentService.getAppointmentById(action.id).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let clientAppointment: ClientAppointment = response.data
                            return clientDialogAppointmentsActions.actionClientDialogAppointmentAppointmentInEdittingLoaded({ clientAppointment })
                        } else {
                            this.notificationService.error("Get Appointment Failed!")
                            return clientDialogAppointmentsFailedActions.actionClientDialogAppointmentAppointmentInEdittingFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error("Get Appointment Failed!")
                        return of(clientDialogAppointmentsFailedActions.actionClientDialogAppointmentAppointmentInEdittingFailed())
                    })
                )
            )
        )
    );


    cancelAppointment = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogAppointmentsActions.actionClientDialogAppointmentCancelRequseted),
            switchMap(action =>
                this.clientDialogAppointmentService.cancelAppointmentById(action.params).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogAppointmentsActions.actionClientDialogAppointmentCancelSuccessfully()
                        } else {
                            this.notificationService.error("Cancel Appointment Failed!")
                            return clientDialogAppointmentsFailedActions.actionClientDialogAppointmentCancelFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error("Cancel Appointment Failed!")
                        return of(clientDialogAppointmentsFailedActions.actionClientDialogAppointmentCancelFailed())
                    })
                )
            )
        )
    );

    updateLostStatusSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogAppointmentsActions.actionClientDialogAppointmentCancelSuccessfully),
            withLatestFrom(
                this.store.pipe(select(selectClientLeadId))
            ),
            switchMap(([action, leadId]) =>
                [
                    actionClientDialogAppointmentAppointmentsRequested({ leadId: leadId })
                ]
            )
        )
    );

}
