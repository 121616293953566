import { createAction, props } from "@ngrx/store";
import { Task, TaskAssignUser, TaskCreateParam, TaskUpdateParam } from "app/shared/models/task.model";

export const actionTenantDialogTaskTasksRequested = createAction(
    '[Tenant Dialog Task] Tasks Requested',
    props<{ tenantId: number }>()
)

export const actionTenantDialogTaskTasksLoaded = createAction(
    '[Tenant Dialog Task] Tasks Loaded',
    props<{ tasks: Task[] }>()
)

export const actionTenantDialogTaskTasksLoadFailed = createAction(
    '[Tenant Dialog Task] Tasks Load Failed'
)

export const actionTenantDialogTasktaskAssignUsersRequested = createAction(
    '[Tenant Dialog Task] Task Assign Users Requested',
)

export const actionTenantDialogTasktaskAssignUsersLoaded = createAction(
    '[Tenant Dialog Task] Task Assign Users Loaded',
    props<{ taskAssignUsers: TaskAssignUser[] }>()
)

export const actionTenantDialogTasktaskAssignUsersFailed = createAction(
    '[Tenant Dialog Task] Task Assign Users Failed',
)

export const actionTenantDialogTasktaskCreateRequested = createAction(
    '[Tenant Dialog Task] Task Create Requested',
    props<{ param: TaskCreateParam }>()
)

export const actionTenantDialogTasktaskCreateSucceed = createAction(
    '[Tenant Dialog Task] Task Create Succeed',
)

export const actionTenantDialogTasktaskCreateFailed = createAction(
    '[Tenant Dialog Task] Task Create Failed',
)

export const actionTenantDialogTasktaskUpdateRequested = createAction(
    '[Tenant Dialog Task] Task Update Requested',
    props<{ param: TaskUpdateParam }>()
)

export const actionTenantDialogTasktaskUpdateSucceed = createAction(
    '[Tenant Dialog Task] Task Update Succeed',
)

export const actionTenantDialogTasktaskUpdateFailed = createAction(
    '[Tenant Dialog Task] Task Update Failed',
)

export const actionTenantDialogTasktaskDeleteRequested = createAction(
    '[Tenant Dialog Task] Task Delete Requested',
    props<{ taskId: number }>()
)

export const actionTenantDialogTasktaskDeleteSucceed = createAction(
    '[Tenant Dialog Task] Task Delete Succeed',
)

export const actionTenantDialogTasktaskDeleteFailed = createAction(
    '[Tenant Dialog Task] Task Delete Failed',
)

export const actionTenantDialogTaskTaskStatusToggle = createAction(
    '[Tenant Dialog Task] Task Status Toggle',
    props<{ taskId: number }>()
)

export const actionTenantDialogTaskTaskStatusToggleSucceed = createAction(
    '[Tenant Dialog Task] Task Status Toggle Succeed',
)

export const actionTenantDialogTaskTaskStatusToggleFailed = createAction(
    '[Tenant Dialog Task] Task Status Toggle Failed',
    props<{ taskId: number }>()
)

export const actionTenantDialogTaskTaskCreateStateReset = createAction(
    '[Tenant Dialog Task] Tasks Task Create State Reset'
)

export const actionTenantDialogTaskTaskEditStateReset = createAction(
    '[Tenant Dialog Task] Tasks Task Edit State Reset'
)

export const actionTenantDialogTaskTaskDeleteStateReset = createAction(
    '[Tenant Dialog Task] Tasks Task Delete State Reset'
)

export const actionTenantDialogTaskStateReset = createAction(
    '[Tenant Dialog Task] Tasks State Reset'
)

export const tenantDialogTaskActions = {
    actionTenantDialogTaskTasksRequested,
    actionTenantDialogTaskTasksLoaded,
    actionTenantDialogTasktaskAssignUsersRequested,
    actionTenantDialogTasktaskAssignUsersLoaded,
    actionTenantDialogTasktaskCreateRequested,
    actionTenantDialogTasktaskCreateSucceed,
    actionTenantDialogTasktaskUpdateRequested,
    actionTenantDialogTasktaskUpdateSucceed,
    actionTenantDialogTasktaskDeleteRequested,
    actionTenantDialogTasktaskDeleteSucceed,
    actionTenantDialogTaskTaskStatusToggle,
    actionTenantDialogTaskTaskStatusToggleSucceed,
    actionTenantDialogTaskTaskCreateStateReset,
    actionTenantDialogTaskTaskEditStateReset,
    actionTenantDialogTaskTaskDeleteStateReset,
    actionTenantDialogTaskStateReset
}

export const tenantDialogTaskFailActions = {
    actionTenantDialogTaskTasksLoadFailed,
    actionTenantDialogTasktaskCreateFailed,
    actionTenantDialogTasktaskUpdateFailed,
    actionTenantDialogTasktaskDeleteFailed,
    actionTenantDialogTasktaskAssignUsersFailed,
    actionTenantDialogTaskTaskStatusToggleFailed,
}