import { createSelector } from "@ngrx/store";
import { ClientDialogState, ClientDialogBuildingsState, selectClientDialog } from "../../client-dialog.state";

export const selectClientDialogBuildingsState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogBuildings
);

export const selectclientDialogBuildingsStateIsBuildingsLoaded = createSelector(
    selectClientDialogBuildingsState,
    (state: ClientDialogBuildingsState) => {
        return state.isBuildingsLoaded
    }
);

export const selectclientDialogBuildingsStateIsBuildingOptionsLoaded = createSelector(
    selectClientDialogBuildingsState,
    (state: ClientDialogBuildingsState) => {
        return state.isBuildingOptionsLoaded
    }
);

export const selectclientDialogBuildingsStateClientBuildings = createSelector(
    selectClientDialogBuildingsState,
    (state: ClientDialogBuildingsState) => {
        return state.clientBuildings
    }
);

export const selectclientDialogBuildingsStateBuildingOptions = createSelector(
    selectClientDialogBuildingsState,
    (state: ClientDialogBuildingsState) => {
        return state.buildingOptions
    }
);

export const selectclientDialogBuildingsIsBuildingSubmitting = createSelector(
    selectClientDialogBuildingsState,
    (state: ClientDialogBuildingsState) => {
        return state.isBuildingSubmitting
    }
);

export const selectclientDialogBuildingsIsCreateUpdateSuccessfully = createSelector(
    selectClientDialogBuildingsState,
    (state: ClientDialogBuildingsState) => {
        return state.isUpdateSuccessfully
    }
);  