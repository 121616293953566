import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { CommunicationTemplate, State } from "../../client-dialog.state";
import { clientDialogActivityActions } from "../client-dialog-activities/client-dialog-activities.action";
import { actionClientDialogLeadRepsRequested } from "../client-dialog-lead-rep/client-dialog-lead-rep.action";
import { selectClientLeadId } from "../client-dialog-user-general/client-dialog-general.selectors";
import { actionClientDialogSendTextAndCollaborateFailed, actionClientDialogSendTextAndCollaborateRequested, actionClientDialogSendTextAndCollaborateSuccess, actionClientDialogSendTextFailed, actionClientDialogSendTextRequested, actionClientDialogSendTextSuccess, actionClientDialogTextMessageTemplateDeleteFailed, actionClientDialogTextMessageTemplateDeleteRequest, actionClientDialogTextMessageTemplateDeleteSucceeded, actionClientDialogTextMessageTemplateSaveAndUpdate, actionClientDialogTextMessageTemplateSaveAndUpdateFailed, actionClientDialogTextMessageTemplateSaveAndUpdateSucceeded, actionClientDialogTextMessageTemplatesFalied, actionClientDialogTextMessageTemplatesLoaded, actionClientDialogTextMessageTemplatesRequest } from "./client-dialog-lead-quick-text-message.action";
import { CilentDialogLeadTextMessageService } from "./client-dialog-lead-text-message.service"


@Injectable()
export class ClientDialogLeadTextMessageEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private clientDialogService: CilentDialogLeadTextMessageService,
        private notificationService: NotificationService
    ) { }

    requestSendTextMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendTextRequested),
            switchMap(action =>
                this.clientDialogService.sendTextMessage(action.leadId, action.text, action.sendFromId, action.sendTo).pipe(
                    map((response => {
                        if (response.status == 1) {
                            return actionClientDialogSendTextSuccess()
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogSendTextFailed()
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogSendTextFailed())
                        })
                    )
                )
            )
        )
    );

    requestSendTextMessageAndCollaborate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendTextAndCollaborateRequested),
            switchMap(action =>
                this.clientDialogService.sendTextMessageAndCollaborate(action.leadId, action.text, action.sendFromId, action.sendTo).pipe(
                    map((response => {
                        if (response.status == 1) {
                            return actionClientDialogSendTextAndCollaborateSuccess()
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogSendTextAndCollaborateFailed()
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogSendTextAndCollaborateFailed())
                        })
                    )
                )
            )
        )
    );

    collaborateAndMessageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendTextAndCollaborateSuccess),
            withLatestFrom(this.store.select(selectClientLeadId)),
            switchMap(([action, leadId]) =>
                [
                    actionClientDialogLeadRepsRequested({ leadId: leadId }),
                ]
            )
        )
    );

    sentTextSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogSendTextSuccess),
            withLatestFrom(this.store.pipe(select(selectClientLeadId))),
            switchMap(([action, leadId]) =>
                [clientDialogActivityActions.actionClientDialogAllActivitiesRequest({ leadId: leadId, pageNo: 0, pageSize: 20, itemCountPage: 0 })]
            )
        )
    );


    saveAndUpdateTextTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogTextMessageTemplateSaveAndUpdate),
            switchMap(action =>
                this.clientDialogService.saveAndUpdateTextTemplate(action.friendlyName, action.displayName, action.content, action.isAccountShared, action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Saved!')
                            return actionClientDialogTextMessageTemplateSaveAndUpdateSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogTextMessageTemplateSaveAndUpdateFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogTextMessageTemplateSaveAndUpdateFailed())
                        })
                    )
                )
            )
        )
    );

    saveAndUpdateTextTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogTextMessageTemplateSaveAndUpdateSucceeded),
            switchMap(action =>
                [
                    actionClientDialogTextMessageTemplatesRequest(),
                ]
            )
        )
    );

    requestTextTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogTextMessageTemplatesRequest),
            switchMap(action =>
                this.clientDialogService.getTextTemplates().pipe(
                    map((response => {
                        if (response.status == 1) {
                            let templates: CommunicationTemplate[] = response.data
                            return actionClientDialogTextMessageTemplatesLoaded({ templates });
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogTextMessageTemplatesFalied();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogTextMessageTemplatesFalied())
                        })
                    )
                )
            )
        )
    );

    deleteTextTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogTextMessageTemplateDeleteRequest),
            switchMap(action =>
                this.clientDialogService.deleteTextTemplate(action.id).pipe(
                    map((response => {
                        if (response.status == 1) {
                            this.notificationService.success('Template Deleted!')
                            return actionClientDialogTextMessageTemplateDeleteSucceeded();
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogTextMessageTemplateDeleteFailed();
                        }
                    }),
                        catchError(error => {
                            return of(actionClientDialogTextMessageTemplateDeleteFailed())
                        })
                    )
                )
            )
        )
    );

    deleteTextTemplateSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogTextMessageTemplateDeleteSucceeded),
            switchMap(action =>
                [
                    actionClientDialogTextMessageTemplatesRequest(),
                ]
            )
        )
    );
}
