import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebApiResponse } from 'app/core/response/response.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class TenantDialogTenantTextMessageService {

  constructor(private http: HttpClient) { }

  sendTextMessage(id: number, text: string, sendFromId: string, sendTo: string) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/sendTextMessage`,
      {
        id: id,
        text: text,
        sendFromId: sendFromId,
        sendTo: sendTo
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  sendTextMessageAndCollaborate(leadId: number, text: string, sendFromId: number, sendTo: string) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/sendTextMessageAndCollaborate`,
      {
        id: leadId,
        text: text,
        sendFromId: sendFromId,
        sendTo: sendTo
      })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }


  saveAndUpdateTextTemplate(friendlyName: string, displayName: string, content: string, isAccountShared: boolean, id?: number) {
    const data = {
      friendlyName: friendlyName,
      displayName: displayName,
      content: content,
      id: id ? id : 0,
      isAccountShared: isAccountShared
    }
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/saveUpdateTextTemplate`, data)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  getTextTemplates() {
    return this.http.get(`${environment.apiBaseUrl}tenant-detail/getTextTemplates`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  deleteTextTemplate(id: number) {
    return this.http.post(`${environment.apiBaseUrl}tenant-detail/deleteCommunicationTemplate`, id)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }
}
