import { createReducer, on } from "@ngrx/store";
import { tenantDialogActions } from "../../tenant-dialog.actions";
import { TenantDialogTextMessageState } from "../../tenant-dialog.state";
import { tenantDialogTextMessageActions } from "./tenant-dialog-tenant-quick-text-message.action";

const initialSate: TenantDialogTextMessageState = {
    isTextMessageSubmitting: false,
    isTextMessageSubmittedSuccessfully: false,
    sendFromPhoneNumbers: [],
    textMessageEditor: {
        message: '',
        sendFrom: '',
        sendTo: ''
    },
    textMessageTemplates: [],
    isTemplatesLoaded: false,
    isTemplateSaveOrUpdateing: false,
    isTemplateSaveOrUpdateSuccessfully: false,
    isTemplateDeletedSuccessfully: false,
    isTemplateDeleting: false
}

const reducer = createReducer(
    initialSate,
    on(
        tenantDialogTextMessageActions.actionTenantDialogSendTextRequested,
        tenantDialogTextMessageActions.actionTenantDialogSendTextAndCollaborateRequested,
        (state) => ({
            ...state,
            isTextMessageSubmitting: true,
            isTextMessageSubmittedSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogSendTextSuccess,
        tenantDialogTextMessageActions.actionTenantDialogSendTextAndCollaborateSuccess,
        (state) => ({
            ...state,
            isTextMessageSubmitting: false,
            isTextMessageSubmittedSuccessfully: true
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogSendTextFailed,
        tenantDialogTextMessageActions.actionTenantDialogSendTextAndCollaborateFailed,
        (state) => ({
            ...state,
            isTextMessageSubmitting: false,
            isTextMessageSubmittedSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTextContentUpdate,
        (state, { message, sendFrom, sendTo }) => ({
            ...state,
            textMessageEditor: {
                message: message,
                sendFrom: sendFrom,
                sendTo: sendTo
            }
        })
    ),
    on(tenantDialogActions.actionTenantDialogCommunicationSettingsSucceded, (state, { emails, phoneNumbers, userId }) => ({
        ...state,
        sendFromPhoneNumbers: phoneNumbers,
        textMessageEditor: {
            ...state.textMessageEditor,
            sendFrom: phoneNumbers.find(x => x.userId == userId) != null ? phoneNumbers.find(x => x.userId == userId).id : phoneNumbers[0]?.id
        }
    })),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplatesLoaded,
        (state, { templates }) => ({
            ...state,
            textMessageTemplates: templates,
            isTemplatesLoaded: true
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateSaveAndUpdate,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: true,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateSaveAndUpdateSucceeded,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: true
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateSaveAndUpdateFailed,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateSaveAndUpdateStateReset,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateDeleteRequest,
        (state) => ({
            ...state,
            isTemplateDeleting: true,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateDeleteSucceeded,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: true
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateSaveAndUpdateFailed,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        tenantDialogTextMessageActions.actionTenantDialogTextMessageTemplateDeleteStateReset,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(tenantDialogTextMessageActions.actionTenantDialogTextMessagestateReset, (state) => initialSate),
)

export const tenantDialogTextMessageReducer = (state: TenantDialogTextMessageState | undefined, action) => reducer(state, action)