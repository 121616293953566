import { createAction, props } from '@ngrx/store';
import { Building, Communication, Conversation, ConversationType, User } from '../../tenant-unified-inbox.model';

export const actionTenantUnifiedInboxConversationsactiveConversationListTypeChange = createAction(
    '[Tenant Unified Inbox Conversation] Active Conversation Type Change',
    props<{ activeConversationListType: ConversationType }>()
)

export const actionTenantUnifiedInboxConversationsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Request',
    props<{ page: number, pageSize: number, itemCountPage: number }>()
)

export const actionTenantUnifiedInboxConversationsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Loaded',
    props<{ conversations: Conversation[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxConversationsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Failed',
)

export const actionTenantUnifiedInboxOutboundCommunicationsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications Request',
    props<{ page: number, pageSize: number, itemCountPage: number, tenantId: number, startDate: Date, endDate: Date }>()
)

export const actionTenantUnifiedInboxOutboundCommunicationsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxOutboundCommunicationsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications Failed',
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications Request',
    props<{ page: number, pageSize: number, itemCountPage: number, tenantId: number, startDate: Date, endDate: Date, userIds: number[] }>()
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications Failed',
)

export const actionTenantUnifiedInboxOutboundCommunicationsMoreRequest = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications More Request',
    props<{ page: number, pageSize: number, itemCountPage: number, tenantId: number, startDate: Date, endDate: Date }>()
)

export const actionTenantUnifiedInboxOutboundCommunicationsMoreLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications More Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxOutboundCommunicationsMoreFailed = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications More Failed',
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsMoreRequest = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications More Request',
    props<{ page: number, pageSize: number, itemCountPage: number, tenantId: number, startDate: Date, endDate: Date, userIds: number[] }>()
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsMoreLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications More Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsMoreFailed = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications More Failed',
)

export const actionTenantUnifiedInboxOutboundCommunicationsReset = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communications Reset',
)

export const actionTenantUnifiedInboxVoiceMailCommunicationsReset = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communications Reset',
)

export const actionTenantUnifiedInboxCompanyConversationsReset = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation Reset',
)

export const actionTenantUnifiedInboxCompanyConversationsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation Request',
    props<{
        page: number,
        pageSize: number,
        itemCountPage: number,
        userIds: number[],
        communicationTypes: string[],
        roles: string[],
        buildingIds: number[],
        lastCommunicationStartDate: Date,
        lastCommunicationEndDate: Date,
        createStartDate: Date,
        createEndDate: Date
    }>()
)

export const actionTenantUnifiedInboxCompanyConversationsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation Loaded',
    props<{ conversations: Conversation[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxCompanyConversationsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation Failed',
)

export const actionTenantUnifiedInboxCompanyConversationsMoreRequest = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation More Request',
    props<{
        page: number,
        pageSize: number,
        itemCountPage: number,
        userIds: number[],
        communicationTypes: string[],
        roles: string[],
        buildingIds: number[],
        lastCommunicationStartDate: Date,
        lastCommunicationEndDate: Date,
        createStartDate: Date,
        createEndDate: Date
    }>()
)

export const actionTenantUnifiedInboxCompanyConversationsMoreLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation More Loaded',
    props<{ conversations: Conversation[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionTenantUnifiedInboxCompanyConversationsMoreFailed = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation More Failed',
)

export const actionTenantUnifiedInboxNewConversationNotificationLoaded = createAction(
    '[Tenant Unified Inbox Conversation] New Conversation Notification Loaded',
    props<{ conversation: Conversation }>()
)

export const actionTenantUnifiedInboxNewCompanyConversationNotificationLoaded = createAction(
    '[Tenant Unified Inbox Conversation] New Company Conversation Notification Loaded',
    props<{ conversation: Conversation }>()
)

export const actionTenantUnifiedInboxCompanyConversationsUserIdUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation UserId Update',
    props<{ userId: number, isChecked: boolean }>()
)

export const actionTenantUnifiedInboxCompanyConversationsUserIdReset = createAction(
    '[Tenant Unified Inbox Conversation] Company Conversation UserId Reset',
)

export const actionTenantUnifiedInboxCompanyConversationsBuildingIdUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Building Id Update',
    props<{ buildingId: number, isChecked: boolean }>()
)

export const actionTenantUnifiedInboxCompanyConversationsBuildingIdReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Building Id Reset',
)

export const actionTenantUnifiedInboxConversationsPaginationReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Pagination Reset',
)

export const actionTenantUnifiedInboxConversationsCompanyPaginationReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Pagination Reset',
)

export const actionTenantUnifiedInboxConversationsOutboundPaginationReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Outbound Pagination Reset',
)

export const actionTenantUnifiedInboxConversationsVoiceMailPaginationReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Voice Mail Pagination Reset',
)

export const actionTenantUnifiedInboxConversationsCompanyFilterTypeUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Type Update',
    props<{ communicationType: string, isChecked: boolean }>()
)

export const actionTenantUnifiedInboxConversationsCompanyFilterTypeReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Type Reset',
)

export const actionTenantUnifiedInboxConversationsCompanyFilterLastCommunicationDateUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Last Communication Date Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionTenantUnifiedInboxConversationsCompanyFilterCreateDateUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Create Date Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionTenantUnifiedInboxConversationsCompanyFilterRoleUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Role Update',
    props<{ role: string, isChecked: boolean }>()
)

export const actionTenantUnifiedInboxConversationsCompanyFilterRoleReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Role Reset',
)

export const actionTenantUnifiedInboxConversationsCompanyFilterBuildingUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Building Update',
    props<{ buildingId: number, isChecked: boolean }>()
)

export const actionTenantUnifiedInboxConversationsCompanyFilterBuildingReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Filter Building Reset',
)

export const actionTenantUnifiedInboxConversationsUserOptionsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Conversation User Options Request',
    props<{ roles: string[] }>()
)

export const actionTenantUnifiedInboxConversationsUserOptionsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Conversation User Options Loaded',
    props<{ userOptions: User[] }>()
)

export const actionTenantUnifiedInboxConversationsUserOptionsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Conversation User Options Failed',
)

export const actionTenantUnifiedInboxConversationsVoiceMailUserOptionsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Voice Mail User Options Request',
    props<{ roles: string[] }>()
)

export const actionTenantUnifiedInboxConversationsVoiceMailUserOptionsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Voice Mail User Options Loaded',
    props<{ userOptions: User[] }>()
)

export const actionTenantUnifiedInboxConversationsVoiceMailUserOptionsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Voice Mail User Options Failed',
)

export const actionTenantUnifiedInboxConversationsBuildingOptionsRequest = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Building Options Request',
)

export const actionTenantUnifiedInboxConversationsBuildingOptionsLoaded = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Building Options Loaded',
    props<{ buildingOptions: Building[] }>()
)

export const actionTenantUnifiedInboxConversationsBuildingOptionsFailed = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Building Options Failed',
)

export const actionTenantUnifiedInboxConversationsCompanyInboxFiltersReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Company Inbox Filters Reset',
)

export const actionTenantUnifiedInboxConversationsConversationsReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Conversations Reset',
)

export const actionTenantUnifiedInboxConversationNotificationFailed = createAction(
    '[Tenant Unified Inbox Conversation] New Conversation Failed'
)

export const actionTenantUnifiedInboxMarkConversationReadRequested = createAction(
    '[Tenant Unified Inbox Conversation] Mark Conversation Read Requested',
    props<{ conversation: Conversation }>()
)

export const actionTenantUnifiedInboxMarkConversationReadFailed = createAction(
    '[Tenant Unified Inbox Conversation] Mark Conversation Read Failed',
)

export const actionTenantUnifiedInboxMarkConversationUnreadSuccess = createAction(
    '[Tenant Unified Inbox Conversation] Mark Conversation Unread Success',
    props<{ conversationId: number }>()
)

export const actionTenantUnifiedInboxMarkConversationUnreadFailed = createAction(
    '[Tenant Unified Inbox Conversation] Mark Conversation Unread Failed'
)

export const actionTenantUnifiedInboxMarkConversationConversationSelectedUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Conversation Selected Update',
    props<{ conversation: Conversation }>()
)

export const actionTenantUnifiedInboxOutboundCommunicationSelectedUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communication Selected Update',
    props<{ communication: Communication }>()
)

export const actionTenantUnifiedInboxVoiceMailCommunicationSelectedUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communication Selected Update',
    props<{ communication: Communication }>()
)

export const actionTenantUnifiedInboxOutboundCommunicationReset = createAction(
    '[Tenant Unified Inbox Conversation] Outbound Communication Reset',
)

export const actionTenantUnifiedInboxVoiceMailCommunicationReset = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Communication Reset',
)

export const actionTenantUnifiedInboxOutboxFilterTenantInfoUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Outox Filter Tenant Id Update',
    props<{ tenantId: number, tenantFirstName: string, tenantLastName: string }>()
)

export const actionTenantUnifiedInboxOutboxFilterDateRangeUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Outox Filter Date Range Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionTenantUnifiedInboxOutboxFilterReset = createAction(
    '[Tenant Unified Inbox Conversation] Outox Filter Reset',
)

export const actionTenantUnifiedInboxVoiceMailFilterTenantInfoUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Filter Tenant Id Update',
    props<{ tenantId: number, tenantFirstName: string, tenantLastName: string }>()
)

export const actionTenantUnifiedInboxVoiceMailFilterDateRangeUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Filter Date Range Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionTenantUnifiedInboxVoiceMailFilterUserIdUpdate = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Filter User Id Update',
    props<{ userId: number, isChecked: boolean }>()
)

export const actionTenantUnifiedInboxVoiceMailFilterUserIdReset = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Filter User Id Reset',
)


export const actionTenantUnifiedInboxVoiceMailFilterReset = createAction(
    '[Tenant Unified Inbox Conversation] Voice Mail Filter Reset',
)

export const actionTenantUnifiedInboxCloseActiveConversationRequested = createAction(
    '[Tenant Unified Inbox Conversation] Close Active Conversation Requested',
    props<{ conversationId: number }>()
)

export const actionTenantUnifiedInboxCollaborateRequested = createAction(
    '[Tenant Unified Inbox Conversation] Collaborate Requested',
    props<{ tenantId: number }>()
)

export const actionTenantUnifiedInboxCollaborateSuccess = createAction(
    '[Tenant Unified Inbox Conversation] Collaborate Success',
)

export const actionTenantUnifiedInboxCollaborateFailed = createAction(
    '[Tenant Unified Inbox Conversation] Collaborate Failed',
)

export const actionTenantUnifiedInboxCloseActiveConversationSuccess = createAction(
    '[Tenant Unified Inbox Conversation] Close Active Conversation Success',
    props<{ conversationId: number, totalRecords: number }>()
)

export const actionTenantUnifiedInboxCloseActiveConversationFailed = createAction(
    '[Tenant Unified Inbox Conversation] Close Active Conversation Failed',
)

export const actionTenantUnifiedInboxCreateNewConversationRequested = createAction(
    '[Tenant Unified Inbox Conversation] Cerate New Conversation Requested',
    props<{ tenantId: number }>()
)

export const actionTenantUnifiedInboxCreateNewConversationSuccess = createAction(
    '[Tenant Unified Inbox Conversation] Create New Conversation Success',
    props<{ conversation: Conversation }>()
)

export const actionTenantUnifiedInboxCreateNewConversationFailed = createAction(
    '[Tenant Unified Inbox Conversation] Create New Conversation Failed',
)

export const actionTenantUnifiedInboxConversationStateReset = createAction(
    '[Tenant Unified Inbox Conversation] Conversation State Reset',
)

export const tenantUnifiedInboxConversationActions = {
    actionTenantUnifiedInboxConversationsactiveConversationListTypeChange,
    actionTenantUnifiedInboxConversationsRequest,
    actionTenantUnifiedInboxConversationsLoaded,
    actionTenantUnifiedInboxConversationsFailed,
    actionTenantUnifiedInboxOutboundCommunicationsRequest,
    actionTenantUnifiedInboxOutboundCommunicationsLoaded,
    actionTenantUnifiedInboxOutboundCommunicationsFailed,
    actionTenantUnifiedInboxOutboundCommunicationsMoreRequest,
    actionTenantUnifiedInboxOutboundCommunicationsMoreLoaded,
    actionTenantUnifiedInboxOutboundCommunicationsMoreFailed,
    actionTenantUnifiedInboxOutboundCommunicationsReset,
    actionTenantUnifiedInboxVoiceMailCommunicationsRequest,
    actionTenantUnifiedInboxVoiceMailCommunicationsLoaded,
    actionTenantUnifiedInboxVoiceMailCommunicationsFailed,
    actionTenantUnifiedInboxVoiceMailCommunicationsMoreRequest,
    actionTenantUnifiedInboxVoiceMailCommunicationsMoreLoaded,
    actionTenantUnifiedInboxVoiceMailCommunicationsMoreFailed,
    actionTenantUnifiedInboxVoiceMailCommunicationsReset,
    actionTenantUnifiedInboxCompanyConversationsReset,
    actionTenantUnifiedInboxCompanyConversationsRequest,
    actionTenantUnifiedInboxCompanyConversationsLoaded,
    actionTenantUnifiedInboxCompanyConversationsFailed,
    actionTenantUnifiedInboxCompanyConversationsMoreRequest,
    actionTenantUnifiedInboxCompanyConversationsMoreLoaded,
    actionTenantUnifiedInboxCompanyConversationsMoreFailed,
    actionTenantUnifiedInboxConversationStateReset,
    actionTenantUnifiedInboxConversationsUserOptionsRequest,
    actionTenantUnifiedInboxConversationsUserOptionsLoaded,
    actionTenantUnifiedInboxConversationsUserOptionsFailed,
    actionTenantUnifiedInboxConversationsVoiceMailUserOptionsRequest,
    actionTenantUnifiedInboxConversationsVoiceMailUserOptionsLoaded,
    actionTenantUnifiedInboxConversationsVoiceMailUserOptionsFailed,
    actionTenantUnifiedInboxConversationsBuildingOptionsRequest,
    actionTenantUnifiedInboxConversationsBuildingOptionsLoaded,
    actionTenantUnifiedInboxConversationsBuildingOptionsFailed,
    actionTenantUnifiedInboxNewConversationNotificationLoaded,
    actionTenantUnifiedInboxNewCompanyConversationNotificationLoaded,
    actionTenantUnifiedInboxConversationNotificationFailed,
    actionTenantUnifiedInboxMarkConversationReadRequested,
    actionTenantUnifiedInboxMarkConversationReadFailed,
    actionTenantUnifiedInboxMarkConversationUnreadSuccess,
    actionTenantUnifiedInboxMarkConversationUnreadFailed,
    actionTenantUnifiedInboxCompanyConversationsUserIdUpdate,
    actionTenantUnifiedInboxCompanyConversationsUserIdReset,
    actionTenantUnifiedInboxCompanyConversationsBuildingIdUpdate,
    actionTenantUnifiedInboxCompanyConversationsBuildingIdReset,
    actionTenantUnifiedInboxConversationsCompanyInboxFiltersReset,
    actionTenantUnifiedInboxConversationsCompanyFilterTypeReset,
    actionTenantUnifiedInboxConversationsCompanyFilterTypeUpdate,
    actionTenantUnifiedInboxConversationsCompanyFilterRoleUpdate,
    actionTenantUnifiedInboxConversationsCompanyFilterRoleReset,
    actionTenantUnifiedInboxConversationsCompanyFilterBuildingUpdate,
    actionTenantUnifiedInboxConversationsCompanyFilterBuildingReset,
    actionTenantUnifiedInboxConversationsCompanyFilterLastCommunicationDateUpdate,
    actionTenantUnifiedInboxConversationsCompanyFilterCreateDateUpdate,
    actionTenantUnifiedInboxConversationsPaginationReset,
    actionTenantUnifiedInboxConversationsCompanyPaginationReset,
    actionTenantUnifiedInboxConversationsOutboundPaginationReset,
    actionTenantUnifiedInboxConversationsVoiceMailPaginationReset,
    actionTenantUnifiedInboxMarkConversationConversationSelectedUpdate,
    actionTenantUnifiedInboxOutboundCommunicationSelectedUpdate,
    actionTenantUnifiedInboxOutboundCommunicationReset,
    actionTenantUnifiedInboxVoiceMailCommunicationSelectedUpdate,
    actionTenantUnifiedInboxVoiceMailCommunicationReset,
    actionTenantUnifiedInboxConversationsConversationsReset,
    actionTenantUnifiedInboxCloseActiveConversationRequested,
    actionTenantUnifiedInboxCloseActiveConversationSuccess,
    actionTenantUnifiedInboxCreateNewConversationRequested,
    actionTenantUnifiedInboxCreateNewConversationSuccess,
    actionTenantUnifiedInboxCreateNewConversationFailed,
    actionTenantUnifiedInboxCloseActiveConversationFailed,
    actionTenantUnifiedInboxOutboxFilterTenantInfoUpdate,
    actionTenantUnifiedInboxOutboxFilterDateRangeUpdate,
    actionTenantUnifiedInboxOutboxFilterReset,
    actionTenantUnifiedInboxVoiceMailFilterTenantInfoUpdate,
    actionTenantUnifiedInboxVoiceMailFilterDateRangeUpdate,
    actionTenantUnifiedInboxVoiceMailFilterReset,
    actionTenantUnifiedInboxVoiceMailFilterUserIdUpdate,
    actionTenantUnifiedInboxVoiceMailFilterUserIdReset,
    actionTenantUnifiedInboxCollaborateRequested,
    actionTenantUnifiedInboxCollaborateSuccess,
    actionTenantUnifiedInboxCollaborateFailed
};
