<div class="tenant-dialog-task-create-dialog-component">
    <div class="create-task-dialog-title">
        <div>
            <mat-icon class="task-icon">list</mat-icon>
            <h4>Create</h4>
        </div>
              <button mat-icon-button (click)="$event.preventDefault();close()">
            <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <div class="form-container">
        <div [formGroup]="taskCreateForm">
            <div class="container">
                <div class="row">
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label>Task Name</mat-label>
                        <input type="text" matInput formControlName="taskName" required placeholder="task name">
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Task</mat-label>
                        <mat-select formControlName="taskType" required>
                            <mat-option *ngFor="let taskType of taskTypes" [value]="taskType.value">
                                {{taskType.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Assign To</mat-label>
                        <mat-select formControlName="userId" required>
                            <mat-option *ngFor="let user of taskAssignUsers$ | async" [value]="user.id">
                                {{user.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline" (click)="moveInDatepicker.open()">
                        <mat-label>Date</mat-label>
                        <input readonly matInput required formControlName="date" required [min]="today"
                            [matDatepicker]="moveInDatepicker">
                        <mat-datepicker-toggle matSuffix [for]="moveInDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #moveInDatepicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-12 time-mat-field" appearance="outline">
                        <input style="display: none" matInput>
                        <ngx-timepicker-field [format]="12" formControlName="time" [clockTheme]="dashQTheme">
                        </ngx-timepicker-field>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>TimeZone</mat-label>
                        <mat-select formControlName="timeZone" required>
                            <mat-option *ngFor="let timeZone of timeZoneOptions" [value]="timeZone.id">
                                {{timeZone.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Remind By</mat-label>
                        <mat-select formControlName="taskReminderType" required>
                            <mat-option *ngFor="let taskReminderType of taskReminderTypes"
                                [value]="taskReminderType.value">
                                {{taskReminderType.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Remind Before</mat-label>
                        <mat-select formControlName="taskReminderTimeBefore" required>
                            <mat-select-trigger>
                                {{taskCreateForm.get('taskReminderTimeBefore').value}} mins
                            </mat-select-trigger>
                            <mat-option *ngFor="let taskReminderTimeBefore of taskReminderTimeBeforeOptions"
                                [value]="taskReminderTimeBefore.value">
                                {{taskReminderTimeBefore.value}} mins</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                </div>
            </div>
        </div>
        <div class="button-div col-12">
            <button mat-stroked-button class="button cancel-button" (click)="cancel()">Cancel</button>
            <dq-spinner-button class="button" type="button" (click)="create()" [options]="spinnerCreateButtonOptions">
            </dq-spinner-button>
        </div>
    </div>
</div>
