import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteConfrimParam } from '../delete-remove-confirm.model';
import { MatProgressButtonOptions } from '../../spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-delete-remove-confirm-dialog',
  templateUrl: './delete-remove-confirm-dialog.component.html',
  styleUrls: ['./delete-remove-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DeleteRemoveConfirmDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<DeleteRemoveConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteConfrimParam,
    private cdr: ChangeDetectorRef,
  ) {
    this.title = data.title
    this.content = data.content
    this.spinnerSaveButtonOptions.text = data.deleteButtonText
    this.spinnerSaveButtonOptions.buttonColor = data.deleteButtonColor
    this.isDeleting$ = data.isDeleting$
    this.isDeleteSuccessfully$ = data.isDeleteSuccessfully$
    this.deleteAction = data.deleteAction
  }

  unsubscribe: Subject<void> = new Subject();

  isDeleting$: Observable<boolean>
  isDeleteSuccessfully$: Observable<boolean>

  deleteAction: any

  title: string = 'Delete Confirm'
  content: string = ''

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Delete',
    spinnerSize: 18,
    flat: true,
    buttonColor: 'warn',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  ngOnInit(): void {

    this.isDeleting$.pipe(takeUntil(this.unsubscribe)).subscribe(isDeleting => {
      this.spinnerSaveButtonOptions.active = isDeleting
      this.cdr.markForCheck()
    })

    this.isDeleteSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isDeleteSuccessfully => {
      if (isDeleteSuccessfully) {
        this.dialogRef.close(true);
      }
    })
  }

  confirm() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    this.store.dispatch(this.deleteAction)
  }

  cancel() {
    this.dialogRef.close(false);
  }

  close() {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}
