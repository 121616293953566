import {Injectable} from '@angular/core';
import algoliasearch from 'algoliasearch';
import {Observable, of} from 'rxjs';
import {
  AlgoliaBuildingQueryParameterTypes,
  AlgoliaOperatorTypes,
  AlgoliaPropertyQueryParameterTypes
} from './model/query-builder.model';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import {environment} from '../../../environments/environment';
import {
  AlgoliaGlobalSearchResult,
  AlgoliaPropertySearchResult,
  BoundingBox,
  Marker,
  MarkersSearchResult
} from './model/global-search-result.model';
import {ISimpleItem} from '../../shared/models/simple-item.model';
import {Building} from '../../shared/models/building.model';
import {IBuildingThumbnail} from '../../shared/models/building-thumbnail.model';
import {IPropertyThumbnail} from '../../shared/models/property-thumbnail.model';
import {Area} from '../../shared/models/area.model';
import {PropertyBuildingFilters} from 'app/shared/models/property-building-search.model';

@Injectable()
export class AlgoliaQueryService {
  private buildingIndex: any;
  private propertyIndex: any;
  private areaIndex: any;
  private client: any;
  private queriesIndex = [
    environment.algolia.algoliaAreaIndex,
    environment.algolia.algoliaBuildingIndex,
    environment.algolia.algoliaPropertyIndex
  ];

  constructor() {
    this.client = algoliasearch(
      environment.algolia.algoliaAppId,
      environment.algolia.algoliaSearchApiKey
    );
    this.buildingIndex = this.client.initIndex(
      environment.algolia.algoliaBuildingIndex
    );
    this.propertyIndex = this.client.initIndex(
      environment.algolia.algoliaPropertyIndex
    );
    this.areaIndex = this.client.initIndex(
      environment.algolia.algoliaAreaIndex
    );
  }

  getAlgoliaGlobalSearchResult(
    query: string,
    filterQuery: string,
    page: number,
    hitsPerPage: number,
    queriesIndex: string[] = this.queriesIndex
  ): Observable<AlgoliaGlobalSearchResult.SearchResultItemGroup[]> {
    const queries = [];
    if (queriesIndex.length <= 0) return of(null);
    for (const queryIndex in queriesIndex) {
      if (Object.prototype.hasOwnProperty.call(queriesIndex, queryIndex)) {
        queries.push({
          indexName: queriesIndex[queryIndex],
          query,
          params: {
            filters: filterQuery,
            page,
            hitsPerPage
          }
        });
      }
    }

    return new Observable(observer =>
      this.client
        .search(queries)
        .then(
          (hitsResults: AlgoliaGlobalSearchResult.MultipleSearchResults) => {
            let resultItemGroups: AlgoliaGlobalSearchResult.SearchResultItemGroup[] = [];

            hitsResults.results.forEach(hitsResult => {
              if (
                hitsResult.index === environment.algolia.algoliaBuildingIndex
              ) {
                const resultItems: ISimpleItem[] = hitsResult.hits.map(
                  result =>
                    ({
                      id: result.objectID,
                      label: result.name,
                      extra: result.fullAddress,
                      uri: result.uri
                    } as ISimpleItem)
                );

                const resultItemGroup: AlgoliaGlobalSearchResult.SearchResultItemGroup = {
                  index: hitsResult.index,
                  searchResultItems: resultItems
                };

                resultItemGroups = resultItemGroups.concat(resultItemGroup);
              } else if (
                hitsResult.index === environment.algolia.algoliaPropertyIndex
              ) {
                const resultItems: ISimpleItem[] = hitsResult.hits.map(
                  result =>
                   ( {
                      id: result.objectID,
                      label: `${result.propertyBuilding.name} | Unit ${result.unit} `,
                      extra: `${result.listingId} | ${result.propertyBuilding.fullAddress}`,
                      uri: result.uri
                    } as ISimpleItem)
                );

                const resultItemGroup: AlgoliaGlobalSearchResult.SearchResultItemGroup = {
                  index: hitsResult.index,
                  searchResultItems: resultItems
                };

                resultItemGroups = resultItemGroups.concat(resultItemGroup);
              } else if (
                hitsResult.index === environment.algolia.algoliaAreaIndex
              ) {
                const resultItems: ISimpleItem[] = hitsResult.hits.map(
                  result =>
                    ({
                      id: result.objectID,
                      label: result.name,
                      extra: `${result.meta}`,
                      uri: result.uri
                    } as ISimpleItem)
                );

                const resultItemGroup: AlgoliaGlobalSearchResult.SearchResultItemGroup = {
                  index: hitsResult.index,
                  searchResultItems: resultItems
                };

                resultItemGroups = resultItemGroups.concat(resultItemGroup);
              }
            });

            observer.next(resultItemGroups);
          }
        )
    );
  }

  getAlgoliaBuildingSearchResult(
    query: string,
    filterQuery: string,
    page: number,
    hitsPerPage: number,
    boundingBox?: BoundingBox
  ): Observable<Building.BuildingThumbnailSearchResult> {
    let topParams: any;

    if (boundingBox !== undefined) {
      topParams = {
        filters: filterQuery,
        insideBoundingBox: [
          [
            boundingBox.p1Lat,
            boundingBox.p1Lng,
            boundingBox.p2Lat,
            boundingBox.p2Lng
          ]
        ],
        page,
        hitsPerPage
      };
    } else {
      topParams = {
        filters: filterQuery,
        page,
        hitsPerPage
      };
    }

    return new Observable(observer =>
      this.buildingIndex
        .search(query, topParams)
        .then((hitsResult: Building.SearchResult) => {
          const buildingThumbnail: IBuildingThumbnail[] = hitsResult.hits.map(
            b =>
              ({
                id: b.objectID,
                uri: b.uri,
                lat: b.lat,
                lng: b.lng,
                buildingName: b.name,
                streetAddress: b.streetAddress,
                isFavoriteActive: false,
                averagePrice: 0,
                listingsForSaleCount: b.listingsForSaleCount,
                listingsForRentCount: b.listingsForRentCount,
                buildingTypes: b.buildingTypes,
                buildingStyles: b.buildingStyles,
                photos: b.photoSeq.map(p => b.photoBaseUri + '-' + p)
              } as IBuildingThumbnail)
          );

          observer.next({
            hits: buildingThumbnail,
            page: hitsResult.page,
            nbPages: hitsResult.nbPages,
            nbHits: hitsResult.nbHits,
            hitsPerPage: hitsResult.hitsPerPage
          } as Building.BuildingThumbnailSearchResult);
        })
    );
  }

  getAlgoliaPropertyBuildingMarkersSearchResult(
    query: string,
    filterQuery: string,
    page: number,
    hitsPerPage: number,
    boundingBox?: BoundingBox
  ): Observable<Building.BuildingThumbnailSearchResult> {
    let topParams: any;

    if (boundingBox !== undefined) {
      topParams = {
        filters: filterQuery,
        responseFields: ['hits'],
        insideBoundingBox: [
          [
            boundingBox.p1Lat,
            boundingBox.p1Lng,
            boundingBox.p2Lat,
            boundingBox.p2Lng
          ]
        ],
        page,
        hitsPerPage
      };
    } else {
      topParams = {
        filters: filterQuery,
        page,
        hitsPerPage
      };
    }

    return Observable.create(observer =>
      this.buildingIndex
        .search(query, topParams)
        .then((hitsResult: Building.SearchResult) => {
          const markers: Marker[] = hitsResult.hits.map(b => ({
            id: b.objectID,
            uri: b.uri,
            lat: b.lat,
            lng: b.lng,
            buildingName: b.name,
            streetAddress: b.streetAddress,
            listingsForSaleCount: b.listingsForSaleCount,
            listingsForRentCount: b.listingsForRentCount,
            type: b.buildingStyles
          }));

          observer.next({
            hits: markers,
            page: hitsResult.page,
            nbPages: hitsResult.nbPages,
            nbHits: hitsResult.nbHits,
            hitsPerPage: hitsResult.hitsPerPage
          } as MarkersSearchResult);
        })
    );
  }

  getAlgoliaPropertySearchResult(
    query: string,
    filterQuery: string,
    page: number,
    hitsPerPage: number,
    boundingBox?: BoundingBox
  ): Observable<AlgoliaPropertySearchResult.PropertyThumbnailSearchResult> {
    let topParams: any;

    if (boundingBox !== undefined) {
      topParams = {
        filters: filterQuery,
        insideBoundingBox: [
          [
            boundingBox.p1Lat,
            boundingBox.p1Lng,
            boundingBox.p2Lat,
            boundingBox.p2Lng
          ]
        ],
        page,
        hitsPerPage
      };
    } else {
      topParams = {
        filters: filterQuery,
        page,
        hitsPerPage
      };
    }

    return new Observable(observer =>
      this.propertyIndex
        .search(query, topParams)
        .then((hitsResult: AlgoliaPropertySearchResult.SearchResult) => {
          const propertyThumbnail: IPropertyThumbnail[] = hitsResult.hits.map(
            p =>
             ( {
                id: p.objectID,
                uri: p.uri,
                lat: p.propertyBuilding ? p.propertyBuilding.lat : undefined,
                lng: p.propertyBuilding ? p.propertyBuilding.lng : undefined,
                price: p.price,
                beds: p.beds,
                baths: p.baths,
                size: p.size,
                sizeMin: p.sizeMin,
                sizeMax: p.sizeMax,
                parkingSpace: p.parkingSpace,
                fees: p.fees,
                transactionType: p.transactionType,
                buildingName: p.propertyBuilding ? p.propertyBuilding.name : '',
                streetAddress: p.propertyBuilding
                  ? p.propertyBuilding.streetAddress
                  : '',
                marketDateTime: p.marketDate_timestamp,
                buildingStyles: p.propertyBuilding
                  ? p.propertyBuilding.buildingStyles
                  : [],
                photos: p.photoSeq.map(ph => p.photoBaseUri + '-' + ph)
              } as IPropertyThumbnail)
          );

          observer.next({
            hits: propertyThumbnail,
            page: hitsResult.page,
            nbPages: hitsResult.nbPages,
            nbHits: hitsResult.nbHits,
            hitsPerPage: hitsResult.hitsPerPage
          } as AlgoliaPropertySearchResult.PropertyThumbnailSearchResult);
        })
    );
  }

  private getGroupedCriterias(
    filterCriterias: PropertyBuildingFilters.FilterCriterias[],
    staticFilterCriterias: PropertyBuildingFilters.FilterCriterias[]
  ): PropertyBuildingFilters.FilterCriterias[] {
    if (
      filterCriterias.some(
        e =>
          e.type ===
          PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_GEO_POLYGONS
      )
    ) {
      staticFilterCriterias = staticFilterCriterias.filter(
        x =>
          x.type !==
          PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_GEO_POLYGONS
      );
    }

    filterCriterias = filterCriterias.concat(staticFilterCriterias);

    return groupBy(filterCriterias, criterias => criterias.type);
  }

  getAlgoliaAreaSearchResult(
    query: string,
    filterQuery: string,
    page: number,
    hitsPerPage: number
  ): Observable<Area.AreaSearchResult> {
    return new Observable(observer =>
      this.areaIndex
        .search(query, {
          filters: filterQuery,
          page,
          hitsPerPage
        })
        .then((hitsResult: Area.AreaSearchResult) => {
          observer.next({
            hits: hitsResult.hits,
            page: hitsResult.page,
            nbPages: hitsResult.nbPages,
            nbHits: hitsResult.nbHits,
            hitsPerPage: hitsResult.hitsPerPage
          } as Area.AreaSearchResult);
        })
    );
  }

  buildBuildingIndexQuery(
    filterCriterias: PropertyBuildingFilters.FilterCriterias[],
    staticFilterCriterias: PropertyBuildingFilters.FilterCriterias[]
  ): string {
    const globalQueryItems: string[] = [];

    if (filterCriterias === undefined || staticFilterCriterias === undefined)
      return '';

    const groupedCriterias = this.getGroupedCriterias(
      filterCriterias,
      staticFilterCriterias
    );

    forEach(groupedCriterias, (groupedfilterCriterias) => {
      const queryItems: string[] = [];

      groupedfilterCriterias.forEach(filterCriteria => {
        switch (filterCriteria.type) {
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_GEO_POLYGONS: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.geoPolygons +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_TYPES: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.buildingTypes +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_STYLES: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.buildingStyles +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_AMENITIES: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.amenities +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_LISTINGS_FOR_SALE_COUNT: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForSaleCount +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForSaleCount +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_LISTINGS_FOR_RENT_COUNT: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForRentCount +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForRentCount +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_AGE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.buildingAge +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.buildingAge +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      });

      globalQueryItems.push(queryItems.join(' OR '));
    });

    return globalQueryItems.join(' AND ');
  }

  buildPropertyIndexQuery(
    filterCriterias: PropertyBuildingFilters.FilterCriterias[],
    staticFilterCriterias: PropertyBuildingFilters.FilterCriterias[]
  ): string {
    const globalQueryItems: string[] = [];

    if (filterCriterias === undefined || staticFilterCriterias === undefined)
      return '';

    const groupedCriterias = this.getGroupedCriterias(
      filterCriterias,
      staticFilterCriterias
    );

    forEach(groupedCriterias, (groupedfilterCriterias) => {
      const queryItems: string[] = [];

      groupedfilterCriterias.forEach(filterCriteria => {
        switch (filterCriteria.type) {
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_GEO_POLYGONS: {
            queryItems.push(
              AlgoliaPropertyQueryParameterTypes.geoPolygons +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_TYPES: {
            queryItems.push(
              AlgoliaPropertyQueryParameterTypes.buildingTypes +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_STYLES: {
            queryItems.push(
              AlgoliaPropertyQueryParameterTypes.buildingStyles +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_BEDS: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.beds +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.beds +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_BATHS: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.baths +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.baths +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_PRICE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.price +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.minValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.price +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_TO: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.price +
                  ': ' +
                  filterCriteria.criteria.minValue +
                  AlgoliaOperatorTypes.OPERATOR_TO +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_TRANSACTION_TYPE: {
            queryItems.push(
              AlgoliaPropertyQueryParameterTypes.transactionType +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_SIZE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.size +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.minValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.size +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_TO: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.size +
                  ': ' +
                  filterCriteria.criteria.minValue +
                  AlgoliaOperatorTypes.OPERATOR_TO +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_AMENITIES: {
            queryItems.push(
              AlgoliaPropertyQueryParameterTypes.amenities +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_MARKET_DATE_DAYS: {
            const date = new Date();
            date.setDate(date.getDate() - filterCriteria.criteria.value);
            const timestampUtcNow: number = Date.UTC(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate(),
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds()
            );
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.marketDateTimestamp +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  `${Math.floor(timestampUtcNow / 1000)}`
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.marketDateTimestamp +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  `${Math.floor(timestampUtcNow / 1000)}`
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_AGE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.buildingAge +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaPropertyQueryParameterTypes.buildingAge +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      });

      globalQueryItems.push(queryItems.join(' OR '));
    });

    return globalQueryItems.join(' AND ');
  }

  buildBuildingPropertyIndexQuery(
    filterCriterias: PropertyBuildingFilters.FilterCriterias[],
    staticFilterCriterias: PropertyBuildingFilters.FilterCriterias[]
  ): string {
    const globalQueryItems: string[] = [];

    if (filterCriterias === undefined || staticFilterCriterias === undefined)
      return '';

    const groupedCriterias = this.getGroupedCriterias(
      filterCriterias,
      staticFilterCriterias
    );

    groupBy(groupedCriterias, (groupedfilterCriterias) => {
      const queryItems: string[] = [];

      groupedfilterCriterias.forEach(filterCriteria => {
        switch (filterCriteria.type) {
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_GEO_POLYGONS: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.geoPolygons +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_TYPES: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.buildingTypes +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_STYLES: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.buildingStyles +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_LISTINGS_FOR_SALE_COUNT: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForSaleCount +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForSaleCount +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_LISTINGS_FOR_RENT_COUNT: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForRentCount +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.listingsForRentCount +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_BEDS: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.beds +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.beds +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_BATHS: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.baths +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.baths +
                  AlgoliaOperatorTypes.OPERATOR_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_PRICE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.price +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.minValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.price +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_TO: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.price +
                  ': ' +
                  filterCriteria.criteria.minValue +
                  AlgoliaOperatorTypes.OPERATOR_TO +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_TRANSACTION_TYPE: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.transactionType +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_SIZE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.size +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.minValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.size +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes.OPERATOR_TO: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.size +
                  ': ' +
                  filterCriteria.criteria.minValue +
                  AlgoliaOperatorTypes.OPERATOR_TO +
                  filterCriteria.criteria.maxValue
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_AMENITIES: {
            queryItems.push(
              AlgoliaBuildingQueryParameterTypes.amenities +
              ':' +
              filterCriteria.criteria.value
            );
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_MARKET_DATE_DAYS: {
            const date = new Date();
            date.setDate(date.getDate() - filterCriteria.criteria.value);
            const timestampUtcNow: number = Date.UTC(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate(),
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds()
            );
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.marketDateTimestamp +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  `${Math.floor(timestampUtcNow / 1000)}`
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.marketDateTimestamp +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  `${Math.floor(timestampUtcNow / 1000)}`
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case PropertyBuildingFilters.FilterCriteriaTypes
            .QUERY_PARAMETER_AGE: {
            switch (filterCriteria.criteria.operator.type) {
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_OPEN_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.buildingAge +
                  AlgoliaOperatorTypes.OPERATOR_OPEN_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              case PropertyBuildingFilters.FilterOperatorTypes
                .OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL: {
                queryItems.push(
                  AlgoliaBuildingQueryParameterTypes.buildingAge +
                  AlgoliaOperatorTypes.OPERATOR_CLOSE_ANGLED_BRACKET_EQUAL +
                  filterCriteria.criteria.value
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      });

      globalQueryItems.push(queryItems.join(' OR '));
    });

    return globalQueryItems.join(' AND ');
  }
}
