import { Injectable } from '@angular/core';
import {
  RouterStateSnapshot,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment,
  Router
} from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './router.state';

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  constructor(private router: Router) {}

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    const tree: UrlTree = this.router.parseUrl(url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];

    let urlTree: string[] = [];

    if (g !== undefined) {
      const urlSegment: UrlSegment[] = g.segments;
      urlSegment.forEach(element => {
        urlTree.push(element.path);
      });
    }

    return { url, urlTree, params, queryParams };
  }
}
