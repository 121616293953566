import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { takeUntil } from 'rxjs/operators';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { RentalItem } from 'app/features/client-dialog/client-dialog-model';
import { selectClientDialogRentalApplicationIsRentalItemOptionsLoaded, selectClientDialogRentalApplicationIsRentalItemTypesLoaded, selectClientDialogRentalApplicationIsRentalItemUpdating, selectClientDialogRentalApplicationRentalItemOptions, selectClientDialogRentalApplicationRentalItemOptionsPagination, selectClientDialogRentalApplicationRentalItemOptionsTotalRecords, selectClientDialogRentalApplicationRentalItemTypes, selectClientDialogRentalApplicationRentalItemTypeSelected } from '../../client-dialog-rental-application.selector';
import { actionClientDialogApplicationAddRentalItemsToRentalReqest, actionClientDialogApplicationGetRentalItemOptionsForRentalReqest, actionClientDialogApplicationGetRentalItemTypeSelected, actionClientDialogApplicationGetRentalItemTypesForRentalReqest, actionClientDialogApplicationRentalItemOptionsNextPage, actionClientDialogApplicationRentalItemStateReset } from '../../client-dialog-rental-application.action';
import { RENTAL_ITEM_TYPES } from 'app/features/building/building.model';
import {  MatTableDataSource } from '@angular/material/table';
import { IPagination } from 'app/shared/models/pagination.model';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-rental-item-add',
  templateUrl: './client-dialog-rental-application-rental-item-add.component.html',
  styleUrls: ['./client-dialog-rental-application-rental-item-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationRentalItemAddComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    @Inject('dialogData') private dialogData: any,
    private cdr: ChangeDetectorRef,) {
    this.rentalRequestId = dialogData.rentalRequestId;
  }

  unsubscribe$: Subject<void> = new Subject<void>();
  addNewForm: UntypedFormGroup;

  rentalRequestId: number;
  rentalItemTypes$: Observable<string[]>;
  rentalItemOptions$: Observable<RentalItem[]>;
  isRentalItemsUpdating$: Observable<boolean>;
  isRentalItemTypesLoaded$: Observable<boolean>;
  isRentalItemOptionsLoaded$: Observable<boolean>;
  rentalItemTypeSelected$: Observable<string>;
  rentalItemOptionsPagination$: Observable<IPagination>;
  rentalItemOptionsTotalRecords$: Observable<number>;

  countryCode: string;

  selectedItemId: number | undefined;

  displayedColumns: string[] = ['name', 'type', 'price'];
  dataSource: MatTableDataSource<RentalItem>;

  spinnerLoadMoreButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Load More',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };


  spinnerAddRentalItemButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Add',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  ngOnInit(): void {
    this.isRentalItemsUpdating$ = this.store.select(selectClientDialogRentalApplicationIsRentalItemUpdating);
    this.isRentalItemOptionsLoaded$ = this.store.select(selectClientDialogRentalApplicationIsRentalItemOptionsLoaded);
    this.isRentalItemTypesLoaded$ = this.store.select(selectClientDialogRentalApplicationIsRentalItemTypesLoaded);
    this.rentalItemTypes$ = this.store.select(selectClientDialogRentalApplicationRentalItemTypes);
    this.rentalItemOptions$ = this.store.select(selectClientDialogRentalApplicationRentalItemOptions);
    this.rentalItemTypeSelected$ = this.store.select(selectClientDialogRentalApplicationRentalItemTypeSelected);
    this.rentalItemOptionsPagination$ = this.store.select(selectClientDialogRentalApplicationRentalItemOptionsPagination);
    this.rentalItemOptionsTotalRecords$ = this.store.select(selectClientDialogRentalApplicationRentalItemOptionsTotalRecords);

    this.rentalItemOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(rentalItemOptions => {
      this.dataSource = new MatTableDataSource(rentalItemOptions);
      this.cdr.markForCheck();
    });

    this.isRentalItemsUpdating$.pipe(takeUntil(this.unsubscribe$)).subscribe(isSending => {
      this.spinnerAddRentalItemButtonOptions.active = isSending;
      this.cdr.markForCheck();
    });

    this.store.dispatch(actionClientDialogApplicationGetRentalItemTypesForRentalReqest({ rentalRequestId: this.rentalRequestId }));

    combineLatest([this.rentalItemTypeSelected$, this.rentalItemOptionsPagination$]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([rentalItemType, pagination]) => {
        if (rentalItemType) {
          this.store.dispatch(actionClientDialogApplicationGetRentalItemOptionsForRentalReqest(
            {
              rentalRequestId: this.rentalRequestId,
              rentalItemType: rentalItemType,
              page: pagination.page,
              pageSize: pagination.pageSize
            }
          ));
        }
      })
  }

  get formTemplateFormArray() {
    return this.addNewForm.controls.formTemplates as FormArray;
  }

  isFormSelected(id: number) {
    if (this.addNewForm.get('formTemplates').value[id] == true) {
      return 'form-item-selected';
    }
  }


  selectItem(itemId: number) {
    this.selectedItemId = itemId;
    this.cdr.markForCheck();
  }

  submitForm() {
    this.store.dispatch(actionClientDialogApplicationAddRentalItemsToRentalReqest({
      rentalRequestId: this.rentalRequestId,
      rentalItemIds: [this.selectedItemId]
    }))
  }
  cancelType() {
    this.selectedItemId = undefined;
    this.store.dispatch(actionClientDialogApplicationGetRentalItemTypeSelected({ rentalItemType: '' }));
  }

  getRentalItemTypeDisplayName(value: string) {
    return RENTAL_ITEM_TYPES.find(x => x.value == value)?.label;
  }

  loadMoreRentalItemOptions() {
    this.store.dispatch(actionClientDialogApplicationRentalItemOptionsNextPage());
  }

  rentalItemTypeSelected(rentalItemType) {
    this.store.dispatch(actionClientDialogApplicationGetRentalItemTypeSelected({ rentalItemType }));
  }

  close(): void {
    this.clientDialogMatDialogService.closeRentaItemAdd();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(actionClientDialogApplicationRentalItemStateReset());
  }
}
