import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {  MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { ILeadToMerge } from 'app/shared/models/lead-info-models';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionClientDialogLeadsToMergeRequested, actionClientDialogLeadsToMergeIdsUpdate, actionClientDialogLeadsToMergeMergeRequested, actionClientDialogLeadsMergeReset } from '../../client-dialog-general.action';
import { selectClientLeadId, selectclientDialogLeadsToMergeLoaded, selectclientDialogLeadsToMergeIsMergedSuccessfully, selectclientDialogLeadsToMergeIsSubmitting, selectclientDialogLeadsToMerge, selectclientDialogLeadsToMergeIds } from '../../client-dialog-general.selectors';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-lead-merge',
  templateUrl: './client-dialog-lead-merge.component.html',
  styleUrls: ['./client-dialog-lead-merge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogLeadMergeComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ClientDialogLeadMergeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) { }


  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Merge',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  unsubscribe: Subject<void> = new Subject();
  leadId$: Observable<number>
  leadId: number
  isLeadsToMergeLoaded$: Observable<boolean>
  isLeadsMergeSuccessfully$: Observable<boolean>
  isLeadsMergeSummittingy$: Observable<boolean>
  leadsToMergeOptions$: Observable<ILeadToMerge[]>
  leadsToMergeIds$: Observable<string[]>
  leadsToMergeIds: string[]
  canMerge: boolean = true

  displayedColumns: string[] = ['select', 'name', 'email', 'phoneNumber', 'appointmentCount', 'communicationCount'];
  dataSource = new MatTableDataSource<ILeadToMerge>();
  selection = new SelectionModel<ILeadToMerge>(true, []);

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.isLeadsToMergeLoaded$ = this.store.pipe(select(selectclientDialogLeadsToMergeLoaded));
    this.isLeadsMergeSuccessfully$ = this.store.pipe(select(selectclientDialogLeadsToMergeIsMergedSuccessfully));
    this.isLeadsMergeSummittingy$ = this.store.pipe(select(selectclientDialogLeadsToMergeIsSubmitting));
    this.leadsToMergeOptions$ = this.store.pipe(select(selectclientDialogLeadsToMerge));
    this.leadsToMergeIds$ = this.store.pipe(select(selectclientDialogLeadsToMergeIds));
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId;
      this.store.dispatch(actionClientDialogLeadsToMergeRequested({ leadId }))
    })
    this.leadsToMergeOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(leadsToMergeOptions => {
      this.dataSource = new MatTableDataSource<ILeadToMerge>(leadsToMergeOptions);
    })
    this.leadsToMergeIds$.pipe(takeUntil(this.unsubscribe)).subscribe(leadsToMergeIds => {
      this.leadsToMergeIds = leadsToMergeIds
      this.spinnerSaveButtonOptions.disabled = !(leadsToMergeIds && leadsToMergeIds.length > 0);
      this.cdr.markForCheck()
    })

    this.selection.changed.pipe(takeUntil(this.unsubscribe)).subscribe(selected => {
      let leadToMergeIds: string[] = this.selection.selected.map(x => x.id)
      this.store.dispatch(actionClientDialogLeadsToMergeIdsUpdate({ leadToMergeIds }))
    })

    this.isLeadsMergeSummittingy$.pipe(takeUntil(this.unsubscribe)).subscribe(isLeadsMergeSummittingy => {
      this.spinnerSaveButtonOptions.active = isLeadsMergeSummittingy;
      this.cdr.markForCheck()
    })

    this.isLeadsMergeSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isLeadsMergeSuccessfully => {
      if (isLeadsMergeSuccessfully) {
        this.close()
      }
    })

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  save() {
    if (!this.leadsToMergeIds || this.leadsToMergeIds.length == 0) {
      return
    }
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    this.store.dispatch(actionClientDialogLeadsToMergeMergeRequested({ leadId: this.leadId, leadToMergeIds: this.leadsToMergeIds }))
  }

  cancel() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.store.dispatch(actionClientDialogLeadsMergeReset())
  }

}
