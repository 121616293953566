import { createSelector } from "@ngrx/store";
import { TenantDialogState, TenantDialogTextMessageState, selectTenantDialog } from "../../tenant-dialog.state";
export const selectTenantDialogTextMessageState = createSelector(
  selectTenantDialog,
  (state: TenantDialogState) => state.tenantDialogTextMessage
);

export const selectTenantDialogIsTextMessageSubmitting = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTextMessageSubmitting
);

export const selectTenantDialogIsTextMessageSubmittedSuccessfully = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTextMessageSubmittedSuccessfully
);

export const selectTenantDialogSendFromPhoneNumbers = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.sendFromPhoneNumbers
);

export const selectTenantDialogTextMessagesTextEditor = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.textMessageEditor
);

export const selectTenantDialogTextMessagesTemplates = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.textMessageTemplates
);

export const selectTenantDialogTextMessagesIsTemplatesLoaded = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTemplatesLoaded
);

export const selectTenantDialogTextMessagesIsTemplateSaveOrUpdating = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTemplateSaveOrUpdateing
);

export const selectTenantDialogTextMessagesIsTemplateSaveOrUpdatedSuccessfully = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTemplateSaveOrUpdateSuccessfully
);

export const selectTenantDialogTextMessagesIsTemplateDeleting = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTemplateDeleting
);

export const selectTenantDialogTextMessagesIsTemplateDeletedSuccessfully = createSelector(
  selectTenantDialogTextMessageState,
  (state: TenantDialogTextMessageState) => state.isTemplateDeletedSuccessfully
);
