<mat-menu #childMenu="matMenu">
    <ng-container *ngFor="let child of items">
        <!-- Handle leaf node menu items -->
        <ng-container *ngIf="!child.children || child.children.length === 0">
            <button (click)="onPhoneNumberSelected(child.parentValue, child.value)" mat-menu-item>
                <mat-icon>{{child.iconName}}</mat-icon>
                <span>{{child.displayName}}</span>
            </button>
        </ng-container>
    </ng-container>
</mat-menu>