import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectTenantDialogTenantId } from '../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { actionTenantDialogCreateNoteRequest, actionTenantDialogTenantNoteStateReset } from '../tenant-dialog-tenant-note.action';
import { selectTenantDialogIsNoteSubmitting, selectTenantDialogIsNoteSubmittedSuccessfully } from '../tenant-dialog-tenant-note.selector';
import { MatProgressButtonOptions } from '../../../../../shared/components/spinner-button/spinner-button.interface';


@Component({
  selector: 'dq-tenant-dialog-tenant-note',
  templateUrl: './tenant-dialog-tenant-note.component.html',
  styleUrls: ['./tenant-dialog-tenant-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTenantNoteComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<TenantDialogTenantNoteComponent>,
    private cdr: ChangeDetectorRef) { }

  tenantId$: Observable<number>;
  tenantId: number

  isMobile$: Observable<boolean>
  isMobile: boolean

  unsubscribe: Subject<void> = new Subject();

  isSubmitting$: Observable<boolean>
  isSubmittedSuccessfully$: Observable<boolean>

  note: FormControl = new FormControl('', [Validators.required])

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Create',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: true,
    mode: 'indeterminate'
  };


  ngOnInit(): void {

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId));
    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId
    })
    this.isSubmitting$ = this.store.pipe(select(selectTenantDialogIsNoteSubmitting))
    this.isSubmittedSuccessfully$ = this.store.pipe(select(selectTenantDialogIsNoteSubmittedSuccessfully))
    this.isSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmitting => {
      this.spinnerSaveButtonOptions.active = isSubmitting
      this.cdr.markForCheck()
    })
    this.isSubmittedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isSubmittedSuccessfully => {
      if (isSubmittedSuccessfully) {
        this.note.reset()
        this.cdr.markForCheck()
      }
    })

    this.note.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.spinnerSaveButtonOptions.disabled = !this.note.valid
      this.cdr.markForCheck()
    })
  }

  submit() { }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    if (this.note) {
      this.store.dispatch(actionTenantDialogCreateNoteRequest({ id: this.tenantId, note: this.note.value }))
    }
  }

  close() {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionTenantDialogTenantNoteStateReset())
  }

}
