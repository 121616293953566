import { AuthService } from '../auth/auth.service';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LocalStorageService } from '../core.module';

export const AUTH_KEY = 'AUTH';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(private injector: Injector, private localStorageService: LocalStorageService) { }
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);

    const access_token: string = this.authService.getToken();

    if (access_token) {
      const expiresat = this.authService.getExpDate();

      if (new Date(expiresat) > new Date(new Date().getTime() - 1000 * 60)) {
        const req = this.GetContentType(request, access_token);
        return next.handle(req);
      }

      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      }));

    }
    return next.handle(request);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: true,
            token: res.token,
            refreshToken: res.refreshToken,
            expires: res.expires,
            roles: res.roles,
            profile: {
              userName: res.userName,
              userId: res.userId,
              userTimeZone: res.userTimeZone
            }
          });

          this.isRefreshing = false;
          this.refreshTokenSubject.next(res.accessToken);

          return next.handle(this.addTokenHeader(request));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return throwError(() => err);
        })
      );
    }
    else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request)))
      );
    }
  }

  private addTokenHeader(request: HttpRequest<any>) {
    const authToken = this.authService.getTestToken();
    if (authToken) {
      return this.GetContentType(request, authToken);
    } else {
      return request;
    }
  }

  private GetContentType(request: HttpRequest<any>, authToken: string) {
    if (request.body instanceof FormData)
    {
        return request.clone({
         setHeaders:{
            'ContentType' : 'multipart/form-data',
            Authorization: `Bearer ${authToken}`
          }
        });
    }
     else{
        return request.clone({
          setHeaders:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
       }
      });
    }
  }
}
