import { Action, createReducer, on } from '@ngrx/store';
import { actionTenantDialogTenantCallFromPhoneNumbersSuccessed } from '../../tenant-dialog.actions';
import { TenantDialogGeneralInfoState } from '../../tenant-dialog.state';
import { tenantDialogGeneralInfoActions } from './tenant-dialog-general.action';


export const initialState: TenantDialogGeneralInfoState = {
  tenantId: -1,
  tenantInfo: {
    id: -1,
    firstName: '',
    lastName: '',
    emails: [],
    username: '',
    phoneNumbers: [],
    phoneNumberCountryCode: '',
    lastCommunicationDate: '',
    interests: '',
    tenantTags: [],
    tenantBuilding: {
      id: -1,
      name: '',
    },
    tenantAssignedUsers: []
  },
  callFromPhoneNumbers: [],
  isTenantInfoLoaded: false,
  isBaseInfoEditMode: false,
  isBaseInfoSubmitting: false,

  tenantDialogPhoneNumberBlock: {
    isUpdating: false,
    isUpdatedSuccessfully: false
  }
};

const reducer = createReducer(
  initialState,
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogByTenantIdSuccess,
    (state, { tenantInfo }) => ({
      ...state,
      tenantId: tenantInfo.id,
      tenantInfo: tenantInfo,
      isTenantInfoLoaded: true,
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogFailed,
    (state) => ({
      ...state,
      isTenantInfoLoaded: true,
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantUserGeneralProfileUpdateSuccessfully,
    (state, { tenantInfo }) => ({
      ...state,
      tenantInfo: tenantInfo,
      isBaseInfoEditMode: false,
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogFailed,
    state => ({
      ...state,
      isTenantInfoLoaded: true
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantUserGeneralProfileChangeIsBaseInfoEditMode,
    (state, { isEditMode }) => ({
      ...state,
      isBaseInfoEditMode: isEditMode
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantUserGeneralProfileUpdateRequested,
    (state) => ({
      ...state,
      isBaseInfoSubmitting: true
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantUserGeneralProfileUpdateSuccessfully,
    tenantDialogGeneralInfoActions.actionTenantUserGeneralProfileUpdateFailed,
    (state) => ({
      ...state,
      isBaseInfoSubmitting: false
    })
  ),
  on(tenantDialogGeneralInfoActions.actionTenantDialogUpdateTenantId, (state, { tenantId: tenantId }) => ({
    ...state,
    tenantId: tenantId
  })),
  on(
    actionTenantDialogTenantCallFromPhoneNumbersSuccessed,
    (state, { phoneNumbers }) => ({
      ...state,
      callFromPhoneNumbers: phoneNumbers
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockRequested,
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockRequested,
    (state,) => ({
      ...state,
      tenantDialogPhoneNumberBlock: {
        ...state.tenantDialogPhoneNumberBlock,
        isUpdating: true,
        isUpdatedSuccessfully: false
      }
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockSuccessfully,
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockSuccessfully,
    (state, { phoneNumberBlockInfo }) => ({
      ...state,
      tenantInfo: {
        ...state.tenantInfo,
        phoneNumberBlockInfo: phoneNumberBlockInfo
      },
      tenantDialogPhoneNumberBlock: {
        ...state.tenantDialogPhoneNumberBlock,
        isUpdating: false,
        isUpdatedSuccessfully: true
      }
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockFailed,
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockFailed,
    (state,) => ({
      ...state,
      tenantDialogPhoneNumberBlock: {
        ...state.tenantDialogPhoneNumberBlock,
        isUpdating: false,
        isUpdatedSuccessfully: false
      }
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockStateReset,
    (state,) => ({
      ...state,
      tenantDialogPhoneNumberBlock: {
        ...state.tenantDialogPhoneNumberBlock,
        isUpdating: false,
        isUpdatedSuccessfully: false
      }
    })
  ),
  on(
    tenantDialogGeneralInfoActions.actionTenantDialogGeneralStateReset,
    (state) => initialState
  ),
);

export const tenantDialogGeneralInfoReducer = (
  state: TenantDialogGeneralInfoState | undefined,
  action: Action
) => {
  return reducer(state, action);
};
