import { Action, createReducer, on } from "@ngrx/store";
import { TenantDialogBuildingState } from "../../tenant-dialog.state";
import { tenantDialogBuildingActions } from "./tenant-dialog-tenant-building.action";

const initialState: TenantDialogBuildingState = {
    isBuildingLoaded: false,
    tenantBuilding: {
        id: -1,
        name: ''
    }
}

const reducer = createReducer(
    initialState,
    on(
        tenantDialogBuildingActions.actionTenantDialogTenantBuildingRequested,
        (state) => ({
            ...state,
            isBuildingLoaded: false
        })
    ),
    on(
        tenantDialogBuildingActions.actionTenantDialogTenantBuildingLoaded,
        (state, { building }) => ({
            ...state,
            isBuildingLoaded: true,
            tenantBuilding: building
        })
    ),
    on(
        tenantDialogBuildingActions.actionTenantDialogTenantBuildingFailed,
        (state) => ({
            ...state,
            isBuildingLoaded: true
        })
    ),
    on(
        tenantDialogBuildingActions.actionTenantDialogTenantBuildingFailed,
        (state) => ({
            ...state,
            isBuildingLoaded: true
        })
    ),
    on(
        tenantDialogBuildingActions.actionTenantDialogBuildingReset,
        (state,) => (initialState)
    ),
)

export const tenantDialogBuildingReducer = (
    state: TenantDialogBuildingState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
