import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { AssignedUser } from 'app/shared/models/lead-info-models';
import { LeadUpdateAction } from 'app/features/lead-manager/lead-manager.model';

import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { selectClientLeadId } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogRepsRepIsCheckedUpdate, actionClientDialogBatchRepsAddRequested, actionClientDialogBatchRepsRemoveRequested, actionClientDialogRepsUpdateRequested, actionClientDialogRepOptionsRequested } from '../../client-dialog-lead-rep.action';
import { selectclientDialogRepsStateIsRepsLoaded, selectclientDialogRepsStateRepOptions, selectclientDialogRepsIsCreateUpdateSuccessfully, selectclientDialogRepsIsRepSubmitting, selectclientDialogRepsStateIsRepOptionsLoaded } from '../../client-dialog-lead-rep.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-user-general-reps',
  templateUrl: './client-dialog-user-general-reps.component.html',
  styleUrls: ['./client-dialog-user-general-reps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogUserGeneralRepsComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ClientDialogUserGeneralRepsComponent>,
    @Inject('dialogData') private dialogData: any,
    private cdr: ChangeDetectorRef
  ) {
    if (dialogData && dialogData.action && dialogData.leadIds) {
      this.title = dialogData.action.name
      this.action = dialogData.action
      this.leadIds = dialogData.leadIds
    }
  }

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  title: string;
  leadIds: number[] = []

  action: LeadUpdateAction

  unsubscribe: Subject<void> = new Subject();
  repOptions$: Observable<AssignedUser[]>;
  repOptions: AssignedUser[];
  isRepLoaded$: Observable<boolean>
  repNameControl = new UntypedFormControl();
  repSearchOptions$: Observable<AssignedUser[]>
  type: string = '';
  panelOpenState = false;
  checkedRepList: AssignedUser[];
  isRepChecked: boolean;
  name: string;
  leadId$: Observable<number>
  leadId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  isRepOptionsLoaded$: Observable<boolean>
  isRepsSubmitting$: Observable<boolean>
  isUpdateSuccessfully$: Observable<boolean>

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.store.dispatch(actionClientDialogRepOptionsRequested())
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.isRepOptionsLoaded$ = this.store.pipe(select(selectclientDialogRepsStateIsRepOptionsLoaded));
    this.isRepsSubmitting$ = this.store.pipe(select(selectclientDialogRepsIsRepSubmitting));
    this.isUpdateSuccessfully$ = this.store.pipe(select(selectclientDialogRepsIsCreateUpdateSuccessfully));
    this.repOptions$ = this.store.pipe(select(selectclientDialogRepsStateRepOptions));
    this.isRepLoaded$ = this.store.pipe(select(selectclientDialogRepsStateIsRepsLoaded));
    this.repOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(reps => {
      this.repOptions = reps;
      this.spinnerSaveButtonOptions.disabled = reps.length == 0
      //this.leadIds.length > 0 means now this components is used for bacth leads update (leadIds will come from leads manage page when tigger this dialog)
      if (this.leadIds && this.leadIds.length > 0) {
        this.spinnerSaveButtonOptions.disabled = reps.filter(rep => rep.isChecked).length == 0;
      }
      this.repSearchOptions$ = this.repNameControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.cdr.markForCheck()
    })

    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId;
    })
    this.isRepsSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isRepsSubmitting => {
      this.spinnerSaveButtonOptions.active = isRepsSubmitting;
      this.cdr.markForCheck()
    })
    this.isUpdateSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isUpdateSuccessfully => {
      if (isUpdateSuccessfully) {
        this.dialogRef.close()
      }
    })
  }

  private _filter(value: string): AssignedUser[] {
    const filterValue = value.toLowerCase();
    return this.repOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeSelection(checked, rep) {
    this.store.dispatch(actionClientDialogRepsRepIsCheckedUpdate({ checked, rep }));
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    let reps = this.repOptions.filter(rep => rep.isChecked);
    let id = this.leadId

    if (this.leadIds && this.leadIds.length > 0) {
      if (this.action.action == 'addRepresentatives') {
        this.store.dispatch(actionClientDialogBatchRepsAddRequested({ leadIds: this.leadIds, repIds: reps.map(rep => rep.id) }))
      } else if (this.action.action == 'removeRepresentatives') {
        this.store.dispatch(actionClientDialogBatchRepsRemoveRequested({ leadIds: this.leadIds, repIds: reps.map(rep => rep.id) }))
      }
    } else {
      this.store.dispatch(actionClientDialogRepsUpdateRequested({ id, reps }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}
