import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionClientDialogBaseStateReset, actionClientDialogCommunicationSettingsRequested, actionClientDialogResetState, clientDialogActions } from '../client-dialog.actions';


import { State } from '../client-dialog.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadInfo } from '../../../shared/models/lead-info-models';
import { selectTopNavigationTab } from '../client-dialog.selector';
import { ClientDialogLeadNoteComponent } from '../components/client-dialog-lead-note/client-dialog-lead-note/client-dialog-lead-note.component';
import { actionUpdateLeadId, actionClientDialogByLeadIdRequired } from '../components/client-dialog-user-general/client-dialog-general.action';
import { selectClientLeadInfo, selectClientLeadInfoIsLoaded } from '../components/client-dialog-user-general/client-dialog-general.selectors';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { AuthService } from 'app/core/auth/auth.service';
import {  MatTabGroup } from '@angular/material/tabs';
import { LeadEmailMessage } from 'app/shared/models/lead-communication.model';
import { actionClientDialogEmailEditorIsCcAndBccActiveUpdate, actionClientDialogEmailEditorStateReset, actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate, actionClientDialogEmailTemplatesRequest, actionClientDialogSendEmailReplyEditorIsForwardingUpdate, actionClientDialogSendEmailReplyEditorReplyAllToUpdate, actionClientDialogSendEmailReplyEditorUpdate } from '../components/client-dialog-email-editor/client-dialog-email-editor.action';
import { EmailSendType } from 'app/shared/models/user-communication.model';
import { actionClientDialogApplicationRentalApplicationReset } from '../components/client-dialog-rental-application/client-dialog-rental-application.action';

@Component({
  selector: 'dq-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    public dialogRef: MatDialogRef<ClientDialogComponent>,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.leadId) {
      this.store.dispatch(actionUpdateLeadId({ leadId: data.leadId }))
    }
  }

  addNoteDialogRef: MatDialogRef<ClientDialogLeadNoteComponent>;

  @ViewChild('topTabGroup', { static: false }) topTabGroup: MatTabGroup;
  @ViewChild('target', { static: false }) target: ElementRef;

  unsubscribe: Subject<void> = new Subject();

  leadInfo$: Observable<LeadInfo>
  leadId: number
  isLeadInfoLoaded$: Observable<boolean>

  isMobile$: Observable<boolean>
  isMobile: boolean
  activeTab$: Observable<number>;
  userRoles: string[];
  selectedTabIndex: number;

  ngOnInit() {

    this.store.dispatch(clientDialogActions.actionClientDialogResetState());
    this.store.dispatch(clientDialogActions.actionClientDialogLogInUserEmailsAndPhoneNumbersRequest());

    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe)).subscribe(roles => {
      this.userRoles = roles;
    });

    if (this.data && this.data.leadId) {
      const id: number = +this.data.leadId;
      this.store.dispatch(actionClientDialogByLeadIdRequired({ leadId: id }));
    }

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    });

    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo));
    this.leadInfo$.pipe(takeUntil(this.unsubscribe)).subscribe(leadInfo => {
      if (leadInfo.id > 0) {
        this.leadId = leadInfo.id;
        this.store.dispatch(actionClientDialogCommunicationSettingsRequested({ leadId: this.leadId }));
        this.store.dispatch(actionClientDialogEmailTemplatesRequest());
      }
    });

    this.isLeadInfoLoaded$ = this.store.pipe(select(selectClientLeadInfoIsLoaded));
    this.activeTab$ = this.store.pipe(select(selectTopNavigationTab));

  }

  handleSelectedTabChange(index: number): void {
    this.selectedTabIndex = index;
  }

  openAddNoteDialog(): void {
    this.addNoteDialogRef = this.dialog.open(ClientDialogLeadNoteComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
    });

    this.addNoteDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
    });
  }

  hasAccessToEditAssignedUser(): Observable<boolean> {
    return this.authService.isUserInRole([SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager])
  }

  refreshDialog() {
    this.store.dispatch(clientDialogActions.actionClientDialogResetState());
    this.store.dispatch(clientDialogActions.actionClientDialogLogInUserEmailsAndPhoneNumbersRequest());
    if (this.data && this.data.leadId) {
      const id: number = +this.data.leadId;
      this.store.dispatch(actionClientDialogByLeadIdRequired({ leadId: id }));
    }
  }

  close() {
    this.dialogRef.close();
  }

  onTopNavigate(tab: number) {
    if (tab <= 4) {
      this.store.dispatch(clientDialogActions.actionClientDialogTopNavigationActiveTabChange({ tab }));
    }
  }

  replyEmail(emailMessage: LeadEmailMessage) {
    this.topTabGroup.selectedIndex = 1;
    this.target.nativeElement.scrollTop = 0;
    const subject = emailMessage.subject.toLowerCase().startsWith('re') ? emailMessage.subject : 'RE: ' + emailMessage.subject;
    this.store.dispatch(actionClientDialogSendEmailReplyEditorUpdate({
      email: emailMessage.content,
      sendToCcs: [],
      sendToBccs: [],
      subject,
    }));
    this.store.dispatch(actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate({ sendType: EmailSendType.REPLY, externalIdentifier: emailMessage.externalIdentifier }));
    this.store.dispatch(actionClientDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false }));
  }

  replyEmailAll(emailMessage: LeadEmailMessage) {
    this.topTabGroup.selectedIndex = 1;
    this.target.nativeElement.scrollTop = 0;
    const subject = emailMessage.subject.toLowerCase().startsWith('re') ? emailMessage.subject : 'RE: ' + emailMessage.subject;
    this.store.dispatch(actionClientDialogSendEmailReplyEditorUpdate({
      email: emailMessage.content,
      sendToCcs: emailMessage.ccs?.length > 0 ? emailMessage.ccs?.split(';') : [],
      sendToBccs: emailMessage.bccs?.length > 0 ? emailMessage.bccs?.split(';') : [],
      subject,
    }));
    this.store.dispatch(actionClientDialogEmailEditorIsCcAndBccActiveUpdate({ isActive: true }));
    this.store.dispatch(actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate({ sendType: EmailSendType.REPLYALL, externalIdentifier: emailMessage.externalIdentifier }));
    const replyAllToEmails = emailMessage.to?.length > 0 ? emailMessage.to?.split(';') : [];
    replyAllToEmails.push(emailMessage.from);
    this.store.dispatch(actionClientDialogSendEmailReplyEditorReplyAllToUpdate({ replyAllTo: replyAllToEmails }));
    this.store.dispatch(actionClientDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: false }));
  }

  forwardEmail(emailMessage: LeadEmailMessage) {
    this.topTabGroup.selectedIndex = 1;
    this.target.nativeElement.scrollTop = 0;
    const subject = emailMessage.subject.toLowerCase().startsWith('fwd') ? emailMessage.subject : 'Fwd: ' + emailMessage.subject;
    this.store.dispatch(actionClientDialogSendEmailReplyEditorUpdate({
      email: '<p>---------- Forwarded message ---------</p>' + emailMessage.content,
      sendToCcs: [],
      sendToBccs: [],
      subject,
    }));

    this.store.dispatch(actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate({ sendType: EmailSendType.FORWARD, externalIdentifier: emailMessage.externalIdentifier }));
    this.store.dispatch(actionClientDialogSendEmailReplyEditorIsForwardingUpdate({ isForwarding: true }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(actionClientDialogResetState());
    this.store.dispatch(actionClientDialogBaseStateReset());
    this.store.dispatch(actionClientDialogEmailEditorStateReset());
    this.store.dispatch(actionClientDialogApplicationRentalApplicationReset());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}

