import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogActivitiesState } from "../../client-dialog.state";
import { clientDialogActivityActions } from "./client-dialog-activities.action";

export const initialState: ClientDialogActivitiesState = {
  activities: [],
  isActivitiesLoaded: false,
  communicationTotalecords: 0,
  communicationPageNo: 1,
  communicationPageSize: 30,
  isLoadingMoreCommunications: false,
  lastCommunicatePhoneNumber: ''
}

export const reducer = createReducer(
  initialState,
  on(
    clientDialogActivityActions.actionClientDialogAllActivitiesRequest,
    (state, { leadId, pageNo, pageSize, isLoadMore }) => ({
      ...state,
      isActivitiesLoaded: isLoadMore ? true : false,
      activities: isLoadMore ? state.activities : initialState.activities,
      isLoadingMoreCommunications: true
    })
  ),
  on(
    clientDialogActivityActions.actionClientDialogAllActivitiesSuccessed,
    (state, { activities, pageNo, pageSize, totalRecords }) => ({
      ...state,
      isActivitiesLoaded: true,
      activities: state.activities.concat(activities).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
      lastCommunicatePhoneNumber: state.activities.concat(activities).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).find(x => x.type == 'Text' || x.type == 'Voice')?.phoneNumber,
      isLoadingMoreCommunications: false,
      communicationPageNo: pageNo,
      communicationPageSize: pageSize,
      communicationTotalecords: totalRecords
    })
  ),
  on(
    clientDialogActivityActions.actionClientDialogAllActivitiesFailed,
    (state) => ({
      ...state,
      isActivitiesLoaded: true,
      isLoadingMoreCommunications: false
    })
  ),
  on(
    clientDialogActivityActions.actionClientDialogActivityStateReset,
    (state) => initialState
  )
)

export const clientDialogActivityReducer = (
  state: ClientDialogActivitiesState | undefined,
  action: Action
) => {
  return reducer(state, action);
}

