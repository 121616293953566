import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ClientDialogInventoryService } from "./client-dialog-inventory.service";
import { BuildingProperty, IBuilding, IProperty, IUnit } from "./client-dialog-inventory.model";
import { actionClientDialogInventoryFilterProptertyIdsToRemoveFailed, actionClientDialogInventoryFilterProptertyIdsToRemoveLoaded, actionClientDialogInventoryFilterProptertyIdsToRemoveRequest, clientDialogInventoryActions, clientDialogInventoryActionsFailed } from "./client-dialog-inventory.action";
import { NotificationService } from "app/core/core.module";

@Injectable()
export class ClientDialogInventoryEffects {
    constructor(
        private actions$: Actions<Action>,
        private notificationService: NotificationService,
        private clientDialogInventoryService: ClientDialogInventoryService,
    ) { }

    requestInventoryBuildings = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingsRequested),
            switchMap(action =>
                this.clientDialogInventoryService.getInventoryBuildings(action.buildingId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildings = response.data as IProperty[]
                            return clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingsLoaded({ buildings })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingsFailed()))
                )
            )
        )
    );



    //Units tab
    requestPropertyiesByBuildingId = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogInventoryActions.actionClientDialogInventoryUnitsBuildingPropertyRequest),
            switchMap(action =>
                this.clientDialogInventoryService.postBuildingPropertiesByBuildingId(action.buildingIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildingPropertyOptions = response.data as BuildingProperty[]
                            return clientDialogInventoryActions.actionClientDialogInventoryUnitsBuildingPropertyLoaded({ buildingPropertyOptions })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogInventoryActionsFailed.actionClientDialogInventoryUnitsBuildingPropertyRequestFailed()
                        }
                    }),
                    catchError(error => of(clientDialogInventoryActionsFailed.actionClientDialogInventoryUnitsBuildingPropertyRequestFailed()))
                )
            )
        )
    );


    //Buildings tab
    requestBuildingPropertyiesByBuildingId = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogInventoryActions.actionClientDialogInventoryBuildingsBuildingPropertyRequest),
            switchMap(action =>
                this.clientDialogInventoryService.postBuildingPropertiesByBuildingId(action.buildingIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildingProperties = response.data as BuildingProperty[]
                            return clientDialogInventoryActions.actionClientDialogInventoryBuildingsBuildingPropertyLoaded({ buildingProperties })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogInventoryActionsFailed.actionClientDialogInventoryBuildingsBuildingPropertyRequestFailed()
                        }
                    }),
                    catchError(error => of(clientDialogInventoryActionsFailed.actionClientDialogInventoryBuildingsBuildingPropertyRequestFailed()))
                )
            )
        )
    );

    requestInventoryBuildingOptions = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingOptionsRequested),
            switchMap(action =>
                this.clientDialogInventoryService.getInventoryBuildingOptions().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildings = response.data as IBuilding[]
                            return clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingOptionsLoaded({ buildings })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingOptionsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingOptionsFailed()))
                )
            )
        )
    );

    requestInventoryUnits = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogInventoryActions.actionClientDialogInventoryAreaUnitsRequested),
            switchMap(action =>
                this.clientDialogInventoryService.getYardiInventory(action.queryFilter, action.paginator).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let units = response.data.objectsList as IUnit[]
                            let totalRecords: number = response.data.totalRecords
                            let page: number = response.data.page
                            let pageSize: number = response.data.pageSize
                            return clientDialogInventoryActions.actionClientDialogInventoryAreaUnitsLoaded({ units, totalRecords, page, pageSize })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaUnitsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaUnitsFailed()))
                )
            )
        )
    );

    requestInventoryBuildingUnits = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingUnitsRequested),
            switchMap(action =>
                this.clientDialogInventoryService.getInventoryUnitsByBuildingId(action.propertyId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let units = response.data.objectsList as IUnit[]
                            return clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingUnitsLoaded({ units })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingUnitsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingUnitsFailed()))
                )
            )
        )
    );

    requestPropertyIdsToRemoveForFilter = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogInventoryFilterProptertyIdsToRemoveRequest),
            switchMap(action =>
                this.clientDialogInventoryService.postBuildingPropertiesByBuildingId([action.buildingId]).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildingPropertyOptions = response.data as BuildingProperty[];
                            let propertyIds = buildingPropertyOptions.map(x => x.buildingPropertyId);
                            return actionClientDialogInventoryFilterProptertyIdsToRemoveLoaded({ propertyIds })
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogInventoryFilterProptertyIdsToRemoveFailed()
                        }
                    }),
                    catchError(error => of(actionClientDialogInventoryFilterProptertyIdsToRemoveFailed()))
                )
            )
        )
    );
}