import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TitleService } from 'app/core/core.module';

@Component({
  selector: 'cdk-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
              private titleService: TitleService,
              private router: Router) { }


  unsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    this.titleService.setTitleByText('Privacy Policy | DashQ')
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
