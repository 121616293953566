import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';

@Component({
  selector: 'dq-manual-slider',
  templateUrl: './manual-slider.component.html',
  styleUrls: ['./manual-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ManualSliderComponent {
  constructor() {}

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  @Input() minValue: number = 0;

  @Input() maxValue: number = 100;

  @Input() options: Options;

  @Output() onUserSliderChangeEnd = new EventEmitter<ChangeContext>();
  onUserChangeEnd(changeContext: ChangeContext): void {
    this.onUserSliderChangeEnd.emit(changeContext);
  }

  @Output() onUserSliderChange = new EventEmitter<ChangeContext>();
  onUserChange(changeContext: ChangeContext): void {
    this.onUserSliderChange.emit(changeContext);
  }
}
