import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs';
import { IWebApiResponse } from '../response/response.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IndexSearchService {

  constructor(
    private http: HttpClient,
  ) { }


  getLeadsBySearchKeyWord(search: string, page: number, pageSize: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-queue/GetLeadsByText?search=${search}&page=${page}&pageSize=${pageSize}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }
}
