import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digiDecimalCommaPipe'
})
export class DigiDecimalCommaPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {
  }
  transform(value: string | number, args?: any): any {
      if(typeof value === 'string'){
          return  value;
      }
      if (value < 10) {
          return value;
      }
      return this.decimalPipe.transform(value, '2.');
  }

}
