<div class="dialog-pad-component">
  <form class="dialer">
    <mat-form-field id="output" appearance="outline">
      <input [formControl]="numberFormControl" matInput (keypress)="keypress($event)">
      <mat-icon *ngIf="numberFormControl.invalid && (numberFormControl.dirty || numberFormControl.touched)" matSuffix
        [matTooltip]="'This phone number cannot be validated.'" [matRippleCentered]="true"
  [matRippleRadius]="30" >error</mat-icon>
    </mat-form-field>
    <div id="mouse-input">
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(1)" class="digit">
        <span>1</span>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(2)" class="digit">
        <span>2</span>
        <em class="sub">ABC</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(3)" class="digit">
        <span>3</span>
        <em class="sub">DEF</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(4)" class="digit">
        <span>4</span>
        <em class="sub">GHI</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(5)" class="digit">
        <span>5</span>
        <em class="sub">JKL</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(6)" class="digit">
        <span>6</span>
        <em class="sub">MNO</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(7)" class="digit">
        <span>7</span>
        <em class="sub">PQRS</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(8)" class="digit">
        <span>8</span>
        <em class="sub">TUV</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(9)" class="digit">
        <span>9</span>
        <em class="sub">WXYZ</em>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue('*')" class="digit">
        <span>*</span>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue(0)" class="digit">
        <span>0</span>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue('#')" class="digit">
        <span>#</span>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="addNumberValue('+')" class="digit align-items-center">
        <mat-icon class="mr-0">add</mat-icon>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="call()" [disabled]="!numberFormControl.valid" [ngClass]="{'disabled': !numberFormControl.valid}" class="digit digit-call-btn align-items-center">
        <mat-icon class="mr-0">call</mat-icon>
      </button>
      <button matRipple  [matRippleColor]="'rgba(0, 0, 0, 0.2)'" [matRippleCentered]="true"
  [matRippleRadius]="30" (click)="backNumberValue()" class="digit align-items-center">
        <mat-icon class="mr-0">keyboard_backspace</mat-icon>
      </button>
    </div>
  </form>
</div>
