<div class="timeline-webform-component">
    <div class="desktop-container">
        <section>
            <article class="timeline-content">
                <header class="header">
                    <mat-icon class="timeline-icon dvr">dvr</mat-icon>
                    <div class="header-meta">
                        <strong>
                            Inquiry from {{sourceName}}
                        </strong>
                        <em>
                            {{event.date|date: 'medium'}}
                        </em>
                    </div>
                </header>
                <div class="wf-content">{{message}}</div>
                <!-- <div class="wf-content" [innerHtml]="event.content | safeHtml"></div>
                    <div *ngIf="event.metaData!=undefined" class="metadata">
                        <div *ngIf="buildingName!=undefined">Building: <span
                                class="wf-detail-value">{{buildingName}}</span></div>
                        <div *ngIf="unitDescription!=undefined">Description: <span
                                class="wf-detail-value">{{unitDescription}}</span></div>
                        <div *ngIf="bedrooms!=undefined">Bedrooms: <span class="wf-detail-value">{{bedrooms}}</span>
                        </div>
                        <div *ngIf="bathrooms!=undefined">Bathrooms: <span class="wf-detail-value">{{bathrooms}}</span>
                        </div>
                        <div *ngIf="moveInDate!=undefined">Move in date: <span
                                class="wf-detail-value">{{moveInDate}}</span></div>
                        <div *ngIf="url!=undefined">Link: <a href="{{url}}" target="_blank"><span
                                    class="wf-detail-value">{{url}}</span></a></div>
                    </div> -->
            </article>
        </section>
    </div>
</div>