import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { ClientDialogService } from "../../client-dialog.service";
import { State } from "../../client-dialog.state";
import { clientDialogActivityActions } from "../client-dialog-activities/client-dialog-activities.action";
import { selectClientLeadId } from "../client-dialog-user-general/client-dialog-general.selectors";
import { actionClientDialogCreateNoteRequest, actionClientDialogCreateNoteSuccessed, actionClientDialogCreateNoteFailed } from "./client-dialog-lead-note.action";


@Injectable()
export class ClientDialogLeadNoteEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    createLeadNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogCreateNoteRequest),
            switchMap(action =>
                this.clientDialogService.createLeadNote(action.id, action.note).pipe(
                    map((response => {
                        if (response.status == 1) {
                            return actionClientDialogCreateNoteSuccessed()
                        } else {
                            this.notificationService.error(response.message);
                            return actionClientDialogCreateNoteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(actionClientDialogCreateNoteFailed())
                    })
                    )
                )
            )
        )
    );

    createLeadNoteSuccessfully$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionClientDialogCreateNoteSuccessed),
            withLatestFrom(this.store.pipe(select(selectClientLeadId))),
            switchMap(([action, leadId]) =>
                [clientDialogActivityActions.actionClientDialogAllActivitiesRequest({ leadId: leadId, pageNo: 0, pageSize: 20, itemCountPage: 0 })]
            )
        )
    );

}
