import { createAction, props } from "@ngrx/store"
import { CommunicationTemplate, ITextMessageQueryResult, TextChatMessage } from "app/features/tenant-unified-inbox/tenant-unified-inbox.model"

export const actionTenantUnifiedInboxTextMessagesRequested = createAction(
    '[Tenant Unified Inbox Text Message] Text Messages Requested',
    props<{ tenantId: number, page: number, pageSize: number, itemCountPage: number }>()
)

export const actionTenantUnifiedInboxTextMessagesLoaded = createAction(
    '[Tenant Unified Inbox Text Message] Text Messages Loaded',
    props<{ textMessageQueryResult: ITextMessageQueryResult }>()
)

export const actionTenantUnifiedInboxTextMessagesFailed = createAction(
    '[Tenant Unified Inbox Text Message] Text Messages loading Failed',
)

export const actionTenantUnifiedInboxCreateTextMessageRequest = createAction(
    '[Tenant Unified Inbox Text Message] Create Text Message Request',
    props<{ id: number, text: string, sendFromId: string, sendTo: string }>()
)

export const actionTenantUnifiedInboxCreateTextMessageSuccessed = createAction(
    '[Tenant Unified Inbox Text Message] Create Text Message Successed',
)

export const actionTenantUnifiedInboxCreateTextMessageFailed = createAction(
    '[Tenant Unified Inbox Text Message] Create Text Message Failed',
)

export const actionTenantUnifiedInboxAddTextMessageReceived = createAction(
    '[Tenant Unified Inbox Text Message] Add Text Message Received',
    props<{ textMessage: TextChatMessage }>()
)

export const actionTenantUnifiedInboxAddTextMessageNotAdded = createAction(
    '[Tenant Unified Inbox Text Message] Add Text Message Not Added'
)

export const actionTenantUnifiedInboxAddTextMessageLoaded = createAction(
    '[Tenant Unified Inbox Text Message] Add Text Message Loaded',
    props<{ textMessage: TextChatMessage }>()
)

export const actionTenantUnifiedInboxAddTextMessageFailed = createAction(
    '[Tenant Unified Inbox Text Message] Add Text Message Failed',
)

export const actionTenantUnifiedInboxSendTextRequested = createAction(
    '[Tenant Unified Inbox Text Message] Send Text Message Requested',
    props<{ tenantId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionTenantUnifiedInboxSendTextSuccess = createAction(
    '[Tenant Unified Inbox Text Message] Send Text Message Success',
)

export const actionTenantUnifiedInboxSendTextFailed = createAction(
    '[Tenant Unified Inbox Text Message] Send Text Message Failed',
)

export const actionTenantUnifiedInboxSendTextAndCollaborateRequested = createAction(
    '[Tenant Unified Inbox Text Message] Send Text Message And Collaborate Requested',
    props<{ tenantId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionTenantUnifiedInboxSendTextAndCollaborateSuccess = createAction(
    '[Tenant Unified Inbox Text Message] Send Text Message And Collaborate Success',
)

export const actionTenantUnifiedInboxSendTextAndCollaborateFailed = createAction(
    '[Tenant Unified Inbox Text Message] Send Text Message And Collaborate Failed',
)

export const actionTenantUnifiedInboxTextMessageTextContentUpdate = createAction(
    '[Tenant Unified Inbox Text Message] Text Content Update',
    props<{ message: string, sendFrom: string, sendTo: string }>()
)

export const actionTenantUnifiedInboxTextMessageTemplatesRequest = createAction(
    '[Tenant Unified Inbox Text Message] Templates Request',
)

export const actionTenantUnifiedInboxTextMessageTemplatesLoaded = createAction(
    '[Tenant Unified Inbox Text Message] Templates Loaded',
    props<{ templates: CommunicationTemplate[] }>()
)

export const actionTenantUnifiedInboxTextMessageTemplatesFalied = createAction(
    '[Tenant Unified Inbox Text Message] Templates Failed',
)

export const actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdate = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Save And Update',
    props<{ friendlyName: string, displayName: string, content: string, id?: number, isAccountShared: boolean }>()
);

export const actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdateSucceeded = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Save And Update Succeeded'
);

export const actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdateFailed = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Save And Update Failed'
);

export const actionTenantUnifiedInboxTextMessageTemplateDeleteRequest = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Delete Request',
    props<{ id: number }>()
);

export const actionTenantUnifiedInboxTextMessageTemplateDeleteSucceeded = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Delete Succeeded'
);

export const actionTenantUnifiedInboxTextMessageTemplateDeleteFailed = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Delete Failed'
);

export const actionTenantUnifiedInboxTextMessageTemplateDeleteStateReset = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Delete State Reset'
);
export const actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdateStateReset = createAction(
    '[Tenant Unified Inbox TextMessage] Eamil Template Save And Failed State Reset'
);

export const actionTenantUnifiedInboxTextMessageStateReset = createAction(
    '[Tenant Unified Inbox Text Message] Text Message State Reset',
)

export const tenantUnifiedInboxTextMessageActions = {
    actionTenantUnifiedInboxTextMessagesRequested,
    actionTenantUnifiedInboxTextMessagesLoaded,
    actionTenantUnifiedInboxTextMessagesFailed,
    actionTenantUnifiedInboxCreateTextMessageRequest,
    actionTenantUnifiedInboxCreateTextMessageSuccessed,
    actionTenantUnifiedInboxCreateTextMessageFailed,
    actionTenantUnifiedInboxTextMessageStateReset,
    actionTenantUnifiedInboxAddTextMessageReceived,
    actionTenantUnifiedInboxAddTextMessageNotAdded,
    actionTenantUnifiedInboxAddTextMessageLoaded,
    actionTenantUnifiedInboxAddTextMessageFailed,
    actionTenantUnifiedInboxSendTextRequested,
    actionTenantUnifiedInboxSendTextSuccess,
    actionTenantUnifiedInboxSendTextFailed,
    actionTenantUnifiedInboxTextMessageTextContentUpdate,
    actionTenantUnifiedInboxSendTextAndCollaborateRequested,
    actionTenantUnifiedInboxSendTextAndCollaborateSuccess,
    actionTenantUnifiedInboxTextMessageTemplatesRequest,
    actionTenantUnifiedInboxTextMessageTemplatesLoaded,
    actionTenantUnifiedInboxTextMessageTemplatesFalied,
    actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdate,
    actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdateSucceeded,
    actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdateFailed,
    actionTenantUnifiedInboxTextMessageTemplateDeleteRequest,
    actionTenantUnifiedInboxTextMessageTemplateDeleteSucceeded,
    actionTenantUnifiedInboxTextMessageTemplateDeleteFailed,
    actionTenantUnifiedInboxTextMessageTemplateDeleteStateReset,
    actionTenantUnifiedInboxTextMessageTemplateSaveAndUpdateStateReset,
    actionTenantUnifiedInboxSendTextAndCollaborateFailed,
}
