import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';

@Component({
  selector: 'dq-timeline-voice',
  templateUrl: './timeline-voice.component.html',
  styleUrls: ['./timeline-voice.component.scss', './timeline-voice.component.mobile.scss',],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimelineVoiceComponent implements OnInit {

  @Input() event: ITimelineElement

  isMobile$: Observable<boolean>;

  voiceRecord: string;
  voiceMail: string;
  voiceBaseUrl: string = environment.baseUrl.voiceBaseUrl;
  ifVoiceAvailable: boolean;

  msgFrom: string;
  msgTo: string;
  msg: string;
  duration: string;

  constructor(public store: Store<State>) { }

  ngOnInit(): void {

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));

    this.voiceRecord = `${this.voiceBaseUrl}${this.event.voiceMessage?.recordUrl}`;
    this.voiceMail = `${this.voiceBaseUrl}${this.event.voiceMessage?.voiceMailUrl}`;

    this.ifVoiceAvailable = this.event.voiceMessage?.recordUrl || this.event.voiceMessage?.voiceMailUrl ? true : false;
    this.msgFrom = this.event.voiceMessage?.fromLabel != null ? this.event.voiceMessage?.fromLabel : this.event.voiceMessage?.from;
    this.msgTo = this.event.voiceMessage?.toLabel != null ? this.event.voiceMessage?.toLabel : this.event.voiceMessage?.to;
    let duration = +this.event.voiceMessage?.durationInSecond;

    if (!isNaN(duration)) {
      this.duration = this.secondsToMinsAndSecs(+this.event.voiceMessage?.durationInSecond)
    }

  }

  secondsToMinsAndSecs(totalSeconds: number) {

    // 👇️ get number of full minutes
    const minutes = Math.floor(totalSeconds / 60);

    // 👇️ get remainder of seconds
    const seconds = totalSeconds % 60;

    const result = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return result

  }
}
