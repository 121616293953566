import { createAction, props } from "@ngrx/store"
import { ITimelineElement } from "app/shared/components/timeline/timeline.model"

export const actionTenantUnifiedInboxAllActivitiesRequest = createAction(
    '[Tenant Unified Inbox Activity] All Activities Request',
    props<{ tenantId: number, pageNo: number, pageSize: number, itemCountPage: number, isLoadingMore?: boolean }>()
)

export const actionTenantUnifiedInboxAllActivitiesSuccessed = createAction(
    '[Tenant Unified Inbox Activity] All Activities Successed',
    props<{ activities: ITimelineElement[], pageNo: number, pageSize: number, totalRecords: number }>()
)

export const actionTenantUnifiedInboxAllActivitiesFailed = createAction(
    '[Tenant Unified Inbox Activity] All Activities Failed',
)

export const actionTenantUnifiedInboxAddActivityReceived = createAction(
    '[Tenant Unified Inbox Activity] Add Activity Received',
    props<{ activity: ITimelineElement }>()
)

export const actionTenantUnifiedInboxAddActivityNotAdded = createAction(
    '[Tenant Unified Inbox Activity] Add Activity Not Added'
)

export const actionTenantUnifiedInboxAddActivityLoaded = createAction(
    '[Tenant Unified Inbox Activity] Add Activity Loaded',
    props<{ activity: ITimelineElement }>()
)

export const actionTenantUnifiedInboxAddActivityFailed = createAction(
    '[Tenant Unified Inbox Activity] Add Activity Failed',
)

export const actionTenantUnifiedInboxAddNoteReceived = createAction(
    '[Tenant Unified Inbox Text Message] Add Note Received',
    props<{ activity: ITimelineElement }>()
)

export const actionTenantUnifiedInboxActivityStateReset = createAction(
    '[Tenant Unified Inbox Activity] State Reset'
)

export const tenantUnifiedInboxActivityActions = {
    actionTenantUnifiedInboxAllActivitiesRequest,
    actionTenantUnifiedInboxAllActivitiesSuccessed,
    actionTenantUnifiedInboxAllActivitiesFailed,
    actionTenantUnifiedInboxActivityStateReset,
    actionTenantUnifiedInboxAddActivityReceived,
    actionTenantUnifiedInboxAddActivityLoaded,
    actionTenantUnifiedInboxAddActivityFailed
}