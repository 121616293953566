import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ClientAppointment, LeadInfo } from 'app/shared/models/lead-info-models';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Observable, Subject } from 'rxjs';
import { actionClientDialogAppointmentAppointmentInEdittingRequested, actionClientDialogAppointmentAppointmentInEdittingReset, actionClientDialogAppointmentAppointmentsRequested, actionClientDialogAppointmentStateReset } from '../client-dialog-appointment.action';
import { selectClientDialogIsClientAppointmentsLoaded, selectClientDialogClientAppointments } from '../client-dialog-appointment.selector';
import { selectClientLeadId, selectClientLeadInfo } from 'app/features/client-dialog/components/client-dialog-user-general/client-dialog-general.selectors'
import { DeleteOrRemoveConfirmService } from 'app/shared/components/delete-remove-confirm/delete-or-remove-confirm.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientDialogInventoryBookingComponent } from '../../client-dialog-inventory/components/client-dialog-inventory-booking/client-dialog-inventory-booking/client-dialog-inventory-booking.component';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { DatePipe } from '@angular/common';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { AuthService } from 'app/core/auth/auth.service';
import { LeadPreference } from 'app/shared/models/lead-preference.model';
import { LEAD_PREFERENCE_BATH_OPTIONS, LEAD_PREFERENCE_BED_OPTIONS, LEAD_PREFERENCE_PARKING_OPTIONS, LEAD_PREFERENCE_PET_OPTIONS } from 'app/shared/models/lead-preference.model';
import { ClientDialogAppointmentCancelDialopgComponent } from '../components/client-appointment-cancel-dialog/client-appointment-cancel-dialog.component';

@Component({
  selector: 'dq-client-dialog-appointment',
  templateUrl: './client-dialog-appointment.component.html',
  styleUrls: ['./client-dialog-appointment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogAppointmentComponent implements OnInit, OnDestroy {

  private deleteOrRemoveConfirmService: DeleteOrRemoveConfirmService;

  private datePipe: DatePipe;

  constructor(
    public store: Store<State>,
    deleteOrRemoveConfirmService: DeleteOrRemoveConfirmService,
    public dialog: MatDialog,
    datePipe: DatePipe,
    private authService: AuthService
  ) {
    this.datePipe = datePipe;
    this.deleteOrRemoveConfirmService = deleteOrRemoveConfirmService;
  }


  unsubscribe$: Subject<void> = new Subject();
  isAppointmentsLoaded$: Observable<boolean>
  appointments$: Observable<ClientAppointment[]>

  leadInfo$: Observable<LeadInfo>
  leadId$: Observable<number>
  leadId: number
  userRoles: string[]

  bedOptions = LEAD_PREFERENCE_BED_OPTIONS;
  bathOptions = LEAD_PREFERENCE_BATH_OPTIONS;
  parkingOptions = LEAD_PREFERENCE_PARKING_OPTIONS;
  petOptions = LEAD_PREFERENCE_PET_OPTIONS;

  bookAppointmentDialogRef: MatDialogRef<ClientDialogInventoryBookingComponent>;
  confirmDialogGeneric: MatDialogRef<ClientDialogAppointmentCancelDialopgComponent>;

  isMobile$: Observable<boolean>
  isMobile: boolean

  ngOnInit(): void {
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo));
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.isAppointmentsLoaded$ = this.store.pipe(select(selectClientDialogIsClientAppointmentsLoaded));
    this.appointments$ = this.store.pipe(select(selectClientDialogClientAppointments));
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));

    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe$)).subscribe(roles => {
      this.userRoles = roles;
    });

    this.leadId$.pipe(takeUntil(this.unsubscribe$)).subscribe(leadId => {
      this.leadId = leadId
      if (this.leadId > 0) {
        this.store.dispatch(actionClientDialogAppointmentAppointmentsRequested({ leadId: leadId }));
      }
    });

    this.isMobile$.pipe(takeUntil(this.unsubscribe$)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  cancelAppointment(appointment: ClientAppointment) {
    this.confirmDialogGeneric = this.dialog.open(
      ClientDialogAppointmentCancelDialopgComponent,
      {
        width: '525px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: false,
        data: {
          id: appointment.id,
        }
      }
    );
  }

  hasAccessToBooking(): Observable<boolean> {
    return this.authService.isUserInRole([SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesBookingManager])
  }

  openBookAppointmentDialog(appointment: ClientAppointment) {
    this.store.dispatch(actionClientDialogAppointmentAppointmentInEdittingRequested({ id: appointment.id }))
    this.bookAppointmentDialogRef = this.dialog.open(
      ClientDialogInventoryBookingComponent,
      {
        width: this.isMobile ? '100vw' : '75vw',
        height: '100dvh',
        maxWidth: '100vw',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: false,
        data: {
          buildingId: appointment.buildingId
        }
      }
    );

    this.bookAppointmentDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.store.dispatch(actionClientDialogAppointmentAppointmentInEdittingReset())
      if (data) {
        this.store.dispatch(actionClientDialogAppointmentAppointmentsRequested({ leadId: this.leadId }));
      }
    });
  }

  formatOptionsPreference(leadPreferenceOptions: LeadPreference[], preferences: string[]) {
    let preference: string = ''
    preferences?.forEach((p, i) => {
      preference += leadPreferenceOptions.find(x => x.value == p)?.label
      if (i < preferences.length - 1) {
        preference += ', '
      }
    })
    return preference
  }

  formatParkingOptionsPreference(leadPreferenceOptions: LeadPreference[], preference: string) {
    return leadPreferenceOptions.find(x => x.value == preference)?.label
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.store.dispatch(actionClientDialogAppointmentStateReset())
  }

}
