import { Action, createReducer, on } from '@ngrx/store';
import {
  actionScreenManagerUpdateMobileScreen,
  actionScreenManagerUpdateWindowSize
} from './screen-manager.actions';
import { ScreenManagerState } from './screen-manager.state';

export const initialState: ScreenManagerState = {
  isMobile: /Mobi|Android/i.test(navigator.userAgent),
  isMobileSize: false,
  windowScreenInfo: {
    windowHeightSize: 0,
    windowWidthSize: 0
  }
};

const reducer = createReducer(
  initialState,
  on(actionScreenManagerUpdateWindowSize, (state, { windowScreenInfo }) => ({
    ...state,
    windowScreenInfo: windowScreenInfo
  })),
  on(actionScreenManagerUpdateMobileScreen, (state, { isMobile }) => ({
    ...state,
    // isMobileSize: isMobile,
  }))
);

export function screenManagerReducer(
  state: ScreenManagerState | undefined,
  action: Action
) {
  return reducer(state, action);
}
