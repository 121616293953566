import { createAction, props } from "@ngrx/store";
import { BookingCalendar, BuildingWeeklyAvailability, IBuilding } from "../../booking.state";

export const actionBookingBuildingAvailabilitySelectedBuildingChange = createAction(
    '[Booking] Booking Building Availability Selected Building Change',
    props<{ building: IBuilding }>()
);
export const actionBookingBuildingAvailabilitySelectedBuildingIdChange = createAction(
    '[Booking] Booking Building Availability Selected Building Id Change',
    props<{ builidngId: number }>()
);

export const actionBookingBuildingAvailabilityDateFilterChange = createAction(
    '[Booking] Booking Building Availability Date Filter Change',
    props<{ date: string }>()
);

export const actionBookingBuildingAvailabilityTimeSlotToConfirmChange = createAction(
    '[Booking] Booking Building Availability Time Slot Confirm Change',
    props<{ timeSlot: string }>()
);

export const actionBookingBuildingAvailabilityRequested = createAction(
    '[Booking] Booking Building Availability Requested',
    props<{ date: string, buildingId: number }>()
);

export const actionBookingBuildingAvailabilityLoaded = createAction(
    '[Booking] Booking Building Availability Loaded',
    props<{ buildingAvailability: BuildingWeeklyAvailability }>()
);

export const actionBookingBuildingAvailabilityFailed = createAction(
    '[Booking] Booking Building Availability Failed',
);

export const actionBookingBuildingCalendarToggleScheduleOverwrite = createAction(
    '[Booking] Booking Building Calendar Toggle Schedule Overwrite',
    props<{ isScheduleOverwrite: boolean }>()
)

export const actionBookingBuildingAvailabilityScheduleOverwriteRequested = createAction(
    '[Booking] Booking Building Availability Schedule Overwrite Requested',
    props<{ date: string, buildingId: number }>()
);

export const actionBookingBuildingAvailabilityScheduleOverwriteLoaded = createAction(
    '[Booking] Booking Building Availability Schedule Overwrite Loaded',
    props<{ buildingAvailability: BuildingWeeklyAvailability }>()
);

export const actionBookingBuildingAvailabilityScheduleOverwriteFailed = createAction(
    '[Booking] Booking Building Availability Schedule Overwrite Failed',
);

export const actionBookingBuildingAvailabilityBuildingsRequested = createAction(
    '[Booking] Booking Building Availability Buildings Requested',
);

export const actionBookingBuildingAvailabilityBuildingsLoaded = createAction(
    '[Booking] Booking Building Availability Buildings Loaded',
    props<{ buildings: IBuilding[] }>()
);

export const actionBookingBuildingAvailabilityBuildingsFailed = createAction(
    '[Booking] Booking Building Availability Buildings Failed',
);

export const actionBookingBuildingAvailabilityCalendarsRequested = createAction(
    '[Booking] Booking Building Availability Calendars Requested',
    props<{calendarIds: number[]}>()
);

export const actionBookingBuildingAvailabilityCalendarsLoaded = createAction(
    '[Booking] Booking Building Availability Calendars Loaded',
    props<{calendars: BookingCalendar[]}>()
);

export const actionBookingBuildingAvailabilityCalendarsFailed = createAction(
    '[Booking] Booking Building Availability Calendars Failed',
);

export const actionBookingBuildingAvailabilityCalendarsReset = createAction(
    '[Booking] Booking Building Availability Calendars Reset',
);

export const actionBookingBuildingAvailabilityReset = createAction(
    '[Booking] Booking Building Availability Reset',
);



export const bookingBuildingAvailabilityActions = {
    actionBookingBuildingAvailabilitySelectedBuildingChange,
    actionBookingBuildingAvailabilitySelectedBuildingIdChange,
    actionBookingBuildingAvailabilityDateFilterChange,
    actionBookingBuildingAvailabilityTimeSlotToConfirmChange,
    actionBookingBuildingAvailabilityRequested,
    actionBookingBuildingAvailabilityLoaded,
    actionBookingBuildingAvailabilityBuildingsRequested,
    actionBookingBuildingAvailabilityBuildingsLoaded,
    actionBookingBuildingAvailabilityCalendarsRequested,
    actionBookingBuildingAvailabilityCalendarsLoaded,
    actionBookingBuildingAvailabilityCalendarsReset,
    actionBookingBuildingAvailabilityReset,
    actionBookingBuildingAvailabilityScheduleOverwriteRequested,
    actionBookingBuildingAvailabilityScheduleOverwriteLoaded,
    actionBookingBuildingCalendarToggleScheduleOverwrite
}

export const bookingBuildingAvailabilityFailedActions = {
    actionBookingBuildingAvailabilityFailed,
    actionBookingBuildingAvailabilityBuildingsFailed,
    actionBookingBuildingAvailabilityCalendarsFailed,
    actionBookingBuildingAvailabilityScheduleOverwriteFailed
}

