import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  selectIsAuthenticated,
} from './auth.selectors';
import { AppState } from '../core.state';
import { tap } from 'rxjs/operators';

@Injectable()
export class RoleGuardService  {
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const expectedRoles = route.data.expectedRoles;
    const isUserInRole = this.authService.isUserInRole(expectedRoles);

    return (
      this.store.pipe(select(selectIsAuthenticated)) && isUserInRole
    ).pipe(
      tap((isInRole: boolean) => {
        if (!isInRole) {
          // this.router.navigate([`/${route.url}`]);
          this.router.navigate(['sign-in']);
        }
      })
    );
  }
}
