import { createAction, props } from '@ngrx/store';
import { Building, Communication, Conversation, ConversationType, User } from '../../unified-inbox.model';

export const actionUnifiedInboxConversationsactiveConversationListTypeChange = createAction(
    '[Unified Inbox Conversation] Active Conversation Type Change',
    props<{ activeConversationListType: ConversationType }>()
)

export const actionUnifiedInboxConversationsRequest = createAction(
    '[Unified Inbox Conversation] Conversation Request',
    props<{ page: number, pageSize: number, itemCountPage: number }>()
)

export const actionUnifiedInboxConversationsLoaded = createAction(
    '[Unified Inbox Conversation] Conversation Loaded',
    props<{ conversations: Conversation[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxConversationsFailed = createAction(
    '[Unified Inbox Conversation] Conversation Failed',
)

export const actionUnifiedInboxOutboundCommunicationsRequest = createAction(
    '[Unified Inbox Conversation] Outbound Communications Request',
    props<{ page: number, pageSize: number, itemCountPage: number, leadId: number, startDate: Date, endDate: Date }>()
)

export const actionUnifiedInboxOutboundCommunicationsLoaded = createAction(
    '[Unified Inbox Conversation] Outbound Communications Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxOutboundCommunicationsFailed = createAction(
    '[Unified Inbox Conversation] Outbound Communications Failed',
)

export const actionUnifiedInboxVoiceMailCommunicationsRequest = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications Request',
    props<{ page: number, pageSize: number, itemCountPage: number, leadId: number, startDate: Date, endDate: Date, userIds: number[] }>()
)

export const actionUnifiedInboxVoiceMailCommunicationsLoaded = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxVoiceMailCommunicationsFailed = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications Failed',
)

export const actionUnifiedInboxOutboundCommunicationsMoreRequest = createAction(
    '[Unified Inbox Conversation] Outbound Communications More Request',
    props<{ page: number, pageSize: number, itemCountPage: number, leadId: number, startDate: Date, endDate: Date }>()
)

export const actionUnifiedInboxOutboundCommunicationsMoreLoaded = createAction(
    '[Unified Inbox Conversation] Outbound Communications More Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxOutboundCommunicationsMoreFailed = createAction(
    '[Unified Inbox Conversation] Outbound Communications More Failed',
)

export const actionUnifiedInboxVoiceMailCommunicationsMoreRequest = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications More Request',
    props<{ page: number, pageSize: number, itemCountPage: number, leadId: number, startDate: Date, endDate: Date, userIds: number[] }>()
)

export const actionUnifiedInboxVoiceMailCommunicationsMoreLoaded = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications More Loaded',
    props<{ communications: Communication[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxVoiceMailCommunicationsMoreFailed = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications More Failed',
)

export const actionUnifiedInboxOutboundCommunicationsReset = createAction(
    '[Unified Inbox Conversation] Outbound Communications Reset',
)

export const actionUnifiedInboxVoiceMailCommunicationsReset = createAction(
    '[Unified Inbox Conversation] Voice Mail Communications Reset',
)

export const actionUnifiedInboxCompanyConversationsReset = createAction(
    '[Unified Inbox Conversation] Company Conversation Reset',
)

export const actionUnifiedInboxCompanyConversationsRequest = createAction(
    '[Unified Inbox Conversation] Company Conversation Request',
    props<{
        page: number,
        pageSize: number,
        itemCountPage: number,
        userIds: number[],
        communicationTypes: string[],
        roles: string[],
        buildingIds: number[],
        lastCommunicationStartDate: Date,
        lastCommunicationEndDate: Date,
        createStartDate: Date,
        createEndDate: Date
    }>()
)

export const actionUnifiedInboxCompanyConversationsLoaded = createAction(
    '[Unified Inbox Conversation] Company Conversation Loaded',
    props<{ conversations: Conversation[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxCompanyConversationsFailed = createAction(
    '[Unified Inbox Conversation] Company Conversation Failed',
)

export const actionUnifiedInboxCompanyConversationsMoreRequest = createAction(
    '[Unified Inbox Conversation] Company Conversation More Request',
    props<{
        page: number,
        pageSize: number,
        itemCountPage: number,
        userIds: number[],
        communicationTypes: string[],
        roles: string[],
        buildingIds: number[],
        lastCommunicationStartDate: Date,
        lastCommunicationEndDate: Date,
        createStartDate: Date,
        createEndDate: Date
    }>()
)

export const actionUnifiedInboxCompanyConversationsMoreLoaded = createAction(
    '[Unified Inbox Conversation] Company Conversation More Loaded',
    props<{ conversations: Conversation[], totalRecords: number, page: number, pageSize: number }>()
)

export const actionUnifiedInboxCompanyConversationsMoreFailed = createAction(
    '[Unified Inbox Conversation] Company Conversation More Failed',
)

export const actionUnifiedInboxNewConversationNotificationLoaded = createAction(
    '[Unified Inbox Conversation] New Conversation Notification Loaded',
    props<{ conversation: Conversation }>()
)

export const actionUnifiedInboxNewCompanyConversationNotificationLoaded = createAction(
    '[Unified Inbox Conversation] New Company Conversation Notification Loaded',
    props<{ conversation: Conversation }>()
)

export const actionUnifiedInboxCompanyConversationsUserIdUpdate = createAction(
    '[Unified Inbox Conversation] Conversation UserId Update',
    props<{ userId: number, isChecked: boolean }>()
)

export const actionUnifiedInboxCompanyConversationsUserIdReset = createAction(
    '[Unified Inbox Conversation] Company Conversation UserId Reset',
)

export const actionUnifiedInboxCompanyConversationsBuildingIdUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Building Id Update',
    props<{ buildingId: number, isChecked: boolean }>()
)

export const actionUnifiedInboxCompanyConversationsBuildingIdReset = createAction(
    '[Unified Inbox Conversation] Conversation Building Id Reset',
)

export const actionUnifiedInboxConversationsPaginationReset = createAction(
    '[Unified Inbox Conversation] Conversation Pagination Reset',
)

export const actionUnifiedInboxConversationsCompanyPaginationReset = createAction(
    '[Unified Inbox Conversation] Conversation Company Pagination Reset',
)

export const actionUnifiedInboxConversationsOutboundPaginationReset = createAction(
    '[Unified Inbox Conversation] Conversation Outbound Pagination Reset',
)

export const actionUnifiedInboxConversationsVoiceMailPaginationReset = createAction(
    '[Unified Inbox Conversation] Conversation Voice Mail Pagination Reset',
)

export const actionUnifiedInboxConversationsCompanyFilterTypeUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Type Update',
    props<{ communicationType: string, isChecked: boolean }>()
)

export const actionUnifiedInboxConversationsCompanyFilterTypeReset = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Type Reset',
)

export const actionUnifiedInboxConversationsCompanyFilterLastCommunicationDateUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Last Communication Date Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionUnifiedInboxConversationsCompanyFilterCreateDateUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Create Date Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionUnifiedInboxConversationsCompanyFilterRoleUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Role Update',
    props<{ role: string, isChecked: boolean }>()
)

export const actionUnifiedInboxConversationsCompanyFilterRoleReset = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Role Reset',
)

export const actionUnifiedInboxConversationsCompanyFilterBuildingUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Building Update',
    props<{ buildingId: number, isChecked: boolean }>()
)

export const actionUnifiedInboxConversationsCompanyFilterBuildingReset = createAction(
    '[Unified Inbox Conversation] Conversation Company Filter Building Reset',
)

export const actionUnifiedInboxConversationsUserOptionsRequest = createAction(
    '[Unified Inbox Conversation] Conversation User Options Request',
    props<{ roles: string[] }>()
)

export const actionUnifiedInboxConversationsUserOptionsLoaded = createAction(
    '[Unified Inbox Conversation] Conversation User Options Loaded',
    props<{ userOptions: User[] }>()
)

export const actionUnifiedInboxConversationsUserOptionsFailed = createAction(
    '[Unified Inbox Conversation] Conversation User Options Failed',
)

export const actionUnifiedInboxConversationsVoiceMailUserOptionsRequest = createAction(
    '[Unified Inbox Conversation] Conversation Voice Mail User Options Request',
    props<{ roles: string[] }>()
)

export const actionUnifiedInboxConversationsVoiceMailUserOptionsLoaded = createAction(
    '[Unified Inbox Conversation] Conversation Voice Mail User Options Loaded',
    props<{ userOptions: User[] }>()
)

export const actionUnifiedInboxConversationsVoiceMailUserOptionsFailed = createAction(
    '[Unified Inbox Conversation] Conversation Voice Mail User Options Failed',
)

export const actionUnifiedInboxConversationsBuildingOptionsRequest = createAction(
    '[Unified Inbox Conversation] Conversation Building Options Request',
)

export const actionUnifiedInboxConversationsBuildingOptionsLoaded = createAction(
    '[Unified Inbox Conversation] Conversation Building Options Loaded',
    props<{ buildingOptions: Building[] }>()
)

export const actionUnifiedInboxConversationsBuildingOptionsFailed = createAction(
    '[Unified Inbox Conversation] Conversation Building Options Failed',
)

export const actionUnifiedInboxConversationsCompanyInboxFiltersReset = createAction(
    '[Unified Inbox Conversation] Conversation Company Inbox Filters Reset',
)

export const actionUnifiedInboxConversationsConversationsReset = createAction(
    '[Unified Inbox Conversation] Conversation Conversations Reset',
)

export const actionUnifiedInboxConversationNotificationFailed = createAction(
    '[Unified Inbox Conversation] New Conversation Failed'
)

export const actionUnifiedInboxMarkConversationReadRequested = createAction(
    '[Unified Inbox Conversation] Mark Conversation Read Requested',
    props<{ conversation: Conversation }>()
)

export const actionUnifiedInboxMarkConversationReadFailed = createAction(
    '[Unified Inbox Conversation] Mark Conversation Read Failed',
)

export const actionUnifiedInboxMarkConversationUnreadSuccess = createAction(
    '[Unified Inbox Conversation] Mark Conversation Unread Success',
    props<{ conversationId: number }>()
)

export const actionUnifiedInboxMarkConversationUnreadFailed = createAction(
    '[Unified Inbox Conversation] Mark Conversation Unread Failed'
)

export const actionUnifiedInboxMarkConversationConversationSelectedUpdate = createAction(
    '[Unified Inbox Conversation] Conversation Selected Update',
    props<{ conversation: Conversation }>()
)

export const actionUnifiedInboxOutboundCommunicationSelectedUpdate = createAction(
    '[Unified Inbox Conversation] Outbound Communication Selected Update',
    props<{ communication: Communication }>()
)

export const actionUnifiedInboxVoiceMailCommunicationSelectedUpdate = createAction(
    '[Unified Inbox Conversation] Voice Mail Communication Selected Update',
    props<{ communication: Communication }>()
)

export const actionUnifiedInboxOutboundCommunicationReset = createAction(
    '[Unified Inbox Conversation] Outbound Communication Reset',
)

export const actionUnifiedInboxVoiceMailCommunicationReset = createAction(
    '[Unified Inbox Conversation] Voice Mail Communication Reset',
)

export const actionUnifiedInboxOutboxFilterLeadInfoUpdate = createAction(
    '[Unified Inbox Conversation] Outox Filter Lead Id Update',
    props<{ leadId: number, leadFirstName: string, leadLastName: string }>()
)

export const actionUnifiedInboxOutboxFilterDateRangeUpdate = createAction(
    '[Unified Inbox Conversation] Outox Filter Date Range Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionUnifiedInboxOutboxFilterReset = createAction(
    '[Unified Inbox Conversation] Outox Filter Reset',
)

export const actionUnifiedInboxVoiceMailFilterLeadInfoUpdate = createAction(
    '[Unified Inbox Conversation] Voice Mail Filter Lead Id Update',
    props<{ leadId: number, leadFirstName: string, leadLastName: string }>()
)

export const actionUnifiedInboxVoiceMailFilterDateRangeUpdate = createAction(
    '[Unified Inbox Conversation] Voice Mail Filter Date Range Update',
    props<{ startDate: Date, endDate: Date }>()
)

export const actionUnifiedInboxVoiceMailFilterUserIdUpdate = createAction(
    '[Unified Inbox Conversation] Voice Mail Filter User Id Update',
    props<{ userId: number, isChecked: boolean }>()
)

export const actionUnifiedInboxVoiceMailFilterUserIdReset = createAction(
    '[Unified Inbox Conversation] Voice Mail Filter User Id Reset',
)


export const actionUnifiedInboxVoiceMailFilterReset = createAction(
    '[Unified Inbox Conversation] Voice Mail Filter Reset',
)

export const actionUnifiedInboxCloseActiveConversationRequested = createAction(
    '[Unified Inbox Conversation] Close Active Conversation Requested',
    props<{ conversationId: number }>()
)

export const actionUnifiedInboxCollaborateRequested = createAction(
    '[Unified Inbox Conversation] Collaborate Requested',
    props<{ leadId: number }>()
)

export const actionUnifiedInboxCollaborateSuccess = createAction(
    '[Unified Inbox Conversation] Collaborate Success',
)

export const actionUnifiedInboxCollaborateFailed = createAction(
    '[Unified Inbox Conversation] Collaborate Failed',
)

export const actionUnifiedInboxCloseActiveConversationSuccess = createAction(
    '[Unified Inbox Conversation] Close Active Conversation Success',
    props<{ conversationId: number, totalRecords: number }>()
)

export const actionUnifiedInboxCloseActiveConversationFailed = createAction(
    '[Unified Inbox Conversation] Close Active Conversation Failed',
)

export const actionUnifiedInboxCreateNewConversationRequested = createAction(
    '[Unified Inbox Conversation] Cerate New Conversation Requested',
    props<{ leadId: number }>()
)

export const actionUnifiedInboxCreateNewConversationSuccess = createAction(
    '[Unified Inbox Conversation] Create New Conversation Success',
    props<{ conversation: Conversation }>()
)

export const actionUnifiedInboxCreateNewConversationFailed = createAction(
    '[Unified Inbox Conversation] Create New Conversation Failed',
)

export const actionUnifiedInboxConversationStateReset = createAction(
    '[Unified Inbox Conversation] Conversation State Reset',
)

export const unifiedInboxConversationActions = {
    actionUnifiedInboxConversationsactiveConversationListTypeChange,
    actionUnifiedInboxConversationsRequest,
    actionUnifiedInboxConversationsLoaded,
    actionUnifiedInboxConversationsFailed,
    actionUnifiedInboxOutboundCommunicationsRequest,
    actionUnifiedInboxOutboundCommunicationsLoaded,
    actionUnifiedInboxOutboundCommunicationsFailed,
    actionUnifiedInboxOutboundCommunicationsMoreRequest,
    actionUnifiedInboxOutboundCommunicationsMoreLoaded,
    actionUnifiedInboxOutboundCommunicationsMoreFailed,
    actionUnifiedInboxOutboundCommunicationsReset,
    actionUnifiedInboxVoiceMailCommunicationsRequest,
    actionUnifiedInboxVoiceMailCommunicationsLoaded,
    actionUnifiedInboxVoiceMailCommunicationsFailed,
    actionUnifiedInboxVoiceMailCommunicationsMoreRequest,
    actionUnifiedInboxVoiceMailCommunicationsMoreLoaded,
    actionUnifiedInboxVoiceMailCommunicationsMoreFailed,
    actionUnifiedInboxVoiceMailCommunicationsReset,
    actionUnifiedInboxCompanyConversationsReset,
    actionUnifiedInboxCompanyConversationsRequest,
    actionUnifiedInboxCompanyConversationsLoaded,
    actionUnifiedInboxCompanyConversationsFailed,
    actionUnifiedInboxCompanyConversationsMoreRequest,
    actionUnifiedInboxCompanyConversationsMoreLoaded,
    actionUnifiedInboxCompanyConversationsMoreFailed,
    actionUnifiedInboxConversationStateReset,
    actionUnifiedInboxConversationsUserOptionsRequest,
    actionUnifiedInboxConversationsUserOptionsLoaded,
    actionUnifiedInboxConversationsUserOptionsFailed,
    actionUnifiedInboxConversationsVoiceMailUserOptionsRequest,
    actionUnifiedInboxConversationsVoiceMailUserOptionsLoaded,
    actionUnifiedInboxConversationsVoiceMailUserOptionsFailed,
    actionUnifiedInboxConversationsBuildingOptionsRequest,
    actionUnifiedInboxConversationsBuildingOptionsLoaded,
    actionUnifiedInboxConversationsBuildingOptionsFailed,
    actionUnifiedInboxNewConversationNotificationLoaded,
    actionUnifiedInboxNewCompanyConversationNotificationLoaded,
    actionUnifiedInboxConversationNotificationFailed,
    actionUnifiedInboxMarkConversationReadRequested,
    actionUnifiedInboxMarkConversationReadFailed,
    actionUnifiedInboxMarkConversationUnreadSuccess,
    actionUnifiedInboxMarkConversationUnreadFailed,
    actionUnifiedInboxCompanyConversationsUserIdUpdate,
    actionUnifiedInboxCompanyConversationsUserIdReset,
    actionUnifiedInboxCompanyConversationsBuildingIdUpdate,
    actionUnifiedInboxCompanyConversationsBuildingIdReset,
    actionUnifiedInboxConversationsCompanyInboxFiltersReset,
    actionUnifiedInboxConversationsCompanyFilterTypeReset,
    actionUnifiedInboxConversationsCompanyFilterTypeUpdate,
    actionUnifiedInboxConversationsCompanyFilterRoleUpdate,
    actionUnifiedInboxConversationsCompanyFilterRoleReset,
    actionUnifiedInboxConversationsCompanyFilterBuildingUpdate,
    actionUnifiedInboxConversationsCompanyFilterBuildingReset,
    actionUnifiedInboxConversationsCompanyFilterLastCommunicationDateUpdate,
    actionUnifiedInboxConversationsCompanyFilterCreateDateUpdate,
    actionUnifiedInboxConversationsPaginationReset,
    actionUnifiedInboxConversationsCompanyPaginationReset,
    actionUnifiedInboxConversationsOutboundPaginationReset,
    actionUnifiedInboxConversationsVoiceMailPaginationReset,
    actionUnifiedInboxMarkConversationConversationSelectedUpdate,
    actionUnifiedInboxOutboundCommunicationSelectedUpdate,
    actionUnifiedInboxOutboundCommunicationReset,
    actionUnifiedInboxVoiceMailCommunicationSelectedUpdate,
    actionUnifiedInboxVoiceMailCommunicationReset,
    actionUnifiedInboxConversationsConversationsReset,
    actionUnifiedInboxCloseActiveConversationRequested,
    actionUnifiedInboxCloseActiveConversationSuccess,
    actionUnifiedInboxCreateNewConversationRequested,
    actionUnifiedInboxCreateNewConversationSuccess,
    actionUnifiedInboxCreateNewConversationFailed,
    actionUnifiedInboxCloseActiveConversationFailed,
    actionUnifiedInboxOutboxFilterLeadInfoUpdate,
    actionUnifiedInboxOutboxFilterDateRangeUpdate,
    actionUnifiedInboxOutboxFilterReset,
    actionUnifiedInboxVoiceMailFilterLeadInfoUpdate,
    actionUnifiedInboxVoiceMailFilterDateRangeUpdate,
    actionUnifiedInboxVoiceMailFilterReset,
    actionUnifiedInboxVoiceMailFilterUserIdUpdate,
    actionUnifiedInboxVoiceMailFilterUserIdReset,
    actionUnifiedInboxCollaborateRequested,
    actionUnifiedInboxCollaborateSuccess,
    actionUnifiedInboxCollaborateFailed
};
