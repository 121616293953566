import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { clientDialogRentalRequestActions, clientDialogRentalRequestFailedActions } from "./client-dialog-rental-request.action";
import { LeasingRep, RentalItem, RentalRequest } from "app/features/client-dialog/client-dialog-model";
import { ClientDialogMatDialogService } from "app/features/client-dialog/client-dialog-mat-dialog.serivce";
import { ClientDialogApplicationService } from "../client-dialog-rental-application/client-dialog-rental-application.service";

@Injectable()
export class ClientDialogClientRentalRequestEffects {
    constructor(
        private actions$: Actions,
        private clientDialogApplicationService: ClientDialogApplicationService,
        private clientDialogMatDialogService: ClientDialogMatDialogService,
        private notificationService: NotificationService
    ) { }

    requestClientApplication = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRentalRequestActions.actionClientDialogApplicationRentalRequestRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getClientRentalRequests(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogRentalRequestActions.actionClientDialogApplicationRentalRequestSuccess({ rentalRequest: <RentalRequest> response.data });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogRentalRequestFailedActions.actionClientDialogApplicationRentalRequestFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogRentalRequestFailedActions.actionClientDialogApplicationRentalRequestFailed())
                    })
                )
            )
        )
    );

    startNewClientApplication = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRentalRequestActions.actionClientDialogApplicationInitRentalRequestRequested),
            switchMap(action =>
                this.clientDialogApplicationService.initNewRentalRequest(action.initRentalRequest).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.clientDialogMatDialogService.closeInitRentalRequest();
                            return clientDialogRentalRequestActions.actionClientDialogApplicationInitRentalRequestSuccess();
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogRentalRequestFailedActions.actionClientDialogApplicationInitRentalRequestFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogRentalRequestFailedActions.actionClientDialogApplicationInitRentalRequestFailed())
                    })
                )
            )
        )
    );

    rentalItemsRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRentalRequestActions.actionClientDialogApplicationRentalItemsRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getRentalItems(action.unitId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogRentalRequestActions.actionClientDialogApplicationRentalItemsSuccess({ rentalItems: <RentalItem[]> response.data });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogRentalRequestFailedActions.actionClientDialogApplicationRentalItemsFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogRentalRequestFailedActions.actionClientDialogApplicationRentalItemsFailed())
                    })
                )
            )
        )
    );

    rentalLeasingRepRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRentalRequestActions.actionClientDialogApplicationLeasingRepRequested),
            switchMap(action =>
                this.clientDialogApplicationService.getLeasingRepresentativesByBuilding(action.buildingId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            return clientDialogRentalRequestActions.actionClientDialogApplicationLeasingRepSuccess({ leasingReps: <LeasingRep[]> response.data });
                        } else {
                            this.notificationService.error(response.message)
                            return clientDialogRentalRequestFailedActions.actionClientDialogApplicationLeasingRepFailed()
                        }
                    }),
                    catchError(error => {
                        this.notificationService.error(error.message)
                        return of(clientDialogRentalRequestFailedActions.actionClientDialogApplicationLeasingRepFailed())
                    })
                )
            )
        )
    );
}