import { createAction, props } from '@ngrx/store';

export const actionClientDialogCreateNoteRequest = createAction(
    '[Client Dialog Note] Create Note Request',
    props<{ id: number, note: string }>()
)

export const actionClientDialogCreateNoteSuccessed = createAction(
    '[Client Dialog Note] Create Note Successed',
)

export const actionClientDialogCreateNoteFailed = createAction(
    '[Client Dialog Note] Create Note Failed',
)

export const actionClientDialogLeadNoteStateReset = createAction(
    '[Client Dialog Note] Lead Note State Reset',
)

export const clientDialogLeadNoteActions = {
    actionClientDialogCreateNoteRequest,
    actionClientDialogCreateNoteSuccessed,
    actionClientDialogCreateNoteFailed,
    actionClientDialogLeadNoteStateReset
};
