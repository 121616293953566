import { AppState } from '../core.state';

export const NIGHT_MODE_THEME = 'DARK-THEME';

export type Language = 'en' | 'fr';

export interface SettingsState {
  language: string;
  theme: string;
  autoNightMode: boolean;
  nightTheme: string;
  stickyHeader: boolean;
  pageAnimations: boolean;
  pageAnimationsDisabled: boolean;
  elementsAnimations: boolean;
  hour: number;
  notifications: notificationSetting;
  connectionState: string
}

export interface notificationSetting {
  taskNotificationCount: number;
  userLeadConversationNotificationCount: number;
  userTenantConversationNotificationCount: number;
}

export interface State extends AppState {
  settings: SettingsState;
}
