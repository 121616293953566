import { createSelector } from "@ngrx/store";
import { selectTenantDialog, TenantDialogState, TenantDialogTaskState } from "../../tenant-dialog.state";

export const selectTenantDialogTaskState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogTask
);

export const selectTenantDialogTasks = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.tasks
)

export const selectTenantDialogIsTasksLoaded = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.isTasksLoaded
)

export const selectTenantDialogTaskAssignUsers = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskCreateState.taskAssignUsers
)

export const selectTenantDialogTaskIsTaskCreating = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskCreateState.isCreating
)

export const selectTenantDialogTaskIsTaskCreatedSuccessfully = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskCreateState.isCreatedSuccessfully
)

export const selectTenantDialogTaskIsTaskEditing = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskEditState.isEditing
)

export const selectTenantDialogTaskIsTaskEditSuccessfully = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskEditState.isEditSuccessfully
)

export const selectTenantDialogTaskIsTaskDeleting = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskDeleteState.isDeleting
)

export const selectTenantDialogTaskIsTaskDeletedSuccessfully = createSelector(
    selectTenantDialogTaskState,
    (state: TenantDialogTaskState) => state.taskDeleteState.isDeletSuccessfully
)