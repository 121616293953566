import { createSelector } from "@ngrx/store";
import { ClientDialogClientRentalApplicationState, ClientDialogState, selectClientDialog } from "app/features/client-dialog/client-dialog.state";

export const selectClientDialogClientRentalApplicationState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => {
    return state.clientDialogClientRentalApplication
  }
);

export const selectClientDialogIsClientRentalApplicationLoaded = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationLoaded
  }
);

export const selectClientDialogIsClientRentalRequestLoaded = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalRequestLoaded
  }
);

export const selectClientDialogIsRentalApplicationSending = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationSending
  }
);

export const selectClientDialogIsRentalApplicationStarting = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationStarting
  }
);

export const selectClientDialogRentalApplicationCancelErrorMessage = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.cancelErrorMessage
  }
);

export const selectClientDialogIsRentalFileUploadLinkSending = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalFileUploadLinkSending
  }
);

export const selectClientDialogIsRentalApplicationCancelling = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationCancelling
  }
);

export const selectClientDialogIsRentalApplicationFormRemoving = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormRemoving
  }
);

export const selectClientDialogRentalRequestDepositTransactions = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequest.rentalRequestDepositTransactions
  }
);

export const selectClientDialogRentalRequestUnitsToSelect = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplicationUnitsToSelect
  }
);

export const selectClientDialogRentalApplicationFormFileRemoving = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormFileRemoving
  }
);

export const selectClientDialogRentalApplicationVerifcationReportRemoving = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationVerificationReportRemoving
  }
);

export const selectClientDialogIsRentalApplicationResendApplicantEmail = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationEmailSending
  }
);

export const selectClientDialogRentalRequestValidationMessages = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequestValidationMessage
  }
);

export const selectClientDialogIsRentalRequestDateSending = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalRequestDateSending
  }
);

export const selectClientDialogIsRentalApplicationCreditCheckSending = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationCreditCheckFormSending
  }
);

export const selectClientDialogIsRentalRequestFormListLoading = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalRequestFormListLoading
  }
);

export const selectClientDialogIsRentalApplicationFormListLoading = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormListLoading
  }
);

export const selectClientDialogIsClientRentalApplicationAddNewFormSending = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationAddNewFormSending
  }
);

export const selectClientDialogIsClientRentalApplicationApproveAndSigning = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormApproveAndSigning
  }
);

export const selectClientDialogIsRentalRequestStatusUpdating = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalRequestStatusUpdating
  }
);

export const selectClientDialogIsClientRentalFormTemplates = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalFormTemplates
  }
);

export const selectClientDialogIsClientRentalApplicationAddNewFormLoading = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationAddNewFormLoading
  }
);

export const selectClientDialogRentalApplication = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplication
  }
);

export const selectClientDialogRentalApplicationLeadInfo = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplicationLeadInfo
  }
);


export const selectClientDialogRentalRequest = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequest
  }
);

export const selectClientDialogRentalRequestIsParkingRequested = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequest.isParkingRequested
  }
);


export const selectClientDialogRentalRequestIsStorageRequested = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequest.isStorageRequested
  }
);


export const selectClientDialogIsClientRentalApplicationListItems = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplicationListItems
  }
);

export const selectClientDialogIsClientRentalRequestListItems = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequestListItems
  }
);

export const selectClientDialogIsClientRentalApplicationForms = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplication.rentalApplicationForms
  }
);

export const selectClientDialogIsClientRentalApplicationFormFiles = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplication.rentalApplicationFormFiles
  }
);

export const selectClientDialogIsClientRentalApplicationCreditBackgroundReports = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplication.rentalApplicationCertnReports
  }
);

export const selectClientDialogIsClientrentalApplicationPlaidReports = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplication.rentalApplicationPlaidReports
  }
);

export const selectClientDialogIsClientRentalApplicationForm = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.selectedRentalApplicationForm
  }
);

export const selectClientDialogIsClientRentalApplicationFormViewerLoading = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormViewerLoading
  }
);

export const selectClientDialogIsClientRentalApplicationFormEditorLoading = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormEditorLoading
  }
);

export const selectClientDialogIsClientRentalApplicationFormEditorDocument = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplicationFormEditorDocument
  }
);

export const selectClientDialogIsClientRentalApplicationFormViewerDocument = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalApplicationFormViewerDocument
  }
);

export const selectClientDialogRentalApplicationAddNewErrorMsg = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.addNewApplicationError
  }
);

export const selectClientDialogRentalRequestActivities = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalRequestActivities
  }
);

export const selectClientDialogIsRentalRequestActivitiesLoaded = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalRequestActivitiesLoaded
  }
);

export const selectClientDialogIfShowRentalRequestActivities = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.ifShowRentalRequestActivities
  }
);

export const selectClientDialogRentalRequestActivitiesPageNo = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.activityPageNo
  }
);

export const selectClientDialogRentalRequestActivitiesPageSize = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.activittPageSize
  }
);

export const selectClientDialogRentalRequestActivitiesTotalRecords = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.activityTotalRecords
  }
);

export const selectClientDialogRentalRequestActivitiesisLoadingMore = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isActivitiesLoadingMore
  }
);

export const selectClientDialogIsRentalApplicationInfoUpdating = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationinfoUpdating
  }
);

export const selectClientDialogRentalApplicationRentalItemOptions = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalItemOptions
  }
);

export const selectClientDialogRentalApplicationRentalItemTypes = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalItemTypes
  }
);

export const selectClientDialogRentalApplicationIsRentalItemUpdating = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalItemUpdating
  }
);

export const selectClientDialogRentalApplicationIsRentalItemOptionsLoaded = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalItemOptionsLoaded
  }
);
export const selectClientDialogRentalApplicationIsRentalItemTypesLoaded = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalItemTypesLoaded
  }
);

export const selectClientDialogRentalApplicationIsRentalItemsLoaded = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalItemsLoaded
  }
);

export const selectClientDialogRentalApplicationRentalItemTypeSelected = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalItemSelected
  }
);

export const selectClientDialogRentalApplicationRentalItemOptionsPagination = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalItemOptionsPagination
  }
);

export const selectClientDialogRentalApplicationRentalItemOptionsTotalRecords = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.rentalItemOptionsTotalRecords
  }
);

export const selectClientDialogRentalApplicationIsFormEditorSaving = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalApplicationFormEditorSaving
  }
);

export const selectClientDialogRentalApplicationIsleasingDocSignatureReseting = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isLeasingSignatureReseting
  }
);

export const selectClientDialogRentalApplicationIsRentalRequestUnitUpdating = createSelector(
  selectClientDialogClientRentalApplicationState,
  (state: ClientDialogClientRentalApplicationState) => {
    return state.isRentalRequestUnitUpdating
  }
);

