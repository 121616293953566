import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { Tag } from "../../../../shared/models/lead-info-models";
import { ClientDialogService } from "../../client-dialog.service";
import { clientDialogTagActions } from "./client-dialog-lead-tag.action";

@Injectable()
export class ClientDialogTagsEffects {
    constructor(
        private actions$: Actions,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    requestLeadTags = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogLeadTagsRequested),
            switchMap(action =>
                this.clientDialogService.getLeadTags(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags = response.data as Tag[]
                            return clientDialogTagActions.actionClientDialogLeadTagsLoaded({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogLeadTagsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogTagActions.actionClientDialogLeadTagsFailed()))
                )
            )
        )
    );

    requestClientTagOptions = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogTagOptionsRequested),
            switchMap(action =>
                this.clientDialogService.getLeadTagOptions().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags = response.data as Tag[]
                            return clientDialogTagActions.actionClientDialogTagOptionsLoaded({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogTagOptionsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogTagActions.actionClientDialogTagOptionsFailed()))
                )
            )
        )
    );

    initialTagCheckStatus = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogTagOptionsLoaded),
            switchMap(action =>
                [clientDialogTagActions.actionClientDialogTagOptionsCheckStateInitialize()]
            )
        )
    )

    deleteClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogClientTagDeleteRequested),
            switchMap(action =>
                this.clientDialogService.deleteClientTag(action.leadId, action.tagId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags: Tag[] = response.data
                            return clientDialogTagActions.actionClientDialogClientTagDeleteSuccessfully({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogClientTagDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogTagActions.actionClientDialogClientTagDeleteFailed())
                    })
                )
            )
        )
    );

    deleteClientPreDefinedTag = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogClientLeadPreDefinedTagDeleteRequested,
            ),
            switchMap(action =>
                this.clientDialogService.deleteClientPreDefinedTag(action.leadId, action.tagId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags: Tag[] = response.data
                            return clientDialogTagActions.actionClientDialogClientLeadPreDefinedTagDeleteSuccessfully({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogClientLeadPreDefinedTagDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogTagActions.actionClientDialogClientLeadPreDefinedTagDeleteFailed())
                    })
                )
            )
        )
    );

    updateClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogTagsUpdateRequested),
            switchMap(action =>
                this.clientDialogService.updateClientTags(action.id, action.tags, action.preDefinedTags).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let tags: Tag[] = response.data
                            return clientDialogTagActions.actionClientDialogTagsUpdateSuccessfully({ tags })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogTagsUpdateFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogTagActions.actionClientDialogTagsUpdateFailed())
                    })
                )
            )
        )
    );

    addBatchClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogBatchTagsAddRequested),
            switchMap(action =>
                this.clientDialogService.addBatchClientTags(action.leadIds, action.tagIds, action.preDefinedTagIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return clientDialogTagActions.actionClientDialogBatchTagsAddSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogBatchTagsAddFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogTagActions.actionClientDialogBatchTagsAddFailed())
                    })
                )
            )
        )
    );

    removeBatchClientTag = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogTagActions.actionClientDialogBatchTagsRemoveRequested),
            switchMap(action =>
                this.clientDialogService.removeBatchClientTags(action.leadIds, action.tagIds, action.preDefinedTagIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return clientDialogTagActions.actionClientDialogBatchTagsRemoveSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogTagActions.actionClientDialogBatchTagsRemoveFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogTagActions.actionClientDialogBatchTagsRemoveFailed())
                    })
                )
            )
        )
    );

}