import { createAction, props } from '@ngrx/store';
import {  BuildingWeeklyAvailability, IBuilding } from '../../client-dialog-inventory.model';
import { BookingCalendar } from '../../../../../../shared/models/booking-calendar.model';

export const actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange = createAction(
    '[Client Dialog Booking] Booking Building Availability Selected Building Change',
    props<{ building: IBuilding }>()
);
export const actionClientDialogBookingBuildingAvailabilitySelectedBuildingIdChange = createAction(
    '[Client Dialog Booking] Booking Building Availability Selected Building Id Change',
    props<{ builidngId: number }>()
);

export const actionClientDialogBookingBuildingAvailabilityDateFilterChange = createAction(
    '[Client Dialog Booking] Booking Building Availability Date Filter Change',
    props<{ date: string }>()
);

export const actionClientDialogBookingBuildingAvailabilityTimeSlotToConfirmChange = createAction(
    '[Client Dialog Booking] Booking Building Availability Time Slot Confirm Change',
    props<{ timeSlot: string }>()
);

export const actionClientDialogBookingBuildingAvailabilityRequested = createAction(
    '[Client Dialog Booking] Booking Building Availability Requested',
    props<{ date: string, buildingId: number }>()
);

export const actionClientDialogBookingBuildingAvailabilityLoaded = createAction(
    '[Client Dialog Booking] Booking Building Availability Loaded',
    props<{ buildingAvailability: BuildingWeeklyAvailability }>()
);

export const actionClientDialogBookingBuildingAvailabilityFailed = createAction(
    '[Client Dialog Booking] Booking Building Availability Failed',
);

export const actionClientDialogBookingBuildingAvailabilityScheduleOverwriteRequested = createAction(
    '[Client Dialog Booking] Booking Building Availability Schedule Overwrite Requested',
    props<{ date: string, buildingId: number }>()
);

export const actionClientDialogBookingBuildingAvailabilityScheduleOverwriteLoaded = createAction(
    '[Client Dialog Booking] Booking Building Availability Schedule Overwrite Loaded',
    props<{ buildingAvailability: BuildingWeeklyAvailability }>()
);

export const actionClientDialogBookingBuildingAvailabilityScheduleOverwriteFailed = createAction(
    '[Client Dialog Booking] Booking Building Availability Schedule Overwrite Failed',
);

export const actionClientDialogBookingBuildingAvailabilityBuildingsRequested = createAction(
    '[Client Dialog Booking] Booking Building Availability Buildings Requested',
    props<{ buildingId: number }>()
);

export const actionClientDialogBookingBuildingAvailabilityBuildingsLoaded = createAction(
    '[Client Dialog Booking] Booking Building Availability Buildings Loaded',
    props<{ buildings: IBuilding[], buildingId: number }>()
);

export const actionClientDialogBookingBuildingAvailabilityBuildingsFailed = createAction(
    '[Client Dialog Booking] Booking Building Availability Buildings Failed',
);

export const actionClientDialogBookingBuildingAvailabilityCalendarsRequested = createAction(
    '[Client Dialog Booking] Booking Building Availability Calendars Requested',
    props<{ calendarIds: number[] }>()
);

export const actionClientDialogBookingEventCalendarToggleScheduleOverwrite = createAction(
    '[Client Dialog Booking] Booking Building Schedule Overwrite',
    props<{ isScheduleOverwrite: boolean }>()
);

export const actionClientDialogBookingBuildingAvailabilityCalendarsLoaded = createAction(
    '[Client Dialog Booking] Booking Building Availability Calendars Loaded',
    props<{ calendars: BookingCalendar[] }>()
);

export const actionClientDialogBookingStepChange = createAction(
    '[Client Dialog Booking] Booking Step Change',
    props<{ bookingStep: number }>()
);

export const actionClientDialogBookingBuildingAvailabilityCalendarsFailed = createAction(
    '[Client Dialog Booking] Booking Building Availability Calendars Failed',
);

export const actionClientDialogBookingBuildingAvailabilityCalendarsReset = createAction(
    '[Client Dialog Booking] Booking Building Availability Calendars Reset',
);

export const actionClientDialogBookingBuildingAvailabilityReset = createAction(
    '[Client Dialog Booking] Booking Building Availability Reset',
);

export const clientDialogBookingBuildingAvailabilityActions = {
    actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange,
    actionClientDialogBookingBuildingAvailabilitySelectedBuildingIdChange,
    actionClientDialogBookingBuildingAvailabilityDateFilterChange,
    actionClientDialogBookingBuildingAvailabilityTimeSlotToConfirmChange,
    actionClientDialogBookingBuildingAvailabilityRequested,
    actionClientDialogBookingBuildingAvailabilityLoaded,
    actionClientDialogBookingBuildingAvailabilityBuildingsRequested,
    actionClientDialogBookingBuildingAvailabilityBuildingsLoaded,
    actionClientDialogBookingBuildingAvailabilityCalendarsRequested,
    actionClientDialogBookingBuildingAvailabilityCalendarsLoaded,
    actionClientDialogBookingBuildingAvailabilityCalendarsReset,
    actionClientDialogBookingBuildingAvailabilityReset,
    actionClientDialogBookingEventCalendarToggleScheduleOverwrite,
    actionClientDialogBookingBuildingAvailabilityScheduleOverwriteRequested,
    actionClientDialogBookingBuildingAvailabilityScheduleOverwriteLoaded,
    actionClientDialogBookingStepChange
}

export const clientDialogBookingBuildingAvailabilityFailedActions = {
    actionClientDialogBookingBuildingAvailabilityFailed,
    actionClientDialogBookingBuildingAvailabilityBuildingsFailed,
    actionClientDialogBookingBuildingAvailabilityCalendarsFailed,
    actionClientDialogBookingBuildingAvailabilityScheduleOverwriteFailed
}

