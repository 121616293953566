
<div class="dq-row header-container">
    <div class="dq-row dq-flex-aligned">
        <mat-icon class="icon-general">list</mat-icon>
        <span class="title">Tasks</span>
    </div>
    <button mat-icon-button (click)="openCreateTaskDialog()">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div class="tasks-container">
    <ng-container *ngIf="isTasksLoaded$ | async; else loading">
        <ng-container *ngFor="let task of (tasks$ | async); let i = index">
            <div class="task-item-div">
                <div class="task-container">
                    <div class="dq-flex-aligned">
                        <mat-checkbox class="task-check-box" [checked]="task.isCompleted"
                        (click)="taskStatusToggle(task.id)">
                        </mat-checkbox>
                    </div>
                    <div style="width: 100%;" [ngClass]="{'task-done-item': task.isCompleted}">
                        <div class="dq-flex-aligned">
                            <div class="task-date-div">
                                <span class="task-date-text">
                                    {{task.date | date: 'EE, MMM d, h:mm a'}} {{task.timeZone}}
                                </span>
                            </div>
                        </div>
                        <div class="dq-flex-aligned">
                            <div class="task-name-div">
                                <mat-icon class="task-type-icon"
                                    [ngStyle]="{color:getTaskTypeInfo(task.type)?.color}">
                                    {{getTaskTypeInfo(task.type)?.icon}}
                                </mat-icon>
                                <span class="task-name">{{task.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="dq-flex-aligned">
                        <ng-container *ngIf="!task.isCompleted">
                            <button mat-icon-button (click)="openEditTaskDialog(task)">
                                <mat-icon >edit</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="i < (tasks$ | async).length -1">
                <mat-divider></mat-divider>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #loading>
        <mat-spinner class="loading-spinner"></mat-spinner>
    </ng-template>
</div>
