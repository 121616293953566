import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { INavCard } from './nav-card.model';

@Component({
  selector: 'dq-nav-card',
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavCardComponent  {
  @Input() card: INavCard;
  @Input() isLink: boolean;
  constructor() {}

}
