import { createSelector } from "@ngrx/store";
import { ClientDialogState, ClientDialogPreferenceState, selectClientDialog } from "../../client-dialog.state";

export const selectClientDialogPreferenceState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogPreferences
);

export const selectclientDialogPreferencesStateIsPreferencesLoaded = createSelector(
    selectClientDialogPreferenceState,
    (state: ClientDialogPreferenceState) => {
        return state.isPreferencesLoaded
    }
);

export const selectclientDialogPreferencesStateIsPreferenceOptionssLoaded = createSelector(
    selectClientDialogPreferenceState,
    (state: ClientDialogPreferenceState) => {
        return state.isPreferenceOptionsLoaded
    }
);

export const selectclientDialogPreferencesStateClientPreferences = createSelector(
    selectClientDialogPreferenceState,
    (state: ClientDialogPreferenceState) => {
        return state.clientPreferences
    }
);

export const selectclientDialogPreferencesStatePreferenceOptions = createSelector(
    selectClientDialogPreferenceState,
    (state: ClientDialogPreferenceState) => {
        return state.preferenceOptions
    }
);

export const selectclientDialogPreferencesIsPreferenceSubmitting = createSelector(
    selectClientDialogPreferenceState,
    (state: ClientDialogPreferenceState) => {
        return state.isPreferenceSubmitting
    }
);

export const selectclientDialogPreferencesIsCreateUpdateSuccessfully = createSelector(
    selectClientDialogPreferenceState,
    (state: ClientDialogPreferenceState) => {
        return state.isUpdateSuccessfully
    }
);  