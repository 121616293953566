import { Action, createReducer, on } from "@ngrx/store";
import { BookingAccountRepresentativesState } from "../../booking.state";
import { actionBookingAccountRepresentativesLoaded, actionBookingAccountBuildingRepresentativesRequested, actionBookingAccountBuildingInventoryRequested, actionBookingAccountBuildingInventoryLoaded, actionBookingAccountBuildingRepresentativesLoaded, actionBookingAccountRepresentativesRequested, actionBookingAccountRepresentativesReset, actionBookingAccountBuildingsLoaded, actionBookingAccountBuildingssRequested, actionBookingAccountBuildingsFailed } from "./booking-account-representatives.action";

export const initialState: BookingAccountRepresentativesState = {
  isLoaded: false,
  representativeThumbnails: [],
  account: {
    id: '',
    name: '',
    type: '',
    uri: '',
  },
  buildingRepresentatives: [],
  isBuildingsLoaded: false,
  isBuildingRepresentativesLoaded: false,
  buildings: [],
  buildingUnits: [],
  isBuildingUnitsLoaded: false
}

const reducer = createReducer(
  initialState,
  on(
    actionBookingAccountRepresentativesRequested,
    (state) => ({
      ...state,
      isLoaded: false
    })
  ),
  on(
    actionBookingAccountBuildingRepresentativesRequested,
    (state, { buildingId }) => ({
      ...state,
      isBuildingRepresentativesLoaded: false
    })
  ),
  on(
    actionBookingAccountBuildingInventoryRequested,
    (state, { buildingId }) => ({
      ...state,
      isBuildingUnitsLoaded: false
    })
  ),
  on(
    actionBookingAccountBuildingssRequested,
    (state, { accountId }) => ({
      ...state,
      isBuildingsLoaded: false
    })
  ),
  on(
    actionBookingAccountRepresentativesLoaded,
    (state, { account, representativeThumbnails }) => ({
      ...state,
      isLoaded: true,
      account: account,
      representativeThumbnails: representativeThumbnails
    })
  ),
  on(
    actionBookingAccountBuildingRepresentativesLoaded,
    (state, { representativeThumbnails }) => ({
      ...state,
      isBuildingRepresentativesLoaded: true,
      buildingRepresentatives: representativeThumbnails
    })
  ),
  on(
    actionBookingAccountBuildingInventoryLoaded,
    (state, { units }) => ({
      ...state,
      isBuildingUnitsLoaded: true,
      buildingUnits: units
    })
  ),
  on(
    actionBookingAccountBuildingsLoaded,
    (state, { buildings }) => ({
      ...state,
      isBuildingsLoaded: true,
      buildings: buildings
    })
  ),
  on(
    actionBookingAccountBuildingsFailed,
    (state) => ({
      ...state,
      isBuildingsLoaded: true,
    })
  ),
  on(
    actionBookingAccountRepresentativesReset,
    (state) => (
      {
        ...initialState,
        account: {
          ...state.account,
          id: state.account.id,
          name: state.account.name,
          type: state.account.type,
          uri: state.account.uri,
        }
      }
    )
  ),
)

export const bookingAccountRepresentativesReducer = (
  state: BookingAccountRepresentativesState | undefined,
  action: Action
) => {
  return reducer(state, action);
};
