import { createAction, props } from "@ngrx/store";
import { IUpdateTenantProfileParams, TenantInfo, TenantPhoneNumber } from "../../../../shared/models/tenant-info-models";

export const actionTenantDialogByTenantIdRequired = createAction(
    '[Tenant Dialog] Tenant Dialog By Tenant Id Required',
    props<{ tenantId: number }>()
);

export const actionTenantDialogByTenantIdSuccess = createAction(
    '[Tenant Dialog] Tenant Dialog By Tenant Id Loaded',
    props<{ tenantInfo: TenantInfo }>()
);

export const actionTenantDialogUpdateTenantId = createAction(
    '[Tenant Dialog] Tenant Dialog Update TenantId',
    props<{ tenantId: number }>()
);

export const actionTenantDialogFailed = createAction(
    '[Tenant Dialog] Tenant Dialog Failed'
);

export const actionTenantUserGeneralProfileChangeIsBaseInfoEditMode = createAction(
    '[Tenant Dialog] Change Base Info Is Edit Mode',
    props<{ isEditMode: boolean }>()
)

export const actionTenantUserGeneralProfileUpdateRequested = createAction(
    '[Tenant Dialog] Tenant Dialog Dialog Status Requested',
    props<{ params: IUpdateTenantProfileParams }>()
);

export const actionTenantUserGeneralProfileUpdateSuccessfully = createAction(
    '[Tenant Dialog] Tenant Dialog Dialog Status Successfully',
    props<{ tenantInfo: TenantInfo }>()
);

export const actionTenantUserGeneralProfileUpdateFailed = createAction(
    '[Tenant Dialog] Tenant Dialog Dialog Status Failed'
);

export const actionTenantDialogTenantPhoneNumberBlockRequested = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number Block Requested',
    props<{ tenantId: number, phoneNumber: string, reason: string }>()
)

export const actionTenantDialogTenantPhoneNumberBlockSuccessfully = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number Block Successfully',
    props<{ phoneNumberBlockInfo: TenantPhoneNumber[] }>()
)

export const actionTenantDialogTenantPhoneNumberBlockFailed = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number Block Failed',
)

export const actionTenantDialogTenantPhoneNumberUnBlockRequested = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number UnBlock Requested',
    props<{ tenantId: number, phoneNumber: string }>()
)

export const actionTenantDialogTenantPhoneNumberUnBlockSuccessfully = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number UnBlock Successfully',
    props<{ phoneNumberBlockInfo: TenantPhoneNumber[] }>()
)

export const actionTenantDialogTenantPhoneNumberUnBlockFailed = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number UnBlock Failed',
)

export const actionTenantDialogTenantPhoneNumberBlockStateReset = createAction(
    '[Tenant Dialog Tenant] Tenant Dialog Tenant Phone Number Block State Reset',
)

export const actionTenantDialogGeneralStateReset = createAction(
    '[Tenant Dialog] Tenant Dialog General State Reset',
)

export const tenantDialogGeneralInfoActions = {
    actionTenantDialogByTenantIdRequired,
    actionTenantDialogByTenantIdSuccess,
    actionTenantUserGeneralProfileChangeIsBaseInfoEditMode,
    actionTenantUserGeneralProfileUpdateRequested,
    actionTenantUserGeneralProfileUpdateSuccessfully,
    actionTenantUserGeneralProfileUpdateFailed,
    actionTenantDialogUpdateTenantId,
    actionTenantDialogFailed,
    actionTenantDialogTenantPhoneNumberBlockRequested,
    actionTenantDialogTenantPhoneNumberBlockSuccessfully,
    actionTenantDialogTenantPhoneNumberBlockFailed,
    actionTenantDialogTenantPhoneNumberUnBlockRequested,
    actionTenantDialogTenantPhoneNumberUnBlockSuccessfully,
    actionTenantDialogTenantPhoneNumberUnBlockFailed,
    actionTenantDialogTenantPhoneNumberBlockStateReset,
    actionTenantDialogGeneralStateReset
}
