import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { takeUntil } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { MatProgressButtonOptions } from '../spinner-button/spinner-button.interface';

export interface ActionValidationMessage {
  errorMessage: string;
  validationMessages: string[];
}

@Component({
  selector: 'dq-action-dialog',
  templateUrl: './action-dialog-component.component.html',
  styleUrls: ['./action-dialog-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ActionDialogComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ActionDialogComponent>) {
    this.title = data.title;
    this.notificationText = data.notificationText;
    this.buttonColor = data.buttonColor;
    this.spinnerColor = data.spinnerColor;
    this.buttonText = data.buttonText;
    this.triggerAction = data.triggerAction;
    this.resetValidationMessageAction = data.resetValidationMessageAction;
    this.isActionActive$ = data.isActionActive;
    this.actionValidationMessages$ = data.actionValidationMessages
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  title: string;
  notificationText: string;
  buttonColor: string;
  buttonText: string;
  spinnerColor: string;

  isActionActive$: Observable<boolean>;
  actionValidationMessages$: Observable<ActionValidationMessage>;

  triggerAction: Action;
  resetValidationMessageAction: Action;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: '',
    spinnerSize: 18,
    flat: true,
    buttonColor: 'warn',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  ngOnInit(): void {
    this.spinnerButtonOptions.text = this.buttonText;
    this.spinnerButtonOptions.buttonColor = this.buttonColor as ThemePalette;
    this.spinnerButtonOptions.spinnerColor = this.spinnerColor as ThemePalette;

    this.isActionActive$.pipe(takeUntil(this.unsubscribe$)).subscribe(isActive => {
      this.spinnerButtonOptions.active = isActive;
      this.cdr.markForCheck();
    });
  }

  onAction() {
    if (this.spinnerButtonOptions.active == false) {
      this.store.dispatch(this.triggerAction);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.resetValidationMessageAction != undefined) {
      this.store.dispatch(this.resetValidationMessageAction);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
