<div class="dq-row header-container">
    <div class="dq-row dq-flex-aligned">
        <mat-icon class="icon-general">person</mat-icon>
        <span class="title">Assigned Representatives</span>
    </div>
    <button mat-icon-button (click)="openRepAddEditDialogEdit()">
        <mat-icon>add</mat-icon>
    </button>
</div>

<div class="dq-row items-container">
    <ng-container>
        <div class="reps-container">
            <ng-container *ngIf="(isRepsLoaded$  | async); else repsLoading">
                <ng-container *ngFor="let rep of (clientReps$ | async)">
                    <div class="rep-div-wrapper">
                        <div class="rep-div"> {{rep.name}}<mat-icon class="rep-icon"
                                (click)="deleteRep(rep.id)">clear
                            </mat-icon>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #repsLoading>
                <mat-spinner class="reps-loading-spinner"></mat-spinner>
            </ng-template>
        </div>
    </ng-container>
</div>