import { Action, createReducer, on } from '@ngrx/store';
import { ClientDialogBookingBuildingAvailabilityState } from 'app/features/client-dialog/client-dialog.state';
import { clientDialogBookingBuildingAvailabilityActions } from './client-dialog-booking-building-avaliabilty.action';

export const initialState: ClientDialogBookingBuildingAvailabilityState = {
  bookingStep: 1,
  dateFilter: new Date().toISOString(),
  isBuildingAvailabilityLoaded: false,
  isBuildingsLoaded: false,
  availabilitySelected: '',
  buildingSelected: {
    id: -999,
    name: '',
    address: '',
    style: '',
    photoUrl: '',
    activationDate: ''
  },
  buildingSelectedId: -999,
  buildings: [],
  buildingWeeklyAvailability: {
    weekStartDate: '',
    weekEndDate: '',
    weeklyScheduleDays: []
  },
  isBookingCalendarsLoaded: false,
  bookingCalendars: [],
  isScheduleOverwrite: false
}

const reducer = createReducer(
  initialState,
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingStepChange,
    (state, { bookingStep }) => ({
      ...state,
      bookingStep
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityRequested,
    (state, { buildingId }) => ({
      ...state,
      isBuildingAvailabilityLoaded: false
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityScheduleOverwriteRequested,
    (state, { buildingId }) => ({
      ...state,
      isBuildingAvailabilityLoaded: false
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityScheduleOverwriteLoaded,
    (state, { buildingAvailability }) => ({
      ...state,
      isBuildingAvailabilityLoaded: true,
      buildingWeeklyAvailability: buildingAvailability
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityLoaded,
    (state, { buildingAvailability }) => ({
      ...state,
      isBuildingAvailabilityLoaded: true,
      buildingWeeklyAvailability: buildingAvailability
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityBuildingsRequested,
    (state) => ({
      ...state,
      isBuildingsLoaded: false
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityBuildingsLoaded,
    (state, { buildings, buildingId }) => ({
      ...state,
      isBuildingsLoaded: true,
      buildings,
      buildingSelected: buildings.find(x => x.id == buildingId),
      buildingSelectedId: buildingId > 0 ? buildingId : -999
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityCalendarsRequested,
    (state) => ({
      ...state,
      isBookingCalendarsLoaded: false
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityCalendarsLoaded,
    (state, { calendars }) => ({
      ...state,
      isBookingCalendarsLoaded: true,
      bookingCalendars: calendars
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityDateFilterChange,
    (state, { date }) => ({
      ...state,
      dateFilter: date
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityTimeSlotToConfirmChange,
    (state, { timeSlot }) => ({
      ...state,
      availabilitySelected: timeSlot
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilitySelectedBuildingChange,
    (state, { building }) => ({
      ...state,
      buildingSelected: building
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilitySelectedBuildingIdChange,
    (state, { builidngId }) => ({
      ...state,
      buildingSelectedId: builidngId
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityCalendarsReset,
    (state) => ({
      ...state,
      isBookingCalendarsLoaded: false,
      bookingCalendars: []
    })
  ),
  on(
    clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingBuildingAvailabilityReset,
    (state) => (
      initialState
    )
  ),
  on(clientDialogBookingBuildingAvailabilityActions.actionClientDialogBookingEventCalendarToggleScheduleOverwrite,
    (state, { isScheduleOverwrite }) => ({
      ...state,
      isScheduleOverwrite
    })),
)

export const clientDialogBookingBuildingAvailabilityReducer = (
  state: ClientDialogBookingBuildingAvailabilityState | undefined,
  action: Action
) => {
  return reducer(state, action);
};
