import { createAction, props } from "@ngrx/store";
import { AssignedUser } from "../../../../shared/models/tenant-info-models";

export const actionTenantDialogTenantRepsRequested = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Tenant Reps Requested',
    props<{ tenantId: number }>()
)

export const actionTenantDialogTenantRepsLoaded = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Tenant Reps Loaded',
    props<{ reps: AssignedUser[] }>()
)

export const actionTenantDialogTenantRepsFailed = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Tenant Reps Failed'
)

export const actionTenantDialogRepOptionsRequested = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Rep Options Requested',
)

export const actionTenantDialogRepOptionsLoaded = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Rep Options Loaded',
    props<{ reps: AssignedUser[] }>()
)

export const actionTenantDialogRepsUpdate = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps Update ',
    props<{ reps: AssignedUser[] }>()
);

export const actionTenantDialogRepsRepIsCheckedUpdate = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps Rep Is Checked Update ',
    props<{ checked: boolean, rep: AssignedUser }>()
);

export const actionTenantDialogRepOptionsCheckStateInitialize = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Rep Options Check State Initalize',
)

export const actionTenantDialogRepOptionsFailed = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Rep Options Failed',
)

export const actionTenantDialogRepsUpdateRequested = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps Update Requested',
    props<{ id: number, reps: AssignedUser[] }>()
)

export const actionTenantDialogRepsUpdateSuccessfully = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps Update Successfully',
    props<{ tenantId: number, reps: AssignedUser[] }>()
)

export const actionTenantDialogRepsUpdateFailed = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps Update Failed',
)

export const actionTenantDialogBatchRepsAddRequested = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Batch Reps Add Requested',
    props<{ tenantIds: number[], repIds: number[] }>()
)

export const actionTenantDialogBatchRepsAddSuccessfully = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Batch Reps Add Successfully',
)

export const actionTenantDialogBatchRepsAddFailed = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Batch Reps Add Failed',
)

export const actionTenantDialogBatchRepsRemoveRequested = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Batch Reps Remove Requested',
    props<{ tenantIds: number[], repIds: number[] }>()
)

export const actionTenantDialogBatchRepsRemoveSuccessfully = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Batch Reps Remove Successfully',
)

export const actionTenantDialogBatchRepsRemoveFailed = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Batch Reps Remove Failed',
)

export const actionTenantDialogTenantRepDeleteRequested = createAction(
    '[Tenant Dialog TRepsags] Tenant Dialog Cilent Rep Delete Requested',
    props<{ tenantId: number, repId: number }>()
)

export const actionTenantDialogRepsCreateUpdateSuccessfulStateReset = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps Create Update Successfully State Reset'
)

export const actionTenantDialogTenantRepDeleteSuccessfully = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Tenant Rep Delete Successfully',
    props<{ tenantId: number, reps: AssignedUser[] }>()
)

export const actionTenantDialogTenantRepDeleteFailed = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Tenant Rep Delete Failed',
)

export const actionTenantDialogRepsReset = createAction(
    '[Tenant Dialog Reps] Tenant Dialog Reps  Reset',
)

export const tenantDialogRepActions = {
    actionTenantDialogTenantRepsRequested,
    actionTenantDialogTenantRepsLoaded,
    actionTenantDialogTenantRepsFailed,
    actionTenantDialogRepOptionsFailed,
    actionTenantDialogRepsUpdateFailed,
    actionTenantDialogTenantRepDeleteFailed,
    actionTenantDialogBatchRepsAddRequested,
    actionTenantDialogBatchRepsAddSuccessfully,
    actionTenantDialogBatchRepsAddFailed,
    actionTenantDialogBatchRepsRemoveRequested,
    actionTenantDialogBatchRepsRemoveSuccessfully,
    actionTenantDialogBatchRepsRemoveFailed,
    actionTenantDialogRepOptionsRequested,
    actionTenantDialogRepOptionsLoaded,
    actionTenantDialogRepsUpdate,
    actionTenantDialogRepsRepIsCheckedUpdate,
    actionTenantDialogRepsUpdateRequested,
    actionTenantDialogRepsUpdateSuccessfully,
    actionTenantDialogTenantRepDeleteRequested,
    actionTenantDialogTenantRepDeleteSuccessfully,
    actionTenantDialogRepOptionsCheckStateInitialize,
    actionTenantDialogRepsCreateUpdateSuccessfulStateReset,
    actionTenantDialogRepsReset,
}