import { createSelector } from "@ngrx/store";
import { ClientDialogState, ClientDialogTagsState, selectClientDialog } from "../../client-dialog.state";

export const selectClientDialogTagsState = createSelector(
    selectClientDialog,
    (state: ClientDialogState) => state.clientDialogTags
);

export const selectclientDialogTagsStateIsTagsLoaded = createSelector(
    selectClientDialogTagsState,
    (state: ClientDialogTagsState) => {
        return state.isTagsLoaded
    }
);

export const selectclientDialogTagsStateIsTagOptionssLoaded = createSelector(
    selectClientDialogTagsState,
    (state: ClientDialogTagsState) => {
        return state.isTagOptionsLoaded
    }
);

export const selectclientDialogTagsStateClientTags = createSelector(
    selectClientDialogTagsState,
    (state: ClientDialogTagsState) => {
        return state.clientTags
    }
);

export const selectclientDialogTagsStateTagOptions = createSelector(
    selectClientDialogTagsState,
    (state: ClientDialogTagsState) => {
        return state.tagOptions
    }
);

export const selectclientDialogTagsIsTagSubmitting = createSelector(
    selectClientDialogTagsState,
    (state: ClientDialogTagsState) => {
        return state.isTagSubmitting
    }
);

export const selectclientDialogTagsIsCreateUpdateSuccessfully = createSelector(
    selectClientDialogTagsState,
    (state: ClientDialogTagsState) => {
        return state.isUpdateSuccessfully
    }
);  