import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogTaskState } from "../../client-dialog.state";
import { clientDialogTaskActions, clientDialogTaskFailActions } from "./client-dialog-task.action";

const initialState: ClientDialogTaskState = {
    isTasksLoaded: false,
    tasks: [],
    taskCreateState: {
        isCreating: false,
        isCreatedSuccessfully: false,
        taskAssignUsers: []
    },
    taskEditState: {
        isEditing: false,
        isEditSuccessfully: false
    },
    taskDeleteState: {
        isDeleting: false,
        isDeletSuccessfully: false
    }
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogTaskActions.actionClientDialogTaskTasksRequested,
        (state) => ({
            ...state,
            isTasksLoaded: false
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTaskTasksLoaded,
        (state, { tasks }) => ({
            ...state,
            isTasksLoaded: true,
            tasks: tasks,
        })
    ),
    on(
        clientDialogTaskFailActions.actionClientDialogTaskTasksLoadFailed,
        (state) => ({
            ...state,
            isTasksLoaded: true
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskCreateRequested,
        (state) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                isCreating: true
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskCreateSucceed,
        (state) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                isCreating: false,
                isCreatedSuccessfully: true
            }
        })
    ),
    on(
        clientDialogTaskFailActions.actionClientDialogTasktaskCreateFailed,
        (state) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                isCreating: false,
                isCreatedSuccessfully: false
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskUpdateRequested,
        (state) => ({
            ...state,
            taskEditState: {
                ...state.taskEditState,
                isEditing: true
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskUpdateSucceed,
        (state) => ({
            ...state,
            taskEditState: {
                ...state.taskEditState,
                isEditing: false,
                isEditSuccessfully: true
            }
        })
    ),
    on(
        clientDialogTaskFailActions.actionClientDialogTasktaskUpdateFailed,
        (state) => ({
            ...state,
            taskEditState: {
                ...state.taskEditState,
                isEditing: false,
                isEditSuccessfully: false
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskDeleteRequested,
        (state) => ({
            ...state,
            taskDeleteState: {
                ...state.taskDeleteState,
                isDeleting: true
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskDeleteSucceed,
        (state) => ({
            ...state,
            taskDeleteState: {
                ...state.taskDeleteState,
                isDeleting: false,
                isDeletSuccessfully: true
            }
        })
    ),
    on(
        clientDialogTaskFailActions.actionClientDialogTasktaskDeleteFailed,
        (state) => ({
            ...state,
            taskDeleteState: {
                ...state.taskDeleteState,
                isDeleting: false,
                isDeletSuccessfully: false
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTasktaskAssignUsersLoaded,
        (state, { taskAssignUsers }) => ({
            ...state,
            taskCreateState: {
                ...state.taskCreateState,
                taskAssignUsers: taskAssignUsers
            }
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTaskTaskStatusToggle,
        clientDialogTaskFailActions.actionClientDialogTaskTaskStatusToggleFailed,
        (state, { taskId }) => ({
            ...state,
            tasks: state.tasks.map(x => x.id == taskId ? { ...x, isCompleted: !x.isCompleted } : x)
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTaskTaskCreateStateReset,
        (state) => ({
            ...state,
            taskCreateState: initialState.taskCreateState
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTaskTaskEditStateReset,
        (state) => ({
            ...state,
            taskEditState: initialState.taskEditState
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTaskTaskDeleteStateReset,
        (state) => ({
            ...state,
            taskDeleteState: initialState.taskDeleteState
        })
    ),
    on(
        clientDialogTaskActions.actionClientDialogTaskStateReset,
        (state) => initialState
    )
)

export const clientDialogTaskReducer = (
    state: ClientDialogTaskState | undefined,
    action: Action
) => {
    return reducer(state, action);
};