import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogBuildingsState } from "../../client-dialog.state";
import { clientDialogBuildingActions } from "./client-dialog-lead-building.action";

const initialState: ClientDialogBuildingsState = {
    isBuildingsLoaded: false,
    isBuildingOptionsLoaded: false,
    clientBuildings: [],
    buildingOptions: [],
    isBuildingSubmitting: false,
    isUpdateSuccessfully: false
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogBuildingActions.actionClientDialogLeadBuildingsRequested,
        (state) => ({
            ...state,
            isBuildingsLoaded: false
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogLeadBuildingsLoaded,
        (state, { buildings }) => ({
            ...state,
            isBuildingsLoaded: true,
            clientBuildings: buildings
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogLeadBuildingsFailed,
        (state) => ({
            ...state,
            isBuildingsLoaded: true
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingOptionsLoaded,
        (state, { buildings }) => ({
            ...state,
            buildingOptions: buildings,
            isBuildingOptionsLoaded: true
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingOptionsFailed,
        (state) => ({
            ...state,
            isBuildingOptionsLoaded: true
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsUpdate,
        (state, { buildings }) => ({
            ...state,
            isBuildingsLoaded: true,
            clientBuildings: buildings
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingOptionsCheckStateInitialize,
        (state) => ({
            ...state,
            buildingOptions: state.buildingOptions.map(x => state.clientBuildings.map(y => y.id).includes(x.id) ? { ...x, isChecked: true } : { ...x, isChecked: false })
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsBuildingIsCheckedUpdate,
        (state, { checked, building }) => ({
            ...state,
            buildingOptions: state.buildingOptions.map(x => x.id == building.id ? { ...x, isChecked: checked } : x)
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsUpdateRequested,
        (state) => ({
            ...state,
            isBuildingSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBatchBuildingsAddRequested,
        clientDialogBuildingActions.actionClientDialogBatchBuildingsRemoveRequested,
        (state) => ({
            ...state,
            isBuildingSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsUpdateSuccessfully,
        (state, { buildings }) => ({
            ...state,
            isBuildingSubmitting: false,
            isUpdateSuccessfully: true,
            clientBuildings: buildings
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBatchBuildingsAddSuccessfully,
        clientDialogBuildingActions.actionClientDialogBatchBuildingsRemoveSuccessfully,
        (state) => ({
            ...state,
            isBuildingSubmitting: false,
            isUpdateSuccessfully: true,
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogClientBuildingDeleteSuccessfully,
        (state, { buildings }) => ({
            ...state,
            clientBuildings: buildings
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsUpdateFailed,
        (state) => ({
            ...state,
            isBuildingSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBatchBuildingsAddFailed,
        clientDialogBuildingActions.actionClientDialogBatchBuildingsRemoveFailed,
        (state) => ({
            ...state,
            isBuildingSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsCreateUpdateSuccessfulStateReset,
        (state) => ({
            ...state,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogClientBuildingsFailed,
        (state) => ({
            ...state,
            isBuildingsLoaded: true
        })
    ),
    on(
        clientDialogBuildingActions.actionClientDialogBuildingsReset,
        (state,) => (initialState)
    ),
)

export const clientDialogBuildingsReducer = (
    state: ClientDialogBuildingsState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
