import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dqFileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, extension: string = 'MB') {
    if (size !== null && typeof size === 'number') {
        return (size / (1024 * 1024)).toFixed(2) + extension;
    }
    else {
        return 'N/A'
    }
  }
}