import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { LeadBuilding } from 'app/shared/models/lead-info-models';
import { LeadUpdateAction } from 'app/features/lead-manager/lead-manager.model';

import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { selectClientLeadId } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogBuildingsBuildingIsCheckedUpdate, actionClientDialogBatchBuildingsAddRequested, actionClientDialogBatchBuildingsRemoveRequested, actionClientDialogBuildingsUpdateRequested, actionClientDialogBuildingOptionsRequested } from '../../client-dialog-lead-building.action';
import { selectclientDialogBuildingsStateIsBuildingsLoaded, selectclientDialogBuildingsStateBuildingOptions, selectclientDialogBuildingsIsCreateUpdateSuccessfully, selectclientDialogBuildingsIsBuildingSubmitting, selectclientDialogBuildingsStateIsBuildingOptionsLoaded } from '../../client-dialog-lead-building.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-user-general-buildings',
  templateUrl: './client-dialog-user-general-buildings.component.html',
  styleUrls: ['./client-dialog-user-general-buildings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogUserGeneralBuildingsComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ClientDialogUserGeneralBuildingsComponent>,
    @Inject('dialogData') private dialogData: any,
    private cdr: ChangeDetectorRef
  ) {
    if (dialogData && dialogData.action && dialogData.leadIds) {
      this.title = dialogData.action.name
      this.action = dialogData.action
      this.leadIds = dialogData.leadIds
    }
  }

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };
  
  title: string;
  leadIds: number[] = []

  action: LeadUpdateAction

  unsubscribe: Subject<void> = new Subject();
  buildingOptions$: Observable<LeadBuilding[]>;
  buildingOptions: LeadBuilding[];
  isBuildingLoaded$: Observable<boolean>
  buildingNameControl = new UntypedFormControl();
  buildingSearchOptions$: Observable<LeadBuilding[]>
  type: string = '';
  panelOpenState = false;
  checkedBuildingList: LeadBuilding[];
  isBuildingChecked: boolean;
  name: string;
  leadId$: Observable<number>
  leadId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  isBuildingOptionsLoaded$: Observable<boolean>
  isBuildingsSubmitting$: Observable<boolean>
  isUpdateSuccessfully$: Observable<boolean>

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.store.dispatch(actionClientDialogBuildingOptionsRequested())
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.isBuildingOptionsLoaded$ = this.store.pipe(select(selectclientDialogBuildingsStateIsBuildingOptionsLoaded));
    this.isBuildingsSubmitting$ = this.store.pipe(select(selectclientDialogBuildingsIsBuildingSubmitting));
    this.isUpdateSuccessfully$ = this.store.pipe(select(selectclientDialogBuildingsIsCreateUpdateSuccessfully));
    this.buildingOptions$ = this.store.pipe(select(selectclientDialogBuildingsStateBuildingOptions));
    this.isBuildingLoaded$ = this.store.pipe(select(selectclientDialogBuildingsStateIsBuildingsLoaded));
    this.buildingOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(buildings => {
      this.buildingOptions = buildings;
      this.spinnerSaveButtonOptions.disabled = buildings.length == 0
      if (this.leadIds && this.leadIds.length > 0) {
        this.spinnerSaveButtonOptions.disabled = buildings.filter(building => building.isChecked).length == 0;
      }
      this.buildingSearchOptions$ = this.buildingNameControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.cdr.markForCheck()
    })

    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId;
    })
    this.isBuildingsSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isBuildingsSubmitting => {
      this.spinnerSaveButtonOptions.active = isBuildingsSubmitting;
      this.cdr.markForCheck()
    })
    this.isUpdateSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isUpdateSuccessfully => {
      if (isUpdateSuccessfully) {
        this.dialogRef.close()
      }
    })
  }

  private _filter(value: string): LeadBuilding[] {
    const filterValue = value.toLowerCase();
    return this.buildingOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeSelection(checked, building) {
    this.store.dispatch(actionClientDialogBuildingsBuildingIsCheckedUpdate({ checked, building }));
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    let buildings = this.buildingOptions.filter(building => building.isChecked);
    let id = this.leadId

    if (this.leadIds && this.leadIds.length > 0) {
      if (this.action.action == 'addBuildingresentatives') {
        this.store.dispatch(actionClientDialogBatchBuildingsAddRequested({ leadIds: this.leadIds, buildingIds: buildings.map(building => building.id) }))
      } else if (this.action.action == 'removeBuildingresentatives') {
        this.store.dispatch(actionClientDialogBatchBuildingsRemoveRequested({ leadIds: this.leadIds, buildingIds: buildings.map(building => building.id) }))
      }
    } else {
      this.store.dispatch(actionClientDialogBuildingsUpdateRequested({ id, buildings }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
