// only for this since we can't use import for some reason
/* eslint-disable @typescript-eslint/no-var-requires */
// tslint:disable-next-line:no-var-requires
const packageJson = require('../../../../package.json');

export const environment = {
  algolia: {
    algoliaSearchApiKey: 'db5341eabb628cf0855eb3d280d59286',
    algoliaAppId: 'IJVU6OWSHE',
    algoliaBuildingIndex: 'prod_cdk_building',
    algoliaPropertyIndex: 'prod_cdk_property',
    algoliaAreaIndex: 'prod_cdk_area'
  },
  appName: 'DashQ',
  azureCountainers: {
    baseUrl: 'blob.core.windows.net',
    geoDataStorage: 'digirealtygeostorage',
    geoDataContainer: 'areas-data'
  },
  envName: 'PROD',
  baseUrl: {
    webapp: 'https://portal.dashq.io/',
    bpBaseUrl:
      'https://dashqblobstorage.blob.core.windows.net/dq-building-photos/',
    paBaseUrl: 'https://dashqblobstorage.blob.core.windows.net/dq-rep-photos/',
    urBaseUrl:
      'https://dashqblobstorage.blob.core.windows.net/dq-user-recordings/',
    staticBaseUrl: 'https://dashqblobstorage.blob.core.windows.net/dq-static/',
    voiceBaseUrl:
      'https://dashqblobstorage.blob.core.windows.net/dq-voice-messages/'
  },
  datadog: {
    site: 'us3.datadoghq.com',
    clientToken: 'pub299f4b47490dd4adbb0d0d57a6a57e2f'
  },
  intercom: {
    appId: 'inpvko4n'
  },
  production: true,
  test: false,
  printActions: false,
  i18nPrefix: '',
  hubBaseUrl: 'https://webapi.dashq.io/hubs/',
  apiBaseUrl: 'https://webapi.dashq.io/api/',
  websiteUrl: 'https://portal.dashq.io/',
  clarity: {
    projectId: 'gxvir4u6d0',
    enabled: true
  },
  appInsights: {
    instrumentationKey: 'NA'
  },
  microsoftGraph: {
    microsoftClientId: '65019a06-5b31-4a9e-b076-3070c58c6e49'
  },
  zoom: {
    zoomClientId: 'bAbokXC4RhmIjDvEnjq9oQ'
  },
  tinyMce: {
    tinyMceApiKey: 'lxdy0yc38kf0gbnf8jhggzhxh1yozbpgrbnjtt55ry7p1cz4'
  },
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  }
};
