import { createSelector } from "@ngrx/store";
import { ClientDialogBookingBuildingAvailabilityState, ClientDialogState, selectClientDialog } from "app/features/client-dialog/client-dialog.state";

export const selectClientDialogBookingBuildingAvailabilityState = createSelector(
  selectClientDialog,
  (state: ClientDialogState) => {
    return state.clientDialogBookingBuildingAvailability
  }
);

export const selectClientDialogBookingStateBuildingAvailability = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.buildingWeeklyAvailability;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityDateFilter = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.dateFilter;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityIsLoaded = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.isBuildingAvailabilityLoaded;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityBuildingSelected = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.buildingSelected;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityBuildingSelectedId = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.buildingSelectedId;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityBuildings = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.buildings;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityIsBuildingsLoaded = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.isBuildingsLoaded;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilitySelected = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.availabilitySelected;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityBookingCalendars = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.bookingCalendars;
  }
);

export const selectClientDialogBookingStateBuildingAvailabilityIsBookingCalendarsLoaded = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.isBookingCalendarsLoaded;
  }
);

export const selectClientDialogBookingStateBookingStep = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.bookingStep;
  }
);

export const selectClientDialogIsScheduleOverwrite = createSelector(
  selectClientDialogBookingBuildingAvailabilityState,
  (state: ClientDialogBookingBuildingAvailabilityState) => {
    return state.isScheduleOverwrite;
  }
);


