import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebApiResponse } from 'app/core/response/response.model';
import { EmailSendType } from 'app/shared/models/user-communication.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientDialogEmailService {

  constructor(private http: HttpClient) { }

  createLeadEmail(leadId: number, externalIdentifier: string, emailType: EmailSendType, text: string, sendFromUserId: string, sendTo: string[], sendToCc: string[], sendToBcc: string[], subject: string, files: string[]) {

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    ///formData.append('id',id.toString())
    formData.append('sendTo', JSON.stringify(sendTo))
    formData.append('text', text)
    formData.append('leadId', leadId?.toString())
    formData.append('externalIdentifier', externalIdentifier)
    formData.append('emailType', emailType)
    formData.append('sendFromUserId', JSON.stringify(sendFromUserId))
    formData.append('sendTo', JSON.stringify(sendTo))
    formData.append('sendToCc', JSON.stringify(sendToCc))
    formData.append('sendToBcc', JSON.stringify(sendToBcc))
    formData.append('subject', subject)

    return this.http.post(`${environment.apiBaseUrl}lead-detail/sendEmail`, formData)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  createLeadEmailAndCollaborate(leadId: number, externalIdentifier: string, emailType: EmailSendType, text: string, sendFromUserId: string, sendTo: string[], sendToCc: string[], sendToBcc: string[], subject: string, files: string[]) {

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('sendTo', JSON.stringify(sendTo))
    formData.append('leadId', leadId.toString())
    formData.append('text', text)
    formData.append('externalIdentifier', externalIdentifier)
    formData.append('emailType', emailType)
    formData.append('sendFromUserId', JSON.stringify(sendFromUserId))

    formData.append('sendToCc', JSON.stringify(sendToCc))
    formData.append('sendToBcc', JSON.stringify(sendToBcc))
    formData.append('subject', subject)

    return this.http.post(`${environment.apiBaseUrl}lead-detail/sendEmailAndCollaborate`, formData)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  saveAndUpdateEmailTemplate(friendlyName: string, displayName: string, subject: string, content: string, isAccountShared: boolean, id?: number) {
    const data = {
      friendlyName: friendlyName,
      displayName: displayName,
      subject: subject,
      content: content,
      id: id ? id : 0,
      isAccountShared: isAccountShared
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail/saveUpdateEmailTemplate`, data)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  getEmailTemplates() {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getEmailTemplates`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  deleteEmailTemplate(id: number) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail/deleteCommunicationTemplate`, id)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  getInventoryPricesTemplate(buildingId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getInventoryPricesTemplate?buildingId=${buildingId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }))
  }
}
