import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { TenantInfo, TenantPhoneNumber } from "../../../../shared/models/tenant-info-models";
import { TenantDialogService } from "../../tenant-dialog.service";
import { State } from "../../tenant-dialog.state";
import { actionTenantUserGeneralProfileUpdateRequested, actionTenantUserGeneralProfileUpdateSuccessfully, actionTenantUserGeneralProfileUpdateFailed, tenantDialogGeneralInfoActions } from "./tenant-dialog-general.action";
import { IWebApiResponse } from "app/core/response/response.model";

@Injectable()
export class TenantDialogGeneralEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private tenantDialogService: TenantDialogService,
        private notificationService: NotificationService
    ) { }

    updateUserGeneralInfos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionTenantUserGeneralProfileUpdateRequested),
            switchMap(action =>
                this.tenantDialogService.updateUserGeneralProfile(action.params).pipe(
                    map((response => {
                        if (response.status == 1) {
                            let tenantInfo: TenantInfo = response.data
                            return actionTenantUserGeneralProfileUpdateSuccessfully({ tenantInfo: tenantInfo })
                        } else {
                            this.notificationService.error(response.message);
                            return actionTenantUserGeneralProfileUpdateFailed()
                        }
                    }),
                        catchError(error => {
                            return of(actionTenantUserGeneralProfileUpdateFailed())
                        })
                    )
                )
            )
        )
    );

    actionTenantDialogByTenantIdRequired = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogGeneralInfoActions.actionTenantDialogByTenantIdRequired),
            switchMap(action =>
                this.tenantDialogService
                    .getTenantDetailInfo(action.tenantId)
                    .pipe(
                        map((response: IWebApiResponse) => {
                            if (response.status == 1) {
                                return tenantDialogGeneralInfoActions.actionTenantDialogByTenantIdSuccess({
                                    tenantInfo: response.data
                                });
                            } else {
                                this.notificationService.error(response.message);
                                return tenantDialogGeneralInfoActions.actionTenantDialogFailed();
                            }
                        }),
                        catchError(error =>
                            of(tenantDialogGeneralInfoActions.actionTenantDialogFailed())
                        )
                    )
            )
        )
    );


    tenantPhoneNumberBlock = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockRequested),
            switchMap(action =>
                this.tenantDialogService.phoneNumberBlock(action.tenantId, action.phoneNumber, action.reason).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let phoneNumberBlockInfo: TenantPhoneNumber[] = response.data
                            return tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockSuccessfully({ phoneNumberBlockInfo: phoneNumberBlockInfo ? phoneNumberBlockInfo : [] })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberBlockFailed())
                    })
                )
            )
        )
    );

    tenantPhoneNumberUnBlock = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockRequested),
            switchMap(action =>
                this.tenantDialogService.phoneNumberUnBlock(action.tenantId, action.phoneNumber).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let phoneNumberBlockInfo: TenantPhoneNumber[] = response.data
                            return tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockSuccessfully({ phoneNumberBlockInfo: phoneNumberBlockInfo ? phoneNumberBlockInfo : [] })
                        } else {
                            this.notificationService.error(response.message);
                            return tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockFailed()
                        }
                    }),
                    catchError(error => {
                        return of(tenantDialogGeneralInfoActions.actionTenantDialogTenantPhoneNumberUnBlockFailed())
                    })
                )
            )
        )
    );

}
