<div class="tenant-dialog-reps-component">
    <mat-card>
        <div class="container">
            <div class="row">
                <div>
                    <mat-icon class="icon-general">person</mat-icon>
                </div>
                <div>
                    <h4 class="title">Assigned Representatives</h4>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div class="row items-container">
                <ng-container>
                    <div class="reps-container">
                        <ng-container *ngIf="(isRepsLoaded$  | async); else repsLoading">
                            <ng-container *ngFor="let rep of (tenantReps$ | async)">
                                <div class="rep-div-wrapper">
                                    <div class="rep-div"> {{rep.name}}<mat-icon class="rep-icon"
                                            (click)="deleteRep(rep.id)">clear
                                        </mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                            <button (click)="openRepAddEditDialogEdit()" mat-mini-fab class="add-rep-button">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #repsLoading>
                            <mat-spinner class="reps-loading-spinner"></mat-spinner>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>
</div>