import { createSelector } from "@ngrx/store";
import { selectBookingState, BookingState, BookingEventCalendarState, } from "../../booking.state";

export const selectBookingEventCalendarState = createSelector(
  selectBookingState,
  (state: BookingState) => {
    return state.eventCalendar;
  }
);

export const selectBookingCalendarLeadPreferences = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.leadPreferences;
  }
);

export const selectBookingCalendarSelectedId = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.calendarIdSelected;
  }
);

export const selectBookingEventIsScheduleOverwrite = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.isScheduleOverwrite;
  }
);

export const selectBookingEventCalendarStateIsloaded = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.isLoaded;
  }
);

export const selectBookingEventCalendarStateRepresentative = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.representative;
  }
);

export const selectBookingEventCalendarStateBuildingAddress = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.buildingAddress;
  }
);

export const selectBookingEventCalendarStateBuildingId = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.buildingId;
  }
);
export const selectBookingEventCalendarStateBuildingProperties = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.buildingProperties;
  }
);

export const selectBookingEventCalendarStateEvent = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.event;
  }
);

export const selectBookingEventCalendarLeadSources = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.leadSources;
  }
);

export const selectBookingEventCalendarIsFormActive = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.isFormActive;
  }
);

export const selectBookingEventCalendarMinDate = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.minCalendarDate;
  }
);

export const selectBookingEventCalendarStateTimeSlots = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.timeSlots;
  }
);

export const selectBookingEventCalendarStateDate = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.date;
  }
);

export const selectBookingEventCalendarStateTimeSlot = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.timeSlot;
  }
);

export const selectBookingEventCalendarStateTimeIdToConfirm = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.timeToConfirmId;
  }
);

export const selectBookingEventCalendarStateCalendarType = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.event?.representativeCalendarType?.type;
  }
);

export const selectBookingEventCalendarStateTimeSlotsLoaded = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.timeSlotsLoaded;
  }
);

export const selectBookingEventCalendarStateIsSubmiting = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.isSubmitting;
  }
);

export const selectBookingEventCalendarStateTimeZoneOptions = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.timeZoneOptions;
  }
);

export const selectBookingEventCalendarStateTimeZone = createSelector(
  selectBookingEventCalendarState,
  (state: BookingEventCalendarState) => {
    return state.timeZone;
  }
);
