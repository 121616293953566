import { createSelector } from "@ngrx/store";
import { TenantDialogState, TenantDialogTagsState, selectTenantDialog } from "../../tenant-dialog.state";

export const selectTenantDialogTagsState = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogTags
);

export const selectTenantDialogTagsStateIsTagsLoaded = createSelector(
    selectTenantDialogTagsState,
    (state: TenantDialogTagsState) => {
        return state.isTagsLoaded
    }
);

export const selectTenantDialogTagsStateIsTagOptionssLoaded = createSelector(
    selectTenantDialogTagsState,
    (state: TenantDialogTagsState) => {
        return state.isTagOptionsLoaded
    }
);

export const selectTenantDialogTagsStateTenantTags = createSelector(
    selectTenantDialogTagsState,
    (state: TenantDialogTagsState) => {
        return state.tenantTags
    }
);

export const selectTenantDialogTagsStateTagOptions = createSelector(
    selectTenantDialogTagsState,
    (state: TenantDialogTagsState) => {
        return state.tagOptions
    }
);

export const selectTenantDialogTagsIsTagSubmitting = createSelector(
    selectTenantDialogTagsState,
    (state: TenantDialogTagsState) => {
        return state.isTagSubmitting
    }
);

export const selectTenantDialogTagsIsCreateUpdateSuccessfully = createSelector(
    selectTenantDialogTagsState,
    (state: TenantDialogTagsState) => {
        return state.isUpdateSuccessfully
    }
);  