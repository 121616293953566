import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digiTextSub'
})
export class DigiTextSubPipe implements PipeTransform {
  transform(value: string, maxLength: number, minLength?: number): any {
    if(!value){
      return
    }
    if(minLength && value.length < minLength){
      return value.padEnd(minLength, ' ')
    }
    if(value.length < maxLength){
      return value
    }
    return value.substr(0,maxLength) + '...'
  }
}
