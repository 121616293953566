export interface UIState {
  hasFooter: boolean;
  width: number;
}

export const UI_SIZES = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};
