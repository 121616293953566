<div class="action-div">
    {{notificationText}}
    <div class="tiny-mce-div">
        <editor class="tiny-mce-editor" #tinyMce [apiKey]="tinyMceApiKey" [init]="editorSettings" [formControl]="note"></editor>
    </div>
    <ng-container *ngIf="actionValidationMessages$ | async as actionValidationMessages">
        <ng-container
            *ngIf="actionValidationMessages.validationMessages?.length > 0 || actionValidationMessages.errorMessage != ''">
            <div class="validation-message-container">
                <div class="message-title">{{actionValidationMessages.errorMessage}}</div>
                <div *ngFor="let message of actionValidationMessages.validationMessages" class="validation-message">
                    {{message}}</div>
            </div>
        </ng-container>
    </ng-container>
    <div class="dq-row">
        <div class="col-12 button-div">
            <dq-spinner-button class="generic-button confirm-button" type="button" (click)="onAction()"
                [options]="spinnerButtonOptions"></dq-spinner-button>
        </div>
    </div>
</div>


