import { createReducer, on } from "@ngrx/store";
import { clientDialogActions } from "../../client-dialog.actions";
import { ClientDialogEmailEditorState } from "../../client-dialog.state";
import { actionClientDialogEmailEditorActions } from "./client-dialog-email-editor.action";
import { EmailSendType } from "app/shared/models/user-communication.model";

const initialSate: ClientDialogEmailEditorState = {
    isEmailSubmitting: false,
    isEmailSubmittedSuccessfully: false,
    emailSendType: EmailSendType.SEND,
    emailSendingExternalIdentifier: '',
    leadUserEmails: [],
    emailEditor: {
        email: '',
        subject: '',
        sendToBccs: [],
        sendToCcs: [],
        attachements: [],
        sendFromUserId: 0,
        sendToEmails: []
    },
    emailReplyEditor: {
        email: '',
        subject: '',
        sendToBccs: [],
        sendToCcs: [],
    },
    isForwarding: false,
    replayAllTo: [],
    isCcAndBccActive: false,
    emailTemplates: [],
    isTemplatesLoaded: false,
    isTemplateSaveOrUpdateing: false,
    isTemplateSaveOrUpdateSuccessfully: false,
    isTemplateDeletedSuccessfully: false,
    isTemplateDeleting: false
}

const reducer = createReducer(
    initialSate,
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailEditorStateReset,
        (state) => initialSate
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailRequest,
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailAndCollaborateRequest,
        (state) => ({
            ...state,
            isEmailSubmitting: true,
            isEmailSubmittedSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailSuccessed,
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailAndCollaborateSuccessed,
        (state) => ({
            ...state,
            isEmailSubmitting: false,
            isEmailSubmittedSuccessfully: true
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailFailed,
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailAndCollaborateFailed,
        (state) => ({
            ...state,
            isEmailSubmitting: false,
            isEmailSubmittedSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailReplyEditorIsForwardingUpdate,
        (state, { isForwarding }) => ({
            ...state,
            isForwarding: isForwarding
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailReplyEditorReplyAllToUpdate,
        (state, { replyAllTo }) => ({
            ...state,
            replayAllTo: replyAllTo
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailEditorIsCcAndBccActiveUpdate,
        (state, { isActive }) => ({
            ...state,
            isCcAndBccActive: isActive
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailSendingMailTypeAndExternalIdentifierUpdate,
        (state, { sendType, externalIdentifier }) => ({
            ...state,
            emailSendType: sendType,
            emailSendingExternalIdentifier: externalIdentifier
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailEditorReset,
        (state) => ({
            ...state,
            emailEditor: initialSate.emailEditor
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailReplyEditorReset,
        (state) => ({
            ...state,
            emailReplyEditor: initialSate.emailReplyEditor
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailEditorUpdate,
        (state, { email, sendFromUserId, sendToEmails, sendToCcs, sendToBccs, subject, attachements }) => ({
            ...state,
            emailEditor: {
                ...state.emailEditor,
                email: email,
                sendFromUserId: sendFromUserId,
                sendToEmails: sendToEmails,
                sendToCcs: sendToCcs,
                sendToBccs: sendToBccs,
                subject: subject,
                attachements: attachements
            }
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogSendEmailReplyEditorUpdate,
        (state, { email, sendToCcs, sendToBccs, subject }) => ({
            ...state,
            emailReplyEditor: {
                ...state.emailReplyEditor,
                email: email,
                sendToCcs: sendToCcs,
                sendToBccs: sendToBccs,
                subject: subject,
            }
        })
    ),
    on(
        clientDialogActions.actionClientDialogCommunicationSettingsSucceded,
        (state, { emails, phoneNumbers, userId }) => ({
            ...state,
            leadUserEmails: emails,
            emailEditor: {
                ...state.emailEditor,
                sendFromUserId: emails.find(x => x.userId == userId) != null ? userId : emails[0]?.userId
            }
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateSaveAndUpdate,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: true,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateSaveAndUpdateSucceeded,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: true
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateSaveAndUpdateFailed,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateSaveAndUpdateStateReset,
        (state) => ({
            ...state,
            isTemplateSaveOrUpdateing: false,
            isTemplateSaveOrUpdateSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateDeleteRequest,
        (state) => ({
            ...state,
            isTemplateDeleting: true,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateDeleteSucceeded,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: true
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateDeleteFailed,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplateDeleteStateReset,
        (state) => ({
            ...state,
            isTemplateDeleting: false,
            isTemplateDeletedSuccessfully: false
        })
    ),
    on(
        actionClientDialogEmailEditorActions.actionClientDialogEmailTemplatesLoaded,
        (state, { templates }) => ({
            ...state,
            emailTemplates: templates,
            isTemplatesLoaded: true
        })
    ),
)

export const clientDialogEmailEditorReducer = (state: ClientDialogEmailEditorState | undefined, action) => reducer(state, action)