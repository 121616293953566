import { createAction, props } from "@ngrx/store";
import { LeadPreference } from "app/shared/models/lead-preference.model";

export const actionClientDialogLeadPreferenceRequested = createAction(
    '[Client Dialog Preference] Client Dialog Lead Preference Requested',
    props<{ leadId: number }>()
)

export const actionClientDialogLeadPreferenceLoaded = createAction(
    '[Client Dialog Preference] Client Dialog Lead Preference Loaded',
    props<{ preferences: LeadPreference[] }>()
)

export const actionClientDialogLeadPreferenceFailed = createAction(
    '[Client Dialog Preference] Client Dialog Lead Preference Failed'
)

export const actionClientDialogPreferenceOptionsRequested = createAction(
    '[Client Dialog Preference] Client Dialog Preference Options Requested',
)

export const actionClientDialogPreferenceOptionsLoaded = createAction(
    '[Client Dialog Preference] Client Dialog Preference Options Loaded',
    props<{ preferences: LeadPreference[] }>()
)

export const actionClientDialogPreferenceUpdate = createAction(
    '[Client Dialog Preference] Client Dialog Preference Update ',
    props<{ preferences: LeadPreference[] }>()
);

export const actionClientDialogPreferencePreferenceIsCheckedUpdate = createAction(
    '[Client Dialog Preference] Client Dialog Preference Preference Is Checked Update ',
    props<{ checked: boolean, preference: LeadPreference }>()
);

export const actionClientDialogPreferenceOptionsCheckStateInitialize = createAction(
    '[Client Dialog Preference] Client Dialog Preference Options Check State Initalize',
)

export const actionClientDialogClientPreferenceFailed = createAction(
    '[Client Dialog Preference] Client Dialog Preference Failed',
)

export const actionClientDialogPreferenceOptionsFailed = createAction(
    '[Client Dialog Preference] Client Dialog Preference Options Failed',
)

export const actionClientDialogPreferenceUpdateRequested = createAction(
    '[Client Dialog Preference] Client Dialog Preference Update Requested',
    props<{ id: number, preferences: LeadPreference[] }>()
)

export const actionClientDialogPreferenceUpdateSuccessfully = createAction(
    '[Client Dialog Preference] Client Dialog Preference Update Successfully',
    props<{ preferences: LeadPreference[] }>()
)

export const actionClientDialogPreferenceUpdateFailed = createAction(
    '[Client Dialog Preference] Client Dialog Preference Update Failed',
)

export const actionClientDialogBatchPreferenceAddRequested = createAction(
    '[Client Dialog Preference] Client Dialog Batch Preference Add Requested',
    props<{ leadIds: number[], preferenceValues: string[] }>()
)

export const actionClientDialogBatchPreferenceAddSuccessfully = createAction(
    '[Client Dialog Preference] Client Dialog Batch Preference Add Successfully',
)

export const actionClientDialogBatchPreferenceAddFailed = createAction(
    '[Client Dialog Preference] Client Dialog Batch Preference Add Failed',
)

export const actionClientDialogBatchPreferenceRemoveRequested = createAction(
    '[Client Dialog Preference] Client Dialog Batch Remove Add Requested',
    props<{ leadIds: number[], preferenceValues: string[] }>()
)

export const actionClientDialogBatchPreferenceRemoveSuccessfully = createAction(
    '[Client Dialog Preference] Client Dialog Batch Preference Remove Successfully',
)

export const actionClientDialogBatchPreferenceRemoveFailed = createAction(
    '[Client Dialog Preference] Client Dialog Batch Preference Remove Failed',
)

export const actionClientDialogClientPreferenceDeleteRequested = createAction(
    '[Client Dialog Preference] Client Dialog Cilent Preference Delete Requested',
    props<{ leadId: number, preferenceValue: string }>()
)

export const actionClientDialogClientPreferenceDeleteSuccessfully = createAction(
    '[Client Dialog Preference] Client Dialog Client Preference Delete Successfully',
    props<{ preferences: LeadPreference[] }>()
)

export const actionClientDialogClientPreferenceDeleteFailed = createAction(
    '[Client Dialog Preference] Client Dialog Client Preference Delete Failed',
)

export const actionClientDialogPreferenceCreateUpdateSuccessfulStateReset = createAction(
    '[Client Dialog Preference] Client Dialog Preference Create Update Successfully State Reset'
)

export const actionClientDialogPreferenceReset = createAction(
    '[Client Dialog Preference] Client Dialog Preference  Reset',
)


export const clientDialogPreferenceActions = {
    actionClientDialogLeadPreferenceRequested,
    actionClientDialogLeadPreferenceLoaded,
    actionClientDialogLeadPreferenceFailed,
    actionClientDialogPreferenceOptionsRequested,
    actionClientDialogPreferenceOptionsLoaded,
    actionClientDialogPreferenceUpdate,
    actionClientDialogPreferencePreferenceIsCheckedUpdate,
    actionClientDialogPreferenceUpdateRequested,
    actionClientDialogPreferenceUpdateSuccessfully,
    actionClientDialogClientPreferenceDeleteRequested,
    actionClientDialogClientPreferenceDeleteSuccessfully,
    actionClientDialogPreferenceOptionsCheckStateInitialize,
    actionClientDialogPreferenceOptionsFailed,
    actionClientDialogClientPreferenceFailed,
    actionClientDialogPreferenceUpdateFailed,
    actionClientDialogClientPreferenceDeleteFailed,
    actionClientDialogBatchPreferenceAddRequested,
    actionClientDialogBatchPreferenceAddSuccessfully,
    actionClientDialogBatchPreferenceAddFailed,
    actionClientDialogBatchPreferenceRemoveRequested,
    actionClientDialogBatchPreferenceRemoveSuccessfully,
    actionClientDialogBatchPreferenceRemoveFailed,
    actionClientDialogPreferenceCreateUpdateSuccessfulStateReset,
    actionClientDialogPreferenceReset,
}
