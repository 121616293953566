import { createReducer, on } from "@ngrx/store";
import { tenantDialogActions } from "./tenant-dialog.actions";
import { TenantDialogBaseState } from "./tenant-dialog.state";

const initialState: TenantDialogBaseState = {
    activeTab: 1
}

const reducer = createReducer(
    initialState,
    on(
        tenantDialogActions.actionTenantDialogTopNavigationActiveTabChange,
        (state, { tab }) => ({
            ...state,
            activeTab: tab
        })
    ),
    on(
        tenantDialogActions.actionTenantDialogBaseStateReset,
        (state) => initialState
    ),
)

export const tenantDialogBaseReducer = (state: TenantDialogBaseState | undefined, action) => reducer(state, action)