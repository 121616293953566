import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { RentalDocument } from 'app/features/client-dialog/client-dialog-model';
import { selectClientDialogIsClientRentalApplicationFormEditorDocument, selectClientDialogIsClientRentalApplicationFormEditorLoading, selectClientDialogRentalApplicationIsFormEditorSaving } from '../../client-dialog-rental-application.selector';
import { actionClientDialogApplicationRentalFormEditorSaveRequested, actionClientDialogApplicationRentalRequestFormEditorSaveRequested } from '../../client-dialog-rental-application.action';
import { MatProgressButtonOptions } from 'app/shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-doc-editor',
  templateUrl: './client-dialog-rental-application-doc-editor.component.html',
  styleUrls: ['./client-dialog-rental-application-doc-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationDocEditorComponent implements OnInit, OnDestroy {

  constructor(private store: Store<State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ClientDialogRentalApplicationDocEditorComponent>,
    private cdr: ChangeDetectorRef) {
    this.requestAction = data.requestAction as Action;
    this.saveActionType = data.saveActionType as any;
    this.resetAction = data.resetAction as Action;
    this.formId = data.formId as number;
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  rentalApplicationFormId: number;

  isFormEditorLoading$: Observable<boolean>;
  
  document$: Observable<RentalDocument>;
  document: RentalDocument;

  requestAction: Action;
  saveActionType: any;
  resetAction: Action;
  formId: number;

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    flat: true, 
    spinnerSize: 18,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  formData: {
    [fieldName: string]: string | string[] | number | boolean;
  } = {};

  ngOnInit(): void {

    this.store.select(selectClientDialogRentalApplicationIsFormEditorSaving).pipe(takeUntil(this.unsubscribe$)).subscribe(isSaving => {
      this.spinnerSaveButtonOptions.active = isSaving;
      this.cdr.markForCheck();
    });

    this.isFormEditorLoading$ = this.store.select(selectClientDialogIsClientRentalApplicationFormEditorLoading);
    this.document$ = this.store.select(selectClientDialogIsClientRentalApplicationFormEditorDocument);
    this.document$.pipe(takeUntil(this.unsubscribe$)).subscribe(document => {
      this.document = document;
      if (this.document.data !== '') {
        this.formData = JSON.parse(document.data);
        this.formData = { ...this.formData };
        this.shallowLoopWithSignatureProperties(this.formData);
      }
    })
    this.store.dispatch(this.requestAction);
  }

  saveChanges() {
    if (!this.spinnerSaveButtonOptions.active) {
      if (this.saveActionType == actionClientDialogApplicationRentalFormEditorSaveRequested.type) {
        this.store.dispatch(actionClientDialogApplicationRentalFormEditorSaveRequested({ formId: this.formId, data: JSON.stringify(this.formData) }));
      }
      else if (this.saveActionType == actionClientDialogApplicationRentalRequestFormEditorSaveRequested.type) {
        this.store.dispatch(actionClientDialogApplicationRentalRequestFormEditorSaveRequested({ formId: this.formId, data: JSON.stringify(this.formData) }));
      }
    }
  }

  onFormDataChange(formData: any) {
    this.formData = formData;
  }

  shallowLoopWithSignatureProperties(obj: any): void {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key.includes('_Signature')) {
        obj[key] = ''
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(this.resetAction);
  }
}
