import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Task, TaskType, TASK_TYPES } from 'app/shared/models/task.model';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { selectClientLeadId } from '../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogTaskStateReset, actionClientDialogTaskTasksRequested, clientDialogTaskActions } from '../client-dialog-task.action';
import { selectClientDialogIsTasksLoaded, selectClientDialogTasks } from '../client-dialog-task.selector';
import { ClientDialogTaskCreateDialogComponent } from '../components/client-dialog-task-create-dialog/client-dialog-task-create-dialog.component';
import { ClientDialogTaskEditDialogComponent } from '../components/client-dialog-task-edit-dialog/client-dialog-task-edit-dialog.component';
import { MultiPurposeDialogComponent } from 'app/shared/components/multi-purpose-dialog/multi-purpose-dialog.component';

@Component({
  selector: 'dq-client-dialog-task',
  templateUrl: './client-dialog-task.component.html',
  styleUrls: ['./client-dialog-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogTaskComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) { }

  unsubscribe: Subject<void> = new Subject();

  leadId$: Observable<number>
  leadId: number
  tasks$: Observable<Task[]>
  isTasksLoaded$: Observable<boolean>

  taskTypes: TaskType[] = TASK_TYPES

  taskCreateDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  taskEditDialogRef: MatDialogRef<MultiPurposeDialogComponent>;

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      if (leadId > 0) {
        this.leadId = leadId
        this.store.dispatch(actionClientDialogTaskTasksRequested({ leadId }))
      }
    })

    this.tasks$ = this.store.pipe(select(selectClientDialogTasks))
    this.isTasksLoaded$ = this.store.pipe(select(selectClientDialogIsTasksLoaded))
  }

  getTaskTypeInfo(typeValue: string): TaskType {
    const taskType = this.taskTypes.find(type => type.value == typeValue)
    return taskType ? taskType : {
      id: 0,
      name: 'unknown',
      value: 'unknown',
      icon: 'help',
      color: 'black'
    }
  }

  openCreateTaskDialog() {
    this.store.pipe(select(selectScreenManagerIsMobileSize)).pipe(take(1)).subscribe(isMobile => {
      this.taskCreateDialogRef = this.dialog.open(
        MultiPurposeDialogComponent,
        {
          width: isMobile ? '100vw' : '675px',
          height: isMobile ? '100dvh' : '',
          maxWidth: '100vw',
          autoFocus: true,
          panelClass: 'no-padding-dialog-container',
          disableClose: false,
          data: {
            title: 'User Schedule',
            component: ClientDialogTaskCreateDialogComponent,
            dialogData: {
              leadId: this.leadId,
            }
          }
        }
      )
    })
  }

  openEditTaskDialog(task: Task) {
    this.store.pipe(select(selectScreenManagerIsMobileSize)).pipe(take(1)).subscribe(isMobile => {
      this.taskEditDialogRef = this.dialog.open(
        MultiPurposeDialogComponent,
        {
          width: isMobile ? '100vw' : '675px',
          height: isMobile ? '100dvh' : '',
          maxWidth: '100vw',
          autoFocus: true,
          panelClass: 'no-padding-dialog-container',
          disableClose: false,
          data: {
            title: 'User Schedule',
            component: ClientDialogTaskEditDialogComponent,
            dialogData: {
              task: task,
            }
          }
        }
      )
    })
  }

  taskStatusToggle(taskId: number) {
    this.store.dispatch(clientDialogTaskActions.actionClientDialogTaskTaskStatusToggle({ taskId }))
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(actionClientDialogTaskStateReset())
  }

}
