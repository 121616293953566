export enum LeadPreferenceTypes {
    BED = 'bed',
    BATH = 'bath',
    PARKING = 'parking',
    PET = 'pet',
    BUDGET = 'budget',
    MOVE_IN_DATE = 'moveInDate',
    LANGUAGE = 'language',
    ACCESSIBILITY = 'accessibility',
}

export interface LeadPreference {
    type: LeadPreferenceTypes.BED | LeadPreferenceTypes.BATH | LeadPreferenceTypes.BUDGET | LeadPreferenceTypes.PARKING | LeadPreferenceTypes.MOVE_IN_DATE | LeadPreferenceTypes.PET | LeadPreferenceTypes.ACCESSIBILITY | LeadPreferenceTypes.LANGUAGE;
    value: string;
    label: string;
    leadId?: number;
    isChecked?: boolean;
}

export const LEAD_PREFERENCE_BED_OPTIONS: LeadPreference[] = [
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed0',
        label: 'Studio'
    },
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed1',
        label: '1 Bed'
    },
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed1-den',
        label: '1 Bed + Den'
    },
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed2',
        label: '2 Beds'
    },
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed2-den',
        label: '2 Beds + Den'
    },
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed3',
        label: '3 Beds'
    },
    {
        type: LeadPreferenceTypes.BED,
        value: 'bed4',
        label: '4+ Beds'
    }
]


export const LEAD_PREFERENCE_BATH_OPTIONS: LeadPreference[] = [
    {
        type: LeadPreferenceTypes.BATH,
        value: 'bath1',
        label: '1 Bath'
    },
    {
        type: LeadPreferenceTypes.BATH,
        value: 'bath2',
        label: '2 Baths'
    },
    {
        type: LeadPreferenceTypes.BATH,
        value: 'bath3',
        label: '3 Baths'
    }
]

export const LEAD_PREFERENCE_PET_OPTIONS: LeadPreference[] = [
    {
        type: LeadPreferenceTypes.PET,
        value: 'no-pet',
        label: 'No Pets'
    },
    {
        type: LeadPreferenceTypes.PET,
        value: 'cat',
        label: 'Cat'
    },
    {
        type: LeadPreferenceTypes.PET,
        value: 'dog-less-25lbs',
        label: 'Dog < 25lbs'
    },
    {
        type: LeadPreferenceTypes.PET,
        value: 'dog-more-25lbs',
        label: 'Dog > 25lbs'
    },
    {
        type: LeadPreferenceTypes.PET,
        value: 'pets n/a',
        label: 'N/A'
    }
]

export const LEAD_PREFERENCE_PARKING_OPTIONS: LeadPreference[] = [
    {
        type: LeadPreferenceTypes.PARKING,
        value: 'parking0',
        label: 'No parking'
    },
    {
        type: LeadPreferenceTypes.PARKING,
        value: 'parking1',
        label: '1 Parking Spot'
    },
    {
        type: LeadPreferenceTypes.PARKING,
        value: 'parking2',
        label: '2+ Parking Spots'
    },
    {
        type: LeadPreferenceTypes.PARKING,
        value: 'parking n/a',
        label: 'N/A'
    }
]

export const LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS: LeadPreference[] = [
    {
        type: LeadPreferenceTypes.ACCESSIBILITY,
        value: 'yes',
        label: 'Yes'
    },
    {
        type: LeadPreferenceTypes.ACCESSIBILITY,
        value: 'no',
        label: 'No'
    },
    {
        type: LeadPreferenceTypes.ACCESSIBILITY,
        value: 'accessibility n/a',
        label: 'N/A'
    }
]

export const LEAD_PREFERENCE_LANGUAGE_OPTIONS: LeadPreference[] = [
    {
        type: LeadPreferenceTypes.LANGUAGE,
        value: 'en',
        label: 'English'
    },
    {
        type: LeadPreferenceTypes.LANGUAGE,
        value: 'fr',
        label: 'French'
    },
    {
        type: LeadPreferenceTypes.LANGUAGE,
        value: 'other',
        label: 'Other'
    }
]
