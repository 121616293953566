import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dq-on-going-dialog-pad',
  templateUrl: './on-going-dialog-pad.component.html',
  styleUrls: ['./on-going-dialog-pad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OnGoingDialogPadComponent implements OnInit {



  @Output()
  pressKey = new EventEmitter<string>();

  ngOnInit(): void {
  }

  pressKeyValue(key: string) {
    this.pressKey.emit(key);
  }
}
