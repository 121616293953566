import { createAction, props } from '@ngrx/store';
import { WindowScreenInfo } from './screen-manager.state';

export const actionScreenManagerUpdateWindowSize = createAction(
  '[Screen Manager] Update window size',
  props<{ windowScreenInfo: WindowScreenInfo }>()
);

export const actionScreenManagerUpdateMobileScreen = createAction(
  '[Screen Manager] Update mobile Screen',
  props<{ isMobile: boolean }>()
);

export const actionScreenManagerUpdateMobileType = createAction(
  '[Screen Manager] Update mobile type',
  props<{ isMobile: boolean }>()
);

