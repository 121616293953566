import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogClientRentalRequestState } from "app/features/client-dialog/client-dialog.state";
import { clientDialogRentalRequestActions, clientDialogRentalRequestFailedActions } from "./client-dialog-rental-request.action";

export const initialState: ClientDialogClientRentalRequestState = {
    isRentalRequestLoaded: false,
    isRentalRequestdeleting: false,
    isRentalRequestSending: false,
    rentalParkingItems: [],
    rentalStorageItems: [],
    leasingReps: []
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogRentalRequestActions.actionClientDialogApplicationRentalRequestRequested,
        (state) => ({
            ...state,
            isRentalRequestLoaded: false
        })
    ),
    on(
        clientDialogRentalRequestActions.actionClientDialogApplicationRentalRequestSuccess,
        (state, { rentalRequest }) => ({
            ...state,
            isRentalRequestLoaded: true,
            rentalRequest: rentalRequest
        })
    ),
    on(
        clientDialogRentalRequestActions.actionClientDialogApplicationRentalItemsSuccess,
        (state, { rentalItems }) => ({
            ...state,
            isRentalRequestLoaded: true,
            rentalParkingItems: rentalItems.filter(x => x.type == 'Parking'),
            rentalStorageItems: rentalItems.filter(x => x.type == 'Storage')
        })
    ),
    on(
        clientDialogRentalRequestActions.actionClientDialogApplicationLeasingRepSuccess,
        (state, { leasingReps }) => ({
            ...state,
            isRentalRequestLoaded: true,
            leasingReps: leasingReps
        })
    ),
    on(
        clientDialogRentalRequestActions.actionClientDialogApplicationInitRentalRequestRequested,
        (state) => ({
            ...state,
            isRentalRequestSending: true
        })
    ),
    on(
        clientDialogRentalRequestActions.actionClientDialogApplicationInitRentalRequestSuccess,
        (state) => ({
            ...state,
            isRentalRequestSending: false
        })
    ),    on(
        clientDialogRentalRequestFailedActions.actionClientDialogApplicationInitRentalRequestFailed,
        (state) => ({
            ...state,
            isRentalRequestSending: false
        })
    )
)

export const clientDialogClientRentalRequestReducer = (
    state: ClientDialogClientRentalRequestState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
