import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import {  ClientAppointment, ILeadToMerge, LeadBuilding, LeadInfo, LeadSource, AssignedUser, Tag } from '../../shared/models/lead-info-models';
import { ITimelineElement } from 'app/shared/components/timeline/timeline.model';
import { clientDialogGeneralInfoReducer } from './components/client-dialog-user-general/client-dialog-general.reducer';
import { clientDialogActivityReducer } from './components/client-dialog-activities/client-dialog-activities.reducer';
import { clientDialogLeadNoteReducer } from './components/client-dialog-lead-note/client-dialog-lead-note.reducer';
import { clientDialogBaseReducer } from './client-dialog.reducer';
import { clientDialogTaskReducer } from './components/client-dialog-task/client-dialog-task.reducer';
import { Task, TaskAssignUser } from 'app/shared/models/task.model';
import { clientDialogTagsReducer } from './components/client-dialog-lead-tag/client-dialog-lead-tag.reducer';
import { clientDialogRepsReducer } from './components/client-dialog-lead-rep/client-dialog-lead-rep.reducer';
import { clientDialogBuildingsReducer } from './components/client-dialog-lead-building/client-dialog-lead-building.reducer';
import { clientDialogPreferencesReducer } from './components/client-dialog-lead-preference/client-dialog-lead-preference.reducer';
import { LeadPreference } from 'app/shared/models/lead-preference.model';
import { IPagination } from 'app/shared/models/pagination.model';
import { BookingEvent, BookingLeadSource, BookingTimeSlot, BuildingProperty, BuildingWeeklyAvailability, IBuilding, InventoryQeurytFilter, IProperty, IUnit, RepresentativeThumbnail, UnitFilterOptions } from './components/client-dialog-inventory/client-dialog-inventory.model';
import { clientDialogInventoryReducer } from './components/client-dialog-inventory/client-dialog-inventory.reducer';
import { clientDialogBookingBuildingAvailabilityReducer } from './components/client-dialog-inventory/components/client-dialog-inventory-booking/client-dialog-booking-building-avaliabilty.reducer';
import { TimeZone } from 'app/shared/models/timezone.model';
import { clientDialogBookingRepresentativeEventCalendarReducer } from './components/client-dialog-inventory/components/client-dialog-booking-calendar/client-dialog-booking-calendar.reducer';
import { clientDialogClientAppointmentReducer } from './components/client-dialog-appointment/client-dialog-appointment.reducer';
import { EmailSendType, UserEmail, UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { clientDialogEmailEditorReducer } from './components/client-dialog-email-editor/client-dialog-email-editor.reducer';
import { clientDialogTextMessageReducer } from './components/client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message.reducer';
import { LeasingRep, RentalApplication, RentalDocument, RentalFormTemplate, RentalApplicationListItem, RentalItem, RentalRequest, RentalRequestListItem, RentalApplicationAddNew, RentalRequestAcitivity } from './client-dialog-model';
import { DynamicForm } from 'app/shared/models/dynamic-form.mode';
import { clientDialogClientRentalRequestReducer } from './components/client-dialog-rental-request/client-dialog-rental-request.reducer';
import { clientDialogClientRentalApplicationReducer } from './components/client-dialog-rental-application/client-dialog-rental-application.reducer';
import { ActionValidationMessage } from 'app/shared/components/action-dialog/action-dialog-component.component';
import { BookingCalendar } from '../../shared/models/booking-calendar.model';

export const FEATURE_NAME = 'clientDialog';

export const selectClientDialog = createFeatureSelector<
  ClientDialogState
>(FEATURE_NAME);

export const clientDialogReducers: ActionReducerMap<ClientDialogState> = {
  clientDialogBase: clientDialogBaseReducer,
  clientDialogGeneralInfo: clientDialogGeneralInfoReducer,
  clientDialogTask: clientDialogTaskReducer,
  clientDialogTags: clientDialogTagsReducer,
  clientDialogReps: clientDialogRepsReducer,
  clientDialogPreferences: clientDialogPreferencesReducer,
  clientDialogBuildings: clientDialogBuildingsReducer,
  clientDialogActivities: clientDialogActivityReducer,
  clientDialogLeadNote: clientDialogLeadNoteReducer,
  clientDialogInventory: clientDialogInventoryReducer,
  clientDialogBookingBuildingAvailability: clientDialogBookingBuildingAvailabilityReducer,
  clientDialogBookingeventCalendar: clientDialogBookingRepresentativeEventCalendarReducer,
  clientDialogClientAppointment: clientDialogClientAppointmentReducer,
  clientDialogClientRentalApplication: clientDialogClientRentalApplicationReducer,
  clientDialogEmailEditor: clientDialogEmailEditorReducer,
  clientDialogTextMessage: clientDialogTextMessageReducer,
  clientDialogClientRentalRequest: clientDialogClientRentalRequestReducer
};

export interface ClientDialogState {
  clientDialogBase: ClientDialogBaseState
  clientDialogGeneralInfo: ClientDialogGeneralInfoState
  clientDialogPreferences: ClientDialogPreferenceState
  clientDialogTags: ClientDialogTagsState
  clientDialogReps: ClientDialogRepsState
  clientDialogBuildings: ClientDialogBuildingsState
  clientDialogTask: ClientDialogTaskState
  clientDialogActivities: ClientDialogActivitiesState
  clientDialogLeadNote: ClientDialogLeadNoteState
  clientDialogInventory: ClientDialogInventoryState
  clientDialogBookingBuildingAvailability: ClientDialogBookingBuildingAvailabilityState
  clientDialogBookingeventCalendar: ClientDialogBookingEventCalendarState,
  clientDialogEmailEditor: ClientDialogEmailEditorState,
  clientDialogTextMessage: ClientDialogTextMessageState,
  clientDialogClientAppointment: ClientDialogClientAppointmentState,
  clientDialogClientRentalRequest: ClientDialogClientRentalRequestState,
  clientDialogClientRentalApplication: ClientDialogClientRentalApplicationState
}

export interface ClientDialogBaseState {
  activeTab: number;
}

export interface ClientDialogGeneralInfoState {
  leadId: number;
  leadInfo: LeadInfo;
  isLeadInfoLoaded: boolean;
  isBaseInfoEditMode: boolean;
  isBaseInfoSubmitting: boolean;
  clientDialogLeadsToMerge: ClientDialogLeadMergeState;
  callFromPhoneNumbers: UserPhoneNumber[]
  clientDialogPhoneNumberBlock: ClientDialogLeadPhoneNumberBlockState
}

export interface ClientDialogBookingState {
  isBookingFormSubmitting: boolean;
  isBookedSuccessfully: boolean;
  timeSlots: Date[],
  leadSources: LeadSource[],
  representativeCalendars: BookingCalendar[]
  buildingAddress: string;
}

export interface ClientDialogTagsState {
  isTagsLoaded: boolean
  isTagOptionsLoaded: boolean
  clientTags: Tag[]
  tagOptions: Tag[]
  isTagSubmitting: boolean
  isUpdateSuccessfully: boolean
}

export interface ClientDialogPreferenceState {
  isPreferencesLoaded: boolean
  isPreferenceOptionsLoaded: boolean
  clientPreferences: LeadPreference[]
  preferenceOptions: LeadPreference[]
  isPreferenceSubmitting: boolean
  isUpdateSuccessfully: boolean
}

export interface ClientDialogRepsState {
  isRepsLoaded: boolean
  isRepOptionsLoaded: boolean
  clientReps: AssignedUser[]
  repOptions: AssignedUser[]
  isRepSubmitting: boolean
  isUpdateSuccessfully: boolean
}

export interface ClientDialogBuildingsState {
  isBuildingsLoaded: boolean
  isBuildingOptionsLoaded: boolean
  clientBuildings: LeadBuilding[]
  buildingOptions: LeadBuilding[]
  isBuildingSubmitting: boolean
  isUpdateSuccessfully: boolean
}

export interface ClientDialogLeadMergeState {
  isLeadsToMergeLoaded: boolean
  leadsToMerge: ILeadToMerge[]
  leadToMergeIds: string[]
  isMergeSubmitting: boolean
  isMegerSuccessfully: boolean
}

export interface ClientDialogLeadPhoneNumberBlockState {
  isUpdating: boolean
  isUpdatedSuccessfully: boolean
}

export interface ClientDialogActivitiesState {
  activities: ITimelineElement[]
  isActivitiesLoaded: boolean
  communicationTotalecords: number
  communicationPageNo: number
  communicationPageSize: number
  isLoadingMoreCommunications: boolean
  lastCommunicatePhoneNumber: string
}

export interface ClientDialogLeadNoteState {
  isNoteSubmitting: boolean;
  isNoteSubmittedSuccessfully: boolean;
}

export interface ClientDialogTaskState {
  isTasksLoaded: boolean
  tasks: Task[]
  taskCreateState: TaskCreateState
  taskEditState: TaskEditState
  taskDeleteState: TaskDeleteState
}

export interface TaskCreateState {
  taskAssignUsers: TaskAssignUser[]
  isCreating: boolean
  isCreatedSuccessfully: boolean
}

export interface TaskEditState {
  isEditing: boolean
  isEditSuccessfully: boolean
}

export interface TaskDeleteState {
  isDeleting: boolean
  isDeletSuccessfully: boolean
}

export interface ClientDialogInventoryState {
  inventoryUnits: ClientDialogInventoryUnitsState
}

export interface ClientDialogInventoryUnitsState {
  buildings: IProperty[]
  buildingOptions: IBuilding[]
  buildingPropertyOptions: BuildingProperty[]
  units: IUnit[]
  buildingUnits: IUnit[]
  isPropertiesLoaded: boolean
  isUnitsLoaded: boolean
  isBuildingUnitsLoaded: boolean
  queryFilterOptions: UnitFilterOptions
  unitsQueryFilter: InventoryQeurytFilter
  buildingsUnitsQueryFilterBuildingId: number
  unitsTotalRecords: number
  unitsPaginator: IPagination
}

export interface ClientDialogBookingBuildingAvailabilityState {
  bookingStep: number
  dateFilter: string
  buildingSelected: IBuilding
  buildingSelectedId: number
  buildings: IBuilding[]
  buildingWeeklyAvailability: BuildingWeeklyAvailability,
  isBuildingAvailabilityLoaded: boolean
  isBuildingsLoaded: boolean
  availabilitySelected: string
  isBookingCalendarsLoaded: boolean
  bookingCalendars: BookingCalendar[],
  isScheduleOverwrite: boolean
}


export interface ClientDialogBookingEventCalendarState {
  isLoaded: boolean,
  buildingAddress: string,
  representative: RepresentativeThumbnail,
  buildingId: number,
  buildingProperties: BuildingProperty[]
  event: BookingEvent,
  date: string,
  timeZoneOptions: TimeZone[],
  timeZone: TimeZone,
  timeSlot: BookingTimeSlot,
  timeToConfirmId: string,
  timeSlots: BookingTimeSlot[],
  timeSlotsLoaded: boolean,
  isSubmitting: boolean,
  minCalendarDate: Date,
  leadSources: BookingLeadSource[],
  isFormActive: boolean
  ifBookSucceed: boolean
}

export interface ClientDialogClientAppointmentState {
  isAppointmentsLoaded: boolean
  clientAppointments: ClientAppointment[]
  appointmentInEditting: ClientAppointment
  isCancelling: boolean
  isCancelledSuccessfully: boolean
  lostReasonOptions: string[]
}

export interface ClientDialogClientRentalRequestState {
  isRentalRequestLoaded: boolean;
  isRentalRequestSending: boolean;
  isRentalRequestdeleting: boolean;
  rentalStorageItems: RentalItem[];
  rentalParkingItems: RentalItem[];
  leasingReps: LeasingRep[];
}

export interface ClientDialogClientRentalApplicationState {
  isRentalRequestLoaded: boolean;
  isRentalApplicationLoaded: boolean;
  isRentalApplicationSending: boolean;
  isRentalApplicationFormSending: boolean;
  isRentalApplicationCreditCheckFormSending: boolean;
  isRentalApplicationAddNewFormSending: boolean;
  isRentalApplicationAddNewFormLoading: boolean;
  isRentalApplicationCancelling: boolean;
  isRentalApplicationFormRemoving: boolean;
  isRentalApplicationVerificationReportRemoving: boolean;
  isRentalApplicationFormFileRemoving: boolean;
  isRentalApplicationFormApproveAndSigning: boolean;
  isRentalRequestStatusUpdating: boolean;
  isRentalApplicationFormLoading: boolean;
  isRentalApplicationFormViewerLoading: boolean;
  isRentalApplicationFormEditorLoading: boolean;
  isRentalApplicationFormEditorSaving: boolean;
  isRentalApplicationEmailSending: boolean;
  isRentalApplicationFormListLoading: boolean;
  isRentalRequestFormListLoading: boolean;
  isRentalApplicationFormFileListLoading: boolean;
  isRentalApplicationStarting: boolean;
  isRentalFileUploadLinkSending: boolean;
  isRentalRequestDateSending: boolean;
  isRentalRequestUnitUpdating: boolean;
  isRentalApplicationinfoUpdating: boolean;
  isLeasingSignatureReseting: boolean;
  rentalApplicationAddEdit: RentalApplicationAddNew;
  rentalRequest: RentalRequest;
  rentalApplicationListItems: RentalApplicationListItem[];
  rentalApplication: RentalApplication;
  rentalApplicationLeadInfo: LeadInfo;
  rentalApplicationUnitsToSelect: IUnit[]
  rentalRequestListItems: RentalRequestListItem[];
  rentalFormTemplates: RentalFormTemplate[];
  rentalApplicationFormViewerDocument: RentalDocument[];
  rentalApplicationFormEditorDocument: RentalDocument;
  selectedRentalApplicationForm: DynamicForm;
  rentalRequestValidationMessage: ActionValidationMessage;
  addNewApplicationError: string;
  rentalRequestActivities: RentalRequestAcitivity[];
  isRentalRequestActivitiesLoaded: boolean;
  ifShowRentalRequestActivities: boolean;
  activityTotalRecords: number;
  activityPageNo: number;
  activittPageSize: number;
  isActivitiesLoadingMore: boolean;
  rentalItemOptionsPagination: IPagination;
  rentalItemOptionsTotalRecords: number;
  rentalItemOptions: RentalItem[];
  isRentalItemTypesLoaded: boolean;
  isRentalItemOptionsLoaded: boolean;
  rentalItemTypes: string[];
  isRentalItemUpdating: boolean;
  isRentalItemsLoaded: boolean;
  rentalItemSelected: string;
  cancelErrorMessage: string;
}

export interface ClientDialogEmailEditorState {
  isEmailSubmitting: boolean;
  isEmailSubmittedSuccessfully: boolean;
  emailSendType: EmailSendType;
  emailSendingExternalIdentifier: string;
  leadUserEmails: UserEmail[];
  emailEditor: EmailEditor;
  emailReplyEditor: EmailReplyEditor;
  isCcAndBccActive: boolean;
  replayAllTo: string[];
  isForwarding: boolean;
  emailTemplates: CommunicationTemplate[];
  isTemplatesLoaded: boolean;
  isTemplateSaveOrUpdateing: boolean;
  isTemplateSaveOrUpdateSuccessfully: boolean;
  isTemplateDeleting: boolean;
  isTemplateDeletedSuccessfully: boolean;
}

export interface ClientDialogTextMessageState {
  isTextMessageSubmitting: boolean;
  isTextMessageSubmittedSuccessfully: boolean;
  sendFromPhoneNumbers: UserPhoneNumber[];
  textMessageEditor: TextMessageEditor;
  textMessageTemplates: CommunicationTemplate[];
  isTemplatesLoaded: boolean;
  isTemplateSaveOrUpdateing: boolean;
  isTemplateSaveOrUpdateSuccessfully: boolean;
  isTemplateDeleting: boolean;
  isTemplateDeletedSuccessfully: boolean;
}

export interface EmailEditor {
  email: string;
  sendFromUserId: number;
  sendToEmails: string[];
  sendToCcs: string[];
  sendToBccs: string[];
  subject: string;
  attachements: string[];
}

export interface EmailReplyEditor {
  email: string;
  sendToCcs: string[];
  sendToBccs: string[];
  subject: string;
}

export interface CommunicationTemplate {
  id: number;
  type: string;
  friendlyName: string;
  displayName: string;
  content: string;
  isAccountShared: boolean;
}

export interface TextMessageEditor {
  message: string;
  sendFrom: string;
  sendTo: string;
}

export interface State extends AppState {
  [FEATURE_NAME]: ClientDialogState;
}
