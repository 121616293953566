import { createSelector } from "@ngrx/store";
import { TenantDialogBaseState, TenantDialogState, selectTenantDialog } from "./tenant-dialog.state";

export const selectTenantDialogTenantDialogBase = createSelector(
    selectTenantDialog,
    (state: TenantDialogState) => state.tenantDialogBase
)

export const selectTenantDialogTopNavigationTab = createSelector(
    selectTenantDialogTenantDialogBase,
    (state: TenantDialogBaseState) => state.activeTab
);