<div class="multi-purpose-dialog-container contrast-background-color">
    <button class="close-btn" mat-icon-button (click)="close()">
        <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
    </button>
    <div class="multi-purpose-dialog-title">
        <h2>{{ data.title }}</h2>
    </div>
    <div class="multi-purpose-dialog-content">
        <ng-container *ngComponentOutlet="data.component; injector: componentInjector"></ng-container>
    </div>
</div>