import { createAction, props } from "@ngrx/store"
import { CommunicationTemplate, ITextMessageQueryResult, TextChatMessage } from "app/features/unified-inbox/unified-inbox.model"

export const actionUnifiedInboxTextMessagesRequested = createAction(
    '[Unified Inbox Text Message] Text Messages Requested',
    props<{ leadId: number, page: number, pageSize: number, itemCountPage: number }>()
)

export const actionUnifiedInboxTextMessagesLoaded = createAction(
    '[Unified Inbox Text Message] Text Messages Loaded',
    props<{ textMessageQueryResult: ITextMessageQueryResult }>()
)

export const actionUnifiedInboxTextMessagesFailed = createAction(
    '[Unified Inbox Text Message] Text Messages loading Failed',
)

export const actionUnifiedInboxCreateTextMessageRequest = createAction(
    '[Unified Inbox Text Message] Create Text Message Request',
    props<{ id: number, text: string, sendFromId: string, sendTo: string }>()
)

export const actionUnifiedInboxCreateTextMessageSuccessed = createAction(
    '[Unified Inbox Text Message] Create Text Message Successed',
)

export const actionUnifiedInboxCreateTextMessageFailed = createAction(
    '[Unified Inbox Text Message] Create Text Message Failed',
)

export const actionUnifiedInboxAddTextMessageReceived = createAction(
    '[Unified Inbox Text Message] Add Text Message Received',
    props<{ textMessage: TextChatMessage }>()
)

export const actionUnifiedInboxAddTextMessageNotAdded = createAction(
    '[Unified Inbox Text Message] Add Text Message Not Added'
)

export const actionUnifiedInboxAddTextMessageLoaded = createAction(
    '[Unified Inbox Text Message] Add Text Message Loaded',
    props<{ textMessage: TextChatMessage }>()
)

export const actionUnifiedInboxAddTextMessageFailed = createAction(
    '[Unified Inbox Text Message] Add Text Message Failed',
)

export const actionUnifiedInboxSendTextRequested = createAction(
    '[Unified Inbox Text Message] Send Text Message Requested',
    props<{ leadId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionUnifiedInboxSendTextSuccess = createAction(
    '[Unified Inbox Text Message] Send Text Message Success',
)

export const actionUnifiedInboxSendTextFailed = createAction(
    '[Unified Inbox Text Message] Send Text Message Failed',
)

export const actionUnifiedInboxSendTextAndCollaborateRequested = createAction(
    '[Unified Inbox Text Message] Send Text Message And Collaborate Requested',
    props<{ leadId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionUnifiedInboxSendTextAndCollaborateSuccess = createAction(
    '[Unified Inbox Text Message] Send Text Message And Collaborate Success',
)

export const actionUnifiedInboxSendTextAndCollaborateFailed = createAction(
    '[Unified Inbox Text Message] Send Text Message And Collaborate Failed',
)

export const actionUnifiedInboxTextMessageTextContentUpdate = createAction(
    '[Unified Inbox Text Message] Text Content Update',
    props<{ message: string, sendFrom: string, sendTo: string }>()
)

export const actionUnifiedInboxTextMessageTemplatesRequest = createAction(
    '[Unified Inbox Text Message] Templates Request',
)

export const actionUnifiedInboxTextMessageTemplatesLoaded = createAction(
    '[Unified Inbox Text Message] Templates Loaded',
    props<{ templates: CommunicationTemplate[] }>()
)

export const actionUnifiedInboxTextMessageTemplatesFalied = createAction(
    '[Unified Inbox Text Message] Templates Failed',
)

export const actionUnifiedInboxTextMessageTemplateSaveAndUpdate = createAction(
    '[Unified Inbox TextMessage] Eamil Template Save And Update',
    props<{ friendlyName: string, displayName: string, content: string, id?: number, isAccountShared: boolean }>()
);

export const actionUnifiedInboxTextMessageTemplateSaveAndUpdateSucceeded = createAction(
    '[Unified Inbox TextMessage] Eamil Template Save And Update Succeeded'
);

export const actionUnifiedInboxTextMessageTemplateSaveAndUpdateFailed = createAction(
    '[Unified Inbox TextMessage] Eamil Template Save And Update Failed'
);

export const actionUnifiedInboxTextMessageTemplateDeleteRequest = createAction(
    '[Unified Inbox TextMessage] Eamil Template Delete Request',
    props<{ id: number }>()
);

export const actionUnifiedInboxTextMessageTemplateDeleteSucceeded = createAction(
    '[Unified Inbox TextMessage] Eamil Template Delete Succeeded'
);

export const actionUnifiedInboxTextMessageTemplateDeleteFailed = createAction(
    '[Unified Inbox TextMessage] Eamil Template Delete Failed'
);

export const actionUnifiedInboxTextMessageTemplateDeleteStateReset = createAction(
    '[Unified Inbox TextMessage] Eamil Template Delete State Reset'
);
export const actionUnifiedInboxTextMessageTemplateSaveAndUpdateStateReset = createAction(
    '[Unified Inbox TextMessage] Eamil Template Save And Failed State Reset'
);

export const actionUnifiedInboxTextMessageStateReset = createAction(
    '[Unified Inbox Text Message] Text Message State Reset',
)

export const unifiedInboxTextMessageActions = {
    actionUnifiedInboxTextMessagesRequested,
    actionUnifiedInboxTextMessagesLoaded,
    actionUnifiedInboxTextMessagesFailed,
    actionUnifiedInboxCreateTextMessageRequest,
    actionUnifiedInboxCreateTextMessageSuccessed,
    actionUnifiedInboxCreateTextMessageFailed,
    actionUnifiedInboxTextMessageStateReset,
    actionUnifiedInboxAddTextMessageReceived,
    actionUnifiedInboxAddTextMessageNotAdded,
    actionUnifiedInboxAddTextMessageLoaded,
    actionUnifiedInboxAddTextMessageFailed,
    actionUnifiedInboxSendTextRequested,
    actionUnifiedInboxSendTextSuccess,
    actionUnifiedInboxSendTextFailed,
    actionUnifiedInboxTextMessageTextContentUpdate,
    actionUnifiedInboxSendTextAndCollaborateRequested,
    actionUnifiedInboxSendTextAndCollaborateSuccess,
    actionUnifiedInboxTextMessageTemplatesRequest,
    actionUnifiedInboxTextMessageTemplatesLoaded,
    actionUnifiedInboxTextMessageTemplatesFalied,
    actionUnifiedInboxTextMessageTemplateSaveAndUpdate,
    actionUnifiedInboxTextMessageTemplateSaveAndUpdateSucceeded,
    actionUnifiedInboxTextMessageTemplateSaveAndUpdateFailed,
    actionUnifiedInboxTextMessageTemplateDeleteRequest,
    actionUnifiedInboxTextMessageTemplateDeleteSucceeded,
    actionUnifiedInboxTextMessageTemplateDeleteFailed,
    actionUnifiedInboxTextMessageTemplateDeleteStateReset,
    actionUnifiedInboxTextMessageTemplateSaveAndUpdateStateReset,
    actionUnifiedInboxSendTextAndCollaborateFailed,
}
