export interface UserEmail {
    userId: number;
    email: string;
}

export interface UserPhoneNumber {
    id: string;
    userId: number;
    friendlyName: string;
    phoneNumber: string;
}

export interface CallTransferQueue {
    id: number;
    name: string;
    phoneQueueType: string;
}

export enum EmailSendType {
    SEND = 'Send',
    REPLY = 'Reply',
    REPLYALL = 'ReplyAll',
    FORWARD = 'Forward',
}
