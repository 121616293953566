import { Action, createReducer, on } from "@ngrx/store";
import { TenantDialogTagsState } from "../../tenant-dialog.state";
import { tenantDialogTagActions } from "./tenant-dialog-tenant-tag.action";

const initialState: TenantDialogTagsState = {
    isTagsLoaded: false,
    isTagOptionsLoaded: false,
    tenantTags: [],
    tagOptions: [],
    isTagSubmitting: false,
    isUpdateSuccessfully: false
}

const reducer = createReducer(
    initialState,
    on(
        tenantDialogTagActions.actionTenantDialogTenantTagsRequested,
        (state) => ({
            ...state,
            isTagsLoaded: false
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTenantTagsLoaded,
        (state, { tags }) => ({
            ...state,
            isTagsLoaded: true,
            tenantTags: tags
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTenantTagsFailed,
        (state) => ({
            ...state,
            isTagsLoaded: true
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagOptionsLoaded,
        (state, { tags }) => ({
            ...state,
            tagOptions: tags,
            isTagOptionsLoaded: true
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagOptionsFailed,
        (state) => ({
            ...state,
            isTagOptionsLoaded: true
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsUpdate,
        (state, { tags }) => ({
            ...state,
            isTagsLoaded: true,
            tenantTags: tags
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagOptionsCheckStateInitialize,
        (state) => ({
            ...state,
            tagOptions: state.tagOptions.map(x => state.tenantTags.map(y => y.id).includes(x.id) ? { ...x, isChecked: true } : { ...x, isChecked: false })
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagOptionsCheckStateUnCheckAll,
        (state) => ({
            ...state,
            tagOptions: state.tagOptions.map(x => { return { ...x, isChecked: false } })
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsTagIsCheckedUpdate,
        (state, { checked, tag }) => ({
            ...state,
            tagOptions: state.tagOptions.map(x => x.id == tag.id ? { ...x, isChecked: checked } : x)
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsUpdateRequested,
        (state) => ({
            ...state,
            isTagSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogBatchTagsAddRequested,
        tenantDialogTagActions.actionTenantDialogBatchTagsRemoveRequested,
        (state) => ({
            ...state,
            isTagSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsUpdateSuccessfully,
        (state, { tags }) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: true,
            tenantTags: tags
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogBatchTagsAddSuccessfully,
        tenantDialogTagActions.actionTenantDialogBatchTagsRemoveSuccessfully,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: true,
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTenantTagDeleteSuccessfully,
        (state, { tags }) => ({
            ...state,
            tenantTags: tags
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsUpdateFailed,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogBatchTagsAddFailed,
        tenantDialogTagActions.actionTenantDialogBatchTagsRemoveFailed,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsCreateUpdateSuccessfulStateReset,
        (state) => ({
            ...state,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTenantTagsFailed,
        (state) => ({
            ...state,
            isTagsLoaded: true
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsTagOptionsDialogStateReset,
        (state) => ({
            ...state,
            isTagSubmitting: false,
            isUpdateSuccessfully: false,
            tagOptions: state.tagOptions
        })
    ),
    on(
        tenantDialogTagActions.actionTenantDialogTagsReset,
        (state,) => (initialState)
    ),
)

export const tenantDialogTagsReducer = (
    state: TenantDialogTagsState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
