import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineElement } from '../../timeline.model';
import { select, Store } from '@ngrx/store';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';

@Component({
  selector: 'dq-timeline-text',
  templateUrl: './timeline-text.component.html',
  styleUrls: ['./timeline-text.component.scss', './timeline-text.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimelineTextComponent implements OnInit {

  @Input() event: ITimelineElement

  isMobile$: Observable<boolean>;

  msgFrom: string
  msgTo: string
  msg: string

  constructor(public store: Store<State>) { }

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile));
    this.msgFrom = this.event.textMessage?.fromLabel != null ? this.event.textMessage?.fromLabel : this.event.textMessage?.from
    this.msgTo = this.event.textMessage?.toLabel != null ? this.event.textMessage?.toLabel : this.event.textMessage?.to
    this.msg = this.event.textMessage?.message?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
