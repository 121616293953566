import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Call, Device } from '@twilio/voice-sdk';

import { environment } from '../../../environments/environment';
import { AppState } from '../core.state';
import { IWebApiResponse } from '../response/response.model';
import {
  actionCallManagerDeviceRegisteredUpdate,
  actionCallManagerLogEvent,
  actionCallManagerLogInDeviceRequested,
  actionCallManagerLogOutDeviceRequested,
  actionCallManagerUpdateCallFromPhoneNumber,
  actionCallManagerUpdateCallPanel,
  actionCallManagerUpdateCallToPhoneNumber,
  actionCallManagerUpdateInComingCallConnected,
  actionCallManagerUpdateInComingCallPanel,
  actionCallManagerUpdateOutgoingCallConnected,
  actionCallManagerUpdateOutgoingCallPanel
} from './call-manager.actions';
import { DeviceAuth } from './call-manager.model';
import { selectIsAuthenticated } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class CallManagerService {

  isAuth$: Observable<boolean>;
  isAuth: boolean;

  constructor(private http: HttpClient,
              private store: Store<AppState>) {
    this.isAuth$ = this.store.pipe(select(selectIsAuthenticated));
    this.isAuth$.subscribe((isAuth) => {
      if (!isAuth) {
        this.callReset();
        this.device?.unregister();
      }
      this.isAuth = isAuth;
    });
  }

  get speakerDevices(): MediaDeviceInfo {
    return this.device.audio.speakerDevices.get().values().next().value;
  }

  get ringtoneDevices(): MediaDeviceInfo {
    return this.device.audio.ringtoneDevices.get().values().next().value;
  }

  get CallParameterTo() {
    const urlParams = this.call?.parameters?.Params;
    const params = new URLSearchParams(urlParams);
    return params?.get('To');
  }

  get CallParameterFrom() {
    return this.call.parameters.From;
  }

  private speakerChange$ = new Subject<void>(); // Subject to emit events

  private ringtoneChange$ = new Subject<void>(); // Subject to emit events

  device: Device;
  call?: Call;
  mediaStream: MediaStream;
  speakerMediaDevices: MediaDeviceInfo[] = [];
  ringtoneMediaDevices: MediaDeviceInfo[] = [];

  onSpeakerChange() {
    return this.speakerChange$.asObservable();
  }

  onRingtoneChange() {
    return this.ringtoneChange$.asObservable();
  }


  async startOutgoingCall(from: string, to: string) {
    const params = {
      FromSelectedPhoneNumber: from,
      To: to
    };
    if (this.device) {
      this.log(`Attempting to call ${to}...`);
      this.store.dispatch(actionCallManagerUpdateCallFromPhoneNumber({ fromPhoneNumber: from }));
      this.store.dispatch(actionCallManagerUpdateCallToPhoneNumber({ toPhoneNumber: to }));
      this.store.dispatch(actionCallManagerUpdateCallPanel({ isCallPanelActive: true }));
      this.store.dispatch(actionCallManagerUpdateOutgoingCallPanel({ isOutgoingCallPanelActive: true }));
      this.call = await this.device?.connect({ params });
      this.call?.on('disconnect', this.callReset.bind(this));
      this.call?.on('accept', this.outgoingCallAccepted.bind(this));
      this.call?.on('error', this.onCallError.bind(this));
    } else {
      this.log('Unable to make call.');
    }
  }

  async getAudioDevices() {
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaStream = stream;
        console.log('Got MediaStream:', stream);
      })
      .catch((error) => {
        this.log(`Error accessing media devices. >>> ${error}`);
      });
    this.updateAllAudioDevices(this.device);
  }

  acceptCall() {
    this.call?.accept();
  }

  endCall() {
    this.store.dispatch(actionCallManagerUpdateCallPanel({ isCallPanelActive: false }));
    this.call?.disconnect();
  }

  incomingCallAccepted() {
    this.store.dispatch(actionCallManagerUpdateInComingCallConnected({ isIncomingCallConnected: true }));
  }

  callReset() {
    this.store.dispatch(actionCallManagerUpdateInComingCallConnected({ isIncomingCallConnected: false }));
    this.store.dispatch(actionCallManagerUpdateInComingCallPanel({ isIncomingCallPanelActive: false }));
    this.store.dispatch(actionCallManagerUpdateOutgoingCallConnected({ isOutgoingCallConnected: false }));
    this.store.dispatch(actionCallManagerUpdateOutgoingCallPanel({ isOutgoingCallPanelActive: false }));
    this.store.dispatch(actionCallManagerUpdateCallPanel({ isCallPanelActive: false }));
    this.store.dispatch(actionCallManagerUpdateCallFromPhoneNumber({ fromPhoneNumber: '' }));
    this.store.dispatch(actionCallManagerUpdateCallToPhoneNumber({ toPhoneNumber: '' }));

    this.call?.disconnect();
    this.call = undefined;
  }

  outgoingCallAccepted(call: Call) {
    this.store.dispatch(actionCallManagerUpdateOutgoingCallConnected({ isOutgoingCallConnected: true }));
  }

  registerDevice(deviceAuth: DeviceAuth) {
    if (!deviceAuth.token || !this.isAuth) return;

    if (this.device) {
      this.log('Unregistering previous device.');
      if (this.device?.state.toString() === 'registered') {
        this.device.unregister();
      }
    }

    this.device = new Device(deviceAuth.token, {
      logLevel: 1,
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions.
      // codecPreferences: []
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      // codecPreferences: ['opus', 'pcmu']
      tokenRefreshMs: 30_000,
      closeProtection: true,
      allowIncomingWhileBusy: false,
      enableImprovedSignalingErrorPrecision: true
    });

    this.addDeviceListeners(this.device);
    // Device must be registered in order to receive incoming calls

    if (this.device?.state.toString() === 'unregistered') {
      this.device.register();
      this.store.dispatch(actionCallManagerDeviceRegisteredUpdate({ isRegistered: true }));
    }

  }

  addDeviceListeners(device: Device) {
    if (!device) return;

    device.on('registered', this.onDeviceRegistered.bind(this));
    device.on('unregistered', this.onDeviceUnregistered.bind(this));
    device.on('error', this.onDeviceError.bind(this));
    device.on('incoming', this.handleIncomingCall.bind(this));
    // device?.audio?.on("deviceChange", this.updateAllAudioDevices.bind(device));
  }

  onDeviceRegistered() {
    this.log('Phone deviced registered.');
    this.getAudioDevices();
    this.store.dispatch(actionCallManagerDeviceRegisteredUpdate({ isRegistered: true }));
  }

  onDeviceUnregistered() {
    this.log('Phone deviced unregistred.');
    this.mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    this.store.dispatch(actionCallManagerDeviceRegisteredUpdate({ isRegistered: false }));
  }

  onDeviceError(error: any) {
    console.log('Device Error: ' + error.message);
  }

  onCallError(error: any) {
    this.log(error);
    this.callReset();
  }

  handleIncomingCall(call: Call) {

    this.log(`Incoming call from ${call.parameters.From}`);
    this.call = call;
    call?.on('accept', this.incomingCallAccepted.bind(this));
    call?.on('cancel', this.callReset.bind(this));
    call?.on('disconnect', this.callReset.bind(this));
    call?.on('reject', this.callReset.bind(this));
    call?.on('error', this.callReset.bind(this));

    this.store.dispatch(actionCallManagerUpdateCallFromPhoneNumber({ fromPhoneNumber: this.CallParameterFrom }));
    this.store.dispatch(actionCallManagerUpdateCallToPhoneNumber({ toPhoneNumber: this.CallParameterFrom }));
    this.store.dispatch(actionCallManagerUpdateInComingCallPanel({ isIncomingCallPanelActive: true }));
    this.store.dispatch(actionCallManagerUpdateCallPanel({ isCallPanelActive: true }));

  }

  updateAllAudioDevices(device?: Device) {
    if (!device) return;

    this.updateSpeakerDevices();
    this.updateRingtoneDevices();
  }

  updateSpeakerDevices() {
    const options: MediaDeviceInfo[] = [];
    this.speakerMediaDevices = [];

    this.device?.audio?.availableOutputDevices.forEach(
      (value: MediaDeviceInfo) => {
        options.push(value);
      }
    );
    this.speakerMediaDevices = options;

    this.speakerChange$.next();
  }

  updateRingtoneDevices() {
    const options: MediaDeviceInfo[] = [];
    this.ringtoneMediaDevices = [];

    this.device?.audio?.availableOutputDevices.forEach((
      value: MediaDeviceInfo
    ) => {
      options.push(value);
    });
    this.ringtoneMediaDevices = options;
    this.ringtoneChange$.next();
  }

  log(log: string) {
    this.store.dispatch(actionCallManagerLogEvent({ event: log }));
    console.log(log);
  }

  setSpeakerDeviceVolume(value: string) {
    this.device?.audio?.speakerDevices.set(value);
    this.speakerChange$.next();
  }

  setRingtoneDeviceVolume(value: string) {
    this.device?.audio?.ringtoneDevices.set(value);
    this.ringtoneChange$.next();
  }

  doLoginAndRegister() {
    this.store.dispatch(actionCallManagerLogInDeviceRequested());
  }

  doLogoutAndUnregister() {
    this.store.dispatch(actionCallManagerLogOutDeviceRequested());
    if (this.device?.state.toString() === 'registered')
      this.device?.unregister();
  }

  sendDigit(digit: string) {
    this.call?.sendDigits(digit);
  }

  mute(isMuted: boolean) {
    this.call?.mute(isMuted);
  }

  rejectIncomingCall() {
    this.call?.reject();
    this.log('Rejected incoming call');
  }

  getDeviceAuthenticationToken(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post(`${environment.apiBaseUrl}auth/registerDevice`, {})
      .pipe(
        map((response) => response)
      );
  }

  transferCall(queueId: number) {
    const params = { queueId };
    return this.http.post(`${environment.apiBaseUrl}call-center/transfer`, params)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  getUserPhoneNumberSettings() {
    return this.http.get(`${environment.apiBaseUrl}call-center/getUserComSettings`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  getTransferQueueOptions() {
    return this.http.get(`${environment.apiBaseUrl}call-center/getTransferPhoneQueueOptions`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      }));
  }

  blockPhoneNumber(externalBlockedPhoneNumber: string, reason: string): Observable<IWebApiResponse> {
    return this.http.post(`${environment.apiBaseUrl}call-center/blockPhoneNumber`,
      {
        externalBlockedPhoneNumber,
        reason
      })
      .pipe(map((response: IWebApiResponse) => response));
  }

  logEvent(logMessage: string): Observable<IWebApiResponse> {
    return this.http.post(`${environment.apiBaseUrl}call-center/userCallLog`, { logMessage })
      .pipe(map((response: IWebApiResponse) => response));
  }

  getCallParameterTo() {
    const urlParams = this.call?.parameters?.Params;
    const params = new URLSearchParams(urlParams);
    return params?.get('To');
  }

  ngOnDestroy(): void {
    this.mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
    if (this.device?.state.toString() === 'registered') {
      this.device?.unregister();
    }
  }
}
