<div class="tenant-dialog-task-component">
    <mat-card>
        <div class="container">
            <div class="row">
                <div>
                    <mat-icon class="icon-general">list</mat-icon>
                </div>
                <div>
                    <h4 class="title">Tasks</h4>
                </div>
                <button mat-mini-fab class="add-task-button" (click)="openCreateTaskDialog()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="tasks-container">
            <ng-container *ngIf="isTasksLoaded$ | async; else loading">
                <ng-container *ngFor="let task of (tasks$ | async); let i = index">
                    <div class="task-item-div task-done-item" [ngClass]="{'task-done-item': task.isCompleted}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="task-date-div">
                                    <mat-checkbox class="task-check-box" [checked]="task.isCompleted"
                                        (click)="taskStatusToggle(task.id)">
                                    </mat-checkbox>
                                    <mat-icon class="task-date-icon">schedule</mat-icon>
                                    <span class="task-date-text">
                                        {{task.date | date: 'EE, MMM d, h:mm a'}} {{task.timeZone}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="task-name-div">
                                    <div>
                                        <mat-icon class="task-type-icon"
                                            [ngStyle]="{color:getTaskTypeInfo(task.type)?.color}">
                                            {{getTaskTypeInfo(task.type)?.icon}}
                                        </mat-icon>
                                        <span class="task-name">{{task.name}}</span>
                                    </div>
                                    <mat-icon class="task-edit-icon" (click)="openEditTaskDialog(task)">edit</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="i < (tasks$ | async).length -1">
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #loading>
                <mat-spinner class="loading-spinner"></mat-spinner>
            </ng-template>
        </div>
    </mat-card>
</div>