import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { ITimelineElement } from "app/shared/components/timeline/timeline.model";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ClientDialogService } from "../../client-dialog.service";
import { clientDialogActivityActions } from "./client-dialog-activities.action";

@Injectable()
export class ClientDialogActivityEffects {
    constructor(
        private actions$: Actions,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    requestClientAcitivites = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogActivityActions.actionClientDialogAllActivitiesRequest),
            switchMap(action =>
                this.clientDialogService.getActivitiesByLeadId(action.leadId, action.pageNo, action.pageSize, action.itemCountPage).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let activities = response.data.objectsList as ITimelineElement[]
                            let pageNo = response.data.page
                            let pageSize = response.data.pageSize
                            let totalRecords = response.data.totalRecords
                            return clientDialogActivityActions.actionClientDialogAllActivitiesSuccessed({ activities, pageNo, pageSize, totalRecords })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogActivityActions.actionClientDialogAllActivitiesFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogActivityActions.actionClientDialogAllActivitiesFailed())
                    })
                )
            )
        )
    );
}
