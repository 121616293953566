import { createAction, props } from "@ngrx/store"
import { CommunicationTemplate } from "app/features/tenant-unified-inbox/tenant-unified-inbox.model"



export const actionTenantDialogCreateTextMessageRequest = createAction(
    '[Tenant Dialog Text Message] Create Text Message Request',
    props<{ id: number, text: string, sendFromId: string, sendTo: string }>()
)

export const actionTenantDialogCreateTextMessageSuccessed = createAction(
    '[Tenant Dialog Text Message] Create Text Message Successed',
)

export const actionTenantDialogCreateTextMessageFailed = createAction(
    '[Tenant Dialog Text Message] Create Text Message Failed',
)

export const actionTenantDialogSendTextRequested = createAction(
    '[Tenant Dialog Text Message] Send Text Message Requested',
    props<{ tenantId: number, text: string, sendFromId: string, sendTo: string }>()
)

export const actionTenantDialogSendTextSuccess = createAction(
    '[Tenant Dialog Text Message] Send Text Message Success',
)

export const actionTenantDialogSendTextFailed = createAction(
    '[Tenant Dialog Text Message] Send Text Message Failed',
)

export const actionTenantDialogSendTextAndCollaborateRequested = createAction(
    '[Tenant Dialog Text Message] Send Text Message And Collaborate Requested',
    props<{ tenantId: number, text: string, sendFromId: number, sendTo: string }>()
)

export const actionTenantDialogSendTextAndCollaborateSuccess = createAction(
    '[Tenant Dialog Text Message] Send Text Message And Collaborate Success',
)

export const actionTenantDialogSendTextAndCollaborateFailed = createAction(
    '[Tenant Dialog Text Message] Send Text Message And Collaborate Failed',
)

export const actionTenantDialogTextMessageTextContentUpdate = createAction(
    '[Tenant Dialog Text Message] Text Content Update',
    props<{ message: string, sendFrom: string, sendTo: string }>()
)
export const actionTenantDialogTextMessageTemplatesRequest = createAction(
    '[Tenant Dialog Text Message] Templates Request',
)

export const actionTenantDialogTextMessageTemplatesLoaded = createAction(
    '[Tenant Dialog Text Message] Templates Loaded',
    props<{ templates: CommunicationTemplate[] }>()
)

export const actionTenantDialogTextMessageTemplatesFalied = createAction(
    '[Tenant Dialog Text Message] Templates Failed',
)

export const actionTenantDialogTextMessageTemplateSaveAndUpdate = createAction(
    '[Tenant Dialog Text Message] Eamil Template Save And Update',
    props<{ friendlyName: string, displayName: string, content: string, isAccountShared: boolean, id?: number }>()
);

export const actionTenantDialogTextMessageTemplateSaveAndUpdateSucceeded = createAction(
    '[Tenant Dialog Text Message] Eamil Template Save And Update Succeeded'
);

export const actionTenantDialogTextMessageTemplateSaveAndUpdateFailed = createAction(
    '[Tenant Dialog Text Message] Eamil Template Save And Update Failed'
);

export const actionTenantDialogTextMessageTemplateDeleteRequest = createAction(
    '[Tenant Dialog Text Message] Eamil Template Delete Request',
    props<{ id: number }>()
);

export const actionTenantDialogTextMessageTemplateDeleteSucceeded = createAction(
    '[Tenant Dialog Text Message] Eamil Template Delete Succeeded'
);

export const actionTenantDialogTextMessageTemplateDeleteFailed = createAction(
    '[Tenant Dialog Text Message] Eamil Template Delete Failed'
);

export const actionTenantDialogTextMessageTemplateDeleteStateReset = createAction(
    '[Tenant Dialog Text Message] Eamil Template Delete State Reset'
);
export const actionTenantDialogTextMessageTemplateSaveAndUpdateStateReset = createAction(
    '[Tenant Dialog Text Message] Eamil Template Save And Failed State Reset'
);

export const actionTenantDialogTextMessagestateReset = createAction(
    '[Tenant Dialog Text Message] Text Message State Reset',
)

export const tenantDialogTextMessageActions = {
    actionTenantDialogCreateTextMessageRequest,
    actionTenantDialogCreateTextMessageSuccessed,
    actionTenantDialogCreateTextMessageFailed,
    actionTenantDialogSendTextRequested,
    actionTenantDialogSendTextSuccess,
    actionTenantDialogSendTextFailed,
    actionTenantDialogSendTextAndCollaborateRequested,
    actionTenantDialogSendTextAndCollaborateSuccess,
    actionTenantDialogSendTextAndCollaborateFailed,
    actionTenantDialogTextMessageTextContentUpdate,
    actionTenantDialogTextMessageTemplatesRequest,
    actionTenantDialogTextMessageTemplatesLoaded,
    actionTenantDialogTextMessageTemplatesFalied,
    actionTenantDialogTextMessageTemplateSaveAndUpdate,
    actionTenantDialogTextMessageTemplateSaveAndUpdateSucceeded,
    actionTenantDialogTextMessageTemplateSaveAndUpdateFailed,
    actionTenantDialogTextMessageTemplateDeleteRequest,
    actionTenantDialogTextMessageTemplateDeleteSucceeded,
    actionTenantDialogTextMessageTemplateDeleteFailed,
    actionTenantDialogTextMessageTemplateDeleteStateReset,
    actionTenantDialogTextMessageTemplateSaveAndUpdateStateReset,
    actionTenantDialogTextMessagestateReset
}
