<div class="tenant-dialog-buildings-component">
    <mat-card>
        <div class="container">
            <div class="row">
                <div>
                    <mat-icon class="icon-general" svgIcon="building"></mat-icon>
                </div>
                <div>
                    <h4 class="title">Building</h4>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div class="row items-container">
                <ng-container>
                    <div class="buildings-container">
                        <ng-container *ngIf="(isBuildingsLoaded$ | async); else buildingsLoading">
                            <div class="building-div-wrapper">
                                <div class="building-div"> {{(tenantBuilding$ | async).name}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #buildingsLoading>
                            <mat-spinner class="buildings-loading-spinner"></mat-spinner>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>
</div>