import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { selectIsAuthenticated, selectIsNotExpired, selectIsRequireTwoFactorVerify } from './auth.selectors';
import { AppState } from '../core.state';
import { tap } from 'rxjs/operators';
import { authLogout } from './auth.actions';

@Injectable()
export class AuthTwoFactorGuardService  {
  constructor(private store: Store<AppState>, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(selectIsRequireTwoFactorVerify)).pipe(tap(isAuth =>{
      if (!isAuth) {
        this.store.dispatch(authLogout());
      }
    }))
  }


  canActivateService(): Observable<boolean> {
    return combineLatest(
      this.store.select(selectIsAuthenticated),
      this.store.select(selectIsNotExpired),
      (isAuth, isNotExpired) => isAuth && isNotExpired
    );
  }
}
