import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { ManualSliderComponent } from 'app/shared/components/manual-slider/manual-slider.component';
import { IPagination } from 'app/shared/models/pagination.model';
import { DigiDecimalCommaPipe } from 'app/shared/pipes/digi-decimal-comma-pipe.pipe';
import { DigiDecimalPipe } from 'app/shared/pipes/digi-decimal-pipe.pipe';
import { fromEvent, Observable, Subject } from 'rxjs';
import { BathOption, BedOption, BuildingProperty, IBuilding, InventoryQeurytFilter, IProperty, IUnit, IUnitAmenity, LEASED_OPTIONS, OCCUPENCY_OPTIONS, UNIT_ECONOMIC_OPTIONS } from '../client-dialog-inventory.model';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { selectClientDialogInventoryBuildings, selectClientDialogInventoryBuildingOptions, selectClientDialogInventoryBuildingPropertyOptions, selectClientDialogInventoryUnits, selectClientDialogInventoryBuildingUnits, selectClientDialogInventoryIsUnitsLoaded, selectClientDialogInventoryIsBuildingUnitsLoaded, selectClientDialogInventoryisPropertiesLoaded, selectClientDialogInventoryQueryFilterBedOptions, selectClientDialogInventoryQueryFilterBathOptions, selectClientDialogInventoryUnitsQueryFilter, selectClientDialogInventoryAreaUnitsQueryPaginator, selectClientDialogInventoryAreaUnitsTotalRecords, selectClientDialogInventoryBuildingUnitsQueryFilterBuildingId, selectClientDialogInventoryUnitsQueryFilterBuildingPropertyId, selectClientDialogInventoryUnitsQueryFilterIsMadeReady, selectClientDialogInventoryUnitsQueryFilterBuildingIds, selectClientDialogInventoryUnitsQueryFilterBasicFilters, selectClientDialogInventoryUnitsQueryFilterMoreFilters, selectClientDialogInventoryUnitsQueryFilterIsReserved, selectClientDialogInventoryUnitsQueryFilterIsNotReady } from '../client-dialog-inventory.selector';
import { debounceTime, distinctUntilChanged, map, skip, startWith, takeUntil } from 'rxjs/operators';
import { actionClientDialogInventoryAreaBuildingOptionsRequested, actionClientDialogInventoryAreaBuildingsRequested, actionClientDialogInventoryAreaBuildingUnitsRequested, actionClientDialogInventoryAreaUnitsRequested, actionClientDialogInventoryBuildingUnitsReset, actionClientDialogInventoryFilterProptertyIdsToRemoveRequest, actionClientDialogInventoryUnitsBuildingIdChange, actionClientDialogInventoryUnitsBuildingPropertyRequest, actionClientDialogInventoryUnitsFilterReset, actionClientDialogInventoryUnitsStateReset, actionClientDialogInventoryUpdateBuildingPropertyFilterBuildingId, actionClientDialogInventoryUpdateBuildingsFromLead, actionClientDialogInventoryUpdateUnitsQueryFilterBath, actionClientDialogInventoryUpdateUnitsQueryFilterBathNumber, actionClientDialogInventoryUpdateUnitsQueryFilterBed, actionClientDialogInventoryUpdateUnitsQueryFilterBuildingProperty, actionClientDialogInventoryUpdateUnitsQueryFilterEconomicStatus, actionClientDialogInventoryUpdateUnitsQueryFilterIsReservedCheckbox, actionClientDialogInventoryUpdateUnitsQueryFilterLeasedStatus, actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyCheckbox, actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyDate, actionClientDialogInventoryUpdateUnitsQueryFilterNotReadyCheckbox, actionClientDialogInventoryUpdateUnitsQueryFilterOccupencyStatus, actionClientDialogInventoryUpdateUnitsQueryFilterPrice, actionClientDialogInventoryUpdateUnitsQueryFilterSearchByPropertyName, actionClientDialogInventoryUpdateUnitsQueryFilterSize, actionClientDialogInventoryUpdateUnitsQueryFilterVacancyDate } from '../client-dialog-inventory.action';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { isPlatformBrowser } from '@angular/common';
import { ClientDialogInventoryBookingComponent } from '../components/client-dialog-inventory-booking/client-dialog-inventory-booking/client-dialog-inventory-booking.component';
import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { AuthService } from 'app/core/auth/auth.service';
import { LeadBuilding } from 'app/shared/models/lead-info-models';
import { selectclientDialogBuildingsStateClientBuildings } from '../../client-dialog-lead-building/client-dialog-lead-building.selector';
import { initialState } from '../client-dialog-inventory.reducer';
import { selectClientLeadId } from '../../client-dialog-user-general/client-dialog-general.selectors';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';

@Component({
  selector: 'dq-client-dialog-inventory',
  templateUrl: './client-dialog-inventory.component.html',
  styleUrls: ['./client-dialog-inventory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogInventoryComponent implements OnInit, OnDestroy {

  constructor(
    public store: Store<State>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    private removeCommaPipe: DigiDecimalPipe,
    private dialog: MatDialog,
    private digiDecimalCommaPipe: DigiDecimalCommaPipe,
    private authService: AuthService) { }

  unsubscribe$: Subject<void> = new Subject();

  isMobile$: Observable<boolean>
  isMobile: boolean

  userRoles: string[];
  buildings$: Observable<IProperty[]>
  buildingOptions$: Observable<IBuilding[]>
  buildingOptions: IBuilding[]
  buildingSearchOptions$: Observable<IBuilding[]>
  buildingPropertyOptions$: Observable<BuildingProperty[]>
  buildings: IProperty[]
  units$: Observable<IUnit[]>
  buildingUnits$: Observable<IUnit[]>
  buildingPropertyFilterBuildingId$: Observable<number>

  isUnitsLoaded$: Observable<boolean>
  isBuildingUnitsLoaded$: Observable<boolean>
  isPropertiesLoaded$: Observable<boolean>

  bedOptions$: Observable<BedOption[]>
  bathOptions$: Observable<BathOption[]>

  occupencyStatusOptions = OCCUPENCY_OPTIONS;
  leasedStatusOptions = LEASED_OPTIONS;
  unitEconomicStatusOptions = UNIT_ECONOMIC_OPTIONS;

  unitQueryFilters$: Observable<InventoryQeurytFilter>
  unitQueryFilters: InventoryQeurytFilter
  unitQueryFilterBuildingIds$: Observable<number[]>;
  unitQueryFilterBuildingPropertyId$: Observable<number[]>
  buildingsUnitQueryFilters$: Observable<InventoryQeurytFilter>

  unitsPaginator$: Observable<IPagination>
  unitsPaginator: IPagination

  areaSlug: string

  unitsTotalRecords$: Observable<number>

  areaUri: string

  propertyNameControl = new UntypedFormControl();
  propertySearchOptions$: Observable<IProperty[]>

  panelOpenState = false;

  vacancyRange: UntypedFormGroup;
  madeReadyRange: UntypedFormGroup;
  isMadeReady$: Observable<boolean>;
  isNotReady$: Observable<boolean>;
  isReserved$: Observable<boolean>;

  leadBuildingIds$: Observable<LeadBuilding[]>;

  today: Date = new Date();
  bathFormControl: UntypedFormControl = new UntypedFormControl(0)

  // confirmDialogRef: MatDialogRef<InventoryUnitReserveConfirmComponent>;

  childPriceSliderComponent: ManualSliderComponent;
  childSizeSliderComponent: ManualSliderComponent;

  priceMinValueField: string = '0';
  priceMaxValueField: string = 'Unlimited';
  priceMinSliderValue: number = 0;
  priceMaxSliderValue: number = 10000;

  priceMaxLabel: string = 'Unlimited';
  isMaxPrice: boolean;

  sizeMinValueField: string = '0';
  sizeMaxValueField: string = 'Unlimited';
  sizeMinSliderValue: number = 0;
  sizeMaxSliderValue: number = 4000;
  sizeMaxLabel: string = 'Unlimited';
  isMaxSize: boolean;

  isBasicFiltersApplied: boolean;
  isMoreFiltersApplied: boolean;

  leadId$: Observable<number>;
  leadId: number;

  communitySearchControl = new UntypedFormControl();
  unitsSearchControl = new UntypedFormControl();
  buildingPropertyFilterBuildingIdFormControl: UntypedFormControl = new UntypedFormControl(-999)


  @ViewChild('priceManualSlider', { static: false }) set setChildPriceSliderComponent(childPriceSliderComponent: ManualSliderComponent) {
    if (childPriceSliderComponent !== undefined) {
      this.childPriceSliderComponent = childPriceSliderComponent;
    }
  }

  @ViewChild('sizeManualSlider', { static: false }) set setChildSizeSliderComponent(childSizeSliderComponent: ManualSliderComponent) {
    if (childSizeSliderComponent !== undefined) {
      this.childSizeSliderComponent = childSizeSliderComponent;
    }
  }

  @ViewChild('priceMinFilterBarInput', { static: false }) set childPriceMinFilterBarInput(childPriceMinFilterBarInput: ElementRef) {
    if (childPriceMinFilterBarInput !== undefined) {
      this.debouncePrice(childPriceMinFilterBarInput, 'keyup');
    }
  }
  @ViewChild('priceMaxFilterBarInput', { static: false }) set childPriceMaxFilterBarInput(childPriceMaxFilterBarInput: ElementRef) {
    if (childPriceMaxFilterBarInput !== undefined) {
      this.debouncePrice(childPriceMaxFilterBarInput, 'keyup');
    }
  }
  @ViewChild('sizeMinFilterBarInput', { static: false }) set childSizeMinFilterBarInput(childSizeMinFilterBarInput: ElementRef) {
    if (childSizeMinFilterBarInput !== undefined) {
      this.debounceSize(childSizeMinFilterBarInput, 'keyup');
    }
  }
  @ViewChild('sizeMaxFilterBarInput', { static: false }) set childSizeMaxFilterBarInput(childSizeMaxFilterBarInput: ElementRef) {
    if (childSizeMaxFilterBarInput !== undefined) {
      this.debounceSize(childSizeMaxFilterBarInput, 'keyup');
    }
  }

  priceOptions: Options = {
    floor: 0,
    ceil: 10000,
    step: 1000,
    animate: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          return 'Min';
        case LabelType.Ceil:
          return 'Max';
        default:
          return value.toString();
      }
    }
  };

  sizeOptions: Options = {
    floor: 0,
    ceil: 4000,
    step: 100,
    animate: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          return 'Min';
        case LabelType.Ceil:
          return 'Max';
        default:
          return value.toString();
      }
    }
  };

  bookAppointmentDialogRef: MatDialogRef<ClientDialogInventoryBookingComponent>;
  copiedElement: string;

  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.buildings$ = this.store.pipe(select(selectClientDialogInventoryBuildings))
    this.buildingOptions$ = this.store.pipe(select(selectClientDialogInventoryBuildingOptions))
    this.buildingPropertyOptions$ = this.store.pipe(select(selectClientDialogInventoryBuildingPropertyOptions))
    this.units$ = this.store.pipe(select(selectClientDialogInventoryUnits))
    this.isMadeReady$ = this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterIsMadeReady))
    this.isNotReady$ = this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterIsNotReady))
    this.isReserved$ = this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterIsReserved))
    this.buildingUnits$ = this.store.pipe(select(selectClientDialogInventoryBuildingUnits))
    this.isUnitsLoaded$ = this.store.pipe(select(selectClientDialogInventoryIsUnitsLoaded))
    this.isBuildingUnitsLoaded$ = this.store.pipe(select(selectClientDialogInventoryIsBuildingUnitsLoaded))
    this.isPropertiesLoaded$ = this.store.pipe(select(selectClientDialogInventoryisPropertiesLoaded))
    this.bedOptions$ = this.store.pipe(select(selectClientDialogInventoryQueryFilterBedOptions))
    this.bathOptions$ = this.store.pipe(select(selectClientDialogInventoryQueryFilterBathOptions))
    this.unitQueryFilters$ = this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilter))
    this.unitsPaginator$ = this.store.pipe(select(selectClientDialogInventoryAreaUnitsQueryPaginator))
    this.unitsTotalRecords$ = this.store.pipe(select(selectClientDialogInventoryAreaUnitsTotalRecords))
    this.buildingPropertyFilterBuildingId$ = this.store.pipe(select(selectClientDialogInventoryBuildingUnitsQueryFilterBuildingId))
    this.unitQueryFilterBuildingIds$ = this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterBuildingIds));
    this.unitQueryFilterBuildingPropertyId$ = this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterBuildingPropertyId))
    this.leadBuildingIds$ = this.store.pipe(select(selectclientDialogBuildingsStateClientBuildings));
    this.leadId$ = this.store.pipe(select(selectClientLeadId));

    this.leadBuildingIds$.subscribe(buildings => {
      const leadBuildingIds = buildings.map(building => +building.id)
      this.store.dispatch(actionClientDialogInventoryUpdateBuildingsFromLead({ leadBuildingIds }));
    })

    this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterBasicFilters)).subscribe(currentState => {
      this.isBasicFiltersApplied = initialState.inventoryUnits.unitsQueryFilter.baseFilters === currentState
    })

    this.store.pipe(select(selectClientDialogInventoryUnitsQueryFilterMoreFilters)).subscribe(currentState => {
      this.isMoreFiltersApplied = initialState.inventoryUnits.unitsQueryFilter.moreFilters === currentState
    })

    this.isMobile$.pipe(takeUntil(this.unsubscribe$)).subscribe(isMobile => {
      this.isMobile = isMobile
    })

    this.unitsPaginator$.pipe(takeUntil(this.unsubscribe$)).subscribe(unitsPaginator => {
      this.unitsPaginator = unitsPaginator
    })

    this.unitsSearchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(propertyName => {
      this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterSearchByPropertyName({ propertyName }))
    });

    this.buildingPropertyOptions$.pipe(takeUntil(this.unsubscribe$), skip(1)).subscribe(buildingPropertyOptions => {
    })

    this.unitQueryFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe(unitQueryFilters => {
      this.unitQueryFilters = unitQueryFilters
    })
    this.unitQueryFilterBuildingIds$.pipe(takeUntil(this.unsubscribe$)).subscribe(buildingIds => {
      // this.store.dispatch(actionInventoryUnitsPaginatorReset())
      this.store.dispatch(actionClientDialogInventoryUnitsBuildingPropertyRequest({ buildingIds }));
    })
    this.buildings$.pipe(takeUntil(this.unsubscribe$)).subscribe(buildings => {
      this.buildings = buildings
      this.propertySearchOptions$ = this.propertyNameControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    })

    this.buildingOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(buildingOptions => {
      this.buildingOptions = buildingOptions
      this.buildingSearchOptions$ = this.communitySearchControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterBuildingSearchOption(value))
      );
    })

    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe$)).subscribe(roles => {
      this.userRoles = roles;
    });

    this.bathFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(bath => {
      this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterBathNumber({ bath }))
    })

    this.buildingPropertyFilterBuildingIdFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(buildingId => {
      if (buildingId > 0) {
        this.store.dispatch(actionClientDialogInventoryUpdateBuildingPropertyFilterBuildingId({ buildingId }));
      }
    })

    this.unitQueryFilters$.pipe(startWith(), takeUntil(this.unsubscribe$)).subscribe(queryFilter => {
      // this.store.dispatch(actionClientDialogInventoryUnitsPaginatorReset())
      let paginator = this.unitsPaginator

      this.store.dispatch(actionClientDialogInventoryAreaUnitsRequested({ queryFilter: queryFilter, paginator: { page: 1, pageSize: 20 } }))
    })


    this.store.dispatch(actionClientDialogInventoryAreaBuildingOptionsRequested());

    this.buildingPropertyFilterBuildingId$.pipe(takeUntil(this.unsubscribe$)).subscribe(buildingId => {
      if (buildingId > 0) {
        this.store.dispatch(actionClientDialogInventoryAreaBuildingsRequested({ buildingId }));
      }
    })

    this.madeReadyRange = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl()
    });

    this.madeReadyRange.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(values => {
      if (values.start && values.end) {
        let start: string = values.start;
        let end: string = values.end;
        this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyDate({ start, end }));
      }
    });

    this.vacancyRange = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl()
    });

    this.vacancyRange.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(values => {
      if (values.start && values.end) {
        let start: string = values.start;
        let end: string = values.end;
        this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterVacancyDate({ start, end }));
      }
    });

    this.leadId$.pipe(takeUntil(this.unsubscribe$)).subscribe(leadId => {
      this.leadId = leadId
    });
  }

  openBookAppointmentDialog(buildingId: number) {
    this.bookAppointmentDialogRef = this.dialog.open(
      ClientDialogInventoryBookingComponent,
      {
        width: this.isMobile ? '100vw' : '75vw',
        height: '100dvh',
        maxWidth: '100vw',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: false,
        data: {
          buildingId: buildingId
        }
      }
    );
  }

  startNewApplication(unit: IUnit) {
    let madeReadyDate: Date = new Date(unit.madeReadyDate);
    this.clientDialogMatDialogService.openInitRentalRequest(unit.id, unit.buildingId, this.leadId, madeReadyDate, unit.price, `${unit.yardiUnitIdentifier} - ${unit.buildingName}`);
  }

  isUnitsMadeReady($event: MatCheckboxChange) {
    let checked = $event.checked
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyCheckbox({ checked }))
  }
  isUnitsNotReady($event: MatCheckboxChange) {
    let checked = $event.checked
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterNotReadyCheckbox({ checked }))
  }

  isReserved($event: MatCheckboxChange) {
    let checked = $event.checked
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterIsReservedCheckbox({ checked }))
  }

  communitiesChecked($event: MatCheckboxChange) {
    let buildingId: number = +$event.source.value;
    let checked: boolean = $event.checked;
    if (!checked) {
      this.store.dispatch(actionClientDialogInventoryFilterProptertyIdsToRemoveRequest({ buildingId }))
    }
    this.store.dispatch(actionClientDialogInventoryUnitsBuildingIdChange({ buildingId, checked }));
  }

  isCommunityChecked(buildingId: number) {
    return this.unitQueryFilters.baseFilters.buildingIds.some(x => x == buildingId);
  }

  propertiesChecked($event: MatCheckboxChange) {
    let buildingPropertyId: number = +$event.source.value;
    let checked: boolean = $event.checked;
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterBuildingProperty({ buildingPropertyId, checked }));
  }

  isPropertyChecked(propertyId: number) {
    return this.unitQueryFilters.baseFilters.buildingPropertyIds.some(x => x == propertyId);
  }

  unitsFilterReset() {
    this.vacancyRange.reset();
    this.madeReadyRange.reset();
    this.communitySearchControl.setValue('');
    this.priceMinValueField = '0';
    this.priceMaxValueField = 'Unlimited';
    this.priceMinSliderValue = 0;
    this.priceMaxSliderValue = 10000;
    this.bathFormControl.reset(0);
    this.store.dispatch(actionClientDialogInventoryUnitsFilterReset());
  }

  private _filter(value: string): IProperty[] {
    const filterValue = value.toLowerCase();
    return this.buildings.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  private _filterBuildingSearchOption(value: string): IBuilding[] {
    const filterValue = value.toLowerCase();
    return this.buildingOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  unitsBathFilterChange($event: MatCheckboxChange) {
    let bathCount: number = +$event.source.value;
    let checked: boolean = $event.source.checked;
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterBath({ bathCount, checked }));
  }

  unitsBedFilterChange($event: MatCheckboxChange) {
    let bedCount: number = +$event.source.value;
    let checked: boolean = $event.source.checked;
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterBed({ bedCount, checked }));
  }

  unitsOccupencyStatusFilterChange($event: MatCheckboxChange) {
    let occupencyStatusValue: string = $event.source.value;
    let checked: boolean = $event.source.checked;
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterOccupencyStatus({ occupencyStatusValue, checked }));
  }

  unitsLeasedStatusFilterChange($event: MatCheckboxChange) {
    let leasedStatusValue: string = $event.source.value;
    let checked: boolean = $event.source.checked;
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterLeasedStatus({ leasedStatusValue, checked }));
  }

  unitsEconomicStatusFilterChange($event: MatCheckboxChange) {
    let economicStatusValue: string = $event.source.value;
    let checked: boolean = $event.source.checked;
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterEconomicStatus({ economicStatusValue, checked }));
  }

  isOccupencyStatusChecked(occupancyStatusValue: string) {
    return this.unitQueryFilters.moreFilters.occupencyStatuses.some(x => x == occupancyStatusValue);
  }

  isLeasedStatusChecked(leasedStatusValue: string) {
    return this.unitQueryFilters.moreFilters.leasedStatuses.some(x => x == leasedStatusValue);
  }

  isEconomicStatusChecked(economicStatusValue: string) {
    return this.unitQueryFilters.moreFilters.economicStatuses.some(x => x == economicStatusValue);
  }

  isMoreFiltersChecked() {
    return this.unitQueryFilters.moreFilters;
  }

  isBedFilterChecked(bedOptionId: number) {
    return this.unitQueryFilters.baseFilters.beds.some(x => x == bedOptionId);
  }

  debouncePrice(elementRef: ElementRef, event: string) {
    return fromEvent(elementRef.nativeElement, event).pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      // , filter(res => res.length > 3)
      // Time in milliseconds between key events
      , debounceTime(1000)
      // If previous query is diffent from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.validateAndUpdatePriceField();
      let priceOptionValues: any = {
        priceMinValue: this.priceMinSliderValue,
        priceMaxValue: this.priceMaxSliderValue
      }
      this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterPrice({ minPrice: this.priceMinSliderValue, maxPrice: this.priceMaxSliderValue }))
    });
  }

  debounceSize(elementRef: ElementRef, event: string) {
    return fromEvent(elementRef.nativeElement, event).pipe(
      takeUntil(this.unsubscribe$),
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      // , filter(res => res.length > 3)
      // Time in milliseconds between key events
      , debounceTime(1000)
      // If previous query is diffent from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.validateAndUpdateSizeField();
      let sizeOptionValues: any = {
        isMax: this.isMaxSize,
        sizeMinValue: this.sizeMinSliderValue,
        sizeMaxValue: this.sizeMaxSliderValue
      }


      this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterSize({ minSize: this.sizeMinSliderValue, maxSize: this.sizeMaxSliderValue }))

    });
  }

  onPriceSliderPopupMenuOpened() {

    // 2019-06-11 UPDATE: This use-case is now resolved on newest browser version which support ResizeObserver API
    // (https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver) - at this time it is Chrome >= 64 and Opera >= 51.
    // For other browsers, or older browser versions, the workaround with manualRefresh still applies.
    if (isPlatformBrowser(this.platformId)) {
      new Promise(resolve => {
        setTimeout(() => {
          resolve(0);
        }, 200)
      })
        .then(value => {
          this.childPriceSliderComponent.manualRefresh.emit();
        })
    }

  }

  onSizeSliderPopupMenuOpened() {

    // 2019-06-11 UPDATE: This use-case is now resolved on newest browser version which support ResizeObserver API
    // (https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver) - at this time it is Chrome >= 64 and Opera >= 51.
    // For other browsers, or older browser versions, the workaround with manualRefresh still applies.
    if (isPlatformBrowser(this.platformId)) {
      new Promise(resolve => {
        setTimeout(() => {
          resolve(0);
        }, 200)
      })
        .then(value => {
          this.childSizeSliderComponent.manualRefresh.emit();
        })
    }

  }

  validateAndUpdatePriceField() {
    this.priceMinValueField = this.removeCommaPipe.transform(this.priceMinValueField);
    this.priceMaxValueField = this.removeCommaPipe.transform(this.priceMaxValueField);
    if (!Number.isInteger(+this.priceMinValueField) || this.priceMinValueField === '') {
      this.priceMinValueField = this.priceOptions.floor.toString();
      this.priceMinSliderValue = this.priceOptions.floor;
    }
    else {
      //Remove leading 0
      this.priceMinValueField = String(parseInt(this.priceMinValueField, 10));
      if (+this.priceMinValueField < 0) {
        this.priceMinValueField = this.priceOptions.floor.toString();
        this.priceMinSliderValue = this.priceOptions.floor;
      }
      if (+this.priceMinValueField > +this.priceMinValueField) {
        let tmpPrice: string = this.priceMaxValueField;
        this.priceMaxValueField = this.priceMinValueField
        this.priceMinValueField = tmpPrice;
      }
      this.priceMinSliderValue = +this.priceMinValueField;
      this.priceMaxSliderValue = +this.priceMaxValueField;
    }

    if (this.priceMaxValueField !== this.priceMaxLabel) {
      if (!Number.isInteger(+this.priceMaxValueField) || this.priceMaxValueField === '') {
        this.isMaxPrice = true;
        this.priceMaxValueField = this.priceMaxLabel;
        this.priceMaxSliderValue = this.priceOptions.ceil;
      }
      else {
        //Remove leading 0
        this.isMaxPrice = false;
        this.priceMaxValueField = String(parseInt(this.priceMaxValueField, 10));
        if (+this.priceMinValueField > +this.priceMaxValueField) {
          let tmpPrice: string = this.priceMaxValueField;
          this.priceMaxValueField = this.priceMinValueField
          this.priceMinValueField = tmpPrice;
        }
        this.priceMinSliderValue = +this.priceMinValueField;
        this.priceMaxSliderValue = +this.priceMaxValueField;
      }
    }
    else {
      this.priceMaxSliderValue = this.priceOptions.ceil;
    }

    this.priceMinValueField = this.digiDecimalCommaPipe.transform(parseInt(this.priceMinValueField))
    if (!isNaN(parseInt(this.priceMaxValueField))) {
      this.priceMaxValueField = this.digiDecimalCommaPipe.transform(parseInt(this.priceMaxValueField))
    }

  }


  onUserChangePriceSlider($event: ChangeContext) {
    this.priceMinValueField = this.digiDecimalCommaPipe.transform($event.value);
    this.priceMinSliderValue = this.removeCommaPipe.transform(String($event.value));
    this.priceMaxValueField = this.digiDecimalCommaPipe.transform($event.highValue);
    this.priceMaxSliderValue = this.removeCommaPipe.transform(String($event.highValue));

    this.isMaxPrice = false;
    if (this.priceMaxSliderValue === this.priceOptions.ceil) {
      this.priceMaxValueField = this.priceMaxLabel;
      this.isMaxPrice = true;
    }
  }

  validateAndUpdateSizeField() {
    this.sizeMinValueField = this.removeCommaPipe.transform(this.sizeMinValueField);
    this.sizeMaxValueField = this.removeCommaPipe.transform(this.sizeMaxValueField);
    if (!Number.isInteger(+this.sizeMinValueField) || this.sizeMinValueField === '') {
      this.sizeMinValueField = this.sizeOptions.floor.toString();
      this.sizeMinSliderValue = this.sizeOptions.floor;
    }
    else {
      //Remove leading 0
      this.sizeMinValueField = String(parseInt(this.sizeMinValueField, 10));
      if (+this.sizeMinValueField < 0) {
        this.sizeMinValueField = this.sizeOptions.floor.toString();
        this.sizeMinSliderValue = this.sizeOptions.floor;
      }
      if (+this.sizeMinValueField > +this.sizeMinValueField) {
        let tmpSize: string = this.sizeMaxValueField;
        this.sizeMaxValueField = this.sizeMinValueField
        this.sizeMinValueField = tmpSize;
      }
      this.sizeMinSliderValue = +this.sizeMinValueField;
      this.sizeMaxSliderValue = +this.sizeMaxValueField;
    }

    if (this.sizeMaxValueField !== this.sizeMaxLabel) {
      if (!Number.isInteger(+this.sizeMaxValueField) || this.sizeMaxValueField === '') {
        this.sizeMaxValueField = this.sizeMaxLabel;
        this.sizeMaxSliderValue = this.sizeOptions.ceil;
        this.isMaxSize = true;
      }
      else {
        //Remove leading 0
        this.sizeMaxValueField = String(parseInt(this.sizeMaxValueField, 10));
        this.isMaxSize = false;
        if (+this.sizeMinValueField > +this.sizeMaxValueField) {
          let tmpsize: string = this.sizeMaxValueField;
          this.sizeMaxValueField = this.sizeMinValueField
          this.sizeMinValueField = tmpsize;
        }
        this.sizeMinSliderValue = +this.sizeMinValueField;
        this.sizeMaxSliderValue = +this.sizeMaxValueField;
      }
    }
    else {
      this.sizeMaxSliderValue = this.sizeOptions.ceil;
    }

    this.sizeMinValueField = this.digiDecimalCommaPipe.transform(parseInt(this.sizeMinValueField))
    if (!isNaN(parseInt(this.sizeMaxValueField))) {
      this.sizeMaxValueField = this.digiDecimalCommaPipe.transform(parseInt(this.sizeMaxValueField))
    }
  }


  onUserChangePriceSliderEnd($event: ChangeContext) {
    this.onUserChangePriceSlider($event);
    let priceOptionValues = {
      isMax: this.isMaxPrice,
      priceMinValue: this.priceMinSliderValue,
      priceMaxValue: this.priceMaxSliderValue
    }
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterPrice({ minPrice: this.priceMinSliderValue, maxPrice: this.priceMaxSliderValue }))
  }

  onPriceFocusIn() {
    if (this.priceMaxValueField === 'Unlimited') {
      this.priceMaxValueField = '';
      this.priceMaxSliderValue = this.priceOptions.ceil;
      this.isMaxPrice = true;
    }
  }

  onPriceFocusOut() {
    this.validateAndUpdatePriceField();
  }

  onSizeFocusOut() {
    this.validateAndUpdateSizeField();

  }

  onSizeFocusIn() {
    if (this.sizeMaxValueField === 'Unlimited') {
      this.sizeMaxValueField = '';
      this.sizeMaxSliderValue = this.sizeOptions.ceil;
      this.isMaxSize = true;
    }
  }

  onUserChangeSizeSlider($event: ChangeContext) {
    this.sizeMinValueField = this.digiDecimalCommaPipe.transform($event.value);
    this.sizeMinSliderValue = this.removeCommaPipe.transform(String($event.value));
    this.sizeMaxValueField = this.digiDecimalCommaPipe.transform($event.highValue);
    this.sizeMaxSliderValue = this.removeCommaPipe.transform(String($event.highValue));

    this.isMaxSize = false;
    if (this.sizeMaxSliderValue === this.sizeOptions.ceil) {
      this.sizeMaxValueField = this.sizeMaxLabel;
      this.isMaxSize = true;
    }
  }

  onUserChangeSizeSliderEnd($event: ChangeContext) {
    this.onUserChangeSizeSlider($event);
    this.store.dispatch(actionClientDialogInventoryUpdateUnitsQueryFilterSize({ minSize: this.sizeMinSliderValue, maxSize: this.sizeMaxSliderValue }))
  }


  getUnitsForProperty(propertyId: number) {
    this.store.dispatch(actionClientDialogInventoryAreaBuildingUnitsRequested({ propertyId }))
  }

  resetBuildingUnits() {
    this.store.dispatch(actionClientDialogInventoryBuildingUnitsReset())
  }

  unitsPageChanged(currentPage: number) {
    this.store.dispatch(actionClientDialogInventoryAreaUnitsRequested({ queryFilter: this.unitQueryFilters, paginator: { page: currentPage, pageSize: this.unitsPaginator.pageSize } }));
  }

  hasAccessToBooking(): Observable<boolean> {
    return this.authService.isUserInRole([SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.ApplicationCreator])
  }

  // openConfirmDialog(unit: IUnit): void {
  //   this.confirmDialogRef = this.dialog.open(
  //     InventoryUnitReserveConfirmComponent,
  //     {
  //       width: this.isMobile ? '100vw' : '575px',
  //       height: this.isMobile ? '100dvh' : '',
  //       maxWidth: '100vw',
  //       autoFocus: true,
  //       panelClass: 'no-padding-dialog-container',
  //       disableClose: false,
  //       data: {
  //         type: unit.isReserved ? 'release' : 'reserve',
  //         yardiBuildingPropertyId: unit.yardiBuildingPropertyId,
  //         yardiUnitIdentifier: unit.yardiUnitIdentifier,
  //         buildingPropertyInventoryId: unit.id
  //       }
  //     }
  //   );

  //   this.confirmDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
  //     if (confirm == true) {

  //     }
  //   });
  // }

  //Returns a string containing the unit's amenities type : description pairs, with a breakline between each pair
  displayAmenities(amenities: IUnitAmenity[]) {
    let result = "";
    amenities.forEach((amenity, index) => {
      result += amenity.type + " : " + amenity.description;
      if (index < amenities.length - 1) {
        result += "\n";  // Add a new line character
      }
    });
    return result;
  }

  openUnitDescriptionDialog(desc: string) {
    this.clientDialogMatDialogService.openUnitDescriptionDailog(desc);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.store.dispatch(actionClientDialogInventoryUnitsStateReset())
  }

}


