import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { NotificationService } from "app/core/core.module";
import { Task, TaskAssignUser } from "app/shared/models/task.model";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ClientDialogService } from "../../client-dialog.service";
import { State } from "../../client-dialog.state";
import { selectClientLeadId } from "../client-dialog-user-general/client-dialog-general.selectors";
import { clientDialogTaskActions, clientDialogTaskFailActions } from "./client-dialog-task.action";

@Injectable()
export class ClientDialogTaskEffects {
    constructor(
        private store: Store<State>,
        private actions$: Actions,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService,
    ) { }

    requestTasks$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTaskTasksRequested
                ),
                switchMap(action =>
                    this.clientDialogService.getTasksByLeadId(action.leadId).pipe(
                        map(response => {
                            if (response.status == 1) {
                                let tasks: Task[] = response.data
                                return clientDialogTaskActions.actionClientDialogTaskTasksLoaded({ tasks })
                            } else {
                                return clientDialogTaskFailActions.actionClientDialogTaskTasksLoadFailed()
                            }
                        }), catchError(error => of(clientDialogTaskFailActions.actionClientDialogTaskTasksLoadFailed()))
                    )
                )
            )
    )


    requesttaskAssignUsers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskAssignUsersRequested
                ),
                switchMap(action =>
                    this.clientDialogService.getTaskAssignUserList().pipe(
                        map(response => {
                            if (response.status == 1) {
                                let taskAssignUsers: TaskAssignUser[] = response.data
                                return clientDialogTaskActions.actionClientDialogTasktaskAssignUsersLoaded({ taskAssignUsers })
                            } else {
                                this.notificationService.error(response.message);
                                return clientDialogTaskFailActions.actionClientDialogTasktaskAssignUsersFailed()
                            }
                        }), catchError(err => of(clientDialogTaskFailActions.actionClientDialogTasktaskAssignUsersFailed()))
                    )
                )
            )
    )


    createTask$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskCreateRequested
                ),
                switchMap(action =>
                    this.clientDialogService.createTask(action.param).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return clientDialogTaskActions.actionClientDialogTasktaskCreateSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return clientDialogTaskFailActions.actionClientDialogTasktaskCreateFailed()
                            }
                        }), catchError(err => {
                            return of(clientDialogTaskFailActions.actionClientDialogTasktaskCreateFailed())
                        })
                    )
                )
            )
    )

    createTaskSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskCreateSucceed
                ),
                withLatestFrom(
                    this.store.pipe(select(selectClientLeadId)),
                ),
                switchMap(([action, leadId]) =>
                    [
                        clientDialogTaskActions.actionClientDialogTaskTasksRequested({ leadId })
                    ]
                )
            )
    )

    updateTask$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskUpdateRequested
                ),
                switchMap(action =>
                    this.clientDialogService.updateTask(action.param).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return clientDialogTaskActions.actionClientDialogTasktaskUpdateSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return clientDialogTaskFailActions.actionClientDialogTasktaskUpdateFailed()
                            }
                        }), catchError(err => {
                            return of(clientDialogTaskFailActions.actionClientDialogTasktaskUpdateFailed())
                        }
                        )
                    )
                )
            )
    )

    updateTaskSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskUpdateSucceed
                ),
                withLatestFrom(
                    this.store.pipe(select(selectClientLeadId)),
                ),
                switchMap(([action, leadId]) =>
                    [
                        clientDialogTaskActions.actionClientDialogTaskTasksRequested({ leadId })
                    ]
                )
            )
    )

    deleteTask$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskDeleteRequested
                ),
                switchMap(action =>
                    this.clientDialogService.deleteTask(action.taskId).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return clientDialogTaskActions.actionClientDialogTasktaskDeleteSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return clientDialogTaskFailActions.actionClientDialogTasktaskDeleteFailed()
                            }
                        }), catchError(err => {
                            return of(clientDialogTaskFailActions.actionClientDialogTasktaskDeleteFailed())
                        }
                        )
                    )
                )
            )
    )

    deleteTaskSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTasktaskDeleteSucceed
                ),
                withLatestFrom(
                    this.store.pipe(select(selectClientLeadId)),
                ),
                switchMap(([action, leadId]) =>
                    [
                        clientDialogTaskActions.actionClientDialogTaskTasksRequested({ leadId })
                    ]
                )
            )
    )

    toggleTaskStatus$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    clientDialogTaskActions.actionClientDialogTaskTaskStatusToggle
                ),
                switchMap(action =>
                    this.clientDialogService.toggleUserTasKComnplateStatus(action.taskId).pipe(
                        map(response => {
                            if (response.status == 1) {
                                return clientDialogTaskActions.actionClientDialogTaskTaskStatusToggleSucceed()
                            } else {
                                this.notificationService.error(response.message);
                                return clientDialogTaskFailActions.actionClientDialogTaskTaskStatusToggleFailed({ taskId: action.taskId })
                            }
                        }), catchError(err => {
                            return of(clientDialogTaskFailActions.actionClientDialogTaskTaskStatusToggleFailed({ taskId: action.taskId }))
                        }
                        )
                    )
                )
            )
    )

}