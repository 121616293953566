import { createAction, props } from "@ngrx/store"
import { ITimelineElement } from "app/shared/components/timeline/timeline.model"

export const actionUnifiedInboxAllActivitiesRequest = createAction(
    '[Unified Inbox Activity] All Activities Request',
    props<{ leadId: number, pageNo: number, pageSize: number, itemCountPage: number, isLoadingMore?: boolean }>()
)

export const actionUnifiedInboxAllActivitiesSuccessed = createAction(
    '[Unified Inbox Activity] All Activities Successed',
    props<{ activities: ITimelineElement[], pageNo: number, pageSize: number, totalRecords: number }>()
)

export const actionUnifiedInboxAllActivitiesFailed = createAction(
    '[Unified Inbox Activity] All Activities Failed',
)

export const actionUnifiedInboxAddActivityReceived = createAction(
    '[Unified Inbox Activity] Add Activity Received',
    props<{ activity: ITimelineElement }>()
)

export const actionUnifiedInboxAddActivityNotAdded = createAction(
    '[Unified Inbox Activity] Add Activity Not Added'
)

export const actionUnifiedInboxAddActivityLoaded = createAction(
    '[Unified Inbox Activity] Add Activity Loaded',
    props<{ activity: ITimelineElement }>()
)

export const actionUnifiedInboxAddActivityFailed = createAction(
    '[Unified Inbox Activity] Add Activity Failed',
)

export const actionUnifiedInboxAddNoteReceived = createAction(
    '[Unified Inbox Text Message] Add Note Received',
    props<{ activity: ITimelineElement }>()
)

export const actionUnifiedInboxActivityStateReset = createAction(
    '[Unified Inbox Activity] State Reset'
)

export const unifiedInboxActivityActions = {
    actionUnifiedInboxAllActivitiesRequest,
    actionUnifiedInboxAllActivitiesSuccessed,
    actionUnifiedInboxAllActivitiesFailed,
    actionUnifiedInboxActivityStateReset,
    actionUnifiedInboxAddActivityReceived,
    actionUnifiedInboxAddActivityLoaded,
    actionUnifiedInboxAddActivityFailed
}