<div class="form-div">
  <form [formGroup]="addApplicationFormGroup">
    <div class="row">
      <div class="dq-flex-centered col-12">
        <button (click)="onUseLeadInfo()" mat-stroked-button>
          <span>Use current lead information</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="dq-flex-centered radio-group col-12">
        <mat-radio-group formControlName="applicantType">
          <mat-radio-button class="radio-btn" value="Tenant">Tenant</mat-radio-button>
          <mat-radio-button class="radio-btn" value="Occupant">Occupant</mat-radio-button>
          <mat-radio-button class="radio-btn" value="Guarantor">Guarantor</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Applicant First Name</mat-label>
        <input type="text" matInput formControlName="applicantFirstName" placeholder="Applicant First Name"
          name="applicantFirstName">
        <mat-error *ngIf="addApplicationFormGroup.controls['applicantFirstName'].hasError('required')">
          A first name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Applicant Last Name</mat-label>
        <input type="text" matInput formControlName="applicantLastName" placeholder="Applicant Last Name"
          name="applicantLastName">
        <mat-error *ngIf="addApplicationFormGroup.controls['applicantLastName'].hasError('required')">
          A last name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="applicantEmail" placeholder="Email" name="applicantEmail">
        <mat-error *ngIf="addApplicationFormGroup.controls['applicantEmail'].hasError('required')">
          An email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Phone Number</mat-label>
        <ngx-mat-intl-tel-input (countryChanged)="countryChanged($event)" [cssClass]="'custom'"
          [preferredCountries]="['ca', 'us']" placeholder="Enter Phone Number" [enablePlaceholder]="false"
          [enableSearch]="true" formControlName="applicantPhoneNumber" name="applicantPhoneNumber"
          #phoneNumber></ngx-mat-intl-tel-input>
        <mat-error *ngIf="addApplicationFormGroup.controls['applicantPhoneNumber'].hasError('required')">
          A phone number is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Employement Status</mat-label>
        <mat-select formControlName="applicantEmploymentStatus">
          <mat-option *ngFor="let employmentStatus of employmentStatusOptions" [value]="employmentStatus.value">
            {{employmentStatus.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row ">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Prefered Language</mat-label>
        <mat-select formControlName="applicantLanguage" name="applicantLanguage">
          <mat-option *ngFor="let language of USER_LANGUAGES" [value]="language.id">
            {{language.label}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addApplicationFormGroup.controls['applicantLanguage'].hasError('required')">
          Prefered language is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="option-tilte">
      Application Requirements
    </div>
    <div class="row option">
      <mat-slide-toggle (change)="onManualSliderToggleChange()"
        formControlName="isManualDocument">Is this a manual application?</mat-slide-toggle>
    </div>
    <div class="row option">
      <mat-slide-toggle (change)="onCheckSliderToggleChange()" formControlName="isDepositPayer">Is
        this applicant paying the deposit?</mat-slide-toggle>
    </div>
  </form>
</div>
<ng-container *ngIf="(errorMsg$ | async) != ''">
  <div class="row">
    <div class="error-message-container">
      <span class="error-message">
        {{errorMsg$ | async}}
      </span>
    </div>
  </div>
</ng-container>
<div class="row">
  <div class="col-12 button-div">
    <dq-spinner-button style="width: 100%;" [disabled]="!addApplicationFormGroup.valid"
      class="generic-button confirm-button" type="button" (click)="addNewApplication()"
      [options]="spinnerCreateButtonOptions"></dq-spinner-button>
  </div>
</div>