import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { LeadBuilding } from "../../../../shared/models/lead-info-models";
import { ClientDialogService } from "../../client-dialog.service";
import { clientDialogBuildingActions } from "./client-dialog-lead-building.action";

@Injectable()
export class ClientDialogBuildingsEffects {
    constructor(
        private actions$: Actions,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    requestLeadBuildings = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBuildingActions.actionClientDialogLeadBuildingsRequested),
            switchMap(action =>
                this.clientDialogService.getLeadBuildings(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildings = response.data as LeadBuilding[]
                            return clientDialogBuildingActions.actionClientDialogLeadBuildingsLoaded({ buildings })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBuildingActions.actionClientDialogLeadBuildingsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogBuildingActions.actionClientDialogLeadBuildingsFailed()))
                )
            )
        )
    );

    requestClientBuildingOptions = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBuildingActions.actionClientDialogBuildingOptionsRequested),
            switchMap(action =>
                this.clientDialogService.getLeadBuildingOptions().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildings = response.data.objectsList as LeadBuilding[]
                            return clientDialogBuildingActions.actionClientDialogBuildingOptionsLoaded({ buildings })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBuildingActions.actionClientDialogBuildingOptionsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogBuildingActions.actionClientDialogBuildingOptionsFailed()))
                )
            )
        )
    );

    initialBuildingCheckStatus = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBuildingActions.actionClientDialogBuildingOptionsLoaded),
            switchMap(action =>
                [clientDialogBuildingActions.actionClientDialogBuildingOptionsCheckStateInitialize()]
            )
        )
    )

    deleteClientBuilding = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBuildingActions.actionClientDialogClientBuildingDeleteRequested),
            switchMap(action =>
                this.clientDialogService.deleteClientBuilding(action.leadId, action.buildingId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildings: LeadBuilding[] = response.data
                            return clientDialogBuildingActions.actionClientDialogClientBuildingDeleteSuccessfully({ buildings })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBuildingActions.actionClientDialogClientBuildingDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogBuildingActions.actionClientDialogClientBuildingDeleteFailed())
                    })
                )
            )
        )
    );

    updateClientBuilding = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogBuildingActions.actionClientDialogBuildingsUpdateRequested),
            switchMap(action =>
                this.clientDialogService.updateClientBuildings(action.id, action.buildings).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let buildings: LeadBuilding[] = response.data
                            return clientDialogBuildingActions.actionClientDialogBuildingsUpdateSuccessfully({ buildings })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogBuildingActions.actionClientDialogBuildingsUpdateFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogBuildingActions.actionClientDialogBuildingsUpdateFailed())
                    })
                )
            )
        )
    );

}