import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogRepsState } from "../../client-dialog.state";
import { clientDialogRepActions } from "./client-dialog-lead-rep.action";

const initialState: ClientDialogRepsState = {
    isRepsLoaded: false,
    isRepOptionsLoaded: false,
    clientReps: [],
    repOptions: [],
    isRepSubmitting: false,
    isUpdateSuccessfully: false
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogRepActions.actionClientDialogLeadRepsRequested,
        (state) => ({
            ...state,
            isRepsLoaded: false
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogLeadRepsLoaded,
        (state, { reps }) => ({
            ...state,
            isRepsLoaded: true,
            clientReps: reps
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogLeadRepsFailed,
        (state) => ({
            ...state,
            isRepsLoaded: true
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepOptionsLoaded,
        (state, { reps }) => ({
            ...state,
            repOptions: reps,
            isRepOptionsLoaded: true
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepOptionsFailed,
        (state) => ({
            ...state,
            isRepOptionsLoaded: true
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsUpdate,
        (state, { reps }) => ({
            ...state,
            isRepsLoaded: true,
            clientReps: reps
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepOptionsCheckStateInitialize,
        (state) => ({
            ...state,
            repOptions: state.repOptions.map(x => state.clientReps.map(y => y.id).includes(x.id) ? { ...x, isChecked: true } : { ...x, isChecked: false })
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsRepIsCheckedUpdate,
        (state, { checked, rep }) => ({
            ...state,
            repOptions: state.repOptions.map(x => x.id == rep.id ? { ...x, isChecked: checked } : x)
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsUpdateRequested,
        (state) => ({
            ...state,
            isRepSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogBatchRepsAddRequested,
        clientDialogRepActions.actionClientDialogBatchRepsRemoveRequested,
        (state) => ({
            ...state,
            isRepSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsUpdateSuccessfully,
        (state, { reps }) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: true,
            clientReps: reps
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogBatchRepsAddSuccessfully,
        clientDialogRepActions.actionClientDialogBatchRepsRemoveSuccessfully,
        (state) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: true,
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogClientRepDeleteSuccessfully,
        (state, { reps }) => ({
            ...state,
            clientReps: reps
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsUpdateFailed,
        (state) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogBatchRepsAddFailed,
        clientDialogRepActions.actionClientDialogBatchRepsRemoveFailed,
        (state) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsCreateUpdateSuccessfulStateReset,
        (state) => ({
            ...state,
            isUpdateSuccessfully: false
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogClientRepsFailed,
        (state) => ({
            ...state,
            isRepsLoaded: true
        })
    ),
    on(
        clientDialogRepActions.actionClientDialogRepsReset,
        (state,) => (initialState)
    ),
)

export const clientDialogRepsReducer = (
    state: ClientDialogRepsState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
