import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NotificationService } from "app/core/core.module";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { AssignedUser } from "../../../../shared/models/lead-info-models";
import { actionClientDialogLeadCallFromPhoneNumbersRequest } from "../../client-dialog.actions";
import { ClientDialogService } from "../../client-dialog.service";
import { clientDialogRepActions } from "./client-dialog-lead-rep.action";

@Injectable()
export class ClientDialogRepsEffects {
    constructor(
        private actions$: Actions,
        private clientDialogService: ClientDialogService,
        private notificationService: NotificationService
    ) { }

    requestLeadReps = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogLeadRepsRequested),
            switchMap(action =>
                this.clientDialogService.getLeadReps(action.leadId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let reps = response.data as AssignedUser[]
                            return clientDialogRepActions.actionClientDialogLeadRepsLoaded({ reps })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogRepActions.actionClientDialogLeadRepsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogRepActions.actionClientDialogLeadRepsFailed()))
                )
            )
        )
    );

    requestClientRepOptions = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogRepOptionsRequested),
            switchMap(action =>
                this.clientDialogService.getLeadRepOptions().pipe(
                    map(response => {
                        if (response.status == 1) {
                            let users = response.data as AssignedUser[]
                            return clientDialogRepActions.actionClientDialogRepOptionsLoaded({ reps: users })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogRepActions.actionClientDialogRepOptionsFailed()
                        }
                    }),
                    catchError(error => of(clientDialogRepActions.actionClientDialogRepOptionsFailed()))
                )
            )
        )
    );

    initialRepCheckStatus = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogRepOptionsLoaded),
            switchMap(action =>
                [clientDialogRepActions.actionClientDialogRepOptionsCheckStateInitialize()]
            )
        )
    )

    deleteClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogClientRepDeleteRequested),
            switchMap(action =>
                this.clientDialogService.deleteClientRep(action.leadId, action.repId).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let users: AssignedUser[] = response.data
                            return clientDialogRepActions.actionClientDialogClientRepDeleteSuccessfully({ leadId: action.leadId, reps: users })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogRepActions.actionClientDialogClientRepDeleteFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogRepActions.actionClientDialogClientRepDeleteFailed())
                    })
                )
            )
        )
    );

    updateClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogRepsUpdateRequested),
            switchMap(action =>
                this.clientDialogService.updateClientReps(action.id, action.reps).pipe(
                    map(response => {
                        if (response.status == 1) {
                            let users: AssignedUser[] = response.data
                            return clientDialogRepActions.actionClientDialogRepsUpdateSuccessfully({ leadId: action.id, reps: users })
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogRepActions.actionClientDialogRepsUpdateFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogRepActions.actionClientDialogRepsUpdateFailed())
                    })
                )
            )
        )
    );

    addBatchClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogBatchRepsAddRequested),
            switchMap(action =>
                this.clientDialogService.addBatchClientReps(action.leadIds, action.repIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return clientDialogRepActions.actionClientDialogBatchRepsAddSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogRepActions.actionClientDialogBatchRepsAddFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogRepActions.actionClientDialogBatchRepsAddFailed())
                    })
                )
            )
        )
    );

    removeBatchClientRep = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogBatchRepsRemoveRequested),
            switchMap(action =>
                this.clientDialogService.removeBatchClientReps(action.leadIds, action.repIds).pipe(
                    map(response => {
                        if (response.status == 1) {
                            this.notificationService.success('Update Successfully!')
                            return clientDialogRepActions.actionClientDialogBatchRepsRemoveSuccessfully()
                        } else {
                            this.notificationService.error(response.message);
                            return clientDialogRepActions.actionClientDialogBatchRepsRemoveFailed()
                        }
                    }),
                    catchError(error => {
                        return of(clientDialogRepActions.actionClientDialogBatchRepsRemoveFailed())
                    })
                )
            )
        )
    );



    leadAssignedUserUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogRepsUpdateSuccessfully),
            switchMap((action) => [
                actionClientDialogLeadCallFromPhoneNumbersRequest({ leadId: action.leadId })
            ])
        )
    )
    leadAssignedUserRemove$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientDialogRepActions.actionClientDialogClientRepDeleteSuccessfully),
            switchMap((action) => [
                actionClientDialogLeadCallFromPhoneNumbersRequest({ leadId: action.leadId })
            ])
        )
    )

}
