import { Action, createReducer, on } from "@ngrx/store";
import { TenantDialogRepsState } from "../../tenant-dialog.state";
import { tenantDialogRepActions } from "./tenant-dialog-tenant-rep.action";

const initialState: TenantDialogRepsState = {
    isRepsLoaded: false,
    isRepOptionsLoaded: false,
    tenantReps: [],
    repOptions: [],
    isRepSubmitting: false,
    isUpdateSuccessfully: false
}

const reducer = createReducer(
    initialState,
    on(
        tenantDialogRepActions.actionTenantDialogTenantRepsRequested,
        (state) => ({
            ...state,
            isRepsLoaded: false
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogTenantRepsLoaded,
        (state, { reps }) => ({
            ...state,
            isRepsLoaded: true,
            tenantReps: reps
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogTenantRepsFailed,
        (state) => ({
            ...state,
            isRepsLoaded: true
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepOptionsLoaded,
        (state, { reps }) => ({
            ...state,
            repOptions: reps,
            isRepOptionsLoaded: true
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepOptionsFailed,
        (state) => ({
            ...state,
            isRepOptionsLoaded: true
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsUpdate,
        (state, { reps }) => ({
            ...state,
            isRepsLoaded: true,
            tenantReps: reps
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepOptionsCheckStateInitialize,
        (state) => ({
            ...state,
            repOptions: state.repOptions.map(x => state.tenantReps.map(y => y.id).includes(x.id) ? { ...x, isChecked: true } : { ...x, isChecked: false })
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsRepIsCheckedUpdate,
        (state, { checked, rep }) => ({
            ...state,
            repOptions: state.repOptions.map(x => x.id == rep.id ? { ...x, isChecked: checked } : x)
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsUpdateRequested,
        (state) => ({
            ...state,
            isRepSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogBatchRepsAddRequested,
        tenantDialogRepActions.actionTenantDialogBatchRepsRemoveRequested,
        (state) => ({
            ...state,
            isRepSubmitting: true,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsUpdateSuccessfully,
        (state, { reps }) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: true,
            tenantReps: reps
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogBatchRepsAddSuccessfully,
        tenantDialogRepActions.actionTenantDialogBatchRepsRemoveSuccessfully,
        (state) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: true,
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogTenantRepDeleteSuccessfully,
        (state, { reps }) => ({
            ...state,
            tenantReps: reps
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsUpdateFailed,
        (state) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogBatchRepsAddFailed,
        tenantDialogRepActions.actionTenantDialogBatchRepsRemoveFailed,
        (state) => ({
            ...state,
            isRepSubmitting: false,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsCreateUpdateSuccessfulStateReset,
        (state) => ({
            ...state,
            isUpdateSuccessfully: false
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogTenantRepsFailed,
        (state) => ({
            ...state,
            isRepsLoaded: true
        })
    ),
    on(
        tenantDialogRepActions.actionTenantDialogRepsReset,
        (state,) => (initialState)
    ),
)

export const tenantDialogRepsReducer = (
    state: TenantDialogRepsState | undefined,
    action: Action
) => {
    return reducer(state, action);
};
